import React from "react";

export const Analytics = ({ viewBox = "0 0 20 20" }) => {
  return (
    <svg
      viewBox={viewBox}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.93333 3.35556C9.24106 3.12476 9.61534 3 10 3C10.3847 3 10.7589 3.12476 11.0667 3.35556L17.2889 8.02222C17.5097 8.18782 17.6889 8.40254 17.8123 8.6494C17.9357 8.89625 18 9.16845 18 9.44444V17C18 17.4715 17.8127 17.9237 17.4793 18.2571C17.1459 18.5905 16.6937 18.7778 16.2222 18.7778H3.77778C3.30628 18.7778 2.8541 18.5905 2.5207 18.2571C2.1873 17.9237 2 17.4715 2 17V9.44444C2 9.16845 2.06426 8.89625 2.18769 8.6494C2.31111 8.40254 2.49032 8.18782 2.71111 8.02222L8.93333 3.35556ZM10.8889 9C10.8889 8.76425 10.7952 8.53816 10.6285 8.37146C10.4618 8.20476 10.2357 8.11111 10 8.11111C9.76425 8.11111 9.53816 8.20476 9.37146 8.37146C9.20476 8.53816 9.11111 8.76425 9.11111 9V14.3333C9.11111 14.5691 9.20476 14.7952 9.37146 14.9619C9.53816 15.1286 9.76425 15.2222 10 15.2222C10.2357 15.2222 10.4618 15.1286 10.6285 14.9619C10.7952 14.7952 10.8889 14.5691 10.8889 14.3333V9ZM7.33333 11.6667C7.33333 11.4309 7.23968 11.2048 7.07298 11.0381C6.90628 10.8714 6.68019 10.7778 6.44444 10.7778C6.2087 10.7778 5.9826 10.8714 5.81591 11.0381C5.64921 11.2048 5.55556 11.4309 5.55556 11.6667V14.3333C5.55556 14.5691 5.64921 14.7952 5.81591 14.9619C5.9826 15.1286 6.2087 15.2222 6.44444 15.2222C6.68019 15.2222 6.90628 15.1286 7.07298 14.9619C7.23968 14.7952 7.33333 14.5691 7.33333 14.3333V11.6667ZM14.4444 13.4444C14.4444 13.2087 14.3508 12.9826 14.1841 12.8159C14.0174 12.6492 13.7913 12.5556 13.5556 12.5556C13.3198 12.5556 13.0937 12.6492 12.927 12.8159C12.7603 12.9826 12.6667 13.2087 12.6667 13.4444V14.3333C12.6667 14.5691 12.7603 14.7952 12.927 14.9619C13.0937 15.1286 13.3198 15.2222 13.5556 15.2222C13.7913 15.2222 14.0174 15.1286 14.1841 14.9619C14.3508 14.7952 14.4444 14.5691 14.4444 14.3333V13.4444Z"
        fill="currentColor"
      />
    </svg>
  );
};
