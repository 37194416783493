export const COMPARATIVE_ANALYSIS_USER_PERFORMANCE_INITIAL =
  "COMPARATIVE_ANALYSIS_USER_PERFORMANCE_INITIAL";
export const COMPARATIVE_ANALYSIS_USER_PERFORMANCE_PROCESS =
  "COMPARATIVE_ANALYSIS_USER_PERFORMANCE_PROCESS";
export const COMPARATIVE_ANALYSIS_USER_PERFORMANCE_SUCCESS =
  "COMPARATIVE_ANALYSIS_USER_PERFORMANCE_SUCCESS";
export const COMPARATIVE_ANALYSIS_USER_PERFORMANCE_FAILED =
  "COMPARATIVE_ANALYSIS_USER_PERFORMANCE_FAILED";

export const COMPARATIVE_ATTENDANCE_USER_PERFORMANCE_INITIAL =
  "COMPARATIVE_ATTENDANCE_USER_PERFORMANCE_INITIAL";
export const COMPARATIVE_ATTENDANCE_USER_PERFORMANCE_PROCESS =
  "COMPARATIVE_ATTENDANCE_USER_PERFORMANCE_PROCESS";
export const COMPARATIVE_ATTENDANCE_USER_PERFORMANCE_SUCCESS =
  "COMPARATIVE_ATTENDANCE_USER_PERFORMANCE_SUCCESS";
export const COMPARATIVE_ATTENDANCE_USER_PERFORMANCE_FAILED =
  "COMPARATIVE_ATTENDANCE_USER_PERFORMANCE_FAILED";

export const COMPARATIVE_GOAL_USER_PERFORMANCE_INITIAL =
  "COMPARATIVE_GOAL_USER_PERFORMANCE_INITIAL";
export const COMPARATIVE_GOAL_USER_PERFORMANCE_PROCESS =
  "COMPARATIVE_GOAL_USER_PERFORMANCE_PROCESS";
export const COMPARATIVE_GOAL_USER_PERFORMANCE_SUCCESS =
  "COMPARATIVE_GOAL_USER_PERFORMANCE_SUCCESS";
export const COMPARATIVE_GOAL_USER_PERFORMANCE_FAILED =
  "COMPARATIVE_GOAL_USER_PERFORMANCE_FAILED";

export const COMPARATIVE_APPLICATION_USER_PERFORMANCE_INITIAL =
  "COMPARATIVE_APPLICATION_USER_PERFORMANCE_INITIAL";
export const COMPARATIVE_APPLICATION_USER_PERFORMANCE_PROCESS =
  "COMPARATIVE_APPLICATION_USER_PERFORMANCE_PROCESS";
export const COMPARATIVE_APPLICATION_USER_PERFORMANCE_SUCCESS =
  "COMPARATIVE_APPLICATION_USER_PERFORMANCE_SUCCESS";
export const COMPARATIVE_APPLICATION_USER_PERFORMANCE_FAILED =
  "COMPARATIVE_APPLICATION_USER_PERFORMANCE_FAILED";
