import { put } from "redux-saga/effects";
import { LeaveTypeAction } from "../action";
import { Client } from "../../../utils/transport";

// LEAVE TYPE LIST

export function* GetLeaveType(payload) {
  let config = {
    method: "GET",
    url: `api/v1/leave_types/?${payload?.payload?.body}`,
    headers: {
      ...payload?.payload?.token,
    },
    fetchPolicy: "no-cache",
  };

  try {
    var response = yield Client.request(config);
    if (response.data) {
      yield put(LeaveTypeAction.LeaveTypeListSuccess(response.data));
    }
  } catch (err) {
    yield put(LeaveTypeAction.LeaveTypeListFailed(err.response));
  }
}

//CREATE LEAVE TYPE

export function* CreateLeaveType(payload) {
  let config = {
    method: "POST",
    url: "api/v1/leave_types/",
    headers: {
      ...payload?.payload?.token,
    },
    data: payload.payload.body,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);
    if (response.status) {
      yield put(LeaveTypeAction.LeaveTypeCreateSuccess(response.data));
    }
  } catch (error) {
    yield put(
      LeaveTypeAction.LeaveTypeCreateFailed(
        error.response.data.errors[0].detail
      )
    );
  }
}

//EDIT LEAVE TYPE

export function* EditLeaveType(payload) {
  let config = {
    method: "PUT",
    url: `api/v1/leave_types/${payload?.payload?.body?.id}`,
    headers: {
      ...payload?.payload?.token,
    },
    data: payload.payload.body,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.data) {
      yield put(LeaveTypeAction.LeaveTypeEditSuccess(response.data));
    }
  } catch (err) {
    yield put(LeaveTypeAction.LeaveTypeEditFailed(err.response));
  }
}
