//WORK DID BY KANCHAN
// modify by BJ RAIK 14-05-2022

//GET BREAKS
import * as actionTypes from "./actionTypes";

export const Domain_blocking_Policy_List_Initial = () => {
  return {
    type: actionTypes.DOMAIN_BLOCKING_POLICY_LIST_INITIAL,
  };
};
export const Domain_blocking_Policy_List_Process = (data) => {
  return {
    type: actionTypes.DOMAIN_BLOCKING_POLICY_LIST_PROCESS,
    data,
  };
};
export const Domain_blocking_Policy_List_Success = (data) => {
  return {
    type: actionTypes.DOMAIN_BLOCKING_POLICY_LIST_SUCCESS,
    data,
  };
};
export const Domain_blocking_Policy_List_Failed = (data) => {
  return {
    type: actionTypes.DOMAIN_BLOCKING_POLICY_LIST_FAILED,
    data,
  };
};

export const Domain_blocking_Policy_List_Search = (data) => {
  return {
    type: actionTypes.DOMAIN_BLOCKING_POLICY_LIST_SEARCH,
    data,
  };
};

//CREATE BREAKS

export const Domain_blocking_Policy_Create_Initial = (data) => {
  return {
    type: actionTypes.DOMAIN_BLOCKING_POLICY_CREATE_INITIAL,
    data,
  };
};
export const Domain_blocking_Policy_Create_Process = (data) => {
  return {
    type: actionTypes.DOMAIN_BLOCKING_POLICY_CREATE_PROCESS,
    data,
  };
};
export const Domain_blocking_Policy_Create_Success = (data) => {
  return {
    type: actionTypes.DOMAIN_BLOCKING_POLICY_CREATE_SUCCESS,
    data,
  };
};
export const Domain_blocking_Policy_Create_Failed = (data) => {
  return {
    type: actionTypes.DOMAIN_BLOCKING_POLICY_CREATE_FAILED,
    data,
  };
};

//EDIT BREAKS

export const Domain_blocking_Policy_Edit_Initial = (token, id, data) => {
  return {
    type: actionTypes.DOMAIN_BLOCKING_POLICY_EDIT_INITIAL,
    data,
    id,
    token,
  };
};
export const Domain_blocking_Policy_Edit_Process = (data) => {
  return {
    type: actionTypes.DOMAIN_BLOCKING_POLICY_EDIT_PROCESS,
    data,
  };
};
export const Domain_blocking_Policy_Edit_Success = (data) => {
  return {
    type: actionTypes.DOMAIN_BLOCKING_POLICY_EDIT_SUCCESS,
    data,
  };
};
export const Domain_blocking_Policy_Edit_Failed = (data) => {
  return {
    type: actionTypes.DOMAIN_BLOCKING_POLICY_EDIT_FAILED,
    data,
  };
};
