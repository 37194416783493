import { put } from "redux-saga/effects";
import {} from "./action";
import { Client } from "../../../../utils/transport";
import {
  ReportMonthlyAttendanceFailed,
  ReportMonthlyAttendanceSuccess,
  ReportDownloadMonthlyAttendanceFailed,
  ReportDownloadMonthlyAttendanceSuccess,
} from "./action";

export function* ReportMonthlyAttendance(data) {
  let config = {
    method: "POST",
    url: "query/reports/monthly_inout_attendance",

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };

  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(ReportMonthlyAttendanceSuccess(response.data));
    } else {
      yield put(ReportMonthlyAttendanceFailed(response));
    }
  } catch (err) {
    yield put(ReportMonthlyAttendanceFailed(err));
  }
}

export function* ReportDownloadMonthlyAttendance(data) {
  let config = {
    method: "POST",
    url: "query/reports/monthly_inout_attendance",

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };

  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(ReportDownloadMonthlyAttendanceSuccess(response.data));
    } else {
      yield put(ReportDownloadMonthlyAttendanceFailed(response));
    }
  } catch (err) {
    yield put(ReportDownloadMonthlyAttendanceFailed(err));
  }
}
