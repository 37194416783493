// GET ACTIVITY

import * as actionTypes from "./actionTypes";

export const ApplicationrReportGraphInitial = () => {
  return {
    type: actionTypes.APPLICATION_REPORT_GRAPH_INITIAL,
  };
};
export const ApplicationReportGraphProcess = (payload) => {
  return {
    type: actionTypes.APPLICATION_REPORT_GRAPH_PROCESS,
    payload,
  };
};
export const ApplicationReportGraphSuccess = (response) => {
  return {
    type: actionTypes.APPLICATION_REPORT_GRAPH_SUCCESS,
    response,
  };
};
export const ApplicationReportGraphFailed = (error) => {
  return {
    type: actionTypes.APPLICATION_REPORT_GRAPH_FAILED,
    error,
  };
};

export const ApplicationrReportListInitial = () => {
  return {
    type: actionTypes.APPLICATION_REPORT_LIST_INITIAL,
  };
};
export const ApplicationReportListProcess = (payload) => {
  return {
    type: actionTypes.APPLICATION_REPORT_LIST_PROCESS,
    payload,
  };
};
export const ApplicationReportListSuccess = (response) => {
  return {
    type: actionTypes.APPLICATION_REPORT_LIST_SUCCESS,
    response,
  };
};
export const ApplicationReportListFailed = (error) => {
  return {
    type: actionTypes.APPLICATION_REPORT_LIST_FAILED,
    error,
  };
};

export const ApplicationrReportExportListInitial = () => {
  return {
    type: actionTypes.APPLICATION_REPORT_EXPORT_LIST_INITIAL,
  };
};
export const ApplicationReportExportListProcess = (payload) => {
  return {
    type: actionTypes.APPLICATION_REPORT_EXPORT_LIST_PROCESS,
    payload,
  };
};
export const ApplicationReportExportListSuccess = () => {
  return {
    type: actionTypes.APPLICATION_REPORT_EXPORT_LIST_SUCCESS,
  };
};
export const ApplicationReportExportListFailed = (error) => {
  return {
    type: actionTypes.APPLICATION_REPORT_EXPORT_LIST_FAILED,
    error,
  };
};
