export function getColorCodes() {
  return {
    onlineTime: "#A290FF",
    activeTime: "#004EEC",
    idleTime: "#D5D4DB",
    productiveTime: "#00B5B5",
    unproductiveTime: "#F2645A",
    neutralTime: "#8C8C8C",
    breakTime: "#FFD80A",
    offlineTime: "#EC66A7",
    "0-25%": "#91B8F8",
    "25-50%": "#518CFE",
    "50-75%": "#145EF0",
    "75-100": "#013AAC",
    PRODUCTIVE: "#00B5B5",
    UNPRODUCTIVE: "#F2645A",
    NEUTRAL: "#8C8C8C",
    goals_0_25: "#723BF8",
    goals_25_50: "#FF5777",
    goals_50_75: "#FFB800",
    goals_75: "#14B8A6",
    goals_0_25_secondary: "#E3D8FE",
    goals_25_50_secondary: "#FFDDE4",
    goals_50_75_secondary: "#FFF1CC",
    goals_75_secondary: "#EBFAF7",
    goals_achived: "#14B8A6",
    goals_missed: "#FF5777",
    past_goals_achived: "#14B8A690",
    past_goals_missed: "#FF577790",
    goals_percent_bar_bg: "#ECF4FF",
    graph_legends_font_color: "#86909C",
    present: "#7B61FF",
    absent: "#CFD8DC",
    attendancePer: "#FFB800",
    avgWorkTime: "#00B5B5",
    // productivityActivityPer: "#5382FF",
    // onTime: "#27C94D",
    // lateArrival: "#E23B32",
    // healthy: "#27C94D",
    // overburdened: "#E23B32",
    // underutilized: "#FFD80A",
    // wpresent: "#4A2DDA",
  };
}
