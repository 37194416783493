import * as actionTypes from "./actionTypes";

//* ACTIVITY SUMMARY WEDIGT *//

export const ActivitySummaryWedigtInitial = (token) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_WEDIGT_INITIAL,
    token,
  };
};
export const ActivitySummaryWedigtProcess = (token) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_WEDIGT_PROCESS,
    token,
  };
};
export const ActivitySummaryWedigtSuccess = (data) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_WEDIGT_SUCCESS,
    data,
  };
};
export const ActivitySummaryWedigtFailed = (data) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_WEDIGT_FAILED,
    data,
  };
};

//* Online time Breakdown SUMMARY *//

export const ActivitySummaryOnlineTimeBreakdownInitial = () => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_ONLINE_TIME_BREAK_DOWN_INITIAL,
  };
};
export const ActivitySummaryOnlineTimeBreakdownProcess = (token, bodyData) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_ONLINE_TIME_BREAK_DOWN_PROCESS,
    token,
    bodyData,
  };
};
export const ActivitySummaryOnlineTimeBreakdownSuccess = (data) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_ONLINE_TIME_BREAK_DOWN_SUCCESS,
    data,
  };
};
export const ActivitySummaryOnlineTimeBreakdownFailed = (data) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_ONLINE_TIME_BREAK_DOWN_FAILED,
    data,
  };
};

//*  ACTIVITY SUMMARY ACTIVITY Level Break down *//

export const ActivitySummaryActivityLevelBreakdownInitial = () => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_ACTIVITY_LEVEL_BREAK_DOWN_INITIAL,
  };
};
export const ActivitySummaryActivityLevelBreakdownProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_ACTIVITY_LEVEL_BREAK_DOWN_PROCESS,
    token,
    bodyData,
  };
};
export const ActivitySummaryActivityLevelBreakdownSuccess = (data) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_ACTIVITY_LEVEL_BREAK_DOWN_SUCCESS,
    data,
  };
};
export const ActivitySummaryActivityLevelBreakdownFailed = (data) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_ACTIVITY_LEVEL_BREAK_DOWN_FAILED,
    data,
  };
};

//*  ACTIVITY SUMMARY Top 3 most active team *//

export const ActivitySummaryTopButtomActiveTeamInitial = () => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_INITIAL,
  };
};
export const ActivitySummaryTopButtomActiveTeamProcess = (token, bodyData) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_PROCESS,
    token,
    bodyData,
  };
};
export const ActivitySummaryTopButtomActiveTeamSuccess = (data) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_SUCCESS,
    data,
  };
};
export const ActivitySummaryTopButtomActiveTeamFailed = (data) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_FAILED,
    data,
  };
};
//*  ACTIVITY SUMMARY Top 3 lease active team *//

export const ActivitySummaryTopLeastActiveTeamInitial = (token) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_TOP_LEAST_ACTIVE_TEAM_INITIAL,
    token,
  };
};
export const ActivitySummaryTopLeastActiveTeamProcess = (token) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_TOP_LEAST_ACTIVE_TEAM_PROCESS,
    token,
  };
};
export const ActivitySummaryTopLeastActiveTeamSuccess = (data) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_TOP_LEAST_ACTIVE_TEAM_SUCCESS,
    data,
  };
};
export const ActivitySummaryTopLeastActiveTeamFailed = (data) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_TOP_LEAST_ACTIVE_TEAM_FAILED,
    data,
  };
};

//*  ACTIVITY SUMMARY TEAM WISE ACTIVITY BREAK DOWN *//

export const ActivitySummaryTeamWiseActivityBreakdownInitial = () => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_TEAM_WISE_ACTIVITY_BREAK_DOWN_INITIAL,
  };
};
export const ActivitySummaryTeamWiseActivityBreakdownProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_TEAM_WISE_ACTIVITY_BREAK_DOWN_PROCESS,
    token,
    bodyData,
  };
};
export const ActivitySummaryTeamWiseActivityBreakdownSuccess = (data) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_TEAM_WISE_ACTIVITY_BREAK_DOWN_SUCCESS,
    data,
  };
};
export const ActivitySummaryTeamWiseActivityBreakdownFailed = (data) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_TEAM_WISE_ACTIVITY_BREAK_DOWN_FAILED,
    data,
  };
};

export const ActivitySummaryTopAppUrlInitial = () => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_TOP_APPLICATION_URL_INITIAL,
  };
};

export const ActivitySummaryTopAppUrlProcess = (token, bodyData) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_TOP_APPLICATION_URL_PROCESS,
    token,
    bodyData,
  };
};

export const ActivitySummaryTopAppUrlSuccess = (data) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_TOP_APPLICATION_URL_SUCCESS,
    data,
  };
};

export const ActivitySummaryTopAppUrlFailed = (data) => {
  return {
    type: actionTypes.ACTIVITY_SUMMARY_TOP_APPLICATION_URL_FAILED,
    data,
  };
};
