import React from "react";

export const Leave = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        d="M8.96,10.07c-0.89,0.3-1.71,0.57-2.53,0.84c-1.17,0.39-2.33,0.78-3.5,1.16c-0.15,0.05-0.33,0.1-0.48,0.07
		c-0.15-0.03-0.37-0.13-0.4-0.24c-0.04-0.15,0.03-0.38,0.13-0.49c0.15-0.16,0.38-0.24,0.59-0.36c-0.7-0.87-1.39-1.74-2.09-2.6
		c-0.62-0.77-0.48-1.19,0.5-1.43c0.14-0.03,0.32,0.03,0.46,0.1c1.01,0.51,2.01,1.03,3.01,1.56c0.25,0.13,0.43,0.14,0.69,0
		c3.14-1.64,6.29-3.26,9.43-4.88c0.64-0.33,1.3-0.57,2.05-0.53c0.44,0.03,0.88-0.06,1.32-0.06c0.22,0,0.45,0.03,0.66,0.1
		c0.51,0.17,0.73,0.77,0.34,1.18c-0.39,0.43-0.87,0.81-1.37,1.09c-1.36,0.76-2.76,1.45-4.13,2.18c-0.22,0.12-0.42,0.27-0.6,0.45
		c-1.09,1.12-2.14,2.29-3.27,3.37C9.35,12,8.75,12.24,8.21,12.53c-0.09,0.05-0.35-0.02-0.39-0.11c-0.07-0.13-0.07-0.35,0-0.48
		C8.17,11.33,8.54,10.74,8.96,10.07z"
      />
      <path
        d="M18.86,15.02c0.5,0.08,0.8,0.38,0.82,0.82c0.02,0.44-0.26,0.77-0.75,0.89c-0.11,0-0.23-0.01-0.34-0.01
		c-4.16,0-8.33,0-12.49,0c-1.49,0-2.98,0.01-4.47,0.01c-0.44-0.13-0.77-0.5-0.75-0.86c0.01-0.39,0.37-0.75,0.83-0.84
		c0.1,0.01,0.2,0.02,0.3,0.02c5.5,0,11.01,0,16.51,0C18.63,15.04,18.74,15.02,18.86,15.02z"
      />
      <path
        d="M1.63,16.72c1.49,0,2.98-0.01,4.47-0.01c4.16,0,8.33,0,12.49,0c0.11,0,0.23,0.01,0.34,0.01
		c-0.13,0.02-0.27,0.06-0.4,0.06c-5.49,0-10.97,0-16.46,0C1.93,16.78,1.78,16.74,1.63,16.72z"
      />
      <path
        d="M18.86,15.02c-0.11,0.01-0.23,0.02-0.34,0.02c-5.5,0-11.01,0-16.51,0c-0.1,0-0.2-0.01-0.3-0.02
		c0.13-0.02,0.27-0.05,0.4-0.05c5.45,0,10.9,0,16.35,0C18.59,14.97,18.72,15,18.86,15.02z"
      />
    </svg>
  );
};
