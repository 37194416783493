import * as actionTypes from "./actionTypes";

export const getProject = (payload) => {
  return {
    type: actionTypes.GET_PROJECT,
    payload,
  };
};

export const getProjectSuccess = (payload) => {
  return {
    type: actionTypes.GET_PROJECT_SUCCESS,
    payload,
  };
};

export const getProjectFailed = (payload) => {
  return {
    type: actionTypes.GET_PROJECT_FAILED,
    payload,
  };
};

export const fetchProjects = (payload) => {
  return {
    type: actionTypes.FETCH_PROJECTS,
    payload,
  };
};

export const fetchProjectsSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_PROJECTS_SUCCESS,
    payload,
  };
};

export const fetchProjectsFailed = (payload) => {
  return {
    type: actionTypes.FETCH_PROJECTS_FAILED,
    payload,
  };
};

export const createProject = (payload) => {
  return {
    type: actionTypes.CREATE_PROJECT,
    payload,
  };
};

export const createProjectSuccess = (payload) => {
  return {
    type: actionTypes.CREATE_PROJECT_SUCCESS,
    payload,
  };
};

export const createProjectFailed = (payload) => {
  return {
    type: actionTypes.CREATE_PROJECT_FAILED,
    payload,
  };
};

export const deleteProject = (payload) => {
  return {
    type: actionTypes.DELETE_PROJECT,
    payload,
  };
};

export const deleteProjectSuccess = (payload) => {
  return {
    type: actionTypes.DELETE_PROJECT_SUCCESS,
    payload,
  };
};

export const deleteProjectFailed = (payload) => {
  return {
    type: actionTypes.DELETE_PROJECT_FAILED,
    payload,
  };
};

export const updateProject = (payload) => {
  return {
    type: actionTypes.UPDATE_PROJECT,
    payload,
  };
};

export const updateProjectSuccess = (payload) => {
  return {
    type: actionTypes.UPDATE_PROJECT_SUCCESS,
    payload,
  };
};

export const updateProjectFailed = (payload) => {
  return {
    type: actionTypes.UPDATE_PROJECT_FAILED,
    payload,
  };
};

export const setProjectErrorMessage = (payload) => {
  return {
    type: actionTypes.SET_PROJECT_ERROR_MESSAGE,
    payload,
  };
};

export const setSelectedProject = (payload) => {
  return {
    type: actionTypes.SET_SELECTED_PROJECT,
    payload,
  };
};
