import { put, call } from "redux-saga/effects";
import { TeamAction } from "../action";
import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";

export function* CreateTeam(data) {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    url: "TEAM_PHASE_1",
    method: "POST",
    data: data.data.BodyData,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    yield put(TeamAction.TeamCreateSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TeamAction.TeamCreateFailed(errorMessage));
  }
}

export function* UpdateTeam(data) {
  let config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    url: "api/v1/groups/" + data.data.BodyData.id,
    method: "PUT",
    data: data.data.BodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(TeamAction.TeamUpdateSuccess(response.data));
    } else {
      yield put(TeamAction.TeamUpdateFailed(response.data));
    }
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TeamAction.TeamUpdateFailed(errorMessage));
  }
}

export function* TeamList(data) {
  let config = {
    method: "GET",
    // url: "TEAM_PHASE_1",
    url: "api/v1/groups/?page=1&size=500",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      // "X-Tenant-Id": data.data.clientId,
    },
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(TeamAction.TeamListSuccess(response.data));
    } else {
      yield put(TeamAction.TeamListFailed(response));
    }
  } catch (err) {
    yield put(TeamAction.TeamListFailed(err));
  }
}

export function* DeleteTeam(data) {
  var IsChild = data.data.IsChild;
  var url = "";
  if (IsChild) {
    url = "api/v1/groups/" + data.data.BodyData.id;
  } else {
    url = "api/v1/groups/" + data.data.BodyData.id;
  }

  let config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    url: url,
    method: "DELETE",
    data: "",
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    yield put(
      TeamAction.TeamDeleteSuccess({
        data: response.data,
        deletedTeamId: data.data.BodyData.id,
      })
    );
  } catch (err) {
    yield put(TeamAction.TeamDeleteFailed(err));
  }
}

export function* UpdateMemberTeam(data) {
  let config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
    },
    url: "/api/v1/user_infos/" + data.data.memberId,
    method: "put",
    data: data.data.BodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(TeamAction.TeamMemberSuccess(response));
    } else {
      yield put(TeamAction.TeamMemberFailed(response));
    }
  } catch (err) {
    yield put(TeamAction.TeamMemberFailed(err));
  }
}

export function* UpdateManagerTeam(data) {
  //
  let config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    url: "/api/v1/user_infos/" + data.data.BodyData.memberId,
    method: "put",
    data: data.data.BodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(TeamAction.TeamManegerSuccess(response));
    } else {
      yield put(TeamAction.TeamManegerFailed(response));
    }
  } catch (err) {
    yield put(TeamAction.TeamManegerFailed(err));
  }
}

export function* UpdateUserSetting(data) {
  let config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    url: "/api/v1/user_infos/" + data.data.BodyData.id,
    method: "put",
    data: data.data.BodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(TeamAction.UpdateUserSettingSuccess(response));
    } else {
      yield put(TeamAction.UpdateUserSettingFailed(response));
    }
  } catch (err) {
    yield put(TeamAction.UpdateUserSettingFailed(err));
  }
}
