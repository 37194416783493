import React from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts"; // Assuming you are using react-apexcharts
import { Skeleton, Empty } from "antd";
import { hm, ms } from "../../../../utils/timeConverter";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { GetMaximumValue, GetMinimumValue } from "utils/getMinimunMaximumValue";
import { getColorCodes } from "utils/colorCodes";
import { ApexOptions } from "apexcharts"; // Import ApexOptions type

const colorCodes = getColorCodes();

const Cardskeleton = () => {
  return (
    <div className="charts">
      <div className="chart_header">
        <Skeleton.Input size="small" style={{ height: "15px" }} />
        <Skeleton.Avatar
          size="small"
          shape="circle"
          style={{ height: "15px", width: "15px" }}
        />
      </div>
      <div className="chartContainer all_tool" id="chart">
        <Skeleton.Avatar
          size="large"
          style={{ height: "300px", width: "100%" }}
        />
      </div>
    </div>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

interface UserMonthlyActivityTrendProps {
  teamWiseActivityTrendData: {
    activityTime?: number[];
    idleTime?: number[];
    formattedDate?: string[];
    date?: string[];
  };
  hideTooltip?: boolean;
  title?: string;
  tooltip?: string;
}

const UserMonthlyActivityTrend: React.FC<UserMonthlyActivityTrendProps> = (
  props
) => {
  const { teamWiseActivityTrendData } = props || {};
  const activitydetails = useSelector((state: any) => state.activitydetail);
  const arrays = [
    teamWiseActivityTrendData?.activityTime,
    teamWiseActivityTrendData?.idleTime,
  ];

  const minValue = GetMinimumValue(arrays);
  const maxValue = GetMaximumValue(arrays);
  const trendData = {
    series: [
      {
        name: "Active time",
        type: "line",
        data: teamWiseActivityTrendData?.activityTime || [],
      },
      {
        name: "Idle time",
        type: "line",
        data: teamWiseActivityTrendData?.idleTime || [],
      },
    ],
  };

  const dataOptions: ApexOptions = {
    chart: {
      background: "transparent",
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: [colorCodes.activeTime, colorCodes.idleTime],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },
    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      categories: teamWiseActivityTrendData?.formattedDate,
      labels: {
        show: false, // Hide x-axis labels
        rotate: -45,
        rotateAlways: true,
        hideOverlappingLabels: true,
        trim: true,
        style: {
          colors: colorCodes.graph_legends_font_color,
        },
      },
      axisBorder: {
        show: true,
        offsetY: 2,
      },
    },
    yaxis: {
      min: minValue >= 0 ? 0 : minValue - 10800,
      max: maxValue > 3659 ? maxValue + 10800 : 3660,
      tickAmount: 4,
      labels: {
        style: {
          colors: colorCodes.graph_legends_font_color,
        },
        formatter: function (val: number) {
          if (maxValue > 3659) {
            return Math.floor(val / 3600) + "h";
          } else {
            const minutes = Math.floor(val / 60);
            return minutes + "m";
          }
        },
      },
    },
    legend: {
      position: "top",
      horizontalAlign: "center",
      show: false,
    },
    tooltip: {
      enabled: true,
      theme: "light",
      custom: function ({
        w,
        dataPointIndex,
      }: {
        w: any;
        dataPointIndex: number;
      }) {
        const date = dayjs(
          teamWiseActivityTrendData?.date?.[dataPointIndex]
        ).format("DD-MMM-YYYY");
        const activityTime =
          maxValue > 3659
            ? hm(teamWiseActivityTrendData?.activityTime?.[dataPointIndex] || 0)
            : ms(
                teamWiseActivityTrendData?.activityTime?.[dataPointIndex] || 0
              );
        const idleTime =
          maxValue > 3659
            ? hm(teamWiseActivityTrendData?.idleTime?.[dataPointIndex] || 0)
            : ms(teamWiseActivityTrendData?.idleTime?.[dataPointIndex] || 0);

        return `<div class="bartooltip">${date}</div><div class="tooltip_card"><div class="w_data"><div class="colordot" style="background:${w.globals.colors[0]}"></div>${w.globals.seriesNames[0]}<div class="w_value">${activityTime}</div></div><div class="w_data"><div class="colordot" style="background:${w.globals.colors[1]}"></div>${w.globals.seriesNames[1]}<div class="w_value">${idleTime}</div></div>`;
      },
    },
  };

  return (
    <>
      {activitydetails &&
      activitydetails?.activity_detailed_working_time_trend_processing ===
        true ? (
        <Cardskeleton />
      ) : (
        <div className="charts shadow-none border-0">
          <div
            className="chartContainer all_tool pl-0 pr-0"
            id="chart"
            style={{ marginTop: "-40px" }}
          >
            {teamWiseActivityTrendData?.activityTime?.length > 0 ? (
              <Chart
                options={dataOptions}
                series={trendData?.series || []}
                type="line"
                height={280}
              />
            ) : (
              <div style={{ height: "271px" }} className="emptyblock">
                <Empty description={false} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

UserMonthlyActivityTrend.propTypes = {
  teamWiseActivityTrendData: PropTypes.object,
  hideTooltip: PropTypes.bool,
  title: PropTypes.string,
  tooltip: PropTypes.string,
};

export default UserMonthlyActivityTrend;
