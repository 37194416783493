export const FETCH_CROSS_DAY_ATTENDANCE_REPORT =
  "FETCH_CROSS_DAY_ATTENDANCE_REPORT";
export const FETCH_CROSS_DAY_ATTENDANCE_REPORT_SUCCESS =
  "FETCH_CROSS_DAY_ATTENDANCE_REPORT_SUCCESS";
export const FETCH_CROSS_DAY_ATTENDANCE_REPORT_FAILED =
  "FETCH_CROSS_DAY_ATTENDANCE_REPORT_FAILED";

export const DOWNLOAD_CROSS_DAY_ATTENDANCE_REPORT =
  "DOWNLOAD_CROSS_DAY_ATTENDANCE_REPORT";
export const DOWNLOAD_CROSS_DAY_ATTENDANCE_REPORT_SUCCESS =
  "DOWNLOAD_CROSS_DAY_ATTENDANCE_REPORT_SUCCESS";
export const DOWNLOAD_CROSS_DAY_ATTENDANCE_REPORT_FAILED =
  "DOWNLOAD_CROSS_DAY_ATTENDANCE_REPORT_FAILED";
