import * as actionTypes from "./actionType";
import _ from "lodash";

const initialState = {
  //*GLOBAL*//

  /** REPORT MONTHLY IN OUT  */

  report_monthly_in_out_processing: false,
  report_monthly_in_out_success: false,
  report_monthly_in_out_failed: false,
  report_monthly_in_out_data: [],
  report_monthly_in_out_message: "",

  /** REPORT MONTHLY IN OUT DOWNLOAD */

  report_monthly_in_out_download_processing: false,
  report_monthly_in_out_download_success: false,
  report_monthly_in_out_download_failed: false,
  report_monthly_in_out_download_data: [],
  report_monthly_in_out_download_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    //* REPORT MONTHLY IN OUT

    case actionTypes.REPORT_MONTHLY_IN_OUT_INITIAL:
      return {
        ...state,
        report_monthly_in_out_processing: false,
        report_monthly_in_out_success: false,
        report_monthly_in_out_failed: false,
        report_monthly_in_out_data: {},
        report_monthly_in_out__message: "",
      };

    case actionTypes.REPORT_MONTHLY_IN_OUT_PROCESS:
      return {
        ...state,
        report_monthly_in_out_processing: true,
        report_monthly_in_out_success: false,
        report_monthly_in_out_failed: false,
        report_monthly_in_out_data: {},
        report_monthly_in_out_message: "",
      };

    case actionTypes.REPORT_MONTHLY_IN_OUT_SUCCESS:
      return {
        ...state,
        report_monthly_in_out_processing: false,
        report_monthly_in_out_success: true,
        report_monthly_in_out_failed: false,
        report_monthly_in_out_data: action.data,
        report_monthly_in_out_message: "",
      };

    case actionTypes.REPORT_MONTHLY_IN_OUT_FAILED:
      return {
        ...state,
        report_monthly_in_out_processing: false,
        report_monthly_in_out_success: false,
        report_monthly_in_out_failed: true,
        report_monthly_in_out_data: action.data,
        report_monthly_in_out_message: action.err,
      };

    case actionTypes.REPORT_MONTHLY_IN_OUT_DOWNLOAD_INITIAL:
      return {
        ...state,
        report_monthly_in_out_download_processing: false,
        report_monthly_in_out_download_success: false,
        report_monthly_in_out_download_failed: false,
        report_monthly_in_out_download_data: [],
        report_monthly_in_out_download_message: "",
      };

    case actionTypes.REPORT_MONTHLY_IN_OUT_DOWNLOAD_PROCESS:
      return {
        ...state,
        report_monthly_in_out_download_processing: false,
        report_monthly_in_out_download_success: true,
        report_monthly_in_out_download_failed: false,
        report_monthly_in_out_download_data: [],
        report_monthly_in_out_download_message: "",
      };

    case actionTypes.REPORT_MONTHLY_IN_OUT_DOWNLOAD_SUCCESS:
      return {
        ...state,
        report_monthly_in_out_download_processing: false,
        report_monthly_in_out_download_success: true,
        report_monthly_in_out_download_failed: false,
        report_monthly_in_out_download_data: action.data,
        report_monthly_in_out_download_message: "",
      };

    case actionTypes.REPORT_MONTHLY_IN_OUT_DOWNLOAD_FAILED:
      return {
        ...state,
        report_monthly_in_out_download_processing: false,
        report_monthly_in_out_download_success: false,
        report_monthly_in_out_download_failed: true,
        report_monthly_in_out_download_data: action.data,
        report_monthly_in_out_download_message: "",
      };
    default:
      return state;
  }
};
