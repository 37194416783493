import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "../index.scss";
import { InfoCircleFilled } from "@ant-design/icons";
import _ from "lodash";
import { hms } from "../../../utils/timeConverter";
import appIcon from "../../../assets/images/icon_app.svg";
import urlIcon from "../../../assets/images/icon_url.svg";
import {
  Skeleton,
  Typography,
  Tooltip,
  Empty,
  Card,
  Tag,
  Table,
  Spin,
} from "antd";
import { Tabshorizental, Tabh, Content } from "./tabs";
import { useSelector } from "react-redux";
const { Title } = Typography;

const Cardskeleton = () => {
  return (
    <>
      <div className="charts">
        <div className="chart_header">
          <Skeleton.Input
            size={"small"}
            shape={"default"}
            style={{ height: "15px" }}
          />
          <Skeleton.Avatar
            size={"small"}
            shape={"circle"}
            style={{ height: "15px", width: "15px" }}
          />
        </div>
        <div className="chartContainer chart_tool" id="chart">
          {/* chart */}
          <Skeleton.Avatar
            size={"large"}
            shape={"default"}
            style={{ height: "275px", width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

const AppUrlLogs = () => {
  const [active, setActive] = useState(0);
  const [categoryWiseSearchUrl, setCategoryWiseSearchUrl] = useState([]);
  const [categoryWiseSearchApp, setCategoryWiseSearchApp] = useState([]);
  const [categoryWiseSearchAppUrl, setCategoryWiseSearchAppUrl] = useState([]);

  const applicationdetailed = useSelector((state) => state.applicationdetailed);

  useEffect(() => {
    if (
      applicationdetailed.application_detailed_search_wise_app_detail_success ===
      true
    ) {
      if (
        applicationdetailed?.application_detailed_search_wise_app_detail_data
          ?.length > 0
      ) {
        const categoryAppSearch = [];
        applicationdetailed.application_detailed_search_wise_app_detail_data.map(
          (item, index) => {
            if (
              item.application_name !== null &&
              item.application_name !== ""
            ) {
              categoryAppSearch.push({
                application_name: item.application_name,
                productive_duration: item.productive_duration,
                app_policy_status: item.app_policy_status,
                key: "app" + index,
                index: index,
              });
            }
          }
        );

        const appSearchOrderBy = categoryAppSearch.sort(
          (a, b) => b.productive_duration - a.productive_duration
        );

        setCategoryWiseSearchApp(appSearchOrderBy);
      }
    }
    if (
      applicationdetailed.application_detailed_search_wise_url_detail_success ===
      true
    ) {
      if (
        applicationdetailed?.application_detailed_search_wise_url_detail_data
          ?.length > 0
      ) {
        const categoryUrlSearch = [];
        applicationdetailed.application_detailed_search_wise_url_detail_data.map(
          (item, index) => {
            if (item.url !== null && item.url !== "") {
              categoryUrlSearch.push({
                url: item.url,
                productive_duration:
                  item.productive_duration === null
                    ? 0
                    : item.productive_duration,
                app_policy_status: item.app_policy_status,

                key: "app" + index,
                index: index,
              });
            }
          }
        );
        const urlSearchOrderBy = categoryUrlSearch.sort(
          (a, b) => b.productive_duration - a.productive_duration
        );

        setCategoryWiseSearchUrl(urlSearchOrderBy);
      }
    }
    if (
      applicationdetailed.application_detailed_search_wise_url_detail_failed ===
      true
    ) {
      setCategoryWiseSearchUrl([]);
    }

    if (
      applicationdetailed.application_detailed_search_wise_app_url_detail_success ===
      true
    ) {
      if (
        applicationdetailed
          ?.application_detailed_search_wise_app_url_detail_data?.length > 0
      ) {
        const categoryAppUrlSearch = [];
        applicationdetailed.application_detailed_search_wise_app_url_detail_data.map(
          (item, index) => {
            if (item.name !== null && item.name !== "") {
              categoryAppUrlSearch.push({
                name: item.name,
                productive_duration:
                  item.productive_duration === null
                    ? 0
                    : item.productive_duration,

                title: item.title,
                key: "app" + index,
                index: index,
              });
            }
          }
        );

        const appUrlSearchOrderBy = categoryAppUrlSearch.sort(
          (a, b) => b.productive_duration - a.productive_duration
        );

        setCategoryWiseSearchAppUrl(appUrlSearchOrderBy);
      }
    }
  }, [applicationdetailed]);

  const handleClick = (e) => {
    const index = parseInt(e.target.id, 0);
    if (index !== active) {
      setActive(index);
    }
  };

  const urlcolumns = [
    {
      title: "URLs",
      dataIndex: "url",

      render: (val) => (
        <>
          {applicationdetailed.application_detailed_search_wise_url_detail_processing ? (
            <div className="flex item_center">
              <Skeleton.Avatar
                size={"small"}
                shape={"circle"}
                style={{ height: "30px", width: "30px", marginRight: "15px" }}
              />
              <Skeleton.Input
                size={"small"}
                shape={"default"}
                style={{ height: "15px" }}
              />
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>
              <img
                src={urlIcon}
                style={{ width: "20px", height: "20px", marginRight: "15px" }}
              />
              {val}
            </div>
          )}
        </>
      ),
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Mapping",
      dataIndex: "app_policy_status",
      render: (val, record) => (
        <>
          {record.app_policy_status === "" ? null : (
            <Tag
              color={
                record.app_policy_status === "PRODUCTIVE"
                  ? "success"
                  : record.app_policy_status === "UNPRODUCTIVE"
                  ? "error"
                  : "default"
              }
            >
              {record.app_policy_status}
            </Tag>
          )}
        </>
      ),
    },
    {
      title: "Duration",
      dataIndex: "productive_duration",
      width: 200,
      render: (val) => <>{hms(val)}</>,
    },
  ];

  const logscolumns = [
    {
      title: "Name",
      dataIndex: "name",
      // sorter: {
      //   compare: (a, b) => a.math - b.math,
      //   multiple: 2,
      // },
      render: (val) => (
        <>
          {applicationdetailed.application_detailed_search_wise_app_url_detail_processing ? (
            <div className="flex item_center">
              <Skeleton.Avatar
                size={"small"}
                shape={"circle"}
                style={{ height: "30px", width: "30px", marginRight: "15px" }}
              />
              <Skeleton.Input
                size={"small"}
                shape={"default"}
                style={{ height: "15px" }}
              />
            </div>
          ) : (
            <div style={{ display: "flex", alignItems: "center" }}>{val}</div>
          )}
        </>
      ),
    },
    {
      title: "Duration",
      dataIndex: "productive_duration",
      width: 200,
      render: (val) => <>{hms(val)}</>,
    },
    {
      title: "Title",
      dataIndex: "title",
      ellipsis: true,
    },
  ];

  return (
    <>
      {applicationdetailed.application_detailed_search_wise_app_detail_processing ? (
        <Cardskeleton />
      ) : (
        <div className="charts marginT">
          <div className="chart_header" style={{ marginBottom: 0 }}>
            <Content active={active === 0}>
              <div className="flex item_center">
                <Title level={2} className="mr-3">
                  All Used Apps{" "}
                </Title>
                <Tooltip
                  title={
                    "Lists all used applications, their usage duration, titles visited and logs."
                  }
                >
                  <InfoCircleFilled />
                </Tooltip>
              </div>
            </Content>
            <Content active={active === 1}>
              <div className="flex item_center">
                <Title level={2} className="mr-3">
                  All Used URLs{" "}
                </Title>
                <Tooltip
                  title={
                    "Lists all used URLs, their usage duration, titles visited and logs."
                  }
                >
                  <InfoCircleFilled />
                </Tooltip>
              </div>
            </Content>
            <Content active={active === 2}>
              <div className="flex item_center">
                <Title level={2} className="mr-3">
                  Logs{" "}
                </Title>
                <Tooltip
                  title={
                    "Lists all used apps/URLs, their usage duration, titles visited and logs."
                  }
                >
                  <InfoCircleFilled />
                </Tooltip>
              </div>
            </Content>

            <div className="flex item_center">
              <Tabshorizental>
                <Tabh onClick={handleClick} active={active === 0} id={0}>
                  Apps
                </Tabh>

                <Tabh onClick={handleClick} active={active === 1} id={1}>
                  URLs
                </Tabh>
                <Tabh onClick={handleClick} active={active === 2} id={2}>
                  Logs
                </Tabh>
              </Tabshorizental>
            </div>
          </div>
          <div className="" style={{ marginTop: "0px" }}>
            {/* chart */}

            <div style={{}}>
              <Content active={active === 0}>
                {applicationdetailed.application_detailed_search_wise_app_detail_processing ? (
                  <Spin />
                ) : applicationdetailed
                    ?.application_detailed_search_wise_app_detail_data?.length >
                  0 ? (
                  <div className="aul_block mgrid column_2">
                    {categoryWiseSearchApp &&
                      _.map(categoryWiseSearchApp, (item, index) => {
                        return (
                          <Card key={index} className="app_block">
                            <div className="flex item_center">
                              <img src={appIcon} />

                              <div className="app_info">
                                {item.application_name}
                                <small> {hms(item.productive_duration)}</small>
                              </div>
                            </div>
                            {item.app_policy_status === "" ? null : (
                              <Tag
                                color={
                                  item.app_policy_status === "PRODUCTIVE"
                                    ? "success"
                                    : item.app_policy_status === "UNPRODUCTIVE"
                                    ? "error"
                                    : "default"
                                }
                              >
                                {item.app_policy_status}
                              </Tag>
                            )}
                          </Card>
                        );
                      })}
                  </div>
                ) : (
                  <div style={{ height: "242px" }} className="emptyblock">
                    <Empty description={false} />
                  </div>
                )}
              </Content>

              <Content active={active === 1}>
                {applicationdetailed.application_detailed_search_wise_url_detail_processing ? (
                  <Spin />
                ) : applicationdetailed
                    ?.application_detailed_search_wise_url_detail_data?.length >
                  0 ? (
                  <Table
                    columns={urlcolumns}
                    dataSource={categoryWiseSearchUrl && categoryWiseSearchUrl}
                    size="small"
                  />
                ) : (
                  <div style={{ height: "242px" }} className="emptyblock">
                    <Empty description={false} />
                  </div>
                )}
              </Content>
              <Content active={active === 2}>
                {applicationdetailed.application_detailed_search_wise_app_url_detail_processing ? (
                  <Spin />
                ) : applicationdetailed
                    ?.application_detailed_search_wise_app_url_detail_data
                    ?.length > 0 ? (
                  <Table
                    columns={logscolumns}
                    dataSource={
                      categoryWiseSearchAppUrl && categoryWiseSearchAppUrl
                    }
                    size="small"
                  />
                ) : (
                  <div style={{ height: "242px" }} className="emptyblock">
                    <Empty description={false} />
                  </div>
                )}
              </Content>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

AppUrlLogs.propTypes = {
  getList: PropTypes.func,
};

export default AppUrlLogs;
