export const HMS = (value) =>
  value == null || value == undefined || value == "" || value < 0
    ? "00:00:00"
    : (parseInt(value / 3600) >= 10
        ? parseInt(parseInt(value / 3600).toFixed(0))
        : (value / 3600).toString().split("-").length == 1
        ? "0" + parseInt(parseInt(value / 3600).toFixed(0))
        : parseInt((value / 3600).toString().split("-")[1]) >= 10
        ? "-" +
          parseInt(parseInt(value.toString().split("-")[1] / 3600).toFixed(0))
        : "-0" +
          parseInt(parseInt(value.toString().split("-")[1] / 3600)).toFixed(
            0
          )) +
      ":" +
      (parseInt(value % 3600) / 60 >= 10
        ? parseInt(parseInt(value % 3600) / 60).toFixed(0)
        : ((value % 3600) / 60).toString().split("-").length == 1
        ? "0" + parseInt(parseInt(value % 3600) / 60).toFixed(0)
        : (parseInt(value % 3600) / 60).toString().split("-")[1] >= 10
        ? "-" +
          parseInt(
            parseInt((value.toString().split("-")[1] % 3600) / 60).toFixed(0)
          )
        : "-0" +
          parseInt(
            parseInt((value.toString().split("-")[1] % 3600) / 60)
          ).toFixed(0)) +
      ":" +
      (parseInt(value % 60) >= 10
        ? parseInt(parseInt(value % 60).toFixed(0))
        : (value % 60).toString().split("-").length == 1
        ? "0" + parseInt(parseInt(value % 60)).toFixed(0)
        : parseInt(value % 60)
            .toString()
            .split("-")[1] >= 10
        ? "-" +
          parseInt(parseInt(value.toString().split("-")[1] % 60).toFixed(0))
        : "-0" +
          parseInt(parseInt(value.toString().split("-")[1] % 60)).toFixed(0));
export const HM = (value) =>
  value == null || value == undefined || value == "" || value < 0
    ? "00:00"
    : (parseInt(value / 3600) >= 10
        ? parseInt(parseInt(value / 3600).toFixed(0))
        : (value / 3600).toString().split("-").length == 1
        ? "0" + parseInt(parseInt(value / 3600).toFixed(0))
        : parseInt((value / 3600).toString().split("-")[1]) >= 10
        ? "-" +
          parseInt(parseInt(value.toString().split("-")[1] / 3600).toFixed(0))
        : "-0" +
          parseInt(parseInt(value.toString().split("-")[1] / 3600)).toFixed(
            0
          )) +
      ":" +
      (parseInt(value % 3600) / 60 >= 10
        ? parseInt(parseInt(value % 3600) / 60).toFixed(0)
        : ((value % 3600) / 60).toString().split("-").length == 1
        ? "0" + parseInt(parseInt(value % 3600) / 60).toFixed(0)
        : (parseInt(value % 3600) / 60).toString().split("-")[1] >= 10
        ? "-" +
          parseInt(
            parseInt((value.toString().split("-")[1] % 3600) / 60).toFixed(0)
          )
        : "-0" +
          parseInt(
            parseInt((value.toString().split("-")[1] % 3600) / 60)
          ).toFixed(0));
export const H = (value) =>
  value == null || value == undefined || value == "" || value < 0
    ? "00"
    : parseInt(value / 3600) >= 10
    ? parseInt(parseInt(value / 3600).toFixed(0))
    : (value / 3600).toString().split("-").length == 1
    ? "0" + parseInt(parseInt(value / 3600).toFixed(0))
    : parseInt((value / 3600).toString().split("-")[1]) >= 10
    ? "-" + parseInt(parseInt(value.toString().split("-")[1] / 3600).toFixed(0))
    : "-0" +
      parseInt(parseInt(value.toString().split("-")[1] / 3600)).toFixed(0);
export const MS = (value) =>
  value == null || value == undefined || value == "" || value < 0
    ? "00m 00s"
    : (parseInt(value % 3600) / 60 >= 10
        ? parseInt(parseInt(value % 3600) / 60).toFixed(0)
        : ((value % 3600) / 60).toString().split("-").length == 1
        ? "0" + parseInt(parseInt(value % 3600) / 60).toFixed(0)
        : (parseInt(value % 3600) / 60).toString().split("-")[1] >= 10
        ? "-" +
          parseInt(
            parseInt((value.toString().split("-")[1] % 3600) / 60).toFixed(0)
          )
        : "-0" +
          parseInt(
            parseInt((value.toString().split("-")[1] % 3600) / 60)
          ).toFixed(0)) +
      "m " +
      (parseInt(value % 60) >= 10
        ? parseInt(parseInt(value % 60).toFixed(0))
        : (value % 60).toString().split("-").length == 1
        ? "0" + parseInt(parseInt(value % 60)).toFixed(0)
        : parseInt(value % 60)
            .toString()
            .split("-")[1] >= 10
        ? "-" +
          parseInt(parseInt(value.toString().split("-")[1] % 60).toFixed(0))
        : "-0" +
          parseInt(parseInt(value.toString().split("-")[1] % 60)).toFixed(0)) +
      "s";

export const M = (value) =>
  value == null || value == undefined || value == "" || value < 0
    ? "00"
    : parseInt(value % 3600) / 60 >= 10
    ? parseInt(parseInt(value % 3600) / 60).toFixed(0)
    : ((value % 3600) / 60).toString().split("-").length == 1
    ? "0" + parseInt(parseInt(value % 3600) / 60).toFixed(0)
    : (parseInt(value % 3600) / 60).toString().split("-")[1] >= 10
    ? "-" +
      parseInt(
        parseInt((value.toString().split("-")[1] % 3600) / 60).toFixed(0)
      )
    : "-0" +
      parseInt(parseInt((value.toString().split("-")[1] % 3600) / 60)).toFixed(
        0
      );

export const S = (value) =>
  value == null || value == undefined || value == "" || value < 0
    ? "00"
    : parseInt(value % 60) >= 10
    ? parseInt(parseInt(value % 60).toFixed(0))
    : (value % 60).toString().split("-").length == 1
    ? "0" + parseInt(parseInt(value % 60)).toFixed(0)
    : parseInt(value % 60)
        .toString()
        .split("-")[1] >= 10
    ? "-" + parseInt(parseInt(value.toString().split("-")[1] % 60).toFixed(0))
    : "-0" + parseInt(parseInt(value.toString().split("-")[1] % 60)).toFixed(0);

export const hm = (value) => {
  if (value == null || value == undefined || value == "" || value < 0) {
    return "00:00";
  }

  let hours =
    parseInt(value / 3600) >= 10
      ? parseInt(parseInt(value / 3600).toFixed(0))
      : (value / 3600).toString().split("-").length == 1
      ? "0" + parseInt(parseInt(value / 3600).toFixed(0))
      : parseInt((value / 3600).toString().split("-")[1]) >= 10
      ? "-" +
        parseInt(parseInt(value.toString().split("-")[1] / 3600).toFixed(0))
      : "-0" +
        parseInt(parseInt(value.toString().split("-")[1] / 3600)).toFixed(0);

  if (hours >= 1000000) {
    hours = `${(hours / 1000000).toFixed(0)}M `;
  } else if (hours >= 1000) {
    hours = `${(hours / 1000).toFixed(0)}K `;
  }

  return (
    hours +
    "h " +
    (parseInt(value % 3600) / 60 >= 10
      ? parseInt(parseInt(value % 3600) / 60).toFixed(0)
      : ((value % 3600) / 60).toString().split("-").length == 1
      ? "0" + parseInt(parseInt(value % 3600) / 60).toFixed(0)
      : (parseInt(value % 3600) / 60).toString().split("-")[1] >= 10
      ? "-" +
        parseInt(
          parseInt((value.toString().split("-")[1] % 3600) / 60).toFixed(0)
        )
      : "-0" +
        parseInt(
          parseInt((value.toString().split("-")[1] % 3600) / 60)
        ).toFixed(0)) +
    "m "
  );
};

export function getHoursAndMinutesFromSeconds(seconds = 0) {
  try {
    const hours = Math.floor(seconds / 3600); // Get the number of whole hours
    const minutes = Math.floor((seconds % 3600) / 60); // Get the number of whole minutes remaining

    return `${hours}h ${minutes}m `;
  } catch (ex) {
    return `0h 0m `;
  }
}

export const getFormattedTime = (seconds) =>
  seconds >= 3600 ? hm(seconds) : MS(seconds);
