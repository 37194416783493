import * as actionTypes from "./actionTypes";

const initialPagination = { optional: 0 };

const initialState = {
  fetchLogsReportLoading: true,
  logsReportData: [],
  pagination: initialPagination,
  logReportErrorMessage: "",
  downloadLogsReportLoading: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_LOGS_REPORT:
      return {
        ...state,
        fetchLogsReportLoading: true,
        logReportErrorMessage: "",
      };

    case actionTypes.FETCH_LOGS_REPORT_SUCCESS: {
      return {
        ...state,
        fetchLogsReportLoading: false,
        logsReportData: action.payload.data,
        pagination: action.payload || state.pagination,
      };
    }

    case actionTypes.FETCH_LOGS_REPORT_FAILED:
      return {
        ...state,
        fetchLogsReportLoading: false,
        logsReportData: [],
        pagination: initialPagination,
        logReportErrorMessage: action.payload,
      };
    case actionTypes.DOWNLOAD_LOGS_REPORT:
      return {
        ...state,
        downloadLogsReportLoading: true,
        logReportErrorMessage: "",
      };

    case actionTypes.DOWNLOAD_LOGS_REPORT_SUCCESS:
      return {
        ...state,
        downloadLogsReportLoading: false,
      };

    case actionTypes.DOWNLOAD_LOGS_REPORT_FAILED:
      return {
        ...state,
        downloadLogsReportLoading: false,
        logReportErrorMessage: action.payload,
      };

    default:
      return state;
  }
};
