import React from "react";
import { Alert } from "antd";

import { useSelector } from "react-redux";
import DOMPurify from "isomorphic-dompurify";

const Banner = () => {
  //@ts-ignore
  const { bannerInfo } = useSelector((state) => state.tenantSettings);

  //@ts-ignore
  const { medetails_success } = useSelector((state) => state.users);

  return bannerInfo ? (
    <Alert
      className={medetails_success ? "ml-[220px]" : ""}
      type={bannerInfo?.type}
      message={
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(bannerInfo?.message) || "",
          }}
        />
      }
      banner
      closable
    />
  ) : (
    <></>
  );
};

export default Banner;
