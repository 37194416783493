//* REPORT MONTHLY IN OUT WIDGET *//

export const REPORT_MONTHLY_IN_OUT_INITIAL = "REPORT_MONTHLY_IN_OUT_INITIAL";
export const REPORT_MONTHLY_IN_OUT_PROCESS = "REPORT_MONTHLY_IN_OUT_PROCESS";
export const REPORT_MONTHLY_IN_OUT_SUCCESS = "REPORT_MONTHLY_IN_OUT_SUCCESS";
export const REPORT_MONTHLY_IN_OUT_FAILED = "REPORT_MONTHLY_IN_OUT_FAILED";

//*  REPORT MONTHLY IN OUT WIDGET End *//

//* REPORT MONTHLY IN OUT DOWNLOAD WIDGET *//

export const REPORT_MONTHLY_IN_OUT_DOWNLOAD_INITIAL =
  "REPORT_MONTHLY_IN_OUT_DOWNLOAD_INITIAL";
export const REPORT_MONTHLY_IN_OUT_DOWNLOAD_PROCESS =
  "REPORT_MONTHLY_IN_OUT_DOWNLOAD_PROCESS";
export const REPORT_MONTHLY_IN_OUT_DOWNLOAD_SUCCESS =
  "REPORT_MONTHLY_IN_OUT_DOWNLOAD_SUCCESS";
export const REPORT_MONTHLY_IN_OUT_DOWNLOAD_FAILED =
  "REPORT_MONTHLY_IN_OUT_DOWNLOAD_FAILED";
