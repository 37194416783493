import * as actionTypes from "./actionType";

/** REPORT MONTHLY ATTENDANCE  */

export const ReportMonthlyAttendanceInitial = () => {
  return {
    type: actionTypes.REPORT_MONTHLY_ATTENDANCE_INITIAL,
  };
};

export const ReportMonthlyAttendanceProcess = (token, bodyData) => {
  return {
    type: actionTypes.REPORT_MONTHLY_ATTENDANCE_PROCESS,
    token,
    bodyData,
  };
};

export const ReportMonthlyAttendanceSuccess = (data) => {
  return {
    type: actionTypes.REPORT_MONTHLY_ATTENDANCE_SUCCESS,
    data,
  };
};

export const ReportMonthlyAttendanceFailed = (err) => {
  return {
    type: actionTypes.REPORT_MONTHLY_ATTENDANCE_FAILED,
    err,
  };
};

/** REPORT DOWNLOAD MONTHLY ATTENDANCE  */

export const ReportDownloadMonthlyAttendanceInitial = () => {
  return {
    type: actionTypes.REPORT_DOWNLOAD_MONTHLY_ATTENDANCE_INITIAL,
  };
};

export const ReportDownloadMonthlyAttendanceProcess = (token, bodyData) => {
  return {
    type: actionTypes.REPORT_DOWNLOAD_MONTHLY_ATTENDANCE_PROCESS,
    token,
    bodyData,
  };
};

export const ReportDownloadMonthlyAttendanceSuccess = (data) => {
  return {
    type: actionTypes.REPORT_DOWNLOAD_MONTHLY_ATTENDANCE_SUCCESS,
    data,
  };
};

export const ReportDownloadMonthlyAttendanceFailed = (err) => {
  return {
    type: actionTypes.REPORT_DOWNLOAD_MONTHLY_ATTENDANCE_FAILED,
    err,
  };
};
