import * as actionTypes from "./actionTypes";
import _ from "lodash";

const initialState = {
  //*GLOBAL*//

  //* ACTIVITY SUMMARY WEDIGT *//

  activity_summary_wedigt_processing: false,
  activity_summary_wedigt_success: false,
  activity_summary_wedigt_failed: false,
  activity_summary_wedigt_data: [],
  activity_summary_wedigt_message: "",

  //* ACTIVITY SUMMARY ONLINE TIME BREAK DOWN *//

  activity_summary_online_time_break_down_processing: false,
  activity_summary_online_time_break_down_success: false,
  activity_summary_online_time_break_down_failed: false,
  activity_summary_online_time_break_down_data: [],
  activity_summary_online_time_break_down_message: "",

  //* ACTIVITY SUMMARY ACTIVITY LEVEL BREAK DOWN  *//

  activity_summary_activity_level_break_down_processing: false,
  activity_summary_activity_level_break_down_success: false,
  activity_summary_activity_level_break_down_failed: false,
  activity_summary_activity_level_break_down_data: [],
  activity_summary_activity_level_break_down_message: "",

  //* ACTIVITY SUMMARY TOP MOST ACTIVE TEAM  *//

  activity_summary_top_buttom_active_team_processing: false,
  activity_summary_top_buttom_active_team_success: false,
  activity_summary_top_buttom_active_team_failed: false,
  activity_summary_top_buttom_active_team_data: [],
  activity_summary_top_buttom_active_team_message: "",

  //* ACTIVITY SUMMARY TOP LEAST ACTIVE TEAM  *//

  activity_summary_top_least_active_team_processing: true,
  activity_summary_top_least_active_team_success: false,
  activity_summary_top_least_active_team_failed: false,
  activity_summary_top_least_active_team_data: [],
  activity_summary_top_least_active_team_message: "",

  //* ACTIVITY SUMMARY TEAM WISE ACTIVITY BREAK DOWN *//

  activity_summary_team_wise_activity_break_down_processing: true,
  activity_summary_team_wise_activity_break_down_success: false,
  activity_summary_team_wise_activity_break_down_failed: false,
  activity_summary_team_wise_activity_break_down_data: [],
  activity_summary_team_wise_activity_break_down_message: "",

  //* ACTIVITY SUMMARY WEDIGT *//

  activity_summary_app_url_processing: false,
  activity_summary_app_url_success: false,
  activity_summary_app_url_failed: false,
  activity_summary_app_url_data: [],
  activity_summary_app_url_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.ACTIVITY_SUMMARY_WEDIGT_INITIAL:
      return {
        ...state,
        activity_summary_wedigt_processing: true,
        activity_summary_wedigt_success: false,
        activity_summary_wedigt_failed: false,
        activity_summary_wedigt_data: [],
        activity_summary_wedigt_message: "",
      };

    case actionTypes.ACTIVITY_SUMMARY_WEDIGT_PROCESS:
      return {
        ...state,
        activity_summary_wedigt_processing: true,
        activity_summary_wedigt_success: false,
        activity_summary_wedigt_failed: false,
        activity_summary_wedigt_data: [],
        activity_summary_wedigt_message: "",
      };

    case actionTypes.ACTIVITY_SUMMARY_WEDIGT_SUCCESS:
      return {
        ...state,
        activity_summary_wedigt_processing: false,
        activity_summary_wedigt_success: true,
        activity_summary_wedigt_failed: false,
        activity_summary_wedigt_data: action.data,
        activity_summary_wedigt_message: "",
      };
    case actionTypes.ACTIVITY_SUMMARY_WEDIGT_FAILED:
      return {
        ...state,
        activity_summary_wedigt_processing: false,
        activity_summary_wedigt_success: false,
        activity_summary_wedigt_failed: true,
        activity_summary_wedigt_data: action.data,
        activity_summary_wedigt_message: "",
      };

    case actionTypes.ACTIVITY_SUMMARY_ONLINE_TIME_BREAK_DOWN_INITIAL:
      return {
        ...state,
        activity_summary_online_time_break_down_processing: false,
        activity_summary_online_time_break_down_success: false,
        activity_summary_online_time_break_down_failed: false,
        activity_summary_online_time_break_down_data: [],
        activity_summary_online_time_break_down_message: "",
      };

    case actionTypes.ACTIVITY_SUMMARY_ONLINE_TIME_BREAK_DOWN_PROCESS:
      return {
        ...state,
        activity_summary_online_time_break_down_processing: true,
        activity_summary_online_time_break_down_success: false,
        activity_summary_online_time_break_down_failed: false,
        activity_summary_online_time_break_down_data: [],
        activity_summary_online_time_break_down_message: "",
      };

    case actionTypes.ACTIVITY_SUMMARY_ONLINE_TIME_BREAK_DOWN_SUCCESS:
      return {
        ...state,
        activity_summary_online_time_break_down_processing: false,
        activity_summary_online_time_break_down_success: true,
        activity_summary_online_time_break_down_failed: false,
        activity_summary_online_time_break_down_data: action.data,
        activity_summary_online_time_break_down_message: "",
      };
    case actionTypes.ACTIVITY_SUMMARY_ONLINE_TIME_BREAK_DOWN_FAILED:
      return {
        ...state,
        activity_summary_online_time_break_down_processing: false,
        activity_summary_online_time_break_down_success: false,
        activity_summary_online_time_break_down_failed: true,
        activity_summary_online_time_break_down_data: [],
        activity_summary_online_time_break_down_message: action.data,
      };

    case actionTypes.ACTIVITY_SUMMARY_ACTIVITY_LEVEL_BREAK_DOWN_INITIAL:
      return {
        ...state,
        activity_summary_activity_level_break_down_processing: false,
        activity_summary_activity_level_break_down_success: false,
        activity_summary_activity_level_break_down_failed: false,
        activity_summary_activity_level_break_down_data: [],
        activity_summary_activity_level_break_down_message: "",
      };

    case actionTypes.ACTIVITY_SUMMARY_ACTIVITY_LEVEL_BREAK_DOWN_PROCESS:
      return {
        ...state,
        activity_summary_activity_level_break_down_processing: true,
        activity_summary_activity_level_break_down_success: false,
        activity_summary_activity_level_break_down_failed: false,
        activity_summary_activity_level_break_down_data: [],
        activity_summary_activity_level_break_down_message: "",
      };

    case actionTypes.ACTIVITY_SUMMARY_ACTIVITY_LEVEL_BREAK_DOWN_SUCCESS:
      return {
        ...state,
        activity_summary_activity_level_break_down_processing: false,
        activity_summary_activity_level_break_down_success: true,
        activity_summary_activity_level_break_downfailed: false,
        activity_summary_activity_level_break_down_data: action.data,
        activity_summary_activity_level_break_down_message: "",
      };
    case actionTypes.ACTIVITY_SUMMARY_ACTIVITY_LEVEL_BREAK_DOWN_FAILED:
      return {
        ...state,
        activity_summary_activity_level_break_down_processing: false,
        activity_summary_activity_level_break_downsuccess: false,
        activity_summary_activity_level_break_down_failed: true,
        activity_summary_activity_level_break_downdata: action.data,
        activity_summary_activity_level_break_down_message: "",
      };

    case actionTypes.ACTIVITY_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_INITIAL:
      return {
        ...state,
        activity_summary_top_buttom_active_team_processing: false,
        activity_summary_top_buttom_active_team_success: false,
        activity_summary_top_buttom_active_team_failed: false,
        activity_summary_top_buttom_active_team_data: [],
        activity_summary_top_buttom_active_team_message: "",
      };

    case actionTypes.ACTIVITY_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_PROCESS:
      return {
        ...state,
        activity_summary_top_buttom_active_team_processing: true,
        activity_summary_top_buttom_active_team_success: false,
        activity_summary_top_buttom_active_team_failed: false,
        activity_summary_top_buttom_active_team_data: [],
        activity_summary_top_buttom_active_team_message: "",
      };

    case actionTypes.ACTIVITY_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_SUCCESS:
      return {
        ...state,
        activity_summary_top_buttom_active_team_processing: false,
        activity_summary_top_buttom_active_team_success: true,
        activity_summary_top_buttom_active_team_failed: false,
        activity_summary_top_buttom_active_team_data: action.data,
        activity_summary_top_buttom_active_team_message: "",
      };
    case actionTypes.ACTIVITY_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_FAILED:
      return {
        ...state,
        activity_summary_top_buttom_active_team_processing: false,
        activity_summary_top_buttom_active_team_success: false,
        activity_summary_top_buttom_active_team_failed: true,
        activity_summary_top_buttom_active_team_data: [],
        activity_summary_top_buttom_active_team_message: action.data,
      };
    case actionTypes.ACTIVITY_SUMMARY_TOP_LEAST_ACTIVE_TEAM_INITIAL:
      return {
        ...state,
        activity_summary_top_least_Active_team_processing: true,
        activity_summary_top_least_Active_team_success: false,
        activity_summary_top_least_Active_team_failed: false,
        activity_summary_top_least_Active_team_data: [],
        activity_summary_top_least_Active_team_message: "",
      };

    case actionTypes.ACTIVITY_SUMMARY_TOP_LEAST_ACTIVE_TEAM_PROCESS:
      return {
        ...state,
        activity_summary_top_least_Active_team_processing: true,
        activity_summary_top_least_Active_team_success: false,
        activity_summary_top_least_Active_team_failed: false,
        activity_summary_top_least_Active_team_data: [],
        activity_summary_top_least_Active_team_message: "",
      };

    case actionTypes.ACTIVITY_SUMMARY_TOP_LEAST_ACTIVE_TEAM_SUCCESS:
      return {
        ...state,
        activity_summary_top_least_Active_team_processing: false,
        activity_summary_top_least_Active_team_success: true,
        activity_summary_top_least_Active_teamfailed: false,
        activity_summary_top_least_Active_team_data: action.data,
        activity_summary_top_least_Active_team_message: "",
      };
    case actionTypes.ACTIVITY_SUMMARY_TOP_LEAST_ACTIVE_TEAM_FAILED:
      return {
        ...state,
        activity_summary_top_least_Active_team_processing: false,
        activity_summary_top_least_Active_team_success: false,
        activity_summary_top_least_Active_team_failed: true,
        activity_summary_top_least_Active_team_data: action.data,
        activity_summary_top_least_Active_team_message: "",
      };

    case actionTypes.ACTIVITY_SUMMARY_TEAM_WISE_ACTIVITY_BREAK_DOWN_INITIAL:
      return {
        ...state,
        activity_summary_team_wise_activity_break_down_processing: false,
        activity_summary_team_wise_activity_break_down_success: false,
        activity_summary_team_wise_activity_break_down_failed: false,
        activity_summary_team_wise_activity_break_down_data: [],
        activity_summary_team_wise_activity_break_down_message: "",
      };

    case actionTypes.ACTIVITY_SUMMARY_TEAM_WISE_ACTIVITY_BREAK_DOWN_PROCESS:
      return {
        ...state,
        activity_summary_team_wise_activity_break_down_processing: true,
        activity_summary_team_wise_activity_break_down_success: false,
        activity_summary_team_wise_activity_break_down_failed: false,
        activity_summary_team_wise_activity_break_down_data: [],
        activity_summary_team_wise_activity_break_down_message: "",
      };

    case actionTypes.ACTIVITY_SUMMARY_TEAM_WISE_ACTIVITY_BREAK_DOWN_SUCCESS:
      return {
        ...state,
        activity_summary_team_wise_activity_break_down_processing: false,
        activity_summary_team_wise_activity_break_down_success: true,
        activity_summary_team_wise_activity_break_down_failed: false,
        activity_summary_team_wise_activity_break_down_data: action.data,
        activity_summary_team_wise_activity_break_down_message: "",
      };
    case actionTypes.ACTIVITY_SUMMARY_TEAM_WISE_ACTIVITY_BREAK_DOWN_FAILED:
      return {
        ...state,
        activity_summary_team_wise_activity_break_down_processing: false,
        activity_summary_team_wise_activity_break_down_success: false,
        activity_summary_team_wise_activity_break_down_failed: true,
        activity_summary_team_wise_activity_break_down_data: action.data,
        activity_summary_team_wise_activity_break_down_message: "",
      };

    case actionTypes.ACTIVITY_SUMMARY_TOP_APPLICATION_URL_INITIAL:
      return {
        ...state,
        activity_summary_app_url_processing: false,
        activity_summary_app_url_success: false,
        activity_summary_app_url_failed: false,
        activity_summary_app_url_data: [],
        activity_summary_app_url_message: "",
      };

    case actionTypes.ACTIVITY_SUMMARY_TOP_APPLICATION_URL_PROCESS:
      return {
        ...state,
        activity_summary_app_url_processing: true,
        activity_summary_app_url_success: false,
        activity_summary_app_url_failed: false,
        activity_summary_app_url_data: [],
        activity_summary_app_url_message: "",
      };

    case actionTypes.ACTIVITY_SUMMARY_TOP_APPLICATION_URL_SUCCESS:
      return {
        ...state,
        activity_summary_app_url_processing: false,
        activity_summary_app_url_success: true,
        activity_summary_app_url_failed: false,
        activity_summary_app_url_data: action.data,
        activity_summary_app_url_message: "",
      };
    case actionTypes.ACTIVITY_SUMMARY_TOP_APPLICATION_URL_FAILED:
      return {
        ...state,
        activity_summary_app_url_processing: false,
        activity_summary_app_url_success: false,
        activity_summary_app_url_failed: true,
        activity_summary_app_url_data: [],
        activity_summary_app_url_message: action.data,
      };

    default:
      return state;
  }
};
