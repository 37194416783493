export const FETCH_INTEGRATIONS = "FETCH_INTEGRATIONS";
export const FETCH_INTEGRATIONS_SUCCESS = "FETCH_INTEGRATIONS_SUCCESS";
export const FETCH_INTEGRATIONS_FAILED = "FETCH_INTEGRATIONS_FAILED";

export const CREATE_INTEGRATION = "CREATE_INTEGRATION";
export const CREATE_INTEGRATION_SUCCESS = "CREATE_INTEGRATION_SUCCESS";
export const CREATE_INTEGRATION_FAILED = "CREATE_INTEGRATION_FAILED";

export const DELETE_INTEGRATION = "DELETE_INTEGRATION";
export const DELETE_INTEGRATION_SUCCESS = "DELETE_INTEGRATION_SUCCESS";
export const DELETE_INTEGRATION_FAILED = "DELETE_INTEGRATION_FAILED";

export const UPDATE_INTEGRATION = "UPDATE_INTEGRATION";
export const UPDATE_INTEGRATION_SUCCESS = "UPDATE_INTEGRATION_SUCCESS";
export const UPDATE_INTEGRATION_FAILED = "UPDATE_INTEGRATION_FAILED";

export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";
