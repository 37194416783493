import * as actionTypes from "./actionTypes";

export const fetchProjectsReport = (payload) => {
  return {
    type: actionTypes.FETCH_PROJECTS_REPORT,
    payload,
  };
};

export const fetchProjectsReportSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_PROJECTS_REPORT_SUCCESS,
    payload,
  };
};

export const fetchProjectsReportFailed = (payload) => {
  return {
    type: actionTypes.FETCH_PROJECTS_REPORT_FAILED,
    payload,
  };
};

export const downloadProjectsReport = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_PROJECTS_REPORT,
    payload,
  };
};

export const downloadProjectsReportSuccess = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_PROJECTS_REPORT_SUCCESS,
    payload,
  };
};

export const downloadProjectsReportFailed = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_PROJECTS_REPORT_FAILED,
    payload,
  };
};
