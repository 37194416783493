import dayjs from "dayjs";

const dateDifferenceTextDisplay = (start, end) => {
  dayjs.locale("en"); // Set the locale

  const today = dayjs().startOf("day");
  const yesterday = dayjs().subtract(1, "day").startOf("day");

  if (start && end && start.isSame(end, "day")) {
    if (start.isSame(dayjs("2024-03-28"), "day")) {
      return "For 28 March 2024";
    } else if (start.isSame(today, "day")) {
      return "For Today";
    } else if (start.isSame(yesterday, "day")) {
      return "For Yesterday";
    } else {
      return `For ${start.format("D MMMM YYYY")}`;
    }
  } else {
    if (
      start &&
      end &&
      (start.isSame(today, "day") || end.isSame(today, "day"))
    ) {
      const delta = end ? end.diff(start, "day") + 1 : 0;
      return `For the last ${delta} days`;
    } else {
      return "For the selected range";
    }
  }
};
export default dateDifferenceTextDisplay;
