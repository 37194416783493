import React from "react";

export const Attendance = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M4.7,10.9c-2,0-3.6,1.6-3.6,3.6c0,2,1.6,3.6,3.6,3.6s3.6-1.6,3.6-3.6C8.2,12.5,6.6,10.9,4.7,10.9z" />
      <path d="M10,2C8,2,6.4,3.6,6.4,5.6c0,2,1.6,3.6,3.6,3.6c2,0,3.6-1.6,3.6-3.6C13.6,3.6,12,2,10,2z" />
      <path d="M15.3,10.9c-2,0-3.6,1.6-3.6,3.6c0,2,1.6,3.6,3.6,3.6c2,0,3.6-1.6,3.6-3.6C18.9,12.5,17.3,10.9,15.3,10.9z" />
    </svg>
  );
};
