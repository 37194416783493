//GET BREAKS

import * as actionTypes from "./actionTypes";

export const BreaksListInitial = (token) => {
  return {
    type: actionTypes.BREAKS_LIST_INITIAL,
    token,
  };
};

export const BreaksListProcess = (data) => {
  return {
    type: actionTypes.BREAKS_LIST_PROCESS,
    data,
  };
};
export const BreaksListSuccess = (data) => {
  return {
    type: actionTypes.BREAKS_LIST_SUCCESS,
    data,
  };
};
export const BreaksListFailed = (data) => {
  return {
    type: actionTypes.BREAKS_LIST_FAILED,
    data,
  };
};

export const BreaksListSearch = (data) => {
  return {
    type: actionTypes.BREAKS_LIST_SEARCH,
    data,
  };
};

//CREATE BREAKS

export const BreaksCreateInitial = (data, token) => {
  return {
    type: actionTypes.BREAKS_CREATE_INITIAL,
    data,
    token,
  };
};
export const BreaksCreateProcess = (data) => {
  return {
    type: actionTypes.BREAKS_CREATE_PROCESS,
    data,
  };
};
export const BreaksCreateSuccess = (data) => {
  return {
    type: actionTypes.BREAKS_CREATE_SUCCESS,
    data,
  };
};
export const BreaksCreateFailed = (data) => {
  return {
    type: actionTypes.BREAKS_CREATE_SUCCESS,
    data,
  };
};

//EDIT BREAKS

export const BreaksEditInitial = () => {
  return {
    type: actionTypes.BREAKS_EDIT_INITIAL,
  };
};
export const BreaksEditProcess = (data) => {
  return {
    type: actionTypes.BREAKS_EDIT_PROCESS,
    data,
  };
};
export const BreaksEditSuccess = (data) => {
  return {
    type: actionTypes.BREAKS_EDIT_SUCCESS,
    data,
  };
};
export const BreaksEditFailed = (data) => {
  return {
    type: actionTypes.BREAKS_EDIT_FAILED,
    data,
  };
};
