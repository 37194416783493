import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { NavLink, useNavigate } from "react-router-dom";
import { Layout, Menu } from "antd";

import { SettingsM } from "./../../components/icons/setting_m";
import { Attendance } from "./../../components/icons/attendance";
import { Dashboard } from "./../../components/icons/dashboard";
import { Wellness } from "./../../components/icons/wellness";
import { Livestream } from "./../../components/icons/livestream";
import { Activity } from "./../../components/icons/activity";
import { Productivity } from "./../../components/icons/productivity";
import { Timeline } from "./../../components/icons/timeline";
import { Application } from "./../../components/icons/application";
import { Reports } from "./../../components/icons/reports";
import { Project } from "./../../components/icons/project";
import logolight from "./../../assets/images/logo-light.png";
import { Alerts } from "components/icons/alerts";
import { TbLiveView } from "react-icons/tb";
import { MdScreenshotMonitor } from "react-icons/md";
import { Realtime } from "components/icons/RealTime";
import { Analytics } from "components/icons/Analytics";
import { Leave } from "components/icons/leave";

import "./index.scss";
import {
  ACTIVITY,
  ALERTS,
  APPLICATION,
  ATTENDANCE,
  DASHBOARD,
  LIVESTREAM,
  ME,
  PRODUCTIVITY,
  REPORTS,
  SETTINGS,
  TIMELINE,
  WELLNESS,
  PROJECTS,
  FIELD,
  SCREENSHOT,
  NOTEBOOK,
  LEAVE_MANAGEMENT,
  DEVICE360,
  GOALS,
  MANUALTIME,
} from "route/constant";
import { permissionsMap } from "constants/constant";
import { AuthContext } from "contexts/authContext";
import { AiOutlineUser } from "react-icons/ai";
import { RiMenuFoldFill, RiMenuUnfoldFill } from "react-icons/ri";
import { GiNotebook } from "react-icons/gi";
import { getLogoUrl } from "utils/transport";
import { Deviceinfo } from "components/icons/deviceinfo";
import { Goals } from "components/icons/Goals";
import { ManualTime } from "../../components/icons/manualTime";

const { Sider } = Layout;

const Sidebar = (props) => {
  const logoRef = useRef(null);
  const { silderBtn } = props;
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();

  const {
    checkRequiredPermissions,
    coreApiUrl,
    setCookies,
    checkRequiredAccess,
    // collapsed,setCollapsed
  } = useContext(AuthContext);

  const clickSilder = (val) => {
    setCollapsed(val);
    silderBtn(val);
  };

  const getFilteredMenuItems = (item) =>
    checkRequiredPermissions(item.requiredPermissions) &&
    (!item.path || checkRequiredAccess(item.path, true)) &&
    (!item.children || item.children.length > 0);

  const items = [
    // {
    //   path: "/some-path",
    //   label: "a menu item",
    //   icon: <Icon type="fire" />,
    // },
    {
      icon: <Dashboard />,
      label: "Dashboard",
      path: DASHBOARD,
      key: "dashboard",
      requiredPermissions: [
        permissionsMap.TENANT_MANAGER,
        permissionsMap.TENANT_OWNER,
      ],
    },
    {
      path: ATTENDANCE,
      icon: <Attendance />,
      label: "Attendance",
      key: "attendance",
      requiredPermissions: [
        permissionsMap.TENANT_MANAGER,
        permissionsMap.TENANT_OWNER,
      ],
    },
    {
      icon: <Realtime />,
      label: "Real Time",
      key: "realtime",
      requiredPermissions: [
        permissionsMap.TENANT_MANAGER,
        permissionsMap.TENANT_OWNER,
      ],
      children: [
        {
          path: LIVESTREAM,
          icon: <Livestream viewBox="0 0 24 24" />,
          label: "Livestream",
          key: "livestream",
          requiredPermissions: [
            permissionsMap.TENANT_MANAGER,
            permissionsMap.TENANT_OWNER,
          ],
        },
        {
          path: FIELD,
          icon: <TbLiveView className="w-[18px] mr-3.5" />,
          key: "field",
          label: "Field",
          requiredPermissions: [
            permissionsMap.TENANT_MANAGER,
            permissionsMap.TENANT_OWNER,
          ],
        },
      ].filter(getFilteredMenuItems),
    },
    {
      icon: <Analytics />,
      label: "Analytics",
      key: "analytics",
      requiredPermissions: [
        permissionsMap.TENANT_MANAGER,
        permissionsMap.TENANT_OWNER,
      ],
      children: [
        {
          path: TIMELINE,
          icon: <Timeline viewBox="0 0 24 24" />,
          label: "Timeline",
          key: "timeline",
          requiredPermissions: [
            permissionsMap.TENANT_MANAGER,
            permissionsMap.TENANT_OWNER,
          ],
        },
        {
          path: ACTIVITY,
          icon: <Activity viewBox="0 0 24 24" />,
          label: "Activity",
          key: "activity",
          requiredPermissions: [
            permissionsMap.TENANT_MANAGER,
            permissionsMap.TENANT_OWNER,
          ],
        },
        {
          path: PRODUCTIVITY,
          icon: <Productivity viewBox="0 0 24 24" />,
          label: "Productivity",
          key: "productivity",
          requiredPermissions: [
            permissionsMap.TENANT_MANAGER,
            permissionsMap.TENANT_OWNER,
          ],
        },
        {
          path: GOALS,
          icon: <Goals viewBox="0 0 24 24" />,
          label: "Goals",
          key: "goals",
          requiredPermissions: [
            permissionsMap.TENANT_MANAGER,
            permissionsMap.TENANT_OWNER,
          ],
        },
        {
          path: SCREENSHOT,
          icon: <MdScreenshotMonitor className="w-[18px] mr-3.5" />,
          label: "Screenshots",
          key: "screenshots",
          requiredPermissions: [
            permissionsMap.TENANT_MANAGER,
            permissionsMap.TENANT_OWNER,
          ],
        },
        {
          path: APPLICATION,
          icon: <Application viewBox="0 0 24 24" />,
          label: "Apps & URLs",
          key: "app&url",
          requiredPermissions: [
            permissionsMap.TENANT_MANAGER,
            permissionsMap.TENANT_OWNER,
          ],
        },
        {
          path: WELLNESS,
          icon: <Wellness viewBox="0 0 24 24" />,
          label: "Wellness",
          key: "wellness",
          requiredPermissions: [
            permissionsMap.TENANT_MANAGER,
            permissionsMap.TENANT_OWNER,
          ],
        },
      ].filter(getFilteredMenuItems),
    },
    {
      path: DEVICE360,
      icon: <Deviceinfo viewBox="0 0 24 24" />,
      key: "device360",
      label: "Devices",
      requiredPermissions: [
        permissionsMap.TENANT_MANAGER,
        permissionsMap.TENANT_OWNER,
      ],
    },
    {
      path: MANUALTIME,
      icon: <ManualTime />,
      label: "Manual Time",
      key: "leaves",
      requiredPermissions: [
        permissionsMap.TENANT_MANAGER,
        permissionsMap.TENANT_OWNER,

        permissionsMap.TENANT_USER,
        permissionsMap.IAM_MANAGE_ALL,
      ],
    },
    {
      path: ALERTS,
      icon: <Alerts />,
      label: "Alerts",
      key: "alerts",
      requiredPermissions: [permissionsMap.TENANT_OWNER],
    },

    {
      path: REPORTS,
      icon: <Reports />,
      label: "Reports",
      key: "reports",
      requiredPermissions: [
        permissionsMap.TENANT_MANAGER,
        permissionsMap.TENANT_OWNER,
      ],
    },
    {
      path: PROJECTS,
      icon: <Project />,
      label: "Projects",
      key: "project",
      requiredPermissions: [permissionsMap.TENANT_USER],
    },

    {
      path: LEAVE_MANAGEMENT,
      icon: <Leave />,
      label: "Leaves",
      key: "leaves",
      requiredPermissions: [
        permissionsMap.TENANT_MANAGER,
        permissionsMap.TENANT_OWNER,
        permissionsMap.TENANT_USER,
        permissionsMap.IAM_MANAGE_ALL,
      ],
    },
    {
      path: NOTEBOOK,
      icon: <GiNotebook size={20} />,
      label: "Notebook",
      key: "notebook",
      requiredPermissions: [permissionsMap.TENANT_USER],
    },
    {
      path: ME,
      icon: <AiOutlineUser size={20} />,
      label: !checkRequiredPermissions([
        permissionsMap.TENANT_OWNER,
        permissionsMap.TENANT_MANAGER,
      ])
        ? "Me"
        : "User Detail",
      key: "me",
      requiredPermissions: [permissionsMap.TENANT_USER],
    },
    {
      path: SETTINGS,
      icon: <SettingsM />,
      label: "Settings",
      key: "settings",
      requiredPermissions: [
        permissionsMap.TENANT_OWNER,
        permissionsMap.IAM_MANAGE_ALL,
        permissionsMap.IAM_TEAM_MANAGE,
      ],
    },
  ].filter(getFilteredMenuItems);

  // Function to render menu items (including submenus if they exist)
  const renderMenuItem = (item) => {
    // If the item has children, render it as a submenu
    if (item.children && item.children.length > 1) {
      const submenuItems = item.children.map((subItem) =>
        renderMenuItem(subItem)
      );
      return (
        <Menu.SubMenu
          key={item.key}
          title={item.label}
          icon={item.icon}
          popupClassName="submenu-item"
        >
          {submenuItems}
        </Menu.SubMenu>
      );
    }

    // Otherwise, render it as a regular menu item
    return item.children && item.children.length === 1 ? (
      <Menu.Item key={item.children[0].key} icon={item.children[0].icon}>
        <NavLink to={item.children[0].path}>{item.children[0].label}</NavLink>
      </Menu.Item>
    ) : (
      <Menu.Item key={item.key} icon={item.icon}>
        <NavLink to={item.path}>{item.label}</NavLink>
      </Menu.Item>
    );
  };

  const onLogoError = () => {
    setCookies();
    if (logoRef.current) {
      logoRef.current.src = logolight;
    }
  };

  useEffect(() => {
    if (
      !checkRequiredAccess(
        `/${window.location.pathname.split("/")[1]}`,
        true
      ) &&
      items.length > 0
    ) {
      navigate(items[0].path);
    }
  }, [window.location.pathname]);

  return (
    <Sider
      trigger={null}
      collapsed={collapsed}
      className="aside_slid"
      width={"220px"}
      collapsible
    >
      <div
        className="sider-logo mainlogo"
        style={{ "background-color": "#0e082e", cursor: "pointer" }}
      >
        <img
          className="max-h-12 w-100%"
          ref={logoRef}
          src={
            checkRequiredAccess("whiteLabelling")
              ? `${getLogoUrl(coreApiUrl)}`
              : logolight
          }
          onError={onLogoError}
        />

        {collapsed ? (
          <div
            style={{
              color: "white",
              float: "right",
              paddingLeft: "5px",
              fontSize: "25px",
            }}
          >
            <RiMenuUnfoldFill onClick={() => clickSilder(!collapsed)} />
          </div>
        ) : (
          <div
            style={{
              color: "white",
              float: "right",
              margin: "0 -20px 0 0",
              fontSize: "25px",
            }}
          >
            <RiMenuFoldFill onClick={() => clickSilder(!collapsed)} />
          </div>
        )}
      </div>

      <div className="side_content">
        <Menu
          mode="inline"
          className="menu-item"
          selectable={true}
          selectedKeys={[]}
        >
          {items.map((item) => renderMenuItem(item))}
        </Menu>
      </div>
    </Sider>
  );
};

Sidebar.propTypes = {
  showBackground: PropTypes.bool,
  silderBtn: PropTypes.bool,
};

export default Sidebar;
