//LEAVE_TYPE STARTS
export const LEAVE_TYPE_LIST_INITIAL = "LEAVE_TYPE_LIST_INITIAL";
export const LEAVE_TYPE_LIST_PROCESS = "LEAVE_TYPE_LIST_PROCESS";
export const LEAVE_TYPE_LIST_SUCCESS = "LEAVE_TYPE_LIST_SUCCESS";
export const LEAVE_TYPE_LIST_FAILED = "LEAVE_TYPE_LIST_FAILED";

export const LEAVE_TYPE_CREATE_INITIAL = "LEAVE_TYPE_CREATE_INITIAL ";
export const LEAVE_TYPE_CREATE_PROCESS = "LEAVE_TYPE_CREATE_PROCESS ";
export const LEAVE_TYPE_CREATE_SUCCESS = "LEAVE_TYPE_CREATE_SUCCESS";
export const LEAVE_TYPE_CREATE_FAILED = "LEAVE_TYPE_CREATE_FAILED";

export const LEAVE_TYPE_EDIT_INITIAL = "LEAVE_TYPE_EDIT_INITIAL ";
export const LEAVE_TYPE_EDIT_PROCESS = "LEAVE_TYPE_EDIT_PROCESS ";
export const LEAVE_TYPE_EDIT_SUCCESS = "LEAVE_TYPE_EDIT_SUCCESS";
export const LEAVE_TYPE_EDIT_FAILED = "LEAVE_TYPE_EDIT_FAILED";

// LEAVE_TYPE END
