import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import "../index.scss";
import { FiArrowDownRight, FiArrowUpRight } from "react-icons/fi";
import { hm, hms } from "../../../utils/timeConverter";
import {
  Skeleton,
  Calendar,
  theme,
  Badge,
  Avatar,
  Tooltip,
  List,
  Divider,
  Spin,
  notification,
} from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { AttendanceDetailedAction } from "redux/store/action";
import { AuthContext } from "contexts/authContext";
import GetParamers from "utils/getParamers";
import _ from "lodash";
import UserIO from "../../../assets/images/userIO.svg";
import SystemIO from "../../../assets/images/systemIO.svg";
import ClientAuto from "../../../assets/images/idle-punch-out.svg";
import dayjs from "dayjs";
const Cardskeleton = () => {
  return (
    <>
      <div className="att_profile">
        <div className="a_p_block">
          <Skeleton.Avatar
            size={"small"}
            shape={"circle"}
            style={{ height: "40px", width: "40px", marginRight: "15px" }}
          />
          <div className="at_block">
            <div className="a_text">
              {" "}
              <Skeleton.Input
                size={"small"}
                shape={"default"}
                style={{ height: "14px" }}
              />
            </div>
            <div className="a_mail">
              <Skeleton.Input
                size={"small"}
                shape={"default"}
                style={{ height: "14px" }}
              />{" "}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Skeleton.Input
          size={"small"}
          shape={"default"}
          style={{ height: "355px", width: "100%" }}
        />
      </div>
    </>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

const AttendaceCalender = (props) => {
  const { dataemployee, open, type } = props || {};
  const { token } = theme.useToken();
  const attendancedetailed = useSelector((state) => state.attendancedetailed);
  const [SelectedMonth, setSelectedMonth] = useState();
  const [SelectedYear, setSelectedYear] = useState();
  const [selectedDate, setSelectedDate] = useState(null);
  const { KState } = useContext(AuthContext);
  const groupIds = [];
  const dispatch = useDispatch();

  useEffect(() => {
    if (!open) setSelectedDate(null);
    if (!type) {
      const current = moment().format("YYYY-MM-DD");
      dispatch(
        AttendanceDetailedAction.AttendanceDetailedEmployeePunchLogListProcess(
          // datacurrent
          KState.keycloak.token,
          GetParamers(current, current, groupIds, [dataemployee.identity_id])
        )
      );
    }
  }, [open]);

  const wrapperStyle = {
    minWidth: 360,
    border: `1px solid ${token.colorBorderSecondary}`,
    borderRadius: token.borderRadiusLG,
  };

  const [loading, setLoading] = useState(true);
  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const getListData = (value, filterDate) => {
    let listData = [];
    const dataFilter = _.find(
      value,
      (itm) => moment(itm.date).format("YYYY-MM-DD") === filterDate
    );
    // try {
    if (dataFilter !== undefined) {
      listData.push({
        type:
          dataFilter.workplace === "minimum_presence"
            ? "minpresence"
            : dataFilter.workplace === "half_day"
            ? "Halfday"
            : dataFilter.workplace === "full_day"
            ? "Fullday"
            : dataFilter.att_status === "WE" ||
              dataFilter.att_status === "WEEK OFF"
            ? "Weeklyoff"
            : dataFilter.workplace === "absent"
            ? "nabsent"
            : dataFilter.att_status === "H"
            ? "Holiday"
            : "",
        duration:
          dataFilter.punch_duration != null
            ? hm(dataFilter.punch_duration)
            : "00h 00m ",
      });
    } else {
      listData = [];
    }

    return listData || [];
  };

  const dateCellRender = (value) => {
    const current = moment().format("YYYY-MM-DD");
    let isSelected =
      open === true && selectedDate !== null
        ? value.isSame(selectedDate, "day")
        : null;
    let isCurrent =
      open === true && current !== null && selectedDate === null
        ? value.isSame(current, "day")
        : null;

    const datamap =
      attendancedetailed &&
      attendancedetailed.attendance_detailed_attendance_trend_data &&
      attendancedetailed.attendance_detailed_attendance_trend_data.length > 0 &&
      attendancedetailed.attendance_detailed_attendance_trend_data[0].logs &&
      attendancedetailed.attendance_detailed_attendance_trend_data[0].logs;

    const listData = getListData(datamap, value.format("YYYY-MM-DD"));
    if (!_.first(listData)) {
      return null;
    }
    if (
      _.first(listData).type === "minpresence" ||
      _.first(listData).type === "Halfday" ||
      _.first(listData).type === "Fullday"
    ) {
      return (
        <ul
          className={
            isSelected || isCurrent
              ? "cal_legend_calendar selected"
              : "cal_legend_calendar"
          }
        >
          {listData.map((item) => (
            <Tooltip
              title={
                item.type === "minpresence"
                  ? "<Min.presence" + " | Duration :" + " " + item.duration
                  : item.type + " | Duration :" + " " + item.duration
              }
            >
              <li key={item.content}>
                <Badge status={item.type} />
              </li>
            </Tooltip>
          ))}
        </ul>
      );
    } else {
      return (
        <ul className="cal_legend_calendar">
          {listData.map((item) => (
            <Tooltip title={item.type === "nabsent" ? "Absent" : item.type}>
              <li key={item.content}>
                <Badge status={item.type} />
              </li>
            </Tooltip>
          ))}
        </ul>
      );
    }
  };

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    if (name != undefined) {
      const username = name.replace(/\s+/g, " ");
      const lnt = username.toString().split(" ").length;
      const Firstname = username.split(" ")[0];
      const Lastname = lnt > 1 ? username.split(" ")[lnt - 1] : "-";
      const Av1 = Firstname[0].toString().toUpperCase();
      const Av2 =
        Lastname !== undefined &&
        Lastname !== " " &&
        Lastname !== "" &&
        Lastname !== "-"
          ? Lastname[0].toString().toUpperCase()
          : "";
      const finalName = Av1 + (Av2 !== "" ? Av2 : "");

      return {
        style: {
          background: stringToColor(name),
          width: "40px",
          height: "40px",
          fontSize: "15px",
        },
        children: finalName,
      };
    }
  }
  const getMonthData = (value) => {
    if (value.month() === 8) {
      return 1394;
    }
  };
  const monthCellRender = (value) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };
  const cellRender = (current, info) => {
    if (info.type === "date") return dateCellRender(current);
    if (info.type === "month") return monthCellRender(current);
    return info.originNode;
  };

  const getMonthDates = (d) => {
    const current = moment();
    const dateObj = moment(d, "YYYY-MM-DD");
    let changeType = "DATE";
    if (SelectedMonth === dateObj.month() && SelectedYear === dateObj.year()) {
      changeType = "DATE";
    } else {
      changeType = "MONTH";
    }
    setSelectedMonth(dateObj.month());
    setSelectedYear(dateObj.year());
    if (
      dateObj.month() === current.month() &&
      dateObj.year() === current.year()
    ) {
      const startDate = dateObj.startOf("month").format("YYYY-MM-DD");
      const endDate = current.format("YYYY-MM-DD");
      return { startDate, endDate, changeType };
    } else if (
      dateObj.year() < current.year() ||
      (dateObj.month() < current.month() && dateObj.year() === current.year())
    ) {
      const startDate = dateObj.startOf("month").format("YYYY-MM-DD");
      const endDate = dateObj.endOf("month").format("YYYY-MM-DD");
      return { startDate, endDate, changeType };
    } else {
      // notification.error({
      //   message: "Uh Oh!",
      //   description: "Future month not allowed",
      // });
      return null;
    }
  };
  const disabledDate = (current) => {
    // Get the current date
    const today = new Date();
    // Set the time to midnight to compare only the date portion
    today.setHours(23, 59, 59, 0);

    // Disable dates if they are after today
    return current && current > today;
  };
  const openPunchLogDateChange = (data) => {
    setSelectedDate(data);

    if (data !== undefined) {
      const monthData = getMonthDates(data);
      dispatch(
        AttendanceDetailedAction.AttendanceDetailedEmployeePunchLogListProcess(
          // datacurrent
          KState.keycloak.token,
          GetParamers(data, data, groupIds, [dataemployee.identity_id])
        )
      );
      if (monthData) {
        if (monthData.changeType === "DATE") {
          dispatch(
            AttendanceDetailedAction.AttendanceDetailedEmployeePunchLogListProcess(
              // datacurrent
              KState.keycloak.token,
              GetParamers(data, data, groupIds, [dataemployee.identity_id])
            )
          );
        } else {
          dispatch(
            AttendanceDetailedAction.AttendanceDetailedEmployeePunchLogListInitial()
          );
          dispatch(
            AttendanceDetailedAction.AttendanceDetailedAttendanceTrendProcess(
              // datacurrent1
              KState.keycloak.token,
              GetParamers(
                monthData.startDate,
                monthData.endDate,
                groupIds,
                [dataemployee.identity_id],
                0,
                0,
                false,
                false,
                "",
                "",
                "",
                true
              )
            )
          );
        }
      }
    }
  };

  return (
    <>
      <div className="att_profile">
        <div className="a_p_block">
          <Avatar
            {...stringAvatar(
              attendancedetailed &&
                attendancedetailed.attendance_detailed_attendance_trend_data &&
                attendancedetailed.attendance_detailed_attendance_trend_data
                  .length > 0
                ? attendancedetailed
                    .attendance_detailed_attendance_trend_data[0].first_name +
                    " " +
                    attendancedetailed
                      .attendance_detailed_attendance_trend_data[0].last_name
                : null
            )}
          />
          <div className="at_block">
            <div className="a_text">
              {attendancedetailed &&
              attendancedetailed.attendance_detailed_attendance_trend_data &&
              attendancedetailed.attendance_detailed_attendance_trend_data
                .length > 0
                ? attendancedetailed
                    .attendance_detailed_attendance_trend_data[0].first_name +
                  " " +
                  attendancedetailed
                    .attendance_detailed_attendance_trend_data[0].last_name
                : null}
            </div>
            <div className="a_mail">
              {" "}
              {attendancedetailed &&
              attendancedetailed.attendance_detailed_attendance_trend_data &&
              attendancedetailed.attendance_detailed_attendance_trend_data
                .length > 0
                ? attendancedetailed
                    .attendance_detailed_attendance_trend_data[0].email
                : null}
            </div>
            <div className="a_mail"> {dataemployee && dataemployee.date}</div>
          </div>
        </div>
      </div>
      <div style={wrapperStyle} className="attendCalender">
        {open && !type && (
          <Calendar
            fullscreen={false}
            cellRender={cellRender}
            monthCellRender={monthCellRender}
            dateCellRender={dateCellRender}
            onSelect={(e) => openPunchLogDateChange(e.format("YYYY-MM-DD"))}
            disabledDate={disabledDate}
          />
        )}
        <div className="punchlog head w-10/12">
          <span className="pin"> In </span> <span className="pout"> Out </span>{" "}
          <div>Duration</div>
        </div>
        <div
          id="scrollableDiv"
          style={{
            maxHeight: 500,
            overflowX: "hidden",
            paddingLeft: "15px",
            paddingRight: "35px",
            border: "0px solid rgba(140, 140, 140, 0.35)",
          }}
        >
          <InfiniteScroll
            dataLength={
              attendancedetailed &&
              attendancedetailed.attendance_detailed_employee_punch_log_list_data &&
              attendancedetailed
                .attendance_detailed_employee_punch_log_list_data.length > 0
                ? attendancedetailed
                    .attendance_detailed_employee_punch_log_list_data.length
                : null
            }
            hasMore={
              attendancedetailed &&
              attendancedetailed.attendance_detailed_employee_punch_log_list_data &&
              attendancedetailed
                .attendance_detailed_employee_punch_log_list_data.length > 0
                ? attendancedetailed
                    .attendance_detailed_employee_punch_log_list_data.length
                : null
            }
            endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
            scrollableTarget="scrollableDiv"
          >
            {attendancedetailed &&
            attendancedetailed.attendance_detailed_employee_punch_log_list_processing ===
              true ? (
              <Spin size="small" />
            ) : attendancedetailed &&
              attendancedetailed.attendance_detailed_employee_punch_log_list_data &&
              attendancedetailed
                .attendance_detailed_employee_punch_log_list_data.length >=
                0 ? (
              <List
                dataSource={attendancedetailed.attendance_detailed_employee_punch_log_list_data.sort(
                  function (a, b) {
                    var timeInA = a.time_in;
                    var timeInB = b.time_in;

                    if (timeInA < timeInB) {
                      return -1;
                    } else if (timeInA > timeInB) {
                      return 1;
                    } else {
                      return 0;
                    }
                  }
                )}
                renderItem={(item) => (
                  <List.Item key={item.id}>
                    <div className="punchlog">
                      {item && item.time_in != null ? (
                        <div className="pin">
                          {item?.email?.includes(".local") ? (
                            <Tooltip
                              title={
                                "SYSTEM" +
                                (item?.platform ? " | " + item?.platform : "")
                              }
                            >
                              <img
                                src={SystemIO}
                                alt="SystemIO"
                                className="max-w-5 max-h-5"
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={
                                "USER" +
                                (item?.platform ? " | " + item?.platform : "")
                              }
                            >
                              <img
                                src={UserIO}
                                alt="UserIO"
                                className="max-w-5 max-h-5"
                              />
                            </Tooltip>
                          )}
                          <span className="mr-2">
                            {dayjs(item.time_in).format("hh:mm a")}
                          </span>
                        </div>
                      ) : null}
                      {item && item.time_out != null ? (
                        <div className="pout">
                          {item?.out_tracker?.mode === "UNSPECIFIED" ||
                          item?.out_tracker?.mode === "MANUAL" ? (
                            <Tooltip
                              title={
                                "USER" +
                                (item?.platform ? " | " + item?.platform : "")
                              }
                            >
                              <img
                                src={UserIO}
                                alt="UserIO"
                                className="max-w-5 max-h-5"
                              />
                            </Tooltip>
                          ) : item?.out_tracker?.mode === "SERVER_AUTO" ? (
                            <Tooltip
                              title={
                                "SYSTEM" +
                                (item?.platform ? " | " + item?.platform : "")
                              }
                            >
                              <img
                                src={SystemIO}
                                alt="SystemIO"
                                className="max-w-5 max-h-5"
                              />
                            </Tooltip>
                          ) : item?.out_tracker?.mode === "CLIENT_AUTO" ? (
                            <Tooltip
                              title={
                                "SYSTEM IDLE" +
                                (item?.platform ? " | " + item?.platform : "")
                              }
                            >
                              <img
                                src={ClientAuto}
                                alt="SystemIO"
                                className="max-w-3 max-h-3 mt-1 mr-1"
                              />
                            </Tooltip>
                          ) : null}
                          <span className="mr-2">
                            {dayjs(item.time_out).format("hh:mm a")}
                          </span>
                        </div>
                      ) : null}
                      <div>
                        {item && item.working_time != null
                          ? hms(item.working_time)
                          : null}
                      </div>
                    </div>
                  </List.Item>
                )}
              />
            ) : (
              <p>No Data Found</p>
            )}
          </InfiniteScroll>
        </div>
        {!type && (
          <ul className="cal_legend">
            <li className="c_m_p">Min presence</li>
            <li className="c_a_n">Absent</li>
            <li className="c_f">Fullday</li>
            <li className="c_h">Halfday</li>
            <li className="c_w">Weekly Off</li>
          </ul>
        )}
      </div>
    </>
  );
};

AttendaceCalender.propTypes = {
  getList: PropTypes.func,
};

export default AttendaceCalender;
