import * as actionTypes from "./actionTypes";

const initialState = {
  downloadLoading: false,
  downloadKeyConfigLoading: false,
  downloadErrorMessage: "",
  downloadURL: null,
  progressInfo: null,
  ajaxRequest: null,
  appVersionDetails: null,
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.DOWNLOAD_STEALTH_APP:
      return {
        ...state,
        downloadLoading: !action.payload.copy,
        downloadErrorMessage: "",
      };
    case actionTypes.DOWNLOAD_STEALTH_APP_SUCCESS:
      return {
        ...state,
        downloadLoading: false,
      };
    case actionTypes.DOWNLOAD_STEALTH_APP_FAILED:
      return {
        ...state,
        downloadLoading: false,
        downloadErrorMessage: action.payload,
      };

    case actionTypes.DOWNLOAD_STANDARD_APP:
      return {
        ...state,
        downloadLoading: !action.payload.copy,
        downloadErrorMessage: "",
      };
    case actionTypes.DOWNLOAD_STANDARD_APP_SUCCESS:
      return {
        ...state,
        downloadLoading: false,
      };
    case actionTypes.DOWNLOAD_STANDARD_APP_FAILED:
      return {
        ...state,
        downloadLoading: false,
        downloadErrorMessage: action.payload,
      };

    case actionTypes.DOWNLOAD_KEY_CONFIG:
      return {
        ...state,
        downloadKeyConfigLoading: true,
        downloadErrorMessage: "",
      };
    case actionTypes.DOWNLOAD_KEY_CONFIG_SUCCESS:
      return {
        ...state,
        downloadKeyConfigLoading: false,
      };
    case actionTypes.DOWNLOAD_KEY_CONFIG_FAILED:
      return {
        ...state,
        downloadKeyConfigLoading: false,
        downloadErrorMessage: action.payload,
      };

    case actionTypes.OPEN_PROGRESS_BAR:
      return {
        ...state,
        progressInfo: action.payload.progressInfo,
        ajaxRequest: action.payload.ajaxRequest,
      };

    case actionTypes.CLOSE_PROGRESS_BAR:
      return {
        ...state,
        progressInfo: null,
        ajaxRequest: null,
      };

    case actionTypes.FETCH_APP_VERSION_DETAILS_SUCCESS:
      return {
        ...state,
        appVersionDetails: action.payload,
      };

    default:
      return state;
  }
};
