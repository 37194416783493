//* WORKPLACE Master START*/
//* LIST *//

export const WORKPLACE_SETTING_INITIAL = "WORKPLACE_SETTING_INITIAL";
export const WORKPLACE_SETTING_PROCESS = "WORKPLACE_SETTING_PROCESS";
export const WORKPLACE_SETTING_SUCCESS = "WORKPLACE_SETTING_SUCCESS";
export const WORKPLACE_SETTING_FAILED = "WORKPLACE_SETTING_FAILED";

//* EDIT *//

export const WORKPLACE_SETTING_EDIT_INITIAL = "WORKPLACE_SETTING_EDIT_INITIAL ";
export const WORKPLACE_SETTING_EDIT_PROCESS = "WORKPLACE_SETTING_EDIT_PROCESS";
export const WORKPLACE_SETTING_EDIT_SUCCESS = "WORKPLACE_SETTING_EDIT_SUCCESS";
export const WORKPLACE_SETTING_EDIT_FAILED = "WORKPLACE_SETTING_EDIT_FAILED";

//* WORKPLACE_END */
