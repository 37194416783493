import { put } from "redux-saga/effects";
import { TenantAction } from "../action";
import { Client } from "../../../utils/transport";

export function* GetTenantData(data) {
  let config = {
    method: "GET",
    url: "TENANT_PHASE_1",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    yield put(TenantAction.TenantListSuccess(response.data));
  } catch (err) {
    yield put(TenantAction.TenantListFailed(err));
  }
}

export function* CreateTenantData(data) {
  let config = {
    method: "POST",
    url: "TENANT_PHASE_1",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.Bodydata,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);
    yield put(TenantAction.TenantCreateSuccess(response.data));
  } catch (err) {
    yield put(TenantAction.TenantCreateFailed(err));
  }
}
export function* EditTenantData(data) {
  let config = {
    method: "POST",
    url: "api/v1/admin/my_tenant/",

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.Bodydata,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    yield put(TenantAction.TenantEditSuccess(response.data));
  } catch (err) {
    yield put(TenantAction.TenantEditFailed(err));
  }
}
