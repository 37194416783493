import * as actionTypes from "./actionType";

/** REPORT MONTHLY IN OUT  */

export const ReportMonthlyInOutInitial = () => {
  return {
    type: actionTypes.REPORT_MONTHLY_IN_OUT_INITIAL,
  };
};

export const ReportMonthlyInOutProcess = (token, bodyData) => {
  return {
    type: actionTypes.REPORT_MONTHLY_IN_OUT_PROCESS,
    token,
    bodyData,
  };
};

export const ReportMonthlyInOutSuccess = (data) => {
  return {
    type: actionTypes.REPORT_MONTHLY_IN_OUT_SUCCESS,
    data,
  };
};

export const ReportMonthlyInOutFailed = (err) => {
  return {
    type: actionTypes.REPORT_MONTHLY_IN_OUT_FAILED,
    err,
  };
};

export const ReportMonthlyInOutDownloadInitial = () => {
  return {
    type: actionTypes.REPORT_MONTHLY_IN_OUT_DOWNLOAD_INITIAL,
  };
};

export const ReportMonthlyInOutDownloadProcess = (token, bodyData) => {
  return {
    type: actionTypes.REPORT_MONTHLY_IN_OUT_DOWNLOAD_PROCESS,
    token,
    bodyData,
  };
};

export const ReportMonthlyInOutDownloadSuccess = (data) => {
  return {
    type: actionTypes.REPORT_MONTHLY_IN_OUT_DOWNLOAD_SUCCESS,
    data,
  };
};

export const ReportMonthlyInOutDownloadFailed = (data) => {
  return {
    type: actionTypes.REPORT_MONTHLY_IN_OUT_DOWNLOAD_FAILED,
    data,
  };
};
