import * as actionTypes from "./actionTypes";

const initialPagination = { totalCount: 0 };

const initialState = {
  taskFilters: [[null, null, null, null]],
  getTaskLoading: true,
  selectedTask: null,
  fetchTasksLoading: true,
  tasksData: {},
  fetchChildTasksLoading: true,
  childTasksData: {},
  pagination: initialPagination,
  childPagination: initialPagination,
  taskLoading: false,
  taskSuccess: false,
  taskFailed: false,
  deleteTaskSuccess: false,
  deleteTaskLoading: false,
  fetchTaskAttachmentsLoading: true,
  taskAttachmentsData: [],
  taskAttachmentLoading: false,
  taskAttachmentSuccess: false,
  taskAttachmentFailed: false,
  deleteTaskAttachmentSuccess: false,
  deleteTaskAttachmentLoading: false,
  getTaskAttachmentLoading: false,
  taskErrorMessage: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.RESET_TASK:
      return {
        ...initialState,
      };

    case actionTypes.GET_TASK:
      return {
        ...state,
        getTaskLoading: true,
        taskErrorMessage: "",
        selectedTask: null,
      };

    case actionTypes.GET_TASK_SUCCESS:
      return {
        ...state,
        getTaskLoading: false,
        selectedTask: action.payload,
      };

    case actionTypes.GET_TASK_FAILED:
      return {
        ...state,
        getTaskLoading: false,
        taskErrorMessage: action.payload,
      };

    case actionTypes.FETCH_TASKS:
      return {
        ...state,
        fetchTasksLoading: true,
        taskErrorMessage: "",
      };

    case actionTypes.FETCH_TASKS_SUCCESS:
      return {
        ...state,
        fetchTasksLoading: false,
        tasksData: action.payload.data.reduce(
          (acc, task) => ({ ...acc, [task.id]: task }),
          {}
        ),
        pagination: action.payload.meta || state.pagination,
      };

    case actionTypes.FETCH_TASKS_FAILED:
      return {
        ...state,
        fetchTasksLoading: false,
        tasksData: {},
        pagination: initialPagination,
        taskErrorMessage: action.payload,
      };

    case actionTypes.FETCH_CHILD_TASKS:
      return {
        ...state,
        fetchChildTasksLoading: true,
        taskErrorMessage: "",
      };

    case actionTypes.FETCH_CHILD_TASKS_SUCCESS:
      return {
        ...state,
        fetchChildTasksLoading: false,
        childTasksData: action.payload.data.reduce(
          (acc, task) => ({ ...acc, [task.id]: task }),
          {}
        ),
        childPagination: action.payload.meta || state.pagination,
      };

    case actionTypes.FETCH_CHILD_TASKS_FAILED:
      return {
        ...state,
        fetchChildTasksLoading: false,
        childTasksData: {},
        childPagination: initialPagination,
        taskErrorMessage: action.payload,
      };

    case actionTypes.CREATE_TASK:
      return {
        ...state,
        taskLoading: true,
        taskErrorMessage: "",
        taskSuccess: false,
        taskFailed: false,
      };

    case actionTypes.CREATE_TASK_SUCCESS:
      return {
        ...state,
        taskLoading: false,
        tasksData: {
          ...state.tasksData,
          [action.payload.id]: action.payload,
        },
        pagination: {
          ...state.pagination,
          totalCount: state.pagination.totalCount + 1,
        },
        taskSuccess: true,
      };

    case actionTypes.CREATE_TASK_FAILED:
      return {
        ...state,
        taskLoading: false,
        taskErrorMessage: action.payload,
        taskFailed: true,
      };

    case actionTypes.UPDATE_TASK:
      return {
        ...state,
        taskLoading: true,
        taskErrorMessage: "",
        taskSuccess: false,
        taskFailed: false,
      };

    case actionTypes.UPDATE_TASK_SUCCESS: {
      const newState = {
        ...state,
        taskLoading: false,
        tasksData: {
          ...state.tasksData,
          [action.payload.id]: action.payload,
        },
        taskSuccess: true,
      };
      if (state.selectedTask?.id === action.payload.id) {
        newState.selectedTask = action.payload;
      }
      return newState;
    }

    case actionTypes.UPDATE_TASK_FAILED:
      return {
        ...state,
        taskLoading: false,
        taskErrorMessage: action.payload,
        taskFailed: true,
      };

    case actionTypes.DELETE_TASK:
      return {
        ...state,
        deleteTaskLoading: true,
        taskErrorMessage: "",
        deleteTaskSuccess: false,
      };

    case actionTypes.DELETE_TASK_SUCCESS:
      return {
        ...state,
        deleteTaskLoading: false,
        deleteTaskSuccess: true,
      };

    case actionTypes.DELETE_TASK_FAILED:
      return {
        ...state,
        deleteTaskLoading: false,
        taskErrorMessage: action.payload,
      };

    case actionTypes.FETCH_TASK_ATTACHMENTS:
      return {
        ...state,
        fetchTaskAttachmentsLoading: true,
        taskErrorMessage: "",
      };

    case actionTypes.FETCH_TASK_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        fetchTaskAttachmentsLoading: false,
        taskAttachmentsData: action.payload.items || [],
      };

    case actionTypes.FETCH_TASK_ATTACHMENTS_FAILED:
      return {
        ...state,
        fetchTaskAttachmentsLoading: false,
        taskAttachmentsData: [],
        taskErrorMessage: action.payload,
      };

    case actionTypes.GET_TASK_ATTACHMENT:
      return {
        ...state,
        getTaskAttachmentLoading: true,
        taskErrorMessage: "",
      };

    case actionTypes.GET_TASK_ATTACHMENT_SUCCESS:
      return {
        ...state,
        getTaskAttachmentLoading: false,
      };

    case actionTypes.GET_TASK_ATTACHMENT_FAILED:
      return {
        ...state,
        getTaskAttachmentLoading: false,
        taskErrorMessage: action.payload,
      };

    case actionTypes.CREATE_TASK_ATTACHMENT:
      return {
        ...state,
        taskAttachmentLoading: true,
        taskErrorMessage: "",
        taskAttachmentSuccess: false,
        taskAttachmentFailed: false,
      };

    case actionTypes.CREATE_TASK_ATTACHMENT_SUCCESS:
      return {
        ...state,
        taskAttachmentLoading: false,
        taskAttachmentSuccess: true,
      };

    case actionTypes.CREATE_TASK_ATTACHMENT_FAILED:
      return {
        ...state,
        taskAttachmentLoading: false,
        taskErrorMessage: action.payload,
        taskAttachmentFailed: true,
      };

    case actionTypes.UPDATE_TASK_ATTACHMENT:
      return {
        ...state,
        taskAttachmentLoading: true,
        taskErrorMessage: "",
        taskAttachmentSuccess: false,
        taskAttachmentFailed: false,
      };

    case actionTypes.UPDATE_TASK_ATTACHMENT_SUCCESS:
      return {
        ...state,
        taskAttachmentLoading: false,
        taskAttachmentSuccess: true,
      };

    case actionTypes.UPDATE_TASK_ATTACHMENT_FAILED:
      return {
        ...state,
        taskAttachmentLoading: false,
        taskErrorMessage: action.payload,
        taskAttachmentFailed: true,
      };

    case actionTypes.DELETE_TASK_ATTACHMENT:
      return {
        ...state,
        deleteTaskAttachmentLoading: true,
        taskErrorMessage: "",
        deleteTaskAttachmentSuccess: false,
      };

    case actionTypes.DELETE_TASK_ATTACHMENT_SUCCESS:
      return {
        ...state,
        deleteTaskAttachmentLoading: false,
        deleteTaskAttachmentSuccess: true,
      };

    case actionTypes.DELETE_TASK_ATTACHMENT_FAILED:
      return {
        ...state,
        deleteTaskAttachmentLoading: false,
        taskErrorMessage: action.payload,
      };

    case actionTypes.SET_TASK_ERROR_MESSAGE:
      return {
        ...state,
        taskErrorMessage: action.payload,
      };

    case actionTypes.SET_SELECTED_TASK:
      return {
        ...state,
        selectedTask: action.payload,
      };

    case actionTypes.SET_TASK_FILTERS:
      return {
        ...state,
        taskFilters: action.payload,
      };

    default:
      return state;
  }
};
