import React from "react";

export const Application = ({ viewBox = "0 0 20 20", className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      fill="currentColor"
      className={className}
    >
      <path
        d="M14.9,1H5.1C2.8,1,1,2.8,1,5.1v6.5c0,2.3,1.8,4.1,4.1,4.1h9.8c2.3,0,4.1-1.8,4.1-4.1V5.1C19,2.8,17.2,1,14.9,1z
	"
      />
      <path
        d="M15.7,17.4H4.3c-0.2,0-0.4,0.1-0.6,0.2c-0.2,0.2-0.2,0.4-0.2,0.6c0,0.2,0.1,0.4,0.2,0.6C3.8,18.9,4.1,19,4.3,19
	h11.5c0.2,0,0.4-0.1,0.6-0.2c0.2-0.2,0.2-0.4,0.2-0.6c0-0.2-0.1-0.4-0.2-0.6C16.2,17.4,15.9,17.4,15.7,17.4z"
      />
    </svg>
  );
};
