import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "../index.scss";
import { InfoCircleFilled } from "@ant-design/icons";
import _ from "lodash";
import Chart from "../../chart";
import { hm } from "../../../utils/timeConverter";
import { useDispatch, useSelector } from "react-redux";
import { Skeleton, Typography, Tooltip, Empty } from "antd";
import { getDonutChartOptions } from "utils/apexChartsUtil.tsx";
import { getColorCodes } from "utils/colorCodes";
import TotalAverageDonutGraph from "utils/totalAverageDonutGraph";
import dateDifferenceTextDisplay from "utils/dateDiffferenceTextDisplay";

const colorCodes = getColorCodes();
const { Text, Link, Title } = Typography;

const Cardskeleton = (props) => {
  const { loading = false } = props;
  return (
    <>
      <div className="charts">
        <div className="chart_header">
          <Skeleton.Input
            size={"small"}
            shape={"default"}
            style={{ height: "15px" }}
          />
          <Skeleton.Avatar
            size={"small"}
            shape={"circle"}
            style={{ height: "15px", width: "15px" }}
          />
        </div>
        <div className="chartContainer chart_tool" id="chart">
          {/* chart */}
          <Skeleton.Avatar
            size={"large"}
            shape={"default"}
            style={{ height: "275px", width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

const appusages = {
  series: [127000, 27000, 27000],
};

const ProductivityBreakdown = (props) => {
  const { hideTooltip, startDate, endDate } = props;

  const productivitysummary = useSelector((state) => state.productivitysummary);

  const chartOptions = getDonutChartOptions({
    options: {
      labels: ["Productive time", "Unproductive time", "Neutral time"],
      colors: [
        colorCodes.productiveTime,
        colorCodes.unproductiveTime,
        colorCodes.neutralTime,
      ],
    },
  });

  const [emptydata, setEmptydata] = useState(true);

  const [loading, setLoading] = useState(true);
  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }
  const [breakDownSummary, setbreakDownSummary] = useState();

  useEffect(() => {
    if (
      productivitysummary.productivity_summary_productivity_break_down_success ===
      true
    ) {
      setbreakDownSummary([]);
      const breakdownSum = [];
      if (
        _.size(
          productivitysummary.productivity_summary_productivity_break_down_data
        ) > 0
      ) {
        productivitysummary.productivity_summary_productivity_break_down_data.map(
          (item) => {
            breakdownSum.push([
              item.total_productive_duration === null
                ? 0
                : item.total_productive_duration,
              item.total_unproductive_duration === null
                ? 0
                : item.total_unproductive_duration,
              item.total_neutral_duration === null
                ? 0
                : item.total_neutral_duration,
            ]);
          }
        );
      }
      const appusages1 = {
        series: breakdownSum[0],
      };
      setbreakDownSummary(appusages1);
    }
  }, [productivitysummary]);

  return (
    <>
      {productivitysummary.productivity_summary_productivity_break_down_processing ? (
        <Cardskeleton />
      ) : (
        <div className="charts">
          <div className="chart_header">
            <Title level={2}>Productivity Breakdown</Title>
            {!hideTooltip && (
              <Tooltip title="Displays time spent by the employee on productive, unproductive and neutral apps/URLs.">
                <InfoCircleFilled />
              </Tooltip>
            )}
          </div>
          <div className="chartContainer chart_tool" id="chart">
            {/* chart */}
            {_.size(
              productivitysummary.productivity_summary_productivity_break_down_data
            ) > 0 ? (
              <>
                <TotalAverageDonutGraph
                  type={"productivity"}
                  totalTitle={"Total productive time"}
                  totalHours={
                    productivitysummary
                      ?.productivity_summary_productivity_break_down_data[0]
                      ?.total_productive_duration
                  }
                  totalPer={dateDifferenceTextDisplay(startDate, endDate)}
                  avgTitle={"Average productive time"}
                  avgHours={
                    productivitysummary
                      ?.productivity_summary_productivity_break_down_data[0]
                      ?.overall_average_productive_time
                  }
                  avgPer={"Average per day"}
                />
                <Chart
                  options={chartOptions.options}
                  series={breakDownSummary && breakDownSummary.series}
                  type="donut"
                  height={300}
                  // style={{ width: "280px", margin: "0px auto" }}
                />
              </>
            ) : (
              <div style={{ height: "371px" }} className="emptyblock">
                <Empty description={false} />
              </div>
            )}

            <div></div>
          </div>
        </div>
      )}
    </>
  );
};

ProductivityBreakdown.propTypes = {
  getList: PropTypes.func,
};

export default ProductivityBreakdown;
