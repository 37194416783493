//* APPLICATION SUMMARY CATEGORY_UTILIZATION *//

export const APPLICATION_SUMMARY_CATEGORY_UTILIZATION_INITIAL =
  "APPLICATION_SUMMARY_CATEGORY_UTILIZATION_INITIAL";
export const APPLICATION_SUMMARY_CATEGORY_UTILIZATION_PROCESS =
  "APPLICATION_SUMMARY_CATEGORY_UTILIZATION_PROCESS";
export const APPLICATION_SUMMARY_CATEGORY_UTILIZATION_SUCCESS =
  "APPLICATION_SUMMARY_CATEGORY_UTILIZATION_SUCCESS";
export const APPLICATION_SUMMARY_CATEGORY_UTILIZATION_FAILED =
  "APPLICATION_SUMMARY_CATEGORY_UTILIZATION_FAILED";

//*  APPLICATION CATEGORY_UTILIZATION End *//

//* APPLICATION SUMMARY ONLINE TIME BREAK DOWN *//

export const APPLICATION_SUMMARY_TOTAL_APPLICATION_USAGE_INITIAL =
  "APPLICATION_SUMMARY_TOTAL_APPLICATION_USAGE_INITIAL";
export const APPLICATION_SUMMARY_TOTAL_APPLICATION_USAGE_PROCESS =
  "APPLICATION_SUMMARY_TOTAL_APPLICATION_USAGE_PROCESS";
export const APPLICATION_SUMMARY_TOTAL_APPLICATION_USAGE_SUCCESS =
  "APPLICATION_SUMMARY_TOTAL_APPLICATION_USAGE_SUCCESS";
export const APPLICATION_SUMMARY_TOTAL_APPLICATION_USAGE_FAILED =
  "APPLICATION_SUMMARY_TOTAL_APPLICATION_USAGE_FAILED";

//*  APPLICATION SUMMARY ONLINE TIME BREAK DOWN *//

//* APPLICATION SUMMARY APPLICATION LEVEL BREAK DOWN *//

export const APPLICATION_SUMMARY_TOTAL_URL_USAGE_INITIAL =
  "APPLICATION_SUMMARY_TOTAL_URL_USAGE_INITIAL";
export const APPLICATION_SUMMARY_TOTAL_URL_USAGE_PROCESS =
  "APPLICATION_SUMMARY_TOTAL_URL_USAGE_PROCESS";
export const APPLICATION_SUMMARY_TOTAL_URL_USAGE_SUCCESS =
  "APPLICATION_SUMMARY_TOTAL_URL_USAGE_SUCCESS";
export const APPLICATION_SUMMARY_TOTAL_URL_USAGE_FAILED =
  "APPLICATION_SUMMARY_TOTAL_URL_USAGE_FAILED";

//*  APPLICATION SUMMARY APPLICATION LEVEL BREAK DOWN End *//

//* APPLICATION SUMMARY TOP MOST ACTIVE TEAM  *//

export const APPLICATION_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_INITIAL =
  "APPLICATION_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_INITIAL";
export const APPLICATION_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_PROCESS =
  "APPLICATION_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_PROCESS";
export const APPLICATION_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_SUCCESS =
  "APPLICATION_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_SUCCESS";
export const APPLICATION_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_FAILED =
  "APPLICATION_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_FAILED";

//*   APPLICATION SUMMARY TOP MOST ACTIVE TEAM  End *//

//* APPLICATION SUMMARY TOP LEAST ACTIVE TEAM  *//

export const APPLICATION_SUMMARY_TOP_LEAST_ACTIVE_TEAM_INITIAL =
  "APPLICATION_SUMMARY_TOP_LEAST_ACTIVE_TEAM_INITIAL";
export const APPLICATION_SUMMARY_TOP_LEAST_ACTIVE_TEAM_PROCESS =
  "APPLICATION_SUMMARY_TOP_LEAST_ACTIVE_TEAM_PROCESS";
export const APPLICATION_SUMMARY_TOP_LEAST_ACTIVE_TEAM_SUCCESS =
  "APPLICATION_SUMMARY_TOP_LEAST_ACTIVE_TEAM_SUCCESS";
export const APPLICATION_SUMMARY_TOP_LEAST_ACTIVE_TEAM_FAILED =
  "APPLICATION_SUMMARY_TOP_LEAST_ACTIVE_TEAM_FAILED";

//*   APPLICATION SUMMARY TOP LEAST ACTIVE TEAM  End *//

//* APPLICATION SUMMARY TEAM WISE APPLICATION BREAK DOWN *//

export const APPLICATION_SUMMARY_TEAM_WISE_APPLICATION_BREAK_DOWN_INITIAL =
  "APPLICATION_SUMMARY_TEAM_WISE_APPLICATION_BREAK_DOWN_INITIAL";
export const APPLICATION_SUMMARY_TEAM_WISE_APPLICATION_BREAK_DOWN_PROCESS =
  "APPLICATION_SUMMARY_TEAM_WISE_APPLICATION_BREAK_DOWN_PROCESS";
export const APPLICATION_SUMMARY_TEAM_WISE_APPLICATION_BREAK_DOWN_SUCCESS =
  "APPLICATION_SUMMARY_TEAM_WISE_APPLICATION_BREAK_DOWN_SUCCESS";
export const APPLICATION_SUMMARY_TEAM_WISE_APPLICATION_BREAK_DOWN_FAILED =
  "APPLICATION_SUMMARY_TEAM_WISE_APPLICATION_BREAK_DOWN_FAILED";

//*  APPLICATION SUMMARY ENDTEAM WISE APPLICATION BREAK DOWN *//

//* APPLICATION SUMMARY TOP APP URL APPLICATION *//

export const APPLICATION_SUMMARY_TOP_APPLICATION_URL_INITIAL =
  "APPLICATION_SUMMARY_TOP_APPLICATION_URL_INITIAL";
export const APPLICATION_SUMMARY_TOP_APPLICATION_URL_PROCESS =
  "APPLICATION_SUMMARY_TOP_APPLICATION_URL_PROCESS";
export const APPLICATION_SUMMARY_TOP_APPLICATION_URL_SUCCESS =
  "APPLICATION_SUMMARY_TOP_APPLICATION_URL_SUCCESS";
export const APPLICATION_SUMMARY_TOP_APPLICATION_URL_FAILED =
  "APPLICATION_SUMMARY_TOP_APPLICATION_URL_FAILED";

//*  APPLICATION SUMMARY TOP APPLICATION *//
