import React, { useEffect, useState } from "react";
import UserScoreMonthlyAttendanceTrend from "./UserScoreMonthlyAttendanceTrend";
import { Divider } from "antd";
import { useSelector } from "react-redux";
import { hm } from "utils/timeConverter";
import dayjs from "dayjs";

const UserMonthlyAttendance = (props) => {
  const userscorecard = useSelector(
    (
      state // @ts-ignore
    ) => state.userscorecard
  );
  const { month } = props;
  const [attendanceData, setAttendanceData] = useState({
    countPStatus: 0,
    countHStatus: 0,
    countLStatus: 0,
    countEStatus: 0,
    maxBreak: 0,
    totalBreakDuration: 0,
    formattedMaxBreakDate: "N/A",
    averagePunchDuration: 0,
  });

  useEffect(() => {
    if (
      userscorecard &&
      userscorecard?.comparative_attendance_user_performance_success
    ) {
      const comparativeData =
        userscorecard.comparative_attendance_user_performance_data;

      const countPStatus = comparativeData.filter(
        (record) => record.att_status === "P"
      ).length;
      const countHStatus = comparativeData.filter(
        (record) => record.workplace === "half_day"
      ).length;
      const countLStatus = comparativeData.filter(
        (record) => record.departure === "late"
      ).length;
      const countEStatus = comparativeData.filter(
        (record) => record.departure === "early"
      ).length;
      const totalBreakDuration = comparativeData.reduce((total, record) => {
        return total + record.break_duration;
      }, 0);
      const totalPunchDuration = comparativeData.reduce((total, record) => {
        return total + record.punch_duration;
      }, 0);

      const averagePunchDuration =
        countPStatus > 0 ? totalPunchDuration / countPStatus : 0;
      let maxBreak = 0;
      let maxBreakDate = null;

      comparativeData.forEach((record) => {
        if (record.break_duration > maxBreak) {
          maxBreak = record.break_duration;
          maxBreakDate = record.punch_date;
        }
      });

      const formattedMaxBreakDate = maxBreakDate
        ? dayjs(maxBreakDate).format("DD MMMM YYYY, dddd")
        : "N/A";

      setAttendanceData({
        countPStatus,
        countHStatus,
        countLStatus,
        countEStatus,
        maxBreak,
        totalBreakDuration,
        formattedMaxBreakDate,
        averagePunchDuration,
      });
    }
  }, [userscorecard]);

  const {
    countPStatus,
    countHStatus,
    countLStatus,
    countEStatus,
    totalBreakDuration,
    formattedMaxBreakDate,
    averagePunchDuration,
  } = attendanceData;

  return (
    <>
      <div className="bg-white border rounded-[5px] p-4 overflow-hidden">
        <div className="flex items-center justify-between ">
          <h3 className="font-semibold text-[16px] text-slate-500 mt-0">
            Attendance
          </h3>
        </div>
        <div className="w-full bg-neutral-200">
          <div
            className={`${
              countPStatus >= 0 && countPStatus <= 50
                ? "bg-[#14B8A6]"
                : countPStatus > 50 && countPStatus <= 75
                ? "bg-[#14B8A6]"
                : countPStatus > 75
                ? "bg-[#14B8A6]"
                : "bg-white-100"
            } p-0.5 h-[4px] text-center text-xs font-medium leading-none text-slate-100`}
            style={{ width: countPStatus + `%` }}
          ></div>
        </div>
        <div className="flex items-center justify-between pt-4 pb-4">
          <div className="font-extrabold text-[20px] text-[#000]">
            {countPStatus}/
            {userscorecard?.comparative_attendance_user_performance_data.length}
          </div>
        </div>
        <UserScoreMonthlyAttendanceTrend month={month} />
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">Half Days taken</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {countHStatus} days
          </div>
        </div>
        <Divider />
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">Late Arrivals</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {countLStatus} days
          </div>
        </div>
        <Divider />
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">Early Departures</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {countEStatus} days
          </div>
        </div>
        <Divider />
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">Average Working Time</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {hm(averagePunchDuration)}
          </div>
        </div>
        <Divider />
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">Total break time</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {hm(totalBreakDuration)}
          </div>
        </div>
        <Divider />
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">Most break taken on</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {formattedMaxBreakDate}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserMonthlyAttendance;
