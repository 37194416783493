import * as actionTypes from "./actionTypes";

const leaveTypeList = (data) => {};
const initialPagination = { totalCount: 0 };
const initialState = {
  //*LEAVE TYPE*//
  //* LIST *//
  leave_type_list_loading: false,
  leave_type_list_success: false,
  leave_type_list_failed: false,
  leave_type_list_data: [],
  pagination: initialPagination,
  leave_type_message: "",

  //* CREATE *//
  leave_type_create_loading: false,
  leave_type_create_success: false,
  leave_type_create_failed: false,
  leave_type_create_data: [],
  //* EDIT *//

  leave_type_edit_loading: false,
  leave_type_edit_success: false,
  leave_type_edit_failed: false,
  leave_type_edit_data: [],

  //*LEAVE*//
  //* LIST *//
  leave_list_loading: false,
  leave_list_success: false,
  leave_list_failed: false,
  leave_list_data: [],
  leave_pagination: initialPagination,
  leave_message: "",

  //* CREATE *//
  leave_create_loading: false,
  leave_create_success: false,
  leave_create_failed: false,
  leave_create_data: [],
  //* EDIT *//

  leave_edit_loading: false,
  leave_edit_success: false,
  leave_edit_failed: false,
  leave_edit_data: [],
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.LEAVE_TYPE_LIST_INITIAL:
      return {
        ...state,
        leave_type_list_loading: false,
        leave_type_list_success: false,
        leave_type_list_failed: false,
        leave_type_list_data: [],
        leave_type_message: "",
      };
    case actionTypes.LEAVE_TYPE_LIST_PROCESS:
      return {
        ...state,
        leave_type_list_loading: true,
        leave_type_list_success: false,
        leave_type_list_failed: false,
        leave_type_list_data: [],
        leave_type_message: "",
      };
    case actionTypes.LEAVE_TYPE_LIST_SUCCESS:
      return {
        ...state,
        leave_type_list_loading: false,
        leave_type_list_success: true,
        leave_type_list_failed: false,
        leave_type_list_data: action.data?.data,
        pagination: action.data.meta,
        leave_type_message: "",
      };
    case actionTypes.LEAVE_TYPE_LIST_FAILED:
      return {
        ...state,
        leave_type_list_loading: false,
        leave_type_list_success: false,
        leave_type_list_failed: true,
        leave_type_list_data: [],
        leave_type_message: action.data,
      };

    case actionTypes.LEAVE_TYPE_CREATE_INITIAL:
      return {
        ...state,
        leave_type_create_loading: false,
        leave_type_create_success: false,
        leave_type_create_failed: false,
        leave_type_create_data: [],
        leave_type_message: "",
      };
    case actionTypes.LEAVE_TYPE_CREATE_PROCESS:
      return {
        ...state,
        leave_type_create_loading: true,
        leave_type_create_success: false,
        leave_type_create_failed: false,
        leave_type_create_data: [],
        leave_type_message: "",
      };
    case actionTypes.LEAVE_TYPE_CREATE_SUCCESS:
      return {
        ...state,
        leave_type_create_loading: false,
        leave_type_create_success: true,
        leave_type_create_failed: false,
        leave_type_create_data: action.data,
        leave_type_list_data: [action.data, ...state.leave_type_list_data],
        leave_type_message: "",
      };

    case actionTypes.LEAVE_TYPE_CREATE_FAILED:
      return {
        ...state,
        leave_type_create_loading: false,
        leave_type_create_success: false,
        leave_type_create_failed: true,
        leave_type_create_data: "",
        leave_type_message: action.data,
      };

    case actionTypes.LEAVE_TYPE_EDIT_INITIAL:
      return {
        ...state,
        leave_type_edit_loading: false,
        leave_type_edit_success: false,
        leave_type_edit_failed: false,
        leave_type_edit_data: [],
        leave_type_message: "",
      };

    case actionTypes.LEAVE_TYPE_EDIT_PROCESS:
      return {
        ...state,
        leave_type_edit_loading: true,
        leave_type_edit_success: false,
        leave_type_edit_failed: false,
        leave_type_edit_data: [],
        leave_type_message: "",
      };
    case actionTypes.LEAVE_TYPE_EDIT_SUCCESS:
      const fetchLeavetypelist = [...state.leave_type_list_data];
      const findIndex = fetchLeavetypelist.findIndex(
        (item) => item.id == action.data.id
      );
      fetchLeavetypelist[findIndex] = action.data;

      return {
        ...state,
        leave_type_edit_loading: false,
        leave_type_edit_success: true,
        leave_type_edit_failed: false,
        leave_type_edit_data: action.data,
        leave_type_list_data: fetchLeavetypelist,
        leave_type_message: "",
      };

    case actionTypes.LEAVE_TYPE_EDIT_FAILED:
      return {
        ...state,
        leave_type_edit_loading: false,
        leave_type_edit_success: false,
        leave_type_edit_failed: true,
        leave_type_edit_data: "",
        leave_type_message: action.data,
      };

    default:
      return state;
  }
};
