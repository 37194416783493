import * as actionTypes from "./actionTypes";

const initialState = {
  // *GLOBAL*//

  wellness360_details_trends_processing: false,
  wellness360_details_trends_success: false,
  wellness360_details_trends_failed: false,
  wellness360_details_trends_data: [],
  wellness360_details_trends_message: "",

  wellness360_details_employee_list_processing: false,
  wellness360_details_employee_list_success: false,
  wellness360_details_employee_list_failed: false,
  wellness360_details_employee_list_data: [],
  wellness360_details_employee_list_message: "",

  wellness360_employee_details_employee_list_processing: false,
  wellness360_employee_details_employee_list_success: false,
  wellness360_employee_details_employee_list_failed: false,
  wellness360_employee_details_employee_list_data: [],
  wellness360_employee_details_employee_list_message: "",

  wellness360_download_employee_list_processing: false,
  wellness360_download_employee_list_success: false,
  wellness360_download_employee_list_failed: false,
  wellness360_download_employee_list_data: [],
  wellness360_download_employee_list_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.WELLNESS360_DETAILS_TRENDS_INITIAL:
      return {
        ...state,
        wellness360_details_trends_processing: false,
        wellness360_details_trends_success: false,
        wellness360_details_trends_failed: false,
        wellness360_details_trends_data: [],
        wellness360_details_trends_message: "",
      };

    case actionTypes.WELLNESS360_DETAILS_TRENDS_PROCESS:
      return {
        ...state,
        wellness360_details_trends_processing: true,
        wellness360_details_trends_success: false,
        wellness360_details_trends_failed: false,
        wellness360_details_trends_data: [],
        wellness360_details_trends_message: "",
      };

    case actionTypes.WELLNESS360_DETAILS_TRENDS_SUCCESS:
      return {
        ...state,
        wellness360_details_trends_processing: false,
        wellness360_details_trends_success: true,
        wellness360_details_trends_failed: false,
        wellness360_details_trends_data: action.data,
        wellness360_details_trends_message: "",
      };
    case actionTypes.WELLNESS360_DETAILS_TRENDS_FAILED:
      return {
        ...state,
        wellness360_details_trends_processing: false,
        wellness360_details_trends_success: false,
        wellness360_details_trends_failed: true,
        wellness360_details_trends_data: [],
        wellness360_details_trends_message: action.data,
      };

    case actionTypes.WELLNESS360_DETAILS_EMPLOYEE_LIST_INITIAL:
      return {
        ...state,
        wellness360_details_employee_list_processing: false,
        wellness360_details_employee_list_success: false,
        wellness360_details_employee_list_failed: false,
        wellness360_details_employee_list_data: [],
        wellness360_details_employee_list_message: "",
      };

    case actionTypes.WELLNESS360_DETAILS_EMPLOYEE_LIST_PROCESS:
      return {
        ...state,
        wellness360_details_employee_list_processing: true,
        wellness360_details_employee_list_success: false,
        wellness360_details_employee_list_failed: false,
        wellness360_details_employee_list_data: [],
        wellness360_details_employee_list_message: "",
      };

    case actionTypes.WELLNESS360_DETAILS_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        wellness360_details_employee_list_processing: false,
        wellness360_details_employee_list_success: true,
        wellness360_details_employee_list_failed: false,
        wellness360_details_employee_list_data: action.data,
        wellness360_details_employee_list_message: "",
      };
    case actionTypes.WELLNESS360_DETAILS_EMPLOYEE_LIST_FAILED:
      return {
        ...state,
        wellness360_details_employee_list_processing: false,
        wellness360_details_employee_list_success: false,
        wellness360_details_employee_list_failed: true,
        wellness360_details_employee_list_data: [],
        wellness360_details_employee_list_message: action.data,
      };

    case actionTypes.WELLNESS360_EMPLOYEE_DETAILS_EMPLOYEE_LIST_INITIAL:
      return {
        ...state,
        wellness360_employee_details_employee_list_processing: false,
        wellness360_employee_details_employee_list_success: false,
        wellness360_employee_details_employee_list_failed: false,
        wellness360_employee_details_employee_list_data: [],
        wellness360_employee_details_employee_list_message: "",
      };

    case actionTypes.WELLNESS360_EMPLOYEE_DETAILS_EMPLOYEE_LIST_PROCESS:
      return {
        ...state,
        wellness360_employee_details_employee_list_processing: true,
        wellness360_employee_details_employee_list_success: false,
        wellness360_employee_details_employee_list_failed: false,
        wellness360_employee_details_employee_list_data: [],
        wellness360_employee_details_employee_list_message: "",
      };

    case actionTypes.WELLNESS360_EMPLOYEE_DETAILS_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        wellness360_employee_details_employee_list_processing: false,
        wellness360_employee_details_employee_list_success: true,
        wellness360_employee_details_employee_list_failed: false,
        wellness360_employee_details_employee_list_data: action.data,
        wellness360_employee_details_employee_list_message: "",
      };
    case actionTypes.WELLNESS360_EMPLOYEE_DETAILS_EMPLOYEE_LIST_FAILED:
      return {
        ...state,
        wellness360_employee_details_employee_list_processing: false,
        wellness360_employee_details_employee_list_success: false,
        wellness360_employee_details_employee_list_failed: true,
        wellness360_employee_details_employee_list_data: [],
        wellness360_employee_details_employee_list_message: action.data,
      };

    case actionTypes.WELLNESS360_DOWNLOAD_EMPLOYEE_LIST_INITIAL:
      return {
        ...state,
        wellness360_download_employee_list_processing: false,
        wellness360_download_employee_list_success: false,
        wellness360_download_employee_list_failed: false,
        wellness360_download_employee_list_data: [],
        wellness360_download_employee_list_message: "",
      };
    case actionTypes.WELLNESS360_DOWNLOAD_EMPLOYEE_LIST_PROCESS:
      return {
        ...state,
        wellness360_download_employee_list_processing: true,
        wellness360_download_employee_list_success: false,
        wellness360_download_employee_list_failed: false,
        wellness360_download_employee_list_data: [],
        wellness360_download_employee_list_message: "",
      };
    case actionTypes.WELLNESS360_DOWNLOAD_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        wellness360_download_employee_list_processing: false,
        wellness360_download_employee_list_success: true,
        wellness360_download_employee_list_failed: false,
        wellness360_download_employee_list_data: action.data,
        wellness360_download_employee_list_message: "",
      };
    case actionTypes.WELLNESS360_DOWNLOAD_EMPLOYEE_LIST_FAILED:
      return {
        ...state,
        wellness360_download_employee_list_processing: false,
        wellness360_download_employee_list_success: false,
        wellness360_download_employee_list_failed: true,
        wellness360_download_employee_list_data: [],
        wellness360_download_employee_list_message: action.data,
      };
    default:
      return state;
  }
};
