import { put } from "redux-saga/effects";
import { ProjectsAction } from "../action";
import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { notification } from "antd";

export function* getProjectSaga(action) {
  const config = {
    method: "GET",
    url: `/api/v1/projects/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    const response = yield Client.request(config);

    yield put(ProjectsAction.getProjectSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(ProjectsAction.getProjectFailed(errorMessage));
  }
}

export function* fetchProjectsSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/projects/search?${action.payload.query}`,
    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };
  try {
    const response = yield Client.request(config);

    yield put(ProjectsAction.fetchProjectsSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(ProjectsAction.fetchProjectsFailed(errorMessage));
  }
}

export function* createProjectSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/projects/`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);

    yield put(ProjectsAction.createProjectSuccess(response.data));
    notification.success({
      message: `Successfully Created Project!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(ProjectsAction.createProjectFailed(errorMessage));
  }
}

export function* updateProjectSaga(action) {
  const config = {
    method: "PUT",
    url: `/api/v1/projects/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);

    yield put(ProjectsAction.updateProjectSuccess(response.data));
    notification.success({
      message: `Successfully Updated Project!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(ProjectsAction.updateProjectFailed(errorMessage));
  }
}

export function* deleteProjectSaga(action) {
  const config = {
    method: "DELETE",
    url: `/api/v1/projects/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    yield Client.request(config);

    yield put(ProjectsAction.deleteProjectSuccess());
    notification.success({
      message: `Successfully Deleted Project!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(ProjectsAction.deleteProjectFailed(errorMessage));
  }
}
