import * as actionTypes from "./actionTypes";

//*  ACTIVITY DETAILED WORKING TIME TRENDS *//

export const ActivityDetailedWorkingTimeTrendInitial = () => {
  return {
    type: actionTypes.ACTIVITY_DETAILED_WORKING_TIME_TREND_INITIAL,
  };
};
export const ActivityDetailedWorkingTimeTrendProcess = (token, bodyData) => {
  return {
    type: actionTypes.ACTIVITY_DETAILED_WORKING_TIME_TREND_PROCESS,
    token,
    bodyData,
  };
};
export const ActivityDetailedWorkingTimeTrendSuccess = (data) => {
  return {
    type: actionTypes.ACTIVITY_DETAILED_WORKING_TIME_TREND_SUCCESS,
    data,
  };
};
export const ActivityDetailedWorkingTimeTrendFailed = (data) => {
  return {
    type: actionTypes.ACTIVITY_DETAILED_WORKING_TIME_TREND_FAILED,
    data,
  };
};

//*  ACTIVITY DETAILED EMPLOYEE LIST *//

export const ActivityDetailedEmployeeListInitial = () => {
  return {
    type: actionTypes.ACTIVITY_DETAILED_EMPLOYEE_LIST_INITIAL,
  };
};
export const ActivityDetailedEmployeeListProcess = (token, bodyData) => {
  return {
    type: actionTypes.ACTIVITY_DETAILED_EMPLOYEE_LIST_PROCESS,
    token,
    bodyData,
  };
};
export const ActivityDetailedEmployeeListSuccess = (data) => {
  return {
    type: actionTypes.ACTIVITY_DETAILED_EMPLOYEE_LIST_SUCCESS,
    data,
  };
};
export const ActivityDetailedEmployeeListFailed = (data) => {
  return {
    type: actionTypes.ACTIVITY_DETAILED_EMPLOYEE_LIST_FAILED,
    data,
  };
};

//*  ACTIVITY EMPLOYEE DETAILED EMPLOYEE LIST *//

export const ActivityEmployeeDetailedEmployeeListInitial = () => {
  return {
    type: actionTypes.ACTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_INITIAL,
  };
};
export const ActivityEmployeeDetailedEmployeeListProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.ACTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_PROCESS,
    token,
    bodyData,
  };
};
export const ActivityEmployeeDetailedEmployeeListSuccess = (data) => {
  return {
    type: actionTypes.ACTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_SUCCESS,
    data,
  };
};
export const ActivityEmployeeDetailedEmployeeListFailed = (data) => {
  return {
    type: actionTypes.ACTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_FAILED,
    data,
  };
};
