//* BREAK Start*/
export const BREAKS_LIST_INITIAL = "BREAKS_LIST_INITIAL";
export const BREAKS_LIST_PROCESS = "BREAKS_LIST_PROCESS";
export const BREAKS_LIST_SUCCESS = "BREAKS_LIST_SUCCESS";
export const BREAKS_LIST_FAILED = "BREAKS_LIST_FAILED";
export const BREAKS_LIST_SEARCH = "BREAKS_LIST_SEARCH";

export const BREAKS_CREATE_INITIAL = "BREAKS_CREATE_INITIAL";
export const BREAKS_CREATE_PROCESS = "BREAKS_CREATE_PROCESS";
export const BREAKS_CREATE_SUCCESS = "BREAKS_CREATE_SUCCESS";
export const BREAKS_CREATE_FAILED = "BREAKS_CREATE_FAILED";

export const BREAKS_EDIT_INITIAL = "BREAKS_EDIT_INITIAL";
export const BREAKS_EDIT_PROCESS = "BREAKS_EDIT_PROCESS";
export const BREAKS_EDIT_SUCCESS = "BREAKS_EDIT_SUCCESS";
export const BREAKS_EDIT_FAILED = "BREAKS_EDIT_FAILED";

export const BREAKS_STOP = "BREAKS_STOP";

//* BREAK End*/
