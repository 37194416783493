import React, { useContext, useEffect, useState } from "react";
import sLogoai from "../../assets/images/logo_ai.png";
import { analyticsPageEvent } from "utils/analyticsUtils";
import { AuthContext } from "contexts/authContext";
import { CopyOutlined } from "@ant-design/icons";
import { Button, Divider } from "antd";
import { copyToClipboard } from "utils/commonUtils";

const ExtensionLogin = () => {
  const { KState } = useContext(AuthContext);
  const [context, setContext] = useState(null);

  useEffect(() => {
    analyticsPageEvent("ExtensionLogin");
    console.log(KState?.keycloak);
    const code = btoa(
      JSON.stringify({
        access_token: KState?.keycloak.token,
        refresh_token: KState?.keycloak.refreshToken,
      })
    );

    setContext(code);
  }, []);

  const copyCode = () => {
    copyToClipboard(
      context,
      true,
      "Code copied to clipboard",
      "Unable to copy code to clipboard, please copy it manually"
    );
  };

  return (
    <>
      <div className="w-full text-center mt-16">
        <img className="mx-auto" src={sLogoai} />
      </div>
      <center>
        <div>We360.ai</div>
        <br />
        <div>Please copy the code, and enter it in the we360.ai extension.</div>

        <div className="w-8/12  border border-solid border-gray-300 rounded-lg mt-4 p-2 text-right flex flex-col items-end">
          <div className="w-full break-words text-xs text-left ">{context}</div>
          <Divider className="w-full my-2" />
          <Button icon={<CopyOutlined />} onClick={copyCode}>
            Copy
          </Button>
        </div>
      </center>
    </>
  );
};

export default ExtensionLogin;
