//* WELLNESS360 SUMMARY WIDGET *//

export const WELLNESS360_SUMMARY_WIDGET_INITIAL =
  "WELLNESS360_SUMMARY_WEDIGT_INITIAL";
export const WELLNESS360_SUMMARY_WIDGET_PROCESS =
  "WELLNESS360_SUMMARY_WEDIGT_PROCESS";
export const WELLNESS360_SUMMARY_WIDGET_SUCCESS =
  "WELLNESS360_SUMMARY_WEDIGT_SUCCESS";
export const WELLNESS360_SUMMARY_WIDGET_FAILED =
  "WELLNESS360_SUMMARY_WEDIGT_FAILED";

//*  WELLNESS360 WIDGET End *//

//* WELLNESS360 SUMMARY HEALTHY EMPLOYEES *//

export const WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_INITIAL =
  "WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_INITIAL";
export const WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PROCESS =
  "WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PROCESS";
export const WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_SUCCESS =
  "WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_SUCCESS";
export const WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_FAILED =
  "WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_FAILED";

//*  WELLNESS360 Healthy employees End *//

//* WELLNESS360 SUMMARY HEALTHY EMPLOYEES PREVIOUS MONTH*//

export const WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PREVIOUS_MONTH_INITIAL =
  "WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PREVIOUS_MONTH_INITIAL";
export const WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PREVIOUS_MONTH_PROCESS =
  "WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PREVIOUS_MONTH_PROCESS";
export const WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PREVIOUS_MONTH_SUCCESS =
  "WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PREVIOUS_MONTH_SUCCESS";
export const WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PREVIOUS_MONTH_FAILED =
  "WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PREVIOUS_MONTH_FAILED";

//*  WELLNESS360 SUMMARY HEALTHY EMPLOYEES PREVIOUS MONTH END *//

//* WELLNESS360 SUMMARY OVERBURDENED EMPLOYEES *//

export const WELLNESS360_SUMMARY_OVERBURDENED_EMPLOYEES_INITIAL =
  "WELLNESS360_SUMMARY_OVERBURDENED_EMPLOYEES_INITIAL";
export const WELLNESS360_SUMMARY_OVERBURDENED_EMPLOYEES_PROCESS =
  "WELLNESS360_SUMMARY_OVERBURDENED_EMPLOYEES_PROCESS";
export const WELLNESS360_SUMMARY_OVERBURDENED_EMPLOYEES_SUCCESS =
  "WELLNESS360_SUMMARY_OVERBURDENED_EMPLOYEES_SUCCESS";
export const WELLNESS360_SUMMARY_OVERBURDENED_EMPLOYEES_FAILED =
  "WELLNESS360_SUMMARY_OVERBURDENED_EMPLOYEES_FAILED";

//*  WELLNESS360 Overburdened employees End *//

//* WELLNESS360 SUMMARY OVERALL SCORE *//

export const WELLNESS360_SUMMARY_OVERALL_SCORE_INITIAL =
  "WELLNESS360_SUMMARY_OVERALL_SCORE_INITIAL";
export const WELLNESS360_SUMMARY_OVERALL_SCORE_PROCESS =
  "WELLNESS360_SUMMARY_OVERALL_SCORE_PROCESS";
export const WELLNESS360_SUMMARY_OVERALL_SCORE_SUCCESS =
  "WELLNESS360_SUMMARY_OVERALL_SCORE_SUCCESS";
export const WELLNESS360_SUMMARY_OVERALL_SCORE_FAILED =
  "WELLNESS360_SUMMARY_OVERALL_SCORE_FAILED";

//*  WELLNESS360 SUMMARY OVERALL SCORE END *//

//* WELLNESS360 ME OVERALL SCORE *//

export const WELLNESS360_ME_OVERALL_SCORE_INITIAL =
  "WELLNESS360_ME_OVERALL_SCORE_INITIAL";
export const WELLNESS360_ME_OVERALL_SCORE_PROCESS =
  "WELLNESS360_ME_OVERALL_SCORE_PROCESS";
export const WELLNESS360_ME_OVERALL_SCORE_SUCCESS =
  "WELLNESS360_ME_OVERALL_SCORE_SUCCESS";
export const WELLNESS360_ME_OVERALL_SCORE_FAILED =
  "WELLNESS360_ME_OVERALL_SCORE_FAILED";

//*  WELLNESS360 ME OVERALL SCORE END *//

//* WELLNESS360 SUMMARY TOP 10 HEALTHY EMPLOYEES *//

export const WELLNESS360_SUMMARY_TOP_TEAMS_INITIAL =
  "WELLNESS360_SUMMARY_TOP_TEAMS_INITIAL";
export const WELLNESS360_SUMMARY_TOP_TEAMS_PROCESS =
  "WELLNESS360_SUMMARY_TOP_TEAMS_PROCESS";
export const WELLNESS360_SUMMARY_TOP_TEAMS_SUCCESS =
  "WELLNESS360_SUMMARY_TOP_TEAMS_SUCCESS";
export const WELLNESS360_SUMMARY_TOP_TEAMS_FAILED =
  "WELLNESS360_SUMMARY_TOP_TEAMS_FAILED";

//*  WELLNESS360 SUMMARY TOP 10 HEALTHY EMPLOYEES END *//

//* WELLNESS360 SUMMARY TEAM WISE UTILIZATION *//

export const WELLNESS360_SUMMARY_TEAM_WISE_UTILIZATION_INITIAL =
  "WELLNESS360_SUMMARY_TEAM_WISE_UTILIZATION_INITIAL";
export const WELLNESS360_SUMMARY_TEAM_WISE_UTILIZATION_PROCESS =
  "WELLNESS360_SUMMARY_TEAM_WISE_UTILIZATION_PROCESS";
export const WELLNESS360_SUMMARY_TEAM_WISE_UTILIZATION_SUCCESS =
  "WELLNESS360_SUMMARY_TEAM_WISE_UTILIZATION_SUCCESS";
export const WELLNESS360_SUMMARY_TEAM_WISE_UTILIZATION_FAILED =
  "WELLNESS360_SUMMARY_TEAM_WISE_UTILIZATION_FAILED";

//*  WELLNESS360 SUMMARY TEAM WISE UTILIZATION *//

//* WELLNESS360 SUMMARY ONLINE TIME BREAK DOWN *//

export const WELLNESS360_SUMMARY_ONLINE_TIME_BREAK_DOWN_INITIAL =
  "WELLNESS360_SUMMARY_ONLINE_TIME_BREAK_DOWN_INITIAL";
export const WELLNESS360_SUMMARY_ONLINE_TIME_BREAK_DOWN_PROCESS =
  "WELLNESS360_SUMMARY_ONLINE_TIME_BREAK_DOWN_PROCESS";
export const WELLNESS360_SUMMARY_ONLINE_TIME_BREAK_DOWN_SUCCESS =
  "WELLNESS360_SUMMARY_ONLINE_TIME_BREAK_DOWN_SUCCESS";
export const WELLNESS360_SUMMARY_ONLINE_TIME_BREAK_DOWN_FAILED =
  "WELLNESS360_SUMMARY_ONLINE_TIME_BREAK_DOWN_FAILED";

//*  WELLNESS360 SUMMARY ONLINE TIME BREAK DOWN *//

//* WELLNESS360 SUMMARY WELLNESS360 LEVEL BREAK DOWN *//

export const WELLNESS360_SUMMARY_WELLNESS360_LEVEL_BREAK_DOWN_INITIAL =
  "WELLNESS360_SUMMARY_WELLNESS360_LEVEL_BREAK_DOWN_INITIAL";
export const WELLNESS360_SUMMARY_WELLNESS360_LEVEL_BREAK_DOWN_PROCESS =
  "WELLNESS360_SUMMARY_WELLNESS360_LEVEL_BREAK_DOWN_PROCESS";
export const WELLNESS360_SUMMARY_WELLNESS360_LEVEL_BREAK_DOWN_SUCCESS =
  "WELLNESS360_SUMMARY_WELLNESS360_LEVEL_BREAK_DOWN_SUCCESS";
export const WELLNESS360_SUMMARY_WELLNESS360_LEVEL_BREAK_DOWN_FAILED =
  "WELLNESS360_SUMMARY_WELLNESS360_LEVEL_BREAK_DOWN_FAILED";

//*  WELLNESS360 SUMMARY WELLNESS360 LEVEL BREAK DOWN End *//

//* WELLNESS360 SUMMARY TOP MOST ACTIVE TEAM  *//

export const WELLNESS360_SUMMARY_TOP_MOST_ACTIVE_TEAM_INITIAL =
  "WELLNESS360_SUMMARY_TOP_MOST_ACTIVE_TEAM_INITIAL";
export const WELLNESS360_SUMMARY_TOP_MOST_ACTIVE_TEAM_PROCESS =
  "WELLNESS360_SUMMARY_TOP_MOST_ACTIVE_TEAM_PROCESS";
export const WELLNESS360_SUMMARY_TOP_MOST_ACTIVE_TEAM_SUCCESS =
  "WELLNESS360_SUMMARY_TOP_MOST_ACTIVE_TEAM_SUCCESS";
export const WELLNESS360_SUMMARY_TOP_MOST_ACTIVE_TEAM_FAILED =
  "WELLNESS360_SUMMARY_TOP_MOST_ACTIVE_TEAM_FAILED";

//*   WELLNESS360 SUMMARY TOP MOST ACTIVE TEAM  End *//

//* WELLNESS360 SUMMARY TOP LEAST ACTIVE TEAM  *//

export const WELLNESS360_SUMMARY_TOP_LEAST_ACTIVE_TEAM_INITIAL =
  "WELLNESS360_SUMMARY_TOP_LEAST_ACTIVE_TEAM_INITIAL";
export const WELLNESS360_SUMMARY_TOP_LEAST_ACTIVE_TEAM_PROCESS =
  "WELLNESS360_SUMMARY_TOP_LEAST_ACTIVE_TEAM_PROCESS";
export const WELLNESS360_SUMMARY_TOP_LEAST_ACTIVE_TEAM_SUCCESS =
  "WELLNESS360_SUMMARY_TOP_LEAST_ACTIVE_TEAM_SUCCESS";
export const WELLNESS360_SUMMARY_TOP_LEAST_ACTIVE_TEAM_FAILED =
  "WELLNESS360_SUMMARY_TOP_LEAST_ACTIVE_TEAM_FAILED";

//*   WELLNESS360 SUMMARY TOP LEAST ACTIVE TEAM  End *//

//* WELLNESS360 SUMMARY TEAM WISE WELLNESS360 BREAK DOWN *//

export const WELLNESS360_SUMMARY_TEAM_WISE_WELLNESS360_BREAK_DOWN_INITIAL =
  "WELLNESS360_SUMMARY_TEAM_WISE_WELLNESS360_BREAK_DOWN_INITIAL";
export const WELLNESS360_SUMMARY_TEAM_WISE_WELLNESS360_BREAK_DOWN_PROCESS =
  "WELLNESS360_SUMMARY_TEAM_WISE_WELLNESS360_BREAK_DOWN_PROCESS";
export const WELLNESS360_SUMMARY_TEAM_WISE_WELLNESS360_BREAK_DOWN_SUCCESS =
  "WELLNESS360_SUMMARY_TEAM_WISE_WELLNESS360_BREAK_DOWN_SUCCESS";
export const WELLNESS360_SUMMARY_TEAM_WISE_WELLNESS360_BREAK_DOWN_FAILED =
  "WELLNESS360_SUMMARY_TEAM_WISE_WELLNESS360_BREAK_DOWN_FAILED";

//*  WELLNESS360 SUMMARY ENDTEAM WISE WELLNESS360 BREAK DOWN *//

// WELLNESS360 SUMMARY HEALTHY EMPLOYEES //
