//* ACTIVITY SUMMARY WEDIGT *//

export const ACTIVITY_SUMMARY_WEDIGT_INITIAL =
  "ACTIVITY_SUMMARY_WEDIGT_INITIAL";
export const ACTIVITY_SUMMARY_WEDIGT_PROCESS =
  "ACTIVITY_SUMMARY_WEDIGT_PROCESS";
export const ACTIVITY_SUMMARY_WEDIGT_SUCCESS =
  "ACTIVITY_SUMMARY_WEDIGT_SUCCESS";
export const ACTIVITY_SUMMARY_WEDIGT_FAILED = "ACTIVITY_SUMMARY_WEDIGT_FAILED";

//*  ACTIVITY WEDIGT End *//

//* ACTIVITY SUMMARY ONLINE TIME BREAK DOWN *//

export const ACTIVITY_SUMMARY_ONLINE_TIME_BREAK_DOWN_INITIAL =
  "ACTIVITY_SUMMARY_ONLINE_TIME_BREAK_DOWN_INITIAL";
export const ACTIVITY_SUMMARY_ONLINE_TIME_BREAK_DOWN_PROCESS =
  "ACTIVITY_SUMMARY_ONLINE_TIME_BREAK_DOWN_PROCESS";
export const ACTIVITY_SUMMARY_ONLINE_TIME_BREAK_DOWN_SUCCESS =
  "ACTIVITY_SUMMARY_ONLINE_TIME_BREAK_DOWN_SUCCESS";
export const ACTIVITY_SUMMARY_ONLINE_TIME_BREAK_DOWN_FAILED =
  "ACTIVITY_SUMMARY_ONLINE_TIME_BREAK_DOWN_FAILED";

//*  ACTIVITY SUMMARY ONLINE TIME BREAK DOWN *//

//* ACTIVITY SUMMARY ACTIVITY LEVEL BREAK DOWN *//

export const ACTIVITY_SUMMARY_ACTIVITY_LEVEL_BREAK_DOWN_INITIAL =
  "ACTIVITY_SUMMARY_ACTIVITY_LEVEL_BREAK_DOWN_INITIAL";
export const ACTIVITY_SUMMARY_ACTIVITY_LEVEL_BREAK_DOWN_PROCESS =
  "ACTIVITY_SUMMARY_ACTIVITY_LEVEL_BREAK_DOWN_PROCESS";
export const ACTIVITY_SUMMARY_ACTIVITY_LEVEL_BREAK_DOWN_SUCCESS =
  "ACTIVITY_SUMMARY_ACTIVITY_LEVEL_BREAK_DOWN_SUCCESS";
export const ACTIVITY_SUMMARY_ACTIVITY_LEVEL_BREAK_DOWN_FAILED =
  "ACTIVITY_SUMMARY_ACTIVITY_LEVEL_BREAK_DOWN_FAILED";

//*  ACTIVITY SUMMARY ACTIVITY LEVEL BREAK DOWN End *//

//* ACTIVITY SUMMARY TOP MOST ACTIVE TEAM  *//

export const ACTIVITY_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_INITIAL =
  "ACTIVITY_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_INITIAL";
export const ACTIVITY_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_PROCESS =
  "ACTIVITY_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_PROCESS";
export const ACTIVITY_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_SUCCESS =
  "ACTIVITY_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_SUCCESS";
export const ACTIVITY_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_FAILED =
  "ACTIVITY_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_FAILED";

//*   ACTIVITY SUMMARY TOP MOST ACTIVE TEAM  End *//

//* ACTIVITY SUMMARY TOP LEAST ACTIVE TEAM  *//

export const ACTIVITY_SUMMARY_TOP_LEAST_ACTIVE_TEAM_INITIAL =
  "ACTIVITY_SUMMARY_TOP_LEAST_ACTIVE_TEAM_INITIAL";
export const ACTIVITY_SUMMARY_TOP_LEAST_ACTIVE_TEAM_PROCESS =
  "ACTIVITY_SUMMARY_TOP_LEAST_ACTIVE_TEAM_PROCESS";
export const ACTIVITY_SUMMARY_TOP_LEAST_ACTIVE_TEAM_SUCCESS =
  "ACTIVITY_SUMMARY_TOP_LEAST_ACTIVE_TEAM_SUCCESS";
export const ACTIVITY_SUMMARY_TOP_LEAST_ACTIVE_TEAM_FAILED =
  "ACTIVITY_SUMMARY_TOP_LEAST_ACTIVE_TEAM_FAILED";

//*   ACTIVITY SUMMARY TOP LEAST ACTIVE TEAM  End *//

//* ACTIVITY SUMMARY TEAM WISE ACTIVITY BREAK DOWN *//

export const ACTIVITY_SUMMARY_TEAM_WISE_ACTIVITY_BREAK_DOWN_INITIAL =
  "ACTIVITY_SUMMARY_TEAM_WISE_ACTIVITY_BREAK_DOWN_INITIAL";
export const ACTIVITY_SUMMARY_TEAM_WISE_ACTIVITY_BREAK_DOWN_PROCESS =
  "ACTIVITY_SUMMARY_TEAM_WISE_ACTIVITY_BREAK_DOWN_PROCESS";
export const ACTIVITY_SUMMARY_TEAM_WISE_ACTIVITY_BREAK_DOWN_SUCCESS =
  "ACTIVITY_SUMMARY_TEAM_WISE_ACTIVITY_BREAK_DOWN_SUCCESS";
export const ACTIVITY_SUMMARY_TEAM_WISE_ACTIVITY_BREAK_DOWN_FAILED =
  "ACTIVITY_SUMMARY_TEAM_WISE_ACTIVITY_BREAK_DOWN_FAILED";

//*  ACTIVITY SUMMARY ENDTEAM WISE ACTIVITY BREAK DOWN *//

//* ACTIVITY SUMMARY TOP APP URL APPLICATION *//

export const ACTIVITY_SUMMARY_TOP_APPLICATION_URL_INITIAL =
  "ACTIVITY_SUMMARY_TOP_APPLICATION_URL_INITIAL";
export const ACTIVITY_SUMMARY_TOP_APPLICATION_URL_PROCESS =
  "ACTIVITY_SUMMARY_TOP_APPLICATION_URL_PROCESS";
export const ACTIVITY_SUMMARY_TOP_APPLICATION_URL_SUCCESS =
  "ACTIVITY_SUMMARY_TOP_APPLICATION_URL_SUCCESS";
export const ACTIVITY_SUMMARY_TOP_APPLICATION_URL_FAILED =
  "ACTIVITY_SUMMARY_TOP_APPLICATION_URL_FAILED";

//*  ACTIVITY SUMMARY TOP APPLICATION *//
