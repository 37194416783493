export const WELLNESS360_DETAILS_TRENDS_INITIAL =
  "WELLNESS360_DETAILS_TRENDS_INITIAL";
export const WELLNESS360_DETAILS_TRENDS_PROCESS =
  "WELLNESS360_DETAILS_TRENDS_PROCESS";
export const WELLNESS360_DETAILS_TRENDS_SUCCESS =
  "WELLNESS360_DETAILS_TRENDS_SUCCESS";
export const WELLNESS360_DETAILS_TRENDS_FAILED =
  "WELLNESS360_DETAILS_TRENDS_FAILED";

export const WELLNESS360_DETAILS_EMPLOYEE_LIST_INITIAL =
  "WELLNESS360_DETAILS_EMPLOYEE_LIST_INITIAL";
export const WELLNESS360_DETAILS_EMPLOYEE_LIST_PROCESS =
  "WELLNESS360_DETAILS_EMPLOYEE_LIST_PROCESS";
export const WELLNESS360_DETAILS_EMPLOYEE_LIST_SUCCESS =
  "WELLNESS360_DETAILS_EMPLOYEE_LIST_SUCCESS";
export const WELLNESS360_DETAILS_EMPLOYEE_LIST_FAILED =
  "WELLNESS360_DETAILS_EMPLOYEE_LIST_FAILED";

export const WELLNESS360_EMPLOYEE_DETAILS_EMPLOYEE_LIST_INITIAL =
  "WELLNESS360_EMPLOYEE_DETAILS_EMPLOYEE_LIST_INITIAL";
export const WELLNESS360_EMPLOYEE_DETAILS_EMPLOYEE_LIST_PROCESS =
  "WELLNESS360_EMPLOYEE_DETAILS_EMPLOYEE_LIST_PROCESS";
export const WELLNESS360_EMPLOYEE_DETAILS_EMPLOYEE_LIST_SUCCESS =
  "WELLNESS360_EMPLOYEE_DETAILS_EMPLOYEE_LIST_SUCCESS";
export const WELLNESS360_EMPLOYEE_DETAILS_EMPLOYEE_LIST_FAILED =
  "WELLNESS360_EMPLOYEE_DETAILS_EMPLOYEE_LIST_FAILED";

export const WELLNESS360_DOWNLOAD_EMPLOYEE_LIST_INITIAL =
  "WELLNESS360_DOWNLOAD_EMPLOYEE_LIST_INITIAL";
export const WELLNESS360_DOWNLOAD_EMPLOYEE_LIST_PROCESS =
  "WELLNESS360_DOWNLOAD_EMPLOYEE_LIST_PROCESS";
export const WELLNESS360_DOWNLOAD_EMPLOYEE_LIST_SUCCESS =
  "WELLNESS360_DOWNLOAD_EMPLOYEE_LIST_SUCCESS";
export const WELLNESS360_DOWNLOAD_EMPLOYEE_LIST_FAILED =
  "WELLNESS360_DOWNLOAD_EMPLOYEE_LIST_FAILED";
