import * as actionTypes from "./actionTypes";

export const fetchCrossDayAttendanceReport = (payload) => {
  return {
    type: actionTypes.FETCH_CROSS_DAY_ATTENDANCE_REPORT,
    payload,
  };
};

export const fetchCrossDayAttendanceReportSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_CROSS_DAY_ATTENDANCE_REPORT_SUCCESS,
    payload,
  };
};

export const fetchCrossDayAttendanceReportFailed = (payload) => {
  return {
    type: actionTypes.FETCH_CROSS_DAY_ATTENDANCE_REPORT_FAILED,
    payload,
  };
};

export const downloadCrossDayAttendanceReport = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_CROSS_DAY_ATTENDANCE_REPORT,
    payload,
  };
};

export const downloadCrossDayAttendanceReportSuccess = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_CROSS_DAY_ATTENDANCE_REPORT_SUCCESS,
    payload,
  };
};

export const downloadCrossDayAttendanceReportFailed = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_CROSS_DAY_ATTENDANCE_REPORT_FAILED,
    payload,
  };
};
