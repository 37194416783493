// TIMELINE TABLE

export const FETCH_TIMELINE_TABLE = "FETCH_TIMELINE_TABLE";
export const FETCH_TIMELINE_TABLE_SUCCESS = "FETCH_TIMELINE_TABLE_SUCCESS";
export const FETCH_TIMELINE_TABLE_FAILED = "FETCH_TIMELINE_TABLE_FAILED";

// Hourly session drawer
export const SET_HOURLY_SESSION_BREAKDOWN = "SET_HOURLY_SESSION_BREAKDOWN";
export const FETCH_HOURLY_ACTIVITY_PRODUCTIVITY =
  "FETCH_HOURLY_ACTIVITY_PRODUCTIVITY";
export const FETCH_HOURLY_ACTIVITY_PRODUCTIVITY_SUCCESS =
  "FETCH_HOURLY_ACTIVITY_PRODUCTIVITY_SUCCESS";
export const FETCH_HOURLY_ACTIVITY_PRODUCTIVITY_FAILED =
  "FETCH_HOURLY_ACTIVITY_PRODUCTIVITY_FAILED";
export const SET_HOURLY_ACTIVITY_PRODUCTIVITY_LOADING =
  "SET_HOURLY_ACTIVITY_PRODUCTIVITY_LOADING";

export const FETCH_PUNCH_LOGS = "FETCH_PUNCH_LOGS";
export const FETCH_PUNCH_LOGS_SUCCESS = "FETCH_PUNCH_LOGS_SUCCESS";
export const FETCH_PUNCH_LOGS_FAILED = "FETCH_PUNCH_LOGS_FAILED";

export const FETCH_POWER_LOGS = "FETCH_POWER_LOGS";
export const FETCH_POWER_LOGS_SUCCESS = "FETCH_POWER_LOGS_SUCCESS";
export const FETCH_POWER_LOGS_FAILED = "FETCH_POWER_LOGS_FAILED";

export const FETCH_SESSION_APP_LOG = "FETCH_SESSION_APP_LOG";
export const FETCH_SESSION_APP_LOG_SUCCESS = "FETCH_SESSION_APP_LOG_SUCCESS";
export const FETCH_SESSION_APP_LOG_FAILED = "FETCH_SESSION_APP_LOG_FAILED";
