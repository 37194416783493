import React, { useContext } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Typography, Tag, Space, Tooltip } from "antd";

import "./header.scss";
import { AuthContext } from "../../contexts/authContext";

import { getAvatarUrl } from "utils/transport";
import Upload from "common/Upload/index.tsx";
import URLs from "constants/urls";
import { getNamefromIdentity } from "utils/commonUtils";

const { Text, Title } = Typography;

const Profileinfo = () => {
  const users = useSelector((state) => state.users);
  const { coreApiUrl } = useContext(AuthContext);

  return (
    <div className="profileinfo">
      <div className="p_pic">
        <Upload
          avatarURI={
            users.medetails_data?.identity?.avatar_uri === "@we360"
              ? getAvatarUrl(coreApiUrl, users.medetails_data?.identity?.id)
              : users.medetails_data?.identity?.avatar_uri
          }
          apiPath={URLs.ME_AVATAR_PATH}
        />
      </div>
      <div className="p_info">
        <Space direction="vertical" size={"small"} style={{ width: "100%" }}>
          <Title
            className="mb-0"
            level={3}
            style={{ width: "90%" }}
            ellipsis={{
              tooltip: getNamefromIdentity(users.medetails_data?.identity),
            }}
          >
            {getNamefromIdentity(users.medetails_data?.identity)}
          </Title>
          <Text
            style={{ width: "90%" }}
            ellipsis={{ tooltip: users.medetails_data?.identity?.email }}
          >
            {users.medetails_data?.identity?.email}
          </Text>
          <div className="flex item-center">
            <Tooltip title={"Role"}>
              <Tag color="processing" style={{ textAlign: "center" }}>
                {/* Manager */}
                {users?.me_role_data?.role?.name}
              </Tag>
            </Tooltip>
            {users.medetails_data?.employee_id && (
              <Tooltip title={"Employee ID"}>
                <Tag color="magenta">
                  {/* Manager */}
                  {users.medetails_data?.employee_id}
                </Tag>
              </Tooltip>
            )}
            <Tooltip title={"Designation"}>
              {users &&
              users.medetails_data &&
              users.medetails_data.designation != null ? (
                <Tag color="success" style={{ textAlign: "center" }}>
                  {/* UI/Ux Desinger */}
                  {users &&
                    users.medetails_data &&
                    users.medetails_data.designation &&
                    users.medetails_data.designation.name}
                </Tag>
              ) : null}
            </Tooltip>
          </div>
        </Space>
      </div>
    </div>
  );
};

Profileinfo.propTypes = {
  showBackground: PropTypes.bool,
  silderBtn: PropTypes.func,
};

export default Profileinfo;
