import * as actionTypes from "./actionTypes";

const initialState = {
  application_report_graph_processing: false,
  application_report_graph_success: false,
  application_report_graph_failed: false,
  application_report_graph_data: [],
  application_report_graph_message: "",

  application_report_list_processing: false,
  application_report_list_success: false,
  application_report_list_failed: false,
  application_report_list_pagination: {},
  application_report_list_data: [],
  application_report_list_message: "",

  application_report_export_list_processing: false,
  application_report_export_list_success: false,
  application_report_export_list_failed: false,
  application_report_export_list_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.APPLICATION_REPORT_GRAPH_INITIAL:
      return {
        ...state,
        application_report_graph_processing: false,
        application_report_graph_success: false,
        application_report_graph_failed: false,
        application_report_graph_data: [],
        application_report_graph_message: "",
      };
    case actionTypes.APPLICATION_REPORT_GRAPH_PROCESS:
      return {
        ...state,
        application_report_graph_processing: true,
        application_report_graph_success: false,
        application_report_graph_failed: false,
        application_report_graph_data: [],
        application_report_graph_message: "",
      };
    case actionTypes.APPLICATION_REPORT_GRAPH_SUCCESS:
      return {
        ...state,
        application_report_graph_processing: false,
        application_report_graph_success: true,
        application_report_graph_failed: false,
        application_report_graph_data: action?.response,
        application_report_graph_message: "",
      };
    case actionTypes.APPLICATION_REPORT_GRAPH_FAILED:
      return {
        ...state,
        application_report_graph_processing: false,
        application_report_graph_success: false,
        application_report_graph_failed: true,
        application_report_graph_message: action?.error,
        application_report_graph_data: [],
      };

    case actionTypes.APPLICATION_REPORT_LIST_INITIAL:
      return {
        ...state,
        application_report_list_processing: false,
        application_report_list_success: false,
        application_report_list_failed: false,
        application_report_list_data: [],
        application_report_list_pagination: {},
        application_report_list_message: "",
      };
    case actionTypes.APPLICATION_REPORT_LIST_PROCESS:
      return {
        ...state,
        application_report_list_processing: true,
        application_report_list_success: false,
        application_report_list_failed: false,
        application_report_list_data: [],
        application_report_list_pagination: {},
        application_report_list_message: "",
      };
    case actionTypes.APPLICATION_REPORT_LIST_SUCCESS:
      return {
        ...state,
        application_report_list_processing: false,
        application_report_list_success: true,
        application_report_list_failed: false,
        application_report_list_data: action?.response.data,
        application_report_list_pagination:
          action?.response?.optional?.pagination,
        application_report_list_message: "",
      };
    case actionTypes.APPLICATION_REPORT_LIST_FAILED:
      return {
        ...state,
        application_report_list_processing: false,
        application_report_list_success: false,
        application_report_list_failed: true,
        application_report_list_message: action?.error,
        application_report_list_data: [],
        application_report_list_pagination: {},
      };

    case actionTypes.APPLICATION_REPORT_EXPORT_LIST_INITIAL:
      return {
        ...state,
        application_report_export_list_processing: false,
        application_report_export_list_success: false,
        application_report_export_list_failed: false,
        application_report_export_list_message: "",
      };
    case actionTypes.APPLICATION_REPORT_EXPORT_LIST_PROCESS:
      return {
        ...state,
        application_report_export_list_processing: true,
        application_report_export_list_success: false,
        application_report_export_list_failed: false,
        application_report_export_list_message: "",
      };
    case actionTypes.APPLICATION_REPORT_EXPORT_LIST_SUCCESS:
      return {
        ...state,
        application_report_export_list_processing: false,
        application_report_export_list_success: true,
        application_report_export_list_failed: false,
        application_report_export_list_message: "",
      };
    case actionTypes.APPLICATION_REPORT_EXPORT_LIST_FAILED:
      return {
        ...state,
        application_report_export_list_processing: false,
        application_report_export_list_success: false,
        application_report_export_list_failed: true,
        application_report_export_list_message: action?.error,
      };

    default:
      return state;
  }
};
