import * as actionTypes from "./actionTypes";
import _ from "lodash";

const initialState = {
  //*GLOBAL*//

  //* PRODUCTIVITY detailed WORKIGN TIME TRENDS  *//

  productivity_detailed_working_time_trend_processing: false,
  productivity_detailed_working_time_trend_success: false,
  productivity_detailed_working_time_trend_failed: false,
  productivity_detailed_working_time_trend_data: [],
  productivity_detailed_working_time_trend_message: "",

  //* PRODUCTIVITY detailed TEAM WISE PRODUCTIVITY TRENDS  *//

  productivity_detailed_team_wise_productivity_trend_processing: false,
  productivity_detailed_team_wise_productivity_trend_success: false,
  productivity_detailed_team_wise_productivity_trend_failed: false,
  productivity_detailed_team_wise_productivity_trend_data: [],
  productivity_detailed_team_wise_productivity_trend_message: "",

  //* PRODUCTIVITY detailed employee_list WEDIGT *//

  productivity_detailed_employee_list_processing: false,
  productivity_detailed_employee_list_success: false,
  productivity_detailed_employee_list_failed: false,
  productivity_detailed_employee_list_data: [],
  productivity_detailed_employee_list_message: "",

  //* PRODUCTIVITY EMPLOYEE detailed employee_list WEDIGT *//

  productivity_employee_detailed_employee_list_processing: false,
  productivity_employee_detailed_employee_list_success: false,
  productivity_employee_detailed_employee_list_failed: false,
  productivity_employee_detailed_employee_list_data: [],
  productivity_employee_detailed_employee_list_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.PRODUCTIVITY_DETAILED_WORKING_TIME_TREND_INITIAL:
      return {
        ...state,
        productivity_detailed_working_time_trend_processing: false,
        productivity_detailed_working_time_trend_success: false,
        productivity_detailed_working_time_trend_failed: false,
        productivity_detailed_working_time_trend_data: [],
        productivity_detailed_working_time_trend_message: "",
      };

    case actionTypes.PRODUCTIVITY_DETAILED_WORKING_TIME_TREND_PROCESS:
      return {
        ...state,
        productivity_detailed_working_time_trend_processing: true,
        productivity_detailed_working_time_trend_success: false,
        productivity_detailed_working_time_trend_failed: false,
        productivity_detailed_working_time_trend_data: [],
        productivity_detailed_working_time_trend_message: "",
      };

    case actionTypes.PRODUCTIVITY_DETAILED_WORKING_TIME_TREND_SUCCESS:
      return {
        ...state,
        productivity_detailed_working_time_trend_processing: false,
        productivity_detailed_working_time_trend_success: true,
        productivity_detailed_working_time_trend_failed: false,
        productivity_detailed_working_time_trend_data: action.data,
        productivity_detailed_working_time_trend_message: "",
      };
    case actionTypes.PRODUCTIVITY_DETAILED_WORKING_TIME_TREND_FAILED:
      return {
        ...state,
        productivity_detailed_working_time_trend_processing: false,
        productivity_detailed_working_time_trend_success: false,
        productivity_detailed_working_time_trend_failed: true,
        productivity_detailed_working_time_trend_data: [],
        productivity_detailed_working_time_trend_message: action.data,
      };
    case actionTypes.PRODUCTIVITY_DETAILED_TEAM_WISE_PRODUCTIVITY_TREND_INITIAL:
      return {
        ...state,
        productivity_detailed_team_wise_productivity_trend_processing: false,
        productivity_detailed_team_wise_productivity_trend_success: false,
        productivity_detailed_team_wise_productivity_trend_failed: false,
        productivity_detailed_team_wise_productivity_trend_data: [],
        productivity_detailed_team_wise_productivity_trend_message: "",
      };

    case actionTypes.PRODUCTIVITY_DETAILED_TEAM_WISE_PRODUCTIVITY_TREND_PROCESS:
      return {
        ...state,
        productivity_detailed_team_wise_productivity_trend_processing: true,
        productivity_detailed_team_wise_productivity_trend_success: false,
        productivity_detailed_team_wise_productivity_trend_failed: false,
        productivity_detailed_team_wise_productivity_trend_data: [],
        productivity_detailed_team_wise_productivity_trend_message: "",
      };

    case actionTypes.PRODUCTIVITY_DETAILED_TEAM_WISE_PRODUCTIVITY_TREND_SUCCESS:
      return {
        ...state,
        productivity_detailed_team_wise_productivity_trend_processing: false,
        productivity_detailed_team_wise_productivity_trend_success: true,
        productivity_detailed_team_wise_productivity_trend_failed: false,
        productivity_detailed_team_wise_productivity_trend_data: action.data,
        productivity_detailed_team_wise_productivity_trend_message: "",
      };
    case actionTypes.PRODUCTIVITY_DETAILED_TEAM_WISE_PRODUCTIVITY_TREND_FAILED:
      return {
        ...state,
        productivity_detailed_team_wise_productivity_trend_processing: false,
        productivity_detailed_team_wise_productivity_trend_success: false,
        productivity_detailed_team_wise_productivity_trend_failed: true,
        productivity_detailed_team_wise_productivity_trend_data: [],
        productivity_detailed_team_wise_productivity_trend_message: action.data,
      };
    case actionTypes.PRODUCTIVITY_DETAILED_EMPLOYEE_LIST_INITIAL:
      return {
        ...state,
        productivity_detailed_employee_list_processing: false,
        productivity_detailed_employee_list_success: false,
        productivity_detailed_employee_list_failed: false,
        productivity_detailed_employee_list_data: [],
        productivity_detailed_employee_list_message: "",
      };

    case actionTypes.PRODUCTIVITY_DETAILED_EMPLOYEE_LIST_PROCESS:
      return {
        ...state,
        productivity_detailed_employee_list_processing: true,
        productivity_detailed_employee_list_success: false,
        productivity_detailed_employee_list_failed: false,
        productivity_detailed_employee_list_data: [],
        productivity_detailed_employee_list_message: "",
      };

    case actionTypes.PRODUCTIVITY_DETAILED_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        productivity_detailed_employee_list_processing: false,
        productivity_detailed_employee_list_success: true,
        productivity_detailed_employee_list_failed: false,
        productivity_detailed_employee_list_data: action.data,
        productivity_detailed_employee_list_message: "",
      };
    case actionTypes.PRODUCTIVITY_DETAILED_EMPLOYEE_LIST_FAILED:
      return {
        ...state,
        productivity_detailed_employee_list_processing: false,
        productivity_detailed_employee_list_success: false,
        productivity_detailed_employee_list_failed: true,
        productivity_detailed_employee_list_data: [],
        productivity_detailed_employee_list_message: action.data,
      };
    case actionTypes.PRODUCTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_INITIAL:
      return {
        ...state,
        productivity_employee_detailed_employee_list_processing: false,
        productivity_employee_detailed_employee_list_success: false,
        productivity_employee_detailed_employee_list_failed: false,
        productivity_employee_detailed_employee_list_data: [],
        productivity_employee_detailed_employee_list_message: "",
      };

    case actionTypes.PRODUCTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_PROCESS:
      return {
        ...state,
        productivity_employee_detailed_employee_list_processing: true,
        productivity_employee_detailed_employee_list_success: false,
        productivity_employee_detailed_employee_list_failed: false,
        productivity_employee_detailed_employee_list_data: [],
        productivity_employee_detailed_employee_list_message: "",
      };

    case actionTypes.PRODUCTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        productivity_employee_detailed_employee_list_processing: false,
        productivity_employee_detailed_employee_list_success: true,
        productivity_employee_detailed_employee_list_failed: false,
        productivity_employee_detailed_employee_list_data: action.data,
        productivity_employee_detailed_employee_list_message: "",
      };
    case actionTypes.PRODUCTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_FAILED:
      return {
        ...state,
        productivity_employee_detailed_employee_list_processing: false,
        productivity_employee_detailed_employee_list_success: false,
        productivity_employee_detailed_employee_list_failed: true,
        productivity_employee_detailed_employee_list_data: [],
        productivity_employee_detailed_employee_list_message: action.data,
      };
    default:
      return state;
  }
};
