import { put } from "redux-saga/effects";
import { BreakAction } from "../action";
import { Client } from "../../../utils/transport";

export function* GetBreaksData(data) {
  let config = {
    method: "GET",
    url: "api/v1/admin/break_types/",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(BreakAction.BreaksListSuccess(response.data));
    } else {
      yield put(BreakAction.BreaksListFailed(response));
    }
  } catch (err) {
    yield put(BreakAction.BreaksListFailed(err.response));
  }
}
//CREATE BREAKS

export function* CreateBreaksData(data) {
  let config = {
    method: "POST",
    url: "BREAK_PHASE_1",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.Bodydata,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(BreakAction.BreaksCreateSuccess(response.data));
    } else {
      yield put(BreakAction.BreaksCreateFailed(response));
    }
  } catch (err) {
    yield put(BreakAction.BreaksCreateFailed(err.response));
  }
}

//EDIT BREAK
export function* EditBreaksData(data) {
  let config = {
    method: "PUT",
    // url: "BREAK_PHASE_1",
    url: "api/v1/break_types/" + data.data.id,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.Bodydata,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(BreakAction.BreaksEditSuccess(response.data));
    } else {
      yield put(BreakAction.BreaksEditFailed(response));
    }
  } catch (err) {
    yield put(BreakAction.BreaksEditFailed(err.response));
  }
}
