//GET ACTIVITY

import * as actionTypes from "./actionTypes";

export const GroupInsightReportInitial = () => {
  return {
    type: actionTypes.GROUP_INSIGHT_REPORT_INITIAL,
  };
};
export const GroupInsightReportProcess = (token, bodyData) => {
  return {
    type: actionTypes.GROUP_INSIGHT_REPORT_PROCESS,
    token,
    bodyData,
  };
};
export const GroupInsightReportSuccess = (data) => {
  return {
    type: actionTypes.GROUP_INSIGHT_REPORT_SUCCESS,
    data,
  };
};
export const GroupInsightReportFailed = (data) => {
  return {
    type: actionTypes.GROUP_INSIGHT_REPORT_FAILED,
    data,
  };
};
