export const LEAVE_LIST_INITIAL = "LEAVE_LIST_INITIAL";
export const LEAVE_LIST_PROCESS = "LEAVE_LIST_PROCESS";
export const LEAVE_LIST_SUCCESS = "LEAVE_LIST_SUCCESS";
export const LEAVE_LIST_FAILED = "LEAVE_LIST_FAILED";

export const LEAVE_CREATE_INITIAL = "LEAVE_CREATE_INITIAL ";
export const LEAVE_CREATE_PROCESS = "LEAVE_CREATE_PROCESS ";
export const LEAVE_CREATE_SUCCESS = "LEAVE_CREATE_SUCCESS";
export const LEAVE_CREATE_FAILED = "LEAVE_CREATE_FAILED";

export const LEAVE_EDIT_INITIAL = "LEAVE_EDIT_INITIAL ";
export const LEAVE_EDIT_PROCESS = "LEAVE_EDIT_PROCESS ";
export const LEAVE_EDIT_SUCCESS = "LEAVE_EDIT_SUCCESS";
export const LEAVE_EDIT_FAILED = "LEAVE_EDIT_FAILED";
