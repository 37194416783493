import React from "react";

import Task from "./../assets/images/task/type_task.svg";
import Epic from "./../assets/images/task/epic.svg";
import Bug from "./../assets/images/task/bug.svg";
import SubTask from "./../assets/images/task/type_subtask.svg";

interface Props {
  taskType: TaskType;
  className?: string;
}

const TaskTypeIcon = ({ taskType, className = "" }: Props) => {
  const getIcon = (taskType: TaskType) => {
    switch (taskType) {
      case "BUG":
        return Bug;
      case "EPIC":
        return Epic;
      case "TASK":
        return Task;
      case "SUB_TASK":
        return SubTask;
      default:
        return Task;
    }
  };

  return <img className={className} src={getIcon(taskType)} />;
};

export default TaskTypeIcon;
