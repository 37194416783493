import React from "react";
import PropTypes from "prop-types";
import Chart from "react-apexcharts"; // Assuming you are using react-apexcharts
import { Skeleton, Empty } from "antd";
import { hm, ms } from "../../../../utils/timeConverter";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

import { GetMaximumValue, GetMinimumValue } from "utils/getMinimunMaximumValue";
import { getColorCodes } from "utils/colorCodes";
import { ApexOptions } from "apexcharts"; // Import ApexOptions type

const colorCodes = getColorCodes();

const Cardskeleton = () => {
  return (
    <>
      <div className="charts">
        <div className="chart_header">
          <Skeleton.Input
            size={"small"}
            // shape={"default"}
            style={{ height: "15px" }}
          />
          <Skeleton.Avatar
            size={"small"}
            shape={"circle"}
            style={{ height: "15px", width: "15px" }}
          />
        </div>
        <div className="chartContainer all_tool" id="chart">
          {/* chart */}
          <Skeleton.Avatar
            size={"large"}
            // shape={"default"}
            style={{ height: "300px", width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};
interface UserMonthlyProductivityTrendProps {
  teamWiseProductivityTrendData: {
    productiveTime?: number[];
    unproductiveTime?: number[];
    neutralTime?: number[];
    formattedDate?: string[];
    date?: string[];
  };
  hideTooltip?: boolean;
  title?: string;
  tooltip?: string;
}

const UserMonthlyProductivityTrend: React.FC<
  UserMonthlyProductivityTrendProps
> = (props) => {
  const { teamWiseProductivityTrendData } = props || {};
  const productivitydetail = useSelector(
    (state: any) => state.productivitydetail
  );

  const arrays = [
    teamWiseProductivityTrendData?.productiveTime,
    teamWiseProductivityTrendData?.unproductiveTime,
    teamWiseProductivityTrendData?.neutralTime,
  ];
  const minValue = GetMinimumValue(arrays);
  const maxValue = GetMaximumValue(arrays);

  const trendData1 = {
    series: [
      {
        name: "Productive time",
        type: "line",
        data: teamWiseProductivityTrendData?.productiveTime || [],
      },
      {
        name: "Unproductive time",
        type: "line",
        data: teamWiseProductivityTrendData?.unproductiveTime || [],
      },
      {
        name: "Neutral  time",
        type: "line",
        data: teamWiseProductivityTrendData?.neutralTime || [],
      },
    ],
  };

  const dataOptions: ApexOptions = {
    chart: {
      background: "transparent",
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    colors: [
      colorCodes.productiveTime,
      colorCodes.unproductiveTime,
      colorCodes.neutralTime,
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    grid: {
      show: true,
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    xaxis: {
      categories: teamWiseProductivityTrendData?.formattedDate,
      labels: {
        show: false,
        rotate: -45,
        rotateAlways: true,
        hideOverlappingLabels: true,
        trim: true,
        style: {
          colors: colorCodes.graph_legends_font_color,
        },
      },
      axisBorder: {
        show: true,
        offsetY: 2,
      },
    },
    yaxis: {
      min: minValue >= 0 ? 0 : minValue - 10800,
      max: maxValue > 3659 ? maxValue + 10800 : 3660,
      tickAmount: 4,
      labels: {
        style: {
          colors: colorCodes.graph_legends_font_color,
        },
        formatter: function (val: number) {
          if (maxValue > 3659) {
            return Math.floor(val / 3600) + "h";
          } else {
            const minutes = Math.floor(val / 60);
            return minutes + "m";
          }
        },
      },
    },
    legend: {
      show: false,
      position: "top",
      horizontalAlign: "center",
    },
    tooltip: {
      enabled: true,
      theme: "light",
      custom: function ({ w, dataPointIndex }) {
        const date = dayjs(
          teamWiseProductivityTrendData?.date[dataPointIndex]
        ).format("DD-MMM-YYYY");
        const productiveTime =
          maxValue > 3659
            ? hm(teamWiseProductivityTrendData?.productiveTime[dataPointIndex])
            : ms(teamWiseProductivityTrendData?.productiveTime[dataPointIndex]);
        const unproductiveTime =
          maxValue > 3659
            ? hm(
                teamWiseProductivityTrendData?.unproductiveTime[dataPointIndex]
              )
            : ms(
                teamWiseProductivityTrendData?.unproductiveTime[dataPointIndex]
              );
        const neutralTime =
          maxValue > 3659
            ? hm(teamWiseProductivityTrendData?.neutralTime[dataPointIndex])
            : ms(teamWiseProductivityTrendData?.neutralTime[dataPointIndex]);
        return (
          '<div class="bartooltip">' +
          date +
          '</div></div><div class="tooltip_card"><div class="w_data"><div class="colordot" style="background:' +
          w.globals.colors[0] +
          '"></div>' +
          w.globals.seriesNames[0] +
          '<div class="w_value">' +
          productiveTime +
          '</div></div><div class="w_data"><div class="colordot" style="background:' +
          w.globals.colors[1] +
          '"></div>' +
          w.globals.seriesNames[1] +
          '<div class="w_value">' +
          unproductiveTime +
          '</div></div><div class="w_data"><div class="colordot" style="background:' +
          w.globals.colors[2] +
          '"></div>' +
          w.globals.seriesNames[2] +
          '<div class="w_value">' +
          neutralTime +
          "</div></div></div>"
        );
      },
    },
  };

  return (
    <>
      {productivitydetail &&
      productivitydetail.productivity_detailed_team_wise_productivity_trend_processing ===
        true ? (
        <Cardskeleton />
      ) : (
        <div className="charts shadow-none border-0">
          <div
            className="chartContainer all_tool pl-0 pr-0"
            id="chart"
            style={{ marginTop: "-40px" }}
          >
            {/* chart */}
            {(
              productivitydetail?.productivity_detailed_team_wise_productivity_trend_data ||
              []
            ).length > 0 ? (
              <Chart
                options={dataOptions}
                series={trendData1?.series || []}
                type="line"
                height={280}
              />
            ) : (
              <div style={{ height: "271px" }} className="emptyblock">
                <Empty description={false} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

UserMonthlyProductivityTrend.propTypes = {
  teamWiseProductivityTrendData: PropTypes.object,
  hideTooltip: PropTypes.bool,
  title: PropTypes.string,
  tooltip: PropTypes.string,
};

export default UserMonthlyProductivityTrend;
