import * as actionTypes from "./actionTypes";

export const setTeamUserPin = (payload) => {
  return {
    type: actionTypes.SET_TEAM_USER_PIN,
    payload,
  };
};

export const setDatePin = (payload) => {
  return {
    type: actionTypes.SET_DATE_PIN,
    payload,
  };
};

export const setTeamIds = (payload) => {
  return {
    type: actionTypes.SET_TEAM_IDS,
    payload,
  };
};

export const setTeamParentIds = (payload) => {
  return {
    type: actionTypes.SET_TEAM_PARENT_IDS,
    payload,
  };
};

export const setUserIds = (payload) => {
  return {
    type: actionTypes.SET_USER_IDS,
    payload,
  };
};

export const setDate = (payload) => {
  return {
    type: actionTypes.SET_DATE,
    payload,
  };
};

export const setStartDate = (payload) => {
  return {
    type: actionTypes.SET_START_DATE,
    payload,
  };
};

export const setEndDate = (payload) => {
  return {
    type: actionTypes.SET_END_DATE,
    payload,
  };
};

export const setMonth = (payload) => {
  return {
    type: actionTypes.SET_MONTH,
    payload,
  };
};
