import { put } from "redux-saga/effects";
import { Wellness360DetailsAction } from "../action";
import { Client } from "../../../utils/transport";

export function* GetWellnessDetailsTrendsData(data) {
  let config = {
    method: "POST",
    url: "query/wellness/datewise_wellness_trend",

    headers: {
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        Wellness360DetailsAction.Wellness360DetailsTrendsSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        Wellness360DetailsAction.Wellness360DetailsTrendsFailed(response)
      );
    }
  } catch (err) {
    yield put(Wellness360DetailsAction.Wellness360DetailsTrendsFailed(err));
  }
}

export function* GetWellnessDetailsEmlpoyeeListData(data) {
  let config = {
    method: "POST",
    url: "query/wellness/data_by_per_employee",

    headers: {
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        Wellness360DetailsAction.Wellness360DetailsEmployeeListSuccess(
          response.data
        )
      );
    } else {
      yield put(
        Wellness360DetailsAction.Wellness360DetailsEmployeeListFailed(response)
      );
    }
  } catch (err) {
    yield put(
      Wellness360DetailsAction.Wellness360DetailsEmployeeListFailed(err)
    );
  }
}

export function* GetWellnessEmployeeDetailsEmlpoyeeListData(data) {
  let config = {
    method: "POST",
    url: "query/wellness/data_by_per_employee",

    headers: {
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        Wellness360DetailsAction.Wellness360EmployeeDetailsEmployeeListSuccess(
          response.data
        )
      );
    } else {
      yield put(
        Wellness360DetailsAction.Wellness360EmployeeDetailsEmployeeListFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      Wellness360DetailsAction.Wellness360EmployeeDetailsEmployeeListFailed(err)
    );
  }
}

export function* GetWellness360DownloadEmployeeData(data) {
  let config = {
    method: "POST",
    url: "query/wellness/data_by_per_employee",
    headers: {
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);
    if (response.status === 200) {
      yield put(
        Wellness360DetailsAction.Wellness360DownloadEmployeeListSuccess(
          response.data
        )
      );
    } else {
      yield put(
        Wellness360DetailsAction.Wellness360DownloadEmployeeListFailed(response)
      );
    }
  } catch (err) {
    yield put(
      Wellness360DetailsAction.Wellness360DownloadEmployeeListFailed(err)
    );
  }
}
