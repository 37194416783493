//* BREAK Start*/
export const BREAKS_REPORT_INITIAL = "BREAKS_REPORT_INITIAL";
export const BREAKS_REPORT_PROCESS = "BREAKS_REPORT_PROCESS";
export const BREAKS_REPORT_SUCCESS = "BREAKS_REPORT_SUCCESS";
export const BREAKS_REPORT_FAILED = "BREAKS_REPORT_FAILED";

//* BREAK End*/

//* BREAK Start*/
export const BREAKS_DOWNLOAD_REPORT_INITIAL = "BREAKS_DOWNLOAD_REPORT_INITIAL";
export const BREAKS_DOWNLOAD_REPORT_PROCESS = "BREAKS_DOWNLOAD_REPORT_PROCESS";
export const BREAKS_DOWNLOAD_REPORT_SUCCESS = "BREAKS_DOWNLOAD_REPORT_SUCCESS";
export const BREAKS_DOWNLOAD_REPORT_FAILED = "BREAKS_DOWNLOAD_REPORT_FAILED";

//* BREAK End*/

//*MONTHLY BREAK Start*/
export const MONTHLY_BREAKS_REPORT_INITIAL = "BREAKS_REPORT_INITIAL";
export const MONTHLY_BREAKS_REPORT_PROCESS = "MONTHLY_BREAKS_REPORT_PROCESS";
export const MONTHLY_BREAKS_REPORT_SUCCESS = "MONTHLY_BREAKS_REPORT_SUCCESS";
export const MONTHLY_BREAKS_REPORT_FAILED = "MONTHLY_BREAKS_REPORT_FAILED";

//*MONTHLY BREAK End*/

//*MONTHLY BREAK Start*/
export const MONTHLY_BREAKS_DOWNLOAD_REPORT_INITIAL =
  "MONTHLY_BREAKS_DOWNLOAD_REPORT_INITIAL";
export const MONTHLY_BREAKS_DOWNLOAD_REPORT_PROCESS =
  "MONTHLY_BREAKS_DOWNLOAD_REPORT_PROCESS";
export const MONTHLY_BREAKS_DOWNLOAD_REPORT_SUCCESS =
  "MONTHLY_BREAKS_DOWNLOAD_REPORT_SUCCESS";
export const MONTHLY_BREAKS_DOWNLOAD_REPORT_FAILED =
  "MONTHLY_BREAKS_DOWNLOAD_REPORT_FAILED";

//*MONTHLY BREAK End*/
