import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Profile from "./profile";
import { Drawer, Tooltip, Modal, Divider } from "antd";
import "./header.scss";
import ProfileDetail from "./profiledetail";
import { AuthContext } from "../../contexts/authContext";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import FreeTrail from "./freeTrail";
import { permissionsMap } from "constants/constant";
import Help from "common/Help";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { DOWNLOAD } from "route/constant";
import CommonSearch from "./commonSearch";
import UserSessionTable from "./UserSessionTable";
import { TfiReload } from "react-icons/tfi";
import { fetchUserSession } from "redux/store/tenant-settings/action";

const TopHeader = (props) => {
  // console.log("props",props)
  const { silderBtn, ref1, HandleTheme, darkMode } = props;
  const [collapsed, setCollapsed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const { KState, checkRequiredPermissions, getDefaultHeader } =
    useContext(AuthContext);
  const navigate = useNavigate();

  const clickSilder = (val) => {
    setCollapsed(val);
    silderBtn(val);
  };
  const [openprofile, setOpenprofile] = useState(false);
  const [openUserSession, setOpenUserSession] = useState(false);

  const [openmyzen, setOpenmyzen] = useState(false);
  const openMyzenModal = () => {
    setOpenmyzen(true);
  };

  const closemodal = () => {
    setOpenmyzen(false);
    setOpenprofile(false);
    setOpenUserSession(false);
  };

  const openprofilemodal = () => {
    setOpenprofile(true);
  };
  const openSessionModal = () => {
    setOpenUserSession(true);
  };

  const handleRefresh = () => {
    dispatch(fetchUserSession({ headers: getDefaultHeader() }));
  };
  //const [darkMode, setDarkMode] = useState();

  let htmlElm = document.querySelector("html");
  const dispatch = useDispatch();

  useEffect(() => {
    //
    htmlElm.classList.add("lightmode");
    htmlElm.classList.add("content");
    if (darkMode === true) {
      // setDarkMode(true);
      htmlElm.className += " " + "darkmode";
      htmlElm.classList.remove("lightmode");
    } else if (darkMode === false) {
      htmlElm.classList.add("lightmode");
    }
    return function cleanup() {
      htmlElm.classList.remove("lightmode");
      htmlElm.classList.remove("darkmode");
    };
  }, [darkMode]);
  const drawerTitle = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      Signed In devices
      <Tooltip title="Refresh">
        <div
          className="text-blue-500 cursor-pointer mr-5 mt-[-5px]"
          onClick={handleRefresh}
        >
          <TfiReload color="#0066cc" />
        </div>
      </Tooltip>
    </div>
  );
  const HandleThemeChange = (val) => {
    localStorage.setItem("modeType", val);
  };
  return (
    <>
      <div className="header">
        <div className="logo_section">
          {/* <div className="desktop">
          {React.createElement(
            collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
            {
              className: "trigger h_icon",
              onClick: () => clickSilder(!collapsed),
            }
          )}
        </div> */}
          <div className="mobile">
            {React.createElement(
              collapsed ? MenuFoldOutlined : MenuUnfoldOutlined,
              {
                className: "trigger h_icon",
                onClick: () => clickSilder(!collapsed),
              }
            )}
          </div>
          {checkRequiredPermissions([permissionsMap.TENANT_OWNER]) && (
            <CommonSearch />
          )}
        </div>
        <div className="flex item_center">
          <div>
            {checkRequiredPermissions([permissionsMap.TENANT_OWNER]) && (
              <FreeTrail />
            )}
          </div>
          {checkRequiredPermissions([permissionsMap.TENANT_OWNER]) && (
            <>
              <Help />

              <div className="mr-3" />
            </>
          )}

          <div className="mr-3" />

          <div className="flex gap-2 cursor-pointer items-center justify-center">
            <Tooltip title="Download desktop app">
              <div
                className={`flex items-center cursor-pointer ${
                  isHovered ? "text-[#7b6fff]" : "text-[#667085]"
                }`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                onClick={() => {
                  navigate(DOWNLOAD);
                }}
              >
                <BsFillArrowDownCircleFill
                  className="w-7 h-7 mr-1 rounded-full transition duration-300 ease-in-out"
                  size={25}
                />
                <span className="mr-1 bold-text">Download Myzen</span>
              </div>
            </Tooltip>
          </div>
          <div className="mr-4" />
          <Profile
            downloadModal={openMyzenModal}
            ProfileModal={openprofilemodal}
            sessionModal={openSessionModal}
            // darkMode={darkMode}
            // setDarkMode={setDarkMode}
            HandleTheme={(val) => HandleThemeChange(val)}
          />

          <Drawer
            title="My Profile"
            width={520}
            onClose={closemodal}
            open={openprofile}
          >
            <ProfileDetail />
          </Drawer>
          <Modal
            footer={null}
            title={drawerTitle}
            width={1000}
            onCancel={closemodal}
            open={openUserSession}
          >
            <Divider />
            <div className="h-5/6">
              <UserSessionTable />
            </div>
          </Modal>
        </div>
      </div>
      <Divider />
    </>
  );
};

TopHeader.propTypes = {
  showBackground: PropTypes.bool,
  silderBtn: PropTypes.func,
};

export default TopHeader;
