import AttendancePer from "assets/images/timecal/Attendance/Attendance per.png";
import Latearrival from "assets/images/timecal/Attendance/Late arrivals.png";
import ActivityPer from "assets/images/timecal/Activity/Activity.png";
import wellnessImg from "assets/images/timecal/Wellness/Wellness.png";
import Timelineactivity from "assets/images/timecal/Timeline/Time breakup visualisation - activity level.png";
import Timelinebreak from "assets/images/timecal/Timeline/Time breakup visualisation - break time.png";
import Timelineoffline from "assets/images/timecal/Timeline/Time breakup visualisation - offline time.png";
import Timelinepunch from "assets/images/timecal/Timeline/Time breakup visualisation - punch.png";
import ProductivityImg from "assets/images/timecal/Productivity/Time breakup visualisation.png";
export const attendance = [
  {
    title: "Attendance %",
    description: `When the selected date range is 1, total employees present/total employee strength.\n
        When the selected date range is greater than 1, total employees present/total employee strength averaged over the date range.`,
    image: AttendancePer,
  },
  {
    title: "Late Arrivals",
    description: `When the selected date range is 1, the percentage of present employees who have punched in after the start of their shift and outside the grace period. For example - if an employee punches in 10+  minutes after the start of the shift where 10  is the grace period in minutes, then she is a late arrival for the day.\n
    When the selected date range is >1, the percentage of late arrivals against Total present employees averaged over the date range.
    `,
    image: Latearrival,
  },
  {
    title: "Early arrivals",
    description: `Total number of employees who have punched in before the start of their shift.
    `,
  },
  {
    title: "On time arrivals",
    description: `Total number of employees who have punched in within the grace period after the start of their shift or have punched in before the start of their shift are marked as on-time arrivals.
    `,
  },
  {
    title: "Working Time",
    description: `Total working time clocked by employees calculated as the sum of all punched-in durations for all employees.
    `,
  },
  {
    title: "Online Time",
    description: `Total time spent by employees between punch-in and punch-out time, where their activity can be detected.
    `,
  },
  {
    title: "Break Time",
    description: `Total break time taken by the employees in hours and minutes.
    `,
  },
];
export const application = [
  {
    title: "Top Application",
    description: `The application on which the maximum amount of online time was spent by employees (subject to applied filters)`,
  },
  {
    title: "Top URL",
    description: `The URL on which the maximum amount of online time was spent by employees (subject to applied filters)
    `,
  },
  {
    title: "Top Category",
    description: `The productivity category, comprising of apps/URLs on which the maximum amount of online time was spent by employees (subject to applied filters)
    `,
  },
];
export const wellness = [
  {
    title: "Health",
    description: `Total number of days when the employee’s total working time was within the healthy range on the wellness scale in settings.`,
  },
  {
    title: "Overburdened",
    description: `Total number of days when the employee’s total working time was within the Overburdened range on the wellness scale in settings.
    `,
  },
  {
    title: "Underutilised",
    description: `Total number of days when the employee’s total working time was within the Underutilised range on the wellness scale in settings.
    `,
    image: wellnessImg,
  },
  {
    title: "Health %",
    description: `Total healthy days/Total present days`,
  },
  {
    title: "Overburdened %",
    description: `Total Overburdened days/ Total present days
    `,
  },
  {
    title: "Underutilised %",
    description: `Total Underutilised days/ Total present days,
    `,
  },
];
export const activity = [
  {
    title: "Activity %",
    description: `(Active time/Online time)*100`,
    image: ActivityPer,
  },
  {
    title: "Online Time",
    description: `Total time spent by employees between punch-in and punch-out time, where their activity can be detected.
    `,
  },
  {
    title: "Active Time",
    description: `Online time spent while being active on the keyboard/mouse
    `,
  },
  {
    title: "Idle Time",
    description: `Online time spent with no keyboard/mouse activity`,
  },
  {
    title: "Break Time",
    description: `Total break time taken by the employees in hours and minutes
    `,
  },
  {
    title: "Keypresses",
    description: `No. of keypresses recorded by the employee within applicable filters
    `,
  },
  {
    title: "Mouse Clicks",
    description: `No. of mouse clicks recorded by the employee within applicable filters`,
  },
];
export const productivity = [
  {
    image: ProductivityImg,
  },
  {
    title: "Productivity %",
    description: `(Productive time/Online time)*100`,
  },
  {
    title: "Online Time",
    description: `Total time spent by employees between punch-in and punch-out time, where their activity can be detected.
    `,
  },
  {
    title: "Productive Time",
    description: `Active time spent on Productive category apps
    `,
  },
  {
    title: "Unproductive Time",
    description: `Active time spent on Unproductive category apps`,
  },
  {
    title: "Neutral Time",
    description: `Active time spent on Neutral category apps
    `,
  },
  {
    title: "Break Time",
    description: `Total break time taken by the employees in hours and minutes
    `,
  },
];
export const timeline = [
  {
    image: Timelineactivity,
  },
  {
    image: Timelinebreak,
  },
  {
    image: Timelineoffline,
  },
  {
    image: Timelinepunch,
  },
];
