import { put } from "redux-saga/effects";
import { LeaveAction } from "../action";
import { Client } from "../../../utils/transport";

// LEAVE  LIST

export function* GetLeave(payload) {
  let config = {
    method: "post",
    url: `api/v1/leave_requests/search?${payload?.payload?.params}`,
    headers: {
      ...payload?.payload?.token,
    },
    data: payload?.payload?.body,
    fetchPolicy: "no-cache",
  };

  try {
    var response = yield Client.request(config);
    if (response.data) {
      yield put(LeaveAction.LeaveListSuccess(response.data));
    }
  } catch (err) {
    yield put(LeaveAction.LeaveListFailed(err.response));
  }
}

//CREATE LEAVE

export function* CreateLeave(payload) {
  let config = {
    method: "POST",
    url: "api/v1/leave_requests/",
    headers: {
      ...payload?.payload?.token,
    },
    data: payload.payload.body,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);
    if (response.status) {
      yield put(LeaveAction.LeaveCreateSuccess(response.data));
    }
  } catch (error) {
    yield put(
      LeaveAction.LeaveCreateFailed(
        (error.response?.data?.errors[0]?.detail).toString()
      )
    );
  }
}

//EDIT LEAVE

export function* EditLeave(payload) {
  const method = payload?.payload?.body?.status === "PENDING" ? "PUT" : "POST";
  const url =
    payload?.payload?.body?.status !== "PENDING"
      ? payload?.payload?.body?.status === "APPROVED"
        ? `api/v1/leave_requests/${payload?.payload?.body?.id}/approve/?approvalRemarks=${payload?.payload?.body?.approval_remarks}&isRejected=false`
        : `api/v1/leave_requests/${payload?.payload?.body?.id}/review/?approvalRemarks=${payload?.payload?.body?.approval_remarks}&isRejected=true`
      : `api/v1/leave_requests/${payload?.payload?.body?.id}`;
  let config = {
    method: method,
    url: url,
    headers: {
      ...payload?.payload?.token,
    },
    data: payload.payload.body,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);
    if (response.data) {
      yield put(LeaveAction.LeaveEditSuccess(response.data));
    }
  } catch (err) {
    yield put(LeaveAction.LeaveEditFailed(err.response));
  }
}
