import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../index.scss";
import { InfoCircleFilled } from "@ant-design/icons";
import Chart from "./../../chart";
import { Skeleton, Typography, Tooltip, Empty, message } from "antd";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { hm } from "../../../utils/timeConverter";
import _ from "lodash";
import { getColorCodes } from "utils/colorCodes";
const colorCodes = getColorCodes();
const { Title } = Typography;

const Cardskeleton = () => {
  return (
    <>
      <div className="charts">
        <div className="chart_header">
          <Skeleton.Input
            size={"small"}
            shape={"default"}
            style={{ height: "15px" }}
          />
          <Skeleton.Avatar
            size={"small"}
            shape={"circle"}
            style={{ height: "15px", width: "15px" }}
          />
        </div>
        <div className="chartContainer chart_tool" id="chart">
          {/* chart */}
          <Skeleton.Avatar
            size={"large"}
            shape={"default"}
            style={{ height: "300px", width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

const BreakTrends = (props) => {
  const { hideTooltip } = props || {};
  const [loading, setLoading] = useState(true);
  const attendancesummary = useSelector((state) => state.attendancesummary);
  const [BreakTrendData, setBreakTrendData] = useState();
  const [ChartOption, setChartOption] = useState();
  const [maxValue, setMaxValue] = useState(0);
  // let maxValue = 0;
  useEffect(() => {
    if (
      attendancesummary.attendance_summary_break_trend_date_wise_success ===
      true
    ) {
      setLoading(true);
      setChartOption();
      setBreakTrendData();
      setMaxValue(0);
      if (
        _.size(
          attendancesummary.attendance_summary_break_trend_date_wise_data
        ) > 0
      ) {
        const list = [];
        const formetedDated = [];
        const dated = [];
        let fullData = {};
        let Total = 0;
        attendancesummary.attendance_summary_break_trend_date_wise_data.forEach(
          (item) => {
            formetedDated.push(dayjs(item.punchInDate).format("DD/MM"));
            dated.push(dayjs(item.punchInDate));
            list.push(!item.break_duration ? 0 : item.break_duration);
          }
        );
        fullData = {
          formetedDated: formetedDated,
          dated: dated,
          list: list,
        };

        const numbers = list.join().split(",").map(Number);
        const mvalue = Math.max(...numbers);
        setMaxValue(mvalue);

        Total = mvalue > 0 ? mvalue + 3600 : 3660;

        const appusages = {
          series: [
            {
              name: "Break Duration",
              data: list,
            },
          ],
        };

        const dynamicWidth = appusages.series.length * 100;
        const chartWidth =
          dynamicWidth < window.innerWidth ? "100%" : dynamicWidth;

        const dataOptions = {
          options: {
            chart: {
              background: "transparent",
              height: 350,
              width: chartWidth,
              type: "line",
              toolbar: {
                show: false,
              },
              zoom: {
                enabled: false,
              },
            },
            colors: ["#FFB800"],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "smooth",
            },
            grid: {
              show: true,
              xaxis: {
                lines: {
                  show: false,
                },
              },
              yaxis: {
                lines: {
                  show: true,
                },
              },
            },
            xaxis: {
              categories: formetedDated,
              data: fullData,
              labels: {
                rotate: -45,
                rotateAlways: true,
                hideOverlappingLabels: true,
                trim: true,
                style: {
                  colors: colorCodes.graph_legends_font_color,
                },
              },
              axisBorder: {
                show: true,
                offsetY: 2,
              },
            },
            yaxis: {
              max: Total,
              min: 0,
              tickAmount: 4,

              labels: {
                formatter: function (y) {
                  return hm(y.toFixed(0));
                },
                style: {
                  colors: colorCodes.graph_legends_font_color,
                },
              },
            },
            legend: {
              position: "top",
              horizontalAlign: "center",

              formatter: function (label, opts) {
                return '<div class="legenddata">' + label + "</div>";
              },
            },

            tooltip: {
              enabled: true,
              theme: "light",

              custom: function ({ seriesIndex, dataPointIndex, w }) {
                const date1 = dayjs(
                  w.config.xaxis.data.dated[dataPointIndex]
                ).format("DD-MMM-YYYY");

                const xname = w.globals.seriesNames[seriesIndex];

                return (
                  '<div class="bartooltip">' +
                  date1 +
                  '</div><div class="tooltip_card"><div class="w_data"><div class="colordot" style="background:' +
                  w.globals.colors[seriesIndex] +
                  '"></div>' +
                  xname +
                  '<div class="w_value">' +
                  hm(w.globals.series[seriesIndex][dataPointIndex]) +
                  "</div></div></div>"
                );
              },
            },
          },
        };

        setChartOption(dataOptions);
        setBreakTrendData(appusages);
        setLoading(false);
      } else {
        setChartOption();
        setBreakTrendData();
        setLoading(false);
        setMaxValue(0);
      }
    } else if (
      attendancesummary.attendance_summary_break_trend_date_wise_failed
    ) {
      // message.error(
      //   attendancesummary.attendance_summary_break_trend_date_wise_message
      //     .message
      // );
    }
  }, [attendancesummary.attendance_summary_break_trend_date_wise_success]);

  return (
    <>
      {attendancesummary.attendance_summary_break_trend_date_wise_processing ===
      true ? (
        <Cardskeleton />
      ) : (
        <div className="charts">
          <div className="chart_header">
            <Title level={2}>Break Trends</Title>
            {!hideTooltip && (
              <Tooltip
                placement="bottom"
                title="Displays the trend of total time spent in breaks taken."
              >
                <InfoCircleFilled />
              </Tooltip>
            )}
          </div>
          <div
            className="chartContainer all_tool"
            id="chart"
            style={{ marginTop: "-20px" }}
          >
            {/* chart */}
            {maxValue > 0 ? (
              <Chart
                options={ChartOption?.options}
                series={BreakTrendData?.series}
                type="line"
                height={250}
              />
            ) : (
              <div style={{ height: "271px" }} className="emptyblock">
                <Empty description={false} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

BreakTrends.propTypes = {
  getList: PropTypes.func,
};

export default BreakTrends;
