import * as actionTypes from "./actionTypes";
import _ from "lodash";

const initialState = {
  //*GLOBAL*//

  //* PRODUCTIVITY SUMMARY WIDGET *//

  productivity_summary_widget_processing: false,
  productivity_summary_widget_success: false,
  productivity_summary_widget_failed: false,
  productivity_summary_widget_data: [],
  productivity_summary_widget_message: "",

  //* PRODUCTIVITY SUMMARY TOP CATEGORY *//

  productivity_summary_top_category_processing: false,
  productivity_summary_top_category_success: false,
  productivity_summary_top_category_failed: false,
  productivity_summary_top_category_data: [],
  productivity_summary_top_category_message: "",

  //* PRODUCTIVITY SUMMARY TOP APP URL *//

  productivity_summary_top_app_url_processing: false,
  productivity_summary_top_app_url_success: false,
  productivity_summary_top_app_url_failed: false,
  productivity_summary_top_app_url_data: [],
  productivity_summary_top_app_url_message: "",

  //* PRODUCTIVITY SUMMARY WORKING TIME *//

  productivity_summary_working_time_processing: false,
  productivity_summary_working_time_success: false,
  productivity_summary_working_time_failed: false,
  productivity_summary_working_time_data: [],
  productivity_summary_working_time_message: "",

  //* PRODUCTIVITY SUMMARY PRODUCTIVITY  BREAK DOWN  *//

  productivity_summary_productivity_break_down_processing: false,
  productivity_summary_productivity_break_down_success: false,
  productivity_summary_productivity_break_down_failed: false,
  productivity_summary_productivity_break_down_data: [],
  productivity_summary_productivity_break_down_message: "",

  //* PRODUCTIVITY SUMMARY TOP MOST ACTIVE TEAM  *//

  productivity_summary_top_bottom_active_team_processing: true,
  productivity_summary_top_bottom_active_team_success: false,
  productivity_summary_top_bottom_active_team_failed: false,
  productivity_summary_top_bottom_active_team_data: [],
  productivity_summary_top_bottom_active_team_message: "",

  //* PRODUCTIVITY SUMMARY TOP LEAST ACTIVE TEAM  *//

  productivity_summary_team_wise_productivity_break_down_processing: true,
  productivity_summary_team_wise_productivity_break_down_success: false,
  productivity_summary_team_wise_productivity_break_down_failed: false,
  productivity_summary_team_wise_productivity_break_down_data: [],
  productivity_summary_team_wise_productivity_break_down_message: "",

  //* PRODUCTIVITY SUMMARY TEAM WISE PRODUCTIVITY CATEGORY *//

  // productivity_summary_team_wise_productivity_category_processing: false,
  // productivity_summary_team_wise_productivity_category_success: false,
  // productivity_summary_team_wise_productivity_category_failed: false,
  // productivity_summary_team_wise_productivity_category_data: [],
  // productivity_summary_team_wise_productivity_category_message: "",

  //* PRODUCTIVITY SUMMARY PRODUCTIVITY PERCENT *//

  productivity_summary_productivity_percent_processing: true,
  productivity_summary_productivity_percent_success: false,
  productivity_summary_productivity_percent_failed: false,
  productivity_summary_productivity_percent_data: [],
  productivity_summary_productivity_percent_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.PRODUCTIVITY_SUMMARY_WIDGET_INITIAL:
      return {
        ...state,
        productivity_summary_widget_processing: false,
        productivity_summary_widget_success: false,
        productivity_summary_widget_failed: false,
        productivity_summary_widget_data: [],
        productivity_summary_widget_message: "",
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_WIDGET_PROCESS:
      return {
        ...state,
        productivity_summary_widget_processing: true,
        productivity_summary_widget_success: false,
        productivity_summary_widget_failed: false,
        productivity_summary_widget_data: [],
        productivity_summary_widget_message: "",
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_WIDGET_SUCCESS:
      return {
        ...state,
        productivity_summary_widget_processing: false,
        productivity_summary_widget_success: true,
        productivity_summary_widget_failed: false,
        productivity_summary_widget_data: action.data,
        productivity_summary_widget_message: "",
      };
    case actionTypes.PRODUCTIVITY_SUMMARY_WIDGET_FAILED:
      return {
        ...state,
        productivity_summary_widget_processing: false,
        productivity_summary_widget_success: false,
        productivity_summary_widget_failed: true,
        productivity_summary_widget_data: action.data,
        productivity_summary_widget_message: "",
      };
    case actionTypes.PRODUCTIVITY_SUMMARY_TOP_CATEGORY_INITIAL:
      return {
        ...state,
        productivity_summary_top_category_processing: false,
        productivity_summary_top_category_success: false,
        productivity_summary_top_category_failed: false,
        productivity_summary_top_category_data: [],
        productivity_summary_top_category_message: "",
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_TOP_CATEGORY_PROCESS:
      return {
        ...state,
        productivity_summary_top_category_processing: true,
        productivity_summary_top_category_success: false,
        productivity_summary_top_category_failed: false,
        productivity_summary_top_category_data: [],
        productivity_summary_top_category_message: "",
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_TOP_CATEGORY_SUCCESS:
      return {
        ...state,
        productivity_summary_top_category_processing: false,
        productivity_summary_top_category_success: true,
        productivity_summary_top_category_failed: false,
        productivity_summary_top_category_data: action.data,
        productivity_summary_top_category_message: "",
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_TOP_CATEGORY_FAILED:
      return {
        ...state,
        productivity_summary_top_category_processing: false,
        productivity_summary_top_category_success: false,
        productivity_summary_top_category_failed: true,
        productivity_summary_top_category_data: action.data,
        productivity_summary_top_category_message: "",
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_TOP_APP_URL_INITIAL:
      return {
        ...state,
        productivity_summary_top_app_url_processing: false,
        productivity_summary_top_app_url_success: false,
        productivity_summary_top_app_url_failed: true,
        productivity_summary_top_app_url_data: [],
        productivity_summary_top_app_url_message: "",
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_TOP_APP_URL_PROCESS:
      return {
        ...state,
        productivity_summary_top_app_url_processing: true,
        productivity_summary_top_app_url_success: false,
        productivity_summary_top_app_url_failed: false,
        productivity_summary_top_app_url_data: [],
        productivity_summary_top_app_url_message: "",
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_TOP_APP_URL_SUCCESS:
      return {
        ...state,
        productivity_summary_top_app_url_processing: false,
        productivity_summary_top_app_url_success: true,
        productivity_summary_top_app_url_failed: false,
        productivity_summary_top_app_url_data: action.data,
        productivity_summary_top_app_url_message: "",
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_TOP_APP_URL_FAILED:
      return {
        ...state,
        productivity_summary_top_app_url_processing: false,
        productivity_summary_top_app_url_success: false,
        productivity_summary_top_app_url_failed: true,
        productivity_summary_top_app_url_data: action.data,
        productivity_summary_top_app_url_message: "",
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_WORKING_TIME_INITIAL:
      return {
        ...state,
        productivity_summary_working_time_processing: false,
        productivity_summary_working_time_success: false,
        productivity_summary_working_time_failed: false,
        productivity_summary_working_time_data: [],
        productivity_summary_working_time_message: "",
      };
    case actionTypes.PRODUCTIVITY_SUMMARY_WORKING_TIME_PROCESS:
      return {
        ...state,
        productivity_summary_working_time_processing: true,
        productivity_summary_working_time_success: false,
        productivity_summary_working_time_failed: false,
        productivity_summary_working_time_data: [],
        productivity_summary_working_time_message: "",
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_WORKING_TIME_SUCCESS:
      return {
        ...state,
        productivity_summary_working_time_processing: false,
        productivity_summary_working_time_success: true,
        productivity_summary_working_time_failed: false,
        productivity_summary_working_time_data: action.data,
        productivity_summary_working_time_message: "",
      };
    case actionTypes.PRODUCTIVITY_SUMMARY_WORKING_TIME_FAILED:
      return {
        ...state,
        productivity_summary_working_time_processing: false,
        productivity_summary_working_time_success: false,
        productivity_summary_working_time_failed: true,
        productivity_summary_working_time_data: [],
        productivity_summary_working_time_message: action.data,
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_PRODUCTIVITY_BREAK_DOWN_INITIAL:
      return {
        ...state,
        productivity_summary_productivity_break_down_processing: false,
        productivity_summary_productivity_break_down_success: false,
        productivity_summary_productivity_break_down_failed: false,
        productivity_summary_productivity_break_down_data: [],
        productivity_summary_productivity_break_down_message: "",
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_PRODUCTIVITY_BREAK_DOWN_PROCESS:
      return {
        ...state,
        productivity_summary_productivity_break_down_processing: true,
        productivity_summary_productivity_break_down_success: false,
        productivity_summary_productivity_break_down_failed: false,
        productivity_summary_productivity_break_down_data: [],
        productivity_summary_productivity_break_down_message: "",
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_PRODUCTIVITY_BREAK_DOWN_SUCCESS:
      return {
        ...state,
        productivity_summary_productivity_break_down_processing: false,
        productivity_summary_productivity_break_down_success: true,
        productivity_summary_productivity_break_downfailed: false,
        productivity_summary_productivity_break_down_data: action.data,
        productivity_summary_productivity_break_down_message: "",
      };
    case actionTypes.PRODUCTIVITY_SUMMARY_PRODUCTIVITY_BREAK_DOWN_FAILED:
      return {
        ...state,
        productivity_summary_productivity_break_down_processing: false,
        productivity_summary_productivity_break_downsuccess: false,
        productivity_summary_productivity_break_down_failed: true,
        productivity_summary_productivity_break_downdata: [],
        productivity_summary_productivity_break_down_message: action.data,
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_TOP_BOTTOM_ACTIVE_TEAM_INITIAL:
      return {
        ...state,
        productivity_summary_top_bottom_Active_team_processing: false,
        productivity_summary_top_bottom_Active_team_success: false,
        productivity_summary_top_bottom_Active_team_failed: false,
        productivity_summary_top_bottom_Active_team_data: [],
        productivity_summary_top_bottom_Active_team_message: "",
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_TOP_BOTTOM_ACTIVE_TEAM_PROCESS:
      return {
        ...state,
        productivity_summary_top_bottom_Active_team_processing: true,
        productivity_summary_top_bottom_Active_team_success: false,
        productivity_summary_top_bottom_Active_team_failed: false,
        productivity_summary_top_bottom_Active_team_data: [],
        productivity_summary_top_bottom_Active_team_message: "",
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_TOP_BOTTOM_ACTIVE_TEAM_SUCCESS:
      return {
        ...state,
        productivity_summary_top_bottom_Active_team_processing: false,
        productivity_summary_top_bottom_Active_team_success: true,
        productivity_summary_top_bottom_Active_teamfailed: false,
        productivity_summary_top_bottom_Active_team_data: action.data,
        productivity_summary_top_bottom_Active_team_message: "",
      };
    case actionTypes.PRODUCTIVITY_SUMMARY_TOP_BOTTOM_ACTIVE_TEAM_FAILED:
      return {
        ...state,
        productivity_summary_top_bottom_Active_team_processing: false,
        productivity_summary_top_bottom_Active_team_success: false,
        productivity_summary_top_bottom_Active_team_failed: true,
        productivity_summary_top_bottom_Active_team_data: action.data,
        productivity_summary_top_bottom_Active_team_message: "",
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_BREAK_DOWN_INITIAL:
      return {
        ...state,
        productivity_summary_team_wise_productivity_break_down_processing: false,
        productivity_summary_team_wise_productivity_break_down_success: false,
        productivity_summary_team_wise_productivity_break_down_failed: false,
        productivity_summary_team_wise_productivity_break_down_data: [],
        productivity_summary_team_wise_productivity_break_down_message: "",
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_BREAK_DOWN_PROCESS:
      return {
        ...state,
        productivity_summary_team_wise_productivity_break_down_processing: true,
        productivity_summary_team_wise_productivity_break_down_success: false,
        productivity_summary_team_wise_productivity_break_down_failed: false,
        productivity_summary_team_wise_productivity_break_down_data: [],
        productivity_summary_team_wise_productivity_break_down_message: "",
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_BREAK_DOWN_SUCCESS:
      return {
        ...state,
        productivity_summary_team_wise_productivity_break_down_processing: false,
        productivity_summary_team_wise_productivity_break_down_success: true,
        productivity_summary_team_wise_productivity_break_down_failed: false,
        productivity_summary_team_wise_productivity_break_down_data:
          action.data,
        productivity_summary_team_wise_productivity_break_down_message: "",
      };
    case actionTypes.PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_BREAK_DOWN_FAILED:
      return {
        ...state,
        productivity_summary_team_wise_productivity_break_down_processing: false,
        productivity_summary_team_wise_productivity_break_down_success: false,
        productivity_summary_team_wise_productivity_break_down_failed: true,
        productivity_summary_team_wise_productivity_break_down_data: [],

        productivity_summary_team_wise_productivity_break_down_message:
          action.data,
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_PRODUCTIVITY_PERCENT_INITIAL:
      return {
        ...state,
        productivity_summary_team_wise_productivity_break_down_processing: false,
        productivity_summary_team_wise_productivity_break_down_success: false,
        productivity_summary_team_wise_productivity_break_down_failed: false,
        productivity_summary_team_wise_productivity_break_down_data: [],
        productivity_summary_team_wise_productivity_break_down_message: "",
      };
    case actionTypes.PRODUCTIVITY_SUMMARY_PRODUCTIVITY_PERCENT_PROCESS:
      return {
        ...state,
        productivity_summary_team_wise_productivity_break_down_processing: true,
        productivity_summary_team_wise_productivity_break_down_success: false,
        productivity_summary_team_wise_productivity_break_down_failed: false,
        productivity_summary_team_wise_productivity_break_down_data: [],
        productivity_summary_team_wise_productivity_break_down_message: "",
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_PRODUCTIVITY_PERCENT_SUCCESS:
      return {
        ...state,
        productivity_summary_team_wise_productivity_break_down_processing: false,
        productivity_summary_team_wise_productivity_break_down_success: true,
        productivity_summary_team_wise_productivity_break_down_failed: false,
        productivity_summary_team_wise_productivity_break_down_data:
          action.data,
        productivity_summary_team_wise_productivity_break_down_message: "",
      };

    case actionTypes.PRODUCTIVITY_SUMMARY_PRODUCTIVITY_PERCENT_FAILED:
      return {
        ...state,
        productivity_summary_team_wise_productivity_break_down_processing: false,
        productivity_summary_team_wise_productivity_break_down_success: false,
        productivity_summary_team_wise_productivity_break_down_failed: true,
        productivity_summary_team_wise_productivity_break_down_data:
          action.data,
        productivity_summary_team_wise_productivity_break_down_message: "",
      };
    default:
      return state;
  }
};
