import * as actionTypes from "./actionTypes";
import { FETCH_ME_INFO } from "../users/actionTypes";

const initialPagination = { totalCount: 0 };

const initialState = {
  fetchSubscriptionsLoading: true,
  subscriptionsData: {},
  fetchSubscriptionSuccess: false,
  fetchSubscriptionDetail: false,
  fetchInvoicesLoading: true,
  invoicesList: [],
  billingErrorMessage: "",
  upgradeModalOpen: false,
  pagination: initialPagination,
  initiateSubscriptionLoading: false,
  planGroupData: [],
  planGroupLoading: false,
  sendInvoiceLoadingInvoiceID: null,
  initiateSubscriptionData: null,
  updateSubscriptionLoading: false,
  updateSubscriptionSuccess: false,
  overrideFeatures: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_SUBSCRIPTIONS:
      return {
        ...state,
        fetchSubscriptionsLoading: true,
        billingErrorMessage: "",
        fetchSubscriptionFailed: false,
        overrideFeatures:
          action.payload.overrideFeatures || state.overrideFeatures,
      };

    case actionTypes.FETCH_SUBSCRIPTIONS_SUCCESS: {
      return {
        ...state,
        fetchSubscriptionsLoading: false,
        subscriptionsData: {
          ...action.payload,
          billingStatus:
            action.payload.billingStatus || action.payload.billing_status,
          billingType:
            action.payload.billingType || action.payload.billing_type,
          expiryDate: action.payload.expiryDate || action.payload.expiry_date,
          totalAllowedUsers:
            action.payload.totalAllowedUsers || action.payload.licensed_seats,
          plan: {
            ...action.payload.plan,
            plan_type: {
              ...action.payload.plan.plan_type,
              plan_features: {
                ...action.payload.plan.plan_type.plan_features,
                ui: {
                  ...action.payload.plan.plan_type.plan_features.ui,
                  ...(state.overrideFeatures?.ui || {}),
                },
                features: {
                  ...action.payload.plan.plan_type.plan_features.features,
                  ...(state.overrideFeatures?.features || {}),
                },
              },
            },
          },
        },
        fetchSubscriptionSuccess: true,
      };
    }

    case actionTypes.FETCH_SUBSCRIPTIONS_FAILED:
      return {
        ...state,
        fetchSubscriptionsLoading: false,
        subscriptionsData: {},
        billingErrorMessage: action.payload,
        fetchSubscriptionFailed: true,
      };

    case actionTypes.FETCH_INVOICES:
      return {
        ...state,
        fetchInvoicesLoading: true,
        billingErrorMessage: "",
      };

    case actionTypes.FETCH_INVOICES_SUCCESS:
      return {
        ...state,
        fetchInvoicesLoading: false,
        invoicesList: !!action.payload.list
          ? action.payload.list.map((invoice) => invoice.invoice)
          : action.payload.data,
      };

    case actionTypes.FETCH_INVOICES_FAILED:
      return {
        ...state,
        fetchInvoicesLoading: false,
        invoicesList: [],
        billingErrorMessage: action.payload,
      };

    case actionTypes.SEND_INVOICE_VIA_EMAIL:
      return {
        ...state,
        sendInvoiceLoadingInvoiceID: action.payload.invoiceID,
        billingErrorMessage: "",
      };

    case actionTypes.SEND_INVOICE_VIA_EMAIL_SUCCESS:
      return {
        ...state,
        fetchInvoicesLoading: null,
      };

    case actionTypes.SEND_INVOICE_VIA_EMAIL_FAILED:
      return {
        ...state,
        fetchInvoicesLoading: null,
        billingErrorMessage: action.payload,
      };

    case actionTypes.INITIATE_SUBSCRIPTION:
      return {
        ...state,
        initiateSubscriptionLoading: true,
        billingErrorMessage: "",
        initiateSubscriptionData: null,
      };

    case actionTypes.INITIATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        initiateSubscriptionLoading: false,
        initiateSubscriptionData: action.payload,
      };

    case actionTypes.INITIATE_SUBSCRIPTION_FAILED:
      return {
        ...state,
        initiateSubscriptionLoading: false,
        initiateSubscriptionData: null,
        billingErrorMessage: action.payload,
      };

    case actionTypes.UPDATE_SUBSCRIPTION:
      return {
        ...state,
        updateSubscriptionLoading: true,
        updateSubscriptionSuccess: false,
        billingErrorMessage: "",
      };

    case actionTypes.UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        updateSubscriptionLoading: false,
        updateSubscriptionSuccess: true,
      };

    case actionTypes.UPDATE_SUBSCRIPTION_FAILED:
      return {
        ...state,
        updateSubscriptionLoading: false,
        billingErrorMessage: action.payload,
      };

    case actionTypes.SET_UPDATE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        updateSubscriptionLoading: false,
        updateSubscriptionSuccess: action.payload,
      };

    case actionTypes.FETCH_PLAN_GROUP:
      return {
        ...state,
        planGroupLoading: true,
        billingErrorMessage: "",
        planGroupData: [],
      };

    case actionTypes.FETCH_PLAN_GROUP_SUCCESS: {
      return {
        ...state,
        planGroupLoading: false,
        planGroupData: action.payload.items.reduce(
          (acc, plan) => ({
            ...acc,
            [plan.plan_type.id]: {
              plan_type: plan.plan_type,
              plans: {
                ...acc[plan.plan_type.id]?.plans,
                [plan.amount ? plan.subscription_frequency : "free"]: plan,
              },
            },
          }),
          {}
        ),
      };
    }

    case actionTypes.FETCH_PLAN_GROUP_FAILED:
      return {
        ...state,
        planGroupLoading: false,
        planGroupData: [],
        billingErrorMessage: action.payload,
      };

    case actionTypes.SET_BILLING_ERROR_MESSAGE:
      return {
        ...state,
        billingErrorMessage: action.payload,
      };

    case actionTypes.ON_UPGRADE_MODAL_OPEN:
      return {
        ...state,
        upgradeModalOpen: true,
      };

    case actionTypes.ON_UPGRADE_MODAL_CLOSE:
      return {
        ...state,
        upgradeModalOpen: false,
      };

    case actionTypes.SET_BILLING_OVERRIDES:
      return {
        ...state,
        overrideFeatures: action.payload || state.overrideFeatures,
      };

    case FETCH_ME_INFO:
      return {
        ...state,
        fetchSubscriptionsLoading: true,
        billingErrorMessage: "",
        fetchSubscriptionFailed: false,
      };

    default:
      return state;
  }
};
