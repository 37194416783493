import React from "react";
import { Table, Empty, Card, Progress, Typography } from "antd";
import { useSelector } from "react-redux";
import calculatePercentage from "utils/func_percentage";
const { Title } = Typography;

const WellnessTable = () => {
  const wellness360details = useSelector((state) => state.wellness360details);

  const columns = [
    {
      title: "Total Present",
      dataIndex: "present_days",
      fixed: "left",
      width: 80,
    },
    {
      title: "Healthy",
      dataIndex: "healthy_count",
      width: 100,
    },
    {
      title: "Overburdened",
      dataIndex: "overburdened_count",
      width: 100,
    },
    {
      title: "Underutilised",
      dataIndex: "underutilized_count",
      width: 100,
    },

    {
      title: "Health %",
      dataIndex: "healthper",
      width: 80,

      render: (val, record) => {
        const per = calculatePercentage(
          record.healthy_count,
          record.present_days
        );
        return (
          <>
            <Progress
              strokeLinecap="butt"
              percent={!per ? 0 : per === 100 ? 99.99 : per || 0}
              strokeColor={"#7B61FF"}
            />
          </>
        );
      },
    },
    {
      title: "Overburdened %",
      dataIndex: "overworkedper",
      width: 80,

      render: (val, record) => {
        const per = calculatePercentage(
          record.overburdened_count,
          record.present_days
        );
        return (
          <>
            <Progress
              strokeLinecap="butt"
              percent={!per ? 0 : per === 100 ? 99.99 : per || 0}
              strokeColor={"#FFB800"}
            />
          </>
        );
      },
    },
    {
      title: "Underutilized %",
      dataIndex: "underutilisedper",
      width: 80,

      render: (val, record) => {
        const per = calculatePercentage(
          record.underutilized_count,
          record.present_days
        );
        return (
          <>
            <Progress
              strokeLinecap="butt"
              percent={!per ? 0 : per === 100 ? 99.99 : per || 0}
              strokeColor={"#D5D4DB"}
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="charts">
      <div className="chart_header ml-7">
        <Title level={2}>Detail </Title>
      </div>
      <Card className="cardpadding_0">
        {(
          wellness360details?.wellness360_employee_details_employee_list_data
            ?.data || []
        ).length > 0 ? (
          <Table
            columns={columns}
            dataSource={
              wellness360details &&
              wellness360details.wellness360_employee_details_employee_list_data
                .data
            }
            pagination={false}
            size="small"
            scroll={{ x: 1500 }}
          />
        ) : (
          <div style={{ height: "271px" }} className="emptyblock">
            <Empty description={false} />
          </div>
        )}
      </Card>
    </div>
  );
};

export default WellnessTable;
