import * as actionTypes from "./actionTypes";

//* WELLNESS360 SUMMARY WIDGET *//

export const Wellness360SummaryWidgetInitial = () => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_WIDGET_INITIAL,
  };
};
export const Wellness360SummaryWidgetProcess = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_WIDGET_PROCESS,
    data,
  };
};
export const Wellness360SummaryWidgetSuccess = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_WIDGET_SUCCESS,
    data,
  };
};
export const Wellness360SummaryWidgetFailed = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_WIDGET_FAILED,
    data,
  };
};
/** WELLNESS SUMMARY Healthy EMPLOYEES SUMMARY */

export const Wellness360SummaryHealthyEmployeesInitial = () => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_INITIAL,
  };
};

export const Wellness360SummaryHealthyEmployeesProcess = (token, bodyData) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PROCESS,
    token,
    bodyData,
  };
};

export const Wellness360SummaryHealthyEmployeesSuccess = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_SUCCESS,
    data,
  };
};

export const Wellness360SummaryHealthyEmployeesFailed = (err) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_FAILED,
    err,
  };
};

/** WELLNESS SUMMARY Healthy Employees Summary Previous Month */

export const Wellness360SummaryHealthyEmployeesPreviousMonthInitial = () => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PREVIOUS_MONTH_INITIAL,
  };
};

export const Wellness360SummaryHealthyEmployeesPreviousMonthProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PREVIOUS_MONTH_PROCESS,
    token,
    bodyData,
  };
};

export const Wellness360SummaryHealthyEmployeesPreviousMonthSuccess = (
  data
) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PREVIOUS_MONTH_SUCCESS,
    data,
  };
};

export const Wellness360SummaryHealthyEmployeesPreviousMonthFailed = (err) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PREVIOUS_MONTH_FAILED,
    err,
  };
};

//* WELLNESS SUMMARY OVERBURDENED EMPLOYEES

export const Wellness360SummaryOverburdenedEmployeesInitial = () => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_OVERBURDENED_EMPLOYEES_INITIAL,
  };
};

export const Wellness360SummaryOverburdenedEmployeesProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_OVERBURDENED_EMPLOYEES_PROCESS,
    token,
    bodyData,
  };
};

export const Wellness360SummaryOverburdenedEmployeesSuccess = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_OVERBURDENED_EMPLOYEES_SUCCESS,
    data,
  };
};

export const Wellness360SummaryOverburdenedEmployeesFailed = (err) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_OVERBURDENED_EMPLOYEES_FAILED,
    err,
  };
};

//* WELLNESS SUMMARY OVERALL SCORE

export const Wellness360SummaryOverallScoreInitial = () => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_OVERALL_SCORE_INITIAL,
  };
};

export const Wellness360SummaryOverallScoreProcess = (token, bodyData) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_OVERALL_SCORE_PROCESS,
    token,
    bodyData,
  };
};

export const Wellness360SummaryOverallScoreSuccess = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_OVERALL_SCORE_SUCCESS,
    data,
  };
};

export const Wellness360SummaryOverallScoreFailed = (err) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_OVERALL_SCORE_FAILED,
    err,
  };
};

//* WELLNESS ME OVERALL SCORE

export const Wellness360MeOverallScoreInitial = () => {
  return {
    type: actionTypes.WELLNESS360_ME_OVERALL_SCORE_INITIAL,
  };
};

export const Wellness360MeOverallScoreProcess = (token, bodyData) => {
  return {
    type: actionTypes.WELLNESS360_ME_OVERALL_SCORE_PROCESS,
    token,
    bodyData,
  };
};

export const Wellness360MeOverallScoreSuccess = (data) => {
  return {
    type: actionTypes.WELLNESS360_ME_OVERALL_SCORE_SUCCESS,
    data,
  };
};

export const Wellness360MeOverallScoreFailed = (err) => {
  return {
    type: actionTypes.WELLNESS360_ME_OVERALL_SCORE_FAILED,
    err,
  };
};

//* WELLNESS SUMMARY TOP 10 HEALTHY EMPLOYEES

export const Wellness360SummaryTopTeamsInitial = () => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_TOP_TEAMS_INITIAL,
  };
};

export const Wellness360SummaryTopTeamsProcess = (token, bodyData) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_TOP_TEAMS_PROCESS,
    token,
    bodyData,
  };
};

export const Wellness360SummaryTopTeamsSuccess = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_TOP_TEAMS_SUCCESS,
    data,
  };
};

export const Wellness360SummaryTopTeamsFailed = (err) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_TOP_TEAMS_FAILED,
    err,
  };
};

//* WELLNESS SUMMARY TEAM WISE UTILIZATION

export const Wellness360SummaryTeamWiseUtilizationInitial = () => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_TEAM_WISE_UTILIZATION_INITIAL,
  };
};

export const Wellness360SummaryTeamWiseUtilizationProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_TEAM_WISE_UTILIZATION_PROCESS,
    token,
    bodyData,
  };
};

export const Wellness360SummaryTeamWiseUtilizationSuccess = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_TEAM_WISE_UTILIZATION_SUCCESS,
    data,
  };
};

export const Wellness360SummaryTeamWiseUtilizationFailed = (err) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_TEAM_WISE_UTILIZATION_FAILED,
    err,
  };
};

//* Online time Breakdown SUMMARY *//

export const WELLNESS360SummaryOnlineTimeBreakdownInitial = (token) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_ONLINE_TIME_BREAK_DOWN_INITIAL,
    token,
  };
};
export const WELLNESS360SummaryOnlineTimeBreakdownProcess = (token) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_ONLINE_TIME_BREAK_DOWN_PROCESS,
    token,
  };
};
export const WELLNESS360SummaryOnlineTimeBreakdownSuccess = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_ONLINE_TIME_BREAK_DOWN_SUCCESS,
    data,
  };
};
export const WELLNESS360SummaryOnlineTimeBreakdownFailed = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_ONLINE_TIME_BREAK_DOWN_FAILED,
    data,
  };
};

//*  WELLNESS360 SUMMARY WELLNESS360 Level Break down *//

export const WELLNESS360SummaryWELLNESS360LevelBreakdownInitial = (token) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_WELLNESS360_LEVEL_BREAK_DOWN_INITIAL,
    token,
  };
};
export const WELLNESS360SummaryWELLNESS360LevelBreakdownProcess = (token) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_WELLNESS360_LEVEL_BREAK_DOWN_PROCESS,
    token,
  };
};
export const WELLNESS360SummaryWELLNESS360LevelBreakdownSuccess = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_WELLNESS360_LEVEL_BREAK_DOWN_SUCCESS,
    data,
  };
};
export const WELLNESS360SummaryWELLNESS360LevelBreakdownFailed = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_WELLNESS360_LEVEL_BREAK_DOWN_FAILED,
    data,
  };
};

//*  WELLNESS360 SUMMARY Top 3 most active team *//

export const WELLNESS360SummaryTopMostActiveTeamInitial = (token) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_TOP_MOST_ACTIVE_TEAM_INITIAL,
    token,
  };
};
export const WELLNESS360SummaryTopMostActiveTeamProcess = (token) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_TOP_MOST_ACTIVE_TEAM_PROCESS,
    token,
  };
};
export const WELLNESS360SummaryTopMostActiveTeamSuccess = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_TOP_MOST_ACTIVE_TEAM_SUCCESS,
    data,
  };
};
export const WELLNESS360SummaryTopMostActiveTeamFailed = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_TOP_MOST_ACTIVE_TEAM_FAILED,
    data,
  };
};
//*  WELLNESS360 SUMMARY Top 3 lease active team *//

export const WELLNESS360SummaryTopLeastActiveTeamInitial = (token) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_TOP_LEAST_ACTIVE_TEAM_INITIAL,
    token,
  };
};
export const WELLNESS360SummaryTopLeastActiveTeamProcess = (token) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_TOP_LEAST_ACTIVE_TEAM_PROCESS,
    token,
  };
};
export const WELLNESS360SummaryTopLeastActiveTeamSuccess = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_TOP_LEAST_ACTIVE_TEAM_SUCCESS,
    data,
  };
};
export const WELLNESS360SummaryTopLeastActiveTeamFailed = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_TOP_LEAST_ACTIVE_TEAM_FAILED,
    data,
  };
};

//*  WELLNESS360 SUMMARY TEAM WISE WELLNESS360 BREAK DOWN *//

export const WELLNESS360SummaryTeamWiseWELLNESS360BreakdownInitial = (
  token
) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_TEAM_WISE_WELLNESS360_BREAK_DOWN_INITIAL,
    token,
  };
};
export const WELLNESS360SummaryTeamWiseWELLNESS360BreakdownProcess = (
  token
) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_TEAM_WISE_WELLNESS360_BREAK_DOWN_PROCESS,
    token,
  };
};
export const WELLNESS360SummaryTeamWiseWELLNESS360BreakdownSuccess = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_TEAM_WISE_WELLNESS360_BREAK_DOWN_SUCCESS,
    data,
  };
};
export const WELLNESS360SummaryTeamWiseWELLNESS360BreakdownFailed = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_TEAM_WISE_WELLNESS360_BREAK_DOWN_FAILED,
    data,
  };
};
export const WELLNESS360SummaryHealthyEmployeesInitial = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_INITIAL,
    data,
  };
};
export const WELLNESS360SummaryHealthyEmployeesProcess = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PROCESS,
    data,
  };
};

export const WELLNESS360SummaryHealthyEmployeesSuccess = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_SUCCESS,
    data,
  };
};

export const WELLNESS360SummaryHealthyEmployeesFailed = (data) => {
  return {
    type: actionTypes.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_FAILED,
    data,
  };
};
