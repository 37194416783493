import * as actionTypes from "./actionTypes";

const searchBreaklist = (dataset, search) => {
  if (search.length > 0) {
    var searchdata = _.filter(dataset, (list) => {
      return list.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
        ? true
        : false;
    });

    return searchdata;
  } else {
    return dataset;
  }
};

const initialState = {
  //*GLOBAL*//
  //* LIST *//
  breaks_list_loading: false,
  breaks_list_success: false,
  breaks_list_failed: false,
  breaks_list_data: [],
  breaks_list_message: "",

  //* CREATE *//
  breaks_create_loading: false,
  breaks_create_success: false,
  breaks_create_failed: false,
  breaks_create_data: [],
  breaks_create_message: "",

  //* EDIT *//

  breaks_edit_loading: false,
  breaks_edit_success: false,
  breaks_edit_failed: false,
  breaks_edit_data: [],
  breaks_edit_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.BREAKS_LIST_INITIAL:
      return {
        ...state,
        breaks_list_loading: false,
        breaks_list_success: false,
        breaks_list_failed: false,
        breaks_list_data: [],
        breaks_list_message: "",
      };
    case actionTypes.BREAKS_LIST_PROCESS:
      return {
        ...state,
        breaks_list_loading: true,
        breaks_list_success: false,
        breaks_list_failed: false,
        breaks_list_data: [],
        breaks_list_message: "",
        breakMaster: [],
      };
    case actionTypes.BREAKS_LIST_SUCCESS:
      return {
        ...state,
        breaks_list_loading: false,
        breaks_list_success: true,
        breaks_list_failed: false,
        breaks_list_data: action.data.data,
        breaks_list_message: "",
        breakMaster: action.data.data,
      };
    case actionTypes.BREAKS_LIST_FAILED:
      return {
        ...state,
        breaks_list_loading: false,
        breaks_list_success: false,
        breaks_list_failed: true,
        breaks_list_message: action.data,
        breaks_list_data: [],
        breakMaster: [],
      };

    case actionTypes.BREAKS_LIST_SEARCH:
      return {
        ...state,
        breaks_list_data: searchBreaklist(state.breakMaster, action.data),
      };

    case actionTypes.BREAKS_CREATE_INITIAL:
      return {
        ...state,
        breaks_create_loading: false,
        breaks_create_success: false,
        breaks_create_failed: false,
        breaks_create_data: [],
        breaks_create_message: "",
      };
    case actionTypes.BREAKS_CREATE_PROCESS:
      return {
        ...state,
        breaks_create_loading: true,
        breaks_create_success: false,
        breaks_create_failed: false,
        breaks_create_data: [],
        breaks_create_message: "",
      };
    case actionTypes.BREAKS_CREATE_SUCCESS:
      return {
        ...state,
        breaks_create_loading: false,
        breaks_create_success: true,
        breaks_create_failed: false,
        breaks_create_data: action.data,
        breaks_list_data: [...state.breaks_list_data, action.data],
        breaks_create_message: "",
      };

    case actionTypes.BREAKS_CREATE_FAILED:
      return {
        ...state,
        breaks_create_loading: false,
        breaks_create_success: false,
        breaks_create_failed: true,
        breaks_create_message: action.data,
        breaks_create_data: action.data,
      };

    case actionTypes.BREAKS_EDIT_INITIAL:
      return {
        ...state,
        breaks_edit_loading: false,
        breaks_edit_success: false,
        breaks_edit_failed: false,
        breaks_edit_data: [],
        breaks_edit_message: "",
      };
    case actionTypes.BREAKS_EDIT_PROCESS:
      return {
        ...state,
        breaks_edit_loading: true,
        breaks_edit_success: false,
        breaks_edit_failed: false,
        breaks_edit_data: [],
        breaks_edit_message: "",
      };
    case actionTypes.BREAKS_EDIT_SUCCESS:
      const fetchBreakslist = [...state.breaks_list_data];
      const findIndex = fetchBreakslist.findIndex(
        (item) => item.id == action.data.id
      );
      fetchBreakslist[findIndex] = action.data;

      return {
        ...state,
        breaks_edit_loading: false,
        breaks_edit_success: true,
        breaks_edit_failed: false,
        breaks_edit_data: action.data,
        breaks_list_data: fetchBreakslist,
        breaks_edit_message: "",
      };

    case actionTypes.BREAKS_EDIT_FAILED:
      return {
        ...state,
        breaks_edit_loading: false,
        breaks_edit_success: false,
        breaks_edit_failed: true,
        breaks_edit_message: action.data,
        breaks_edit_data: action.data,
      };

    default:
      return state;
  }
};
