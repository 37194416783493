import { put } from "redux-saga/effects";
import { Client } from "../../../../utils/transport";
import {
  ReportsDailyAttendanceSuccess,
  ReportsDailyAttendanceFailed,
  ReportsDownloadDailyAttendanceSuccess,
  ReportsDownloadDailyAttendanceFailed,
} from "./action";

export function* GetReportsDailyAttendance(data) {
  let config = {
    method: "POST",
    url: "query/reports/daily_attendance_details",

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };

  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(ReportsDailyAttendanceSuccess(response.data));
    } else {
      yield put(ReportsDailyAttendanceFailed(response));
    }
  } catch (err) {
    yield put(ReportsDailyAttendanceFailed(err));
  }
}

export function* GetReportsDownloadDailyAttendance(data) {
  let config = {
    method: "POST",
    url: "query/reports/daily_attendance_details",

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };

  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(ReportsDownloadDailyAttendanceSuccess(response.data));
    } else {
      yield put(ReportsDownloadDailyAttendanceFailed(response));
    }
  } catch (err) {
    yield put(ReportsDownloadDailyAttendanceFailed(err));
  }
}
