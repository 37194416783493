import * as actionTypes from "./actionTypes";

//*  ACTIVITY DETAILED WORKING TIME TRENDS *//

export const fetchTaskActivities = (payload) => {
  return {
    type: actionTypes.FETCH_TASK_ACTIVITIES,
    payload,
  };
};

export const fetchTaskActivitiesSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_TASK_ACTIVITIES_SUCCESS,
    payload,
  };
};

export const fetchTaskActivitiesFailed = (payload) => {
  return {
    type: actionTypes.FETCH_TASK_ACTIVITIES_FAILED,
    payload,
  };
};

export const createTaskActivity = (payload) => {
  return {
    type: actionTypes.CREATE_TASK_ACTIVITY,
    payload,
  };
};

export const createTaskActivitySuccess = (payload) => {
  return {
    type: actionTypes.CREATE_TASK_ACTIVITY_SUCCESS,
    payload,
  };
};

export const createTaskActivityFailed = (payload) => {
  return {
    type: actionTypes.CREATE_TASK_ACTIVITY_FAILED,
    payload,
  };
};

export const deleteTaskActivity = (payload) => {
  return {
    type: actionTypes.DELETE_TASK_ACTIVITY,
    payload,
  };
};

export const deleteTaskActivitySuccess = (payload) => {
  return {
    type: actionTypes.DELETE_TASK_ACTIVITY_SUCCESS,
    payload,
  };
};

export const deleteTaskActivityFailed = (payload) => {
  return {
    type: actionTypes.DELETE_TASK_ACTIVITY_FAILED,
    payload,
  };
};

export const updateTaskActivity = (payload) => {
  return {
    type: actionTypes.UPDATE_TASK_ACTIVITY,
    payload,
  };
};

export const updateTaskActivitySuccess = (payload) => {
  return {
    type: actionTypes.UPDATE_TASK_ACTIVITY_SUCCESS,
    payload,
  };
};

export const updateTaskActivityFailed = (payload) => {
  return {
    type: actionTypes.UPDATE_TASK_ACTIVITY_FAILED,
    payload,
  };
};

export const setTaskActivityErrorMessage = (payload) => {
  return {
    type: actionTypes.SET_TASK_ACTIVITY_ERROR_MESSAGE,
    payload,
  };
};

export const fetchActiveTimerActivitySuccess = (payload) => {
  return {
    type: actionTypes.FETCH_ACTIVE_TIMER_ACTIVITY_SUCCESS,
    payload,
  };
};

export const createActiveTimerActivitySuccess = (payload) => {
  return {
    type: actionTypes.CREATE_ACTIVE_TIMER_ACTIVITY_SUCCESS,
    payload,
  };
};

export const updateActiveTimerActivitySuccess = (payload) => {
  return {
    type: actionTypes.UPDATE_ACTIVE_TIMER_ACTIVITY_SUCCESS,
    payload,
  };
};

export const setSelectedTaskDrawer = (payload) => {
  return {
    type: actionTypes.SET_SELECTED_TASK_DRAWER,
    payload,
  };
};

// export const fetchActiveTimerActivity = (payload) => {
//   return {
//     type: actionTypes.FETCH_ACTIVE_TIMER_ACTIVITY,
//     payload,
//   };
// };

// export const fetchActiveTimerActivityFailed = (payload) => {
//   return {
//     type: actionTypes.FETCH_ACTIVE_TIMER_ACTIVITY_FAILED,
//     payload,
//   };
// };

// export const setActiveTimerActivityErrorMessage = (payload) => {
//   return {
//     type: actionTypes.SET_ACTIVE_TIMER_ACTIVITY_ERROR_MESSAGE,
//     payload,
//   };
// };
