import React from "react";

export const Realtime = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        // d="M1 10.6136H4.0447C4.17946 10.6136 4.29769 10.5238 4.33376 10.3939L6.04165 4.2455C6.12205 3.95608 6.5307 3.95133 6.61782 4.23879L10.158 15.9216C10.2406 16.194 10.6208 16.209 10.7245 15.9439L13.845 7.96934C13.9397 7.72729 14.2765 7.71201 14.3927 7.9445L15.6444 10.4478C15.6952 10.5494 15.7991 10.6136 15.9127 10.6136H19"
        // stroke="white"
        // stroke-width="2"
        // fill="none"
        d="M10.4,17.1C10.4,17.1,10.4,17.1,10.4,17.1c-0.6,0-1.1-0.4-1.2-0.9L6.4,6.8l-1.1,3.8c-0.2,0.6-0.7,1-1.3,1H1v-2
        h2.5L5.1,4C5.2,3.4,5.7,3,6.3,3c0.6,0,1.1,0.4,1.3,0.9l3,9.7l2.4-6.1c0.2-0.5,0.6-0.8,1.2-0.8c0.5,0,1,0.3,1.2,0.7l1.1,2.1H19v2
        h-3.1c-0.5,0-0.9-0.3-1.2-0.7l-0.5-1.1l-2.5,6.5C11.5,16.8,11,17.1,10.4,17.1z"
      />
    </svg>
  );
};
