import * as actionTypes from "./actionTypes";

//* ATTENDANCE SUMMARY WEDIGT *//

export const AttendanceSummaryWedigtInitial = (token) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_WEDIGT_INITIAL,
    token,
  };
};
export const AttendanceSummaryWedigtProcess = (token) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_WEDIGT_PROCESS,
    token,
  };
};
export const AttendanceSummaryWedigtSuccess = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_WEDIGT_SUCCESS,
    data,
  };
};
export const AttendanceSummaryWedigtFailed = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_WEDIGT_FAILED,
    data,
  };
};

//* TODAY ATTENDANCE SUMMARY *//

export const AttendanceSummaryTodayInitial = () => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_TODAY_INITIAL,
  };
};
export const AttendanceSummaryTodayProcess = (token, bodyData) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_TODAY_PROCESS,
    token,
    bodyData,
  };
};
export const AttendanceSummaryTodaySuccess = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_TODAY_SUCCESS,
    data,
  };
};
export const AttendanceSummaryTodayFailed = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_TODAY_FAILED,
    data,
  };
};

//*  ATTENDANCE SUMMARY ATTENDANCE TRENDS *//

export const AttendanceSummaryAttendanceTrendInitial = () => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_INITIAL,
  };
};
export const AttendanceSummaryAttendanceTrendProcess = (token, bodyData) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_PROCESS,
    token,
    bodyData,
  };
};
export const AttendanceSummaryAttendanceTrendSuccess = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_SUCCESS,
    data,
  };
};
export const AttendanceSummaryAttendanceTrendFailed = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_FAILED,
    data,
  };
};

export const AttendanceSummaryAttendanceTrendLessInitial = () => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_LESS_INITIAL,
  };
};
export const AttendanceSummaryAttendanceTrendLessProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_LESS_PROCESS,
    token,
    bodyData,
  };
};
export const AttendanceSummaryAttendanceTrendLessSuccess = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_LESS_SUCCESS,
    data,
  };
};
export const AttendanceSummaryAttendanceTrendLessFailed = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_LESS_FAILED,
    data,
  };
};

export const AttendanceSummaryAttendanceTrendCurrentInitial = () => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_CURRENT_INITIAL,
  };
};
export const AttendanceSummaryAttendanceTrendCurrentProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_CURRENT_PROCESS,
    token,
    bodyData,
  };
};
export const AttendanceSummaryAttendanceTrendCurrentSuccess = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_CURRENT_SUCCESS,
    data,
  };
};
export const AttendanceSummaryAttendanceTrendCurrentFailed = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_CURRENT_FAILED,
    data,
  };
};

//*  ATTENDANCE SUMMARY BREAK TRENDS *//

export const AttendanceSummaryBreakTrendInitial = () => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_INITIAL,
  };
};
export const AttendanceSummaryBreakTrendProcess = (token, bodyData) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_PROCESS,
    token,
    bodyData,
  };
};
export const AttendanceSummaryBreakTrendSuccess = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_SUCCESS,
    data,
  };
};
export const AttendanceSummaryBreakTrendFailed = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_FAILED,
    data,
  };
};

//*  ATTENDANCE SUMMARY BREAK TRENDS DATE WISE*//

export const AttendanceSummaryBreakTrendDateWiseInitial = () => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_DATE_WISE_INITIAL,
  };
};
export const AttendanceSummaryBreakTrendDateWiseProcess = (token, bodyData) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_DATE_WISE_PROCESS,
    token,
    bodyData,
  };
};
export const AttendanceSummaryBreakTrendDateWiseSuccess = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_DATE_WISE_SUCCESS,
    data,
  };
};
export const AttendanceSummaryBreakTrendDateWiseFailed = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_DATE_WISE_FAILED,
    data,
  };
};

//*  ATTENDANCE SUMMARY BREAK TRENDS LESS*//

export const AttendanceSummaryBreakTrendLessInitial = () => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_LESS_INITIAL,
  };
};
export const AttendanceSummaryBreakTrendLessProcess = (token, bodyData) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_LESS_PROCESS,
    token,
    bodyData,
  };
};
export const AttendanceSummaryBreakTrendLessSuccess = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_LESS_SUCCESS,
    data,
  };
};
export const AttendanceSummaryBreakTrendLessFailed = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_LESS_FAILED,
    data,
  };
};

//*  ATTENDANCE SUMMARY Late Arrival Tendency *//

export const AttendanceSummaryLateArrivalTendencyInitial = () => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_INITIAL,
  };
};
export const AttendanceSummaryLateArrivalTendencyProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_PROCESS,
    token,
    bodyData,
  };
};
export const AttendanceSummaryLateArrivalTendencySuccess = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_SUCCESS,
    data,
  };
};
export const AttendanceSummaryLateArrivalTendencyFailed = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_FAILED,
    data,
  };
};

//*  ATTENDANCE SUMMARY Late Arrival Tendency Less *//

export const AttendanceSummaryLateArrivalTendencyLessInitial = () => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_LESS_INITIAL,
  };
};
export const AttendanceSummaryLateArrivalTendencyLessProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_LESS_PROCESS,
    token,
    bodyData,
  };
};
export const AttendanceSummaryLateArrivalTendencyLessSuccess = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_LESS_SUCCESS,
    data,
  };
};
export const AttendanceSummaryLateArrivalTendencyLessFailed = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_LESS_FAILED,
    data,
  };
};

//* WORKING TIME ATTENDANCE SUMMARY *//

export const AttendanceSummaryWorkingTimeInitial = () => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_WORKING_TIME_INITIAL,
  };
};
export const AttendanceSummaryWorkingTimeProcess = (token, bodyData) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_WORKING_TIME_PROCESS,
    token,
    bodyData,
  };
};
export const AttendanceSummaryWorkingTimeSuccess = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_WORKING_TIME_SUCCESS,
    data,
  };
};
export const AttendanceSummaryWorkingTimeFailed = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_WORKING_TIME_FAILED,
    data,
  };
};

//* WORKING TIME LESS ATTENDANCE SUMMARY *//

export const AttendanceSummaryWorkingTimeLessInitial = () => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_WORKING_TIME_LESS_INITIAL,
  };
};
export const AttendanceSummaryWorkingTimeLessProcess = (token, bodyData) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_WORKING_TIME_LESS_PROCESS,
    token,
    bodyData,
  };
};
export const AttendanceSummaryWorkingTimeLessSuccess = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_WORKING_TIME_LESS_SUCCESS,
    data,
  };
};
export const AttendanceSummaryWorkingTimeLessFailed = (data) => {
  return {
    type: actionTypes.ATTENDANCE_SUMMARY_WORKING_TIME_LESS_FAILED,
    data,
  };
};
