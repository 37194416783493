import React from "react";

export const Productivity = ({ viewBox = "0 0 20 20" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      fill="currentColor"
    >
      <path
        d="M2.9,14c-0.7,0.7-1.8,4.1-1.9,4.5c0,0.2,0,0.4,0.2,0.4c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
		c0.4-0.1,3.8-1.1,4.5-1.9c0.9-0.9,0.9-2.2,0-3.1C5.1,13.2,3.8,13.2,2.9,14z"
      />
      <path
        d="M18.8,1.6c0-0.2-0.2-0.3-0.4-0.4C17.5,1,16.6,1,15.7,1c-3.4,0-5.4,1.8-6.9,4.2H5.4C4.8,5.2,4.3,5.5,4,6
		L2.3,9.3c0,0.1-0.1,0.2-0.1,0.4c0,0.4,0.4,0.8,0.8,0.8h3.4l-0.7,0.7c-0.4,0.4-0.4,1.1,0,1.5l1.7,1.7c0.4,0.4,1.1,0.4,1.5,0l0.7-0.7
		V17c0,0.4,0.3,0.8,0.8,0.8c0.1,0,0.2,0,0.3-0.1l3.2-1.6c0.5-0.3,0.8-0.8,0.9-1.4v-3.4C17.2,9.7,19,7.7,19,4.3
		C19,3.4,19,2.5,18.8,1.6z M14.8,6.5c-0.7,0-1.3-0.6-1.3-1.3s0.6-1.3,1.3-1.3c0.7,0,1.3,0.6,1.3,1.3S15.5,6.5,14.8,6.5z"
      />
    </svg>
  );
};
