import React from "react";
import { Table } from "antd";
import { useSelector } from "react-redux";
import dayjs from "dayjs";

const UserSessionTable = () => {
  const {
    UserSessionData,
    UserSessionLoading,
  }: {
    UserSessionData: UserSession[];
    UserSessionLoading: boolean;
  } = useSelector(
    // @ts-ignore
    (state) => state.tenantSettings
  );
  const columns = [
    {
      title: "IP address",
      dataIndex: "ipAddress",

      render: (val, record) => {
        return record?.ipAddress;
      },
    },
    {
      title: "Last accessed",
      dataIndex: "lastAccess",

      render: (val, record) => {
        return dayjs(record?.lastAccess).format("MMMM DD, YYYY hh:mm A");
      },
    },
    {
      title: "Clients",
      dataIndex: "Client",

      render: (val, record) => {
        const clients = record?.clients;

        if (clients) {
          const clientIds = Object.keys(clients);
          const clientValues = Object.values(clients);

          const clientInfo = clientIds.map((clientId, index) => (
            <div key={clientId}>
              {`${clientValues[index]}`}
              {/* {`ID: ${clientId}, Value: ${clientValues[index]}`} */}
            </div>
          ));

          return clientInfo;
        }

        return "No clients";
      },
    },
    {
      title: "Started",
      dataIndex: "start",

      render: (val, record) => {
        return dayjs(record?.start).format("MMMM DD, YYYY hh:mm A");
      },
    },
  ];

  return (
    <Table
      // rowSelection={rowSelection}
      loading={UserSessionLoading}
      columns={columns}
      dataSource={UserSessionData || []}
      size="small"
      pagination={false}
    />
  );
};

export default UserSessionTable;
