import * as actionTypes from "./actionTypes";

const initialState = {
  genAIQueryLoading: false,
  genAIQuerySuccess: false,
  genAICurrentQuestion: "",
  genAIData: [],
  genAIErrorMessage: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_RESPONSE_FOR_GEN_AI_QUERY:
      return {
        ...state,
        genAIQueryLoading: true,
        genAICurrentQuestion: action.payload.question,
        genAIErrorMessage: "",
        genAIQuerySuccess: false,
      };

    case actionTypes.GET_RESPONSE_FOR_GEN_AI_QUERY_SUCCESS:
      return {
        ...state,
        genAIQueryLoading: false,
        genAIData: [
          ...state.genAIData,
          {
            question: action.payload.question,
            answer: action.payload.data.text_response,
          },
        ],
        genAICurrentQuestion: "",
        genAIQuerySuccess: true,
      };

    case actionTypes.GET_RESPONSE_FOR_GEN_AI_QUERY_FAILED:
      return {
        ...state,
        genAIQueryLoading: false,
        genAIErrorMessage: action.payload,
        genAIQuerySuccess: false,
      };

    default:
      return state;
  }
};
