import * as actionTypes from "./actionTypes";

export const getResponseForGenAIQuery = (payload) => {
  return {
    type: actionTypes.GET_RESPONSE_FOR_GEN_AI_QUERY,
    payload,
  };
};

export const getResponseForGenAIQuerySuccess = (payload) => {
  return {
    type: actionTypes.GET_RESPONSE_FOR_GEN_AI_QUERY_SUCCESS,
    payload,
  };
};

export const getResponseForGenAIQueryFailed = (payload) => {
  return {
    type: actionTypes.GET_RESPONSE_FOR_GEN_AI_QUERY_FAILED,
    payload,
  };
};
