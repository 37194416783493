//* TENANT Master START*/
//* LIST *//

export const TENANT_LIST_INITIAL = "TENANT_LIST_INITIAL";
export const TENANT_LIST_PROCESS = "TENANT_LIST_PROCESS";
export const TENANT_LIST_SUCCESS = "TENANT_LIST_SUCCESS";
export const TENANT_LIST_FAILED = "TENANT_LIST_FAILED";

//* CREATE *//

export const TENANT_CREATE_INITIAL = "TENANT_CREATE_INITIAL";
export const TENANT_CREATE_PROCESS = "TENANT_CREATE_PROCESS";
export const TENANT_CREATE_SUCCESS = "TENANT_CREATE_SUCCESS";
export const TENANT_CREATE_FAILED = "TENANT_CREATE_FAILED";

//* EDIT *//

export const TENANT_EDIT_INITIAL = "TENANT_EDIT_INITIAL ";
export const TENANT_EDIT_PROCESS = "TENANT_EDIT_PROCESS";
export const TENANT_EDIT_SUCCESS = "TENANT_EDIT_SUCCESS";
export const TENANT_EDIT_FAILED = "TENANT_EDIT_FAILED";

//* TENANT_ END */
