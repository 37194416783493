import { put } from "redux-saga/effects";
import { BreakReportAction } from "../../action";
import { Client } from "../../../../utils/transport";

export function* GetBreakReportData(action) {
  const config = {
    method: "POST",
    url: `query/reports/break_report`,

    headers: {
      ...action.payload.header,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);
    yield put(BreakReportAction.BreaksReportSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(BreakReportAction.BreaksReportFailed(errorMessage));
  }
}

export function* GetBreakDownloadReportData(action) {
  const config = {
    method: "POST",
    url: `query/reports/break_report`,

    headers: {
      ...action.payload.header,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);
    yield put(BreakReportAction.BreaksDownloadReportSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(BreakReportAction.BreaksDownloadReportFailed(errorMessage));
  }
}

export function* GetMonthlyBreakReportData(action) {
  const config = {
    method: "POST",
    url: `query/reports/monthly_break_report`,

    headers: {
      ...action.payload.header,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);
    yield put(BreakReportAction.MonthlyBreaksReportSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(BreakReportAction.MonthlyBreaksReportFailed(errorMessage));
  }
}

export function* GetMonthlyBreakDownloadReportData(action) {
  const config = {
    method: "POST",
    url: `query/reports/monthly_break_report`,

    headers: {
      ...action.payload.header,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);
    yield put(
      BreakReportAction.MonthlyBreaksDownloadReportSuccess(response.data)
    );
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(
      BreakReportAction.MonthlyBreaksDownloadReportFailed(errorMessage)
    );
  }
}
