import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Chart from "../../../../components/chart";
import { Skeleton, Empty } from "antd";
import { getColorCodes } from "utils/colorCodes";
import { ApexOptions } from "apexcharts"; // Import ApexOptions for type definitions

const colorCodes = getColorCodes();

const Cardskeleton = () => {
  return (
    <div className="charts">
      <div className="chart_header">
        <Skeleton.Input size={"small"} style={{ height: "15px" }} />
        <Skeleton.Avatar
          size={"small"}
          shape={"circle"}
          style={{ height: "15px", width: "15px" }}
        />
      </div>
      <div className="chartContainer chart_tool" id="chart">
        <Skeleton.Avatar
          size={"large"}
          style={{ height: "275px", width: "100%" }}
        />
      </div>
    </div>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

type UserWellnessTrendProps = {
  wellnessCount: number[];
};

const UserWellnessTrend: React.FC<UserWellnessTrendProps> = ({
  wellnessCount,
}) => {
  const data = {
    series: [
      {
        data: wellnessCount,
      },
    ],
  };

  const chartOptions: ApexOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
        distributed: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    colors: ["#7B61FF", "#FFB800", "#D5D4DB"],
    stroke: {
      show: true,
      width: 2,
      colors: ["#fff"],
    },
    xaxis: {
      categories: ["Healthy", "Overburdened", "Underutilized"],
      labels: {
        style: {
          colors: colorCodes.graph_legends_font_color,
        },
      },
    },
    yaxis: [
      {
        axisTicks: {
          show: true,
        },
        axisBorder: {
          show: true,
          color: "#008FFB",
        },
        min: 0,
        max: wellnessCount[0] + wellnessCount[1] + wellnessCount[2],
        tickAmount: 2,
        labels: {
          //   showDuplicates: false,
          show: true,
          formatter: function (val) {
            return val + " days";
          },
          style: {
            colors: colorCodes.graph_legends_font_color,
          },
        },
      },
    ],
    legend: {
      show: false,
    },
    tooltip: {
      enabled: true,
      theme: "light",
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        const xname = w.globals.labels[dataPointIndex];
        const total = series[seriesIndex].reduce((acc, val) => acc + val, 0);
        const percentage = (series[seriesIndex][dataPointIndex] / total) * 100;
        const days = series[seriesIndex][dataPointIndex];

        return `<div class="wtooltip">
             <div class="colordot" style="background:${
               w.globals.colors[dataPointIndex]
             }"></div>
             ${xname}
             <div class="w_value">${days} days (${percentage.toFixed(0)}%)</div>
             </div>`;
      },
    },
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {loading ? (
        <Cardskeleton />
      ) : (
        <div className="charts border0 marginT border-0 shadow-none">
          <div
            className="chart_tool legend_left pl-0 pr-0"
            id="chart"
            style={{ padding: "0 20px" }}
          >
            {wellnessCount.length > 0 ? (
              <Chart
                height={270}
                options={chartOptions}
                series={data.series}
                type="bar"
              />
            ) : (
              <div style={{ height: "242px" }} className="emptyblock">
                <Empty description={false} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

UserWellnessTrend.propTypes = {
  wellnessCount: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default UserWellnessTrend;
