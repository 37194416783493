//WORK DID BY KANCHAN
import * as actionTypes from "./actionTypes";

export const ShiftsListInitial = (token) => {
  return {
    type: actionTypes.SHIFTS_LIST_INITIAL,
    token,
  };
};
export const ShiftsListProcess = (data) => {
  return {
    type: actionTypes.SHIFTS_LIST_PROCESS,
    data,
  };
};
export const ShiftsListSuccess = (data) => {
  return {
    type: actionTypes.SHIFTS_LIST_SUCCESS,
    data,
  };
};
export const ShiftsListFailed = (data) => {
  return {
    type: actionTypes.SHIFTS_LIST_FAILED,
    data,
  };
};

//CREATE SHIFTS

export const ShiftsCreateInitial = (token, data) => {
  return {
    type: actionTypes.SHIFTS_CREATE_INITIAL,
    data,
    token,
  };
};

export const ShiftsCreateProcess = (data) => {
  return {
    type: actionTypes.SHIFTS_CREATE_PROCESS,
    data,
  };
};
export const ShiftsCreateSuccess = (data) => {
  return {
    type: actionTypes.SHIFTS_CREATE_SUCCESS,
    data,
  };
};
export const ShiftsCreateFailed = (data) => {
  return {
    type: actionTypes.SHIFTS_CREATE_FAILED,
    data,
  };
};

//EDIT SHIFTS

export const ShiftsEditInitial = (token, id, data) => {
  return {
    type: actionTypes.SHIFTS_EDIT_INITIAL,
    data,
    id,
    token,
  };
};
export const ShiftsEditProcess = (data) => {
  return {
    type: actionTypes.SHIFTS_EDIT_PROCESS,
    data,
  };
};
export const ShiftsEditSuccess = (data) => {
  return {
    type: actionTypes.SHIFTS_EDIT_SUCCESS,
    data,
  };
};
export const ShiftsEditFailed = (data) => {
  return {
    type: actionTypes.SHIFTS_EDIT_FAILED,
    data,
  };
};

export const shiftsListSearch = (data) => {
  return {
    type: actionTypes.SHIFTS_LIST_SEARCH,
    data,
  };
};
