// TIMELINE TABLE

export const FETCH_TASK_ACTIVITIES = "FETCH_TASK_ACTIVITIES";
export const FETCH_TASK_ACTIVITIES_SUCCESS = "FETCH_TASK_ACTIVITIES_SUCCESS";
export const FETCH_TASK_ACTIVITIES_FAILED = "FETCH_TASK_ACTIVITIES_FAILED";
export const FETCH_ACTIVE_TIMER_ACTIVITY_SUCCESS =
  "FETCH_ACTIVE_TIMER_ACTIVITY_SUCCESS";

export const CREATE_TASK_ACTIVITY = "CREATE_TASK_ACTIVITY";
export const CREATE_TASK_ACTIVITY_SUCCESS = "CREATE_TASK_ACTIVITY_SUCCESS";
export const CREATE_TASK_ACTIVITY_FAILED = "CREATE_TASK_ACTIVITY_FAILED";
export const CREATE_ACTIVE_TIMER_ACTIVITY_SUCCESS =
  "CREATE_ACTIVE_TIMER_ACTIVITY_SUCCESS";

export const DELETE_TASK_ACTIVITY = "DELETE_TASK_ACTIVITY";
export const DELETE_TASK_ACTIVITY_SUCCESS = "DELETE_TASK_ACTIVITY_SUCCESS";
export const DELETE_TASK_ACTIVITY_FAILED = "DELETE_TASK_ACTIVITY_FAILED";

export const UPDATE_TASK_ACTIVITY = "UPDATE_TASK_ACTIVITY";
export const UPDATE_TASK_ACTIVITY_SUCCESS = "UPDATE_TASK_ACTIVITY_SUCCESS";
export const UPDATE_TASK_ACTIVITY_FAILED = "UPDATE_TASK_ACTIVITY_FAILED";
export const UPDATE_ACTIVE_TIMER_ACTIVITY_SUCCESS =
  "UPDATE_ACTIVE_TIMER_ACTIVITY_SUCCESS";

export const SET_TASK_ACTIVITY_ERROR_MESSAGE =
  "SET_TASK_ACTIVITY_ERROR_MESSAGE";

export const SET_SELECTED_TASK_DRAWER = "SET_SELECTED_TASK_DRAWER";

// export const FETCH_ACTIVE_TIMER_ACTIVITY = "FETCH_ACTIVE_TIMER_ACTIVITY";

// export const FETCH_ACTIVE_TIMER_ACTIVITY_FAILED =
//   "FETCH_ACTIVE_TIMER_ACTIVITY_FAILED";

// export const SET_ACTIVE_TIMER_ACTIVITY_ERROR_MESSAGE =
//   "SET_ACTIVE_TIMER_ACTIVITY_ERROR_MESSAGE";
