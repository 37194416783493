//* domain Start*/

export const DOMAIN_BLOCKING_POLICY_LIST_INITIAL =
  "DOMAIN_BLOCKING_POLICY_LIST_INITIAL";
export const DOMAIN_BLOCKING_POLICY_LIST_PROCESS =
  "DOMAIN_BLOCKING_POLICY_LIST_PROCESS";
export const DOMAIN_BLOCKING_POLICY_LIST_SUCCESS =
  "DOMAIN_BLOCKING_POLICY_LIST_SUCCESS";
export const DOMAIN_BLOCKING_POLICY_LIST_FAILED =
  "DOMAIN_BLOCKING_POLICY_LIST_FAILED";

export const DOMAIN_BLOCKING_POLICY_LIST_SEARCH =
  "DOMAIN_BLOCKING_POLICY_LIST_SEARCH";

export const DOMAIN_BLOCKING_POLICY_CREATE_INITIAL =
  "DOMAIN_BLOCKING_POLICY_CREATE_INITIAL ";
export const DOMAIN_BLOCKING_POLICY_CREATE_PROCESS =
  "DOMAIN_BLOCKING_POLICY_CREATE_PROCESS ";
export const DOMAIN_BLOCKING_POLICY_CREATE_SUCCESS =
  "DOMAIN_BLOCKING_POLICY_CREATE_SUCCESS";
export const DOMAIN_BLOCKING_POLICY_CREATE_FAILED =
  "DOMAIN_BLOKING_POLICY_CREATE_FAILED";

export const DOMAIN_BLOCKING_POLICY_EDIT_INITIAL =
  "DOMAIN_BLOCKING_POLICY_EDIT_INITIAL ";
export const DOMAIN_BLOCKING_POLICY_EDIT_PROCESS =
  "DOMAIN_BLOCKING_POLICY_EDIT_PROCESS ";
export const DOMAIN_BLOCKING_POLICY_EDIT_SUCCESS =
  "DOMAIN_BLOCKING_POLICY_EDIT_SUCCESS";
export const DOMAIN_BLOCKING_POLICY_EDIT_FAILED =
  "DOMAIN_BLOCKING_POLICY_EDIT_FAILED";

//* domain End*/
