import React, { useState } from "react";
import PropTypes from "prop-types";
import "../index.scss";
import { InfoCircleFilled } from "@ant-design/icons";
import _ from "lodash";
import Chart from "../../chart";
import { useSelector } from "react-redux";
import { Skeleton, Typography, Tooltip, Empty } from "antd";
import { getDonutChartOptions } from "utils/apexChartsUtil.tsx";

const { Title } = Typography;

const Cardskeleton = () => {
  return (
    <>
      <div className="charts">
        <div className="chart_header">
          <Skeleton.Input
            size={"small"}
            shape={"default"}
            style={{ height: "15px" }}
          />
          <Skeleton.Avatar
            size={"small"}
            shape={"circle"}
            style={{ height: "15px", width: "15px" }}
          />
        </div>
        <div className="chartContainer chart_tool" id="chart">
          {/* chart */}
          <Skeleton.Avatar
            size={"large"}
            shape={"default"}
            style={{ height: "275px", width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

const CategoryUtilisation = (props) => {
  const {
    applicationCategoryWorkingHoursTime,
    applicationCategoryList,
    hideTooltip,
  } = props || {};

  const appusages = {
    series: applicationCategoryWorkingHoursTime,
  };
  const { chartTitle, tooltipTitle } = props || {};

  const applicationsummary = useSelector((state) => state.applicationsummary);

  const chartOptions = getDonutChartOptions({
    options: {
      plotOptions: {
        pie: {
          customScale: 0.8,
          donut: {
            size: "77%",
          },
        },
      },
      labels: applicationCategoryList || [],

      colors: [
        "#2DF5CE",
        "#876CDD",
        "#1170C2",
        "#217378",
        "#3B378B",
        "#44B44F",
        "#4670B4",
        "#4FAED4",
        "#52B2DA",
        "#569DD2",
        "#58B4DB",
        "#65348F",
        "#691EF6",
        "#727DF0",
        "#7C9FBF",
        "#83D2F4",
        "#ADD7F6",
        "#AFF6E9",
        "#BF5FF4",
        "#C7DC59",
        "#C9CDD6",
        "#CCB0C6",
        "#D0B2F2",
        "#D21E75",
        "#D59BA7",
        "#DF6BAC",
        "#E5B032",
        "#E5E1BC",
        "#E67436",
        "#F7C9B0",
      ],

      legend: {
        formatter: function (label, opts) {
          return (
            '<div class="legenddata"><div class="datapoint">' +
            opts.w.globals.seriesPercent[opts.seriesIndex][0].toFixed(2) +
            "%" +
            "</div>" +
            label +
            "</div>"
          );
        },
      },
    },
    legendPosition: "right",
  });

  const [loading, setLoading] = useState(true);
  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  return (
    <>
      {applicationsummary.application_summary_category_utilization_processing ? (
        <Cardskeleton />
      ) : (
        <div className="charts">
          <div className="chart_header">
            <Title level={2}>{chartTitle}</Title>
            {!hideTooltip && (
              <Tooltip title={tooltipTitle}>
                <InfoCircleFilled />
              </Tooltip>
            )}
          </div>
          <div
            className="chart_tool legend_left"
            id="chart"
            style={{ marginBottom: "20px" }}
          >
            {/* chart */}
            {(
              applicationsummary?.application_summary_category_utilization_data ||
              []
            ).length > 0 ? (
              <Chart
                options={chartOptions.options}
                series={appusages.series}
                type="donut"
                height={300}
              />
            ) : (
              <div style={{ height: "242px" }} className="emptyblock mt-[40px]">
                <Empty description={false} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

CategoryUtilisation.propTypes = {
  getList: PropTypes.func,
};

export default CategoryUtilisation;
