import * as actionTypes from "./actionTypes";

export const downloadDynamicReport = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_DYNAMIC_REPORT,
    payload,
  };
};
export const downloadDynamicReportSuccess = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_DYNAMIC_REPORT_SUCCESS,
    payload,
  };
};
export const downloadDynamicReportFailed = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_DYNAMIC_REPORT_SUCCESS,
    payload,
  };
};
