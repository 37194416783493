import { put } from "redux-saga/effects";
import { RoleAction } from "../action";
import { Client } from "../../../utils/transport";
import { notification } from "antd";
import { copyToClipboard } from "utils/commonUtils.tsx";

export function* GenerateStealthKeySaga(action) {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 2);
  const stealthConfig = {
    method: "POST",
    url: `/api/v1/admin/tokens/`,
    headers: {
      ...action.payload.headers,
    },
    data: {
      type: "ADMIN_API_KEY",
      expiry_date: date.toISOString(),
    },
  };

  try {
    try {
      let config = {
        method: "POST",
        url: "ROLE_PHASE_1",

        data: {
          name: "Stealth User",
          description: "",
          permissions: ["tenant_user"],
        },
        headers: {
          ...action.payload.headers,
        },
      };

      yield Client.request(config);
    } catch (e) {
      console.log(e);
    }

    const response = yield Client.request(stealthConfig);

    copyToClipboard(response.data.value, false);

    notification.success({
      message: "Success!",
      description: "Stealth key copied to clipboard!",
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    // notification.error({
    //   message: "Uh Oh!",
    //   description: errorMessage,
    // });
  }
}

export function* CreateRole(data) {
  let config = {
    method: "POST",
    url: "ROLE_PHASE_1",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.BodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(RoleAction.RoleCreateSuccess(response.data));
    } else {
      yield put(RoleAction.RoleCreateFailed(response));
    }
  } catch (err) {
    yield put(RoleAction.RoleCreateFailed(err));
  }
}

export function* UpdateRole(data) {
  let config = {
    method: "PUT",
    url: "api/v1/roles/" + data.data.id,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.Bodydata,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(RoleAction.RoleUpdateSuccess(response.data));
    } else {
      yield put(RoleAction.RoleUpdateFailed(response));
    }
  } catch (err) {
    yield put(RoleAction.RoleUpdateFailed(err));
  }
}

export function* RoleList(data) {
  const config = {
    method: "GET",
    url: "ROLE_PHASE_1",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(RoleAction.RoleListSuccess(response.data));
    } else {
      yield put(RoleAction.RoleListFailed(response));
    }
  } catch (err) {
    yield put(RoleAction.RoleListFailed(err));
  }
}

export function* roleAssignmentsSearch(data) {
  const config = {
    method: "POST",
    url: `api/v1/role_assignments/search?size=500`,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.bodyData,
    fetchPolicy: "no-cache",
  };

  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(RoleAction.userRoleSearchListSuccess(response.data));
    } else {
      yield put(RoleAction.userRoleSearchListFailed(response));
    }
  } catch (err) {
    yield put(RoleAction.userRoleSearchListFailed(err));
  }
}
