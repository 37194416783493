import * as actionTypes from "./actionTypes";

export const resetTask = (payload) => {
  return {
    type: actionTypes.RESET_TASK,
    payload,
  };
};

export const getTask = (payload) => {
  return {
    type: actionTypes.GET_TASK,
    payload,
  };
};

export const getTaskSuccess = (payload) => {
  return {
    type: actionTypes.GET_TASK_SUCCESS,
    payload,
  };
};

export const getTaskFailed = (payload) => {
  return {
    type: actionTypes.GET_TASK_FAILED,
    payload,
  };
};

export const fetchTasks = (payload) => {
  return {
    type: actionTypes.FETCH_TASKS,
    payload,
  };
};

export const fetchTasksSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_TASKS_SUCCESS,
    payload,
  };
};

export const fetchTasksFailed = (payload) => {
  return {
    type: actionTypes.FETCH_TASKS_FAILED,
    payload,
  };
};

export const createTask = (payload) => {
  return {
    type: actionTypes.CREATE_TASK,
    payload,
  };
};

export const createTaskSuccess = (payload) => {
  return {
    type: actionTypes.CREATE_TASK_SUCCESS,
    payload,
  };
};

export const createTaskFailed = (payload) => {
  return {
    type: actionTypes.CREATE_TASK_FAILED,
    payload,
  };
};

export const deleteTask = (payload) => {
  return {
    type: actionTypes.DELETE_TASK,
    payload,
  };
};

export const deleteTaskSuccess = (payload) => {
  return {
    type: actionTypes.DELETE_TASK_SUCCESS,
    payload,
  };
};

export const deleteTaskFailed = (payload) => {
  return {
    type: actionTypes.DELETE_TASK_FAILED,
    payload,
  };
};

export const updateTask = (payload) => {
  return {
    type: actionTypes.UPDATE_TASK,
    payload,
  };
};

export const updateTaskSuccess = (payload) => {
  return {
    type: actionTypes.UPDATE_TASK_SUCCESS,
    payload,
  };
};

export const updateTaskFailed = (payload) => {
  return {
    type: actionTypes.UPDATE_TASK_FAILED,
    payload,
  };
};

export const setTaskErrorMessage = (payload) => {
  return {
    type: actionTypes.SET_TASK_ERROR_MESSAGE,
    payload,
  };
};

export const setSelectedTask = (payload) => {
  return {
    type: actionTypes.SET_SELECTED_TASK,
    payload,
  };
};

export const fetchChildTasks = (payload) => {
  return {
    type: actionTypes.FETCH_CHILD_TASKS,
    payload,
  };
};

export const fetchChildTasksSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_CHILD_TASKS_SUCCESS,
    payload,
  };
};

export const fetchChildTasksFailed = (payload) => {
  return {
    type: actionTypes.FETCH_CHILD_TASKS_FAILED,
    payload,
  };
};

export const fetchTaskAttachments = (payload) => {
  return {
    type: actionTypes.FETCH_TASK_ATTACHMENTS,
    payload,
  };
};

export const fetchTaskAttachmentsSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_TASK_ATTACHMENTS_SUCCESS,
    payload,
  };
};

export const fetchTaskAttachmentsFailed = (payload) => {
  return {
    type: actionTypes.FETCH_TASK_ATTACHMENTS_FAILED,
    payload,
  };
};

export const getTaskAttachment = (payload) => {
  return {
    type: actionTypes.GET_TASK_ATTACHMENT,
    payload,
  };
};

export const getTaskAttachmentSuccess = (payload) => {
  return {
    type: actionTypes.GET_TASK_ATTACHMENT_SUCCESS,
    payload,
  };
};

export const getTaskAttachmentFailed = (payload) => {
  return {
    type: actionTypes.GET_TASK_ATTACHMENT_FAILED,
    payload,
  };
};

export const createTaskAttachment = (payload) => {
  return {
    type: actionTypes.CREATE_TASK_ATTACHMENT,
    payload,
  };
};

export const createTaskAttachmentSuccess = (payload) => {
  return {
    type: actionTypes.CREATE_TASK_ATTACHMENT_SUCCESS,
    payload,
  };
};

export const createTaskAttachmentFailed = (payload) => {
  return {
    type: actionTypes.CREATE_TASK_ATTACHMENT_FAILED,
    payload,
  };
};

export const deleteTaskAttachment = (payload) => {
  return {
    type: actionTypes.DELETE_TASK_ATTACHMENT,
    payload,
  };
};

export const deleteTaskAttachmentSuccess = (payload) => {
  return {
    type: actionTypes.DELETE_TASK_ATTACHMENT_SUCCESS,
    payload,
  };
};

export const deleteTaskAttachmentFailed = (payload) => {
  return {
    type: actionTypes.DELETE_TASK_ATTACHMENT_FAILED,
    payload,
  };
};

export const updateTaskAttachment = (payload) => {
  return {
    type: actionTypes.UPDATE_TASK_ATTACHMENT,
    payload,
  };
};

export const updateTaskAttachmentSuccess = (payload) => {
  return {
    type: actionTypes.UPDATE_TASK_ATTACHMENT_SUCCESS,
    payload,
  };
};

export const updateTaskAttachmentFailed = (payload) => {
  return {
    type: actionTypes.UPDATE_TASK_ATTACHMENT_FAILED,
    payload,
  };
};

export const setTaskFilters = (payload) => {
  return {
    type: actionTypes.SET_TASK_FILTERS,
    payload,
  };
};
