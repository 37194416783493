import * as actionTypes from "./actionTypes";

//*  PRODUCTIVITY DETAILED WORKING TIME TRENDS *//

export const ProductivityDetailedWorkingTimeTrendInitial = () => {
  return {
    type: actionTypes.PRODUCTIVITY_DETAILED_WORKING_TIME_TREND_INITIAL,
  };
};
export const ProductivityDetailedWorkingTimeTrendProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.PRODUCTIVITY_DETAILED_WORKING_TIME_TREND_PROCESS,
    token,
    bodyData,
  };
};
export const ProductivityDetailedWorkingTimeTrendSuccess = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_DETAILED_WORKING_TIME_TREND_SUCCESS,
    data,
  };
};
export const ProductivityDetailedWorkingTimeTrendFailed = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_DETAILED_WORKING_TIME_TREND_FAILED,
    data,
  };
};

//*  PRODUCTIVITY DETAILED TEAM WISE PRODUCTIVITY TRENDS *//

export const ProductivityDetailedTeamWiseProductivityTrendInitial = () => {
  return {
    type: actionTypes.PRODUCTIVITY_DETAILED_TEAM_WISE_PRODUCTIVITY_TREND_INITIAL,
  };
};
export const ProductivityDetailedTeamWisProductivityTrendProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.PRODUCTIVITY_DETAILED_TEAM_WISE_PRODUCTIVITY_TREND_PROCESS,
    token,
    bodyData,
  };
};
export const ProductivityDetaileTeamWiseProductivityTrendSuccess = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_DETAILED_TEAM_WISE_PRODUCTIVITY_TREND_SUCCESS,
    data,
  };
};
export const ProductivityDetailedTeamWiseProductivityTrendFailed = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_DETAILED_TEAM_WISE_PRODUCTIVITY_TREND_FAILED,
    data,
  };
};

//*  PRODUCTIVITY DETAILED EMPLOYEE LIST *//

export const ProductivityDetailedEmployeeListInitial = () => {
  return {
    type: actionTypes.PRODUCTIVITY_DETAILED_EMPLOYEE_LIST_INITIAL,
  };
};
export const ProductivityDetailedEmployeeListProcess = (token, bodyData) => {
  return {
    type: actionTypes.PRODUCTIVITY_DETAILED_EMPLOYEE_LIST_PROCESS,
    token,
    bodyData,
  };
};
export const ProductivityDetailedEmployeeListSuccess = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_DETAILED_EMPLOYEE_LIST_SUCCESS,
    data,
  };
};
export const ProductivityDetailedEmployeeListFailed = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_DETAILED_EMPLOYEE_LIST_FAILED,
    data,
  };
};

//*  PRODUCTIVITY EMPLPOYEE DETAILED EMPLOYEE LIST *//

export const ProductivityEmployeeDetailedEmployeeListInitial = () => {
  return {
    type: actionTypes.PRODUCTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_INITIAL,
  };
};
export const ProductivityEmployeeDetailedEmployeeListProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.PRODUCTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_PROCESS,
    token,
    bodyData,
  };
};
export const ProductivityEmployeeDetailedEmployeeListSuccess = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_SUCCESS,
    data,
  };
};
export const ProductivityEmployeeDetailedEmployeeListFailed = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_FAILED,
    data,
  };
};
