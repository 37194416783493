export const FETCH_USER_DATA = "FETCH_USER_DATA";
export const FETCH_USER_DATA_SUCCESS = "FETCH_USER_DATA_SUCCESS";
export const FETCH_USER_DATA_FAILED = "FETCH_USER_DATA_FAILED";

export const START_LIVESTREAM = "START_LIVESTREAM";
export const START_LIVESTREAM_SUCCESS = "START_LIVESTREAM_SUCCESS";
export const START_LIVESTREAM_FAILED = "START_LIVESTREAM_FAILED";

export const START_SCREENSHOT = "START_SCREENSHOT";
export const START_SCREENSHOT_SUCCESS = "START_SCREENSHOT_SUCCESS";
export const START_SCREENSHOT_FAILED = "START_SCREENSHOT_FAILED";
