import * as actionTypes from "./actionTypes";

export const getNote = (payload) => {
  return {
    type: actionTypes.GET_NOTE,
    payload,
  };
};

export const getNoteSuccess = (payload) => {
  return {
    type: actionTypes.GET_NOTE_SUCCESS,
    payload,
  };
};

export const getNoteFailed = (payload) => {
  return {
    type: actionTypes.GET_NOTE_FAILED,
    payload,
  };
};

export const fetchNotes = (payload) => {
  return {
    type: actionTypes.FETCH_NOTES,
    payload,
  };
};

export const fetchNotesSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_NOTES_SUCCESS,
    payload,
  };
};

export const fetchNotesFailed = (payload) => {
  return {
    type: actionTypes.FETCH_NOTES_FAILED,
    payload,
  };
};

export const createNote = (payload) => {
  return {
    type: actionTypes.CREATE_NOTE,
    payload,
  };
};

export const createNoteSuccess = (payload) => {
  return {
    type: actionTypes.CREATE_NOTE_SUCCESS,
    payload,
  };
};

export const createNoteFailed = (payload) => {
  return {
    type: actionTypes.CREATE_NOTE_FAILED,
    payload,
  };
};

export const deleteNote = (payload) => {
  return {
    type: actionTypes.DELETE_NOTE,
    payload,
  };
};

export const deleteNoteSuccess = (payload) => {
  return {
    type: actionTypes.DELETE_NOTE_SUCCESS,
    payload,
  };
};

export const deleteNoteFailed = (payload) => {
  return {
    type: actionTypes.DELETE_NOTE_FAILED,
    payload,
  };
};

export const updateNote = (payload) => {
  return {
    type: actionTypes.UPDATE_NOTE,
    payload,
  };
};

export const updateNoteSuccess = (payload) => {
  return {
    type: actionTypes.UPDATE_NOTE_SUCCESS,
    payload,
  };
};

export const updateNoteFailed = (payload) => {
  return {
    type: actionTypes.UPDATE_NOTE_FAILED,
    payload,
  };
};

export const setNoteErrorMessage = (payload) => {
  return {
    type: actionTypes.SET_NOTE_ERROR_MESSAGE,
    payload,
  };
};

export const setSelectedNote = (payload) => {
  return {
    type: actionTypes.SET_SELECTED_NOTE,
    payload,
  };
};
