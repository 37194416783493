//* application policy Start*/
//* LIST *//

export const APPLICATIONPOLICY_LIST_INITIAL = "APPLICATIONPOLICY_LIST_INITIAL";
export const APPLICATIONPOLICY_LIST_PROCESS = "APPLICATIONPOLICY_LIST_PROCESS";
export const APPLICATIONPOLICY_LIST_SUCCESS = "APPLICATIONPOLICY_LIST_SUCCESS";
export const APPLICATIONPOLICY_LIST_FAILED = "APPLICATIONPOLICY_LIST_FAILED";
export const APPLICATIONPOLICY_LIST_SEARCH = "APPLICATIONPOLICY_LIST_SEARCH";
//* CREATE *//

export const APPLICATIONPOLICY_CREATE_INITIAL =
  "APPLICATIONPOLICY_CREATE_INITIAL";
export const APPLICATIONPOLICY_CREATE_PROCESS =
  "APPLICATIONPOLICY_CREATE_PROCESS";
export const APPLICATIONPOLICY_CREATE_SUCCESS =
  "APPLICATIONPOLICY_CREATE_SUCCESS";
export const APPLICATIONPOLICY_CREATE_FAILED =
  "APPLICATIONPOLICY_CREATE_FAILED";

//* EDIT *//

export const APPLICATIONPOLICY_EDIT_INITIAL = "APPLICATIONPOLICY_EDIT_INITIAL";
export const APPLICATIONPOLICY_EDIT_PROCESS = "APPLICATIONPOLICY_EDIT_PROCESS";
export const APPLICATIONPOLICY_EDIT_SUCCESS = "APPLICATIONPOLICY_EDIT_SUCCESS";
export const APPLICATIONPOLICY_EDIT_FAILED = "APPLICATIONPOLICY_EDIT_FAILED";

//DELETE//

export const APPLICATIONPOLICY_DELETE_INITIAL =
  "APPLICATIONPOLICY_DELETE_INITIAL";
export const APPLICATIONPOLICY_DELETE_PROCESS =
  "APPLICATIONPOLICY_DELETE_PROCESS";
export const APPLICATIONPOLICY_DELETE_SUCCESS =
  "APPLICATIONPOLICY_DELETE_SUCCESS";
export const APPLICATIONPOLICY_DELETE_FAILED =
  "APPLICATIONPOLICY_DELETE_FAILED";

//* application policy End *//
