const URLs = {
  // Auth API's
  GET_LIST: "posts",
  ME_AVATAR_PATH: "/api/v1/me/avatar",
  ADMIN_AVATAR_PATH: "/api/v1/admin/avatar/",
  LOGO_PATH: "/api/v1/admin/logo/",
};

export const WINDOW_DOWNLOAD_URL =
  "https://storage.googleapis.com/we360-v2-installers/binaries/latest/MyZenSetup.exe";

export const HUBSPOT_CREATE_CONTACT_ENDPOINT =
  "https://hs-contact-gsme3ss35q-el.a.run.app";

export default URLs;
