//* ACTIVITY DETAILED WORKING TIME TRENDS *//

export const ACTIVITY_DETAILED_WORKING_TIME_TREND_INITIAL =
  "ACTIVITY_DETAILED_WORKING_TIME_TREND_INITIAL";
export const ACTIVITY_DETAILED_WORKING_TIME_TREND_PROCESS =
  "ACTIVITY_DETAILED_WORKING_TIME_TREND_PROCESS";
export const ACTIVITY_DETAILED_WORKING_TIME_TREND_SUCCESS =
  "ACTIVITY_DETAILED_WORKING_TIME_TREND_SUCCESS";
export const ACTIVITY_DETAILED_WORKING_TIME_TREND_FAILED =
  "ACTIVITY_DETAILED_WORKING_TIME_TREND_FAILED";

//*  ACTIVITY DETAILED WORKING TIME TRENDS End *//

//* ACTIVITY DETAILED EMPLOYEE LIST *//

export const ACTIVITY_DETAILED_EMPLOYEE_LIST_INITIAL =
  "ACTIVITY_DETAILED_EMPLOYEE_LIST_INITIAL";
export const ACTIVITY_DETAILED_EMPLOYEE_LIST_PROCESS =
  "ACTIVITY_DETAILED_EMPLOYEE_LIST_PROCESS";
export const ACTIVITY_DETAILED_EMPLOYEE_LIST_SUCCESS =
  "ACTIVITY_DETAILED_EMPLOYEE_LIST_SUCCESS";
export const ACTIVITY_DETAILED_EMPLOYEE_LIST_FAILED =
  "ACTIVITY_DETAILED_EMPLOYEE_LIST_FAILED";

//*  ACTIVITY DETAILED EMPLOYEE LIST End *//

//* ACTIVITY EMPLOYEE DETAILED EMPLOYEE LIST *//

export const ACTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_INITIAL =
  "ACTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_INITIAL";
export const ACTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_PROCESS =
  "ACTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_PROCESS";
export const ACTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_SUCCESS =
  "ACTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_SUCCESS";
export const ACTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_FAILED =
  "ACTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_FAILED";

//*  ACTIVITY EMPLOYEE DETAILED EMPLOYEE LIST End *//
