import * as actionTypes from "./actionTypes";

export const fetchSubscriptions = (payload) => {
  return {
    type: actionTypes.FETCH_SUBSCRIPTIONS,
    payload,
  };
};

export const fetchSubscriptionsSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_SUBSCRIPTIONS_SUCCESS,
    payload,
  };
};

export const fetchSubscriptionsFailed = (payload) => {
  return {
    type: actionTypes.FETCH_SUBSCRIPTIONS_FAILED,
    payload,
  };
};

export const fetchInvoices = (payload) => {
  return {
    type: actionTypes.FETCH_INVOICES,
    payload,
  };
};

export const fetchInvoicesSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_INVOICES_SUCCESS,
    payload,
  };
};

export const fetchInvoicesFailed = (payload) => {
  return {
    type: actionTypes.FETCH_INVOICES_FAILED,
    payload,
  };
};

export const setBillingErrorMessage = (payload) => {
  return {
    type: actionTypes.SET_BILLING_ERROR_MESSAGE,
    payload,
  };
};

export const onUpgradeModalOpen = () => {
  return {
    type: actionTypes.ON_UPGRADE_MODAL_OPEN,
  };
};

export const onUpgradeModalClose = () => {
  return {
    type: actionTypes.ON_UPGRADE_MODAL_CLOSE,
  };
};

export const initiateSubscription = (payload) => {
  return {
    type: actionTypes.INITIATE_SUBSCRIPTION,
    payload,
  };
};

export const initiateSubscriptionSuccess = (payload) => {
  return {
    type: actionTypes.INITIATE_SUBSCRIPTION_SUCCESS,
    payload,
  };
};

export const initiateSubscriptionFailed = (payload) => {
  return {
    type: actionTypes.INITIATE_SUBSCRIPTION_FAILED,
    payload,
  };
};

export const updateSubscription = (payload) => {
  return {
    type: actionTypes.UPDATE_SUBSCRIPTION,
    payload,
  };
};

export const updateSubscriptionSuccess = (payload) => {
  return {
    type: actionTypes.UPDATE_SUBSCRIPTION_SUCCESS,
    payload,
  };
};

export const updateSubscriptionFailed = (payload) => {
  return {
    type: actionTypes.UPDATE_SUBSCRIPTION_FAILED,
    payload,
  };
};

export const setUpdateSubscriptionSuccess = (payload) => {
  return {
    type: actionTypes.SET_UPDATE_SUBSCRIPTION_SUCCESS,
    payload,
  };
};

export const fetchPlanGroup = (payload) => {
  return {
    type: actionTypes.FETCH_PLAN_GROUP,
    payload,
  };
};

export const fetchPlanGroupSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_PLAN_GROUP_SUCCESS,
    payload,
  };
};

export const fetchPlanGroupFailed = (payload) => {
  return {
    type: actionTypes.FETCH_PLAN_GROUP_FAILED,
    payload,
  };
};

export const sendInvoiceViaEmail = (payload) => {
  return {
    type: actionTypes.SEND_INVOICE_VIA_EMAIL,
    payload,
  };
};

export const sendInvoiceViaEmailSuccess = (payload) => {
  return {
    type: actionTypes.SEND_INVOICE_VIA_EMAIL_SUCCESS,
    payload,
  };
};

export const sendInvoiceViaEmailFailed = (payload) => {
  return {
    type: actionTypes.SEND_INVOICE_VIA_EMAIL_FAILED,
    payload,
  };
};

export const setBillingOverrides = (payload) => {
  return {
    type: actionTypes.SET_BILLING_OVERRIDES,
    payload,
  };
};
