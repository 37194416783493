import { put, call } from "redux-saga/effects";
import { DesignationAction } from "../action";
import { Client } from "../../../utils/transport";

export function* CreateDesignation(data) {
  let config = {
    method: "POST",
    url: "DESGINATION_PHASE_1",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.BodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(DesignationAction.DesignationCreateSuccess(response.data));
    } else {
      yield put(DesignationAction.DesignationCreateFailed(response));
    }
  } catch (err) {
    yield put(DesignationAction.DesignationCreateFailed(err));
  }
}

export function* UpdateDesignation(data) {
  let config = {
    method: "PUT",
    // url: "BREAK_PHASE_1",
    url: "api/v1/designations/" + data.data.id,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.Bodydata,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(DesignationAction.DesignationUpdateSuccess(response.data));
    } else {
      yield put(DesignationAction.DesignationUpdateFailed(response));
    }
  } catch (err) {
    yield put(DesignationAction.DesignationUpdateFailed(err));
  }
}

export function* DesignationList(data) {
  let config = {
    method: "GET",
    url: "DESGINATION_PHASE_1",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);
    if (response.status === 200) {
      yield put(DesignationAction.DesignationListSuccess(response.data));
    } else {
      yield put(DesignationAction.DesignationListFailed(response));
    }
  } catch (err) {
    yield put(DesignationAction.DesignationListFailed(err));
  }
}
