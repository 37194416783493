import * as actionTypes from "./actionTypes";

const searchShift = (dataset, search) => {
  if (search.length > 0) {
    var searchdata = _.filter(dataset, (list) => {
      return list.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
        ? true
        : false;
    });

    return searchdata;
  } else {
    return dataset;
  }
};
const initialState = {
  //*GLOBAL*//
  //* LIST *//
  shifts_list_loading: false,
  shifts_list_success: false,
  shifts_list_failed: false,
  shifts_list_data: [],
  shiftMaster: [],
  shifts_list_message: "",

  //* CREATE *//
  shifts_create_loading: false,
  shifts_create_success: false,
  shifts_create_failed: false,
  shifts_create_data: [],
  shifts_create_message: "",

  //* EDIT *//

  shifts_edit_loading: false,
  shifts_edit_success: false,
  shifts_edit_failed: false,
  shifts_edit_data: [],
  shifts_edit_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.SHIFTS_LIST_INITIAL:
      return {
        ...state,
        shifts_list_loading: false,
        shifts_list_success: false,
        shifts_list_failed: false,
        shifts_list_data: [],
        shiftMaster: [],
        shifts_list_message: "",
      };
    case actionTypes.SHIFTS_LIST_PROCESS:
      return {
        ...state,
        shifts_list_loading: true,
        shifts_list_success: false,
        shifts_list_failed: false,
        shifts_list_data: [],
        shiftMaster: [],
        shifts_list_message: "",
      };
    case actionTypes.SHIFTS_LIST_SUCCESS:
      return {
        ...state,
        shifts_list_loading: false,
        shifts_list_success: true,
        shifts_list_failed: false,
        shifts_list_data: action.data.data,
        shiftMaster: action.data.data,
        shifts_list_message: "",
      };
    case actionTypes.SHIFTS_LIST_FAILED:
      return {
        ...state,
        shifts_list_loading: false,
        shifts_list_success: false,
        shifts_list_failed: true,
        shifts_list_data: [],
        shiftMaster: [],
        shifts_list_message: "",
      };

    case actionTypes.SHIFTS_LIST_SEARCH:
      return {
        ...state,
        shifts_list_data: searchShift(state.shiftMaster, action.data),
      };
    case actionTypes.SHIFTS_CREATE_INITIAL:
      return {
        ...state,
        shifts_create_loading: false,
        shifts_create_success: false,
        shifts_create_failed: false,
        shifts_create_data: [],
        shifts_create_message: "",
      };
    case actionTypes.SHIFTS_CREATE_PROCESS:
      return {
        ...state,
        shifts_create_loading: true,
        shifts_create_success: false,
        shifts_create_failed: false,
        shifts_create_data: [],
        shifts_create_message: "",
      };
    case actionTypes.SHIFTS_CREATE_SUCCESS:
      return {
        ...state,
        shifts_create_loading: false,
        shifts_create_success: true,
        shifts_create_failed: false,
        shifts_create_data: action.data,
        shifts_list_data: [...state.shifts_list_data, action.data],
        shiftMaster: [...state.shiftMaster, action.data],
        shifts_create_message: "",
      };

    case actionTypes.SHIFTS_CREATE_FAILED:
      return {
        ...state,
        shifts_create_loading: false,
        shifts_create_success: false,
        shifts_create_failed: true,
        shifts_create_data: "",
        shifts_create_message: action.data,
      };

    case actionTypes.SHIFTS_EDIT_INITIAL:
      return {
        ...state,
        shifts_edit_loading: false,
        shifts_edit_success: false,
        shifts_edit_failed: false,
        shifts_edit_data: [],
        shifts_edit_message: "",
      };

    case actionTypes.SHIFTS_EDIT_PROCESS:
      return {
        ...state,
        shifts_edit_loading: true,
        shifts_edit_success: false,
        shifts_edit_failed: false,
        shifts_edit_data: [],
        shifts_edit_message: "",
      };
    case actionTypes.SHIFTS_EDIT_SUCCESS:
      const fetchShiftslist = [...state.shifts_list_data];
      const findIndex = fetchShiftslist.findIndex(
        (item) => item.id == action.data.id
      );
      fetchShiftslist[findIndex] = action.data;
      return {
        ...state,
        shifts_edit_loading: false,
        shifts_edit_success: true,
        shifts_edit_failed: false,
        shifts_edit_data: action.data,
        shifts_list_data: fetchShiftslist,
        shiftMaster: fetchShiftslist,
        shifts_edit_message: "",
      };

    case actionTypes.SHIFTS_EDIT_FAILED:
      return {
        ...state,
        shifts_edit_loading: false,
        shifts_edit_success: false,
        shifts_edit_failed: true,
        shifts_edit_data: "",
        shifts_edit_message: action.data,
      };

    default:
      return state;
  }
};
