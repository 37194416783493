export const DESIGNATION_CREATE_INITIAL = "DESIGNATION_CREATE_INITIAL";
export const DESIGNATION_CREATE_PROCESS = "DESIGNATION_CREATE_PROCESS";
export const DESIGNATION_CREATE_SUCCESS = "DESIGNATION_CREATE_SUCCESS";
export const DESIGNATION_CREATE_FAILED = "DESIGNATION_CREATE_FAILED";

export const DESIGNATION_LIST_INITIAL = "DESIGNATION_LIST_INITIAL";
export const DESIGNATION_LIST_PROCESS = "DESIGNATION_LIST_PROCESS";
export const DESIGNATION_LIST_SUCCESS = "DESIGNATION_LIST_SUCCESS";
export const DESIGNATION_LIST_FAILED = "DESIGNATION_LIST_FAILED";
export const DESIGNATION_LIST_SEARCH = "DESIGNATION_LIST_SEARCH";

export const DESIGNATION_UPDATE_INITIAL = "DESIGNATION_UPDATE_INITIAL";
export const DESIGNATION_UPDATE_PROCESS = "DESIGNATION_UPDATE_PROCESS";
export const DESIGNATION_UPDATE_SUCCESS = "DESIGNATION_UPDATE_SUCCESS";
export const DESIGNATION_UPDATE_FAILED = "DESIGNATION_UPDATE_FAILED";
