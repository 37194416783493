//* REPORT MONTHLY ATTENDANCE WIDGET *//

export const REPORT_MONTHLY_ATTENDANCE_INITIAL =
  "REPORT_MONTHLY_ATTENDANCE_INITIAL";
export const REPORT_MONTHLY_ATTENDANCE_PROCESS =
  "REPORT_MONTHLY_ATTENDANCE_PROCESS";
export const REPORT_MONTHLY_ATTENDANCE_SUCCESS =
  "REPORT_MONTHLY_ATTENDANCE_SUCCESS";
export const REPORT_MONTHLY_ATTENDANCE_FAILED =
  "REPORT_MONTHLY_ATTENDANCE_FAILED";

//*  REPORT MONTHLY ATTENDANCE WIDGET END *//

//* REPORT DOWNLOAD MONTHLY ATTENDANCE WIDGET *//

export const REPORT_DOWNLOAD_MONTHLY_ATTENDANCE_INITIAL =
  "REPORT_DOWNLOAD_MONTHLY_ATTENDANCE_INITIAL";
export const REPORT_DOWNLOAD_MONTHLY_ATTENDANCE_PROCESS =
  "REPORT_DOWNLOAD_MONTHLY_ATTENDANCE_PROCESS";
export const REPORT_DOWNLOAD_MONTHLY_ATTENDANCE_SUCCESS =
  "REPORT_DOWNLOAD_MONTHLY_ATTENDANCE_SUCCESS";
export const REPORT_DOWNLOAD_MONTHLY_ATTENDANCE_FAILED =
  "REPORT_DOWNLOAD_MONTHLY_ATTENDANCE_FAILED";

//*  REPORT DOWNLOAD MONTHLY ATTENDANCE WIDGET END *//
