import React, { useEffect, useState } from "react";
import { Divider, Empty } from "antd";
import { useSelector } from "react-redux";
import { hm } from "utils/time-converter"; // Assuming you have this function to convert time

const UserApplication = () => {
  const userscorecard = useSelector(
    (
      state // @ts-ignore
    ) => state.userscorecard
  );

  const [applicationData, setApplicationData] = useState({
    most_used_category_name: "",
    most_used_category_duration: 0,
    most_visited_url_name: "",
    most_visited_url_duration: 0,
    most_used_application_name: "",
    most_used_application_duration: 0,
    most_productive_url_name: "",
    most_productive_url_duration: 0,
    most_unproductive_url_name: "",
    most_unproductive_url_duration: 0,
    most_productive_application_name: "",
    most_productive_application_duration: 0,
  });

  useEffect(() => {
    if (
      userscorecard &&
      userscorecard?.comparative_application_user_performance_success
    ) {
      const comparativeData =
        userscorecard.comparative_application_user_performance_data;
      if (Object.keys(comparativeData).length > 0) {
        setApplicationData(comparativeData);
      }
    }
  }, [userscorecard]);

  return (
    <>
      <div className="bg-white border  rounded-[5px]   overflow-hidden w-full">
        <div className="flex items-center justify-between ">
          <h3 className="font-semibold text-[16px] text-slate-500 mt-0">
            Application
          </h3>
        </div>
        {Object.keys(
          userscorecard?.comparative_application_user_performance_data
        ).length > 0 ? (
          <div>
            <div className="flex flex-row justify-between pt-4 pb-4">
              <div className="w-6/12">Most used category</div>
              <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
                {applicationData.most_used_category_name} (
                {hm(applicationData.most_used_category_duration)})
              </div>
            </div>
            <Divider />
            <div className="flex flex-row justify-between pt-4 pb-4">
              <div className="w-6/12">Most visited URL</div>
              <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
                {applicationData.most_visited_url_name} (
                {hm(applicationData.most_visited_url_duration)})
              </div>
            </div>
            <Divider />
            <div className="flex flex-row justify-between pt-4 pb-4">
              <div className="w-6/12">Most used application</div>
              <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
                {applicationData.most_used_application_name} (
                {hm(applicationData.most_used_application_duration)})
              </div>
            </div>
            <Divider />
            <div className="flex flex-row justify-between pt-4 pb-4">
              <div className="w-6/12">Most productive URL</div>
              <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
                {applicationData.most_productive_url_name} (
                {hm(applicationData.most_productive_url_duration)})
              </div>
            </div>
            <Divider />
            <div className="flex flex-row justify-between pt-4 pb-4">
              <div className="w-6/12">Most unproductive URL</div>
              <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
                {applicationData.most_unproductive_url_name} (
                {hm(applicationData.most_unproductive_url_duration)})
              </div>
            </div>
            <Divider />
            <div className="flex flex-row justify-between pt-4 pb-4">
              <div className="w-6/12">
                Most productive
                <br /> application
              </div>
              <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
                {applicationData.most_productive_application_name} (
                {hm(applicationData.most_productive_application_duration)})
              </div>
            </div>
          </div>
        ) : (
          <div style={{ height: "271px" }} className="emptyblock">
            <Empty description={false} />
          </div>
        )}
      </div>
    </>
  );
};
export default UserApplication;
