import React from "react";
import { Checkbox } from "antd";

interface Props {
  options: Array<{ label: string; value: string }>;
  defaultValue?: string[];
  onChange?: (checkedValues: string[]) => void;
}

const CheckboxComponent = ({ options, defaultValue, onChange }: Props) => (
  <Checkbox.Group
    options={options}
    defaultValue={defaultValue}
    onChange={onChange}
  />
);

export default CheckboxComponent;
