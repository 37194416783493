import * as actionTypes from "./actionTypes";
const initialPagination = { total_records: 0 };

const initialState = {
  // *GLOBAL*//

  pc_user_device_info_processing: false,
  pc_user_device_info_success: false,
  pc_user_device_info_failed: false,
  pc_user_device_info_data: [],
  pc_user_device_info_message: "",
  pagination: initialPagination,

  mobile_user_device_info_processing: false,
  mobile_user_device_info_success: false,
  mobile_user_device_info_failed: false,
  mobile_user_device_info_data: [],
  mobile_user_device_info_message: "",

  user_device_count_processing: false,
  user_device_count_success: false,
  user_device_count_failed: false,
  user_device_count_data: [],
  user_device_count_message: "",

  mobile_platform_processing: false,
  mobile_platform_success: false,
  mobile_platform_failed: false,
  mobile_platform_data: [],
  mobile_platform_message: "",

  pc_platform_processing: false,
  pc_platform_success: false,
  pc_platform_failed: false,
  pc_platform_data: [],
  pc_platform_message: "",

  pc_system_type_processing: false,
  pc_system_type_success: false,
  pc_system_type_failed: false,
  pc_system_type_data: [],
  pc_system_type_message: "",

  pc_myzen_version_processing: false,
  pc_myzen_version_success: false,
  pc_myzen_version_failed: false,
  pc_myzen_version_data: [],
  pc_myzen_version_message: "",

  pc_user_device_info_download_processing: false,
  pc_user_device_info_download_success: false,
  pc_user_device_info_download_failed: false,
  pc_user_device_info_download_data: [],
  pc_user_device_info_download_message: "",
  pagination: initialPagination,

  mobile_user_device_info_download_processing: false,
  mobile_user_device_info_download_success: false,
  mobile_user_device_info_download_failed: false,
  mobile_user_device_info_download_data: [],
  mobile_user_device_info_download_message: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.PC_USER_DEVICE_INFO_INITIAL:
      return {
        ...state,
        pc_user_device_info_processing: false,
        pc_user_device_info_success: false,
        pc_user_device_info_failed: false,
        pc_user_device_info_data: [],
        pc_user_device_info_message: "",
      };

    case actionTypes.PC_USER_DEVICE_INFO_PROCESS:
      return {
        ...state,
        pc_user_device_info_processing: true,
        pc_user_device_info_success: false,
        pc_user_device_info_failed: false,
        pc_user_device_info_data: [],
        pc_user_device_info_message: "",
      };

    case actionTypes.PC_USER_DEVICE_INFO_SUCCESS: {
      return {
        ...state,
        pc_user_device_info_processing: false,
        pc_user_device_info_success: true,
        pc_user_device_info_failed: false,
        pc_user_device_info_data: action.data.data,
        pc_user_device_info_message: "",
        pagination: action.data.optional.pagination || state.pagination,
      };
    }
    case actionTypes.PC_USER_DEVICE_INFO_FAILED:
      return {
        ...state,
        pc_user_device_info_processing: false,
        pc_user_device_info_success: false,
        pc_user_device_info_failed: true,
        pc_user_device_info_data: [],
        pc_user_device_info_message: action.data,
        pagination: initialPagination,
      };

    case actionTypes.MOBILE_USER_DEVICE_INFO_INITIAL:
      return {
        ...state,
        mobile_user_device_info_processing: false,
        mobile_user_device_info_success: false,
        mobile_user_device_info_failed: false,
        mobile_user_device_info_data: [],
        mobile_user_device_info_message: "",
      };

    case actionTypes.MOBILE_USER_DEVICE_INFO_PROCESS:
      return {
        ...state,
        mobile_user_device_info_processing: true,
        mobile_user_device_info_success: false,
        mobile_user_device_info_failed: false,
        mobile_user_device_info_data: [],
        mobile_user_device_info_message: "",
      };

    case actionTypes.MOBILE_USER_DEVICE_INFO_SUCCESS: {
      return {
        ...state,
        mobile_user_device_info_processing: false,
        mobile_user_device_info_success: true,
        mobile_user_device_info_failed: false,
        mobile_user_device_info_data: action.data.data,
        mobile_user_device_info_message: "",
        pagination: action.data.optional.pagination || state.pagination,
      };
    }
    case actionTypes.MOBILE_USER_DEVICE_INFO_FAILED:
      return {
        ...state,
        mobile_user_device_info_processing: false,
        mobile_user_device_info_success: false,
        mobile_user_device_info_failed: true,
        mobile_user_device_info_data: [],
        mobile_user_device_info_message: action.data,
        pagination: initialPagination,
      };

    case actionTypes.USER_DEVICE_COUNT_INITIAL:
      return {
        ...state,
        user_device_count_processing: false,
        user_device_count_success: false,
        user_device_count_failed: false,
        user_device_count_data: [],
        user_device_count_message: "",
      };

    case actionTypes.USER_DEVICE_COUNT_PROCESS:
      return {
        ...state,
        user_device_count_processing: true,
        user_device_count_success: false,
        user_device_count_failed: false,
        user_device_count_data: [],
        user_device_count_message: "",
      };

    case actionTypes.USER_DEVICE_COUNT_SUCCESS:
      return {
        ...state,
        user_device_count_processing: false,
        user_device_count_success: true,
        user_device_count_failed: false,
        user_device_count_data: action.data,
        user_device_count_message: "",
      };
    case actionTypes.USER_DEVICE_COUNT_FAILED:
      return {
        ...state,
        user_device_count_processing: false,
        user_device_count_success: false,
        user_device_count_failed: true,
        user_device_count_data: [],
        user_device_count_message: action.data,
      };

    case actionTypes.MOBILE_PLATFORM_INITIAL:
      return {
        ...state,
        mobile_platform_processing: false,
        mobile_platform_success: false,
        mobile_platform_failed: false,
        mobile_platform_data: [],
        mobile_platform_message: "",
      };

    case actionTypes.MOBILE_PLATFORM_PROCESS:
      return {
        ...state,
        mobile_platform_processing: true,
        mobile_platform_success: false,
        mobile_platform_failed: false,
        mobile_platform_data: [],
        mobile_platform_message: "",
      };

    case actionTypes.MOBILE_PLATFORM_SUCCESS: {
      return {
        ...state,
        mobile_platform_processing: false,
        mobile_platform_success: true,
        mobile_platform_failed: false,
        mobile_platform_data: action.data.data,
        mobile_platform_message: "",
      };
    }
    case actionTypes.MOBILE_PLATFORM_FAILED:
      return {
        ...state,
        mobile_platform_processing: false,
        mobile_platform_success: false,
        mobile_platform_failed: true,
        mobile_platform_data: [],
        mobile_platform_message: action.data,
      };

    case actionTypes.PC_PLATFORM_INITIAL:
      return {
        ...state,
        pc_platform_processing: false,
        pc_platform_success: false,
        pc_platform_failed: false,
        pc_platform_data: [],
        pc_platform_message: "",
      };

    case actionTypes.PC_PLATFORM_PROCESS:
      return {
        ...state,
        pc_platform_processing: true,
        pc_platform_success: false,
        pc_platform_failed: false,
        pc_platform_data: [],
        pc_platform_message: "",
      };

    case actionTypes.PC_PLATFORM_SUCCESS: {
      return {
        ...state,
        pc_platform_processing: false,
        pc_platform_success: true,
        pc_platform_failed: false,
        pc_platform_data: action.data.data,
        pc_platform_message: "",
      };
    }
    case actionTypes.PC_PLATFORM_FAILED:
      return {
        ...state,
        pc_platform_processing: false,
        pc_platform_success: false,
        pc_platform_failed: true,
        pc_platform_data: [],
        pc_platform_message: action.data,
      };

    case actionTypes.PC_SYSTEM_TYPE_INITIAL:
      return {
        ...state,
        pc_system_type_processing: false,
        pc_system_type_success: false,
        pc_system_type_failed: false,
        pc_system_type_data: [],
        pc_system_type_message: "",
      };

    case actionTypes.PC_SYSTEM_TYPE_PROCESS:
      return {
        ...state,
        pc_system_type_processing: true,
        pc_system_type_success: false,
        pc_system_type_failed: false,
        pc_system_type_data: [],
        pc_system_type_message: "",
      };

    case actionTypes.PC_SYSTEM_TYPE_SUCCESS: {
      return {
        ...state,
        pc_system_type_processing: false,
        pc_system_type_success: true,
        pc_system_type_failed: false,
        pc_system_type_data: action.data.data,
        pc_system_type_message: "",
      };
    }
    case actionTypes.PC_SYSTEM_TYPE_FAILED:
      return {
        ...state,
        pc_system_type_processing: false,
        pc_system_type_success: false,
        pc_system_type_failed: true,
        pc_system_type_data: [],
        pc_system_type_message: action.data,
      };

    case actionTypes.PC_MYZEN_VERSION_INITIAL:
      return {
        ...state,
        pc_myzen_version_processing: false,
        pc_myzen_version_success: false,
        pc_myzen_version_failed: false,
        pc_myzen_version_data: [],
        pc_myzen_version_message: "",
      };

    case actionTypes.PC_MYZEN_VERSION_PROCESS:
      return {
        ...state,
        pc_myzen_version_processing: true,
        pc_myzen_version_success: false,
        pc_myzen_version_failed: false,
        pc_myzen_version_data: [],
        pc_myzen_version_message: "",
      };

    case actionTypes.PC_MYZEN_VERSION_SUCCESS: {
      return {
        ...state,
        pc_myzen_version_processing: false,
        pc_myzen_version_success: true,
        pc_myzen_version_failed: false,
        pc_myzen_version_data: action.data.data,
        pc_myzen_version_message: "",
      };
    }
    case actionTypes.PC_MYZEN_VERSION_FAILED:
      return {
        ...state,
        pc_myzen_version_processing: false,
        pc_myzen_version_success: false,
        pc_myzen_version_failed: true,
        pc_myzen_version_data: [],
        pc_myzen_version_message: action.data,
      };
    case actionTypes.PC_USER_DEVICE_INFO_DOWNLOAD_INITIAL:
      return {
        ...state,
        pc_user_device_info_download_processing: false,
        pc_user_device_info_download_success: false,
        pc_user_device_info_download_failed: false,
        pc_user_device_info_download_data: [],
        pc_user_device_info_download_message: "",
      };

    case actionTypes.PC_USER_DEVICE_INFO_DOWNLOAD_PROCESS:
      return {
        ...state,
        pc_user_device_info_download_processing: true,
        pc_user_device_info_download_success: false,
        pc_user_device_info_download_failed: false,
        pc_user_device_info_download_data: [],
        pc_user_device_info_download_message: "",
      };

    case actionTypes.PC_USER_DEVICE_INFO_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        pc_user_device_info_download_processing: false,
        pc_user_device_info_download_success: true,
        pc_user_device_info_download_failed: false,
        pc_user_device_info_download_data: action.data.data,
        pc_user_device_info_download_message: "",
        pagination: action.data.optional.pagination || state.pagination,
      };
    }
    case actionTypes.PC_USER_DEVICE_INFO_DOWNLOAD_FAILED:
      return {
        ...state,
        pc_user_device_info_download_processing: false,
        pc_user_device_info_download_success: false,
        pc_user_device_info_download_failed: true,
        pc_user_device_info_download_data: [],
        pc_user_device_info_download_message: action.data,
        pagination: initialPagination,
      };

    case actionTypes.MOBILE_USER_DEVICE_INFO_DOWNLOAD_INITIAL:
      return {
        ...state,
        mobile_user_device_info_download_processing: false,
        mobile_user_device_info_download_success: false,
        mobile_user_device_info_download_failed: false,
        mobile_user_device_info_download_data: [],
        mobile_user_device_info_download_message: "",
      };

    case actionTypes.MOBILE_USER_DEVICE_INFO_DOWNLOAD_PROCESS:
      return {
        ...state,
        mobile_user_device_info_download_processing: true,
        mobile_user_device_info_download_success: false,
        mobile_user_device_info_download_failed: false,
        mobile_user_device_info_download_data: [],
        mobile_user_device_info_download_message: "",
      };

    case actionTypes.MOBILE_USER_DEVICE_INFO_DOWNLOAD_SUCCESS: {
      return {
        ...state,
        mobile_user_device_info_download_processing: false,
        mobile_user_device_info_download_success: true,
        mobile_user_device_info_download_failed: false,
        mobile_user_device_info_download_data: action.data.data,
        mobile_user_device_info_download_message: "",
        pagination: action.data.optional.pagination || state.pagination,
      };
    }
    case actionTypes.MOBILE_USER_DEVICE_INFO_DOWNLOAD_FAILED:
      return {
        ...state,
        mobile_user_device_info_download_processing: false,
        mobile_user_device_info_download_success: false,
        mobile_user_device_info_download_failed: true,
        mobile_user_device_info_download_data: [],
        mobile_user_device_info_download_message: action.data,
        pagination: initialPagination,
      };

    default:
      return state;
  }
};
