//SHIFTS STARTS
export const SHIFTS_LIST_INITIAL = "SHIFTS_LIST_INITIAL";
export const SHIFTS_LIST_PROCESS = "SHIFTS_LIST_PROCESS";
export const SHIFTS_LIST_SUCCESS = "SHIFTS_LIST_SUCCESS";
export const SHIFTS_LIST_FAILED = "SHIFTS_LIST_FAILED";
export const SHIFTS_LIST_SEARCH = "SHIFTS_LIST_SEARCH";

export const SHIFTS_CREATE_INITIAL = "SHIFTS_CREATE_INITIAL ";
export const SHIFTS_CREATE_PROCESS = "SHIFTS_CREATE_PROCESS ";
export const SHIFTS_CREATE_SUCCESS = "SHIFTS_CREATE_SUCCESS";
export const SHIFTS_CREATE_FAILED = "SHIFTS_CREATE_FAILED";

export const SHIFTS_EDIT_INITIAL = "SHIFTS_EDIT_INITIAL ";
export const SHIFTS_EDIT_PROCESS = "SHIFTS_EDIT_PROCESS ";
export const SHIFTS_EDIT_SUCCESS = "SHIFTS_EDIT_SUCCESS";
export const SHIFTS_EDIT_FAILED = "SHIFTS_EDIT_FAILED";

// SHIFTS END
