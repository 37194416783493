import * as actionTypes from "./actionTypes";

//*  Application DETAILED WORKING TIME TRENDS *//

export const ApplicationDetailedCategoryListInitial = () => {
  return {
    type: actionTypes.APPLICATION_DETAILED_CATEGORY_LIST_INITIAL,
  };
};
export const ApplicationDetailedCategoryListProcess = (token, bodyData) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_CATEGORY_LIST_PROCESS,
    token,
    bodyData,
  };
};
export const ApplicationDetailedCategoryListSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_CATEGORY_LIST_SUCCESS,
    data,
  };
};
export const ApplicationDetailedCategoryListFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_CATEGORY_LIST_FAILED,
    data,
  };
};

//*  APPLICATION DETAILED Team wise app utilization  *//

export const ApplicationDetailedTeamWiseAppUtilizationInitial = () => {
  return {
    type: actionTypes.APPLICATION_DETAILED_TEAM_WISE_APP_UTILIZATION_INITIAL,
  };
};
export const ApplicationDetailedTeamWiseAppUtilizationProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_TEAM_WISE_APP_UTILIZATION_PROCESS,
    token,
    bodyData,
  };
};
export const ApplicationDetailedTeamWiseAppUtilizationSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_TEAM_WISE_APP_UTILIZATION_SUCCESS,
    data,
  };
};
export const ApplicationDetailedTeamWiseAppUtilizationFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_TEAM_WISE_APP_UTILIZATION_FAILED,
    data,
  };
};

//*  APPLICATION DETAILED Team wise url utilization  *//

export const ApplicationDetailedTeamWiseUrlUtilizationInitial = () => {
  return {
    type: actionTypes.APPLICATION_DETAILED_TEAM_WISE_URL_UTILIZATION_INITIAL,
  };
};
export const ApplicationDetailedTeamWiseUrlUtilizationProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_TEAM_WISE_URL_UTILIZATION_PROCESS,
    token,
    bodyData,
  };
};
export const ApplicationDetailedTeamWiseUrlUtilizationSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_TEAM_WISE_URL_UTILIZATION_SUCCESS,
    data,
  };
};
export const ApplicationDetailedTeamWiseUrlUtilizationFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_TEAM_WISE_URL_UTILIZATION_FAILED,
    data,
  };
};

//*  APPLICATION DETAILED Application wise url utilization  *//

export const ApplicationDetailedApplicationWiseAppUrlUtilizationInitial =
  () => {
    return {
      type: actionTypes.APPLICATION_DETAILED_APPLICATION_WISE_APP_URL_UTILIZATION_INITIAL,
    };
  };
export const ApplicationDetailedApplicationWiseAppUrlUtilizationProcess = (
  data
) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_APPLICATION_WISE_APP_URL_UTILIZATION_PROCESS,
    data,
  };
};
export const ApplicationDetailedApplicationWiseAppUrlUtilizationSuccess = (
  data
) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_APPLICATION_WISE_APP_URL_UTILIZATION_SUCCESS,
    data,
  };
};
export const ApplicationDetailedApplicationWiseAppUrlUtilizationFailed = (
  data
) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_APPLICATION_WISE_APP_URL_UTILIZATION_FAILED,
    data,
  };
};

//*  APPLICATION DETAILED Search wise app detailed  *//

export const ApplicationDetailedSearchWiseAppDetailInitial = () => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_APP_DETAIL_INITIAL,
  };
};
export const ApplicationDetailedSearchWiseAppDetailProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_APP_DETAIL_PROCESS,
    token,
    bodyData,
  };
};
export const ApplicationDetailedSearchWiseAppDetailSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_APP_DETAIL_SUCCESS,
    data,
  };
};
export const ApplicationDetailedSearchWiseAppDetailFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_APP_DETAIL_FAILED,
    data,
  };
};

//*  APPLICATION DETAILED Search wise url detailed  *//

export const ApplicationDetailedSearchWiseUrlDetailInitial = () => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_URL_DETAIL_INITIAL,
  };
};
export const ApplicationDetailedSearchWiseUrlDetailProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_URL_DETAIL_PROCESS,
    token,
    bodyData,
  };
};
export const ApplicationDetailedSearchWiseUrlDetailSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_URL_DETAIL_SUCCESS,
    data,
  };
};
export const ApplicationDetailedSearchWiseUrlDetailFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_URL_DETAIL_FAILED,
    data,
  };
};

//*  APPLICATION DETAILED Search wise user   URL detailed *//

export const ApplicationDetailedSearchWiseUserUrlDetailInitial = () => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_USER_URL_DETAIL_INITIAL,
  };
};
export const ApplicationDetailedSearchWiseUserUrlDetailProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_USER_URL_DETAIL_PROCESS,
    token,
    bodyData,
  };
};
export const ApplicationDetailedSearchWiseUserUrlDetailSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_USER_URL_DETAIL_SUCCESS,
    data,
  };
};
export const ApplicationDetailedSearchWiseUserUrlDetailFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_USER_URL_DETAIL_FAILED,
    data,
  };
};

//*  APPLICATION DETAILED Search wise user App detailed *//

export const ApplicationDetailedSearchWiseUserAppDetailInitial = () => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_USER_APP_DETAIL_INITIAL,
  };
};
export const ApplicationDetailedSearchWiseUserAppDetailProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_USER_APP_DETAIL_PROCESS,
    token,
    bodyData,
  };
};
export const ApplicationDetailedSearchWiseUserAppDetailSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_USER_APP_DETAIL_SUCCESS,
    data,
  };
};
export const ApplicationDetailedSearchWiseUserAppDetailFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_USER_APP_DETAIL_FAILED,
    data,
  };
};

//*  APPLICATION DETAILED Search wise graph URL detailed *//

export const ApplicationDetailedSearchWiseGraphUrlDetailInitial = () => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_GRAPH_URL_DETAIL_INITIAL,
  };
};
export const ApplicationDetailedSearchWiseGraphUrlDetailProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_GRAPH_URL_DETAIL_PROCESS,
    token,
    bodyData,
  };
};
export const ApplicationDetailedSearchWiseGraphUrlDetailSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_GRAPH_URL_DETAIL_SUCCESS,
    data,
  };
};
export const ApplicationDetailedSearchWiseGraphUrlDetailFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_GRAPH_URL_DETAIL_FAILED,
    data,
  };
};

//*  APPLICATION DETAILED Search wise GRAPH App detailed *//

export const ApplicationDetailedSearchWiseGraphAppDetailInitial = () => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_GRAPH_APP_DETAIL_INITIAL,
  };
};
export const ApplicationDetailedSearchWiseGraphAppDetailProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_GRAPH_APP_DETAIL_PROCESS,
    token,
    bodyData,
  };
};
export const ApplicationDetailedSearchWiseGraphAppDetailSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_GRAPH_APP_DETAIL_SUCCESS,
    data,
  };
};
export const ApplicationDetailedSearchWiseGraphAppDetailFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_GRAPH_APP_DETAIL_FAILED,
    data,
  };
};

//*  APPLICATION DETAILED Search wise Title URL detailed *//

export const ApplicationDetailedSearchWiseTitleUrlDetailInitial = () => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_TITLE_URL_DETAIL_INITIAL,
  };
};
export const ApplicationDetailedSearchWiseTitleUrlDetailProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_TITLE_URL_DETAIL_PROCESS,
    token,
    bodyData,
  };
};
export const ApplicationDetailedSearchWiseTitleUrlDetailSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_TITLE_URL_DETAIL_SUCCESS,
    data,
  };
};
export const ApplicationDetailedSearchWiseTitleUrlDetailFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_TITLE_URL_DETAIL_FAILED,
    data,
  };
};

//*  APPLICATION DETAILED Search wise TITLE App detailed *//

export const ApplicationDetailedSearchWiseTitleAppDetailInitial = () => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_TITLE_APP_DETAIL_INITIAL,
  };
};
export const ApplicationDetailedSearchWiseTitleAppDetailProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_TITLE_APP_DETAIL_PROCESS,
    token,
    bodyData,
  };
};
export const ApplicationDetailedSearchWiseTitleAppDetailSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_TITLE_APP_DETAIL_SUCCESS,
    data,
  };
};
export const ApplicationDetailedSearchWiseTitleAppDetailFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_TITLE_APP_DETAIL_FAILED,
    data,
  };
};

export const ApplicationDetailedTotalApplicationUsageInitial = () => {
  return {
    type: actionTypes.APPLICATION_DETAILED_TOTAL_APPLICATION_USAGE_INITIAL,
  };
};
export const ApplicationDetailedTotalApplicationUsageProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_TOTAL_APPLICATION_USAGE_PROCESS,
    token,
    bodyData,
  };
};
export const ApplicationDetailedTotalApplicationUsageSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_TOTAL_APPLICATION_USAGE_SUCCESS,
    data,
  };
};
export const ApplicationDetailedTotalApplicationUsageFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_TOTAL_APPLICATION_USAGE_FAILED,
    data,
  };
};

//*  APPLICATION DETAILED APPLICATION Level Break down *//

export const ApplicationDetailedTotalUrlUsageInitial = () => {
  return {
    type: actionTypes.APPLICATION_DETAILED_TOTAL_URL_USAGE_INITIAL,
  };
};
export const ApplicationDetailedTotalUrlUsageProcess = (token, bodyData) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_TOTAL_URL_USAGE_PROCESS,
    token,
    bodyData,
  };
};
export const ApplicationDetailedTotalUrlUsageSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_TOTAL_URL_USAGE_SUCCESS,
    data,
  };
};
export const ApplicationDetailedTotalUrlUsageFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_TOTAL_URL_USAGE_FAILED,
    data,
  };
};

export const ApplicationDetailedAppSearch = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_APP_SEARCH,
    data,
  };
};

export const ApplicationDetailedUrlSearch = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_URL_SEARCH,
    data,
  };
};

export const ApplicationDetailedCategoryListSearch = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_CATEGORY_LIST_SEARCH,
    data,
  };
};

//*  APPLICATION DETAILED Search wise APP URL detailed *//

export const ApplicationDetailedSearchWiseAppUrlDetailInitial = () => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_APP_URL_DETAIL_INITIAL,
  };
};
export const ApplicationDetailedSearchWiseAppUrlDetailProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_APP_URL_DETAIL_PROCESS,
    token,
    bodyData,
  };
};
export const ApplicationDetailedSearchWiseAppUrlDetailSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_APP_URL_DETAIL_SUCCESS,
    data,
  };
};
export const ApplicationDetailedSearchWiseAppUrlDetailFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_DETAILED_SEARCH_WISE_APP_URL_DETAIL_FAILED,
    data,
  };
};
