import React from "react";

export const Alerts = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        d="M17.7,11.5c-0.8-1.2-1.3-2.6-1.4-4l-0.1-0.9c-0.2-1.5-0.9-3-2-4C13,1.6,11.5,1,10,1S7,1.6,5.8,2.6
	c-1.1,1-1.9,2.5-2,4L3.7,7.5c-0.1,1.4-0.6,2.8-1.4,4c-0.3,0.4-0.4,0.9-0.5,1.4c0,0.5,0.1,1,0.3,1.4c0.2,0.4,0.6,0.8,1,1
	c0.4,0.3,0.9,0.4,1.4,0.4h11c0.5,0,1-0.1,1.4-0.4c0.4-0.3,0.8-0.6,1-1c0.2-0.4,0.3-0.9,0.3-1.4C18.2,12.4,18,11.9,17.7,11.5z"
      />
      <path d="M7.2,17.4c0.3,0.5,0.7,0.9,1.2,1.2C8.9,18.8,9.4,19,10,19c0.6,0,1.1-0.2,1.6-0.4c0.5-0.3,0.9-0.7,1.2-1.2H7.2z" />
    </svg>
  );
};
