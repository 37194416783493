import * as actionTypes from "./actionTypes";

const initialPagination = { totalCount: 0 };

const initialState = {
  fetchTaskActivitiesLoading: true,
  taskActivitiesData: {},
  pagination: initialPagination,
  taskActivityLoading: false,
  taskActivitySuccess: false,
  deleteTaskActivitySuccess: false,
  deleteTaskActivityLoading: false,
  taskActivityErrorMessage: "",
  activeTimerActivityData: null,
  fetchActiveTimerActivityLoading: false,
  fetchActiveTimerActivitySuccess: false,
  activeTimerActivityLoading: false,
  activeTimerActivitySuccess: false,
  selectedTaskDrawer: null,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_TASK_ACTIVITIES: {
      if (action.payload.isTimer) {
        return {
          ...state,
          fetchActiveTimerActivityLoading: true,
          fetchActiveTimerActivitySuccess: false,
          taskActivityErrorMessage: "",
        };
      }
      return {
        ...state,
        fetchTaskActivitiesLoading: true,
        taskActivityErrorMessage: "",
      };
    }
    case actionTypes.FETCH_TASK_ACTIVITIES_SUCCESS:
      return {
        ...state,
        fetchTaskActivitiesLoading: false,
        taskActivitiesData: action.payload.data.reduce(
          (acc, taskActivity) => ({ ...acc, [taskActivity.id]: taskActivity }),
          {}
        ),
        pagination: action.payload.meta || state.pagination,
      };

    case actionTypes.FETCH_TASK_ACTIVITIES_FAILED:
      return {
        ...state,
        fetchActiveTimerActivityLoading: false,
        fetchTaskActivitiesLoading: false,
        taskActivitiesData: {},
        pagination: initialPagination,
        taskActivityErrorMessage: action.payload,
      };

    case actionTypes.CREATE_TASK_ACTIVITY: {
      if (action.payload.isTimer) {
        return {
          ...state,
          activeTimerActivityLoading: true,
          activeTimerActivitySuccess: false,
          taskActivityErrorMessage: "",
        };
      }

      return {
        ...state,
        taskActivityLoading: true,
        taskActivityErrorMessage: "",
        taskActivitySuccess: false,
      };
    }

    case actionTypes.CREATE_TASK_ACTIVITY_SUCCESS:
      return {
        ...state,
        taskActivityLoading: false,
        taskActivitiesData: {
          ...state.taskActivitiesData,
          [action.payload.id]: action.payload,
        },
        pagination: {
          ...state.pagination,
          totalCount: state.pagination.totalCount + 1,
        },
        taskActivitySuccess: true,
      };

    case actionTypes.CREATE_TASK_ACTIVITY_FAILED:
      return {
        ...state,
        activeTimerActivityLoading: false,
        taskActivityLoading: false,
        taskActivityErrorMessage: action.payload,
      };

    case actionTypes.UPDATE_TASK_ACTIVITY: {
      if (action.payload.isTimer) {
        return {
          ...state,
          activeTimerActivityLoading: true,
          activeTimerActivitySuccess: false,
          taskActivityErrorMessage: "",
        };
      }

      return {
        ...state,
        taskActivityLoading: true,
        taskActivityErrorMessage: "",
        taskActivitySuccess: false,
      };
    }

    case actionTypes.UPDATE_TASK_ACTIVITY_SUCCESS:
      return {
        ...state,
        taskActivityLoading: false,
        taskActivitiesData: {
          ...state.taskActivitiesData,
          [action.payload.id]: action.payload,
        },
        taskActivitySuccess: true,
      };

    case actionTypes.UPDATE_TASK_ACTIVITY_FAILED:
      return {
        ...state,
        activeTimerActivityLoading: false,
        taskActivityLoading: false,
        taskActivityErrorMessage: action.payload,
      };

    case actionTypes.DELETE_TASK_ACTIVITY:
      return {
        ...state,
        deleteTaskActivityLoading: true,
        taskActivityErrorMessage: "",
        deleteTaskActivitySuccess: false,
      };

    case actionTypes.DELETE_TASK_ACTIVITY_SUCCESS:
      return {
        ...state,
        deleteTaskActivityLoading: false,
        deleteTaskActivitySuccess: true,
      };

    case actionTypes.DELETE_TASK_ACTIVITY_FAILED:
      return {
        ...state,
        deleteTaskActivityLoading: false,
        taskActivityErrorMessage: action.payload,
      };

    case actionTypes.SET_TASK_ACTIVITY_ERROR_MESSAGE:
      return {
        ...state,
        taskActivityErrorMessage: action.payload,
      };

    case actionTypes.FETCH_ACTIVE_TIMER_ACTIVITY_SUCCESS:
      return {
        ...state,
        fetchActiveTimerActivityLoading: false,
        activeTimerActivityData: action.payload,
        fetchActiveTimerActivitySuccess: true,
      };

    case actionTypes.CREATE_ACTIVE_TIMER_ACTIVITY_SUCCESS:
      return {
        ...state,
        activeTimerActivityLoading: false,
        activeTimerActivityData: action.payload,
        activeTimerActivitySuccess: true,
      };

    case actionTypes.UPDATE_ACTIVE_TIMER_ACTIVITY_SUCCESS: {
      if (
        state.selectedTaskDrawer &&
        action.payload?.task?.id === state.selectedTaskDrawer?.id
      ) {
        return {
          ...state,
          activeTimerActivityLoading: false,
          activeTimerActivityData: null,
          taskActivitiesData: {
            [action.payload.id]: action.payload,
            ...state.taskActivitiesData,
          },
          activeTimerActivitySuccess: true,
        };
      }
      return {
        ...state,
        activeTimerActivityLoading: false,
        activeTimerActivityData: null,
        activeTimerActivitySuccess: true,
      };
    }

    case actionTypes.SET_SELECTED_TASK_DRAWER:
      return {
        ...state,
        selectedTaskDrawer: action.payload,
      };

    // case actionTypes.FETCH_ACTIVE_TIMER_ACTIVITY:
    //   return {
    //     ...state,
    //     fetchActiveTimerActivityLoading: true,
    //     activeTimerActivityErrorMessage: "",
    //     fetchActiveTimerActivitySuccess: false,
    //   };

    // case actionTypes.FETCH_ACTIVE_TIMER_ACTIVITY_FAILED:
    //   return {
    //     ...state,
    //     fetchActiveTimerActivityLoading: false,
    //     activeTimerActivityErrorMessage: action.payload,
    //   };

    // case actionTypes.SET_ACTIVE_TIMER_ACTIVITY_ERROR_MESSAGE:
    //   return {
    //     ...state,
    //     activeTimerActivityErrorMessage: action.payload,
    //   };

    default:
      return state;
  }
};
