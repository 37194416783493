import { put } from "redux-saga/effects";
import { UserScorecardAction } from "../action";
import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";

export function* getComparativeAnalysisUserPerformanceInfo(action) {
  const config = {
    method: "POST",
    url: `query/performance/scorecard_comparative_analysis`,

    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);
    yield put(
      UserScorecardAction.comparativeAnalysisUserPerformanceSuccess(
        response.data
      )
    );
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(
      UserScorecardAction.comparativeAnalysisUserPerformanceFailed(errorMessage)
    );
  }
}

export function* getComparativeAttendanceUserPerformanceInfo(action) {
  const config = {
    method: "POST",
    url: `query/performance/scorecard_attendance_analysis`,

    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);
    yield put(
      UserScorecardAction.comparativeAttendanceUserPerformanceSuccess(
        response.data
      )
    );
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(
      UserScorecardAction.comparativeAttendanceUserPerformanceFailed(
        errorMessage
      )
    );
  }
}

export function* getComparativeGoalUserPerformanceInfo(action) {
  const config = {
    method: "POST",
    url: `query/performance/scorecard_goals_analysis`,

    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);
    yield put(
      UserScorecardAction.comparativeGoalUserPerformanceSuccess(response.data)
    );
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(
      UserScorecardAction.comparativeGoalUserPerformanceFailed(errorMessage)
    );
  }
}

export function* getComparativeApplicationUserPerformanceInfo(action) {
  const config = {
    method: "POST",
    url: `query/performance/scorecard_applications_analysis`,

    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);
    yield put(
      UserScorecardAction.comparativeApplicationUserPerformanceSuccess(
        response.data
      )
    );
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(
      UserScorecardAction.comparativeApplicationUserPerformanceFailed(
        errorMessage
      )
    );
  }
}
