import React from "react";

export const ManualTime = ({ viewBox = "0 0 20 20" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      fill="currentColor"
    >
      <path
        d="M5.8,3C5,3,4.3,3.3,3.8,3.8C3.3,4.3,3,5,3,5.8v8.5c0,0.7,0.3,1.4,0.8,1.9C4.3,16.7,5,17,5.8,17h3.9
	C9.2,16.2,9,15.4,9,14.5c0-1.3,0.5-2.6,1.3-3.5H9.5c-0.1,0-0.3-0.1-0.4-0.1C9.1,10.8,9,10.6,9,10.5s0.1-0.3,0.1-0.4
	C9.2,10.1,9.4,10,9.5,10h1.8c0.9-0.6,2-1,3.2-1c0.9,0,1.8,0.2,2.5,0.6V5.8c0-0.7-0.3-1.4-0.8-1.9C15.7,3.3,15,3,14.2,3H5.8z
	 M7.5,7.2c0,0.2-0.1,0.4-0.2,0.5C7.1,7.9,6.9,8,6.8,8S6.4,7.9,6.2,7.8C6.1,7.6,6,7.4,6,7.2s0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2
	s0.4,0.1,0.5,0.2C7.4,6.9,7.5,7.1,7.5,7.2z M6.8,9.5c0.2,0,0.4,0.1,0.5,0.2c0.1,0.1,0.2,0.3,0.2,0.5s-0.1,0.4-0.2,0.5
	C7.1,10.9,6.9,11,6.8,11s-0.4-0.1-0.5-0.2C6.1,10.6,6,10.4,6,10.2s0.1-0.4,0.2-0.5C6.4,9.6,6.6,9.5,6.8,9.5z M7.5,13.2
	c0,0.2-0.1,0.4-0.2,0.5C7.1,13.9,6.9,14,6.8,14s-0.4-0.1-0.5-0.2C6.1,13.6,6,13.4,6,13.2s0.1-0.4,0.2-0.5c0.1-0.1,0.3-0.2,0.5-0.2
	s0.4,0.1,0.5,0.2C7.4,12.9,7.5,13.1,7.5,13.2z M9.5,8C9.4,8,9.2,7.9,9.1,7.9C9.1,7.8,9,7.6,9,7.5s0.1-0.3,0.1-0.4
	C9.2,7.1,9.4,7,9.5,7h4c0.1,0,0.3,0.1,0.4,0.1C13.9,7.2,14,7.4,14,7.5s-0.1,0.3-0.1,0.4C13.8,7.9,13.6,8,13.5,8H9.5z M14.5,19
	c1.2,0,2.3-0.5,3.2-1.3c0.8-0.8,1.3-2,1.3-3.2s-0.5-2.3-1.3-3.2c-0.8-0.8-2-1.3-3.2-1.3s-2.3,0.5-3.2,1.3s-1.3,2-1.3,3.2
	s0.5,2.3,1.3,3.2C12.2,18.5,13.3,19,14.5,19z M14,12.5c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.2-0.1,0.4-0.1s0.3,0.1,0.4,0.1
	c0.1,0.1,0.1,0.2,0.1,0.4V14h1c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.1,0.2,0.1,0.4s-0.1,0.3-0.1,0.4C16.3,14.9,16.1,15,16,15h-1.5
	c-0.1,0-0.3-0.1-0.4-0.1c-0.1-0.1-0.1-0.2-0.1-0.4V12.5z"
      />
    </svg>
  );
};
