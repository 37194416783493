import React from "react";

import { Select, SelectProps } from "antd";

type CompoundedComponent = React.FC<SelectProps> & {
  Option: typeof Select.Option;
};
// The select of the native pagination component cannot automatically change the position in this and is always in the upper right corner
const SelectTopRight: CompoundedComponent = (props) => (
  <Select {...props} placement="topRight" />
);
SelectTopRight.Option = Select.Option;

export { SelectTopRight };
