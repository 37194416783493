import * as actionType from "./actionType";
import _ from "lodash";

const initialState = {
  //*GLOBAL*//

  //* REPORTS DAILY ATTENDANCE *//

  reports_daily_attendance_processing: false,
  reports_daily_attendance_success: false,
  reports_daily_attendance_failed: false,
  reports_daily_attendance_data: [],
  reports_daily_attendance_message: "",

  //* REPORTS DOWNLOAD DAILY ATTENDANCE *//

  reports_download_daily_attendance_processing: false,
  reports_download_daily_attendance_success: false,
  reports_download_daily_attendance_failed: false,
  reports_download_daily_attendance_data: [],
  reports_download_daily_attendance_message: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionType.REPORTS_DAILY_ATTENDANCE_INITIAL:
      return {
        ...state,
        reports_daily_attendance_processing: false,
        reports_daily_attendance_success: false,
        reports_daily_attendance_failed: false,
        reports_daily_attendance_data: [],
        reports_daily_attendance_message: "",
      };

    case actionType.REPORTS_DAILY_ATTENDANCE_PROCESS:
      return {
        ...state,
        reports_daily_attendance_processing: true,
        reports_daily_attendance_success: false,
        reports_daily_attendance_failed: false,
        reports_daily_attendance_data: [],
        reports_daily_attendance_message: "",
      };

    case actionType.REPORTS_DAILY_ATTENDANCE_SUCCESS: {
      return {
        ...state,
        reports_daily_attendance_processing: false,
        reports_daily_attendance_success: true,
        reports_daily_attendance_failed: false,
        reports_daily_attendance_data: action.data,
        reports_daily_attendance_message: "",
      };
    }

    case actionType.REPORTS_DAILY_ATTENDANCE_FAILED:
      return {
        ...state,
        reports_daily_attendance_processing: false,
        reports_daily_attendance_success: false,
        reports_daily_attendance_failed: true,
        reports_daily_attendance_data: action.data,
        reports_daily_attendance_message: "",
      };

    case actionType.REPORTS_DOWNLOAD_DAILY_ATTENDANCE_INITIAL:
      return {
        ...state,
        reports_download_daily_attendance_processing: false,
        reports_download_daily_attendance_success: false,
        reports_download_daily_attendance_failed: false,
        reports_download_daily_attendance_data: [],
        reports_download_daily_attendance_message: "",
      };

    case actionType.REPORTS_DOWNLOAD_DAILY_ATTENDANCE_PROCESS:
      return {
        ...state,
        reports_download_daily_attendance_processing: true,
        reports_download_daily_attendance_success: false,
        reports_download_daily_attendance_failed: false,
        reports_download_daily_attendance_data: [],
        reports_download_daily_attendance_message: "",
      };

    case actionType.REPORTS_DOWNLOAD_DAILY_ATTENDANCE_SUCCESS: {
      return {
        ...state,
        reports_download_daily_attendance_processing: false,
        reports_download_daily_attendance_success: true,
        reports_download_daily_attendance_failed: false,
        reports_download_daily_attendance_data: action.data,
        reports_download_daily_attendance_message: "",
      };
    }

    case actionType.REPORTS_DOWNLOAD_DAILY_ATTENDANCE_FAILED:
      return {
        ...state,
        reports_download_daily_attendance_processing: false,
        reports_download_daily_attendance_success: false,
        reports_download_daily_attendance_failed: true,
        reports_download_daily_attendance_data: action.data,
        reports_download_daily_attendance_message: "",
      };
    default:
      return state;
  }
};
