import React, { useEffect, useState } from "react";
import UserScoreMonthlyGoalTrend from "./UserScoreMonthlyGoalTrend";
import { Divider } from "antd";
import { useSelector } from "react-redux";

const UserMonthlyGoal = (props) => {
  const { month } = props;
  const userscorecard = useSelector(
    (
      state // @ts-ignore
    ) => state.userscorecard
  );

  const [goalData, setGoalData] = useState({
    countAStatus: 0,
    countMStatus: 0,
  });

  useEffect(() => {
    if (
      userscorecard &&
      userscorecard?.comparative_goal_user_performance_success
    ) {
      const comparativeData =
        userscorecard.comparative_goal_user_performance_data;

      const countAStatus = comparativeData.filter(
        (record) => record.goals_percentage > 100
      ).length;
      const countMStatus = comparativeData.filter(
        (record) => record.goals_percentage < 100
      ).length;

      setGoalData({
        countAStatus,
        countMStatus,
      });
    }
  }, [userscorecard]);

  const { countAStatus, countMStatus } = goalData;

  return (
    <>
      <div className="bg-white border rounded-[5px] p-4 overflow-hidden">
        <div className="flex items-center justify-between ">
          <h3 className="font-semibold text-[16px] text-slate-500 mt-0">
            Goal
          </h3>
        </div>
        <div className="w-full bg-neutral-200">
          <div
            className={`${
              countAStatus >= 0 && countAStatus <= 50
                ? "bg-[#14B8A6]"
                : countAStatus > 50 && countAStatus <= 75
                ? "bg-[#14B8A6]"
                : countAStatus > 75
                ? "bg-[#14B8A6]"
                : "bg-white-100"
            } p-0.5 h-[4px] text-center text-xs font-medium leading-none text-slate-100`}
            style={{ width: countAStatus + `%` }}
          ></div>
        </div>
        <div className="flex items-center justify-between pt-4 pb-4">
          <div className="font-extrabold text-[20px] text-[#000]">
            {countAStatus}/
            {userscorecard?.comparative_goal_user_performance_data.length}
          </div>
        </div>
        <UserScoreMonthlyGoalTrend month={month} />
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">No. of days goals achieved</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {countAStatus} days
          </div>
        </div>
        <Divider />
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">No. of days goals missed</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {countMStatus} days
          </div>
        </div>
      </div>
    </>
  );
};

export default UserMonthlyGoal;
