import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { teamName } from "./teamName";
import dayjs from "dayjs";
const convertImageToBase64 = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = url;

    img.onload = () => {
      try {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      } catch (error) {
        reject(new Error(`Canvas conversion failed: ${error.message}`));
      }
    };

    img.onerror = (error) => {
      reject(new Error(`Image load error: ${error.message}`));
    };
  });
};
const handleDownloadPdf = async (
  teamIds,
  teamList,
  element,
  startDate,
  endDate,
  fileName
) => {
  let selectTeamNames = "";
  let dateFilterText = "";
  if (teamIds?.length > 0) {
    selectTeamNames = teamName(teamIds, teamList);
  } else {
    selectTeamNames = "All Teams";
  }

  const images = element.querySelectorAll("img");
  for (const img of images) {
    if (img.src && img.src.startsWith("http")) {
      try {
        const base64 = await convertImageToBase64(img.src);
        img.src = base64;
      } catch (error) {
        console.error("Error converting image to base64:", error);
      }
    }
  }

  const canvas = await html2canvas(element, { useCORS: true });
  // const element = printRef.current;
  // const canvas = await html2canvas(element);
  const data = canvas.toDataURL("image/png");

  const pdf = new jsPDF({
    orientation: "p",
    unit: "in",
    format: [11, 45],
    compressPdf: true,
  });
  // const pdf = new jsPDF("p", "in", "a4");

  const imgProperties = pdf.getImageProperties(data);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

  const marginLeft = 0.5; // Left margin in inches
  const marginRight = 0.5; // Right margin in inches
  const contentWidth = pdfWidth - marginLeft - marginRight; // Width for the content

  pdf.addImage(data, "PNG", marginLeft, 1.4, contentWidth, pdfHeight);
  // Add the additional content to the PDF
  pdf.setFontSize(16);
  pdf.text(fileName, 5, 0.5);

  pdf.setFontSize(12);
  if (fileName === "Wellness Summary") {
    dateFilterText = `Date Filter: ${dayjs(startDate).format("YYYY-MM-DD")} `;
  } else if (fileName === "Task detail") {
    dateFilterText = "";
  } else {
    dateFilterText = `Date Filter: ${dayjs(startDate).format(
      "YYYY-MM-DD"
    )} to ${dayjs(endDate).format("YYYY-MM-DD")}`;
  }

  pdf.text(dateFilterText, 0.5, 0.8);

  const teamFilterText =
    fileName === "Task detail" ? "" : `Team Filter: ${selectTeamNames}`;
  if (selectTeamNames?.length > 20) {
    pdf.setFontSize(8);
  } else {
    pdf.setFontSize(12);
  }

  pdf.text(teamFilterText, 0.5, 1.1);
  var date = fileName + "_" + dayjs().format("YYYY-MM-DD");
  pdf.output("datauri");
  pdf.save(date);
};

export { handleDownloadPdf };
