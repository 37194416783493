import React from "react";

export const Dashboard = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M7.6,3.5v4c0,0.1-0.1,0.1-0.1,0.1H3.6c-0.1,0-0.1-0.1-0.1-0.1V3.6c0-0.1,0.1-0.1,0.1-0.1H7.6" />
      <path d="M9.1,2h-7C2.1,2,2,2.1,2,2.1V9c0,0.1,0.1,0.1,0.1,0.1H9c0.1,0,0.1-0.1,0.1-0.1V2L9.1,2z" />
      <path d="M16.5,3.5v4c0,0.1-0.1,0.1-0.1,0.1h-3.9c-0.1,0-0.1-0.1-0.1-0.1V3.6c0-0.1,0.1-0.1,0.1-0.1H16.5" />
      <path d="M18,2h-7c-0.1,0-0.1,0.1-0.1,0.1V9c0,0.1,0.1,0.1,0.1,0.1h6.9C17.9,9.1,18,9.1,18,9V2L18,2z" />
      <path d="M7.6,12.4v4c0,0.1-0.1,0.1-0.1,0.1H3.6c-0.1,0-0.1-0.1-0.1-0.1v-3.9c0-0.1,0.1-0.1,0.1-0.1H7.6" />
      <path d="M9.1,10.9h-7C2.1,10.9,2,10.9,2,11v6.9C2,17.9,2.1,18,2.1,18H9c0.1,0,0.1-0.1,0.1-0.1V10.9L9.1,10.9z" />

      <path
        d="M15.2,10.9h-1.5c-0.1,0-0.1,0.1-0.1,0.1v2.4c0,0.1-0.1,0.1-0.1,0.1H11c-0.1,0-0.1,0.1-0.1,0.1v1.5c0,0.1,0.1,0.1,0.1,0.1
		h2.4c0.1,0,0.1,0.1,0.1,0.1v2.4c0,0.1,0.1,0.1,0.1,0.1h1.5c0.1,0,0.1-0.1,0.1-0.1v-2.4c0-0.1,0.1-0.1,0.1-0.1h2.4
		c0.1,0,0.1-0.1,0.1-0.1v-1.5c0-0.1-0.1-0.1-0.1-0.1h-2.4c-0.1,0-0.1-0.1-0.1-0.1V11C15.3,10.9,15.3,10.9,15.2,10.9z"
      />
    </svg>
  );
};
