//* ATTENDANCE DETAILED ATTENDANCE TRENDS *//

export const ATTENDANCE_DETAILED_ATTENDANCE_TREND_INITIAL =
  "ATTENDANCE_DETAILED_ATTENDANCE_TREND_INITIAL";
export const ATTENDANCE_DETAILED_ATTENDANCE_TREND_PROCESS =
  "ATTENDANCE_DETAILED_ATTENDANCE_TREND_PROCESS";
export const ATTENDANCE_DETAILED_ATTENDANCE_TREND_SUCCESS =
  "ATTENDANCE_DETAILED_ATTENDANCE_TREND_SUCCESS";
export const ATTENDANCE_DETAILED_ATTENDANCE_TREND_FAILED =
  "ATTENDANCE_DETAILED_ATTENDANCE_TREND_FAILED";

//*  ATTENDANCE DETAILED ATTENDANCE TRENDS End *//

//* ATTENDANCE DETAILED EMPLOYEE LIST *//

export const ATTENDANCE_DETAILED_EMPLOYEE_LIST_INITIAL =
  "ATTENDANCE_DETAILED_EMPLOYEE_LIST_INITIAL";
export const ATTENDANCE_DETAILED_EMPLOYEE_LIST_PROCESS =
  "ATTENDANCE_DETAILED_EMPLOYEE_LIST_PROCESS";
export const ATTENDANCE_DETAILED_EMPLOYEE_LIST_SUCCESS =
  "ATTENDANCE_DETAILED_EMPLOYEE_LIST_SUCCESS";
export const ATTENDANCE_DETAILED_EMPLOYEE_LIST_FAILED =
  "ATTENDANCE_DETAILED_EMPLOYEE_LIST_FAILED";

//*  ATTENDANCE DETAILED EMPLOYEE LIST End *//

//* ATTENDANCE DETAILED EMPLOYEE PUNCH LOG LIST *//

export const ATTENDANCE_DETAILED_EMPLOYEE_PUNCH_LOG_LIST_INITIAL =
  "ATTENDANCE_DETAILED_EMPLOYEE_PUNCH_LOG_LIST_INITIAL";
export const ATTENDANCE_DETAILED_EMPLOYEE_PUNCH_LOG_LIST_PROCESS =
  "ATTENDANCE_DETAILED_EMPLOYEE_PUNCH_LOG_LIST_PROCESS";
export const ATTENDANCE_DETAILED_EMPLOYEE_PUNCH_LOG_LIST_SUCCESS =
  "ATTENDANCE_DETAILED_EMPLOYEE_PUNCH_LOG_LIST_SUCCESS";
export const ATTENDANCE_DETAILED_EMPLOYEE_PUNCH_LOG_LIST_FAILED =
  "ATTENDANCE_DETAILED_EMPLOYEE_LIST_PUNCH_LOG_FAILED";

export const ATTENDANCE_DETAILED_EMPLOYEE_LIST_SEARCH =
  "ATTENDANCE_DETAILED_EMPLOYEE_LIST_SEARCH";

//*  ATTENDANCE DETAILED EMPLOYEE PUNCH LOG LIST End *//

//* ATTENDANCE DETAILED ATTENDANCE TRENDS GRAPH*//

export const ATTENDANCE_DETAILED_ATTENDANCE_TREND_GRAPH_INITIAL =
  "ATTENDANCE_DETAILED_ATTENDANCE_TREND_GRAPH_INITIAL";
export const ATTENDANCE_DETAILED_ATTENDANCE_TREND_GRAPH_PROCESS =
  "ATTENDANCE_DETAILED_ATTENDANCE_TREND_GRAPH_PROCESS";
export const ATTENDANCE_DETAILED_ATTENDANCE_TREND_GRAPH_SUCCESS =
  "ATTENDANCE_DETAILED_ATTENDANCE_TREND_GRAPH_SUCCESS";
export const ATTENDANCE_DETAILED_ATTENDANCE_TREND_GRAPH_FAILED =
  "ATTENDANCE_DETAILED_ATTENDANCE_TREND_GRAPH_FAILED";

//*  ATTENDANCE DETAILED ATTENDANCE TRENDS GRAPH End *//

//* ATTENDANCE DateWise Attendances*//

export const ATTENDANCE_DATEWISE_ATTENDANCE_LIST_INITIAL =
  "ATTENDANCE_DATEWISE_ATTENDANCE_LIST_INITIAL";
export const ATTENDANCE_DATEWISE_ATTENDANCE_LIST_PROCESS =
  "ATTENDANCE_DATEWISE_ATTENDANCE_LIST_PROCESS";
export const ATTENDANCE_DATEWISE_ATTENDANCE_LIST_SUCCESS =
  "ATTENDANCE_DATEWISE_ATTENDANCE_LIST_SUCCESS";
export const ATTENDANCE_DATEWISE_ATTENDANCE_LIST_FAILED =
  "ATTENDANCE_DATEWISE_ATTENDANCE_LIST_FAILED";

// ACTIVITY REPORT DOWNLOAD

export const ATTENDANCE_EMP_LIST_DOWNLOAD_INITIAL =
  "ATTENDANCE_EMP_LIST_DOWNLOAD_INITIAL";
export const ATTENDANCE_EMP_LIST_DOWNLOAD_PROCESS =
  "ATTENDANCE_EMP_LIST_DOWNLOAD_PROCESS";
export const ATTENDANCE_EMP_LIST_DOWNLOAD_SUCCESS =
  "ATTENDANCE_EMP_LIST_DOWNLOAD_SUCCESS";
export const ATTENDANCE_EMP_LIST_DOWNLOAD_FAILED =
  "ATTENDANCE_EMP_LIST_DOWNLOAD_FAILED";
