import * as actionTypes from "./actionTypes";

const searchDomainPolicylist = (dataset, search) => {
  if (search.length > 0) {
    var searchdata = _.filter(dataset, (list) => {
      return list.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
        ? true
        : false;
    });

    return searchdata;
  } else {
    return dataset;
  }
};

const initialState = {
  //*GLOBAL*//

  //* LIST *//
  domain_blocking_policy_list_processing: false,
  domain_blocking_policy_list_success: false,
  domain_blocking_policy_list_failed: false,
  domain_blocking_policy_list_data: [],
  domain_blocking_policy_list_message: "",

  //* CREATE *//
  domain_blocking_policy_create_processing: false,
  domain_blocking_policy_create_success: false,
  domain_blocking_policy_create_failed: false,
  domain_blocking_policy_create_data: [],
  domain_blocking_policy_create_message: "",

  //* EDIT *//

  domain_blocking_policy_edit_processing: false,
  domain_blocking_policy_edit_success: false,
  domain_blocking_policy_edit_failed: false,
  domain_blocking_policy_edit_data: [],
  domain_blocking_policy_edit_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.DOMAIN_BLOCKING_POLICY_LIST_INITIAL:
      return {
        ...state,
        domain_blocking_policy_list_processing: false,
        domain_blocking_policy_list_success: false,
        domain_blocking_policy_list_failed: false,
        domain_blocking_policy_list_data: [],
        domainMaster: [],
      };
    case actionTypes.DOMAIN_BLOCKING_POLICY_LIST_PROCESS:
      return {
        ...state,
        domain_blocking_policy_list_processing: true,
        domain_blocking_policy_list_success: false,
        domain_blocking_policy_list_failed: false,
        domain_blocking_policy_list_data: [],
        domainMaster: [],
      };
    case actionTypes.DOMAIN_BLOCKING_POLICY_LIST_SUCCESS: {
      return {
        ...state,
        domain_blocking_policy_list_processing: false,
        domain_blocking_policy_list_success: true,
        domain_blocking_policy_list_failed: false,
        domain_blocking_policy_list_data: action.data.data,
        domainMaster: action.data.data,
      };
    }
    case actionTypes.DOMAIN_BLOCKING_POLICY_LIST_FAILED:
      return {
        ...state,
        domain_blocking_policy_list_processing: false,
        domain_blocking_policy_list_success: false,
        domain_blocking_policy_list_failed: true,
        domain_blocking_policy_list_data: [],
        domain_blocking_policy_error_message: action.data,
      };

    case actionTypes.DOMAIN_BLOCKING_POLICY_LIST_SEARCH:
      return {
        ...state,
        domain_blocking_policy_list_data: searchDomainPolicylist(
          state.domainMaster,
          action.data
        ),
      };

    case actionTypes.DOMAIN_BLOCKING_POLICY_CREATE_INITIAL:
      return {
        ...state,
        domain_blocking_policy_create_processing: false,
        domain_blocking_policy_create_success: false,
        domain_blocking_policy_create_failed: false,
        domain_blocking_policy_create_data: [],
      };
    case actionTypes.DOMAIN_BLOCKING_POLICY_CREATE_PROCESS:
      return {
        ...state,
        domain_blocking_policy_create_processing: true,
        domain_blocking_policy_create_success: false,
        domain_blocking_policy_create_failed: false,
        domain_blocking_policy_create_data: [],
      };
    case actionTypes.DOMAIN_BLOCKING_POLICY_CREATE_SUCCESS: {
      return {
        ...state,
        domain_blocking_policy_create_processing: false,
        domain_blocking_policy_create_success: true,
        domain_blocking_policy_create_failed: false,
        domain_blocking_policy_create_data: action.data,
        domain_blocking_policy_list_data: [
          ...state.domain_blocking_policy_list_data,
          action.data,
        ],
        domainMaster: [...state.domainMaster, action.data],
      };
    }

    case actionTypes.DOMAIN_BLOCKING_POLICY_CREATE_FAILED:
      return {
        ...state,
        domain_blocking_policy_create_processing: false,
        domain_blocking_policy_create_success: false,
        domain_blocking_policy_create_failed: true,
        domain_blocking_policy_create_data: [],
        domain_blocking_policy_error_message: action.data,
      };
    case actionTypes.DOMAIN_BLOCKING_POLICY_EDIT_INITIAL:
      return {
        ...state,
        domain_blocking_policy_edit_processing: false,
        domain_blocking_policy_edit_success: false,
        domain_blocking_policy_edit_failed: false,
        domain_blocking_policy_edit_data: [],
      };
    case actionTypes.DOMAIN_BLOCKING_POLICY_EDIT_PROCESS:
      return {
        ...state,
        domain_blocking_policy_edit_processing: true,
        domain_blocking_policy_edit_success: false,
        domain_blocking_policy_edit_failed: false,
        domain_blocking_policy_edit_data: [],
      };
    case actionTypes.DOMAIN_BLOCKING_POLICY_EDIT_SUCCESS:
      const list = [...state.domain_blocking_policy_list_data];
      const findIndex = list.findIndex((item) => item.id == action.data.id);
      list[findIndex] = action.data;

      return {
        ...state,
        domain_blocking_policy_edit_processing: false,
        domain_blocking_policy_edit_success: true,
        domain_blocking_policy_edit_failed: false,
        domain_blocking_policy_edit_data: action.data,
        domain_blocking_policy_list_data: list,
      };

    case actionTypes.DOMAIN_BLOCKING_POLICY_EDIT_FAILED:
      return {
        ...state,
        domain_blocking_policy_edit_processing: false,
        domain_blocking_policy_edit_success: false,
        domain_blocking_policy_edit_failed: true,
        domain_blocking_policy_edit_data: [],
        domain_blocking_policy_error_message: action.data,
      };

    default:
      return state;
  }
};
