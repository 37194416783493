import * as actionTypes from "./actionTypes";

export const fetchManualTime = (payload) => {
  return {
    type: actionTypes.FETCH_MANUALTIME,
    payload,
  };
};

export const fetchManualTimeSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_MANUALTIME_SUCCESS,
    payload,
  };
};

export const fetchManualTimeFailed = (payload) => {
  return {
    type: actionTypes.FETCH_MANUALTIME_FAILED,
    payload,
  };
};

export const createManualTime = (payload) => {
  return {
    type: actionTypes.CREATE_MANUALTIME,
    payload,
  };
};

export const createManualTimeSuccess = (payload) => {
  return {
    type: actionTypes.CREATE_MANUALTIME_SUCCESS,
    payload,
  };
};

export const createManualTimeFailed = (payload) => {
  return {
    type: actionTypes.CREATE_MANUALTIME_FAILED,
    payload,
  };
};

export const updateManualTime = (payload) => {
  return {
    type: actionTypes.UPDATE_MANUALTIME,
    payload,
  };
};

export const updateManualTimeSuccess = (payload) => {
  return {
    type: actionTypes.UPDATE_MANUALTIME_SUCCESS,
    payload,
  };
};

export const updateManualTimeFailed = (payload) => {
  return {
    type: actionTypes.UPDATE_MANUALTIME_FAILED,
    payload,
  };
};

export const setManualTimeErrorMessage = (payload) => {
  return {
    type: actionTypes.SET_MANUALTIME_ERROR_MESSAGE,
    payload,
  };
};
export const deleteManualTime = (payload) => {
  return {
    type: actionTypes.DELETE_MANUALTIME,
    payload,
  };
};

export const deleteManualTimeSuccess = (payload) => {
  return {
    type: actionTypes.DELETE_MANUALTIME_SUCCESS,
    payload,
  };
};

export const deleteManualTimeFailed = (payload) => {
  return {
    type: actionTypes.DELETE_MANUALTIME_FAILED,
    payload,
  };
};

export const downloadManualTime = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_MANUALTIME,
    payload,
  };
};

export const downloadManualTimeSuccess = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_MANUALTIME_SUCCESS,
    payload,
  };
};

export const downloadManualTimeFailed = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_MANUALTIME_FAILED,
    payload,
  };
};
