import * as actionTypes from "./actionTypes";
import _ from "lodash";

const searchAttendanceDetailEmployeeList = (dataset, search) => {
  if (search.length > 0) {
    var searchdata = _.filter(dataset.data, (list) => {
      return (
        (list.first_name.toLowerCase().indexOf(search.toLowerCase()) >= 0
          ? true
          : false) ||
        (list.last_name.toLowerCase().indexOf(search.toLowerCase()) >= 0
          ? true
          : false) ||
        (list.email.toLowerCase().indexOf(search.toLowerCase()) >= 0
          ? true
          : false)
      );
    });

    var data = {
      data: searchdata,
    };
    return data;
  } else {
    return dataset.data;
  }
};

const initialState = {
  //*GLOBAL*//

  //* ATTENDANCE detailed ATTENDANCE TRENDS  *//

  attendance_detailed_attendance_trend_processing: false,
  attendance_detailed_attendance_trend_success: false,
  attendance_detailed_attendance_trend_failed: false,
  attendance_detailed_attendance_trend_data: [],
  attendance_detailed_attendance_trend_message: "",

  //* ATTENDANCE detailed employee_list WEDIGT *//

  attendance_detailed_employee_list_processing: false,
  attendance_detailed_employee_list_success: false,
  attendance_detailed_employee_list_failed: false,
  attendance_detailed_employee_list_data: [],
  attendance_detailed_employee_list_data_master: [],
  attendance_detailed_employee_list_message: "",

  //* ATTENDANCE detailed employee_list PUNCH LOG  *//

  attendance_detailed_employee_punch_log_list_processing: false,
  attendance_detailed_employee_punch_log_list_success: false,
  attendance_detailed_employee_punch_log_list_failed: false,
  attendance_detailed_employee_punch_log_list_data: [],
  attendance_detailed_employee_punch_log_list_message: "",

  //* ATTENDANCE detailed ATTENDANCE TRENDS GRAPH *//

  attendance_detailed_attendance_trend_graph_processing: false,
  attendance_detailed_attendance_trend_graph_success: false,
  attendance_detailed_attendance_trend_graph_failed: false,
  attendance_detailed_attendance_trend_graph_data: [],
  attendance_detailed_attendance_trend_graph_message: "",

  attendance_datewise_attendance_list_processing: false,
  attendance_datewise_attendance_list_success: false,
  attendance_datewise_attendance_list_failed: false,
  attendance_datewise_attendance_list_data: [],
  attendance_datewise_attendance_list_message: "",

  attendance_emp_list_download_processing: false,
  attendance_emp_list_download_success: false,
  attendance_emp_list_download_failed: false,
  attendance_emp_list_download_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.ATTENDANCE_DETAILED_ATTENDANCE_TREND_INITIAL:
      return {
        ...state,
        attendance_detailed_attendance_trend_processing: true,
        attendance_detailed_attendance_trend_success: false,
        attendance_detailed_attendance_trend_failed: false,
        attendance_detailed_attendance_trend_data: [],
        attendance_detailed_attendance_trend_message: "",
      };

    case actionTypes.ATTENDANCE_DETAILED_ATTENDANCE_TREND_PROCESS:
      return {
        ...state,
        attendance_detailed_attendance_trend_processing: true,
        attendance_detailed_attendance_trend_success: false,
        attendance_detailed_attendance_trend_failed: false,
        attendance_detailed_attendance_trend_data: [],
        attendance_detailed_attendance_trend_message: "",
      };

    case actionTypes.ATTENDANCE_DETAILED_ATTENDANCE_TREND_SUCCESS:
      return {
        ...state,
        attendance_detailed_attendance_trend_processing: false,
        attendance_detailed_attendance_trend_success: true,
        attendance_detailed_attendance_trend_failed: false,
        attendance_detailed_attendance_trend_data: action.data,
        attendance_detailed_attendance_trend_message: "",
      };
    case actionTypes.ATTENDANCE_DETAILED_ATTENDANCE_TREND_FAILED:
      return {
        ...state,
        attendance_detailed_attendance_trend_processing: false,
        attendance_detailed_attendance_trend_success: false,
        attendance_detailed_attendance_trend_failed: true,
        attendance_detailed_attendance_trend_data: action.data,
        attendance_detailed_attendance_trend_message: "",
      };
    case actionTypes.ATTENDANCE_DETAILED_EMPLOYEE_LIST_INITIAL:
      return {
        ...state,
        attendance_detailed_employee_list_processing: false,
        attendance_detailed_employee_list_success: false,
        attendance_detailed_employee_list_failed: false,
        attendance_detailed_employee_list_data: [],
        attendance_detailed_employee_list_data_master: [],
        attendance_detailed_employee_list_message: "",
      };

    case actionTypes.ATTENDANCE_DETAILED_EMPLOYEE_LIST_PROCESS:
      return {
        ...state,
        attendance_detailed_employee_list_processing: true,
        attendance_detailed_employee_list_success: false,
        attendance_detailed_employee_list_failed: false,
        attendance_detailed_employee_list_data: [],
        attendance_detailed_employee_list_data_master: [],
        attendance_detailed_employee_list_message: "",
      };

    case actionTypes.ATTENDANCE_DETAILED_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        attendance_detailed_employee_list_processing: false,
        attendance_detailed_employee_list_success: true,
        attendance_detailed_employee_list_failed: false,
        attendance_detailed_employee_list_data: action.data,
        attendance_detailed_employee_list_data_master: action.data,
        attendance_detailed_employee_list_message: "",
      };
    case actionTypes.ATTENDANCE_DETAILED_EMPLOYEE_LIST_FAILED:
      return {
        ...state,
        attendance_detailed_employee_list_processing: false,
        attendance_detailed_employee_list_success: false,
        attendance_detailed_employee_list_failed: true,
        attendance_detailed_employee_list_data: action.data,
        attendance_detailed_employee_list_data_master: action.data,
        attendance_detailed_employee_list_message: "",
      };

    case actionTypes.ATTENDANCE_DETAILED_EMPLOYEE_PUNCH_LOG_LIST_INITIAL:
      return {
        ...state,
        attendance_detailed_employee_punch_log_list_processing: false,
        attendance_detailed_employee_punch_log_list_success: false,
        attendance_detailed_employee_punch_log_list_failed: false,
        attendance_detailed_employee_punch_log_list_data: [],
        attendance_detailed_employee_punch_log_list_message: "",
      };

    case actionTypes.ATTENDANCE_DETAILED_EMPLOYEE_PUNCH_LOG_LIST_PROCESS:
      return {
        ...state,
        attendance_detailed_employee_punch_log_list_processing: true,
        attendance_detailed_employee_punch_log_list_success: false,
        attendance_detailed_employee_punch_log_list_failed: false,
        attendance_detailed_employee_punch_log_list_data: [],
        attendance_detailed_employee_punch_log_list_message: "",
      };

    case actionTypes.ATTENDANCE_DETAILED_EMPLOYEE_PUNCH_LOG_LIST_SUCCESS:
      return {
        ...state,
        attendance_detailed_employee_punch_log_list_processing: false,
        attendance_detailed_employee_punch_log_list_success: true,
        attendance_detailed_employee_punch_log_list_failed: false,
        attendance_detailed_employee_punch_log_list_data: action.data,
        attendance_detailed_employee_punch_log_list_message: "",
      };
    case actionTypes.ATTENDANCE_DETAILED_EMPLOYEE_PUNCH_LOG_LIST_FAILED:
      return {
        ...state,
        attendance_detailed_employee_punch_log_list_processing: false,
        attendance_detailed_employee_punch_log_list_success: false,
        attendance_detailed_employee_punch_log_list_failed: true,
        attendance_detailed_employee_punch_log_list_data: [],
        attendance_detailed_employee_punch_log_list_message: action.data,
      };

    case actionTypes.ATTENDANCE_DETAILED_EMPLOYEE_LIST_SEARCH:
      return {
        ...state,
        attendance_detailed_employee_list_data:
          searchAttendanceDetailEmployeeList(
            state.attendance_detailed_employee_list_data_master,
            action.data
          ),
      };

    case actionTypes.ATTENDANCE_DETAILED_ATTENDANCE_TREND_GRAPH_INITIAL:
      return {
        ...state,
        attendance_detailed_attendance_trend_graph_processing: true,
        attendance_detailed_attendance_trend_graph_success: false,
        attendance_detailed_attendance_trend_graph_failed: false,
        attendance_detailed_attendance_trend_graph_data: [],
        attendance_detailed_attendance_trend_graph_message: "",
      };

    case actionTypes.ATTENDANCE_DETAILED_ATTENDANCE_TREND_GRAPH_PROCESS:
      return {
        ...state,
        attendance_detailed_attendance_trend_graph_processing: true,
        attendance_detailed_attendance_trend_graph_success: false,
        attendance_detailed_attendance_trend_graph_failed: false,
        attendance_detailed_attendance_trend_graph_data: [],
        attendance_detailed_attendance_trend_graph_message: "",
      };

    case actionTypes.ATTENDANCE_DETAILED_ATTENDANCE_TREND_GRAPH_SUCCESS:
      return {
        ...state,
        attendance_detailed_attendance_trend_graph_processing: false,
        attendance_detailed_attendance_trend_graph_success: true,
        attendance_detailed_attendance_trend_graph_failed: false,
        attendance_detailed_attendance_trend_graph_data: action.data,
        attendance_detailed_attendance_trend_graph_message: "",
      };
    case actionTypes.ATTENDANCE_DETAILED_ATTENDANCE_TREND_GRAPH_FAILED:
      return {
        ...state,
        attendance_detailed_attendance_trend_graph_processing: false,
        attendance_detailed_attendance_trend_graph_success: false,
        attendance_detailed_attendance_trend_graph_failed: true,
        attendance_detailed_attendance_trend_graph_data: [],
        attendance_detailed_attendance_trend_graph_message: action.data,
      };

    case actionTypes.ATTENDANCE_DATEWISE_ATTENDANCE_LIST_INITIAL:
      return {
        ...state,
        attendance_datewise_attendance_list_processing: false,
        attendance_datewise_attendance_list_success: false,
        attendance_datewise_attendance_list_failed: false,
        attendance_datewise_attendance_list_data: [],
        attendance_datewise_attendance_list_message: "",
      };

    case actionTypes.ATTENDANCE_DATEWISE_ATTENDANCE_LIST_PROCESS:
      return {
        ...state,
        attendance_datewise_attendance_list_processing: true,
        attendance_datewise_attendance_list_success: false,
        attendance_datewise_attendance_list_failed: false,
        attendance_datewise_attendance_list_data: [],
        attendance_datewise_attendance_list_message: "",
      };

    case actionTypes.ATTENDANCE_DATEWISE_ATTENDANCE_LIST_SUCCESS:
      return {
        ...state,
        attendance_datewise_attendance_list_processing: false,
        attendance_datewise_attendance_list_success: true,
        attendance_datewise_attendance_list_failed: false,
        attendance_datewise_attendance_list_data: action.data,
        attendance_datewise_attendance_list_message: "",
      };
    case actionTypes.ATTENDANCE_DATEWISE_ATTENDANCE_LIST_FAILED:
      return {
        ...state,
        attendance_datewise_attendance_list_processing: false,
        attendance_datewise_attendance_list_success: false,
        attendance_datewise_attendance_list_failed: true,
        attendance_datewise_attendance_list_data: [],
        attendance_datewise_attendance_list_message: action.data,
      };

    case actionTypes.ATTENDANCE_EMP_LIST_DOWNLOAD_INITIAL:
      return {
        ...state,
        attendance_emp_list_download_processing: false,
        attendance_emp_list_download_success: false,
        attendance_emp_list_download_failed: false,
        attendance_emp_list_download_message: "",
      };
    case actionTypes.ATTENDANCE_EMP_LIST_DOWNLOAD_PROCESS:
      return {
        ...state,
        attendance_emp_list_download_processing: true,
        attendance_emp_list_download_success: false,
        attendance_emp_list_download_failed: false,
        attendance_emp_list_download_message: "",
      };
    case actionTypes.ATTENDANCE_EMP_LIST_DOWNLOAD_SUCCESS:
      return {
        ...state,
        attendance_emp_list_download_processing: false,
        attendance_emp_list_download_success: true,
        attendance_emp_list_download_failed: false,
        attendance_emp_list_download_message: "",
      };

    case actionTypes.ATTENDANCE_EMP_LIST_DOWNLOAD_FAILED:
      return {
        ...state,
        attendance_emp_list_download_processing: false,
        attendance_emp_list_download_success: false,
        attendance_emp_list_download_failed: true,
        attendance_emp_list_download_message: "",
      };

    default:
      return state;
  }
};
