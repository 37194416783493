import * as actionTypes from "./actionTypes";

export const fetchDailyPings = (payload) => {
  return {
    type: actionTypes.FETCH_DAILY_PINGS,
    payload,
  };
};

export const fetchDailyPingsSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_DAILY_PINGS_SUCCESS,
    payload,
  };
};

export const fetchDailyPingsFailed = (payload) => {
  return {
    type: actionTypes.FETCH_DAILY_PINGS_FAILED,
    payload,
  };
};

export const setFieldErrorMessage = (payload) => {
  return {
    type: actionTypes.SET_FIELD_ERROR_MESSAGE,
    payload,
  };
};
