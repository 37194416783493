// import AsyncStorage from "@react-native-community/async-storage";
import { composeWithDevTools } from "redux-devtools-extension";
import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";

import RootSaga from "./store/saga";
import Rootreducers from "./store/reducer";

import {
  useDispatch as useReduxDispatch,
  useSelector as useReduxSelector,
} from "react-redux";

const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware];
const store = createStore(
  Rootreducers,
  composeWithDevTools(applyMiddleware(...middleware))
);
sagaMiddleware.run(RootSaga);

export default store;
export const useSelector = useReduxSelector;
export const useDispatch = () => useReduxDispatch();
