import * as actionTypes from "./actionTypes";

//* LIST *//

export const ApplicationPolicyListInitial = () => {
  return {
    type: actionTypes.APPLICATIONPOLICY_LIST_INITIAL,
  };
};
export const ApplicationPolicyListProcess = (data) => {
  return {
    type: actionTypes.APPLICATIONPOLICY_LIST_PROCESS,
    data,
  };
};
export const ApplicationPolicyListSuccess = (data) => {
  return {
    type: actionTypes.APPLICATIONPOLICY_LIST_SUCCESS,
    data,
  };
};
export const ApplicationPolicyListFailed = (data) => {
  return {
    type: actionTypes.APPLICATIONPOLICY_LIST_FAILED,
    data,
  };
};

export const ApplicationPolicyListSearch = (data) => {
  return {
    type: actionTypes.APPLICATIONPOLICY_LIST_SEARCH,
    data,
  };
};
//* CREATE *//

export const ApplicationPolicyCreateInitial = () => {
  return {
    type: actionTypes.APPLICATIONPOLICY_CREATE_INITIAL,
  };
};

export const ApplicationPolicyCreateProcess = (data) => {
  return {
    type: actionTypes.APPLICATIONPOLICY_CREATE_PROCESS,
    data,
  };
};
export const ApplicationPolicyCreateSuccess = (data) => {
  return {
    type: actionTypes.APPLICATIONPOLICY_CREATE_SUCCESS,
    data,
  };
};
export const ApplicationPolicyCreateFailed = (data) => {
  return {
    type: actionTypes.APPLICATIONPOLICY_CREATE_FAILED,
    data,
  };
};

//* EDIT *//

export const ApplicationPolicyEditInitial = (data, id, token) => {
  return {
    type: actionTypes.APPLICATIONPOLICY_EDIT_INITIAL,
    data,
    id,
    token,
  };
};

export const ApplicationPolicyEditProcess = (data, id, token) => {
  return {
    type: actionTypes.APPLICATIONPOLICY_EDIT_PROCESS,
    data,
    id,
    token,
  };
};
export const ApplicationPolicyEditSuccess = (data) => {
  return {
    type: actionTypes.APPLICATIONPOLICY_EDIT_SUCCESS,
    data,
  };
};
export const ApplicationPolicyEditFailed = (data) => {
  return {
    type: actionTypes.APPLICATIONPOLICY_EDIT_FAILED,
    data,
  };
};

//APPLICATION POLICY//
//* DELETE *//

export const ApplicationPolicyDeleteInitial = (data, id, token) => {
  return {
    type: actionTypes.APPLICATIONPOLICY_DELETE_INITIAL,
    data,
    id,
    token,
  };
};

export const ApplicationPolicyDeleteProcess = (data, id, token) => {
  return {
    type: actionTypes.APPLICATIONPOLICY_DELETE_PROCESS,
    data,
    id,
    token,
  };
};

export const ApplicationPolicyDeleteSuccess = (data, id) => {
  return {
    type: actionTypes.APPLICATIONPOLICY_DELETE_SUCCESS,
    data,
    id,
  };
};

export const ApplicationPolicyDeleteFailed = (data) => {
  return {
    type: actionTypes.APPLICATIONPOLICY_DELETE_FAILED,
    data,
  };
};
