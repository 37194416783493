import * as actionTypes from "./actionTypes";
import _ from "lodash";

const initialState = {
  //*GLOBAL*//

  //* APPLICATION SUMMARY CATEGORY_UTILIZATION *//

  application_summary_category_utilization_processing: false,
  application_summary_category_utilization_success: false,
  application_summary_category_utilization_failed: false,
  application_summary_category_utilization_data: [],
  application_summary_category_utilization_message: "",

  //* APPLICATION SUMMARY ONLINE TIME BREAK DOWN *//

  application_summary_total_application_usage_processing: false,
  application_summary_total_application_usage_success: false,
  application_summary_total_application_usage_failed: false,
  application_summary_total_application_usage_data: [],
  application_summary_total_application_usage_message: "",

  //* APPLICATION SUMMARY APPLICATION LEVEL BREAK DOWN  *//

  application_summary_total_url_usage_processing: false,
  application_summary_total_url_usage_success: false,
  application_summary_total_url_usage_failed: false,
  application_summary_total_url_usage_data: [],
  application_summary_total_url_usage_message: "",

  //* APPLICATION SUMMARY TOP MOST ACTIVE TEAM  *//

  application_summary_top_buttom_active_team_processing: false,
  application_summary_top_buttom_active_team_success: false,
  application_summary_top_buttom_active_team_failed: false,
  application_summary_top_buttom_active_team_data: [],
  application_summary_top_buttom_active_team_message: "",

  //* APPLICATION SUMMARY TOP LEAST ACTIVE TEAM  *//

  application_summary_top_least_active_team_processing: true,
  application_summary_top_least_active_team_success: false,
  application_summary_top_least_active_team_failed: false,
  application_summary_top_least_active_team_data: [],
  application_summary_top_least_active_team_message: "",

  //* APPLICATION SUMMARY TEAM WISE APPLICATION BREAK DOWN *//

  application_summary_team_wise_application_break_down_processing: true,
  application_summary_team_wise_application_break_down_success: false,
  application_summary_team_wise_application_break_down_failed: false,
  application_summary_team_wise_application_break_down_data: [],
  application_summary_team_wise_application_break_down_message: "",

  //* APPLICATION SUMMARY CATEGORY_UTILIZATION *//

  application_summary_app_url_processing: false,
  application_summary_app_url_success: false,
  application_summary_app_url_failed: false,
  application_summary_app_url_data: [],
  application_summary_app_url_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.APPLICATION_SUMMARY_CATEGORY_UTILIZATION_INITIAL:
      return {
        ...state,
        application_summary_category_utilization_processing: true,
        application_summary_category_utilization_success: false,
        application_summary_category_utilization_failed: false,
        application_summary_category_utilization_data: [],
        application_summary_category_utilization_message: "",
      };

    case actionTypes.APPLICATION_SUMMARY_CATEGORY_UTILIZATION_PROCESS:
      return {
        ...state,
        application_summary_category_utilization_processing: true,
        application_summary_category_utilization_success: false,
        application_summary_category_utilization_failed: false,
        application_summary_category_utilization_data: [],
        application_summary_category_utilization_message: "",
      };

    case actionTypes.APPLICATION_SUMMARY_CATEGORY_UTILIZATION_SUCCESS:
      return {
        ...state,
        application_summary_category_utilization_processing: false,
        application_summary_category_utilization_success: true,
        application_summary_category_utilization_failed: false,
        application_summary_category_utilization_data: action.data,
        application_summary_category_utilization_message: "",
      };
    case actionTypes.APPLICATION_SUMMARY_CATEGORY_UTILIZATION_FAILED:
      return {
        ...state,
        application_summary_category_utilization_processing: false,
        application_summary_category_utilization_success: false,
        application_summary_category_utilization_failed: true,
        application_summary_category_utilization_data: action.data,
        application_summary_category_utilization_message: "",
      };

    case actionTypes.APPLICATION_SUMMARY_TOTAL_APPLICATION_USAGE_INITIAL:
      return {
        ...state,
        application_summary_total_application_usage_processing: false,
        application_summary_total_application_usage_success: false,
        application_summary_total_application_usage_failed: false,
        application_summary_total_application_usage_data: [],
        application_summary_total_application_usage_message: "",
      };

    case actionTypes.APPLICATION_SUMMARY_TOTAL_APPLICATION_USAGE_PROCESS:
      return {
        ...state,
        application_summary_total_application_usage_processing: true,
        application_summary_total_application_usage_success: false,
        application_summary_total_application_usage_failed: false,
        application_summary_total_application_usage_data: [],
        application_summary_total_application_usage_message: "",
      };

    case actionTypes.APPLICATION_SUMMARY_TOTAL_APPLICATION_USAGE_SUCCESS:
      return {
        ...state,
        application_summary_total_application_usage_processing: false,
        application_summary_total_application_usage_success: true,
        application_summary_total_application_usage_failed: false,
        application_summary_total_application_usage_data: action.data,
        application_summary_total_application_usage_message: "",
      };
    case actionTypes.APPLICATION_SUMMARY_TOTAL_APPLICATION_USAGE_FAILED:
      return {
        ...state,
        application_summary_total_application_usage_processing: false,
        application_summary_total_application_usage_success: false,
        application_summary_total_application_usage_failed: true,
        application_summary_total_application_usage_data: [],
        application_summary_total_application_usage_message: action.data,
      };

    case actionTypes.APPLICATION_SUMMARY_TOTAL_URL_USAGE_INITIAL:
      return {
        ...state,
        application_summary_total_url_usage_processing: false,
        application_summary_total_url_usage_success: false,
        application_summary_total_url_usage_failed: false,
        application_summary_total_url_usage_data: [],
        application_summary_total_url_usage_message: "",
      };

    case actionTypes.APPLICATION_SUMMARY_TOTAL_URL_USAGE_PROCESS:
      return {
        ...state,
        application_summary_total_url_usage_processing: true,
        application_summary_total_url_usage_success: false,
        application_summary_total_url_usage_failed: false,
        application_summary_total_url_usage_data: [],
        application_summary_total_url_usage_message: "",
      };

    case actionTypes.APPLICATION_SUMMARY_TOTAL_URL_USAGE_SUCCESS:
      return {
        ...state,
        application_summary_total_url_usage_processing: false,
        application_summary_total_url_usage_success: true,
        application_summary_total_url_usagefailed: false,
        application_summary_total_url_usage_data: action.data,
        application_summary_total_url_usage_message: "",
      };
    case actionTypes.APPLICATION_SUMMARY_TOTAL_URL_USAGE_FAILED:
      return {
        ...state,
        application_summary_total_url_usage_processing: false,
        application_summary_total_url_usagesuccess: false,
        application_summary_total_url_usage_failed: true,
        application_summary_total_url_usagedata: action.data,
        application_summary_total_url_usage_message: "",
      };

    case actionTypes.APPLICATION_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_INITIAL:
      return {
        ...state,
        application_summary_top_buttom_active_team_processing: false,
        application_summary_top_buttom_active_team_success: false,
        application_summary_top_buttom_active_team_failed: false,
        application_summary_top_buttom_active_team_data: [],
        application_summary_top_buttom_active_team_message: "",
      };

    case actionTypes.APPLICATION_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_PROCESS:
      return {
        ...state,
        application_summary_top_buttom_active_team_processing: true,
        application_summary_top_buttom_active_team_success: false,
        application_summary_top_buttom_active_team_failed: false,
        application_summary_top_buttom_active_team_data: [],
        application_summary_top_buttom_active_team_message: "",
      };

    case actionTypes.APPLICATION_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_SUCCESS:
      return {
        ...state,
        application_summary_top_buttom_active_team_processing: false,
        application_summary_top_buttom_active_team_success: true,
        application_summary_top_buttom_active_team_failed: false,
        application_summary_top_buttom_active_team_data: action.data,
        application_summary_top_buttom_active_team_message: "",
      };
    case actionTypes.APPLICATION_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_FAILED:
      return {
        ...state,
        application_summary_top_buttom_active_team_processing: false,
        application_summary_top_buttom_active_team_success: false,
        application_summary_top_buttom_active_team_failed: true,
        application_summary_top_buttom_active_team_data: [],
        application_summary_top_buttom_active_team_message: action.data,
      };
    case actionTypes.APPLICATION_SUMMARY_TOP_LEAST_ACTIVE_TEAM_INITIAL:
      return {
        ...state,
        application_summary_top_least_Active_team_processing: true,
        application_summary_top_least_Active_team_success: false,
        application_summary_top_least_Active_team_failed: false,
        application_summary_top_least_Active_team_data: [],
        application_summary_top_least_Active_team_message: "",
      };

    case actionTypes.APPLICATION_SUMMARY_TOP_LEAST_ACTIVE_TEAM_PROCESS:
      return {
        ...state,
        application_summary_top_least_Active_team_processing: true,
        application_summary_top_least_Active_team_success: false,
        application_summary_top_least_Active_team_failed: false,
        application_summary_top_least_Active_team_data: [],
        application_summary_top_least_Active_team_message: "",
      };

    case actionTypes.APPLICATION_SUMMARY_TOP_LEAST_ACTIVE_TEAM_SUCCESS:
      return {
        ...state,
        application_summary_top_least_Active_team_processing: false,
        application_summary_top_least_Active_team_success: true,
        application_summary_top_least_Active_teamfailed: false,
        application_summary_top_least_Active_team_data: action.data,
        application_summary_top_least_Active_team_message: "",
      };
    case actionTypes.APPLICATION_SUMMARY_TOP_LEAST_ACTIVE_TEAM_FAILED:
      return {
        ...state,
        application_summary_top_least_Active_team_processing: false,
        application_summary_top_least_Active_team_success: false,
        application_summary_top_least_Active_team_failed: true,
        application_summary_top_least_Active_team_data: action.data,
        application_summary_top_least_Active_team_message: "",
      };

    case actionTypes.APPLICATION_SUMMARY_TEAM_WISE_APPLICATION_BREAK_DOWN_INITIAL:
      return {
        ...state,
        application_summary_team_wise_application_break_down_processing: true,
        application_summary_team_wise_application_break_down_success: false,
        application_summary_team_wise_application_break_down_failed: false,
        application_summary_team_wise_application_break_down_data: [],
        application_summary_team_wise_application_break_down_message: "",
      };

    case actionTypes.APPLICATION_SUMMARY_TEAM_WISE_APPLICATION_BREAK_DOWN_PROCESS:
      return {
        ...state,
        application_summary_team_wise_application_break_down_processing: true,
        application_summary_team_wise_application_break_down_success: false,
        application_summary_team_wise_application_break_down_failed: false,
        application_summary_team_wise_application_break_down_data: [],
        application_summary_team_wise_application_break_down_message: "",
      };

    case actionTypes.APPLICATION_SUMMARY_TEAM_WISE_APPLICATION_BREAK_DOWN_SUCCESS:
      return {
        ...state,
        application_summary_team_wise_application_break_down_processing: false,
        application_summary_team_wise_application_break_down_success: true,
        application_summary_team_wise_application_break_down_failed: false,
        application_summary_team_wise_application_break_down_data: action.data,
        application_summary_team_wise_application_break_down_message: "",
      };
    case actionTypes.APPLICATION_SUMMARY_TEAM_WISE_APPLICATION_BREAK_DOWN_FAILED:
      return {
        ...state,
        application_summary_team_wise_application_break_down_processing: false,
        application_summary_team_wise_application_break_down_success: false,
        application_summary_team_wise_application_break_down_failed: true,
        application_summary_team_wise_application_break_down_data: action.data,
        application_summary_team_wise_application_break_down_message: "",
      };

    case actionTypes.APPLICATION_SUMMARY_TOP_APPLICATION_URL_INITIAL:
      return {
        ...state,
        application_summary_app_url_processing: false,
        application_summary_app_url_success: false,
        application_summary_app_url_failed: false,
        application_summary_app_url_data: [],
        application_summary_app_url_message: "",
      };

    case actionTypes.APPLICATION_SUMMARY_TOP_APPLICATION_URL_PROCESS:
      return {
        ...state,
        application_summary_app_url_processing: true,
        application_summary_app_url_success: false,
        application_summary_app_url_failed: false,
        application_summary_app_url_data: [],
        application_summary_app_url_message: "",
      };

    case actionTypes.APPLICATION_SUMMARY_TOP_APPLICATION_URL_SUCCESS:
      return {
        ...state,
        application_summary_app_url_processing: false,
        application_summary_app_url_success: true,
        application_summary_app_url_failed: false,
        application_summary_app_url_data: action.data,
        application_summary_app_url_message: "",
      };
    case actionTypes.APPLICATION_SUMMARY_TOP_APPLICATION_URL_FAILED:
      return {
        ...state,
        application_summary_app_url_processing: false,
        application_summary_app_url_success: false,
        application_summary_app_url_failed: true,
        application_summary_app_url_data: [],
        application_summary_app_url_message: action.data,
      };

    default:
      return state;
  }
};
