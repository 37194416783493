import * as actionTypes from "./actionTypes";

const initialPagination = { totalCount: 0 };

const initialState = {
  getNoteLoading: true,
  selectedNote: null,
  fetchNotesLoading: true,
  notesData: {},
  pagination: initialPagination,
  noteLoading: false,
  noteSuccess: false,
  deleteNoteSuccess: false,
  deleteNoteLoading: false,
  noteErrorMessage: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_NOTE:
      return {
        ...state,
        getNoteLoading: true,
        noteErrorMessage: "",
        selectedNote: null,
      };

    case actionTypes.GET_NOTE_SUCCESS:
      return {
        ...state,
        getNoteLoading: false,
        selectedNote: action.payload,
      };

    case actionTypes.SET_SELECTED_NOTE:
      return {
        ...state,
        selectedNote: action.payload,
      };

    case actionTypes.GET_NOTE_FAILED:
      return {
        ...state,
        getNoteLoading: false,
        noteErrorMessage: action.payload,
      };

    case actionTypes.FETCH_NOTES:
      return {
        ...state,
        fetchNotesLoading: true,
        noteErrorMessage: "",
      };

    case actionTypes.FETCH_NOTES_SUCCESS:
      return {
        ...state,
        fetchNotesLoading: false,
        notesData: action.payload.data.reduce(
          (acc, note) => ({ ...acc, [note.id]: note }),
          {}
        ),
        pagination: action.payload.meta || state.pagination,
      };

    case actionTypes.FETCH_NOTES_FAILED:
      return {
        ...state,
        fetchNotesLoading: false,
        notesData: {},
        pagination: initialPagination,
        noteErrorMessage: action.payload,
      };

    case actionTypes.CREATE_NOTE:
      return {
        ...state,
        noteLoading: true,
        noteErrorMessage: "",
        noteSuccess: false,
      };

    case actionTypes.CREATE_NOTE_SUCCESS:
      return {
        ...state,
        noteLoading: false,
        notesData: {
          ...state.notesData,
          [action.payload.id]: action.payload,
        },
        pagination: {
          ...state.pagination,
          totalCount: state.pagination.totalCount + 1,
        },
        noteSuccess: true,
      };

    case actionTypes.CREATE_NOTE_FAILED:
      return {
        ...state,
        noteLoading: false,
        noteErrorMessage: action.payload,
      };

    case actionTypes.UPDATE_NOTE:
      return {
        ...state,
        noteLoading: true,
        noteErrorMessage: "",
        noteSuccess: false,
      };

    case actionTypes.UPDATE_NOTE_SUCCESS:
      return {
        ...state,
        noteLoading: false,
        notesData: {
          ...state.notesData,
          [action.payload.id]: action.payload,
        },
        noteSuccess: true,
      };

    case actionTypes.UPDATE_NOTE_FAILED:
      return {
        ...state,
        noteLoading: false,
        noteErrorMessage: action.payload,
      };

    case actionTypes.DELETE_NOTE:
      return {
        ...state,
        deleteNoteLoading: true,
        noteErrorMessage: "",
        deleteNoteSuccess: false,
      };

    case actionTypes.DELETE_NOTE_SUCCESS:
      return {
        ...state,
        deleteNoteLoading: false,
        deleteNoteSuccess: true,
      };

    case actionTypes.DELETE_NOTE_FAILED:
      return {
        ...state,
        deleteNoteLoading: false,
        noteErrorMessage: action.payload,
      };

    case actionTypes.SET_NOTE_ERROR_MESSAGE:
      return {
        ...state,
        noteErrorMessage: action.payload,
      };

    default:
      return state;
  }
};
