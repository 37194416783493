import { put } from "redux-saga/effects";
import { DomainblockingAction } from "../action";
import { Client } from "../../../utils/transport";

export function* GetDomainblockingPolicyList(data) {
  let config = {
    method: "GET",
    url: "DOMAIN_BLOCKING_POLICY_PHASE_1",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        DomainblockingAction.Domain_blocking_Policy_List_Success(response.data)
      );
    } else {
      yield put(
        DomainblockingAction.Domain_blocking_Policy_List_Failed(response)
      );
    }
  } catch (error) {
    yield put(DomainblockingAction.Domain_blocking_Policy_List_Failed(error));
  }
}

export function* CreateDomainblockingPolicy(data) {
  let config = {
    method: "POST",
    url: "DOMAIN_BLOCKING_POLICY_PHASE_1",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.Bodydata,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        DomainblockingAction.Domain_blocking_Policy_Create_Success(
          response.data
        )
      );
    } else {
      yield put(
        DomainblockingAction.Domain_blocking_Policy_Create_Failed(response)
      );
    }
  } catch (err) {
    yield put(
      DomainblockingAction.Domain_blocking_Policy_Create_Failed(err.response)
    );
  }
}

export function* EditDomainblockingPolicy(data) {
  let config = {
    method: "PUT",
    url: "api/v1/domain_blocking_policies/" + data.data.id,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.Bodydata,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        DomainblockingAction.Domain_blocking_Policy_Edit_Success(response.data)
      );
    } else {
      yield put(
        DomainblockingAction.Domain_blocking_Policy_Edit_Failed(response)
      );
    }
  } catch (err) {
    yield put(
      DomainblockingAction.Domain_blocking_Policy_Edit_Failed(err.response)
    );
  }
}
