import { put } from "redux-saga/effects";
import { GenAIAction } from "../action";
import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";

export function* getResponseForGenAIQuerySaga(action) {
  const config = {
    method: "POST",
    url: `/genai/api/v1/simple_query`,
    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };
  try {
    const response = yield Client.request(config);

    yield put(
      GenAIAction.getResponseForGenAIQuerySuccess({
        data: response.data,
        question: action.payload.question,
      })
    );
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500 && err.response?.status !== 403) {
      errorMessage = err.response?.data?.error;
    }
    yield put(GenAIAction.getResponseForGenAIQueryFailed(errorMessage));
  }
}
