import React from "react";

export const Logout = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        d="M7.5,18.7H3.9c-0.7,0-1.3-0.3-1.8-0.7c-0.5-0.5-0.7-1.1-0.7-1.8V3.8c0-0.7,0.3-1.3,0.7-1.8s1.1-0.7,1.8-0.7h3.6
            c0.4,0,0.8,0.3,0.8,0.8S7.9,2.7,7.5,2.7H3.9C3.7,2.7,3.4,2.8,3.2,3S2.9,3.5,2.9,3.8v12.4c0,0.3,0.1,0.5,0.3,0.7
            c0.2,0.2,0.5,0.3,0.7,0.3h3.6c0.4,0,0.8,0.3,0.8,0.8S7.9,18.7,7.5,18.7z M13.7,15.2c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.8,0-1.1
            l3.2-3.2H7.5c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h8.9l-3.2-3.2c-0.3-0.3-0.3-0.8,0-1.1s0.8-0.3,1.1,0l4.4,4.4l0,0l0,0
            c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0
            l0,0c0,0,0,0,0,0.1c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
            c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0
            c0,0,0,0,0,0v0c0,0,0,0,0,0s0,0,0,0v0c0,0,0,0,0,0v0c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.2,0.1,0.3v0c0,0,0,0,0,0c0,0,0,0,0,0
            c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.2l0,0c0,0,0,0,0,0l0,0
            v0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0L14.2,15C14.1,15.1,13.9,15.2,13.7,15.2z"
      />
    </svg>
  );
};
