/* eslint-disable react/prop-types */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { UserOutlined } from "@ant-design/icons";
import { Typography, Tag, Avatar } from "antd";

import { getAvatarUrl } from "utils/transport";
import { useSelector } from "react-redux";
import { AuthContext } from "contexts/authContext";
const { Text } = Typography;

const Profileinfo = (props) => {
  const { coreApiUrl } = useContext(AuthContext);
  const { UserDetaildata } = props;
  const { user_map_data } = useSelector((state) => state.users);

  const users = user_map_data[UserDetaildata?.id];

  return (
    <div className="profileinfo">
      <div className="p_pic">
        <Avatar
          shape="square"
          size={64}
          icon={!users?.avatar_uri && <UserOutlined />}
          src={
            users?.avatar_uri === "@we360"
              ? getAvatarUrl(coreApiUrl, UserDetaildata?.id)
              : users?.avatar_uri
          }
        />
      </div>
      <div className="p_info">
        <div className="text-2xl font-bold">
          {UserDetaildata &&
            UserDetaildata.first_name + " " + UserDetaildata.last_name}
        </div>
        <Text
          style={{ width: "90%" }}
          ellipsis={{ tooltip: UserDetaildata && UserDetaildata.email }}
        >
          {/* allisongouse@gmail.com */}
          {UserDetaildata && UserDetaildata.email}
        </Text>
        {users?.employee_id && (
          <Tag color="magenta" className="mt-2">
            {users?.employee_id}
          </Tag>
        )}
        {/* <div className="flex item-center">
            <Tooltip title={"Role"}>
              <Tag color="processing" style={{ textAlign: "center" }}>
                Marketing
                {"-"}
              </Tag>
            </Tooltip>
            <Tooltip title={"Designation"}>
              <Tag color="success" style={{ textAlign: "center" }}>
                UI/Ux Desinger
                {"-"}
              </Tag>
            </Tooltip>
          </div> */}
      </div>
    </div>
  );
};

Profileinfo.propTypes = {
  showBackground: PropTypes.bool,
  silderBtn: PropTypes.func,
};

export default Profileinfo;
