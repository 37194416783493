/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Skeleton, Calendar, Badge, Tooltip, Empty } from "antd";
import dayjs from "dayjs";

const Cardskeleton = () => {
  return (
    <>
      <div className="charts">
        <div className="chart_header">
          <Skeleton.Input
            size={"small"}
            // shape={"default"}
            style={{ height: "15px" }}
          />
          <Skeleton.Avatar
            size={"small"}
            shape={"circle"}
            style={{ height: "15px", width: "15px" }}
          />
        </div>
        <div className="chartContainer chart_tool" id="chart">
          {/* chart */}
          <Skeleton.Avatar
            size={"large"}
            // shape={"default"}
            style={{ height: "300px", width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

const UserScoreMonthlyGoalTrend = (props) => {
  const { month } = props;
  const userscorecard = useSelector(
    (
      state // @ts-ignore
    ) => state.userscorecard
  );
  const [currentDate, setCurrentDate] = useState(dayjs(month).startOf("month"));
  useEffect(() => {
    if (month) {
      setCurrentDate(dayjs(month).startOf("month"));
    }
  }, [month]);
  const [loading, setLoading] = useState(true);
  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }
  const getListData = (value, filterDate) => {
    let listData = [];

    const dataFilter = value.find((itm) => {
      // Extract the date in "YYYY-MM-DD" format
      const itemDate = itm.punch_date.slice(0, 10);
      return itemDate === filterDate;
    });
    // try {
    if (dataFilter !== undefined) {
      listData.push({
        type: dataFilter.goals_percentage > 100 ? "achieved" : "missed",
      });
    } else {
      listData = [];
    }

    return listData || [];
  };

  const dateCellRender = (value) => {
    const datamap =
      userscorecard &&
      userscorecard.comparative_goal_user_performance_data &&
      userscorecard.comparative_goal_user_performance_data.length > 0 &&
      userscorecard.comparative_goal_user_performance_data;

    const listData = getListData(datamap, value.format("YYYY-MM-DD"));
    if (!listData || listData.length === 0) {
      return null;
    }

    const firstItem = listData[0];
    if (firstItem.type === "achieved" || firstItem.type === "missed") {
      return (
        <ul className="events d">
          {listData.map((item) => (
            <Tooltip
              title={item.type === "achieved" ? "achieved" : "missed"}
              key={item.content}
            >
              <li>
                <Badge status={item.type} />
              </li>
            </Tooltip>
          ))}
        </ul>
      );
    }
  };

  const disabledDate = (current) => {
    // Get the current date
    const today = new Date();
    // Set the time to midnight to compare only the date portion
    today.setHours(23, 59, 59, 0);

    // Disable dates if they are after today
    return current && current > today;
  };

  return (
    <>
      {userscorecard &&
      userscorecard.comparative_goal_user_performance_processing ? (
        <Cardskeleton />
      ) : (
        <div className="mt-5 mb-5">
          <div
            className="chartContainer scoreCalender userdetailcalender"
            style={{ marginTop: "-10px" }}
          >
            {userscorecard &&
            userscorecard.comparative_goal_user_performance_data &&
            userscorecard.comparative_goal_user_performance_data.length > 0 &&
            userscorecard.comparative_goal_user_performance_data ? (
              <Calendar
                fullscreen={false}
                dateCellRender={dateCellRender}
                disabledDate={disabledDate}
                value={currentDate}
              />
            ) : (
              <div style={{ height: "271px" }} className="emptyblock">
                <Empty description={false} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

UserScoreMonthlyGoalTrend.propTypes = {
  getList: PropTypes.func,
  month: PropTypes.string,
};

export default UserScoreMonthlyGoalTrend;
