import { put } from "redux-saga/effects";
import { ProjectsReportAction } from "../../action";
import { Client } from "../../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { ExportCSV } from "utils/export-csv";
import dayjs from "dayjs";
import { hms } from "utils/timeConverter";

const ExportData = (fileName, data, colunms, filterDate, teams) => {
  let result = [];
  const Generated = "Generated Date:-" + dayjs().format("DD-MM-YYYY hh:mm a");
  const generatedData = [
    fileName,
    "Teams: - " + teams.join(",").replaceAll(",", "/"),
    "Date:-" + filterDate,

    Generated,
    colunms.join(),
  ];

  data.forEach((record) => {
    const addColunmsValues = [
      record?.project_name,
      record?.team_name,
      record?.task_summary.replaceAll(",", " "),
      record?.task_description.replaceAll(",", " "),
      record?.project_owner,
      record?.assignee_name,
      record?.employee_id,
      record?.reporter_name,
      dayjs(record?.created_date).format("YYYY-MM-DD"),
      record?.start_date,
      record?.due_date,
      hms(record?.total_duration),
      record?.status_name,
    ];

    generatedData.push(addColunmsValues.join(","));
  });
  result = generatedData;
  return result;
};
const getDownloadCSV = (payload, data) => {
  const teamIds = payload.payload.body.group_id.split(",");
  const selectTeamNames = payload.payload.team_list_data
    .filter((item) => teamIds.includes(item.id))
    .map((item) => item.name);

  const csvdata = data?.data;
  const sortData = csvdata.sort((a, b) =>
    a.project_name.localeCompare(b.project_name)
  );

  const date =
    dayjs(payload?.payload.body.start_date).format("YYYY-MM-DD") +
    " To " +
    dayjs(payload?.payload.body.end_date).format("YYYY-MM-DD");

  const colunms = [
    "Project",
    "Team",
    "Summary",
    "Description",
    "Owner",
    "Assignee",
    "Assignee Employee ID",
    "Reporter",
    "Created Date",
    "Start Date",
    "Due Date",
    "Time Logged",
    "Status",
  ];
  const exdata = ExportData(
    "Project List",
    sortData,
    colunms,
    date,
    selectTeamNames
  );

  ExportCSV("Project_List_", exdata);
};
export function* fetchProjectsReportSaga(action) {
  const config = {
    method: "POST",
    url: `query/projects/project_api`,
    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);
    yield put(ProjectsReportAction.fetchProjectsReportSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(ProjectsReportAction.fetchProjectsReportFailed(errorMessage));
  }
}

export function* downloadProjectsReportSaga(action) {
  const config = {
    method: "POST",
    url: `query/projects/project_api`,
    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);
    getDownloadCSV(action, response.data);
    yield put(
      ProjectsReportAction.downloadProjectsReportSuccess(response.data)
    );
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(ProjectsReportAction.downloadProjectsReportFailed(errorMessage));
  }
}
