import { put } from "redux-saga/effects";
import { ShiftAction } from "../action";
import { Client } from "../../../utils/transport";

export function* GetShiftsData(data) {
  let config = {
    method: "GET",
    url: "SHIFT_PHASE_1",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    fetchPolicy: "no-cache",
  };

  try {
    var response = yield Client.request(config);

    if (response.data) {
      yield put(ShiftAction.ShiftsListSuccess(response.data));
    } else {
      yield put(ShiftAction.ShiftsListFailed(response));
    }
  } catch (err) {
    yield put(ShiftAction.ShiftsListFailed(err.response));
  }
}

//CREATE SHIFTS

export function* CreateShiftsData(data) {
  let config = {
    method: "POST",
    url: "SHIFT_PHASE_1",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.Bodydata,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);
    if (response.status === 200) {
      yield put(ShiftAction.ShiftsCreateSuccess(response.data));
    } else {
      yield put(ShiftAction.ShiftsCreateFailed(response));
    }
  } catch (error) {
    yield put(
      ShiftAction.ShiftsCreateFailed(error.response.data.errors[0].detail)
    );
  }
}

//EDIT SHIFTS

export function* EditShiftsData(data) {
  let config = {
    method: "PUT",
    // url: "SHIFT_PHASE_1",
    url: "api/v1/shifts/" + data.data.id,

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.Bodydata,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.data) {
      yield put(ShiftAction.ShiftsEditSuccess(response.data));
    } else {
      yield put(ShiftAction.ShiftsEditFailed(response));
    }
  } catch (err) {
    yield put(ShiftAction.ShiftsEditFailed(err.response));
  }
}
