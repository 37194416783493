import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import "../index.scss";

import { InfoCircleFilled } from "@ant-design/icons";
import Chart from "./../../chart";

import { Skeleton, Typography, Tooltip, Empty } from "antd";
import { getDonutChartOptions } from "utils/apexChartsUtil.tsx";

const { Title } = Typography;

const Cardskeleton = (props) => {
  return (
    <>
      <div className="charts">
        <div className="chart_header">
          <Skeleton.Input
            size={"small"}
            shape={"default"}
            style={{ height: "15px" }}
          />
          <Skeleton.Avatar
            size={"small"}
            shape={"circle"}
            style={{ height: "15px", width: "15px" }}
          />
        </div>
        <div className="chartContainer chart_tool" id="chart">
          {/* chart */}
          <Skeleton.Avatar
            size={"large"}
            shape={"default"}
            style={{ height: "300px", width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

const WellnessOverallScore = (props) => {
  const chartOptions = getDonutChartOptions({
    options: {
      labels: ["Healthy", "Overburdened", "Underutilized"],
      colors: ["#7B61FF", "#FFB800", "#D5D4DB"],
    },
    type: "NUMBER",
  });

  const { wellness360_me_overall_score_data } = useSelector(
    (state) => state.wellness360summary
  );

  const [appusages, setAppusages] = useState({
    series: [],
  });

  useEffect(() => {
    if (wellness360_me_overall_score_data) {
      const {
        healthy_emp_count,
        overburdened_emp_count,
        underutilized_emp_count,
      } = wellness360_me_overall_score_data;
      const isEmptyData =
        !healthy_emp_count &&
        !overburdened_emp_count &&
        !underutilized_emp_count;
      setEmptydata(isEmptyData);
      setAppusages({
        series: [
          healthy_emp_count,
          overburdened_emp_count,
          underutilized_emp_count,
        ],
      });
    }
  }, [wellness360_me_overall_score_data]);

  const [emptydata, setEmptydata] = useState(true);

  const [loading, setLoading] = useState(true);
  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const { title, tooltip } = props || {};

  return (
    <>
      {loading ? (
        <Cardskeleton />
      ) : (
        <div className="charts">
          <div className="chart_header">
            <Title level={2}>{title}</Title>
            <Tooltip title={tooltip}>
              <InfoCircleFilled />
            </Tooltip>
          </div>
          <div className="chartContainer chart_tool" id="chart">
            {/* chart */}
            {!emptydata ? (
              <Chart
                options={chartOptions.options}
                series={appusages.series}
                type="donut"
                height={400}
              />
            ) : (
              <div style={{ height: "371px" }} className="emptyblock">
                <Empty description={false} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

WellnessOverallScore.propTypes = {
  getList: PropTypes.func,
};

export default WellnessOverallScore;
