//* BREAK Start*/
export const APPLICATION_REPORT_GRAPH_INITIAL =
  "APPLICATION_REPORT_GRAPH_INITIAL";
export const APPLICATION_REPORT_GRAPH_PROCESS =
  "APPLICATION_REPORT_GRAPH_PROCESS";
export const APPLICATION_REPORT_GRAPH_SUCCESS =
  "APPLICATION_REPORT_GRAPH_SUCCESS";
export const APPLICATION_REPORT_GRAPH_FAILED =
  "APPLICATION_REPORT_GRAPH_FAILED";

export const APPLICATION_REPORT_LIST_INITIAL =
  "APPLICATION_REPORT_LIST_INITIAL";
export const APPLICATION_REPORT_LIST_PROCESS =
  "APPLICATION_REPORT_LIST_PROCESS";
export const APPLICATION_REPORT_LIST_SUCCESS =
  "APPLICATION_REPORT_LIST_SUCCESS";
export const APPLICATION_REPORT_LIST_FAILED = "APPLICATION_REPORT_LIST_FAILED";

export const APPLICATION_REPORT_EXPORT_LIST_INITIAL =
  "APPLICATION_REPORT_EXPORT_LIST_INITIAL";
export const APPLICATION_REPORT_EXPORT_LIST_PROCESS =
  "APPLICATION_REPORT_EXPORT_LIST_PROCESS";
export const APPLICATION_REPORT_EXPORT_LIST_SUCCESS =
  "APPLICATION_REPORT_EXPORT_LIST_SUCCESS";
export const APPLICATION_REPORT_EXPORT_LIST_FAILED =
  "APPLICATION_REPORT_EXPORT_LIST_FAILED";

//* BREAK End*/
