import Keycloak from "@utkarsh_zenstack/keycloak-js";

export const customKeyCloakConfig = (tenantId, authEndpoint, keycloakRealm) => {
  const keycloakConfigCustom = new Keycloak({
    clientId: tenantId,

    url: authEndpoint || process.env.REACT_APP_AUTH_ENDPOINT,
    realm: keycloakRealm || process.env.REACT_APP_AUTH_REALM,
  });
  return keycloakConfigCustom;
};
