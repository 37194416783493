import * as actionType from "./actionType";

export const GenerateStealthKey = (payload) => {
  return {
    type: actionType.GENERATE_STEALTH_KEY,
    payload,
  };
};

export const RoleCreateInitial = () => {
  return {
    type: actionType.ROLE_CREATE_INITIAL,
  };
};

export const RoleCreateProcess = (data) => {
  return {
    type: actionType.ROLE_CREATE_PROCESS,
    data,
  };
};

export const RoleCreateSuccess = (data) => {
  return {
    type: actionType.ROLE_CREATE_SUCCESS,
    data,
  };
};

export const RoleCreateFailed = (data) => {
  return {
    type: actionType.ROLE_CREATE_FAILED,
    data,
  };
};

export const RoleListInitial = () => {
  return {
    type: actionType.ROLE_LIST_INITIAL,
  };
};

export const RoleListProcess = (data) => {
  return {
    type: actionType.ROLE_LIST_PROCESS,
    data,
  };
};

export const RoleListSuccess = (data) => {
  return {
    type: actionType.ROLE_LIST_SUCCESS,
    data,
  };
};

export const RoleListFailed = (data) => {
  return {
    type: actionType.ROLE_LIST_FAILED,
    data,
  };
};

export const RoleUpdateInitial = () => {
  return {
    type: actionType.ROLE_UPDATE_INITIAL,
  };
};

export const RoleUpdateProcess = (data) => {
  return {
    type: actionType.ROLE_UPDATE_PROCESS,
    data,
  };
};

export const RoleUpdateSuccess = (data) => {
  return {
    type: actionType.ROLE_UPDATE_SUCCESS,
    data,
  };
};

export const RoleUpdateFailed = (data) => {
  return {
    type: actionType.ROLE_UPDATE_FAILED,
    data,
  };
};
export const RoleListSearch = (data) => {
  return {
    type: actionType.ROLE_LIST_SEARCH,
    data,
  };
};

export const userRoleSearchListInitial = () => {
  return {
    type: actionType.USER_ROLE_SEARCH_LIST_INITIAL,
  };
};

export const userRoleSearchListProcess = (data) => {
  return {
    type: actionType.USER_ROLE_SEARCH_LIST_PROCESS,
    data,
  };
};

export const userRoleSearchListSuccess = (data) => {
  return {
    type: actionType.USER_ROLE_SEARCH_LIST_SUCCESS,
    data,
  };
};

export const userRoleSearchListFailed = (data) => {
  return {
    type: actionType.USER_ROLE_SEARCH_LIST_FAILED,
    data,
  };
};
