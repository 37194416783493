import { put } from "redux-saga/effects";
import {
  ReportMonthlyInOutDownloadFailed,
  ReportMonthlyInOutDownloadSuccess,
} from "./action";
import { Client } from "../../../../utils/transport";
import { ReportMonthlyInOutFailed, ReportMonthlyInOutSuccess } from "./action";

//REPORT MONTHLY IN OUT
// export function* ReportMonthlyInOut(data) {
//   try {
//     let config = {
//       method: "GET",
//       url:
//         "query/reports/monthly_inout_attendance?start_date=" +
//         data.data.start_date +
//         "&end_date=" +
//         data.data.end_date +
//         "&user_id" +
//         data.data.user_id +
//         "&group_id=" +
//         data.data.group_id +
//         "&report=true",
//       headers: {
//         // Accept: "application/json",
//         "Content-Type": "application/json",
//         Authorization: `Bearer ` + data.data.token,
//         "X-Tenant-Id": data.data.clientId,
//         "X-Schema-Name": data.data.schemaname,
//         "X-Identity-Id": data.data.identityid,
//       },
//       fetchPolicy: "no-cache",
//     };

//     var response = yield Client.request(config);
//     if (response.status === 200) {
//       yield put(ReportMonthlyInOutSuccess(response.data.data));
//     } else {
//       yield put(ReportMonthlyInOutFailed(response));
//     }
//   } catch (err) {
//     console.log("Error in saga5", err);
//     yield put(ReportMonthlyInOutFailed(err));
//   }
// }

export function* ReportMonthlyInOut(data) {
  let config = {
    method: "POST",
    url: "query/reports/monthly_inout_attendance",

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };

  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(ReportMonthlyInOutSuccess(response.data));
    } else {
      yield put(ReportMonthlyInOutFailed(response));
    }
  } catch (err) {
    yield put(ReportMonthlyInOutFailed(err));
  }
}

export function* DownloadReportMonthlyInOut(data) {
  let config = {
    method: "POST",
    url: "query/reports/monthly_inout_attendance",

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };

  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(ReportMonthlyInOutDownloadSuccess(response.data));
    } else {
      yield put(ReportMonthlyInOutDownloadFailed(response));
    }
  } catch (err) {
    yield put(ReportMonthlyInOutDownloadFailed(err));
  }
}
