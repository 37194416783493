// updating offline time calculation to accomodate break time. offline time = working time - online time - break time
function offlineValue(punchDuration, onlineTime, breakTime = 0) {
  const isEmpty = (value) => !value;

  if (isEmpty(punchDuration) && isEmpty(onlineTime) && isEmpty(breakTime)) {
    return 0;
  } else if (isEmpty(punchDuration)) {
    return 0;
  } else if (isEmpty(onlineTime)) {
    if (isEmpty(breakTime)) {
      return punchDuration - breakTime;
    } else {
      return punchDuration;
    }
  } else {
    return punchDuration - (onlineTime + breakTime);
  }
}

export default offlineValue;
