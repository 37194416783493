import * as actionTypes from "./actionTypes";
import _ from "lodash";

const initialState = {
  //*GLOBAL*//

  //* ATTENDANCE SUMMARY WEDIGT *//

  attendance_summary_wedigt_processing: false,
  attendance_summary_wedigt_success: false,
  attendance_summary_wedigt_failed: false,
  attendance_summary_wedigt_data: [],
  attendance_summary_wedigt_message: "",

  //* ATTENDANCE SUMMARY WORKING TIME//
  attendance_summary_working_time_processing: false,
  attendance_summary_working_time_success: false,
  attendance_summary_working_time_failed: false,
  attendance_summary_working_time_data: [],
  attendance_summary_working_time_message: "",

  //* ATTENDANCE SUMMARY WORKING TIME LESS //
  attendance_summary_working_time_less_processing: false,
  attendance_summary_working_time_less_success: false,
  attendance_summary_working_time_less_failed: false,
  attendance_summary_working_time_less_data: [],
  attendance_summary_working_time_less_message: "",

  //* ATTENDANCE SUMMARY TODAY WEDIGT *//

  attendance_summary_today_processing: false,
  attendance_summary_today_success: false,
  attendance_summary_today_failed: false,
  attendance_summary_today_data: [],
  attendance_summary_today_message: "",

  //* ATTENDANCE SUMMARY ATTENDANCE TRENDS  *//

  attendance_summary_attendance_trend_processing: false,
  attendance_summary_attendance_trend_success: false,
  attendance_summary_attendance_trend_failed: false,
  attendance_summary_attendance_trend_data: [],
  attendance_summary_attendance_trend_message: "",

  attendance_summary_attendance_trend_less_processing: false,
  attendance_summary_attendance_trend_less_success: false,
  attendance_summary_attendance_trend_less_failed: false,
  attendance_summary_attendance_trend_less_data: [],
  attendance_summary_attendance_trend_less_message: "",

  attendance_summary_attendance_trend_current_processing: false,
  attendance_summary_attendance_trend_current_success: false,
  attendance_summary_attendance_trend_current_failed: false,
  attendance_summary_attendance_trend_current_data: [],
  attendance_summary_attendance_trend_current_message: "",

  //* ATTENDANCE SUMMARY BREAK TRENDS  *//

  attendance_summary_break_trend_processing: true,
  attendance_summary_break_trend_success: false,
  attendance_summary_break_trend_failed: false,
  attendance_summary_break_trend_data: [],
  attendance_summary_break_trend_message: "",

  //* ATTENDANCE SUMMARY BREAK TRENDS LESS *//

  attendance_summary_break_trend_less_processing: true,
  attendance_summary_break_trend_less_success: false,
  attendance_summary_break_trend_less_failed: false,
  attendance_summary_break_trend_less_data: [],
  attendance_summary_break_trend_less_message: "",

  //* ATTENDANCE SUMMARY LATE ARRIVAL  TENDENCY *//

  attendance_summary_late_arrival_tendency_processing: true,
  attendance_summary_late_arrival_tendency_success: false,
  attendance_summary_late_arrival_tendency_failed: false,
  attendance_summary_late_arrival_tendency_data: [],
  attendance_summary_late_arrival_tendency_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.ATTENDANCE_SUMMARY_WEDIGT_INITIAL:
      return {
        ...state,
        attendance_summary_wedigt_processing: true,
        attendance_summary_wedigt_success: false,
        attendance_summary_wedigt_failed: false,
        attendance_summary_wedigt_data: [],
        attendance_summary_wedigt_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_WEDIGT_PROCESS:
      return {
        ...state,
        attendance_summary_wedigt_processing: true,
        attendance_summary_wedigt_success: false,
        attendance_summary_wedigt_failed: false,
        attendance_summary_wedigt_data: [],
        attendance_summary_wedigt_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_WEDIGT_SUCCESS:
      return {
        ...state,
        attendance_summary_wedigt_processing: false,
        attendance_summary_wedigt_success: true,
        attendance_summary_wedigt_failed: false,
        attendance_summary_wedigt_data: action.data,
        attendance_summary_wedigt_message: "",
      };
    case actionTypes.ATTENDANCE_SUMMARY_WEDIGT_FAILED:
      return {
        ...state,
        attendance_summary_wedigt_processing: false,
        attendance_summary_wedigt_success: false,
        attendance_summary_wedigt_failed: true,
        attendance_summary_wedigt_data: action.data,
        attendance_summary_wedigt_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_TODAY_INITIAL:
      return {
        ...state,
        attendance_summary_today_processing: false,
        attendance_summary_today_success: false,
        attendance_summary_today_failed: false,
        attendance_summary_today_data: [],
        attendance_summary_today_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_TODAY_PROCESS:
      return {
        ...state,
        attendance_summary_today_processing: true,
        attendance_summary_today_success: false,
        attendance_summary_today_failed: false,
        attendance_summary_today_data: [],
        attendance_summary_today_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_TODAY_SUCCESS:
      return {
        ...state,
        attendance_summary_today_processing: false,
        attendance_summary_today_success: true,
        attendance_summary_today_failed: false,
        attendance_summary_today_data: action.data,
        attendance_summary_today_message: "",
      };
    case actionTypes.ATTENDANCE_SUMMARY_TODAY_FAILED:
      return {
        ...state,
        attendance_summary_today_processing: false,
        attendance_summary_today_success: false,
        attendance_summary_today_failed: true,
        attendance_summary_today_data: action.data,
        attendance_summary_today_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_INITIAL:
      return {
        ...state,
        attendance_summary_attendance_trend_processing: false,
        attendance_summary_attendance_trend_success: false,
        attendance_summary_attendance_trend_failed: false,
        attendance_summary_attendance_trend_data: [],
        attendance_summary_attendance_trend_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_PROCESS:
      return {
        ...state,
        attendance_summary_attendance_trend_processing: true,
        attendance_summary_attendance_trend_success: false,
        attendance_summary_attendance_trend_failed: false,
        attendance_summary_attendance_trend_data: action.data,
        attendance_summary_attendance_trend_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_SUCCESS:
      return {
        ...state,
        attendance_summary_attendance_trend_processing: false,
        attendance_summary_attendance_trend_success: true,
        attendance_summary_attendance_trend_failed: false,
        attendance_summary_attendance_trend_data: action.data,
        attendance_summary_attendance_trend_message: "",
      };
    case actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_FAILED:
      return {
        ...state,
        attendance_summary_attendance_trend_processing: false,
        attendance_summary_attendance_trend_success: false,
        attendance_summary_attendance_trend_failed: true,
        attendance_summary_attendance_trend_data: [],
        attendance_summary_attendance_trend_message: action.data,
      };
    case actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_LESS_INITIAL:
      return {
        ...state,
        attendance_summary_attendance_trend_less_processing: false,
        attendance_summary_attendance_trend_less_success: false,
        attendance_summary_attendance_trend_less_failed: false,
        attendance_summary_attendance_trend_less_data: [],
        attendance_summary_attendance_trend_less_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_LESS_PROCESS:
      return {
        ...state,
        attendance_summary_attendance_trend_less_processing: true,
        attendance_summary_attendance_trend_less_success: false,
        attendance_summary_attendance_trend_less_failed: false,
        attendance_summary_attendance_trend_less_data: action.data,
        attendance_summary_attendance_trend_less_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_LESS_SUCCESS:
      return {
        ...state,
        attendance_summary_attendance_trend_less_processing: false,
        attendance_summary_attendance_trend_less_success: true,
        attendance_summary_attendance_trend_less_failed: false,
        attendance_summary_attendance_trend_less_data: action.data,
        attendance_summary_attendance_trend_less_message: "",
      };
    case actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_LESS_FAILED:
      return {
        ...state,
        attendance_summary_attendance_trend_less_processing: false,
        attendance_summary_attendance_trend_less_success: false,
        attendance_summary_attendance_trend_less_failed: true,
        attendance_summary_attendance_trend_less_data: [],
        attendance_summary_attendance_trend_less_message: action.data,
      };

    case actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_CURRENT_INITIAL:
      return {
        ...state,
        attendance_summary_attendance_trend_current_processing: false,
        attendance_summary_attendance_trend_current_success: false,
        attendance_summary_attendance_trend_current_failed: false,
        attendance_summary_attendance_trend_current_data: [],
        attendance_summary_attendance_trend_current_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_CURRENT_PROCESS:
      return {
        ...state,
        attendance_summary_attendance_trend_current_processing: true,
        attendance_summary_attendance_trend_current_success: false,
        attendance_summary_attendance_trend_current_failed: false,
        attendance_summary_attendance_trend_current_data: action.data,
        attendance_summary_attendance_trend_current_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_CURRENT_SUCCESS:
      return {
        ...state,
        attendance_summary_attendance_trend_current_processing: false,
        attendance_summary_attendance_trend_current_success: true,
        attendance_summary_attendance_trend_current_failed: false,
        attendance_summary_attendance_trend_current_data: action.data,
        attendance_summary_attendance_trend_current_message: "",
      };
    case actionTypes.ATTENDANCE_SUMMARY_ATTENDANCE_TREND_CURRENT_FAILED:
      return {
        ...state,
        attendance_summary_attendance_trend_current_processing: false,
        attendance_summary_attendance_trend_current_success: false,
        attendance_summary_attendance_trend_current_failed: true,
        attendance_summary_attendance_trend_current_data: [],
        attendance_summary_attendance_trend_current_message: action.data,
      };
    case actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_INITIAL:
      return {
        ...state,
        attendance_summary_break_trend_processing: true,
        attendance_summary_break_trend_success: false,
        attendance_summary_break_trend_failed: false,
        attendance_summary_break_trend_data: [],
        attendance_summary_break_trend_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_PROCESS:
      return {
        ...state,
        attendance_summary_break_trend_processing: true,
        attendance_summary_break_trend_success: false,
        attendance_summary_break_trend_failed: false,
        attendance_summary_break_trend_data: [],
        attendance_summary_break_trend_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_SUCCESS:
      return {
        ...state,
        attendance_summary_break_trend_processing: false,
        attendance_summary_break_trend_success: true,
        attendance_summary_break_trend_failed: false,
        attendance_summary_break_trend_data: action.data,
        attendance_summary_break_trend_message: "",
      };
    case actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_FAILED:
      return {
        ...state,
        attendance_summary_break_trend_processing: false,
        attendance_summary_break_trend_success: false,
        attendance_summary_break_trend_failed: true,
        attendance_summary_break_trend_data: [],
        attendance_summary_break_trend_message: action.data,
      };

    case actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_LESS_INITIAL:
      return {
        ...state,
        attendance_summary_break_trend_less_processing: false,
        attendance_summary_break_trend_less_success: false,
        attendance_summary_break_trend_less_failed: false,
        attendance_summary_break_trend_less_data: [],
        attendance_summary_break_trend_less_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_LESS_PROCESS:
      return {
        ...state,
        attendance_summary_break_trend_less_processing: true,
        attendance_summary_break_trend_less_success: false,
        attendance_summary_break_trend_less_failed: false,
        attendance_summary_break_trend_less_data: [],
        attendance_summary_break_trend_less_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_LESS_SUCCESS:
      return {
        ...state,
        attendance_summary_break_trend_less_processing: false,
        attendance_summary_break_trend_less_success: true,
        attendance_summary_break_trend_less_failed: false,
        attendance_summary_break_trend_less_data: action.data,
        attendance_summary_break_trend_less_message: "",
      };
    case actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_LESS_FAILED:
      return {
        ...state,
        attendance_summary_break_trend_less_processing: false,
        attendance_summary_break_trend_less_success: false,
        attendance_summary_break_trend_less_failed: true,
        attendance_summary_break_trend_less_data: [],
        attendance_summary_break_trend_less_message: action.data,
      };

    case actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_DATE_WISE_INITIAL:
      return {
        ...state,
        attendance_summary_break_trend_date_wise_processing: false,
        attendance_summary_break_trend_date_wise_success: false,
        attendance_summary_break_trend_date_wise_failed: false,
        attendance_summary_break_trend_date_wise_data: [],
        attendance_summary_break_trend_date_wise_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_DATE_WISE_PROCESS:
      return {
        ...state,
        attendance_summary_break_trend_date_wise_processing: true,
        attendance_summary_break_trend_date_wise_success: false,
        attendance_summary_break_trend_date_wise_failed: false,
        attendance_summary_break_trend_date_wise_data: [],
        attendance_summary_break_trend_date_wise_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_DATE_WISE_SUCCESS:
      return {
        ...state,
        attendance_summary_break_trend_date_wise_processing: false,
        attendance_summary_break_trend_date_wise_success: true,
        attendance_summary_break_trend_date_wise_failed: false,
        attendance_summary_break_trend_date_wise_data: action.data,
        attendance_summary_break_trend_date_wise_message: "",
      };
    case actionTypes.ATTENDANCE_SUMMARY_BREAK_TREND_DATE_WISE_FAILED:
      return {
        ...state,
        attendance_summary_break_trend_date_wise_processing: false,
        attendance_summary_break_trend_date_wise_success: false,
        attendance_summary_break_trend_date_wise_failed: true,
        attendance_summary_break_trend_date_wise_data: [],
        attendance_summary_break_trend_date_wise_message: action.data,
      };

    case actionTypes.ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_INITIAL:
      return {
        ...state,
        attendance_summary_late_arrival_tendency_processing: false,
        attendance_summary_late_arrival_tendency_success: false,
        attendance_summary_late_arrival_tendency_failed: false,
        attendance_summary_late_arrival_tendency_data: [],
        attendance_summary_late_arrival_tendency_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_PROCESS:
      return {
        ...state,
        attendance_summary_late_arrival_tendency_processing: true,
        attendance_summary_late_arrival_tendency_success: false,
        attendance_summary_late_arrival_tendency_failed: false,
        attendance_summary_late_arrival_tendency_data: [],
        attendance_summary_late_arrival_tendency_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_SUCCESS:
      return {
        ...state,
        attendance_summary_late_arrival_tendency_processing: false,
        attendance_summary_late_arrival_tendency_success: true,
        attendance_summary_late_arrival_tendency_failed: false,
        attendance_summary_late_arrival_tendency_data: action.data,
        attendance_summary_late_arrival_tendency_message: "",
      };
    case actionTypes.ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_FAILED:
      return {
        ...state,
        attendance_summary_late_arrival_tendency_processing: false,
        attendance_summary_late_arrival_tendency_success: false,
        attendance_summary_late_arrival_tendency_failed: true,
        attendance_summary_late_arrival_tendency_data: [],
        attendance_summary_late_arrival_tendency_message: action.data,
      };

    case actionTypes.ATTENDANCE_SUMMARY_WORKING_TIME_INITIAL:
      return {
        ...state,
        attendance_summary_working_time_processing: false,
        attendance_summary_working_time_success: false,
        attendance_summary_working_time_failed: false,
        attendance_summary_working_time_data: [],
        attendance_summary_working_time_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_WORKING_TIME_PROCESS:
      return {
        ...state,
        attendance_summary_working_time_processing: true,
        attendance_summary_working_time_success: false,
        attendance_summary_working_time_failed: false,
        attendance_summary_working_time_data: [],
        attendance_summary_working_time_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_WORKING_TIME_SUCCESS:
      return {
        ...state,
        attendance_summary_working_time_processing: false,
        attendance_summary_working_time_success: true,
        attendance_summary_working_time_failed: false,
        attendance_summary_working_time_data: action.data,
        attendance_summary_working_time_message: "",
      };
    case actionTypes.ATTENDANCE_SUMMARY_WORKING_TIME_FAILED:
      return {
        ...state,
        attendance_summary_working_time_processing: false,
        attendance_summary_working_time_success: false,
        attendance_summary_working_time_failed: true,
        attendance_summary_working_time_data: action.data,
        attendance_summary_working_time_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_WORKING_TIME_LESS_INITIAL:
      return {
        ...state,
        attendance_summary_working_time_less_processing: false,
        attendance_summary_working_time_less_success: false,
        attendance_summary_working_time_less_failed: false,
        attendance_summary_working_time_less_data: [],
        attendance_summary_working_time_less_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_WORKING_TIME_LESS_PROCESS:
      return {
        ...state,
        attendance_summary_working_time_less_processing: true,
        attendance_summary_working_time_less_success: false,
        attendance_summary_working_time_less_failed: false,
        attendance_summary_working_time_less_data: [],
        attendance_summary_working_time_less_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_WORKING_TIME_LESS_SUCCESS:
      return {
        ...state,
        attendance_summary_working_time_less_processing: false,
        attendance_summary_working_time_less_success: true,
        attendance_summary_working_time_less_failed: false,
        attendance_summary_working_time_less_data: action.data,
        attendance_summary_working_time_less_message: "",
      };
    case actionTypes.ATTENDANCE_SUMMARY_WORKING_TIME_LESS_FAILED:
      return {
        ...state,
        attendance_summary_working_time_less_processing: false,
        attendance_summary_working_time_less_success: false,
        attendance_summary_working_time_less_failed: true,
        attendance_summary_working_time_less_data: action.data,
        attendance_summary_working_time_less_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_LESS_INITIAL:
      return {
        ...state,
        attendance_summary_late_arrival_tendency_less_processing: false,
        attendance_summary_late_arrival_tendency_less_success: false,
        attendance_summary_late_arrival_tendency_less_failed: false,
        attendance_summary_late_arrival_tendency_less_data: [],
        attendance_summary_late_arrival_tendency_less_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_LESS_PROCESS:
      return {
        ...state,
        attendance_summary_late_arrival_tendency_less_processing: true,
        attendance_summary_late_arrival_tendency_less_success: false,
        attendance_summary_late_arrival_tendency_less_failed: false,
        attendance_summary_late_arrival_tendency_less_data: [],
        attendance_summary_late_arrival_tendency_less_message: "",
      };

    case actionTypes.ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_LESS_SUCCESS:
      return {
        ...state,
        attendance_summary_late_arrival_tendency_less_processing: false,
        attendance_summary_late_arrival_tendency_less_success: true,
        attendance_summary_late_arrival_tendency_less_failed: false,
        attendance_summary_late_arrival_tendency_less_data: action.data,
        attendance_summary_late_arrival_tendency_less_message: "",
      };
    case actionTypes.ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_LESS_FAILED:
      return {
        ...state,
        attendance_summary_late_arrival_tendency_less_processing: false,
        attendance_summary_late_arrival_tendency_less_success: false,
        attendance_summary_late_arrival_tendency_less_failed: true,
        attendance_summary_late_arrival_tendency_less_data: [],
        attendance_summary_late_arrival_tendency_less_message: action.data,
      };

    default:
      return state;
  }
};
