import * as actionTypes from "./actionType";
import _ from "lodash";

const initialState = {
  //*GLOBAL*//

  /** REPORT MONTHLY ATTENDENCE  */

  report_monthly_attendance_processing: false,
  report_monthly_attendance_success: false,
  report_monthly_attendance_failed: false,
  report_monthly_attendance_data: [],
  report_monthly_attendance_message: "",

  /** REPORT DOWNLOAD MONTHLY ATTENDENCE  */

  report_download_monthly_attendance_processing: false,
  report_download_monthly_attendance_success: false,
  report_download_monthly_attendance_failed: false,
  report_download_monthly_attendance_data: [],
  report_download_monthly_attendance_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    //* REPORT MONTHLY IN OUT

    case actionTypes.REPORT_MONTHLY_ATTENDANCE_INITIAL:
      return {
        ...state,
        report_monthly_attendance_processing: false,
        report_monthly_attendance_success: false,
        report_monthly_attendance_failed: false,
        report_monthly_attendance_data: [],
        report_monthly_attendance_message: "",
      };

    case actionTypes.REPORT_MONTHLY_ATTENDANCE_PROCESS:
      return {
        ...state,
        report_monthly_attendance_processing: true,
        report_monthly_attendance_success: false,
        report_monthly_attendance_failed: false,
        report_monthly_attendance_data: [],
        report_monthly_attendance_message: "",
      };

    case actionTypes.REPORT_MONTHLY_ATTENDANCE_SUCCESS:
      return {
        ...state,
        report_monthly_attendance_processing: false,
        report_monthly_attendance_success: true,
        report_monthly_attendance_failed: false,
        report_monthly_attendance_data: action.data,
        report_monthly_attendance_message: "",
      };

    case actionTypes.REPORT_MONTHLY_ATTENDANCE_FAILED:
      return {
        ...state,
        report_monthly_attendance_processing: false,
        report_monthly_attendance_success: false,
        report_monthly_attendance_failed: true,
        report_monthly_attendance_data: [],
        report_monthly_attendance_message: action.err,
      };

    case actionTypes.REPORT_DOWNLOAD_MONTHLY_ATTENDANCE_INITIAL:
      return {
        ...state,
        report_download_monthly_attendance_processing: false,
        report_download_monthly_attendance_success: false,
        report_download_monthly_attendance_failed: false,
        report_download_monthly_attendance_data: [],
        report_download_monthly_attendance_message: "",
      };

    case actionTypes.REPORT_DOWNLOAD_MONTHLY_ATTENDANCE_PROCESS:
      return {
        ...state,
        report_download_monthly_attendance_processing: true,
        report_download_monthly_attendance_success: false,
        report_download_monthly_attendance_failed: false,
        report_download_monthly_attendance_data: [],
        report_download_monthly_attendance_message: "",
      };

    case actionTypes.REPORT_DOWNLOAD_MONTHLY_ATTENDANCE_SUCCESS:
      return {
        ...state,
        report_download_monthly_attendance_processing: false,
        report_download_monthly_attendance_success: true,
        report_download_monthly_attendance_failed: false,
        report_download_monthly_attendance_data: action.data,
        report_download_monthly_attendance_message: "",
      };

    case actionTypes.REPORT_DOWNLOAD_MONTHLY_ATTENDANCE_FAILED:
      return {
        ...state,
        report_download_monthly_attendance_processing: false,
        report_downoad_monthly_attendance_success: false,
        report_download_monthly_attendance_failed: true,
        report_download_monthly_attendance_data: [],
        report_download_monthly_attendance_message: action.err,
      };

    default:
      return state;
  }
};
