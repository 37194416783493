import * as actionTypes from "./actionTypes";

//* 25-04-22 Sarita *//
//* APPLICATION URL LIST *//

export const AppilcationUrlListInitial = () => {
  return {
    type: actionTypes.APPLICATION_URL_LIST_INITIAL,
  };
};
export const AppilcationUrlListProcess = (data) => {
  return {
    type: actionTypes.APPLICATION_URL_LIST_PROCESS,
    data,
  };
};
export const AppilcationUrlListSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_URL_LIST_SUCCESS,
    data,
  };
};
export const AppilcationUrlListFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_URL_LIST_FAILED,
    data,
  };
};

//* APPLICATION POLICY_MAPPING LIST *//

export const AppilcationPolicyMappingListInitial = () => {
  return {
    type: actionTypes.APPLICATION_POLICY_MAPPING_LIST_INITIAL,
  };
};
export const AppilcationPolicyMappingListProcess = (data) => {
  return {
    type: actionTypes.APPLICATION_POLICY_MAPPING_LIST_PROCESS,
    data,
  };
};
export const AppilcationPolicyMappingListSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_POLICY_MAPPING_LIST_SUCCESS,
    data,
  };
};
export const AppilcationPolicyMappingListFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_POLICY_MAPPING_LIST_FAILED,
    data,
  };
};

//* APPLICATION URL EDIT *//
export const ApplicationUrlEditInitial = () => {
  return {
    type: actionTypes.APPLICATION_URL_EDIT_INITIAL,
  };
};
export const ApplicationUrlSearch = (data) => {
  return {
    type: actionTypes.APPLICATION_URL_SEARCH,
    data,
  };
};
export const ApplicationUrlEditProcess = (data) => {
  return {
    type: actionTypes.APPLICATION_URL_EDIT_PROCESS,
    data,
  };
};
export const ApplicationUrlEditSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_URL_EDIT_SUCCESS,
    data,
  };
};
export const ApplicationUrlEditFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_URL_EDIT_FAILED,
    data,
  };
};

//* APPLICATION POLICY MAPPING CREATE *//

export const ApplicationPolicyMappingCreateInitial = () => {
  return {
    type: actionTypes.APPLICATION_POLICY_MAPPING_CREATE_INITIAL,
  };
};
export const ApplicationPolicyMappingCreateProcess = (data) => {
  return {
    type: actionTypes.APPLICATION_POLICY_MAPPING_CREATE_PROCESS,
    data,
  };
};
export const ApplicationPolicyMappingCreateSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_POLICY_MAPPING_CREATE_SUCCESS,
    data,
  };
};
export const ApplicationPolicyMappingCreateFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_POLICY_MAPPING_CREATE_FAILED,
    data,
  };
};

//* APPLICATION POLICY MAPPING EDIT *//

export const ApplicationPolicyMappingEditInitial = () => {
  return {
    type: actionTypes.APPLICATION_POLICY_MAPPING_EDIT_INITIAL,
  };
};
export const ApplicationPolicyMappingEditProcess = (data) => {
  return {
    type: actionTypes.APPLICATION_POLICY_MAPPING_EDIT_PROCESS,
    data,
  };
};
export const ApplicationPolicyMappingEditSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_POLICY_MAPPING_EDIT_SUCCESS,
    data,
  };
};
export const ApplicationPolicyMappingEditFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_POLICY_MAPPING_EDIT_FAILED,
    data,
  };
};
//* APPLICATION CATEGORY LIST *//

export const AppilcationCategoryListInitial = () => {
  return {
    type: actionTypes.APPLICATION_CATEGORY_LIST_INITIAL,
  };
};
export const AppilcationCategoryListProcess = (data) => {
  return {
    type: actionTypes.APPLICATION_CATEGORY_LIST_PROCESS,
    data,
  };
};
export const AppilcationCategoryListSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_CATEGORY_LIST_SUCCESS,
    data,
  };
};
export const AppilcationCategoryListFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_CATEGORY_LIST_FAILED,
    data,
  };
};

//*APPLICATION POLICY SEARCH *//

export const AppilcationPolicySearchListInitial = () => {
  return {
    type: actionTypes.APPLICATION_POLICY_SEARCH_INITIAL,
  };
};
export const AppilcationPolicySearchListProcess = (data) => {
  return {
    type: actionTypes.APPLICATION_POLICY_SEARCH_PROCESS,
    data,
  };
};
export const AppilcationPolicySearchListSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_POLICY_SEARCH_SUCCESS,
    data,
  };
};
export const AppilcationPolicySearchListFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_POLICY_SEARCH_FAILED,
    data,
  };
};

//*APPLICATION DOWNLOAD *//

export const ApplicationMappingDownloadInitial = () => {
  return {
    type: actionTypes.APPLICATION_MAPPING_DOWNLOAD_INITIAL,
  };
};
export const ApplicationMappingDownloadProcess = (data) => {
  return {
    type: actionTypes.APPLICATION_MAPPING_DOWNLOAD_PROCESS,
    data,
  };
};
export const ApplicationMappingDownloadSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_MAPPING_DOWNLOAD_SUCCESS,
    data,
  };
};
export const ApplicationMappingDownloadFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_MAPPING_DOWNLOAD_FAILED,
    data,
  };
};
