import React, { useState, useEffect } from "react";
import { hmsOnlyNumber } from "utils/timeConverter";

interface Props {
  initialSeconds: number;
}

const Timer: React.FC<Props> = ({ initialSeconds }) => {
  const [seconds, setSeconds] = useState(initialSeconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return <div>{hmsOnlyNumber(seconds)}</div>;
};

export default Timer;
