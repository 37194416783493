import * as actionTypes from "./actionTypes";
const initialPagination = { optional: 0 };
const initialMonthlyPagination = { total_records :0};
const initialState = {
  breaks_report_processing: false,
  breaks_report_success: false,
  breaks_report_failed: false,
  breaks_report_data: [],
  breaks_report_message: "",
  breakReportMaster: [],
  pagination: initialPagination,
  monthlyPagination: initialMonthlyPagination,

  breaks_download_report_processing: false,
  breaks_download_report_success: false,
  breaks_download_report_failed: false,
  breaks_download_report_data: [],
  breaks_download_report_message: "",

  //MONTHLY BREAK REPORT
  monthly_breaks_report_processing: false,
  monthly_breaks_report_success: false,
  monthly_breaks_report_failed: false,
  monthly_breaks_report_data: [],
  monthly_breaks_report_message: "",
  pagination: initialPagination,

  monthly_breaks_download_report_processing: false,
  monthly_breaks_download_report_success: false,
  monthly_breaks_download_report_failed: false,
  monthly_breaks_download_report_data: [],
  monthly_breaks_download_report_message: "",
  monthlyPagination: initialMonthlyPagination,
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.BREAKS_REPORT_INITIAL:
      return {
        ...state,
        breaks_report_processing: false,
        breaks_report_success: false,
        breaks_report_failed: false,
        breaks_report_data: [],
        breaks_report_message: "",
        breakReportMaster: [],
      };
    case actionTypes.BREAKS_REPORT_PROCESS:
      return {
        ...state,
        breaks_report_processing: true,
        breaks_report_success: false,
        breaks_report_failed: false,
        breaks_report_data: [],
        breaks_report_message: "",
        breakReportMaster: [],
      };
    case actionTypes.BREAKS_REPORT_SUCCESS:
      return {
        ...state,
        breaks_report_processing: false,
        breaks_report_success: true,
        breaks_report_failed: false,
        breaks_report_data: action.data.data,
        breaks_report_message: "",
        breakReportMaster: action.data,
        pagination: action.data || state.pagination,
      };
    case actionTypes.BREAKS_REPORT_FAILED:
      return {
        ...state,
        breaks_report_processing: false,
        breaks_report_success: false,
        breaks_report_failed: true,
        breaks_report_message: action.data,
        breaks_report_data: [],
        breakReportMaster: [],
      };

    case actionTypes.BREAKS_DOWNLOAD_REPORT_INITIAL:
      return {
        ...state,
        breaks_download_report_processing: false,
        breaks_download_report_success: false,
        breaks_download_report_failed: false,
        breaks_download_report_data: [],
        breaks_download_report_message: "",
      };
    case actionTypes.BREAKS_DOWNLOAD_REPORT_PROCESS:
      return {
        ...state,
        breaks_download_report_processing: true,
        breaks_download_report_success: false,
        breaks_download_report_failed: false,
        breaks_download_report_data: [],
        breaks_download_report_message: "",
      };
    case actionTypes.BREAKS_DOWNLOAD_REPORT_SUCCESS:
      return {
        ...state,
        breaks_download_report_processing: false,
        breaks_download_report_success: true,
        breaks_download_report_failed: false,
        breaks_download_report_data: action.data.data,
        breaks_download_report_message: "",
      };
    case actionTypes.BREAKS_DOWNLOAD_REPORT_FAILED:
      return {
        ...state,
        breaks_download_report_processing: false,
        breaks_download_report_success: false,
        breaks_download_report_failed: true,
        breaks_download_report_message: action.data,
        breaks_download_report_data: [],
      };

    //monthly report
    case actionTypes.MONTHLY_BREAKS_REPORT_INITIAL:
      return {
        ...state,
        monthly_breaks_report_processing: false,
        monthly_breaks_report_success: false,
        monthly_breaks_report_failed: false,
        monthly_breaks_report_data: [],
        monthly_breaks_report_message: "",
      };
    case actionTypes.MONTHLY_BREAKS_REPORT_PROCESS:
      return {
        ...state,
        monthly_breaks_report_processing: true,
        monthly_breaks_report_success: false,
        monthly_breaks_report_failed: false,
        monthly_breaks_report_data: [],
        monthly_breaks_report_message: "",
      };
    case actionTypes.MONTHLY_BREAKS_REPORT_SUCCESS:
      return {
        ...state,
        monthly_breaks_report_processing: false,
        monthly_breaks_report_success: true,
        monthly_breaks_report_failed: false,
        monthly_breaks_report_data: action.data.data,
        monthly_breaks_report_message: "",
        pagination: action.data.optional.pagination || state.monthlyPagination,
      };
    case actionTypes.MONTHLY_BREAKS_REPORT_FAILED:
      return {
        ...state,
        monthly_breaks_report_processing: false,
        monthly_breaks_report_success: false,
        monthly_breaks_report_failed: true,
        monthly_breaks_report_message: action.data,
        monthly_breaks_report_data: [],
      };

    case actionTypes.MONTHLY_BREAKS_DOWNLOAD_REPORT_INITIAL:
      return {
        ...state,
        monthly_breaks_download_report_processing: false,
        monthly_breaks_download_report_success: false,
        monthly_breaks_download_report_failed: false,
        monthly_breaks_download_report_data: [],
        monthly_breaks_download_report_message: "",
      };
    case actionTypes.MONTHLY_BREAKS_DOWNLOAD_REPORT_PROCESS:
      return {
        ...state,
        monthly_breaks_download_report_processing: true,
        monthly_breaks_download_report_success: false,
        monthly_breaks_download_report_failed: false,
        monthly_breaks_download_report_data: [],
        monthly_breaks_download_report_message: "",
      };
    case actionTypes.MONTHLY_BREAKS_DOWNLOAD_REPORT_SUCCESS:
      return {
        ...state,
        monthly_breaks_download_report_processing: false,
        monthly_breaks_download_report_success: true,
        monthly_breaks_download_report_failed: false,
        monthly_breaks_download_report_data: action.data.data,
        monthly_breaks_download_report_message: "",
      };
    case actionTypes.MONTHLY_BREAKS_DOWNLOAD_REPORT_FAILED:
      return {
        ...state,
        monthly_breaks_download_report_processing: false,
        monthly_breaks_download_report_success: false,
        monthly_breaks_download_report_failed: true,
        monthly_breaks_download_report_message: action.data,
        monthly_breaks_download_report_data: [],
      };

    default:
      return state;
  }
};
