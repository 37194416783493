import { put } from "redux-saga/effects";
import { GoalsAction } from "../action";
import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import dayjs from "dayjs";
import { ExportCSV } from "utils/export-csv";
import { hmsOnlyNumber } from "utils/timeConverter";

const ExportData = (fileName, data, colunms, filterDate, teams) => {
  let result = [];
  const Generated = "Generated Date:-" + dayjs().format("DD-MM-YYYY hh:mm a");
  const generatedData = [
    fileName,
    "Teams: - " + teams.join(",").replaceAll(",", "/"),
    "Date:-" + filterDate,

    Generated,
    colunms.join(),
  ];

  data.forEach((record) => {
    const addColunmsValues = [
      record?.user_name,
      record?.team_name,
      record?.employee_id,
      record?.present > 1
        ? record.present + " " + "days"
        : record.present === 1
        ? record.present + " " + "day"
        : 0, // or an empty string if you want to show nothing for 0 days
      record?.goals_days > 1
        ? record.goals_days + " " + "days"
        : record.goals_days === 1
        ? record.goals_days + " " + "day"
        : 0, // or an empty string if you want to show nothing for 0 days
      hmsOnlyNumber(record?.online_duration),
      ...(record.active_duration !== undefined
        ? [hmsOnlyNumber(record.active_duration)]
        : []),
      ...(record?.activity_goals !== undefined
        ? [hmsOnlyNumber(record?.activity_goals)]
        : []),
      ...(record?.activity_goals_percentage !== undefined
        ? [record?.activity_goals_percentage.toFixed(1)]
        : []),

      ...(record.productive_duration !== undefined
        ? [hmsOnlyNumber(record?.productive_duration)]
        : []),
      ...(record.productivity_goals !== undefined
        ? [hmsOnlyNumber(record?.productivity_goals)]
        : []),
      ...(record.productivity_goals_percentage !== undefined
        ? [record?.productivity_goals_percentage.toFixed(1)]
        : []),

      (record.productivity_goals_percentage === undefined &&
        record.activity_goals_percentage !== undefined &&
        record.activity_goals_percentage >= 100) ||
      (record.productivity_goals_percentage !== undefined &&
        record.activity_goals_percentage === undefined &&
        record.productivity_goals_percentage >= 100) ||
      (record.productivity_goals_percentage !== undefined &&
        record.activity_goals_percentage !== undefined &&
        record.productivity_goals_percentage >= 100 &&
        record.activity_goals_percentage >= 100)
        ? "Goal achieved"
        : "Goal not achieved",
    ];
    generatedData.push(addColunmsValues.join(","));
  });
  result = generatedData;
  return result;
};

const getDownloadCSV = (payload, data) => {
  const teamIds = payload.payload.body.group_id.split(",");
  const selectTeamNames = payload.payload.team_list_data
    .filter((item) => teamIds.includes(item.id))
    .map((item) => item.name);

  const csvdata = data?.data;
  const sortData = csvdata.sort((a, b) =>
    a.user_name.localeCompare(b.user_name)
  );

  const date =
    dayjs(payload?.bodyData?.start_date).format("YYYY-MM-DD") +
    " To " +
    dayjs(payload?.bodyData?.end_date).format("YYYY-MM-DD");

  const colunms = [
    "Employee",
    "Team",
    "Employee ID",
    "Present days",
    "Goals achieved days",
    "Online time (hh:mm:ss)",
    ...(sortData.some(
      (record) => record.activity_goals_percentage !== undefined
    )
      ? [
          "Active time (hh:mm:ss)",
          "Active time goal (hh:mm:ss)",
          "Activity Vs Goal %",
        ]
      : []),

    ...(sortData.some(
      (record) => record.productivity_goals_percentage !== undefined
    )
      ? [
          "Productive Time",
          "Productive time goal (hh:mm:ss)",
          "Productivity Vs Goal %",
        ]
      : []),

    "Goal Achievement",
  ];
  const exdata = ExportData(
    "Goal Employee List",
    sortData,
    colunms,
    date,
    selectTeamNames
  );

  ExportCSV("Goal_Employee_List_", exdata);
};

export function* fetchGoalAchievementDistributionSaga(action) {
  const config = {
    method: "POST",
    url: `query/goals/goals_acheive_distribution`,
    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };
  try {
    const response = yield Client.request(config);

    yield put(
      GoalsAction.fetchGoalAchievementDistributionSuccess(response.data)
    );
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(GoalsAction.fetchGoalAchievementDistributionFailed(errorMessage));
  }
}

export function* fetchTeamwiseGoalComparisonSaga(action) {
  const config = {
    method: "POST",
    url: `query/goals/teamwise_goals`,
    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };
  try {
    const response = yield Client.request(config);

    yield put(GoalsAction.fetchTeamwiseGoalComparisonSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(GoalsAction.fetchTeamwiseGoalComparisonFailed(errorMessage));
  }
}

export function* fetchUserwiseGoalAnalyticsSaga(action) {
  const config = {
    method: "POST",
    url: `query/goals/user_wise_goals`,
    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };
  try {
    const response = yield Client.request(config);

    yield put(GoalsAction.fetchUserwiseGoalAnalyticsSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(GoalsAction.fetchUserwiseGoalAnalyticsFailed(errorMessage));
  }
}

export function* fetchUserwiseGoalAnalyticsDownloadSaga(action) {
  const config = {
    method: "POST",
    url: `query/goals/user_wise_goals`,
    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };
  try {
    const response = yield Client.request(config);
    getDownloadCSV(action, response.data);

    yield put(
      GoalsAction.fetchUserwiseGoalAnalyticsDownloadSuccess(response.data)
    );
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(
      GoalsAction.fetchUserwiseGoalAnalyticsDownloadFailed(errorMessage)
    );
  }
}
