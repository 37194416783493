export const SET_TEAM_USER_PIN = "SET_TEAM_USER_PIN";
export const SET_DATE_PIN = "SET_DATE_PIN";
export const SET_TEAM_IDS = "SET_TEAM_IDS";
export const SET_TEAM_PARENT_IDS = "SET_TEAM_PARENT_IDS";

export const SET_USER_IDS = "SET_USER_IDS";

export const SET_DATE = "SET_DATE";
export const SET_START_DATE = "SET_START_DATE";
export const SET_END_DATE = "SET_END_DATE";

export const SET_MONTH = "SET_MONTH";
