import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../index.scss";
import { InfoCircleFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { Skeleton, Calendar, Badge, Typography, Tooltip, Empty } from "antd";
import moment from "moment";
import { hm } from "utils/timeConverter";
import dayjs from "dayjs";
const { Title } = Typography;

const Cardskeleton = (props) => {
  return (
    <>
      <div className="charts">
        <div className="chart_header">
          <Skeleton.Input
            size={"small"}
            shape={"default"}
            style={{ height: "15px" }}
          />
          <Skeleton.Avatar
            size={"small"}
            shape={"circle"}
            style={{ height: "15px", width: "15px" }}
          />
        </div>
        <div className="chartContainer chart_tool" id="chart">
          {/* chart */}
          <Skeleton.Avatar
            size={"large"}
            shape={"default"}
            style={{ height: "300px", width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

const MonthlyAttendanceTrend = (props) => {
  const { month } = props;
  const attendancedetailed = useSelector((state) => state.attendancedetailed);
  const [loading, setLoading] = useState(true);
  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const [currentDate, setCurrentDate] = useState(dayjs(month).startOf("month"));

  useEffect(() => {
    if (month) {
      setCurrentDate(dayjs(month).startOf("month"));
    }
  }, [month]);

  const getListData = (value, filterDate) => {
    let listData = [];
    const dataFilter = _.find(
      value,
      (itm) => moment(itm.date).format("YYYY-MM-DD") === filterDate
    );
    // try {
    if (dataFilter !== undefined) {
      listData.push({
        type:
          dataFilter.workplace === "minimum_presence"
            ? "minpresence"
            : dataFilter.workplace === "half_day"
            ? "Halfday"
            : dataFilter.workplace === "full_day"
            ? "Fullday"
            : dataFilter.att_status === "WE" ||
              dataFilter.att_status === "WEEK OFF"
            ? "Weeklyoff"
            : dataFilter.workplace === "absent"
            ? "nabsent"
            : dataFilter.att_status === "H"
            ? "Holiday"
            : "",
        duration:
          dataFilter.punch_duration != null
            ? hm(dataFilter.punch_duration)
            : "00h 00m ",
      });
    } else {
      listData = [];
    }

    return listData || [];
  };

  const dateCellRender = (value) => {
    const current = moment().format("YYYY-MM-DD");
    let isSelected =
      open === true && selectedDate !== null
        ? value.isSame(selectedDate, "day")
        : null;
    let isCurrent =
      open === true && current !== null && selectedDate === null
        ? value.isSame(current, "day")
        : null;

    const datamap =
      attendancedetailed &&
      attendancedetailed.attendance_detailed_attendance_trend_data &&
      attendancedetailed.attendance_detailed_attendance_trend_data.length > 0 &&
      attendancedetailed.attendance_detailed_attendance_trend_data[0].logs &&
      attendancedetailed.attendance_detailed_attendance_trend_data[0].logs;

    const listData = getListData(datamap, value.format("YYYY-MM-DD"));
    if (!_.first(listData)) {
      return null;
    }
    if (
      _.first(listData).type === "minpresence" ||
      _.first(listData).type === "Halfday" ||
      _.first(listData).type === "Fullday"
    ) {
      return (
        <ul
          className={
            isSelected || isCurrent
              ? "cal_legend_user_calendar selected"
              : "cal_legend_user_calendar"
          }
        >
          {listData.map((item) => (
            <Tooltip
              title={
                item.type === "minpresence"
                  ? "<Min.presence" + " | Duration :" + " " + item.duration
                  : item.type + " | Duration :" + " " + item.duration
              }
            >
              <li key={item.content}>
                <Badge status={item.type} />
              </li>
            </Tooltip>
          ))}
        </ul>
      );
    } else {
      return (
        <ul className="cal_legend_user_calendar">
          {listData.map((item) => (
            <Tooltip title={item.type === "nabsent" ? "Absent" : item.type}>
              <li key={item.content}>
                <Badge status={item.type} />
              </li>
            </Tooltip>
          ))}
        </ul>
      );
    }
  };

  const { title, tooltip } = props;
  const disabledDate = (current) => {
    // Get the current date
    const today = new Date();
    // Set the time to midnight to compare only the date portion
    today.setHours(23, 59, 59, 0);

    // Disable dates if they are after today
    return current && current > today;
  };

  return (
    <>
      {attendancedetailed &&
      attendancedetailed.attendance_detailed_attendance_trend_processing ? (
        <Cardskeleton />
      ) : (
        <div className="charts">
          <div className="chart_header">
            <Title level={2}>{title}</Title>
            <Tooltip title={tooltip}>
              <InfoCircleFilled />
            </Tooltip>
          </div>
          <div
            className="chartContainer attendCalender userdetailcalender"
            style={{ marginTop: "-10px" }}
          >
            {attendancedetailed &&
            attendancedetailed.attendance_detailed_attendance_trend_data &&
            attendancedetailed.attendance_detailed_attendance_trend_data
              .length > 0 &&
            attendancedetailed.attendance_detailed_attendance_trend_data[0]
              .logs ? (
              <Calendar
                fullscreen={false}
                dateCellRender={dateCellRender}
                disabledDate={disabledDate}
                value={currentDate}
              />
            ) : (
              <div style={{ height: "271px" }} className="emptyblock">
                <Empty description={false} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

MonthlyAttendanceTrend.propTypes = {
  getList: PropTypes.func,
};

export default MonthlyAttendanceTrend;
