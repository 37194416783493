import { Dropdown, Tooltip } from "antd";
import React, { useState } from "react";
import { BiSupport } from "react-icons/bi";
import {
  BsChatRightText,
  BsHeadset,
  BsQuestionCircleFill,
  BsQuestionOctagon,
  BsTicket,
  BsCaretDownFill,
} from "react-icons/bs";
import {
  raiseTicketURL,
  requestOnboardingURL,
  newFeatureUpdate,
} from "utils/transport";
import FeatureUpdateIcon from "../../assets/images/new feature.svg";
import { AiFillQuestionCircle } from "react-icons/ai";

const Help = () => {
  const [isHovered, setIsHovered] = useState(false);

  const items = [
    {
      label: (
        <div
          className="flex items-center justify-start gap-4 h-8"
          onClick={() => {
            window.open("https://we360.zohodesk.in/portal/en/home", "_blank");
          }}
        >
          <BsQuestionOctagon />
          <div>Documentation</div>
        </div>
      ),
      key: "0",
    },
    {
      type: "divider",
      key: "1",
    },
    // {
    //   label: (
    //     <div
    //       className="flex items-center justify-start gap-4 h-8"
    //       onClick={() => {
    //         // @ts-ignore
    //         $zoho.salesiq.chat.start();
    //       }}
    //     >
    //       <BsChatRightText />
    //       <div>Chat with us</div>
    //     </div>
    //   ),
    //   key: "2",
    // },

    // {
    //   type: "divider",
    //   key: "3",
    // },
    {
      label: (
        <div
          className="flex items-center justify-start gap-4 h-8"
          onClick={() => {
            window.open(requestOnboardingURL, "_blank");
          }}
        >
          <BsHeadset />
          <div>Request Onboarding</div>
        </div>
      ),
      key: "4",
    },
    {
      type: "divider",
      key: "5",
    },
    {
      label: (
        <div
          className="flex items-center justify-start gap-4 h-8"
          onClick={() => {
            window.open(raiseTicketURL, "_blank");
          }}
        >
          <BsTicket />
          <div>Raise a ticket</div>
        </div>
      ),
      key: "6",
    },
    {
      type: "divider",
      key: "7",
    },
    {
      label: (
        <div
          className="flex items-center justify-start gap-4 h-8"
          onClick={() => {
            window.open(newFeatureUpdate, "_blank");
          }}
        >
          <img src={FeatureUpdateIcon} width={"15px"} />
          <div> Request a new feature</div>
        </div>
      ),
      key: "8",
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      trigger={["click"]}
      placement="bottomRight"
      arrow
    >
      <Tooltip title="Help">
        <div
          className={`flex items-center cursor-pointer ${
            isHovered ? "text-[#7b6fff]" : "text-[#667085]"
          }`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <AiFillQuestionCircle
            className="w-8 h-8 mr-1 rounded-full transition duration-300 ease-in-out"
            size={25}
          />
          <span className="mr-1 bold-text">Help Center</span>

          <BsCaretDownFill className="" />
        </div>
      </Tooltip>
    </Dropdown>
  );
};

export default Help;
