import { put } from "redux-saga/effects";
import { FieldAction } from "../action";
import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";

export function* fetchDailyPingsSaga(action) {
  const config = {
    method: "POST",
    url: action.payload.isOwner
      ? `/api/v1/admin/daily-pings/?${action.payload.query}`
      : `/api/v1/me/hierarchy/daily-pings/?${action.payload.query}`,
    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };
  try {
    const response = yield Client.request(config);

    yield put(FieldAction.fetchDailyPingsSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(FieldAction.fetchDailyPingsFailed(errorMessage));
  }
}
