import React from "react";

export const Timeline = ({ viewBox = "0 0 20 20" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      fill="currentColor"
    >
      <path
        d="M5.5,11.3h3.9c0.4,0,0.6-0.3,0.6-0.6V8.7c0-0.4-0.3-0.6-0.6-0.6H5.5c-0.4,0-0.6,0.3-0.6,0.6v1.9
	C4.9,11,5.1,11.3,5.5,11.3z"
      />
      <path
        d="M15.8,14.5v-1.9c0-0.4-0.3-0.6-0.6-0.6H5.5c-0.4,0-0.6,0.3-0.6,0.6v1.9c0,0.4,0.3,0.6,0.6,0.6h9.6
	C15.5,15.1,15.8,14.9,15.8,14.5z"
      />
      <path
        d="M18.4,15.8h-6.4c-0.4,0-0.6,0.3-0.6,0.6v1.9c0,0.4,0.3,0.6,0.6,0.6h6.4c0.4,0,0.6-0.3,0.6-0.6v-1.9
	C19,16.1,18.7,15.8,18.4,15.8z"
      />
      <path
        d="M18.4,1H1.6C1.3,1,1,1.3,1,1.6v3.2v1.9v11.6C1,18.7,1.3,19,1.6,19s0.6-0.3,0.6-0.6V7.4h3.2
	c0.4,0,0.6-0.3,0.6-0.6V4.9c0-0.4-0.3-0.6-0.6-0.6H2.3V3.6h16.1c0.4,0,0.6-0.3,0.6-0.6V1.6C19,1.3,18.7,1,18.4,1z"
      />
    </svg>
  );
};
