import * as actionTypes from "./actionTypes";

const initialPagination = { totalCount: 0 };

const initialState = {
  fetchProjectsReportLoading: true,
  projectsReportData: [],
  pagination: initialPagination,
  projectReportErrorMessage: "",
  downloadProjectsReportLoading: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_PROJECTS_REPORT:
      return {
        ...state,
        fetchProjectsReportLoading: true,
        projectReportErrorMessage: "",
      };

    case actionTypes.FETCH_PROJECTS_REPORT_SUCCESS:
      return {
        ...state,
        fetchProjectsReportLoading: false,
        projectsReportData: action.payload.data,
        pagination: action.payload.meta || state.pagination,
      };

    case actionTypes.FETCH_PROJECTS_REPORT_FAILED:
      return {
        ...state,
        fetchProjectsReportLoading: false,
        projectsReportData: [],
        pagination: initialPagination,
        projectReportErrorMessage: action.payload,
      };
    case actionTypes.DOWNLOAD_PROJECTS_REPORT:
      return {
        ...state,
        downloadProjectsReportLoading: true,
        projectReportErrorMessage: "",
      };

    case actionTypes.DOWNLOAD_PROJECTS_REPORT_SUCCESS:
      return {
        ...state,
        downloadProjectsReportLoading: false,
      };

    case actionTypes.DOWNLOAD_PROJECTS_REPORT_FAILED:
      return {
        ...state,
        downloadProjectsReportLoading: false,
        projectReportErrorMessage: action.payload,
      };

    default:
      return state;
  }
};
