export const TEAM_CREATE_INITIAL = "TEAM_CREATE_INITIAL";
export const TEAM_CREATE_PROCESS = "TEAM_CREATE_PROCESS";
export const TEAM_CREATE_SUCCESS = "TEAM_CREATE_SUCCESS";
export const TEAM_CREATE_FAILED = "TEAM_CREATE_FAILED";

export const TEAM_LIST_INITIAL = "TEAM_LIST_INITIAL";
export const TEAM_LIST_PROCESS = "TEAM_LIST_PROCESS";
export const TEAM_LIST_SUCCESS = "TEAM_LIST_SUCCESS";
export const TEAM_LIST_FAILED = "TEAM_LIST_FAILED";

export const TEAM_UPDATE_INITIAL = "TEAM_UPDATE_INITIAL";
export const TEAM_UPDATE_PROCESS = "TEAM_UPDATE_PROCESS";
export const TEAM_UPDATE_SUCCESS = "TEAM_UPDATE_SUCCESS";
export const TEAM_UPDATE_FAILED = "TEAM_UPDATE_FAILED";

export const TEAM_DELETE_INITIAL = "TEAM_DELETE_INITIAL";
export const TEAM_DELETE_PROCESS = "TEAM_DELETE_PROCESS";
export const TEAM_DELETE_SUCCESS = "TEAM_DELETE_SUCCESS";
export const TEAM_DELETE_FAILED = "TEAM_DELETE_FAILED";

export const TEAM_MEMBER_UPDATE_INITIAL = "TEAM_MEMBER_UPDATE_INITIAL";
export const TEAM_MEMBER_UPDATE_PROCESS = "TEAM_MEMBER_UPDATE_PROCESS";
export const TEAM_MEMBER_UPDATE_SUCCESS = "TEAM_MEMBER_UPDATE_SUCCESS";
export const TEAM_MEMBER_UPDATE_FAILED = "TEAM_MEMBER_UPDATE_FAILED";

export const TEAM_MANEGER_UPDATE_INITIAL = "TEAM_MANEGER_UPDATE_INITIAL";
export const TEAM_MANEGER_UPDATE_PROCESS = "TEAM_MANEGER_UPDATE_PROCESS";
export const TEAM_MANEGER_UPDATE_SUCCESS = "TEAM_MANEGER_UPDATE_SUCCESS";
export const TEAM_MANEGER_UPDATE_FAILED = "TEAM_MANEGER_UPDATE_FAILED";

export const UPDATE_USER_SETTING_INITIAL = "UPDATE_USER_SETTING_INITIAL";
export const UPDATE_USER_SETTING_PROCESS = "UPDATE_USER_SETTING_PROCESS";
export const UPDATE_USER_SETTING_SUCCESS = "UPDATE_USER_SETTING_SUCCESS";
export const UPDATE_USER_SETTING_FAILED = "UPDATE_USER_SETTING_FAILED";
