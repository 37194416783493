import { put } from "redux-saga/effects";
import { RolesAction } from "../action";
import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { notification } from "antd";

export function* fetchRolesSaga(action) {
  const config = {
    method: "GET",
    url: `/api/v1/roles/?${action.payload.query}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    const response = yield Client.request(config);

    yield put(RolesAction.fetchRolesSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(RolesAction.fetchRolesFailed(errorMessage));
  }
}

export function* createRoleSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/roles/`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);

    yield put(RolesAction.createRoleSuccess(response.data));
    notification.success({
      message: `Successfully Created Role!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(RolesAction.createRoleFailed(errorMessage));
  }
}

export function* updateRoleSaga(action) {
  const config = {
    method: "PUT",
    url: `/api/v1/roles/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);

    yield put(RolesAction.updateRoleSuccess(response.data));
    notification.success({
      message: `Successfully Updated Role!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(RolesAction.updateRoleFailed(errorMessage));
  }
}

export function* deleteRoleSaga(action) {
  const config = {
    method: "DELETE",
    url: `/api/v1/roles/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    yield Client.request(config);

    yield put(RolesAction.deleteRoleSuccess());
    notification.success({
      message: `Successfully Deleted Role!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(RolesAction.deleteRoleFailed(errorMessage));
  }
}
