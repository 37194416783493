//* ATTENDANCE SUMMARY WEDIGT *//

export const ATTENDANCE_SUMMARY_WEDIGT_INITIAL =
  "ATTENDANCE_SUMMARY_WEDIGT_INITIAL";
export const ATTENDANCE_SUMMARY_WEDIGT_PROCESS =
  "ATTENDANCE_SUMMARY_WEDIGT_PROCESS";
export const ATTENDANCE_SUMMARY_WEDIGT_SUCCESS =
  "ATTENDANCE_SUMMARY_WEDIGT_SUCCESS";
export const ATTENDANCE_SUMMARY_WEDIGT_FAILED =
  "ATTENDANCE_SUMMARY_WEDIGT_FAILED";

//*  ATTENDANCE WEDIGT End *//

//* ATTENDANCE SUMMARY TODAY *//

export const ATTENDANCE_SUMMARY_TODAY_INITIAL =
  "ATTENDANCE_SUMMARY_TODAY_INITIAL";
export const ATTENDANCE_SUMMARY_TODAY_PROCESS =
  "ATTENDANCE_SUMMARY_TODAY_PROCESS";
export const ATTENDANCE_SUMMARY_TODAY_SUCCESS =
  "ATTENDANCE_SUMMARY_TODAY_SUCCESS";
export const ATTENDANCE_SUMMARY_TODAY_FAILED =
  "ATTENDANCE_SUMMARY_TODAY_FAILED";

//*  ATTENDANCE SUMMARY TODAY End *//

//* ATTENDANCE SUMMARY WORKING TIME *//

export const ATTENDANCE_SUMMARY_WORKING_TIME_INITIAL =
  "ATTENDANCE_SUMMARY_WORKING_TIME_INITIAL";
export const ATTENDANCE_SUMMARY_WORKING_TIME_PROCESS =
  "ATTENDANCE_SUMMARY_WORKING_TIME_PROCESS";
export const ATTENDANCE_SUMMARY_WORKING_TIME_SUCCESS =
  "ATTENDANCE_SUMMARY_WORKING_TIME_SUCCESS";
export const ATTENDANCE_SUMMARY_WORKING_TIME_FAILED =
  "ATTENDANCE_SUMMARY_WORKING_TIME_FAILED";

//*  ATTENDANCE SUMMARY WORKING TIME End *//
//* ATTENDANCE SUMMARY WORKING TIME LESS*//

export const ATTENDANCE_SUMMARY_WORKING_TIME_LESS_INITIAL =
  "ATTENDANCE_SUMMARY_WORKING_TIME_LESS_INITIAL";
export const ATTENDANCE_SUMMARY_WORKING_TIME_LESS_PROCESS =
  "ATTENDANCE_SUMMARY_WORKING_TIME_LESS_PROCESS";
export const ATTENDANCE_SUMMARY_WORKING_TIME_LESS_SUCCESS =
  "ATTENDANCE_SUMMARY_WORKING_TIME_LESS_SUCCESS";
export const ATTENDANCE_SUMMARY_WORKING_TIME_LESS_FAILED =
  "ATTENDANCE_SUMMARY_WORKING_TIME_LESS_FAILED";

//*  ATTENDANCE SUMMARY WORKING TIME LESS End *//

//* ATTENDANCE SUMMARY ATTENDANCE TRENDS *//

export const ATTENDANCE_SUMMARY_ATTENDANCE_TREND_INITIAL =
  "ATTENDANCE_SUMMARY_ATTENDANCE_TREND_INITIAL";
export const ATTENDANCE_SUMMARY_ATTENDANCE_TREND_PROCESS =
  "ATTENDANCE_SUMMARY_ATTENDANCE_TREND_PROCESS";
export const ATTENDANCE_SUMMARY_ATTENDANCE_TREND_SUCCESS =
  "ATTENDANCE_SUMMARY_ATTENDANCE_TREND_SUCCESS";
export const ATTENDANCE_SUMMARY_ATTENDANCE_TREND_FAILED =
  "ATTENDANCE_SUMMARY_ATTENDANCE_TREND_FAILED";

export const ATTENDANCE_SUMMARY_ATTENDANCE_TREND_LESS_INITIAL =
  "ATTENDANCE_SUMMARY_ATTENDANCE_TREND_LESS_INITIAL";
export const ATTENDANCE_SUMMARY_ATTENDANCE_TREND_LESS_PROCESS =
  "ATTENDANCE_SUMMARY_ATTENDANCE_TREND_LESS_PROCESS";
export const ATTENDANCE_SUMMARY_ATTENDANCE_TREND_LESS_SUCCESS =
  "ATTENDANCE_SUMMARY_ATTENDANCE_TREND_LESS_SUCCESS";
export const ATTENDANCE_SUMMARY_ATTENDANCE_TREND_LESS_FAILED =
  "ATTENDANCE_SUMMARY_ATTENDANCE_TREND_LESS_FAILED";
//*  ATTENDANCE SUMMARY ATTENDANCE TRENDS End *//

//* ATTENDANCE SUMMARY WORKING TIME CURRENT*//

export const ATTENDANCE_SUMMARY_ATTENDANCE_TREND_CURRENT_INITIAL =
  "ATTENDANCE_SUMMARY_ATTENDANCE_TREND_CURRENT_INITIAL";
export const ATTENDANCE_SUMMARY_ATTENDANCE_TREND_CURRENT_PROCESS =
  "ATTENDANCE_SUMMARY_ATTENDANCE_TREND_CURRENT_PROCESS";
export const ATTENDANCE_SUMMARY_ATTENDANCE_TREND_CURRENT_SUCCESS =
  "ATTENDANCE_SUMMARY_ATTENDANCE_TREND_CURRENT_SUCCESS";
export const ATTENDANCE_SUMMARY_ATTENDANCE_TREND_CURRENT_FAILED =
  "ATTENDANCE_SUMMARY_ATTENDANCE_TREND_CURRENT_FAILED";

//*  ATTENDANCE SUMMARY WORKING TIME CURRENT End *//

//* ATTENDANCE SUMMARY BREAK TRENDS *//

export const ATTENDANCE_SUMMARY_BREAK_TREND_INITIAL =
  "ATTENDANCE_SUMMARY_BREAK_TREND_INITIAL";
export const ATTENDANCE_SUMMARY_BREAK_TREND_PROCESS =
  "ATTENDANCE_SUMMARY_BREAK_TREND_PROCESS";
export const ATTENDANCE_SUMMARY_BREAK_TREND_SUCCESS =
  "ATTENDANCE_SUMMARY_BREAK_TREND_SUCCESS";
export const ATTENDANCE_SUMMARY_BREAK_TREND_FAILED =
  "ATTENDANCE_SUMMARY_BREAK_TREND_FAILED";

//*  ATTENDANCE SUMMARY BREAK TRENDS End *//

//* ATTENDANCE SUMMARY BREAK TRENDS LESS*//

export const ATTENDANCE_SUMMARY_BREAK_TREND_LESS_INITIAL =
  "ATTENDANCE_SUMMARY_BREAK_TREND_LESS_INITIAL";
export const ATTENDANCE_SUMMARY_BREAK_TREND_LESS_PROCESS =
  "ATTENDANCE_SUMMARY_BREAK_TREND_LESS_PROCESS";
export const ATTENDANCE_SUMMARY_BREAK_TREND_LESS_SUCCESS =
  "ATTENDANCE_SUMMARY_BREAK_TREND_LESS_SUCCESS";
export const ATTENDANCE_SUMMARY_BREAK_TREND_LESS_FAILED =
  "ATTENDANCE_SUMMARY_BREAK_TREND_LESS_FAILED";

//*  ATTENDANCE SUMMARY BREAK TRENDS LESS End *//

//* ATTENDANCE SUMMARY BREAK TRENDS DATE WISE*//

export const ATTENDANCE_SUMMARY_BREAK_TREND_DATE_WISE_INITIAL =
  "ATTENDANCE_SUMMARY_BREAK_TREND_DATE_WISE_INITIAL";
export const ATTENDANCE_SUMMARY_BREAK_TREND_DATE_WISE_PROCESS =
  "ATTENDANCE_SUMMARY_BREAK_TREND_DATE_WISE_PROCESS";
export const ATTENDANCE_SUMMARY_BREAK_TREND_DATE_WISE_SUCCESS =
  "ATTENDANCE_SUMMARY_BREAK_TREND_DATE_WISE_SUCCESS";
export const ATTENDANCE_SUMMARY_BREAK_TREND_DATE_WISE_FAILED =
  "ATTENDANCE_SUMMARY_BREAK_TREND_DATE_WISE_FAILED";

//*  ATTENDANCE SUMMARY BREAK TRENDS DATE WISE End *//

//* ATTENDANCE SUMMARY LATE ARRIVAL TENDENCY *//

export const ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_INITIAL =
  "ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_INITIAL";
export const ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_PROCESS =
  "ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_PROCESS";
export const ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_SUCCESS =
  "ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_SUCCESS";
export const ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_FAILED =
  "ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_FAILED";

//*  ATTENDANCE SUMMARY LATE ARRIVAL TENDENCY End *//

//* ATTENDANCE SUMMARY LATE ARRIVAL TENDENCY LESS*//

export const ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_LESS_INITIAL =
  "ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_LESS_INITIAL";
export const ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_LESS_PROCESS =
  "ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_LESS_PROCESS";
export const ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_LESS_SUCCESS =
  "ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_LESS_SUCCESS";
export const ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_LESS_FAILED =
  "ATTENDANCE_SUMMARY_LATE_ARRIVAL_TENDENCY_LESS_FAILED";

//*  ATTENDANCE SUMMARY LATE ARRIVAL TENDENCY LESS End *//
