import * as actionTypes from "./actionTypes";

export const fetchGoalAchievementDistribution = (payload) => {
  return {
    type: actionTypes.FETCH_GOAL_ACHIEVEMENT_DISTRIBUTION,
    payload,
  };
};

export const fetchGoalAchievementDistributionSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_GOAL_ACHIEVEMENT_DISTRIBUTION_SUCCESS,
    payload,
  };
};
export const fetchGoalAchievementDistributionFailed = (payload) => {
  return {
    type: actionTypes.FETCH_GOAL_ACHIEVEMENT_DISTRIBUTION_FAILED,
    payload,
  };
};

export const fetchTeamwiseGoalComparison = (payload) => {
  return {
    type: actionTypes.FETCH_TEAMWISE_GOAL_COMPARISON,
    payload,
  };
};

export const fetchTeamwiseGoalComparisonSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_TEAMWISE_GOAL_COMPARISON_SUCCESS,
    payload,
  };
};
export const fetchTeamwiseGoalComparisonFailed = (payload) => {
  return {
    type: actionTypes.FETCH_TEAMWISE_GOAL_COMPARISON_FAILED,
    payload,
  };
};

export const fetchUserwiseGoalAnalytics = (payload) => {
  return {
    type: actionTypes.FETCH_USERWISE_GOAL_ANALYTICS,
    payload,
  };
};

export const fetchUserwiseGoalAnalyticsSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_USERWISE_GOAL_ANALYTICS_SUCCESS,
    payload,
  };
};
export const fetchUserwiseGoalAnalyticsFailed = (payload) => {
  return {
    type: actionTypes.FETCH_USERWISE_GOAL_ANALYTICS_FAILED,
    payload,
  };
};

export const setGoalErrorMessage = (payload) => {
  return {
    type: actionTypes.SET_GOAL_ERROR_MESSAGE,
    payload,
  };
};

export const fetchUserwiseGoalAnalyticsDownload = (payload) => {
  return {
    type: actionTypes.FETCH_USERWISE_GOAL_ANALYTICS_DOWNLOAD,
    payload,
  };
};

export const fetchUserwiseGoalAnalyticsDownloadSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_USERWISE_GOAL_ANALYTICS_DOWNLOAD_SUCCESS,
    payload,
  };
};
export const fetchUserwiseGoalAnalyticsDownloadFailed = (payload) => {
  return {
    type: actionTypes.FETCH_USERWISE_GOAL_ANALYTICS_DOWNLOAD_FAILED,
    payload,
  };
};
