//* REPORT DAILY ATTENDANCE   *//

export const REPORTS_DAILY_ATTENDANCE_INITIAL =
  "REPORTS_DAILY_ATTENDANCE_INITIAL";
export const REPORTS_DAILY_ATTENDANCE_PROCESS =
  "REPORTS_DAILY_ATTENDANCE_PROCESS";
export const REPORTS_DAILY_ATTENDANCE_SUCCESS =
  "REPORTS_DAILY_ATTENDANCE_SUCCESS";
export const REPORTS_DAILY_ATTENDANCE_FAILED =
  "REPORTS_DAILY_ATTENDANCE_FAILED";

//* REPORT DOWNLOAD DAILY ATTENDANCE   *//

export const REPORTS_DOWNLOAD_DAILY_ATTENDANCE_INITIAL =
  "REPORTS_DOWNLOAD_DAILY_ATTENDANCE_INITIAL";
export const REPORTS_DOWNLOAD_DAILY_ATTENDANCE_PROCESS =
  "REPORTS_DOWNLOAD_DAILY_ATTENDANCE_PROCESS";
export const REPORTS_DOWNLOAD_DAILY_ATTENDANCE_SUCCESS =
  "REPORTS_DOWNLOAD_DAILY_ATTENDANCE_SUCCESS";
export const REPORTS_DOWNLOAD_DAILY_ATTENDANCE_FAILED =
  "REPORTS_DOWNLOAD_DAILY_ATTENDANCE_FAILED";
