import * as actionTypes from "./actionTypes";

const initialState = {
  productivity_report_processing: false,
  productivity_report_success: false,
  productivity_report_failed: false,
  productivity_report_data: [],
  productivity_report_message: "",
  breakReportMaster: [],

  productivity_report_download_processing: false,
  productivity_report_download_success: false,
  productivity_report_download_failed: false,
  productivity_report_download_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.PRODUCTIVITY_REPORT_INITIAL:
      return {
        ...state,
        productivity_report_processing: false,
        productivity_report_success: false,
        productivity_report_failed: false,
        productivity_report_data: [],
        productivity_report_message: "",
        breakReportMaster: [],
      };
    case actionTypes.PRODUCTIVITY_REPORT_PROCESS:
      return {
        ...state,
        productivity_report_processing: true,
        productivity_report_success: false,
        productivity_report_failed: false,
        productivity_report_data: [],
        productivity_report_message: "",
        breakReportMaster: [],
      };
    case actionTypes.PRODUCTIVITY_REPORT_SUCCESS:
      return {
        ...state,
        productivity_report_processing: false,
        productivity_report_success: true,
        productivity_report_failed: false,
        productivity_report_data: action.data,
        productivity_report_message: "",
        breakReportMaster: action.data,
      };
    case actionTypes.PRODUCTIVITY_REPORT_FAILED:
      return {
        ...state,
        productivity_report_processing: false,
        productivity_report_success: false,
        productivity_report_failed: true,
        productivity_report_message: action.data,
        productivity_report_data: [],
        breakReportMaster: [],
      };
    case actionTypes.PRODUCTIVITY_REPORT_DOWNLOAD_INITIAL:
      return {
        ...state,
        productivity_report_download_processing: false,
        productivity_report_download_success: false,
        productivity_report_download_failed: false,

        productivity_report_download_message: "",
      };
    case actionTypes.PRODUCTIVITY_REPORT_DOWNLOAD_PROCESS:
      return {
        ...state,
        productivity_report_download_processing: true,
        productivity_report_download_success: false,
        productivity_report_download_failed: false,

        productivity_report_download_message: "",
      };
    case actionTypes.PRODUCTIVITY_REPORT_DOWNLOAD_SUCCESS:
      return {
        ...state,
        productivity_report_download_processing: false,
        productivity_report_download_success: true,
        productivity_report_download_failed: false,

        productivity_report_download_message: "",
      };
    case actionTypes.PRODUCTIVITY_REPORT_DOWNLOAD_FAILED:
      return {
        ...state,
        productivity_report_download_processing: false,
        productivity_report_download_success: false,
        productivity_report_download_failed: true,

        productivity_report_download_message: "",
      };
    default:
      return state;
  }
};
