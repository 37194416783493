const Texts = {
  CHECK_API_CALL: "Check api call",
  GET_NOTIFCATION: "Get notification",
  attendance: "Attendance",
};

export const SOMETHING_WENT_WRONG = "Something Went Wrong, Please try Again!";

export const LIVESTREAM_CONNECTION_ERROR =
  "There was some issue while establishing the connection. Please check your network connection or try reloading the page.";

export const GOALS_SETTING_NOT_SET_ERROR =
  "Goals have not been set up yet. Please configure them in the settings.";
export default Texts;

export const DELETE_PROJECT_ERROR_MESSAGE =
  "Please note that projects containing child tasks cannot be deleted. Only empty projects without any associated tasks can be deleted. Please mark it as inactive if you want to hide it.";

export const DELETE_TASK_ERROR_MESSAGE =
  "Please note that Task containing child tasks or activities cannot be deleted. Only tasks without any associated child tasks and activities can be deleted.";

export const DELETE_APPLICATION_POLICY_ERROR_MESSAGE =
  "Only application policies without mapped categories will be deleted.";
