//WORK DID BY KANCHAN
import * as actionTypes from "./actionTypes";

export const LeaveListInitial = () => {
  return {
    type: actionTypes.LEAVE_LIST_INITIAL,
  };
};
export const LeaveListProcess = (payload) => {
  return {
    type: actionTypes.LEAVE_LIST_PROCESS,
    payload,
  };
};
export const LeaveListSuccess = (data) => {
  return {
    type: actionTypes.LEAVE_LIST_SUCCESS,
    data,
  };
};
export const LeaveListFailed = (error) => {
  return {
    type: actionTypes.LEAVE_LIST_FAILED,
    error,
  };
};

//CREATE LEAVE TYPE

export const LeaveCreateInitial = () => {
  return {
    type: actionTypes.LEAVE_CREATE_INITIAL,
  };
};

export const LeaveCreateProcess = (payload) => {
  return {
    type: actionTypes.LEAVE_CREATE_PROCESS,
    payload,
  };
};
export const LeaveCreateSuccess = (data) => {
  return {
    type: actionTypes.LEAVE_CREATE_SUCCESS,
    data,
  };
};
export const LeaveCreateFailed = (error) => {
  return {
    type: actionTypes.LEAVE_CREATE_FAILED,
    error,
  };
};

//EDIT LEAVE TYPE

export const LeaveEditInitial = () => {
  return {
    type: actionTypes.LEAVE_EDIT_INITIAL,
  };
};
export const LeaveEditProcess = (payload) => {
  return {
    type: actionTypes.LEAVE_EDIT_PROCESS,
    payload,
  };
};
export const LeaveEditSuccess = (data) => {
  return {
    type: actionTypes.LEAVE_EDIT_SUCCESS,
    data,
  };
};
export const LeaveEditFailed = (error) => {
  return {
    type: actionTypes.LEAVE_EDIT_FAILED,
    error,
  };
};
