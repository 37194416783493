import * as actionTypes from "./actionTypes";

//* PRODUCTIVITY SUMMARY WIDGET *//

export const ProductivitySummaryWidgetInitial = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_WIDGET_INITIAL,
    data,
  };
};
export const ProductivitySummaryWidgetProcess = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_WIDGET_PROCESS,
    data,
  };
};
export const ProductivitySummaryWidgetSuccess = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_WIDGET_SUCCESS,
    data,
  };
};
export const ProductivitySummaryWidgetFailed = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_WIDGET_FAILED,
    data,
  };
};
//* Top Category SUMMARY *//

export const ProductivitySummaryTopCategoryInitial = () => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_TOP_CATEGORY_INITIAL,
  };
};
export const ProductivitySummaryTopCategoryProcess = (token, bodyData) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_TOP_CATEGORY_PROCESS,
    token,
    bodyData,
  };
};
export const ProductivitySummaryTopCategorySuccess = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_TOP_CATEGORY_SUCCESS,
    data,
  };
};
export const ProductivitySummaryTopCategoryFailed = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_TOP_CATEGORY_FAILED,
    data,
  };
};
//* Top App Url SUMMARY *//

export const ProductivitySummaryTopAppUrlInitial = () => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_TOP_APP_URL_INITIAL,
  };
};
export const ProductivitySummaryTopAppUrlProcess = (token, bodyData) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_TOP_APP_URL_PROCESS,
    token,
    bodyData,
  };
};
export const ProductivitySummaryTopAppUrlSuccess = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_TOP_APP_URL_SUCCESS,
    data,
  };
};
export const ProductivitySummaryTopAppUrlFailed = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_TOP_APP_URL_FAILED,
    data,
  };
};
//* Working time  SUMMARY *//

export const ProductivitySummaryWorkingTimeInitial = () => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_WORKING_TIME_INITIAL,
  };
};
export const ProductivitySummaryWorkingTimeProcess = (token, bodyData) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_WORKING_TIME_PROCESS,
    token,
    bodyData,
  };
};
export const ProductivitySummaryWorkingTimeSuccess = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_WORKING_TIME_SUCCESS,
    data,
  };
};
export const ProductivitySummaryWorkingTimeFailed = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_WORKING_TIME_FAILED,
    data,
  };
};

//*  PRODUCTIVITY SUMMARY PRODUCTIVITY Level Break down *//

export const ProductivitySummaryProductivityBreakdownInitial = () => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_PRODUCTIVITY_BREAK_DOWN_INITIAL,
  };
};
export const ProductivitySummaryProductivityBreakdownProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_PRODUCTIVITY_BREAK_DOWN_PROCESS,
    token,
    bodyData,
  };
};
export const ProductivitySummaryProductivityBreakdownSuccess = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_PRODUCTIVITY_BREAK_DOWN_SUCCESS,
    data,
  };
};
export const ProductivitySummaryProductivityBreakdownFailed = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_PRODUCTIVITY_BREAK_DOWN_FAILED,
    data,
  };
};

//*  PRODUCTIVITY SUMMARY Top 3 most active team *//

export const ProductivitySummaryTopBottomActiveTeamInitial = () => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_TOP_BOTTOM_ACTIVE_TEAM_INITIAL,
  };
};
export const ProductivitySummaryTopBottomActiveTeamProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_TOP_BOTTOM_ACTIVE_TEAM_PROCESS,
    token,
    bodyData,
  };
};
export const ProductivitySummaryTopBottomActiveTeamSuccess = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_TOP_BOTTOM_ACTIVE_TEAM_SUCCESS,
    data,
  };
};
export const ProductivitySummaryTopBottomActiveTeamFailed = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_TOP_BOTTOM_ACTIVE_TEAM_FAILED,
    data,
  };
};

//*  PRODUCTIVITY SUMMARY TEAM WISE PRODUCTIVITY BREAK DOWN *//

export const ProductivitySummaryTeamWiseProductivityBreakdownInitial = () => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_BREAK_DOWN_INITIAL,
  };
};
export const ProductivitySummaryTeamWiseProductivityBreakdownProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_BREAK_DOWN_PROCESS,
    token,
    bodyData,
  };
};
export const ProductivitySummaryTeamWiseProductivityBreakdownSuccess = (
  data
) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_BREAK_DOWN_SUCCESS,
    data,
  };
};
export const ProductivitySummaryTeamWiseProductivityBreakdownFailed = (
  data
) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_BREAK_DOWN_FAILED,
    data,
  };
};

// //*  PRODUCTIVITY SUMMARY TEAM WISE PRODUCTIVITY CATEGORY *//

export const ProductivitySummaryTeamWiseProductivityCategoryInitial = (
  data
) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_CATEGORY_INITIAL,
  };
};
export const ProductivitySummaryTeamWiseProductivityCategoryProcess = (
  data
) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_CATEGORY_PROCESS,
    data,
  };
};
export const ProductivitySummaryTeamWiseProductivityCategorySuccess = (
  data
) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_CATEGORY_SUCCESS,
    data,
  };
};
export const ProductivitySummaryTeamWiseProductivityCategoryFailed = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_CATEGORY_FAILED,
    data,
  };
};

//*  PRODUCTIVITY SUMMARY PRODUCTIVITY PERCENT *//

export const ProductivitySummaryProductivityPercentInitial = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_PRODUCTIVITY_PERCENT_INITIAL,
  };
};
export const ProductivitySummaryProductivityPercentProcess = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_PRODUCTIVITY_PERCENT_PROCESS,
    data,
  };
};
export const ProductivitySummaryProductivityPercentSuccess = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_PRODUCTIVITY_PERCENT_SUCCESS,
    data,
  };
};
export const ProductivitySummaryProductivityPercentFailed = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_SUMMARY_PRODUCTIVITY_PERCENT_FAILED,
    data,
  };
};
