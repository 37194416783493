import { put } from "redux-saga/effects";
import { UserDeviceAction } from "../action";
import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";

export function* getPcUserDeviceInfo(action) {
  const config = {
    method: "POST",
    url: `query/devices/user_device_details`,

    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);

    yield put(UserDeviceAction.pcUserDeviceInfoSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(UserDeviceAction.pcUserDeviceInfoFailed(errorMessage));
  }
}

export function* getMobileUserDeviceInfo(action) {
  const config = {
    method: "POST",
    url: `query/devices/user_device_details`,

    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);

    yield put(UserDeviceAction.mobileUserDeviceInfoSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(UserDeviceAction.mobileUserDeviceInfoFailed(errorMessage));
  }
}

export function* getUserDeviceCount(action) {
  const config = {
    method: "POST",
    url: `query/devices/user_device_count`,
    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);

    yield put(UserDeviceAction.userDeviceCountSuccess(response.data.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(UserDeviceAction.userDeviceCountFailed(errorMessage));
  }
}

export function* getMobilePlatform(action) {
  const config = {
    method: "POST",
    url: `query/devices/platform_myzen_version`,

    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);

    yield put(UserDeviceAction.mobilePlatformSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(UserDeviceAction.mobilePlatformFailed(errorMessage));
  }
}

export function* getPCPlatform(action) {
  const config = {
    method: "POST",
    url: `query/devices/platform_myzen_version`,

    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);

    yield put(UserDeviceAction.pcPlatformSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(UserDeviceAction.pcPlatformFailed(errorMessage));
  }
}
export function* getPCSystemType(action) {
  const config = {
    method: "POST",
    url: `query/devices/platform_myzen_version`,

    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);

    yield put(UserDeviceAction.pcSystemTypeSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(UserDeviceAction.pcSystemTypeFailed(errorMessage));
  }
}

export function* getPCMyzenVersion(action) {
  const config = {
    method: "POST",
    url: `query/devices/platform_myzen_version`,

    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);

    yield put(UserDeviceAction.pcMyzenVersionSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(UserDeviceAction.pcMyzenVersionFailed(errorMessage));
  }
}
export function* getPcUserDeviceInfoDownload(action) {
  const config = {
    method: "POST",
    url: `query/devices/user_device_details`,

    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);

    yield put(UserDeviceAction.pcUserDeviceInfoDownloadSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(UserDeviceAction.pcUserDeviceInfoDownloadFailed(errorMessage));
  }
}

export function* getMobileUserDeviceInfoDownload(action) {
  const config = {
    method: "POST",
    url: `query/devices/user_device_details`,

    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);

    yield put(
      UserDeviceAction.mobileUserDeviceInfoDownloadSuccess(response.data)
    );
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(
      UserDeviceAction.mobileUserDeviceInfoDownloadFailed(errorMessage)
    );
  }
}
