import * as actionTypes from "./actionTypes";

const initialState = {
  loading: false,
  errorMessage: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.DOWNLOAD_DYNAMIC_REPORT:
      return {
        ...state,
        loading: true,
        errorMessage: "",
      };
    case actionTypes.DOWNLOAD_DYNAMIC_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.DOWNLOAD_DYNAMIC_REPORT_FAILED:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};
