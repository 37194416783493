import * as actionTypes from "./actionTypes";
import _ from "lodash";

const searchApplicationDetailedApp = (dataset, search) => {
  if (search.length > 0) {
    var searchdata = _.filter(dataset, (list) => {
      return list.application_name
        .toLowerCase()
        .indexOf(search.toLowerCase()) >= 0
        ? true
        : false;
    });

    return searchdata;
  } else {
    return dataset;
  }
};

const searchApplicationDetailedUrl = (dataset, search) => {
  if (search.length > 0) {
    var searchdata = _.filter(dataset, (list) => {
      return list.url.toLowerCase().indexOf(search.toLowerCase()) >= 0
        ? true
        : false;
    });

    return searchdata;
  } else {
    return dataset;
  }
};

const searchApplicationDetailedCategory = (dataset, search) => {
  if (search.length > 0) {
    var searchdata = _.filter(dataset, (list) => {
      return list.cate_name.toLowerCase().indexOf(search.toLowerCase()) >= 0
        ? true
        : false;
    });

    return searchdata;
  } else {
    return dataset;
  }
};
const initialState = {
  //*GLOBAL*//

  //* APPLICATION detailed WORKIGN TIME TRENDS  *//

  application_detailed_category_list_processing: false,
  application_detailed_category_list_success: false,
  application_detailed_category_list_failed: false,
  application_detailed_category_list_data: [],
  application_detailed_category_list_message: "",
  application_detailed_category_list_data_master: [],

  //* APPLICATION detailed team_wise_url_utilization *//

  application_detailed_team_wise_url_utilization_processing: false,
  application_detailed_team_wise_url_utilization_success: false,
  application_detailed_team_wise_url_utilization_failed: false,
  application_detailed_team_wise_url_utilization_data: [],
  application_detailed_team_wise_url_utilization_message: "",

  //* APPLICATION detailed team_wise_app_utilization *//

  application_detailed_team_wise_app_utilization_processing: false,
  application_detailed_team_wise_app_utilization_success: false,
  application_detailed_team_wise_app_utilization_failed: false,
  application_detailed_team_wise_app_utilization_data: [],
  application_detailed_team_wise_app_utilization_message: "",

  //* APPLICATION detailed application_wise_app_url_utilization *//

  application_detailed_application_wise_app_url_utilization_processing: false,
  application_detailed_application_wise_app_url_utilization_success: false,
  application_detailed_application_wise_app_url_utilization_failed: false,
  application_detailed_application_wise_app_url_utilization_data: [],
  application_detailed_application_wise_app_url_utilization_message: "",

  //* APPLICATION detailed SEARCH_WISE_APP_DETAIL *//

  application_detailed_search_wise_app_detail_processing: false,
  application_detailed_search_wise_app_detail_success: false,
  application_detailed_search_wise_app_detail_failed: false,
  application_detailed_search_wise_app_detail_data: [],
  application_detailed_search_wise_app_detail_data_master: [],
  application_detailed_search_wise_app_detail_message: "",

  //* APPLICATION detailed SEARCH_WISE_URL_DETAIL *//

  application_detailed_search_wise_url_detail_processing: false,
  application_detailed_search_wise_url_detail_success: false,
  application_detailed_search_wise_url_detail_failed: false,
  application_detailed_search_wise_url_detail_data: [],
  application_detailed_search_wise_url_detail_data_master: [],
  application_detailed_search_wise_url_detail_message: "",

  //* APPLICATION detailed SEARCH_WISE_USER_APP_DETAIL *//

  application_detailed_search_wise_user_app_detail_processing: true,
  application_detailed_search_wise_user_app_detail_success: false,
  application_detailed_search_wise_user_app_detail_failed: false,
  application_detailed_search_wise_user_app_detail_data: [],
  application_detailed_search_wise_user_app_detail_message: "",

  //* APPLICATION detailed SEARCH_WISE_USER_APP_DETAIL END*//

  //* APPLICATION detailed SEARCH_WISE_USER_URL_DETAIL *//

  application_detailed_search_wise_user_url_detail_processing: true,
  application_detailed_search_wise_user_url_detail_success: false,
  application_detailed_search_wise_user_url_detail_failed: false,
  application_detailed_search_wise_user_url_detail_data: [],
  application_detailed_search_wise_user_url_detail_message: "",

  //* APPLICATION detailed SEARCH_WISE_USER_URL_DETAIL END*//

  //* APPLICATION detailed SEARCH_WISE_GRAPH_APP_DETAIL *//

  application_detailed_search_wise_graph_app_detail_processing: true,
  application_detailed_search_wise_graph_app_detail_success: false,
  application_detailed_search_wise_graph_app_detail_failed: false,
  application_detailed_search_wise_graph_app_detail_data: [],
  application_detailed_search_wise_graph_app_detail_message: "",

  //* APPLICATION detailed SEARCH_WISE_graph_app_DETAIL END*//

  //* APPLICATION detailed SEARCH_WISE_Graph_URL_DETAIL *//

  application_detailed_search_wise_graph_url_detail_processing: true,
  application_detailed_search_wise_graph_url_detail_success: false,
  application_detailed_search_wise_graph_url_detail_failed: false,
  application_detailed_search_wise_graph_url_detail_data: [],
  application_detailed_search_wise_graph_url_detail_message: "",

  //* APPLICATION detailed SEARCH_WISE_graph_url_DETAIL END*//

  //* APPLICATION detailed SEARCH_WISE_TITLE_APP_DETAIL *//

  application_detailed_total_application_usage_processing: false,
  application_detailed_total_application_usage_success: false,
  application_detailed_total_application_usage_failed: false,
  application_detailed_total_application_usage_data: [],
  application_detailed_total_application_usage_message: "",

  application_detailed_total_url_usage_processing: false,
  application_detailed_total_url_usage_success: false,
  application_detailed_total_url_usage_failed: false,
  application_detailed_total_url_usage_data: [],
  application_detailed_total_url_usage_message: "",

  //* APPLICATION detailed SEARCH_WISE_title_app_DETAIL END*//

  //* APPLICATION detailed SEARCH_WISE_title_url_DETAIL *//

  application_detailed_search_wise_title_url_detail_processing: true,
  application_detailed_search_wise_title_url_detail_success: false,
  application_detailed_search_wise_title_url_detail_failed: false,
  application_detailed_search_wise_title_url_detail_data: [],
  application_detailed_search_wise_title_url_detail_message: "",

  //* APPLICATION detailed SEARCH_WISE_title_url_DETAIL END*//
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.APPLICATION_DETAILED_CATEGORY_LIST_INITIAL:
      return {
        ...state,
        application_detailed_category_list_processing: false,
        application_detailed_category_list_success: false,
        application_detailed_category_list_failed: false,
        application_detailed_category_list_data: [],
        application_detailed_category_list_message: "",
        application_detailed_category_list_data_master: [],
      };

    case actionTypes.APPLICATION_DETAILED_CATEGORY_LIST_PROCESS:
      return {
        ...state,
        application_detailed_category_list_processing: true,
        application_detailed_category_list_success: false,
        application_detailed_category_list_failed: false,
        application_detailed_category_list_data: [],
        application_detailed_category_list_message: "",
        application_detailed_category_list_data_master: [],
      };

    case actionTypes.APPLICATION_DETAILED_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        application_detailed_category_list_processing: false,
        application_detailed_category_list_success: true,
        application_detailed_category_list_failed: false,
        application_detailed_category_list_data: action.data,
        application_detailed_category_list_message: "",
        application_detailed_category_list_data_master: action.data,
      };
    case actionTypes.APPLICATION_DETAILED_CATEGORY_LIST_FAILED:
      return {
        ...state,
        application_detailed_category_list_processing: false,
        application_detailed_category_list_success: false,
        application_detailed_category_list_failed: true,
        application_detailed_category_list_data: [],
        application_detailed_category_list_data_master: [],

        application_detailed_category_list_message: action.data,
      };
    case actionTypes.APPLICATION_DETAILED_TEAM_WISE_URL_UTILIZATION_INITIAL:
      return {
        ...state,
        application_detailed_team_wise_url_utilization_processing: false,
        application_detailed_team_wise_url_utilization_success: false,
        application_detailed_team_wise_url_utilization_failed: false,
        application_detailed_team_wise_url_utilization_data: [],
        application_detailed_team_wise_url_utilization_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_TEAM_WISE_URL_UTILIZATION_PROCESS:
      return {
        ...state,
        application_detailed_team_wise_url_utilization_processing: true,
        application_detailed_team_wise_url_utilization_success: false,
        application_detailed_team_wise_url_utilization_failed: false,
        application_detailed_team_wise_url_utilization_data: [],
        application_detailed_team_wise_url_utilization_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_TEAM_WISE_URL_UTILIZATION_SUCCESS:
      return {
        ...state,
        application_detailed_team_wise_url_utilization_processing: false,
        application_detailed_team_wise_url_utilization_success: true,
        application_detailed_team_wise_url_utilization_failed: false,
        application_detailed_team_wise_url_utilization_data: action.data,
        application_detailed_team_wise_url_utilization_message: "",
      };
    case actionTypes.APPLICATION_DETAILED_TEAM_WISE_URL_UTILIZATION_FAILED:
      return {
        ...state,
        application_detailed_team_wise_url_utilization_processing: false,
        application_detailed_team_wise_url_utilization_success: false,
        application_detailed_team_wise_url_utilization_failed: true,
        application_detailed_team_wise_url_utilization_data: action.data,
        application_detailed_team_wise_url_utilization_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_TEAM_WISE_APP_UTILIZATION_INITIAL:
      return {
        ...state,
        application_detailed_team_wise_app_utilization_processing: false,
        application_detailed_team_wise_app_utilization_success: false,
        application_detailed_team_wise_app_utilization_failed: false,
        application_detailed_team_wise_app_utilization_data: [],
        application_detailed_team_wise_app_utilization_message: "",
      };
    case actionTypes.APPLICATION_DETAILED_TEAM_WISE_APP_UTILIZATION_PROCESS:
      return {
        ...state,
        application_detailed_team_wise_app_utilization_processing: true,
        application_detailed_team_wise_app_utilization_success: false,
        application_detailed_team_wise_app_utilization_failed: false,
        application_detailed_team_wise_app_utilization_data: [],
        application_detailed_team_wise_app_utilization_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_TEAM_WISE_APP_UTILIZATION_SUCCESS:
      return {
        ...state,
        application_detailed_team_wise_app_utilization_processing: false,
        application_detailed_team_wise_app_utilization_success: true,
        application_detailed_team_wise_app_utilization_failed: false,
        application_detailed_team_wise_app_utilization_data: action.data,
        application_detailed_team_wise_app_utilization_message: "",
      };
    case actionTypes.APPLICATION_DETAILED_TEAM_WISE_APP_UTILIZATION_FAILED:
      return {
        ...state,
        application_detailed_team_wise_app_utilization_processing: false,
        application_detailed_team_wise_app_utilization_success: false,
        application_detailed_team_wise_app_utilization_failed: true,
        application_detailed_team_wise_app_utilization_data: action.data,
        application_detailed_team_wise_app_utilization_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_URL_DETAIL_INITIAL:
      return {
        ...state,
        application_detailed_search_wise_url_detail_processing: false,
        application_detailed_search_wise_url_detail_success: false,
        application_detailed_search_wise_url_detail_failed: false,
        application_detailed_search_wise_url_detail_data: [],
        application_detailed_search_wise_url_detail_data_master: [],
        application_detailed_search_wise_url_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_URL_DETAIL_PROCESS:
      return {
        ...state,
        application_detailed_search_wise_url_detail_processing: true,
        application_detailed_search_wise_url_detail_success: false,
        application_detailed_search_wise_url_detail_failed: false,
        application_detailed_search_wise_url_detail_data: [],
        application_detailed_search_wise_url_detail_data_master: [],
        application_detailed_search_wise_url_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_URL_DETAIL_SUCCESS:
      return {
        ...state,
        application_detailed_search_wise_url_detail_processing: false,
        application_detailed_search_wise_url_detail_success: true,
        application_detailed_search_wise_url_detail_failed: false,
        application_detailed_search_wise_url_detail_data: action.data,
        application_detailed_search_wise_url_detail_data_master: action.data,
        application_detailed_search_wise_url_detail_message: "",
      };
    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_URL_DETAIL_FAILED:
      return {
        ...state,
        application_detailed_search_wise_url_detail_processing: false,
        application_detailed_search_wise_url_detail_success: false,
        application_detailed_search_wise_url_detail_failed: true,
        application_detailed_search_wise_url_detail_data: action.data,
        application_detailed_search_wise_url_detail_data_master: action.data,
        application_detailed_search_wise_url_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_APP_DETAIL_INITIAL:
      return {
        ...state,
        application_detailed_search_wise_app_detail_processing: false,
        application_detailed_search_wise_app_detail_success: false,
        application_detailed_search_wise_app_detail_failed: false,
        application_detailed_search_wise_app_detail_data: [],
        application_detailed_search_wise_app_detail_data_master: [],
        application_detailed_search_wise_app_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_APP_DETAIL_PROCESS:
      return {
        ...state,
        application_detailed_search_wise_app_detail_processing: true,
        application_detailed_search_wise_app_detail_success: false,
        application_detailed_search_wise_app_detail_failed: false,
        application_detailed_search_wise_app_detail_data: [],
        application_detailed_search_wise_app_detail_data_master: [],
        application_detailed_search_wise_app_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_APP_DETAIL_SUCCESS:
      return {
        ...state,
        application_detailed_search_wise_app_detail_processing: false,
        application_detailed_search_wise_app_detail_success: true,
        application_detailed_search_wise_app_detail_failed: false,
        application_detailed_search_wise_app_detail_data: action.data,
        application_detailed_search_wise_app_detail_data_master: action.data,
        application_detailed_search_wise_app_detail_message: "",
      };
    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_APP_DETAIL_FAILED:
      return {
        ...state,
        application_detailed_search_wise_app_detail_processing: false,
        application_detailed_search_wise_app_detail_success: false,
        application_detailed_search_wise_app_detail_failed: true,
        application_detailed_search_wise_app_detail_data: [],
        application_detailed_search_wise_app_detail_data_master: [],
        application_detailed_search_wise_app_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_GRAPH_APP_DETAIL_INITIAL:
      return {
        ...state,
        application_detailed_search_wise_graph_app_detail_processing: true,
        application_detailed_search_wise_graph_app_detail_success: false,
        application_detailed_search_wise_graph_app_detail_failed: false,
        application_detailed_search_wise_graph_app_detail_data: [],
        application_detailed_search_wise_graph_app_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_GRAPH_APP_DETAIL_PROCESS:
      return {
        ...state,
        application_detailed_search_wise_graph_app_detail_processing: true,
        application_detailed_search_wise_graph_app_detail_success: false,
        application_detailed_search_wise_graph_app_detail_failed: false,
        application_detailed_search_wise_graph_app_detail_data: [],
        application_detailed_search_wise_graph_app_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_GRAPH_APP_DETAIL_SUCCESS:
      return {
        ...state,
        application_detailed_search_wise_graph_app_detail_processing: false,
        application_detailed_search_wise_graph_app_detail_success: true,
        application_detailed_search_wise_graph_app_detail_failed: false,
        application_detailed_search_wise_graph_app_detail_data: action.data,
        application_detailed_search_wise_graph_app_detail_message: "",
      };
    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_GRAPH_APP_DETAIL_FAILED:
      return {
        ...state,
        application_detailed_search_wise_graph_app_detail_processing: false,
        application_detailed_search_wise_graph_app_detail_success: false,
        application_detailed_search_wise_graph_app_detail_failed: true,
        application_detailed_search_wise_graph_app_detail_data: [],
        application_detailed_search_wise_graph_app_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_GRAPH_URL_DETAIL_INITIAL:
      return {
        ...state,
        application_detailed_search_wise_graph_url_detail_processing: true,
        application_detailed_search_wise_graph_url_detail_success: false,
        application_detailed_search_wise_graph_url_detail_failed: false,
        application_detailed_search_wise_graph_url_detail_data: [],
        application_detailed_search_wise_graph_url_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_GRAPH_URL_DETAIL_PROCESS:
      return {
        ...state,
        application_detailed_search_wise_graph_url_detail_processing: true,
        application_detailed_search_wise_graph_url_detail_success: false,
        application_detailed_search_wise_graph_url_detail_failed: false,
        application_detailed_search_wise_graph_url_detail_data: [],
        application_detailed_search_wise_graph_url_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_GRAPH_URL_DETAIL_SUCCESS:
      return {
        ...state,
        application_detailed_search_wise_graph_url_detail_processing: false,
        application_detailed_search_wise_graph_url_detail_success: true,
        application_detailed_search_wise_graph_url_detail_failed: false,
        application_detailed_search_wise_graph_url_detail_data: action.data,
        application_detailed_search_wise_graph_url_detail_message: "",
      };
    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_GRAPH_URL_DETAIL_FAILED:
      return {
        ...state,
        application_detailed_search_wise_graph_url_detail_processing: false,
        application_detailed_search_wise_graph_url_detail_success: false,
        application_detailed_search_wise_graph_url_detail_failed: true,
        application_detailed_search_wise_graph_url_detail_data: [],
        application_detailed_search_wise_graph_url_detail_message: "",
      };
    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_TITLE_APP_DETAIL_INITIAL:
      return {
        ...state,
        application_detailed_search_wise_title_app_detail_processing: true,
        application_detailed_search_wise_title_app_detail_success: false,
        application_detailed_search_wise_title_app_detail_failed: false,
        application_detailed_search_wise_title_app_detail_data: [],
        application_detailed_search_wise_title_app_detail_message: "",
      };
    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_TITLE_APP_DETAIL_PROCESS:
      return {
        ...state,
        application_detailed_search_wise_title_app_detail_processing: true,
        application_detailed_search_wise_title_app_detail_success: false,
        application_detailed_search_wise_title_app_detail_failed: false,
        application_detailed_search_wise_title_app_detail_data: [],
        application_detailed_search_wise_title_app_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_TITLE_APP_DETAIL_SUCCESS:
      return {
        ...state,
        application_detailed_search_wise_title_app_detail_processing: false,
        application_detailed_search_wise_title_app_detail_success: true,
        application_detailed_search_wise_title_app_detail_failed: false,
        application_detailed_search_wise_title_app_detail_data: action.data,
        application_detailed_search_wise_title_app_detail_message: "",
      };
    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_TITLE_APP_DETAIL_FAILED:
      return {
        ...state,
        application_detailed_search_wise_title_app_detail_processing: false,
        application_detailed_search_wise_title_app_detail_success: false,
        application_detailed_search_wise_title_app_detail_failed: true,
        application_detailed_search_wise_title_app_detail_data: [],
        application_detailed_search_wise_title_app_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_TITLE_URL_DETAIL_INITIAL:
      return {
        ...state,
        application_detailed_search_wise_title_url_detail_processing: true,
        application_detailed_search_wise_title_url_detail_success: false,
        application_detailed_search_wise_title_url_detail_failed: false,
        application_detailed_search_wise_title_url_detail_data: [],
        application_detailed_search_wise_title_url_detail_message: "",
      };
    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_TITLE_URL_DETAIL_PROCESS:
      return {
        ...state,
        application_detailed_search_wise_title_url_detail_processing: true,
        application_detailed_search_wise_title_url_detail_success: false,
        application_detailed_search_wise_title_url_detail_failed: false,
        application_detailed_search_wise_title_url_detail_data: [],
        application_detailed_search_wise_title_url_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_TITLE_URL_DETAIL_SUCCESS:
      return {
        ...state,
        application_detailed_search_wise_title_url_detail_processing: false,
        application_detailed_search_wise_title_url_detail_success: true,
        application_detailed_search_wise_title_url_detail_failed: false,
        application_detailed_search_wise_title_url_detail_data: action.data,
        application_detailed_search_wise_title_url_detail_message: "",
      };
    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_TITLE_URL_DETAIL_FAILED:
      return {
        ...state,
        application_detailed_search_wise_title_url_detail_processing: false,
        application_detailed_search_wise_title_url_detail_success: false,
        application_detailed_search_wise_title_url_detail_failed: true,
        application_detailed_search_wise_title_url_detail_data: [],
        application_detailed_search_wise_title_url_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_USER_APP_DETAIL_INITIAL:
      return {
        ...state,
        application_detailed_search_wise_user_app_detail_processing: true,
        application_detailed_search_wise_user_app_detail_success: false,
        application_detailed_search_wise_user_app_detail_failed: false,
        application_detailed_search_wise_user_app_detail_data: [],
        application_detailed_search_wise_user_app_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_USER_APP_DETAIL_PROCESS:
      return {
        ...state,
        application_detailed_search_wise_user_app_detail_processing: true,
        application_detailed_search_wise_user_app_detail_success: false,
        application_detailed_search_wise_user_app_detail_failed: false,
        application_detailed_search_wise_user_app_detail_data: [],
        application_detailed_search_wise_user_app_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_USER_APP_DETAIL_SUCCESS:
      return {
        ...state,
        application_detailed_search_wise_user_app_detail_processing: false,
        application_detailed_search_wise_user_app_detail_success: true,
        application_detailed_search_wise_user_app_detail_failed: false,
        application_detailed_search_wise_user_app_detail_data: action.data,
        application_detailed_search_wise_user_app_detail_message: "",
      };
    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_USER_APP_DETAIL_FAILED:
      return {
        ...state,
        application_detailed_search_wise_user_app_detail_processing: false,
        application_detailed_search_wise_user_app_detail_success: false,
        application_detailed_search_wise_user_app_detail_failed: true,
        application_detailed_search_wise_user_app_detail_data: action.data,
        application_detailed_search_wise_user_app_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_USER_URL_DETAIL_INITIAL:
      return {
        ...state,
        application_detailed_search_wise_user_url_detail_processing: true,
        application_detailed_search_wise_user_url_detail_success: false,
        application_detailed_search_wise_user_url_detail_failed: false,
        application_detailed_search_wise_user_url_detail_data: [],
        application_detailed_search_wise_user_url_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_USER_URL_DETAIL_PROCESS:
      return {
        ...state,
        application_detailed_search_wise_user_url_detail_processing: true,
        application_detailed_search_wise_user_url_detail_success: false,
        application_detailed_search_wise_user_url_detail_failed: false,
        application_detailed_search_wise_user_url_detail_data: [],
        application_detailed_search_wise_user_url_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_USER_URL_DETAIL_SUCCESS:
      return {
        ...state,
        application_detailed_search_wise_user_url_detail_processing: false,
        application_detailed_search_wise_user_url_detail_success: true,
        application_detailed_search_wise_user_url_detail_failed: false,
        application_detailed_search_wise_user_url_detail_data: action.data,
        application_detailed_search_wise_user_url_detail_message: "",
      };
    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_USER_URL_DETAIL_FAILED:
      return {
        ...state,
        application_detailed_search_wise_user_url_detail_processing: false,
        application_detailed_search_wise_user_url_detail_success: false,
        application_detailed_search_wise_user_url_detail_failed: true,
        application_detailed_search_wise_user_url_detail_data: action.data,
        application_detailed_search_wise_user_url_detail_message: "",
      };
    case actionTypes.APPLICATION_DETAILED_TOTAL_APPLICATION_USAGE_INITIAL:
      return {
        ...state,
        application_detailed_total_application_usage_processing: false,
        application_detailed_total_application_usage_success: false,
        application_detailed_total_application_usage_failed: false,
        application_detailed_total_application_usage_data: [],
        application_detailed_total_application_usage_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_TOTAL_APPLICATION_USAGE_PROCESS:
      return {
        ...state,
        application_detailed_total_application_usage_processing: true,
        application_detailed_total_application_usage_success: false,
        application_detailed_total_application_usage_failed: false,
        application_detailed_total_application_usage_data: [],
        application_detailed_total_application_usage_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_TOTAL_APPLICATION_USAGE_SUCCESS:
      return {
        ...state,
        application_detailed_total_application_usage_processing: false,
        application_detailed_total_application_usage_success: true,
        application_detailed_total_application_usage_failed: false,
        application_detailed_total_application_usage_data: action.data,
        application_detailed_total_application_usage_message: "",
      };
    case actionTypes.APPLICATION_DETAILED_TOTAL_APPLICATION_USAGE_FAILED:
      return {
        ...state,
        application_detailed_total_application_usage_processing: false,
        application_detailed_total_application_usage_success: false,
        application_detailed_total_application_usage_failed: true,
        application_detailed_total_application_usage_data: [],
        application_detailed_total_application_usage_message: action.data,
      };

    case actionTypes.APPLICATION_DETAILED_TOTAL_URL_USAGE_INITIAL:
      return {
        ...state,
        application_detailed_total_url_usage_processing: false,
        application_detailed_total_url_usage_success: false,
        application_detailed_total_url_usage_failed: false,
        application_detailed_total_url_usage_data: [],
        application_detailed_total_url_usage_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_TOTAL_URL_USAGE_PROCESS:
      return {
        ...state,
        application_detailed_total_url_usage_processing: true,
        application_detailed_total_url_usage_success: false,
        application_detailed_total_url_usage_failed: false,
        application_detailed_total_url_usage_data: [],
        application_detailed_total_url_usage_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_TOTAL_URL_USAGE_SUCCESS:
      return {
        ...state,
        application_detailed_total_url_usage_processing: false,
        application_detailed_total_url_usage_success: true,
        application_detailed_total_url_usage_failed: false,
        application_detailed_total_url_usage_data: action.data,
        application_detailed_total_url_usage_message: "",
      };
    case actionTypes.APPLICATION_DETAILED_TOTAL_URL_USAGE_FAILED:
      return {
        ...state,
        application_detailed_total_url_usage_processing: false,
        application_detailed_total_url_usage_success: false,
        application_detailed_total_url_usage_failed: true,
        application_detailed_total_url_usage_data: action.data,
        application_detailed_total_url_usage_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_APP_SEARCH:
      return {
        ...state,
        application_detailed_search_wise_app_detail_data:
          searchApplicationDetailedApp(
            state.application_detailed_search_wise_app_detail_data_master,
            action.data
          ),
      };
    case actionTypes.APPLICATION_DETAILED_URL_SEARCH:
      return {
        ...state,
        application_detailed_search_wise_url_detail_data:
          searchApplicationDetailedUrl(
            state.application_detailed_search_wise_url_detail_data_master,
            action.data
          ),
      };

    case actionTypes.APPLICATION_DETAILED_CATEGORY_LIST_SEARCH:
      return {
        ...state,
        application_detailed_category_list_data:
          searchApplicationDetailedCategory(
            state.application_detailed_category_list_data_master,
            action.data
          ),
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_APP_URL_DETAIL_INITIAL:
      return {
        ...state,
        application_detailed_search_wise_url_detail_processing: false,
        application_detailed_search_wise_url_detail_success: false,
        application_detailed_search_wise_url_detail_failed: false,
        application_detailed_search_wise_url_detail_data: [],
        application_detailed_search_wise_url_detail_data_master: [],
        application_detailed_search_wise_url_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_APP_URL_DETAIL_PROCESS:
      return {
        ...state,
        application_detailed_search_wise_app_url_detail_processing: true,
        application_detailed_search_wise_app_url_detail_success: false,
        application_detailed_search_wise_app_url_detail_failed: false,
        application_detailed_search_wise_app_url_detail_data: [],
        application_detailed_search_wise_app_url_detail_data_master: [],
        application_detailed_search_wise_app_url_detail_message: "",
      };

    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_APP_URL_DETAIL_SUCCESS:
      return {
        ...state,
        application_detailed_search_wise_app_url_detail_processing: false,
        application_detailed_search_wise_app_url_detail_success: true,
        application_detailed_search_wise_app_url_detail_failed: false,
        application_detailed_search_wise_app_url_detail_data: action.data,
        application_detailed_search_wise_app_url_detail_data_master:
          action.data,
        application_detailed_search_wise_app_url_detail_message: "",
      };
    case actionTypes.APPLICATION_DETAILED_SEARCH_WISE_APP_URL_DETAIL_FAILED:
      return {
        ...state,
        application_detailed_search_wise_app_url_detail_processing: false,
        application_detailed_search_wise_app_url_detail_success: false,
        application_detailed_search_wise_app_url_detail_failed: true,
        application_detailed_search_wise_app_url_detail_data: action.data,
        application_detailed_search_wise_app_url_detail_data_master:
          action.data,
        application_detailed_search_wise_app_url_detail_message: "",
      };

    default:
      return state;
  }
};
