import * as actionTypes from "./actionTypes";

const initialPagination = { totalCount: 0 };

const initialState = {
  fetchmanualtimeLoading: true,
  manualtimeData: [],
  pagination: initialPagination,
  manualtimeLoading: false,
  manualtimeSuccess: false,
  deletemanualtimeSuccess: false,
  deletemanualtimeLoading: false,
  manualtimeErrorMessage: "",
  downloadManualTimeReportLoading: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_MANUALTIME:
      return {
        ...state,
        fetchmanualtimeLoading: true,
        manualtimeErrorMessage: "",
        manualtimeData: [],
      };

    case actionTypes.FETCH_MANUALTIME_SUCCESS:
      return {
        ...state,
        fetchmanualtimeLoading: false,
        manualtimeData: action.payload.data,
        pagination: action.payload.meta || state.pagination,
      };

    case actionTypes.FETCH_MANUALTIME_FAILED:
      return {
        ...state,
        fetchmanualtimeLoading: false,
        manualtimeData: [],
        pagination: initialPagination,
        manualtimeErrorMessage: action.payload,
      };

    case actionTypes.CREATE_MANUALTIME:
      return {
        ...state,
        manualtimeLoading: true,
        manualtimeErrorMessage: "",
        manualtimeSuccess: false,
      };

    case actionTypes.CREATE_MANUALTIME_SUCCESS: {
      return {
        ...state,
        manualtimeLoading: false,
        manualtimeData: {
          ...state.manualtimeData,
          [action.payload.id]: action.payload,
        },
        pagination: {
          ...state.pagination,
          totalCount: state.pagination.totalCount + 1,
        },
        manualtimeSuccess: true,
      };
    }

    case actionTypes.CREATE_MANUALTIME_FAILED:
      return {
        ...state,
        manualtimeLoading: false,
        manualtimeErrorMessage: action.payload,
      };

    case actionTypes.UPDATE_MANUALTIME:
      return {
        ...state,
        manualtimeLoading: true,
        manualtimeErrorMessage: "",
        manualtimeSuccess: false,
      };

    case actionTypes.UPDATE_MANUALTIME_SUCCESS:
      return {
        ...state,
        manualtimeLoading: false,
        manualtimeData: {
          ...state.manualtimeData,
          [action.payload.id]: action.payload,
        },
        manualtimeSuccess: true,
      };

    case actionTypes.UPDATE_MANUALTIME_FAILED:
      return {
        ...state,
        manualtimeLoading: false,
        manualtimeErrorMessage: action.payload,
      };

    case actionTypes.SET_MANUALTIME_ERROR_MESSAGE:
      return {
        ...state,
        manualtimeErrorMessage: action.payload,
      };

    case actionTypes.DELETE_MANUALTIME:
      return {
        ...state,
        deletemanualtimeLoading: true,
        manualtimeErrorMessage: "",
        manualtimeSuccess: false,
      };

    case actionTypes.DELETE_MANUALTIME_SUCCESS:
      return {
        ...state,
        deletemanualtimeLoading: false,
        manualtimeSuccess: true,
      };

    case actionTypes.DELETE_MANUALTIME_FAILED:
      return {
        ...state,
        deletemanualtimeLoading: false,
        manualtimeErrorMessage: action.payload,
      };
    case actionTypes.DOWNLOAD_MANUALTIME:
      return {
        ...state,
        downloadManualTimeReportLoading: true,
        manualtimeErrorMessage: "",
      };

    case actionTypes.DOWNLOAD_MANUALTIME_SUCCESS:
      return {
        ...state,
        downloadManualTimeReportLoading: false,
      };

    case actionTypes.DOWNLOAD_MANUALTIME_FAILED:
      return {
        ...state,
        downloadManualTimeReportLoading: false,
        manualtimeErrorMessage: action.payload,
      };

    default:
      return state;
  }
};
