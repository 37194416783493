/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { InputNumber, notification } from "antd";
import { useSelector } from "react-redux";

const ChangeUsage = ({ selectedPlan, setValue, value }) => {
  const {
    subscriptionsData,
    initiateSubscriptionLoading,
    updateSubscriptionLoading,
  } = useSelector((state) => state.billing);

  const onChange = (value) => {
    setValue(value);
  };

  const minUsersCount = Math.max(
    subscriptionsData.activeUsers,
    1 // selectedPlan.subscription_frequency === "Monthly" ? 15 : 5
  );

  useEffect(() => {
    if (value < minUsersCount) {
      setValue(minUsersCount);
    }
  }, [selectedPlan.subscription_frequency]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col mb-[10px] pb-4 border-b border-slate-300">
        {/* <div className="flex items-center justify-center w-[55px] h-[55px] text-[35px]  rounded-[50%] bg-indigo-100 text-indigo-500">
          <FiUser />
        </div> */}
        <div className="flex items-center  text-[15px] font-bold text-slate-800 mt-[24px]">
          {" "}
          Plan
        </div>
        <div className="flex items-center  text-[15px] font-medium text-slate-400 mt-[5px]">
          {selectedPlan.name}
        </div>
        <div className="flex items-center  text-[15px] font-bold text-slate-800 mt-[24px]">
          {" "}
          Current License usage
        </div>
        <div className="flex items-center  text-[15px] font-medium text-slate-400 mt-[5px]">
          {" "}
          {`${subscriptionsData.activeUsers}/${subscriptionsData.totalAllowedUsers}`}
        </div>
      </div>

      <div className="flex flex-col pb-3 ">
        <div className="flex items-center  text-[15px] font-medium text-slate-400 mt-[10px]">
          {" "}
          license count{" "}
        </div>
        <div className="flex items-center border border-slate-300 justify-between rounded-[5px] overflow-hidden my-[10px]">
          <div
            className="flex items-center justify-center w-9 h-8 border-r text-[16px] border-slate-300 bg-slate-100 hover:bg-slate-400 hover:text-white cursor-pointer"
            onClick={() => {
              if (value - 1 < subscriptionsData.activeUsers) {
                notification.warning({
                  message: "Please deactivate some users first on Setting page",
                });
              } else if (value - 1 < minUsersCount) {
                notification.warning({
                  message:
                    "There is a requirement of minimum 1 licenses on monthly recurring subscriptions",
                });
              } else {
                setValue(
                  value - 1 < subscriptionsData.activeUsers
                    ? subscriptionsData.activeUsers
                    : value - 1
                );
              }
            }}
          >
            <AiOutlineMinus />
          </div>
          <InputNumber
            disabled={initiateSubscriptionLoading || updateSubscriptionLoading}
            autoFocus
            value={value}
            onChange={onChange}
            min={minUsersCount}
            max={
              selectedPlan.plan_type.plan_features.features.MAX_USERS.data
                .endRange
            }
            bordered={false}
            className="w-full text-center"
            controls={false}
            precision={0}
          />

          <div
            className="flex items-center justify-center w-9 h-8 border-l text-[16px] border-slate-300 bg-slate-100 hover:bg-slate-400 hover:text-white cursor-pointer"
            onClick={() => {
              setValue(
                value + 1 <=
                  selectedPlan.plan_type.plan_features.features.MAX_USERS.data
                    .endRange
                  ? value + 1
                  : selectedPlan.plan_type.plan_features.features.MAX_USERS.data
                      .endRange
              );
            }}
          >
            <AiOutlinePlus />
          </div>
        </div>
      </div>

      <div className="flex flex-col pb-4 ">
        <div className="flex items-center  text-[15px] font-medium text-slate-400 ">
          New Pricing
        </div>
        <div className="flex items-center mt-[5px]">
          <div className=" text-[15px] font-bold text-slate-800 mr-2">
            {selectedPlan.currency_code === "USD" ? "$" : "₹"}
            {selectedPlan.subscription_frequency === "Yearly"
              ? (selectedPlan.amount / 12).toLocaleString("en-US")
              : selectedPlan.amount.toLocaleString("en-US")}
          </div>
          <div className=" text-[13px] font-medium text-slate-400 ">
            {`/user/month`}
          </div>
        </div>
      </div>
      {subscriptionsData.plan.id === selectedPlan.id && (
        <div className="flex flex-col pb-4 ">
          <div className="flex items-center  text-[15px] font-medium text-slate-400 ">
            {subscriptionsData.billingStatus === "NON_RENEWING"
              ? "Expiry Date"
              : "Next Billing Date"}
          </div>
          <div className="flex items-center mt-[5px]">
            <div className=" text-[15px] font-bold text-slate-800 mr-2">
              {new Date(subscriptionsData.expiryDate).toLocaleDateString()}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChangeUsage;
