import { put } from "redux-saga/effects";
import { ProductivityDetailAction } from "../action";
import { Client } from "../../../utils/transport";

export function* GetProductivityDetailWorkingTimeTrend(data) {
  let config = {
    method: "POST",
    url: "query/productivity/total_working_time",

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,

    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ProductivityDetailAction.ProductivityDetailedWorkingTimeTrendSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ProductivityDetailAction.ProductivityDetailedWorkingTimeTrendFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ProductivityDetailAction.ProductivityDetailedWorkingTimeTrendFailed(err)
    );
  }
}

export function* GetProductivityDetailTeamWiseProductivityTrend(data) {
  let config = {
    method: "POST",
    url: "query/productivity/teamwise_productivity",

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ProductivityDetailAction.ProductivityDetaileTeamWiseProductivityTrendSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ProductivityDetailAction.ProductivityDetailedTeamWiseProductivityTrendFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ProductivityDetailAction.ProductivityDetailedTeamWiseProductivityTrendFailed(
        err
      )
    );
  }
}

export function* GetProductivityDetailedEmployeeList(data) {
  let config = {
    method: "POST",
    url: "query/productivity/productivity_employee_list",

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };

  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ProductivityDetailAction.ProductivityDetailedEmployeeListSuccess(
          response.data
        )
      );
    } else {
      yield put(
        ProductivityDetailAction.ProductivityDetailedEmployeeListFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ProductivityDetailAction.ProductivityDetailedEmployeeListFailed(err)
    );
  }
}

export function* GetProductivityEmployeeDetailedEmployeeList(data) {
  let config = {
    method: "POST",
    url: "query/productivity/productivity_employee_list",

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };

  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ProductivityDetailAction.ProductivityEmployeeDetailedEmployeeListSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ProductivityDetailAction.ProductivityEmployeeDetailedEmployeeListFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ProductivityDetailAction.ProductivityEmployeeDetailedEmployeeListFailed(
        err
      )
    );
  }
}
