import moment from "moment";

export const ExportCSV = (filename, data) => {
  //export csv
  var name = filename + moment().format("DD_MM_YYYY_hh_mm_ssz") + ".csv";
  download_csv(data.join("\n"), name);

  function download_csv(csv, name) {
    var csvFile;
    var downloadLink;

    // CSV FILE
    csvFile = new Blob([csv], { type: "text/csv" });

    // Download link
    downloadLink = document.createElement("a");

    // File name
    downloadLink.download = name;

    // We have to create a link to the file
    downloadLink.href = window.URL.createObjectURL(csvFile);

    // Make sure that the link is not displayed
    downloadLink.style.display = "none";

    // Add the link to your DOM
    document.body.appendChild(downloadLink);

    // Lanzamos
    downloadLink.click();
  }
};
