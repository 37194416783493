/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import UserMonthlyActivityTrend from "./UserMonthlyActivityTrend";
import { Divider } from "antd";
import { getHoursAndMinutesFromSeconds } from "utils/timeConverter";
import dayjs from "dayjs";

const UserActivityTrend = (props) => {
  const { teamWiseActivityTrendData, employeeListData } = props;
  const [mostActiveDate, setMostActiveDate] = useState("");

  useEffect(() => {
    if (Object.keys(teamWiseActivityTrendData).length > 0) {
      const { date } = teamWiseActivityTrendData;
      const maxActivityTimeIndex =
        teamWiseActivityTrendData?.activityTime.reduce(
          (maxIndex, currentValue, currentIndex, array) => {
            return currentValue > array[maxIndex] ? currentIndex : maxIndex;
          },
          0
        );

      // Get the corresponding date
      const mostActiveDate = date[maxActivityTimeIndex];
      setMostActiveDate(mostActiveDate);
    }
  }, [teamWiseActivityTrendData]);
  // Find the index of the maximum activityTime

  const activityPer =
    employeeListData.length > 0 && employeeListData[0].activityper;
  return (
    <>
      <div className="bg-white border  rounded-[5px] mt-4 p-4 overflow-hidden rounded-lg border-[1px] border-solid border-gray-200 bg-white shadow-sm">
        <div className="flex items-center justify-between ">
          <h3 className="font-semibold text-[16px] text-slate-500 mt-0">
            Activity
          </h3>
        </div>
        <div className="w-full bg-neutral-200">
          <div
            className={`${
              activityPer >= 0 && activityPer <= 50
                ? "bg-[#14B7DB]"
                : activityPer > 50 && activityPer <= 75
                ? "bg-[#14B7DB]"
                : activityPer > 75
                ? "bg-[#14B7DB]"
                : "bg-white-100"
            } p-0.5 h-[4px] text-center text-xs font-medium leading-none text-slate-100`}
            style={{ width: activityPer + `%` }}
          ></div>
        </div>
        <div className="flex items-center justify-between pt-4 pb-4">
          <div className="font-extrabold text-[20px] text-[#000]">
            {activityPer}%
          </div>
        </div>{" "}
        <UserMonthlyActivityTrend
          teamWiseActivityTrendData={teamWiseActivityTrendData}
          // title={"Activity Trend"}
          tooltip={""}
          hideTooltip={true}
        />
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">Total online time</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {employeeListData.length > 0 &&
              getHoursAndMinutesFromSeconds(
                employeeListData[0].totalonlinetime
              )}
          </div>
        </div>
        <Divider />
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">Total active time</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {" "}
            {employeeListData.length > 0 &&
              getHoursAndMinutesFromSeconds(
                employeeListData[0].totalactivetime
              )}
          </div>
        </div>
        <Divider />
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">Total idle time</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {" "}
            {employeeListData.length > 0 &&
              getHoursAndMinutesFromSeconds(employeeListData[0].totalidletime)}
          </div>
        </div>
        <Divider />
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">Average online time</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {employeeListData.length > 0 &&
            employeeListData[0].totalonlinetime > 0
              ? getHoursAndMinutesFromSeconds(
                  employeeListData[0].totalonlinetime /
                    employeeListData[0].attendance
                )
              : "0h 0m"}
          </div>
        </div>
        <Divider />
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">Average active time</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {employeeListData.length > 0 &&
            employeeListData[0].totalactivetime > 0
              ? getHoursAndMinutesFromSeconds(
                  employeeListData[0].totalactivetime /
                    employeeListData[0].attendance
                )
              : "0h 0m"}
          </div>
        </div>
        <Divider />
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">Average idle time</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {employeeListData.length > 0 &&
            employeeListData[0].totalidletime > 0
              ? getHoursAndMinutesFromSeconds(
                  employeeListData[0].totalidletime /
                    employeeListData[0].attendance
                )
              : "0h 0m"}
          </div>
        </div>
        <div className="flex flex-row justify-between pt-4 pb-4">
          <div className="w-6/12">Most active day</div>
          <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
            {mostActiveDate &&
              dayjs(mostActiveDate).format("DD MMMM YYYY, dddd")}
          </div>
        </div>
      </div>
    </>
  );
};
export default UserActivityTrend;
