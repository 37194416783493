import * as actionTypes from "./actionTypes";

const initialState = {
  // *GLOBAL*//

  comparative_analysis_user_performance_processing: false,
  comparative_analysis_user_performance_success: false,
  comparative_analysis_user_performance_failed: false,
  comparative_analysis_user_performance_data: "",
  comparative_analysis_user_performance_message: "",

  comparative_attendance_user_performance_processing: false,
  comparative_attendance_user_performance_success: false,
  comparative_attendance_user_performance_failed: false,
  comparative_attendance_user_performance_data: "",
  comparative_attendance_user_performance_message: "",

  comparative_goal_user_performance_processing: false,
  comparative_goal_user_performance_success: false,
  comparative_goal_user_performance_failed: false,
  comparative_goal_user_performance_data: "",
  comparative_goal_user_performance_message: "",

  comparative_application_user_performance_processing: false,
  comparative_application_user_performance_success: false,
  comparative_application_user_performance_failed: false,
  comparative_application_user_performance_data: "",
  comparative_application_user_performance_message: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.COMPARATIVE_ANALYSIS_USER_PERFORMANCE_INITIAL:
      return {
        ...state,
        comparative_analysis_user_performance_processing: false,
        comparative_analysis_user_performance_success: false,
        comparative_analysis_user_performance_failed: false,
        comparative_analysis_user_performance_data: "",
        comparative_analysis_user_performance_message: "",
      };

    case actionTypes.COMPARATIVE_ANALYSIS_USER_PERFORMANCE_PROCESS:
      return {
        ...state,
        comparative_analysis_user_performance_processing: true,
        comparative_analysis_user_performance_success: false,
        comparative_analysis_user_performance_failed: false,
        comparative_analysis_user_performance_data: "",
        comparative_analysis_user_performance_message: "",
      };

    case actionTypes.COMPARATIVE_ANALYSIS_USER_PERFORMANCE_SUCCESS: {
      return {
        ...state,
        comparative_analysis_user_performance_processing: false,
        comparative_analysis_user_performance_success: true,
        comparative_analysis_user_performance_failed: false,
        comparative_analysis_user_performance_data: action.data.data,
        comparative_analysis_user_performance_message: "",
      };
    }
    case actionTypes.COMPARATIVE_ANALYSIS_USER_PERFORMANCE_FAILED:
      return {
        ...state,
        comparative_analysis_user_performance_processing: false,
        comparative_analysis_user_performance_success: false,
        comparative_analysis_user_performance_failed: true,
        comparative_analysis_user_performance_data: "",
        comparative_analysis_user_performance_message: action.data,
      };

    case actionTypes.COMPARATIVE_ATTENDANCE_USER_PERFORMANCE_INITIAL:
      return {
        ...state,
        comparative_attendance_user_performance_processing: false,
        comparative_attendance_user_performance_success: false,
        comparative_attendance_user_performance_failed: false,
        comparative_attendance_user_performance_data: "",
        comparative_attendance_user_performance_message: "",
      };

    case actionTypes.COMPARATIVE_ATTENDANCE_USER_PERFORMANCE_PROCESS:
      return {
        ...state,
        comparative_attendance_user_performance_processing: true,
        comparative_attendance_user_performance_success: false,
        comparative_attendance_user_performance_failed: false,
        comparative_attendance_user_performance_data: "",
        comparative_attendance_user_performance_message: "",
      };

    case actionTypes.COMPARATIVE_ATTENDANCE_USER_PERFORMANCE_SUCCESS: {
      return {
        ...state,
        comparative_attendance_user_performance_processing: false,
        comparative_attendance_user_performance_success: true,
        comparative_attendance_user_performance_failed: false,
        comparative_attendance_user_performance_data: action.data.data,
        comparative_attendance_user_performance_message: "",
      };
    }
    case actionTypes.COMPARATIVE_ATTENDANCE_USER_PERFORMANCE_FAILED:
      return {
        ...state,
        comparative_attendance_user_performance_processing: false,
        comparative_attendance_user_performance_success: false,
        comparative_attendance_user_performance_failed: true,
        comparative_attendance_user_performance_data: "",
        comparative_attendance_user_performance_message: action.data,
      };

    case actionTypes.COMPARATIVE_GOAL_USER_PERFORMANCE_INITIAL:
      return {
        ...state,
        comparative_goal_user_performance_processing: false,
        comparative_goal_user_performance_success: false,
        comparative_goal_user_performance_failed: false,
        comparative_goal_user_performance_data: "",
        comparative_goal_user_performance_message: "",
      };

    case actionTypes.COMPARATIVE_GOAL_USER_PERFORMANCE_PROCESS:
      return {
        ...state,
        comparative_goal_user_performance_processing: true,
        comparative_goal_user_performance_success: false,
        comparative_goal_user_performance_failed: false,
        comparative_goal_user_performance_data: "",
        comparative_goal_user_performance_message: "",
      };

    case actionTypes.COMPARATIVE_GOAL_USER_PERFORMANCE_SUCCESS: {
      return {
        ...state,
        comparative_goal_user_performance_processing: false,
        comparative_goal_user_performance_success: true,
        comparative_goal_user_performance_failed: false,
        comparative_goal_user_performance_data: action.data.data,
        comparative_goal_user_performance_message: "",
      };
    }
    case actionTypes.COMPARATIVE_GOAL_USER_PERFORMANCE_FAILED:
      return {
        ...state,
        comparative_goal_user_performance_processing: false,
        comparative_goal_user_performance_success: false,
        comparative_goal_user_performance_failed: true,
        comparative_goal_user_performance_data: "",
        comparative_goal_user_performance_message: action.data,
      };
    case actionTypes.COMPARATIVE_APPLICATION_USER_PERFORMANCE_INITIAL:
      return {
        ...state,
        comparative_application_user_performance_processing: false,
        comparative_application_user_performance_success: false,
        comparative_application_user_performance_failed: false,
        comparative_application_user_performance_data: "",
        comparative_application_user_performance_message: "",
      };

    case actionTypes.COMPARATIVE_APPLICATION_USER_PERFORMANCE_PROCESS:
      return {
        ...state,
        comparative_application_user_performance_processing: true,
        comparative_application_user_performance_success: false,
        comparative_application_user_performance_failed: false,
        comparative_application_user_performance_data: "",
        comparative_application_user_performance_message: "",
      };

    case actionTypes.COMPARATIVE_APPLICATION_USER_PERFORMANCE_SUCCESS: {
      return {
        ...state,
        comparative_application_user_performance_processing: false,
        comparative_application_user_performance_success: true,
        comparative_application_user_performance_failed: false,
        comparative_application_user_performance_data: action.data.data,
        comparative_application_user_performance_message: "",
      };
    }
    case actionTypes.COMPARATIVE_APPLICATION_USER_PERFORMANCE_FAILED:
      return {
        ...state,
        comparative_application_user_performance_processing: false,
        comparative_application_user_performance_success: false,
        comparative_application_user_performance_failed: true,
        comparative_application_user_performance_data: "",
        comparative_application_user_performance_message: action.data,
      };
    default:
      return state;
  }
};
