const LOGIN = "/login/callback";
const SIGNUP_INITIATE = "/signup";
const SIGNIN = "/signin";

const HOME = "/we360";

const SIGNUP_PHASE_1 = "/emailverification";
const SIGNUP_PHASE_2 = "/congratulations";
const SIGNUP_PHASE_3 = "/emailalreadyverify";
const SIGNUP_PHASE_4 = "/linkexpired";
const SIGNUP_PHASE_5 = "/setpassword/:token";
const SIGNUP_PHASE_6 = "/accountsetup";
const SIGNUP_PHASE_7 = "/complete-setup/";
const SIGNUP_PHASE_8 = "/complete-google-setup";
const CHOOSE_LOGIN_FLOW = "/choose-login-flow";
const ACCEPT_INVITATION = "/accept-invitation/";
const REDIRECT_TO_TENANT = "/redirect-to-tenant";

const SUCCESS = "/success";

const PORTAL_ALLOWED_ROUTES = [
  SIGNUP_INITIATE,
  SIGNIN,
  SIGNUP_PHASE_7,
  SIGNUP_PHASE_8,
  ACCEPT_INVITATION,
  REDIRECT_TO_TENANT,
  CHOOSE_LOGIN_FLOW,
];

const BYPASS_DOMAIN_CHECK_ROUTES = [ACCEPT_INVITATION];

const RESERVED_DOMAINS = ["portal", "app", "assets", "login"];
const REDIRECT_TO_PORTAL = ["assets"];

const DASHBOARD = "/dashboard";
const ONBOARDING = "/onboarding";
const ATTENDANCE = "/attendance";
const LIVESTREAM = "/livestream";
const WELLNESS = "/wellness360";
const ACTIVITY = "/activity";
const PRODUCTIVITY = "/productivity";
const TIMELINE = "/timeline";
const APPLICATION = "/application";
const SETTINGS = "/settings";
const FIELD = "/field";
const ALERTS = "/alerts";
const REPORTS = "/reports";
const ACCOUNT = "/account";
const BILLING = "/billing";
const ME = "/user-detail";
const PROJECTS = "/projects";
const TASK = "/tasks";
const DOWNLOAD = "/download";
const NOTEBOOK = "/notebook";
const LEAVE_MANAGEMENT = "/leaves";
const EXTENSION_LOGIN = "/extension-login";
const CLEAR_COOKIES = "/clear-cookies";
const GOALS = "/goals";

const DESIGNATION = "api/v1/designations/";

// error
const FORBIDDEN = "/403";
const NOT_FOUND = "/404";
const BILLING_ALLOWED_ROUTES = [SETTINGS, BILLING, ACCOUNT, DOWNLOAD, SUCCESS];
const SCREENSHOT = "/screenshot";
const DEVICE360 = "/devices";
const MANUALTIME = "/manualtime";

export const accessControls = {
  [DASHBOARD]: "dashboard",
  [ATTENDANCE]: "attendance",
  [LIVESTREAM]: "live_stream",
  [FIELD]: "field",
  [TIMELINE]: "timeline",
  [SCREENSHOT]: "screenshots",
  [ACTIVITY]: "activity",
  [PRODUCTIVITY]: "productivity",
  [GOALS]: "goals",
  [APPLICATION]: "apps_and_urls",
  [WELLNESS]: "wellness",
  [DEVICE360]: "devices",
  [MANUALTIME]: "manual_time",
  [ALERTS]: "alerts",
  [PROJECTS]: "projects",
  [LEAVE_MANAGEMENT]: "leaves",
  [NOTEBOOK]: "notebook",
  [ME]: "user_detail",
  [REPORTS]: "reporting",
};

export const reportingKeys = [
  "break",
  "daily_attendance",
  "monthly_attendance",
  "monthly_in_out",
  "activity",
  "application",
  "productivity",
  "group_insight",
  "project",
  "logs",
  "alert",
  "dynamic",
  "device",
];

export {
  LOGIN,
  SIGNUP_INITIATE,
  SIGNIN,
  CHOOSE_LOGIN_FLOW,
  SIGNUP_PHASE_1,
  SIGNUP_PHASE_2,
  SIGNUP_PHASE_3,
  SIGNUP_PHASE_4,
  SIGNUP_PHASE_5,
  SIGNUP_PHASE_6,
  SIGNUP_PHASE_7,
  SIGNUP_PHASE_8,
  DASHBOARD,
  ONBOARDING,
  ATTENDANCE,
  LIVESTREAM,
  ACTIVITY,
  WELLNESS,
  PRODUCTIVITY,
  TIMELINE,
  APPLICATION,
  SETTINGS,
  FIELD,
  ALERTS,
  REPORTS,
  ACCOUNT,
  DESIGNATION,
  HOME,
  RESERVED_DOMAINS,
  REDIRECT_TO_PORTAL,
  FORBIDDEN,
  NOT_FOUND,
  PORTAL_ALLOWED_ROUTES,
  BYPASS_DOMAIN_CHECK_ROUTES,
  // COOKIE_PATHS,
  ME,
  BILLING,
  PROJECTS,
  TASK,
  BILLING_ALLOWED_ROUTES,
  SCREENSHOT,
  DOWNLOAD,
  NOTEBOOK,
  ACCEPT_INVITATION,
  LEAVE_MANAGEMENT,
  DEVICE360,
  MANUALTIME,
  CLEAR_COOKIES,
  GOALS,
  SUCCESS,
  REDIRECT_TO_TENANT,
  EXTENSION_LOGIN,
};
