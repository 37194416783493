import { getStatusMapFromList } from "utils/projectUtils.tsx";
import * as actionTypes from "./actionTypes";

const initialPagination = { totalCount: 0 };

const initialState = {
  goalAchievementDistributionLoading: true,
  goalAchievementDistributionData: null,
  teamwiseGoalComparisonLoading: true,
  teamwiseGoalComparisonData: null,
  userwiseGoalAnalyticsLoading: true,
  userwiseGoalAnalyticsData: [],
  userwiseGoalAnalyticsPaginationInfo: initialPagination,
  goalErrorMessage: "",
  userwiseGoalAnalyticsDownloadLoading: false,
  userwiseGoalAnalyticsDownloadData: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_GOAL_ACHIEVEMENT_DISTRIBUTION:
      return {
        ...state,
        goalAchievementDistributionLoading: true,
        goalErrorMessage: "",
      };

    case actionTypes.FETCH_GOAL_ACHIEVEMENT_DISTRIBUTION_SUCCESS:
      return {
        ...state,
        goalAchievementDistributionLoading: false,
        goalAchievementDistributionData: action.payload.data,
      };

    case actionTypes.FETCH_GOAL_ACHIEVEMENT_DISTRIBUTION_FAILED:
      return {
        ...state,
        goalAchievementDistributionLoading: false,
        goalErrorMessage: action.payload,
      };

    case actionTypes.FETCH_TEAMWISE_GOAL_COMPARISON:
      return {
        ...state,
        teamwiseGoalComparisonLoading: true,
        goalErrorMessage: "",
      };

    case actionTypes.FETCH_TEAMWISE_GOAL_COMPARISON_SUCCESS:
      return {
        ...state,
        teamwiseGoalComparisonLoading: false,
        teamwiseGoalComparisonData: action.payload.data,
      };

    case actionTypes.FETCH_TEAMWISE_GOAL_COMPARISON_FAILED:
      return {
        ...state,
        teamwiseGoalComparisonLoading: false,
        goalErrorMessage: action.payload,
      };

    case actionTypes.FETCH_USERWISE_GOAL_ANALYTICS:
      return {
        ...state,
        userwiseGoalAnalyticsLoading: true,
        goalErrorMessage: "",
      };

    case actionTypes.FETCH_USERWISE_GOAL_ANALYTICS_SUCCESS:
      return {
        ...state,
        userwiseGoalAnalyticsLoading: false,
        userwiseGoalAnalyticsData: action.payload.data,
        userwiseGoalAnalyticsPaginationInfo:
          action.payload.optional?.pagination,
      };

    case actionTypes.FETCH_USERWISE_GOAL_ANALYTICS_FAILED:
      return {
        ...state,
        userwiseGoalAnalyticsLoading: false,
        goalErrorMessage: action.payload,
      };

    case actionTypes.SET_GOAL_ERROR_MESSAGE:
      return {
        ...state,
        goalErrorMessage: action.payload,
      };

    case actionTypes.FETCH_USERWISE_GOAL_ANALYTICS_DOWNLOAD:
      return {
        ...state,
        userwiseGoalAnalyticsDownloadLoading: true,
      };

    case actionTypes.FETCH_USERWISE_GOAL_ANALYTICS_DOWNLOAD_SUCCESS:
      return {
        ...state,
        userwiseGoalAnalyticsDownloadLoading: false,
        userwiseGoalAnalyticsDownloadData: action.payload.data,
      };

    case actionTypes.FETCH_USERWISE_GOAL_ANALYTICS_DOWNLOAD_FAILED:
      return {
        ...state,
        userwiseGoalAnalyticsDownloadLoading: false,
        goalErrorMessage: action.payload,
      };

    default:
      return state;
  }
};
