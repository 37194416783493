import { put } from "redux-saga/effects";
import {} from "../action";
import { Client } from "../../../utils/transport";
import {
  Wellness360SummaryHealthyEmployeesFailed,
  Wellness360SummaryHealthyEmployeesSuccess,
  Wellness360SummaryOverburdenedEmployeesFailed,
  Wellness360SummaryOverburdenedEmployeesSuccess,
  Wellness360SummaryHealthyEmployeesPreviousMonthFailed,
  Wellness360SummaryHealthyEmployeesPreviousMonthSuccess,
  //  Wellness360SummaryOverallScoreInitial,
  Wellness360SummaryOverallScoreSuccess,
  Wellness360SummaryOverallScoreFailed,
  Wellness360SummaryTopTeamsSuccess,
  Wellness360SummaryTopTeamsFailed,
  Wellness360SummaryTeamWiseUtilizationSuccess,
  Wellness360SummaryTeamWiseUtilizationFailed,
  Wellness360MeOverallScoreSuccess,
  Wellness360MeOverallScoreFailed,
} from "./action";

//Wellness360 SUMMARY WEDIGT

export function* GetWellness360SummaryWedigt(data) {
  var token = data != undefined && data.token;
}

//Wellness360 SUMMARY TODAY

export function* GetWellness360SummaryOnlineTimeBreakDown(data) {
  var token = data != undefined && data.token;
}

//Wellness360 SUMMARY WELLNESS360 TRENDS

export function* GetWellness360SummaryWellness360LevelBreakDown(data) {
  var token = data != undefined && data.token;
}

//Wellness360 SUMMARY TOP MOST ACTIVE TEAM

export function* GetWellness360SummaryTopMostActiveTeam(data) {
  var token = data != undefined && data.token;
}

//Wellness360 SUMMARY TOP LEAST ACTIVE TEAM

export function* GetWellness360SummaryTopLeastActiveTeam(data) {
  var token = data != undefined && data.token;
}

//TTENDANCE SUMMARY LATE ARRIVAL TENDENCY

export function* GetWellness360SummaryTeamWiseWellness360BreakDown(data) {
  var token = data != undefined && data.token;
}

//Wellness360 SUMMARY HEALTHY EMPLOYEES

export function* Wellness360HealthyEmployees(data) {
  try {
    let config = {
      method: "POST",
      url: "/query/wellness/healthy_workingtime",
      headers: {
        Authorization: `Bearer ` + data.token,
      },
      data: data.bodyData,
      fetchPolicy: "no-cache",
    };

    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(Wellness360SummaryHealthyEmployeesSuccess(response.data.data));
    } else {
      yield put(Wellness360SummaryHealthyEmployeesFailed(response));
    }
  } catch (err) {
    yield put(Wellness360SummaryHealthyEmployeesFailed(err));
  }
}

//Wellness360 SUMMARY HEALTHY EMPLOYEES PREVIOUS MONTH

export function* Wellness360HealthyEmployeesPreviousMonth(data) {
  try {
    let config = {
      method: "POST",
      url: "/query/wellness/healthy_workingtime",
      headers: {
        Authorization: `Bearer ` + data.token,
      },
      data: data.bodyData,
      fetchPolicy: "no-cache",
    };

    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        Wellness360SummaryHealthyEmployeesPreviousMonthSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        Wellness360SummaryHealthyEmployeesPreviousMonthFailed(response)
      );
    }
  } catch (err) {
    yield put(Wellness360SummaryHealthyEmployeesPreviousMonthFailed(err));
  }
}

//Wellness360 SUMMARY OVERBURDENED EMPLOYEES

export function* Wellness360SummaryOverburdenedEmployees(data) {
  try {
    let config = {
      method: "POST",
      url: "/query/wellness/overburdened_underutilized_user",
      headers: {
        // Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + data.token,
      },
      data: data.bodyData,
      fetchPolicy: "no-cache",
    };

    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        Wellness360SummaryOverburdenedEmployeesSuccess(response.data.data)
      );
    } else {
      yield put(Wellness360SummaryOverburdenedEmployeesFailed(response));
    }
  } catch (err) {
    yield put(Wellness360SummaryOverburdenedEmployeesFailed(err));
  }
}
//Wellness360 ME OVERALL SCORE

export function* Wellness360MeOverallScore(data) {
  try {
    let config = {
      method: "POST",
      url: "/query/wellness/indivisual_wellness_score",

      headers: {
        // Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + data.token,
      },
      data: data.bodyData,
      fetchPolicy: "no-cache",
    };

    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(Wellness360MeOverallScoreSuccess(response.data.data));
    } else {
      yield put(Wellness360MeOverallScoreFailed(response));
    }
  } catch (err) {
    yield put(Wellness360MeOverallScoreFailed(err));
  }
}
//Wellness360 SUMMARY OVERALL SCORE

export function* Wellness360SummaryOverallScore(data) {
  try {
    let config = {
      method: "POST",
      url: "/query/wellness/overall_score",

      headers: {
        // Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + data.token,
      },
      data: data.bodyData,
      fetchPolicy: "no-cache",
    };

    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(Wellness360SummaryOverallScoreSuccess(response.data.data));
    } else {
      yield put(Wellness360SummaryOverallScoreFailed(response));
    }
  } catch (err) {
    yield put(Wellness360SummaryOverallScoreFailed(err));
  }
}

//Wellness360 TOP 10 HEALTHY EMPLOYEES

export function* Wellness360SummaryTopTeams(data) {
  try {
    let config = {
      method: "POST",
      url: "/query/wellness/top_groups",

      headers: {
        // Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + data.token,
      },
      data: data.bodyData,
      fetchPolicy: "no-cache",
    };

    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(Wellness360SummaryTopTeamsSuccess(response.data.data));
    } else {
      yield put(Wellness360SummaryTopTeamsFailed(response));
    }
  } catch (err) {
    yield put(Wellness360SummaryTopTeamsFailed(err));
  }
}

//* WELLNESS360 SUMMARY TEAM WISE UTILIZATION *// wellness360_summary_Team_Wise_Utilization_processing

export function* Wellness360SummaryTeamWiseUtilization(data) {
  try {
    let config = {
      method: "POST",
      url: "query/wellness/team_wise_utilization",

      headers: {
        // Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + data.token,
      },
      data: data.bodyData,
      fetchPolicy: "no-cache",
    };

    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        Wellness360SummaryTeamWiseUtilizationSuccess(response.data.data)
      );
    } else {
      yield put(Wellness360SummaryTeamWiseUtilizationFailed(response));
    }
  } catch (err) {
    yield put(Wellness360SummaryTeamWiseUtilizationFailed(err));
  }
}
