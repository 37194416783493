function getMinimumValue(arrays: number[][]): number | undefined {
  const flattenedArray = arrays.flat();
  if (flattenedArray.length === 0) {
    return 0;
  }
  return Math.min(...flattenedArray);
}

export const GetMinimumValue = (arrays: number[][]) => {
  return getMinimumValue(arrays);
};

function getMaximumValue(arrays: number[][]): number | undefined {
  const flattenedArray = arrays.flat();
  if (flattenedArray.length === 0) {
    return 0;
  }
  return Math.max(...flattenedArray);
}

export const GetMaximumValue = (arrays: number[][]) => {
  return getMaximumValue(arrays);
};
