//* PRODUCTIVITY SUMMARY WIDGET *//

export const PRODUCTIVITY_SUMMARY_WIDGET_INITIAL =
  "PRODUCTIVITY_SUMMARY_WIDGET_INITIAL";
export const PRODUCTIVITY_SUMMARY_WIDGET_PROCESS =
  "PRODUCTIVITY_SUMMARY_WIDGET_PROCESS";
export const PRODUCTIVITY_SUMMARY_WIDGET_SUCCESS =
  "PRODUCTIVITY_SUMMARY_WIDGET_SUCCESS";
export const PRODUCTIVITY_SUMMARY_WIDGET_FAILED =
  "PRODUCTIVITY_SUMMARY_WIDGET_FAILED";

//* PRODUCTIVITY SUMMARY TOP CATEGORY*//

export const PRODUCTIVITY_SUMMARY_TOP_CATEGORY_INITIAL =
  "PRODUCTIVITY_SUMMARY_TOP_CATEGORY_INITIAL";
export const PRODUCTIVITY_SUMMARY_TOP_CATEGORY_PROCESS =
  "PRODUCTIVITY_SUMMARY_TOP_CATEGORY_PROCESS";
export const PRODUCTIVITY_SUMMARY_TOP_CATEGORY_SUCCESS =
  "PRODUCTIVITY_SUMMARY_TOP_CATEGORY_SUCCESS";
export const PRODUCTIVITY_SUMMARY_TOP_CATEGORY_FAILED =
  "PRODUCTIVITY_SUMMARY_TOP_CATEGORY_FAILED";

//* PRODUCTIVITY SUMMARY TOP APP URL*//

export const PRODUCTIVITY_SUMMARY_TOP_APP_URL_INITIAL =
  "PRODUCTIVITY_SUMMARY_TOP_APP_URL_INITIAL";
export const PRODUCTIVITY_SUMMARY_TOP_APP_URL_PROCESS =
  "PRODUCTIVITY_SUMMARY_TOP_APP_URL_PROCESS";
export const PRODUCTIVITY_SUMMARY_TOP_APP_URL_SUCCESS =
  "PRODUCTIVITY_SUMMARY_TOP_APP_URL_SUCCESS";
export const PRODUCTIVITY_SUMMARY_TOP_APP_URL_FAILED =
  "PRODUCTIVITY_SUMMARY_TOP_APP_URL_FAILED";

//* PRODUCTIVITY SUMMARY WORKING TIME  *//

export const PRODUCTIVITY_SUMMARY_WORKING_TIME_INITIAL =
  "PRODUCTIVITY_SUMMARY_WORKING_TIME_INITIAL";
export const PRODUCTIVITY_SUMMARY_WORKING_TIME_PROCESS =
  "PRODUCTIVITY_SUMMARY_WORKING_TIME_PROCESS";
export const PRODUCTIVITY_SUMMARY_WORKING_TIME_SUCCESS =
  "PRODUCTIVITY_SUMMARY_WORKING_TIME_SUCCESS";
export const PRODUCTIVITY_SUMMARY_WORKING_TIME_FAILED =
  "PRODUCTIVITY_SUMMARY_WORKING_TIME_FAILED";

//* PRODUCTIVITY SUMMARY PRODUCTIVITY  BREAKDOWN*//

export const PRODUCTIVITY_SUMMARY_PRODUCTIVITY_BREAK_DOWN_INITIAL =
  "PRODUCTIVITY_SUMMARY_PRODUCTIVITY_BREAK_DOWN_INITIAL";
export const PRODUCTIVITY_SUMMARY_PRODUCTIVITY_BREAK_DOWN_PROCESS =
  "PRODUCTIVITY_SUMMARY_PRODUCTIVITY_BREAK_DOWN_PROCESS";
export const PRODUCTIVITY_SUMMARY_PRODUCTIVITY_BREAK_DOWN_SUCCESS =
  "PRODUCTIVITY_SUMMARY_PRODUCTIVITY_LEVEL_BREAK_DOWN_SUCCESS";
export const PRODUCTIVITY_SUMMARY_PRODUCTIVITY_BREAK_DOWN_FAILED =
  "PRODUCTIVITY_SUMMARY_PRODUCTIVITY_BREAK_DOWN_FAILED";

//* PRODUCTIVITY SUMMARY TOP MOST ACTIVE TEAM  *//

export const PRODUCTIVITY_SUMMARY_TOP_BOTTOM_ACTIVE_TEAM_INITIAL =
  "PRODUCTIVITY_SUMMARY_TOP_BOTTOM_ACTIVE_TEAM_INITIAL";
export const PRODUCTIVITY_SUMMARY_TOP_BOTTOM_ACTIVE_TEAM_PROCESS =
  "PRODUCTIVITY_SUMMARY_TOP_BOTTOM_ACTIVE_TEAM_PROCESS";
export const PRODUCTIVITY_SUMMARY_TOP_BOTTOM_ACTIVE_TEAM_SUCCESS =
  "PRODUCTIVITY_SUMMARY_TOP_BOTTOM_ACTIVE_TEAM_SUCCESS";
export const PRODUCTIVITY_SUMMARY_TOP_BOTTOM_ACTIVE_TEAM_FAILED =
  "PRODUCTIVITY_SUMMARY_TOP_BOTTOM_ACTIVE_TEAM_FAILED";

export const PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_BREAK_DOWN_INITIAL =
  "PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_BREAK_DOWN_INITIAL";
export const PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_BREAK_DOWN_PROCESS =
  "PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_BREAK_DOWN_PROCESS";
export const PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_BREAK_DOWN_SUCCESS =
  "PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_BREAK_DOWN_SUCCESS";
export const PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_BREAK_DOWN_FAILED =
  "PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_BREAK_DOWN_FAILED";

//* PRODUCTIVITY SUMMARY TEAM WISE PRODUCTIVITY CATEGORY *//

export const PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_CATEGORY_INITIAL =
  "PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_CATEGORY_INITIAL";
export const PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_CATEGORY_PROCESS =
  "PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_CATEGORY_PROCESS";
export const PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_CATEGORY_SUCCESS =
  "PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_CATEGORY_SUCCESS";
export const PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_CATEGORY_FAILED =
  "PRODUCTIVITY_SUMMARY_TEAM_WISE_PRODUCTIVITY_CATEGORY_FAILED";

//* PRODUCTIVITY SUMMARY PRODUCTIVITY PERCENT *//

export const PRODUCTIVITY_SUMMARY_PRODUCTIVITY_PERCENT_INITIAL =
  "PRODUCTIVITY_SUMMARY_PRODUCTIVITY_PERCENT_INITIAL";
export const PRODUCTIVITY_SUMMARY_PRODUCTIVITY_PERCENT_PROCESS =
  "PRODUCTIVITY_SUMMARY_PRODUCTIVITY_PERCENT_PROCESS";
export const PRODUCTIVITY_SUMMARY_PRODUCTIVITY_PERCENT_SUCCESS =
  "PRODUCTIVITY_SUMMARY_PRODUCTIVITY_PERCENT_SUCCESS";
export const PRODUCTIVITY_SUMMARY_PRODUCTIVITY_PERCENT_FAILED =
  "PRODUCTIVITY_SUMMARY_PRODUCTIVITY_PERCENT_FAILED";
