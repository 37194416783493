import { put } from "redux-saga/effects";
import { DynamicReportAction } from "../../action";
import { Client } from "../../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";

export function* downloadDynamicReportSaga(data) {
  const config = {
    method: "POST",
    url: "query/reports/dynamic_report",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      ...data.payload.headers,
    },
    data: data.payload.body,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      downloadReport(
        response,
        data.payload.reportName,
        data.payload.body.download_type
      );
      yield put(DynamicReportAction.downloadDynamicReportSuccess());
    } else {
      yield put(
        DynamicReportAction.downloadDynamicReportFailed(SOMETHING_WENT_WRONG)
      );
    }
  } catch (err) {
    console.log(err);
    yield put(
      DynamicReportAction.downloadDynamicReportFailed(SOMETHING_WENT_WRONG)
    );
  }
}

const downloadReport = (response, fileName, format) => {
  try {
    const blob = new Blob(
      [format === "json" ? JSON.stringify(response.data) : response.data],
      {
        type: "application/octet-stream",
      }
    );
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (err) {
    console.log(err);
    DynamicReportAction.downloadDynamicReportFailed(SOMETHING_WENT_WRONG);
  }
};
