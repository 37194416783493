import * as actionTypes from "./actionTypes";
import _ from "lodash";
const searchApplicationPolicy = (dataset, search) => {
  if (search.length > 0) {
    var searchdata = _.filter(dataset, (list) => {
      return list.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
        ? true
        : false;
    });

    return searchdata;
  } else {
    return dataset;
  }
};

const initialState = {
  //*GLOBAL*//

  //* LIST *//

  applicationpolicy_list_processing: false,
  applicationpolicy_list_success: false,
  applicationpolicy_list_failed: false,
  applicationpolicy_list_data: [],
  applicationpolicy_list_message: "",
  applicationpolicyMaster: [],

  //* CREATE *//

  applicationpolicy_create_processing: false,
  applicationpolicy_create_success: false,
  applicationpolicy_create_failed: false,
  applicationpolicy_create_data: [],
  applicationpolicy_create_message: "",

  //* EDIT *//

  applicationpolicy_edit_processing: false,
  applicationpolicy_edit_success: false,
  applicationpolicy_edit_failed: false,
  applicationpolicy_edit_data: [],
  applicationpolicy_edit_message: "",

  //DELETE//
  applicationpolicy_delete_processing: false,
  applicationpolicy_delete_success: false,
  applicationpolicy_delete_failed: false,
  applicationpolicy_delete_data: [],
  applicationpolicy_delete_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.APPLICATIONPOLICY_LIST_INITIAL:
      return {
        ...state,
        applicationpolicy_list_processing: true,
        applicationpolicy_list_success: false,
        applicationpolicy_list_failed: false,
        applicationpolicy_list_data: [],
        applicationpolicyMaster: [],
        applicationpolicy_list_message: "",
      };

    case actionTypes.APPLICATIONPOLICY_LIST_PROCESS:
      return {
        ...state,
        applicationpolicy_list_processing: true,
        applicationpolicy_list_success: false,
        applicationpolicy_list_failed: false,
        applicationpolicy_list_data: [],
        applicationpolicyMaster: [],
        applicationpolicy_list_message: "",
      };

    case actionTypes.APPLICATIONPOLICY_LIST_SUCCESS:
      return {
        ...state,
        applicationpolicy_list_processing: false,
        applicationpolicy_list_success: true,
        applicationpolicy_list_failed: false,
        applicationpolicy_list_data: action.data.data,
        applicationpolicyMaster: action.data.data,
        applicationpolicy_list_message: "",
      };
    case actionTypes.APPLICATIONPOLICY_LIST_FAILED:
      return {
        ...state,
        applicationpolicy_list_processing: false,
        applicationpolicy_list_success: false,
        applicationpolicy_list_failed: true,
        applicationpolicy_list_data: action.data,
        applicationpolicyMaster: action.data,
        applicationpolicy_list_message: "",
      };
    case actionTypes.APPLICATIONPOLICY_LIST_SEARCH:
      return {
        ...state,
        applicationpolicy_list_data: searchApplicationPolicy(
          state.applicationpolicyMaster,
          action.data
        ),
      };
    case actionTypes.APPLICATIONPOLICY_CREATE_INITIAL:
      return {
        ...state,
        applicationpolicy_create_processing: false,
        applicationpolicy_create_success: false,
        applicationpolicy_create_failed: false,
        applicationpolicy_create_data: action.data,
        applicationpolicy_create_message: "",
      };
    case actionTypes.APPLICATIONPOLICY_CREATE_PROCESS:
      return {
        ...state,
        applicationpolicy_create_processing: true,
        applicationpolicy_create_success: false,
        applicationpolicy_create_failed: false,
        applicationpolicy_create_data: action.data,
        applicationpolicy_create_message: "",
      };

    case actionTypes.APPLICATIONPOLICY_CREATE_SUCCESS:
      return {
        ...state,
        applicationpolicy_create_processing: false,
        applicationpolicy_create_success: true,
        applicationpolicy_create_failed: false,
        applicationpolicy_create_data: action.data,
        applicationpolicy_list_data: [
          ...state.applicationpolicy_list_data,
          action.data,
        ],
        applicationpolicyMaster: [
          ...state.applicationpolicy_list_data,
          action.data,
        ],
        applicationpolicy_create_message: "",
      };

    case actionTypes.APPLICATIONPOLICY_CREATE_FAILED:
      return {
        ...state,
        applicationpolicy_create_processing: false,
        applicationpolicy_create_success: false,
        applicationpolicy_create_failed: true,
        applicationpolicy_error_message: action.data,
        applicationpolicy_create_data: action.data,
      };
    case actionTypes.APPLICATIONPOLICY_EDIT_INITIAL:
      return {
        ...state,
        applicationpolicy_edit_processing: false,
        applicationpolicy_edit_success: false,
        applicationpolicy_edit_failed: false,
        applicationpolicy_edit_data: [],
        applicationpolicy_edit_message: "",
      };

    case actionTypes.APPLICATIONPOLICY_EDIT_PROCESS:
      return {
        ...state,
        applicationpolicy_edit_processing: true,
        applicationpolicy_edit_success: false,
        applicationpolicy_edit_failed: false,
        applicationpolicy_edit_data: [],
        applicationpolicy_edit_message: "",
      };

    case actionTypes.APPLICATIONPOLICY_EDIT_SUCCESS:
      const fetchapplicationpolicylist = [...state.applicationpolicy_list_data];
      const findIndex = fetchapplicationpolicylist.findIndex(
        (item) => item.id == action.data.id
      );
      fetchapplicationpolicylist[findIndex] = action.data;

      return {
        ...state,
        applicationpolicy_edit_processing: false,
        applicationpolicy_edit_success: true,
        applicationpolicy_edit_failed: false,
        applicationpolicy_edit_data: action.data,
        applicationpolicy_list_data: fetchapplicationpolicylist,
        applicationpolicyMaster: fetchapplicationpolicylist,

        applicationpolicy_edit_message: "",
      };

    case actionTypes.APPLICATIONPOLICY_EDIT_FAILED:
      return {
        ...state,
        applicationpolicy_edit_processing: false,
        applicationpolicy_edit_success: false,
        applicationpolicy_edit_failed: true,
        applicationpolicy_error_message: action.data,
        applicationpolicy_edit_data: action.data,
      };

    // APPLICATION POLICY DELETE//
    case actionTypes.APPLICATIONPOLICY_DELETE_INITIAL:
      return {
        ...state,
        applicationpolicy_delete_processing: true,
        applicationpolicy_delete_success: false,
        applicationpolicy_delete_failed: false,
        applicationpolicy_delete_data: [],
        applicationpolicy_delete_data: "",
      };

    case actionTypes.APPLICATIONPOLICY_DELETE_PROCESS:
      return {
        ...state,
        applicationpolicy_delete_processing: true,
        applicationpolicy_delete_success: false,
        applicationpolicy_delete_failed: false,
        applicationpolicy_delete_data: [],
        applicationpolicy_delete_data: "",
      };

    case actionTypes.APPLICATIONPOLICY_DELETE_SUCCESS:
      const deletefetchlist = [...state.applicationpolicy_list_data];
      const deletefindIndex = deletefetchlist.filter(
        (item) => item.id !== action.data.data.id
      );

      return {
        ...state,
        applicationpolicy_delete_processing: false,
        applicationpolicy_delete_success: true,
        applicationpolicy_delete_failed: false,
        applicationpolicy_delete_data: action.data,
        applicationpolicy_list_data: deletefindIndex,
        applicationpolicyMaster: deletefindIndex,
        applicationpolicy_delete_message: "",
      };

    case actionTypes.APPLICATIONPOLICY_DELETE_FAILED:
      return {
        ...state,
        applicationpolicy_delete_processing: false,
        applicationpolicy_delete_success: false,
        applicationpolicy_delete_failed: true,
        applicationpolicy_delete_message: action.data,
        applicationpolicy_delete_data: "",
      };

    default:
      return state;
  }
};
