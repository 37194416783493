import { put } from "redux-saga/effects";
import { ApplicationDetailAction } from "../action";
import { Client } from "../../../utils/transport";

export function* GetapplicationDetailCategoryList(data) {
  let config = {
    method: "POST",
    url: "query/application/category_app_search",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationDetailAction.ApplicationDetailedCategoryListSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ApplicationDetailAction.ApplicationDetailedCategoryListFailed(response)
      );
    }
  } catch (err) {
    yield put(
      ApplicationDetailAction.ApplicationDetailedCategoryListFailed(err)
    );
  }
}

export function* GetapplicationDetailTeamWiseAppUtilization(data) {
  let config = {
    method: "POST",
    url: "query/application/team_wise_app_utilization",

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationDetailAction.ApplicationDetailedTeamWiseAppUtilizationSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ApplicationDetailAction.ApplicationDetailedTeamWiseAppUtilizationFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ApplicationDetailAction.ApplicationDetailedTeamWiseAppUtilizationFailed(
        err
      )
    );
  }
}

export function* GetapplicationDetailTeamWiseUrlUtilization(data) {
  let config = {
    method: "POST",
    url: "query/application/team_wise_url_utilization",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationDetailAction.ApplicationDetailedTeamWiseUrlUtilizationSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ApplicationDetailAction.ApplicationDetailedTeamWiseUrlUtilizationFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ApplicationDetailAction.ApplicationDetailedTeamWiseUrlUtilizationFailed(
        err
      )
    );
  }
}

export function* GetapplicationDetailApplicationWiseAppUrlUtilization(data) {
  let config = {
    method: "GET",
    url:
      "query/application/search_wise_application?start_date=" +
      data.data.start_date +
      "&end_date=" +
      data.data.end_date +
      "&user_id=" +
      data.data.user_id +
      "&group_id=" +
      data.data.group_id,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
      "X-Schema-Name": data.data.schemaname,
      "X-Identity-Id": data.data.identityid,
    },
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationDetailAction.ApplicationDetailedApplicationWiseAppUrlUtilizationSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ApplicationDetailAction.ApplicationDetailedApplicationWiseAppUrlUtilizationFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ApplicationDetailAction.ApplicationDetailedApplicationWiseAppUrlUtilizationFailed(
        err
      )
    );
  }
}

export function* GetapplicationDetailSearchWiseAppDetail(data) {
  let config = {
    method: "POST",
    url: "query/application/search_wise_application",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationDetailAction.ApplicationDetailedSearchWiseAppDetailSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ApplicationDetailAction.ApplicationDetailedSearchWiseAppDetailFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ApplicationDetailAction.ApplicationDetailedSearchWiseAppDetailFailed(err)
    );
  }
}

export function* GetapplicationDetailSearchWiseUrlDetail(data) {
  let config = {
    method: "POST",
    url: "query/application/search_wise_application",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationDetailAction.ApplicationDetailedSearchWiseUrlDetailSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ApplicationDetailAction.ApplicationDetailedSearchWiseUrlDetailFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ApplicationDetailAction.ApplicationDetailedSearchWiseUrlDetailFailed(err)
    );
  }
}

export function* GetapplicationDetailSearchWiseUserAppDetail(data) {
  let config = {
    method: "POST",
    url: "query/application/search_wise_user_details",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationDetailAction.ApplicationDetailedSearchWiseUserAppDetailSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ApplicationDetailAction.ApplicationDetailedSearchWiseUserAppDetailFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ApplicationDetailAction.ApplicationDetailedSearchWiseUserAppDetailFailed(
        err
      )
    );
  }
}

export function* GetapplicationDetailSearchWiseUserUrlDetail(data) {
  let config = {
    method: "POST",
    url: "query/application/search_wise_user_details",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationDetailAction.ApplicationDetailedSearchWiseUserUrlDetailSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ApplicationDetailAction.ApplicationDetailedSearchWiseUserUrlDetailFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ApplicationDetailAction.ApplicationDetailedSearchWiseUserUrlDetailFailed(
        err
      )
    );
  }
}

export function* GetapplicationDetailSearchWiseGraphAppDetail(data) {
  let config = {
    method: "POST",
    url: "query/application/search_wise_graph_details",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationDetailAction.ApplicationDetailedSearchWiseGraphAppDetailSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ApplicationDetailAction.ApplicationDetailedSearchWiseGraphAppDetailFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ApplicationDetailAction.ApplicationDetailedSearchWiseGraphAppDetailFailed(
        err
      )
    );
  }
}

export function* GetapplicationDetailSearchWiseGraphUrlDetail(data) {
  let config = {
    method: "POST",
    url: "query/application/search_wise_graph_details",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationDetailAction.ApplicationDetailedSearchWiseGraphUrlDetailSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ApplicationDetailAction.ApplicationDetailedSearchWiseGraphUrlDetailFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ApplicationDetailAction.ApplicationDetailedSearchWiseGraphUrlDetailFailed(
        err
      )
    );
  }
}

export function* GetapplicationDetailSearchWiseTitleAppDetail(data) {
  let config = {
    method: "POST",
    url: "query/application/search_wise_details",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    fetchPolicy: "no-cache",
    data: data.bodyData,
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationDetailAction.ApplicationDetailedSearchWiseTitleAppDetailSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ApplicationDetailAction.ApplicationDetailedSearchWiseTitleAppDetailFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ApplicationDetailAction.ApplicationDetailedSearchWiseTitleAppDetailFailed(
        err
      )
    );
  }
}

export function* GetapplicationDetailSearchWiseTitleUrlDetail(data) {
  let config = {
    method: "POST",
    url: "query/application/search_wise_details",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationDetailAction.ApplicationDetailedSearchWiseTitleUrlDetailSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ApplicationDetailAction.ApplicationDetailedSearchWiseTitleUrlDetailFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ApplicationDetailAction.ApplicationDetailedSearchWiseTitleUrlDetailFailed(
        err
      )
    );
  }
}

export function* GetApplicationDetailedTotalApplicationUsageData(data) {
  let config = {
    method: "POST",
    url: "query/application/application_usage",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationDetailAction.ApplicationDetailedTotalApplicationUsageSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ApplicationDetailAction.ApplicationDetailedTotalApplicationUsageFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ApplicationDetailAction.ApplicationDetailedTotalApplicationUsageFailed(
        err
      )
    );
  }
}

export function* GetApplicationDetailedTotalUrlUsageData(data) {
  let config = {
    method: "POST",
    url: "query/application/urls_usage",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationDetailAction.ApplicationDetailedTotalUrlUsageSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ApplicationDetailAction.ApplicationDetailedTotalUrlUsageFailed(response)
      );
    }
  } catch (err) {
    yield put(
      ApplicationDetailAction.ApplicationDetailedTotalUrlUsageFailed(err)
    );
  }
}

export function* GetapplicationDetailSearchWiseAppUrlDetail(data) {
  let config = {
    method: "POST",
    url: "query/application/search_wise_details",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationDetailAction.ApplicationDetailedSearchWiseAppUrlDetailSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ApplicationDetailAction.ApplicationDetailedSearchWiseAppUrlDetailFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ApplicationDetailAction.ApplicationDetailedSearchWiseAppUrlDetailFailed(
        err
      )
    );
  }
}
