import { Avatar, Button, Divider, Tooltip, Typography } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { stringToColor } from "utils/helper";
import { getAvatarUrl } from "utils/transport";
import { AuthContext } from "contexts/authContext";
import { permissionsMap } from "constants/constant";
const { Text } = Typography;

interface Props {
  id: string;
}

const UserInfoDetail = ({ id }: Props) => {
  const { coreApiUrl, checkRequiredPermissions } = useContext(AuthContext);
  const {
    user_map_data,
    medetails_data,
  }: {
    user_map_data: { [key: string]: User };
    medetails_data: User;
  } =
    // @ts-ignore
    useSelector((state) => state.users);
  const {
    team_map_data,
  }: {
    team_map_data: { [key: string]: Group };
  } =
    // @ts-ignore
    useSelector((state) => state.team);

  const { identity, employee_id, attached_group_id, designation } =
    user_map_data[id] || {};
  const {
    first_name: firstName = "",
    last_name: lastName = "",
    email = "",
    avatar_uri: avatar = "",
  } = identity || {};

  const userDetailsList = [
    { key: "Team", value: team_map_data[attached_group_id]?.name },
    { key: "Designation", value: designation?.name },
  ];

  return (
    <div className="flex flex-col justify-center items-start gap-2 self-stretch p-4 rounded border-[1px] border-solid border-gray-200 bg-white ">
      <div className="w-full">
        <div className="flex items-center gap-[20px] mr-2 p-2 w-full">
          <div className=" flex items-center gap-[20px]">
            <div className="">
              {avatar === "@we360" ? (
                <Avatar
                  shape="circle"
                  className="rounded-[50%]"
                  size={100}
                  src={getAvatarUrl(coreApiUrl, id)}
                />
              ) : (
                <Avatar
                  shape="circle"
                  className="rounded-[50%]"
                  size={100}
                  style={{
                    background: stringToColor(
                      `${
                        checkRequiredPermissions([
                          permissionsMap.TENANT_OWNER,
                          permissionsMap.TENANT_MANAGER,
                        ])
                          ? firstName
                          : medetails_data?.identity.first_name
                      } ${
                        checkRequiredPermissions([
                          permissionsMap.TENANT_OWNER,
                          permissionsMap.TENANT_MANAGER,
                        ])
                          ? lastName
                          : medetails_data?.identity.last_name
                      }`
                    ),
                    fontSize: "28px",
                  }}
                >{`${
                  firstName?.length
                    ? checkRequiredPermissions([
                        permissionsMap.TENANT_OWNER,
                        permissionsMap.TENANT_MANAGER,
                      ])
                      ? firstName[0].toUpperCase()
                      : medetails_data?.identity.first_name[0].toUpperCase()
                    : ""
                }${
                  lastName?.length
                    ? checkRequiredPermissions([
                        permissionsMap.TENANT_OWNER,
                        permissionsMap.TENANT_MANAGER,
                      ])
                      ? lastName[0].toUpperCase()
                      : medetails_data?.identity.last_name[0].toUpperCase()
                    : ""
                }`}</Avatar>
              )}
            </div>
            <div className="flex-row">
              <div className="flex items-center mb-2 gap-10">
                <div className="text-[#667085] text-sm w-[130px] ">
                  Employee
                </div>
                <div className="text-[14px] text-[#000000] w-full ">
                  {`${
                    checkRequiredPermissions([
                      permissionsMap.TENANT_OWNER,
                      permissionsMap.TENANT_MANAGER,
                    ])
                      ? identity?.first_name
                      : medetails_data?.identity.first_name
                  } ${
                    checkRequiredPermissions([
                      permissionsMap.TENANT_OWNER,
                      permissionsMap.TENANT_MANAGER,
                    ])
                      ? identity?.last_name
                      : medetails_data?.identity.last_name
                  }`}
                </div>
              </div>
              <div className="flex  items-center  mb-2  gap-10">
                <div className="text-[#667085] text-sm w-[130px]">Email</div>
                <div className="text-[14px] text-[#000000] w-full ">
                  {/* <Text style={{ width: "80%" }} ellipsis={{ tooltip: email }}> */}
                  {/* allisongouse@gmail.com */}
                  {email}
                  {/* </Text> */}
                </div>
              </div>
              <div className="flex  items-center mb-2 gap-10 ">
                <div className="text-[#667085] text-sm w-[130px]">
                  Employee Id
                </div>
                {employee_id !== null && (
                  <div className="text-[14px] text-[#000000] w-full  ">
                    {`${employee_id}`}
                  </div>
                )}
              </div>
              <div className=" flex items-center justify-start flex-col">
                {userDetailsList.map((userProperty) => (
                  <div
                    key={userProperty.key}
                    className="flex items-center mb-2 w-full gap-10"
                  >
                    <div className="text-[#667085] text-sm w-[130px]">
                      {userProperty.key}
                    </div>
                    <div className="text-[14px] text-[#000000] w-full">
                      {userProperty.value || "-"}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfoDetail;
