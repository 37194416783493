/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/prop-types */
import React from "react";
import { Table } from "antd";
import { hm } from "utils/timeConverter";

const columns = [
  {
    title: "Parameter",
    dataIndex: "parameter",
    key: "parameter",
  },
  {
    title: "User",
    dataIndex: "user",
    key: "user",
    render: (text) => <span style={{ color: "#7B61FF" }}>{text}</span>,
  },
  {
    title: "Team",
    dataIndex: "team",
    key: "team",
    render: (text) => <span style={{ color: "#0A9888" }}>{text}</span>,
  },
  {
    title: "Company",
    dataIndex: "company",
    key: "company",
    render: (text) => <span style={{ color: "#E64F2D" }}>{text}</span>,
  },
];

const UserCompairsonCompanyTeamWiseTable = (props) => {
  const { userTeamCompanyPerformance } = props;
  const formattedData = [
    {
      key: "1",
      parameter: "Average Working time",
      user:
        userTeamCompanyPerformance.length > 0 &&
        hm(userTeamCompanyPerformance[0]?.user_wise.average_punch_duration),
      team:
        userTeamCompanyPerformance.length > 0 &&
        hm(userTeamCompanyPerformance[1]?.group_wise.average_punch_duration),
      company:
        userTeamCompanyPerformance.length > 0 &&
        hm(userTeamCompanyPerformance[2]?.company_wise.average_punch_duration),
      sorter: {
        compare: (a, b) => {},
      },
    },
    {
      key: "2",
      parameter: "Average Online time",
      user:
        userTeamCompanyPerformance.length > 0 &&
        hm(userTeamCompanyPerformance[0]?.user_wise.average_online_duration),
      team:
        userTeamCompanyPerformance.length > 0 &&
        hm(userTeamCompanyPerformance[1]?.group_wise.average_online_duration),
      company:
        userTeamCompanyPerformance.length > 0 &&
        hm(userTeamCompanyPerformance[2]?.company_wise.average_online_duration),
      sorter: {
        compare: (a, b) => {},
      },
    },
    {
      key: "3",
      parameter: "Average Active Time",
      user:
        userTeamCompanyPerformance.length > 0 &&
        hm(userTeamCompanyPerformance[0]?.user_wise.average_active_duration),
      team:
        userTeamCompanyPerformance.length > 0 &&
        hm(userTeamCompanyPerformance[1]?.group_wise.average_active_duration),
      company:
        userTeamCompanyPerformance.length > 0 &&
        hm(userTeamCompanyPerformance[2]?.company_wise.average_active_duration),
    },

    {
      key: "4",
      parameter: "Average Productive Time",
      user:
        userTeamCompanyPerformance.length > 0 &&
        hm(
          userTeamCompanyPerformance[0]?.user_wise.average_productivity_duration
        ),
      team:
        userTeamCompanyPerformance.length > 0 &&
        hm(
          userTeamCompanyPerformance[1]?.group_wise
            .average_productivity_duration
        ),
      company:
        userTeamCompanyPerformance.length > 0 &&
        hm(
          userTeamCompanyPerformance[2]?.company_wise
            .average_productivity_duration
        ),
    },
    {
      key: "5",
      parameter: "Average Idle Time",
      user:
        userTeamCompanyPerformance.length > 0 &&
        hm(userTeamCompanyPerformance[0]?.user_wise.average_idle_duration),
      team:
        userTeamCompanyPerformance.length > 0 &&
        hm(userTeamCompanyPerformance[1]?.group_wise.average_idle_duration),
      company:
        userTeamCompanyPerformance.length > 0 &&
        hm(userTeamCompanyPerformance[2]?.company_wise.average_idle_duration),
    },
  ];
  return (
    <Table columns={columns} dataSource={formattedData} pagination={false} />
  );
};

export default UserCompairsonCompanyTeamWiseTable;
