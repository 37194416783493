import * as actionTypes from "./actionTypes";

export const openUserDetail = (payload) => {
  return {
    type: actionTypes.OPEN_USER_DETAIL,
    payload,
  };
};

export const closeUserDetail = (payload) => {
  return {
    type: actionTypes.CLOSE_USER_DETAIL,
    payload,
  };
};
