import React from "react";

export const Activity = ({ viewBox = "0 0 20 20" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      fill="currentColor"
    >
      <path
        d="M18.2,6.8C17.3,7.6,16,7.7,15,7c-0.2-0.2-0.4-0.2-0.6,0c-0.6,0.3-1.2,0.6-1.7,0.9c-0.2,0.1-0.3,0.2-0.3,0.5
		c-0.1,1.4-1.1,2.4-2.5,2.4c-1.3,0-2.4-1-2.5-2.4c0-0.3,0.1-0.6,0.1-0.9c0-0.1,0-0.3-0.1-0.4C6.7,6.6,6,6.1,5.3,5.6
		C4.6,6,4,6.3,3.2,6.1c-1.4-0.3-2.3-1.6-2-3c0.3-1.4,1.7-2.3,3.1-1.9C5.6,1.6,6.4,3,6,4.3C5.9,4.7,6,4.8,6.3,5
		c0.6,0.4,1.1,0.8,1.7,1.2c0.1,0.1,0.3,0.1,0.4,0c1.4-0.8,2.6-0.6,3.7,0.7c0,0,0,0,0.1,0.1c0.3-0.2,0.7-0.3,1-0.5
		c0.3-0.2,0.7-0.4,1-0.5c0-0.3-0.1-0.6-0.1-0.8c-0.1-1.2,0.7-2.2,1.8-2.5c1.1-0.3,2.4,0.2,2.9,1.2C19.3,4.8,19,6.1,18.2,6.8z"
      />
      <path
        d="M5.7,13.4c0,1.3,0,2.6,0,3.9c0,1-0.6,1.6-1.6,1.6c-0.5,0-1,0-1.5,0c-1,0-1.6-0.6-1.6-1.6c0-2,0-3.9,0-5.9
		c0-0.6,0-1.2,0-1.8c0-1,0.6-1.6,1.6-1.6c0.5,0,1,0,1.5,0c1,0,1.6,0.6,1.6,1.6C5.7,10.8,5.7,12.1,5.7,13.4z"
      />
      <path
        d="M12.2,15.5c0,0.6,0,1.2,0,1.8c0,0.9-0.6,1.5-1.6,1.6c-0.5,0-1.1,0-1.6,0c-0.9,0-1.5-0.6-1.5-1.5
		c0-1.2,0-2.5,0-3.7c0-0.9,0.7-1.5,1.6-1.5c0.5,0,1.1,0,1.6,0c0.9,0,1.5,0.7,1.5,1.5C12.2,14.2,12.2,14.9,12.2,15.5z"
      />
      <path
        d="M18.6,14.3c0,1,0,1.9,0,2.9c0,1-0.6,1.6-1.6,1.6c-0.5,0-1,0-1.5,0c-0.9,0-1.5-0.6-1.5-1.5c0-2,0-4,0-6
		c0-0.9,0.6-1.5,1.5-1.5c0.5,0,1.1,0,1.6,0c0.9,0,1.5,0.6,1.5,1.5C18.6,12.3,18.6,13.3,18.6,14.3z"
      />
    </svg>
  );
};
