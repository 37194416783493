export const DOWNLOAD_STEALTH_APP = "DOWNLOAD_STEALTH_APP";
export const DOWNLOAD_STEALTH_APP_SUCCESS = "DOWNLOAD_STEALTH_APP_SUCCESS";
export const DOWNLOAD_STEALTH_APP_FAILED = "DOWNLOAD_STEALTH_APP_FAILED";

export const DOWNLOAD_STANDARD_APP = "DOWNLOAD_STANDARD_APP";
export const DOWNLOAD_STANDARD_APP_SUCCESS = "DOWNLOAD_STANDARD_APP_SUCCESS";
export const DOWNLOAD_STANDARD_APP_FAILED = "DOWNLOAD_STANDARD_APP_FAILED";

export const DOWNLOAD_KEY_CONFIG = "DOWNLOAD_KEY_CONFIG";
export const DOWNLOAD_KEY_CONFIG_SUCCESS = "DOWNLOAD_KEY_CONFIG_SUCCESS";
export const DOWNLOAD_KEY_CONFIG_FAILED = "DOWNLOAD_KEY_CONFIG_FAILED";

export const OPEN_PROGRESS_BAR = "OPEN_PROGRESS_BAR";
export const CLOSE_PROGRESS_BAR = "CLOSE_PROGRESS_BAR";

export const FETCH_APP_VERSION_DETAILS = "FETCH_APP_VERSION_DETAILS";
export const FETCH_APP_VERSION_DETAILS_SUCCESS =
  "FETCH_APP_VERSION_DETAILS_SUCCESS";
