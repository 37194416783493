//* PRODUCTIVITY DETAILED WORKING TIME TRENDS *//

export const PRODUCTIVITY_DETAILED_WORKING_TIME_TREND_INITIAL =
  "PRODUCTIVITY_DETAILED_WORKING_TIME_TREND_INITIAL";
export const PRODUCTIVITY_DETAILED_WORKING_TIME_TREND_PROCESS =
  "PRODUCTIVITY_DETAILED_WORKING_TIME_TREND_PROCESS";
export const PRODUCTIVITY_DETAILED_WORKING_TIME_TREND_SUCCESS =
  "PRODUCTIVITY_DETAILED_WORKING_TIME_TREND_SUCCESS";
export const PRODUCTIVITY_DETAILED_WORKING_TIME_TREND_FAILED =
  "PRODUCTIVITY_DETAILED_WORKING_TIME_TREND_FAILED";

//* PRODUCTIVITY DETAILED TEAM WISE PRODUCTIVITY TRENDS *//

export const PRODUCTIVITY_DETAILED_TEAM_WISE_PRODUCTIVITY_TREND_INITIAL =
  "PRODUCTIVITY_DETAILED_TEAM_WISE_PRODUCTIVITY_TREND_INITIAL";
export const PRODUCTIVITY_DETAILED_TEAM_WISE_PRODUCTIVITY_TREND_PROCESS =
  "PRODUCTIVITY_DETAILED_TEAM_WISE_PRODUCTIVITY_TREND_PROCESS";
export const PRODUCTIVITY_DETAILED_TEAM_WISE_PRODUCTIVITY_TREND_SUCCESS =
  "PRODUCTIVITY_DETAILED_TEAM_WISE_PRODUCTIVITY_TREND_SUCCESS";
export const PRODUCTIVITY_DETAILED_TEAM_WISE_PRODUCTIVITY_TREND_FAILED =
  "PRODUCTIVITY_DETAILED_TEAM_WISE_PRODUCTIVITY_TREND_FAILED";

//* PRODUCTIVITY DETAILED EMPLOYEE LIST *//

export const PRODUCTIVITY_DETAILED_EMPLOYEE_LIST_INITIAL =
  "PRODUCTIVITY_DETAILED_EMPLOYEE_LIST_INITIAL";
export const PRODUCTIVITY_DETAILED_EMPLOYEE_LIST_PROCESS =
  "PRODUCTIVITY_DETAILED_EMPLOYEE_LIST_PROCESS";
export const PRODUCTIVITY_DETAILED_EMPLOYEE_LIST_SUCCESS =
  "PRODUCTIVITY_DETAILED_EMPLOYEE_LIST_SUCCESS";
export const PRODUCTIVITY_DETAILED_EMPLOYEE_LIST_FAILED =
  "PRODUCTIVITY_DETAILED_EMPLOYEE_LIST_FAILED";

//* PRODUCTIVITY EMPLOYEE DETAILED EMPLOYEE LIST *//

export const PRODUCTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_INITIAL =
  "PRODUCTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_INITIAL";
export const PRODUCTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_PROCESS =
  "PRODUCTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_PROCESS";
export const PRODUCTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_SUCCESS =
  "PRODUCTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_SUCCESS";
export const PRODUCTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_FAILED =
  "PRODUCTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_FAILED";
