import { put } from "redux-saga/effects";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { Client } from "utils/transport";
import { LivestreamActions } from "../action";

export function* startLivestreamSaga(data) {
  const config = {
    method: "POST",
    url: "api/v1/livestream/start",
    headers: {
      "Content-Type": "application/json",
      ...data.payload.headers,
    },
    data: { ...data.payload.body, type: "START_LIVESTREAM" },
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        LivestreamActions.startLivestreamSuccess(
          data.payload.body.identity_ids.filter(
            (identityId) =>
              !(response?.data?.data.identity_ids || []).includes(identityId)
          )
        )
      );
    } else {
      yield put(LivestreamActions.startLivestreamFailed(SOMETHING_WENT_WRONG));
    }
  } catch (err) {
    console.error(err);
    yield put(LivestreamActions.startLivestreamFailed(SOMETHING_WENT_WRONG));
  }
}

export function* startScreenshotSaga(data) {
  const config = {
    method: "POST",
    url: "api/v1/livestream/start",
    headers: {
      "Content-Type": "application/json",
      ...data.payload.headers,
    },
    data: { ...data.payload.body, type: "SEND_SCREENSHOT" },
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(LivestreamActions.startScreenshotSuccess(response.data.data));
    } else {
      yield put(LivestreamActions.startScreenshotFailed(SOMETHING_WENT_WRONG));
    }
  } catch (err) {
    console.error(err);
    yield put(LivestreamActions.startScreenshotFailed(SOMETHING_WENT_WRONG));
  }
}

export function* fetchUserDataSaga(data) {
  const config = {
    method: "POST",
    url: `${
      data.payload.isOwner
        ? `/api/v1/admin/latest-pings/`
        : "/api/v1/me/hierarchy/latest-pings/"
    }?${data.payload.query}`,
    headers: {
      "Content-Type": "application/json",
      ...data.payload.headers,
    },
    data: data.payload.body,
  };
  try {
    const response = yield Client.request(config);

    yield put(LivestreamActions.fetchUserDataSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }

    yield put(LivestreamActions.fetchUserDataFailed(errorMessage));
  }
}
