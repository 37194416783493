import * as actionTypes from "./actionTypes";

const initialPagination = { total_records: 0 };

const initialState = {
  fetchCrossDayAttendanceReportLoading: true,
  crossDayAttendanceReportData: [],
  pagination: initialPagination,
  crossDayAttendanceReportErrorMessage: "",
  downloadCrossDayAttendanceReportLoading: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_CROSS_DAY_ATTENDANCE_REPORT:
      return {
        ...state,
        fetchCrossDayAttendanceReportLoading: true,
        crossDayAttendanceReportErrorMessage: "",
      };

    case actionTypes.FETCH_CROSS_DAY_ATTENDANCE_REPORT_SUCCESS: {
      return {
        ...state,
        fetchCrossDayAttendanceReportLoading: false,
        crossDayAttendanceReportData: action.payload.data,
        pagination: action.payload || state.pagination,
        pagination: action.payload.optional.pagination || state.pagination,
      };
    }

    case actionTypes.FETCH_CROSS_DAY_ATTENDANCE_REPORT_FAILED:
      return {
        ...state,
        fetchCrossDayAttendanceReportLoading: false,
        crossDayAttendanceReportData: [],
        pagination: initialPagination,
        crossDayAttendanceReportErrorMessage: action.payload,
      };
    case actionTypes.DOWNLOAD_CROSS_DAY_ATTENDANCE_REPORT:
      return {
        ...state,
        downloadCrossDayAttendanceReportLoading: true,
        crossDayAttendanceReportErrorMessage: "",
      };

    case actionTypes.DOWNLOAD_CROSS_DAY_ATTENDANCE_REPORT_SUCCESS:
      return {
        ...state,
        downloadCrossDayAttendanceReportLoading: false,
      };

    case actionTypes.DOWNLOAD_CROSS_DAY_ATTENDANCE_REPORT_FAILED:
      return {
        ...state,
        downloadCrossDayAttendanceReportLoading: false,
        crossDayAttendanceReportErrorMessage: action.payload,
      };

    default:
      return state;
  }
};
