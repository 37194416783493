import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../index.scss";
import { InfoCircleFilled } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { Skeleton, Calendar, Badge, Typography, Tooltip, Empty } from "antd";
import moment from "moment";
import { hm } from "utils/timeConverter";
import dayjs from "dayjs";
const { Title } = Typography;

const Cardskeleton = (props) => {
  return (
    <>
      <div className="charts">
        <div className="chart_header">
          <Skeleton.Input
            size={"small"}
            shape={"default"}
            style={{ height: "15px" }}
          />
          <Skeleton.Avatar
            size={"small"}
            shape={"circle"}
            style={{ height: "15px", width: "15px" }}
          />
        </div>
        <div className="chartContainer chart_tool" id="chart">
          <Skeleton.Avatar
            size={"large"}
            shape={"default"}
            style={{ height: "300px", width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

const MonthlyWellnessTrend = (props) => {
  const { month } = props;
  const wellness360details = useSelector((state) => state.wellness360details);
  const [loading, setLoading] = useState(true);
  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const [currentDate, setCurrentDate] = useState(dayjs(month).startOf("month"));

  useEffect(() => {
    if (month) {
      setCurrentDate(dayjs(month).startOf("month"));
    }
  }, [month]);

  const getListData = (value, filterDate) => {
    let listData = [];
    let dataFilter = _.find(
      value,
      (itm) => moment(itm.date).format("YYYY-MM-DD") == filterDate
    );

    // try {
    if (dataFilter != undefined) {
      listData.push({
        type:
          dataFilter.health_type == "healthy"
            ? "healthy"
            : dataFilter.health_type == "overburdened"
            ? "overburdened"
            : dataFilter.health_type == "underutilized"
            ? "underutilized"
            : "",
        duration:
          dataFilter.duration != null ? hm(dataFilter.duration) : "00h 00m ",
      });
    } else {
      listData = [];
    }
    return listData || [];
  };

  const { title, tooltip } = props;
  const disabledDate = (current) => {
    // Get the current date
    const today = new Date();
    // Set the time to midnight to compare only the date portion
    today.setHours(23, 59, 59, 0);

    // Disable dates if they are after today
    return current && current > today;
  };
  const dateCellRender = (value) => {
    var datamap =
      wellness360details &&
      wellness360details.wellness360_employee_details_employee_list_data &&
      wellness360details.wellness360_employee_details_employee_list_data.data
        .length > 0 &&
      wellness360details.wellness360_employee_details_employee_list_data.data[0]
        .datewiselist &&
      wellness360details.wellness360_employee_details_employee_list_data.data[0]
        .datewiselist.length > 0 &&
      wellness360details.wellness360_employee_details_employee_list_data.data[0]
        .datewiselist;
    const listData = getListData(datamap, value.format("YYYY-MM-DD"));

    if (!_.first(listData)) {
      return null;
    }
    return (
      <Tooltip title={_.first(listData).type.toUpperCase()} placement="topLeft">
        <ul className="cal_legend_user_calendar">
          <li key={_.first(listData).content}>
            <Badge status={_.first(listData).type} />
          </li>
        </ul>
      </Tooltip>
    );
    // }
  };
  return (
    <>
      <div className="charts">
        <div className="chart_header">
          <Title level={2}>{title}</Title>
          <Tooltip title={tooltip}>
            <InfoCircleFilled />
          </Tooltip>
        </div>
        <div
          className="chartContainer attendCalender userdetailcalender"
          style={{ marginTop: "-10px" }}
        >
          {_.size(
            wellness360details.wellness360_employee_details_employee_list_data
              .data
          ) > 0 ? (
            <Calendar
              fullscreen={false}
              dateCellRender={dateCellRender}
              disabledDate={disabledDate}
              value={currentDate}
            />
          ) : (
            <div style={{ height: "271px" }} className="emptyblock">
              <Empty description={false} />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

MonthlyWellnessTrend.propTypes = {
  getList: PropTypes.func,
};

export default MonthlyWellnessTrend;
