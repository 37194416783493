import React from "react";
import { getHoursAndMinutesFromSeconds } from "utils/timeConverter";

interface Props {
  totalHours: number;
  totalTitle: string;
  avgHours: number;
  avgTitle: string;
  totalPer: string;
  avgPer: string;
  type: string;
}
const TotalAverageDonutGraph = ({
  totalHours,
  totalTitle,
  totalPer,
  avgHours,
  avgTitle,
  avgPer,
  type,
}: Props) => {
  return (
    <>
      <div className="flex-row">
        <div className="flex flex-col items-start gap-1 -mt-6">
          <div className="dash-today-title">{totalTitle}</div>
          <div className="dash-today-count">
            {type === "attendance"
              ? totalHours
              : getHoursAndMinutesFromSeconds(totalHours)}
          </div>
          <div className="dash-today-per">{totalPer}</div>
        </div>
        <div
          className={`flex flex-col gap-1 -mt-14  ${
            totalTitle === "Total productive time" ? "ml-40" : "ml-32"
          } 
    `}
        >
          <div className="dash-today-title">{avgTitle}</div>
          <div className="dash-today-count">
            {type === "attendance"
              ? avgHours
              : getHoursAndMinutesFromSeconds(avgHours)}
          </div>
          <div className="dash-today-per">{avgPer}</div>
        </div>
      </div>
    </>
  );
};
export default TotalAverageDonutGraph;
