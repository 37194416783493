//* MAPPING Start*/
//* APPLICATION URL LIST *//
export const APPLICATION_URL_LIST_INITIAL = "APPLICATION_URL_LIST_INITIAL";
export const APPLICATION_URL_LIST_PROCESS = "APPLICATION_URL_LIST_PROCESS";
export const APPLICATION_URL_LIST_SUCCESS = "APPLICATION_URL_LIST_SUCCESS";
export const APPLICATION_URL_LIST_FAILED = "APPLICATION_URL_LIST_FAILED";
export const APPLICATION_URL_SEARCH = "APPLICATION_URL_SEARCH";
//* APPLICATION URL EDIT *//
export const APPLICATION_URL_EDIT_INITIAL = "APPLICATION_URL_EDIT_INITIAL";
export const APPLICATION_URL_EDIT_PROCESS = "APPLICATION_URL_EDIT_PROCESS";
export const APPLICATION_URL_EDIT_SUCCESS = "APPLICATION_URL_EDIT_SUCCESS";
export const APPLICATION_URL_EDIT_FAILED = "APPLICATION_URL_EDIT_FAILED";

//* APPLICATION CATEGORY LIST *//
export const APPLICATION_CATEGORY_LIST_INITIAL =
  "APPLICATION_CATEGORY_LIST_INITIAL";
export const APPLICATION_CATEGORY_LIST_PROCESS =
  "APPLICATION_CATEGORY_LIST_PROCESS";
export const APPLICATION_CATEGORY_LIST_SUCCESS =
  "APPLICATION_CATEGORY_LIST_SUCCESS";
export const APPLICATION_CATEGORY_LIST_FAILED =
  "APPLICATION_CATEGORY_LIST_FAILED";

//* APPLICATION POLICY_MAPPING LIST *//
export const APPLICATION_POLICY_MAPPING_LIST_INITIAL =
  "APPLICATION_POLICY_MAPPING_LIST_INITIAL";
export const APPLICATION_POLICY_MAPPING_LIST_PROCESS =
  "APPLICATION_POLICY_MAPPING_LIST_PROCESS";
export const APPLICATION_POLICY_MAPPING_LIST_SUCCESS =
  "APPLICATION_POLICY_MAPPING_LIST_SUCCESS";
export const APPLICATION_POLICY_MAPPING_LIST_FAILED =
  "APPLICATION_POLICY_MAPPING_LIST_FAILED";

//* APPLICATION_POLICY_MAPPING CREATE *//

export const APPLICATION_POLICY_MAPPING_CREATE_INITIAL =
  "APPLICATION_POLICY_MAPPING_CREATE_INITIAL";
export const APPLICATION_POLICY_MAPPING_CREATE_PROCESS =
  "APPLICATION_POLICY_MAPPING_CREATE_PROCESS";
export const APPLICATION_POLICY_MAPPING_CREATE_SUCCESS =
  "APPLICATION_POLICY_MAPPING_CREATE_SUCCESS";
export const APPLICATION_POLICY_MAPPING_CREATE_FAILED =
  "APPLICATION_POLICY_MAPPING_CREATE_FAILED";

//* APPLICATION_POLICY_MAPPING EDIT *//
export const APPLICATION_POLICY_MAPPING_EDIT_INITIAL =
  "APPLICATION_POLICY_MAPPING_EDIT_INITIAL";
export const APPLICATION_POLICY_MAPPING_EDIT_PROCESS =
  "APPLICATION_POLICY_MAPPING_EDIT_PROCESS";
export const APPLICATION_POLICY_MAPPING_EDIT_SUCCESS =
  "APPLICATION_POLICY_MAPPING_EDIT_SUCCESS";
export const APPLICATION_POLICY_MAPPING_EDIT_FAILED =
  "APPLICATION_POLICY_MAPPING_EDIT_FAILED";

//* MAPPING End */

//* APPLICATION POLICY SEARCH *//
export const APPLICATION_POLICY_SEARCH_INITIAL =
  "APPLICATION_POLICY_SEARCH_INITIAL";
export const APPLICATION_POLICY_SEARCH_PROCESS =
  "APPLICATION_POLICY_SEARCH_PROCESS";
export const APPLICATION_POLICY_SEARCH_SUCCESS =
  "APPLICATION_POLICY_SEARCH_SUCCESS";
export const APPLICATION_POLICY_SEARCH_FAILED =
  "APPLICATION_POLICY_SEARCH_FAILED";

//* APPLICATION POLICY SEARCH *//
export const APPLICATION_MAPPING_DOWNLOAD_INITIAL =
  "APPLICATION_MAPPING_DOWNLOAD_INITIAL";
export const APPLICATION_MAPPING_DOWNLOAD_PROCESS =
  "APPLICATION_MAPPING_DOWNLOAD_PROCESS";
export const APPLICATION_MAPPING_DOWNLOAD_SUCCESS =
  "APPLICATION_MAPPING_DOWNLOAD_SUCCESS";
export const APPLICATION_MAPPING_DOWNLOAD_FAILED =
  "APPLICATION_MAPPING_DOWNLOAD_FAILED";

//* MAPPING End */
