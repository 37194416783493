import { put } from "redux-saga/effects";
import { TimelineActions } from "../action";
import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";

export function* fetchTimelineTableSaga(data) {
  const config = {
    method: "POST",
    url: `query/timeline/employee_timeline_details`,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      ...data.payload.headers,
    },
    data: data.payload.body,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(TimelineActions.fetchTimelineTableSuccess(response.data));
    } else {
      yield put(TimelineActions.fetchTimelineTableFailed(SOMETHING_WENT_WRONG));
    }
  } catch (err) {
    console.error(err);
    yield put(TimelineActions.fetchTimelineTableFailed(SOMETHING_WENT_WRONG));
  }
}

export function* fetchHourlyActivityProductivitySaga(data) {
  const config = {
    method: "POST",
    url: `query/timeline/hourly_activity_productivity`,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      ...data.payload.headers,
    },
    data: data.payload.body,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      if (response.data.data?.length > 0) {
        yield put(
          TimelineActions.fetchHourlyActivityProductivitySuccess(
            response.data.data[0]
          )
        );
      } else {
        yield put(TimelineActions.setHourlyActivityProductivityLoading(false));
      }
    } else {
      yield put(
        TimelineActions.fetchHourlyActivityProductivityFailed(
          SOMETHING_WENT_WRONG
        )
      );
    }
  } catch (err) {
    console.error(err);
    yield put(
      TimelineActions.fetchHourlyActivityProductivityFailed(
        SOMETHING_WENT_WRONG
      )
    );
  }
}

export function* fetchPowerLogsSaga(data) {
  const config = {
    method: "POST",
    url: `query/timeline/hourly_power_log`,
    headers: {
      "Content-Type": "application/json",
      ...data.payload.headers,
    },
    data: data.payload.body,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    yield put(TimelineActions.fetchPowerLogsSuccess(response.data.data));
  } catch (err) {
    console.error(err);
    yield put(TimelineActions.fetchPowerLogsFailed(SOMETHING_WENT_WRONG));
  }
}

export function* fetchPunchLogsSaga(action) {
  const config = {
    method: "POST",
    url: `/query/attendance/logs`,
    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };
  try {
    const response = yield Client.request(config);

    yield put(TimelineActions.fetchPunchLogsSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TimelineActions.fetchPunchLogsFailed(errorMessage));
  }
}

export function* fetchSessionAppLogsSaga(data) {
  const config = {
    method: "POST",
    url: `query/timeline/timeline_breakdown_details`,
    headers: {
      "Content-Type": "application/json",
      ...data.payload.headers,
    },
    data: data.payload.body,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    yield put(TimelineActions.fetchSessionAppSuccess(response.data.data));
  } catch (err) {
    console.error(err);
    yield put(TimelineActions.fetchSessionAppFailed(SOMETHING_WENT_WRONG));
  }
}
