import * as actionTypes from "./actionTypes";
const initialState = {
  //*GLOBAL*//

  //* LIST *//

  workplace_setting_loading: false,
  workplace_setting_success: false,
  workplace_setting_failed: false,
  workplace_setting_data: "",
  workplace_setting_message: "",

  //* EDIT *//

  workplace_setting_edit_loading: false,
  workplace_setting_edit_success: false,
  workplace_setting_edit_failed: false,
  workplace_setting_edit_data: [],
  workplace_setting_edit_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.WORKPLACE_SETTING_INITIAL:
      return {
        ...state,
        workplace_setting_loading: true,
        workplace_setting_success: false,
        workplace_setting_failed: false,
        workplace_setting_data: "",
        workplace_setting_message: "",
      };
    case actionTypes.WORKPLACE_SETTING_PROCESS:
      return {
        ...state,
        workplace_setting_loading: true,
        workplace_setting_success: false,
        workplace_setting_failed: false,
        workplace_setting_data: "",
        workplace_setting_message: "",
      };
    case actionTypes.WORKPLACE_SETTING_SUCCESS:
      return {
        ...state,
        workplace_setting_loading: false,
        workplace_setting_success: true,
        workplace_setting_failed: false,
        workplace_setting_data: action.data,
        workplace_setting_message: "",
      };
    case actionTypes.WORKPLACE_SETTING_FAILED:
      return {
        ...state,
        workplace_setting_loading: false,
        workplace_setting_success: false,
        workplace_setting_failed: true,
        workplace_setting_data: "",
        workplace_setting_message: "",
      };

    case actionTypes.WORKPLACE_SETTING_EDIT_INITIAL:
      return {
        ...state,
        workplace_setting_edit_loading: false,
        workplace_setting_edit_success: false,
        workplace_setting_edit_failed: false,
        workplace_setting_edit_data: [],
        workplace_setting_edit_message: "",
      };

    case actionTypes.WORKPLACE_SETTING_EDIT_PROCESS:
      return {
        ...state,
        workplace_setting_edit_loading: true,
        workplace_setting_edit_success: false,
        workplace_setting_edit_failed: false,
        workplace_setting_edit_data: [],
        workplace_setting_edit_message: "",
      };

    case actionTypes.WORKPLACE_SETTING_EDIT_SUCCESS:
      return {
        ...state,
        workplace_setting_edit_loading: false,
        workplace_setting_edit_success: true,
        workplace_setting_edit_failed: false,
        workplace_setting_edit_data: action.data,
        workplace_setting_data: action.data,
        workplace_setting_edit_message: "",
      };

    case actionTypes.WORKPLACE_SETTING_EDIT_FAILED:
      // const fetchLeaveslist = [...state.workplace_setting_data];
      // const findIndexLeave = fetchLeaveslist.findIndex(item => item.id == action.data.id);
      // fetchLeaveslist[findIndexLeave] = action.data;
      // const fetchMyLeavelist = [...state.my_leave_list_data];
      // const findIndexMyLeave = fetchMyLeavelist.findIndex(item => item.id == action.data.id);
      // fetchMyLeavelist[findIndexMyLeave] = action.data;
      return {
        ...state,
        workplace_setting_edit_loading: false,
        workplace_setting_edit_success: false,
        workplace_setting_edit_failed: true,
        workplace_setting_edit_data: action.data,
        workplace_setting_edit_message: "",
      };

    default:
      return state;
  }
};
