import * as actionTypes from "./actionTypes";

//*  ATTENDANCE DETAILED ATTENDANCE TRENDS *//

export const AttendanceDetailedAttendanceTrendInitial = () => {
  return {
    type: actionTypes.ATTENDANCE_DETAILED_ATTENDANCE_TREND_INITIAL,
  };
};
export const AttendanceDetailedAttendanceTrendProcess = (token, bodyData) => {
  return {
    type: actionTypes.ATTENDANCE_DETAILED_ATTENDANCE_TREND_PROCESS,
    token,
    bodyData,
  };
};
export const AttendanceDetailedAttendanceTrendSuccess = (data) => {
  return {
    type: actionTypes.ATTENDANCE_DETAILED_ATTENDANCE_TREND_SUCCESS,
    data,
  };
};
export const AttendanceDetailedAttendanceTrendFailed = (data) => {
  return {
    type: actionTypes.ATTENDANCE_DETAILED_ATTENDANCE_TREND_FAILED,
    data,
  };
};

//*  ATTENDANCE DETAILED EMPLOYEE LIST *//

export const AttendanceDetailedEmployeeListInitial = () => {
  return {
    type: actionTypes.ATTENDANCE_DETAILED_EMPLOYEE_LIST_INITIAL,
  };
};
export const AttendanceDetailedEmployeeListProcess = (token, bodyData) => {
  return {
    type: actionTypes.ATTENDANCE_DETAILED_EMPLOYEE_LIST_PROCESS,
    token,
    bodyData,
  };
};
export const AttendanceDetailedEmployeeListSuccess = (data) => {
  return {
    type: actionTypes.ATTENDANCE_DETAILED_EMPLOYEE_LIST_SUCCESS,
    data,
  };
};
export const AttendanceDetailedEmployeeListFailed = (data) => {
  return {
    type: actionTypes.ATTENDANCE_DETAILED_EMPLOYEE_LIST_FAILED,
    data,
  };
};

//*  ATTENDANCE DETAILED EMPLOYEE PUNCH LOG LIST *//

export const AttendanceDetailedEmployeePunchLogListInitial = () => {
  return {
    type: actionTypes.ATTENDANCE_DETAILED_EMPLOYEE_PUNCH_LOG_LIST_INITIAL,
  };
};
export const AttendanceDetailedEmployeePunchLogListProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.ATTENDANCE_DETAILED_EMPLOYEE_PUNCH_LOG_LIST_PROCESS,
    token,
    bodyData,
  };
};
export const AttendanceDetailedEmployeePunchLogListSuccess = (data) => {
  return {
    type: actionTypes.ATTENDANCE_DETAILED_EMPLOYEE_PUNCH_LOG_LIST_SUCCESS,
    data,
  };
};
export const AttendanceDetailedEmployeePunchLogListFailed = (data) => {
  return {
    type: actionTypes.ATTENDANCE_DETAILED_EMPLOYEE_PUNCH_LOG_LIST_FAILED,
    data,
  };
};

export const AttendanceDetailedEmployeeListSearch = (data) => {
  return {
    type: actionTypes.ATTENDANCE_DETAILED_EMPLOYEE_LIST_SEARCH,
    data,
  };
};

//*  ATTENDANCE DETAILED ATTENDANCE TRENDS  Graph*//

export const AttendanceDetailedAttendanceTrendGraphInitial = () => {
  return {
    type: actionTypes.ATTENDANCE_DETAILED_ATTENDANCE_TREND_GRAPH_INITIAL,
  };
};
export const AttendanceDetailedAttendanceTrendGraphProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.ATTENDANCE_DETAILED_ATTENDANCE_TREND_GRAPH_PROCESS,
    token,
    bodyData,
  };
};
export const AttendanceDetailedAttendanceTrendGraphSuccess = (data) => {
  return {
    type: actionTypes.ATTENDANCE_DETAILED_ATTENDANCE_TREND_GRAPH_SUCCESS,
    data,
  };
};
export const AttendanceDetailedAttendanceTrendGraphFailed = (data) => {
  return {
    type: actionTypes.ATTENDANCE_DETAILED_ATTENDANCE_TREND_GRAPH_FAILED,
    data,
  };
};

//*  ATTENDANCE DATEWISE ATTENDANCE *//

export const AttendanceDatewiseAttendanceListInitial = () => {
  return {
    type: actionTypes.ATTENDANCE_DATEWISE_ATTENDANCE_LIST_INITIAL,
  };
};
export const AttendanceDatewiseAttendanceListProcess = (token, bodyData) => {
  return {
    type: actionTypes.ATTENDANCE_DATEWISE_ATTENDANCE_LIST_PROCESS,
    token,
    bodyData,
  };
};
export const AttendanceDatewiseAttendanceListSuccess = (data) => {
  return {
    type: actionTypes.ATTENDANCE_DATEWISE_ATTENDANCE_LIST_SUCCESS,
    data,
  };
};
export const AttendanceDatewiseAttendanceListFailed = (data) => {
  return {
    type: actionTypes.ATTENDANCE_DATEWISE_ATTENDANCE_LIST_FAILED,
    data,
  };
};

export const AttendanceEmpListDownloadInitial = () => {
  return {
    type: actionTypes.ATTENDANCE_EMP_LIST_DOWNLOAD_INITIAL,
  };
};

export const AttendanceEmpListDownloadProcess = (
  token,
  bodyData,
  teamsList
) => {
  return {
    type: actionTypes.ATTENDANCE_EMP_LIST_DOWNLOAD_PROCESS,
    token,
    bodyData,
    teamsList,
  };
};

export const AttendanceEmpListDownloadSuccess = () => {
  return {
    type: actionTypes.ATTENDANCE_EMP_LIST_DOWNLOAD_SUCCESS,
  };
};

export const AttendanceEmpListDownloadFailed = (err) => {
  return {
    type: actionTypes.ATTENDANCE_EMP_LIST_DOWNLOAD_FAILED,
    err,
  };
};
