import * as actionTypes from "./actionTypes";

export const Wellness360DetailsTrendsInitial = () => {
  return {
    type: actionTypes.WELLNESS360_DETAILS_TRENDS_INITIAL,
  };
};
export const Wellness360DetailsTrendsProcess = (token, bodyData) => {
  return {
    type: actionTypes.WELLNESS360_DETAILS_TRENDS_PROCESS,
    token,
    bodyData,
  };
};
export const Wellness360DetailsTrendsSuccess = (data) => {
  return {
    type: actionTypes.WELLNESS360_DETAILS_TRENDS_SUCCESS,
    data,
  };
};
export const Wellness360DetailsTrendsFailed = (data) => {
  return {
    type: actionTypes.WELLNESS360_DETAILS_TRENDS_FAILED,
    data,
  };
};

export const Wellness360DetailsEmployeeListInitial = () => {
  return {
    type: actionTypes.WELLNESS360_DETAILS_EMPLOYEE_LIST_INITIAL,
  };
};
export const Wellness360DetailsEmployeeListProcess = (token, bodyData) => {
  return {
    type: actionTypes.WELLNESS360_DETAILS_EMPLOYEE_LIST_PROCESS,
    token,
    bodyData,
  };
};
export const Wellness360DetailsEmployeeListSuccess = (data) => {
  return {
    type: actionTypes.WELLNESS360_DETAILS_EMPLOYEE_LIST_SUCCESS,
    data,
  };
};
export const Wellness360DetailsEmployeeListFailed = (data) => {
  return {
    type: actionTypes.WELLNESS360_DETAILS_EMPLOYEE_LIST_FAILED,
    data,
  };
};

export const Wellness360EmployeeDetailsEmployeeListInitial = () => {
  return {
    type: actionTypes.WELLNESS360_EMPLOYEE_DETAILS_EMPLOYEE_LIST_INITIAL,
  };
};
export const Wellness360EmployeeDetailsEmployeeListProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.WELLNESS360_EMPLOYEE_DETAILS_EMPLOYEE_LIST_PROCESS,
    token,
    bodyData,
  };
};
export const Wellness360EmployeeDetailsEmployeeListSuccess = (data) => {
  return {
    type: actionTypes.WELLNESS360_EMPLOYEE_DETAILS_EMPLOYEE_LIST_SUCCESS,
    data,
  };
};
export const Wellness360EmployeeDetailsEmployeeListFailed = (data) => {
  return {
    type: actionTypes.WELLNESS360_EMPLOYEE_DETAILS_EMPLOYEE_LIST_FAILED,
    data,
  };
};

export const Wellness360DownloadEmployeeListInitial = () => {
  return {
    type: actionTypes.WELLNESS360_DOWNLOAD_EMPLOYEE_LIST_INITIAL,
  };
};
export const Wellness360DownloadEmployeeListProcess = (token, bodyData) => {
  return {
    type: actionTypes.WELLNESS360_DOWNLOAD_EMPLOYEE_LIST_PROCESS,
    token,
    bodyData,
  };
};
export const Wellness360DownloadEmployeeListSuccess = (data) => {
  return {
    type: actionTypes.WELLNESS360_DOWNLOAD_EMPLOYEE_LIST_SUCCESS,
    data,
  };
};
export const Wellness360DownloadEmployeeListFailed = (data) => {
  return {
    type: actionTypes.WELLNESS360_DOWNLOAD_EMPLOYEE_LIST_FAILED,
    data,
  };
};
