/* eslint-disable react/prop-types */
import React from "react";
import { Divider } from "antd";
import UserWellnessTrend from "./UserWellnessTrend";

const UserWellness = (props) => {
  const { wellnessAllCount, wellnessCount } = props;
  return (
    <>
      <div className="bg-white border  rounded-[5px]  overflow-hidden w-full">
        <div className="flex items-center justify-between ">
          <h3 className="font-semibold text-[16px] text-slate-500 mt-0">
            Wellness
          </h3>
        </div>
        <UserWellnessTrend wellnessCount={wellnessCount} />
        {wellnessAllCount.length > 0 && (
          <div>
            <div className="flex flex-row justify-between pt-4 pb-4">
              <div className="w-6/12">Healthy days</div>
              {wellnessAllCount && (
                <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
                  {wellnessAllCount[0]} days ({wellnessAllCount[3] + "%"})
                </div>
              )}
            </div>
            <Divider />
            <div className="flex flex-row justify-between pt-4 pb-4">
              <div className="w-6/12">Overburdened days</div>
              {wellnessAllCount && (
                <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
                  {wellnessAllCount[1]} days ({wellnessAllCount[4] + "%"})
                </div>
              )}
            </div>
            <Divider />
            <div className="flex flex-row justify-between pt-4 pb-4">
              <div className="w-6/12">Underutilized days</div>
              {wellnessAllCount && (
                <div className="w-6/12 font-semibold text-[14px] text-[#000000]">
                  {wellnessAllCount[2]} days ({wellnessAllCount[5] + "%"})
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default UserWellness;
