import { put } from "redux-saga/effects";
import { ApplicationPolicyAction } from "../action";
import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";

//LIST APPLICATION POLICY

export function* GetApplicationpolicyList(data) {
  let config = {
    method: "GET",
    url: "APPLIATION_POLICY_PHASE_1",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationPolicyAction.ApplicationPolicyListSuccess(response.data)
      );
    } else {
      yield put(ApplicationPolicyAction.ApplicationPolicyListFailed(response));
    }
  } catch (err) {
    yield put(ApplicationPolicyAction.ApplicationPolicyListFailed(err));
  }
}

//ADD APPLICATION POLICY
export function* CreateApplicationpolicy(data) {
  let config = {
    method: "POST",
    url: "APPLIATION_POLICY_PHASE_1",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.BodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationPolicyAction.ApplicationPolicyCreateSuccess(response.data)
      );
    } else {
      yield put(
        ApplicationPolicyAction.ApplicationPolicyCreateFailed(response)
      );
    }
  } catch (err) {
    yield put(ApplicationPolicyAction.ApplicationPolicyCreateFailed(err));
  }
}

//UPDATE APPLICATION POLICY

export function* EditApplicationpolicy(data) {
  let config = {
    method: "PUT",
    // url: "BREAK_PHASE_1",
    url: "api/v1/application_policies/" + data.data.id,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.Bodydata,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationPolicyAction.ApplicationPolicyEditSuccess(response.data)
      );
    } else {
      yield put(ApplicationPolicyAction.ApplicationPolicyEditFailed(response));
    }
  } catch (err) {
    yield put(ApplicationPolicyAction.ApplicationPolicyEditFailed(err));
  }
}

//DELETE APPLICATION POLICY

export function* DeleteAplicationPolicy(data) {
  const config = {
    method: "DELETE",
    // url: "BREAK_PHASE_1",
    url: "api/v1/application_policies/" + data.data.id,
    headers: {
      Authorization: `Bearer ` + data.data.token,
    },
  };

  try {
    yield Client.request(config);

    yield put(ApplicationPolicyAction.ApplicationPolicyDeleteSuccess(data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(
      ApplicationPolicyAction.ApplicationPolicyDeleteFailed(errorMessage)
    );
  }
}
