import "./public-path";
import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import store from "./redux/index";
import Route from "./route";
// import * as serviceWorker from "./service-worker";
import "./index.scss";
import { ConfigProvider } from "antd";

// import { PostHogProvider } from "posthog-js/react";

// const options = {
//   api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
//   persistence: "localStorage+cookie",
// };

ReactDOM.render(
  <StrictMode>
    {/* <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={options}
    > */}
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "Lato",
        },
      }}
    >
      <ReduxProvider store={store}>
        <BrowserRouter>
          <Route />
        </BrowserRouter>
      </ReduxProvider>
    </ConfigProvider>
    {/* </PostHogProvider> */}
  </StrictMode>,
  document.getElementById("root")
);
