import { put } from "redux-saga/effects";
import { AttendanceSummaryAction } from "../action";
import { Client } from "../../../utils/transport";

//TTENDANCE SUMMARY WEDIGT

export function* GetAttendanceSummaryWedigt(data) {
  var token = data != undefined && data.token;
}

//TTENDANCE SUMMARY TODAY

export function* GetAttendanceSummaryToday(data) {
  let config = {
    method: "POST",
    url: "query/attendance/summary",

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);
    if (response.status === 200) {
      yield put(
        AttendanceSummaryAction.AttendanceSummaryTodaySuccess(
          response.data.data
        )
      );
    } else {
      yield put(AttendanceSummaryAction.AttendanceSummaryTodayFailed(response));
    }
  } catch (err) {
    yield put(AttendanceSummaryAction.AttendanceSummaryTodayFailed(err));
  }
}

//TTENDANCE SUMMARY ATTENDANCE TRENDS

export function* GetAttendanceSummaryAttendanceTrend(data) {
  let config = {
    method: "POST",
    url: "query/attendance/summary",

    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        AttendanceSummaryAction.AttendanceSummaryAttendanceTrendSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        AttendanceSummaryAction.AttendanceSummaryAttendanceTrendFailed(response)
      );
    }
  } catch (err) {
    yield put(
      AttendanceSummaryAction.AttendanceSummaryAttendanceTrendFailed(err)
    );
  }
}
export function* GetAttendanceSummaryAttendanceTrendCurrent(data) {
  let config = {
    method: "POST",
    url: "query/attendance/summary?",

    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);
    if (response.status === 200) {
      // if(_.size(response.data)>0){
      //   yield put(
      //     AttendanceSummaryAction.AttendanceSummaryAttendanceTrendCurrentSuccess(
      //       response.data.data
      //     )
      //   );
      // }else{
      yield put(
        AttendanceSummaryAction.AttendanceSummaryAttendanceTrendCurrentSuccess(
          response.data.data
        )
      );

      // }
    } else {
      yield put(
        AttendanceSummaryAction.AttendanceSummaryAttendanceTrendCurrentFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      AttendanceSummaryAction.AttendanceSummaryAttendanceTrendCurrentFailed(err)
    );
  }
}
export function* GetAttendanceSummaryAttendanceTrendLess(data) {
  let config = {
    method: "POST",
    url: "query/attendance/summary",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        AttendanceSummaryAction.AttendanceSummaryAttendanceTrendLessSuccess(
          response.data.data
        )
      );

      // }
    } else {
      yield put(
        AttendanceSummaryAction.AttendanceSummaryAttendanceTrendLessFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      AttendanceSummaryAction.AttendanceSummaryAttendanceTrendLessFailed(err)
    );
  }
}
//ATTENDANCE SUMMARY BREAK TRENDS

export function* GetAttendanceSummaryBreakTrend(data) {
  let config = {
    method: "POST",
    url: "query/attendance/break_trends",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        AttendanceSummaryAction.AttendanceSummaryBreakTrendSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        AttendanceSummaryAction.AttendanceSummaryBreakTrendFailed(response)
      );
    }
  } catch (err) {
    yield put(AttendanceSummaryAction.AttendanceSummaryBreakTrendFailed(err));
  }
}

//ATTENDANCE SUMMARY BREAK TRENDS LESS

export function* GetAttendanceSummaryBreakTrendLess(data) {
  let config = {
    method: "POST",
    url: "query/attendance/break_trends",

    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        AttendanceSummaryAction.AttendanceSummaryBreakTrendLessSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        AttendanceSummaryAction.AttendanceSummaryBreakTrendLessFailed(response)
      );
    }
  } catch (err) {
    yield put(
      AttendanceSummaryAction.AttendanceSummaryBreakTrendLessFailed(err)
    );
  }
}

//ATTENDANCE SUMMARY BREAK TRENDS DATE WISE

export function* GetAttendanceSummaryBreakTrendDateWise(data) {
  let config = {
    method: "POST",
    url: "query/attendance/break_trends",

    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        AttendanceSummaryAction.AttendanceSummaryBreakTrendDateWiseSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        AttendanceSummaryAction.AttendanceSummaryBreakTrendDateWiseFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      AttendanceSummaryAction.AttendanceSummaryBreakTrendDateWiseFailed(err)
    );
  }
}
//ATTENDANCE SUMMARY LATE ARRIVAL TENDENCY

export function* GetAttendanceSummaryLateArrivalTendency(data) {
  let config = {
    method: "POST",
    url: "query/attendance/late_arrival",

    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        AttendanceSummaryAction.AttendanceSummaryLateArrivalTendencySuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        AttendanceSummaryAction.AttendanceSummaryLateArrivalTendencyFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      AttendanceSummaryAction.AttendanceSummaryLateArrivalTendencyFailed(err)
    );
  }
}

//ATTENDANCE SUMMARY LATE ARRIVAL TENDENCY

export function* GetAttendanceSummaryLateArrivalTendencyLess(data) {
  let config = {
    method: "POST",
    url: "query/attendance/late_arrival",

    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        AttendanceSummaryAction.AttendanceSummaryLateArrivalTendencyLessSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        AttendanceSummaryAction.AttendanceSummaryLateArrivalTendencyLessFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      AttendanceSummaryAction.AttendanceSummaryLateArrivalTendencyLessFailed(
        err
      )
    );
  }
}

//TTENDANCE SUMMARY WORKING TIME

export function* GetAttendanceSummaryWorkingTime(data) {
  let config = {
    method: "POST",
    url: "query/attendance/working_time",

    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },

    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        AttendanceSummaryAction.AttendanceSummaryWorkingTimeSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        AttendanceSummaryAction.AttendanceSummaryWorkingTimeFailed(response)
      );
    }
  } catch (err) {
    yield put(AttendanceSummaryAction.AttendanceSummaryWorkingTimeFailed(err));
  }
}

//TTENDANCE SUMMARY WORKING TIME LESS

export function* GetAttendanceSummaryWorkingTimeLess(data) {
  let config = {
    method: "POST",
    url: "query/attendance/working_time",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        AttendanceSummaryAction.AttendanceSummaryWorkingTimeLessSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        AttendanceSummaryAction.AttendanceSummaryWorkingTimeLessFailed(response)
      );
    }
  } catch (err) {
    yield put(
      AttendanceSummaryAction.AttendanceSummaryWorkingTimeLessFailed(err)
    );
  }
}
