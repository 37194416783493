import * as actionTypes from "./actionTypes";

//* LIST *//

export const TenantListInitital = () => {
  return {
    type: actionTypes.TENANT_LIST_INITIAL,
  };
};
export const TenantListProcess = (data) => {
  return {
    type: actionTypes.TENANT_LIST_PROCESS,
    data,
  };
};

export const TenantListSuccess = (data) => {
  return {
    type: actionTypes.TENANT_LIST_SUCCESS,
    data,
  };
};

export const TenantListFailed = (data) => {
  return {
    type: actionTypes.TENANT_LIST_FAILED,
    data,
  };
};

//* CREATE *//

export const TenantCreateInitial = () => {
  return {
    type: actionTypes.TENANT_CREATE_INITIAL,
  };
};
export const TenantCreateProcess = (data) => {
  return {
    type: actionTypes.TENANT_CREATE_PROCESS,
    data,
  };
};
export const TenantCreateSuccess = (data) => {
  return {
    type: actionTypes.TENANT_CREATE_SUCCESS,
    data,
  };
};
export const TenantCreateFailed = (data) => {
  return {
    type: actionTypes.TENANT_CREATE_FAILED,
    data,
  };
};

//* EDIT *//

export const TenantEditInitial = () => {
  return {
    type: actionTypes.TENANT_EDIT_INITIAL,
  };
};
export const TenantEditProcess = (data) => {
  return {
    type: actionTypes.TENANT_EDIT_PROCESS,
    data,
  };
};
export const TenantEditSuccess = (data) => {
  return {
    type: actionTypes.TENANT_EDIT_SUCCESS,
    data,
  };
};
export const TenantEditFailed = (data) => {
  return {
    type: actionTypes.TENANT_EDIT_FAILED,
    data,
  };
};
