// import { Analytics } from "@june-so/analytics-node";

// let juneAnalytics: Analytics;
let anonymousId = localStorage.getItem("anonymous_id");
// let identityId = localStorage.getItem("identity_id");
let groupId = localStorage.getItem("TenantIdByBaseDomain");

// try {
//   juneAnalytics = new Analytics("VvAvJlT8BvmSsoFK");
// } catch (e) {
//   console.error(e);
// }

const setAnonymousId = () => {
  if (!anonymousId) {
    anonymousId = localStorage.getItem("anonymous_id");
  }
};

export const analyticsIdentifyEvent = (...args) => {
  setAnonymousId();
  try {
    // identityId = args[0] || identityId;
    // window.analytics?.identify(...args);
    // juneAnalytics.identify({
    //   userId: args[0],
    //   anonymousId,
    //   traits: args[1] || {},
    // });
  } catch (e) {
    console.error(e);
  }
};

export const analyticsGroupEvent = (...args) => {
  setAnonymousId();
  try {
    groupId = args[0] || groupId;
    // window.analytics?.group(...args);
    // juneAnalytics.group({
    //   userId: identityId,
    //   anonymousId,
    //   groupId: args[0],
    //   traits: args[1] || {},
    // });
  } catch (e) {
    console.error(e);
  }
};

export const analyticsPageEvent = (...args) => {
  setAnonymousId();

  try {
    // window.analytics?.page(args[0], { ...(args[1] || {}), tenantId: groupId });
    // juneAnalytics.page({
    //   userId: identityId,
    //   anonymousId,
    //   name: args[0],
    //   properties: args[1] || {},
    //   context: {
    //     groupId,
    //   },
    // });
  } catch (e) {
    console.error(e);
  }
};

export const analyticsTrackEvent = (...args) => {
  setAnonymousId();
  try {
    // window.analytics?.track(args[0], { ...(args[1] || {}), tenantId: groupId });
    // juneAnalytics.track({
    //   userId: identityId,
    //   anonymousId,
    //   event: args[0],
    //   properties: args[1] || {},
    //   context: {
    //     groupId,
    //   },
    // });
  } catch (e) {
    console.error(e);
  }
};
