import React, { useState } from "react";
import PropTypes from "prop-types";
import "./index.scss";
import { BsArrowDownRight, BsArrowUpRight } from "react-icons/bs";
import { InfoCircleFilled } from "@ant-design/icons";

const Notification = (props) => {
  const {
    iconcolor,
    boxmargin,
    n_icon,
    n_text,
    close_icon,
    borderColor,
    background,
    textcolor,
  } = props;

  const [close, setClose] = useState();

  return (
    <>
      <div
        className={close ? "notification none" : "notification "}
        style={{
          margin: boxmargin,
          background: background,
          border: "1px solid",
          borderColor: borderColor,
        }}
      >
        <div className="n_icon_text">
          <div className="n_icon" style={{ color: iconcolor }}>
            {n_icon}
          </div>
          <div className="n_text" style={{ color: textcolor }}>
            {n_text}
          </div>
        </div>
        <div className="n_close" onClick={() => setClose(!close)}>
          {close_icon}
        </div>
      </div>
    </>
  );
};

Notification.propTypes = {
  getList: PropTypes.func,
};

export default Notification;
