import * as actionType from "./actionType";

export const TeamCreateInitial = () => {
  return {
    type: actionType.TEAM_CREATE_INITIAL,
  };
};

export const TeamCreateProcess = (data) => {
  return {
    type: actionType.TEAM_CREATE_PROCESS,
    data,
  };
};

export const TeamCreateSuccess = (data) => {
  return {
    type: actionType.TEAM_CREATE_SUCCESS,
    data,
  };
};

export const TeamCreateFailed = (data) => {
  return {
    type: actionType.TEAM_CREATE_FAILED,
    data,
  };
};

export const TeamListInitial = () => {
  return {
    type: actionType.TEAM_LIST_INITIAL,
  };
};

export const TeamListProcess = (data) => {
  return {
    type: actionType.TEAM_LIST_PROCESS,
    data,
  };
};

export const TeamListSuccess = (data) => {
  return {
    type: actionType.TEAM_LIST_SUCCESS,
    data,
  };
};

export const TeamListFailed = (data) => {
  return {
    type: actionType.TEAM_LIST_FAILED,
    data,
  };
};

export const TeamUpdateInitial = () => {
  return {
    type: actionType.TEAM_UPDATE_INITIAL,
  };
};

export const TeamUpdateProcess = (data) => {
  return {
    type: actionType.TEAM_UPDATE_PROCESS,
    data,
  };
};

export const TeamUpdateSuccess = (data) => {
  return {
    type: actionType.TEAM_UPDATE_SUCCESS,
    data,
  };
};

export const TeamUpdateFailed = (data) => {
  return {
    type: actionType.TEAM_UPDATE_FAILED,
    data,
  };
};

export const TeamDeleteInitial = () => {
  return {
    type: actionType.TEAM_DELETE_INITIAL,
  };
};

export const TeamDeleteProcess = (data) => {
  return {
    type: actionType.TEAM_DELETE_PROCESS,
    data,
  };
};

export const TeamDeleteSuccess = (data) => {
  return {
    type: actionType.TEAM_DELETE_SUCCESS,
    data,
  };
};

export const TeamDeleteFailed = (data) => {
  return {
    type: actionType.TEAM_DELETE_FAILED,
    data,
  };
};

export const TeamMemberInitial = () => {
  return {
    type: actionType.TEAM_MEMBER_UPDATE_INITIAL,
  };
};

export const TeamMemberProcess = (data) => {
  return {
    type: actionType.TEAM_MEMBER_UPDATE_PROCESS,
    data,
  };
};

export const TeamMemberSuccess = (data) => {
  return {
    type: actionType.TEAM_MEMBER_UPDATE_SUCCESS,
    data,
  };
};

export const TeamMemberFailed = (data) => {
  return {
    type: actionType.TEAM_MEMBER_UPDATE_FAILED,
    data,
  };
};

export const TeamManegerInitial = () => {
  return {
    type: actionType.TEAM_MANEGER_UPDATE_INITIAL,
  };
};

export const TeamManegerProcess = (data) => {
  return {
    type: actionType.TEAM_MANEGER_UPDATE_PROCESS,
    data,
  };
};

export const TeamManegerSuccess = (data) => {
  return {
    type: actionType.TEAM_MANEGER_UPDATE_SUCCESS,
    data,
  };
};

export const TeamManegerFailed = (data) => {
  return {
    type: actionType.TEAM_MANEGER_UPDATE_FAILED,
    data,
  };
};

export const UpdateUserSettingInitial = () => {
  return {
    type: actionType.UPDATE_USER_SETTING_INITIAL,
  };
};

export const UpdateUserSettingProcess = (data) => {
  return {
    type: actionType.UPDATE_USER_SETTING_PROCESS,
    data,
  };
};

export const UpdateUserSettingSuccess = (data) => {
  return {
    type: actionType.UPDATE_USER_SETTING_SUCCESS,
    data,
  };
};

export const UpdateUserSettingFailed = (data) => {
  return {
    type: actionType.UPDATE_USER_SETTING_FAILED,
    data,
  };
};
