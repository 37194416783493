import * as actionTypes from "./actionTypes";
import dayjs from "dayjs";

const initialState = {
  open: false,
  startDate: dayjs().subtract(6, "d"),
  endDate: dayjs(),
  tab: 0,
  identityId: null,
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.OPEN_USER_DETAIL:
      return {
        open: true,
        startDate: action.payload.startDate || dayjs().subtract(6, "d"),
        endDate: action.payload.endDate || dayjs(),
        tab: action.payload.tab || 0,
        identityId: action.payload.identityId,
      };

    case actionTypes.CLOSE_USER_DETAIL:
      return {
        open: false,
        startDate: dayjs().subtract(6, "d"),
        endDate: dayjs(),
        tab: 0,
        identityId: null,
      };

    default:
      return state;
  }
};
