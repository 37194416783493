import * as actionTypes from "./actionTypes";

export const LeaveTypeListInitial = () => {
  return {
    type: actionTypes.LEAVE_TYPE_LIST_INITIAL,
  };
};
export const LeaveTypeListProcess = (payload) => {
  return {
    type: actionTypes.LEAVE_TYPE_LIST_PROCESS,
    payload,
  };
};
export const LeaveTypeListSuccess = (data) => {
  return {
    type: actionTypes.LEAVE_TYPE_LIST_SUCCESS,
    data,
  };
};
export const LeaveTypeListFailed = (error) => {
  return {
    type: actionTypes.LEAVE_TYPE_LIST_FAILED,
    error,
  };
};

//CREATE LEAVE TYPE

export const LeaveTypeCreateInitial = () => {
  return {
    type: actionTypes.LEAVE_TYPE_CREATE_INITIAL,
  };
};

export const LeaveTypeCreateProcess = (payload) => {
  return {
    type: actionTypes.LEAVE_TYPE_CREATE_PROCESS,
    payload,
  };
};
export const LeaveTypeCreateSuccess = (data) => {
  return {
    type: actionTypes.LEAVE_TYPE_CREATE_SUCCESS,
    data,
  };
};
export const LeaveTypeCreateFailed = (error) => {
  return {
    type: actionTypes.LEAVE_TYPE_CREATE_FAILED,
    error,
  };
};

//EDIT LEAVE TYPE

export const LeaveTypeEditInitial = () => {
  return {
    type: actionTypes.LEAVE_TYPE_EDIT_INITIAL,
  };
};
export const LeaveTypeEditProcess = (payload) => {
  return {
    type: actionTypes.LEAVE_TYPE_EDIT_PROCESS,
    payload,
  };
};
export const LeaveTypeEditSuccess = (data) => {
  return {
    type: actionTypes.LEAVE_TYPE_EDIT_SUCCESS,
    data,
  };
};
export const LeaveTypeEditFailed = (error) => {
  return {
    type: actionTypes.LEAVE_TYPE_EDIT_FAILED,
    error,
  };
};
