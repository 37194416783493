export const RESET_TASK = "RESET_TASK";

export const GET_TASK = "GET_TASK";
export const GET_TASK_SUCCESS = "GET_TASK_SUCCESS";
export const GET_TASK_FAILED = "GET_TASK_FAILED";

export const FETCH_TASKS = "FETCH_TASKS";
export const FETCH_TASKS_SUCCESS = "FETCH_TASKS_SUCCESS";
export const FETCH_TASKS_FAILED = "FETCH_TASKS_FAILED";

export const CREATE_TASK = "CREATE_TASK";
export const CREATE_TASK_SUCCESS = "CREATE_TASK_SUCCESS";
export const CREATE_TASK_FAILED = "CREATE_TASK_FAILED";

export const DELETE_TASK = "DELETE_TASK";
export const DELETE_TASK_SUCCESS = "DELETE_TASK_SUCCESS";
export const DELETE_TASK_FAILED = "DELETE_TASK_FAILED";

export const UPDATE_TASK = "UPDATE_TASK";
export const UPDATE_TASK_SUCCESS = "UPDATE_TASK_SUCCESS";
export const UPDATE_TASK_FAILED = "UPDATE_TASK_FAILED";

export const FETCH_CHILD_TASKS = "FETCH_CHILD_TASKS";
export const FETCH_CHILD_TASKS_SUCCESS = "FETCH_CHILD_TASKS_SUCCESS";
export const FETCH_CHILD_TASKS_FAILED = "FETCH_CHILD_TASKS_FAILED";

export const SET_TASK_ERROR_MESSAGE = "SET_TASK_ERROR_MESSAGE";

export const SET_SELECTED_TASK = "SET_SELECTED_TASK";

export const GET_TASK_ATTACHMENT = "GET_TASK_ATTACHMENT";
export const GET_TASK_ATTACHMENT_SUCCESS = "GET_TASK_ATTACHMENT_SUCCESS";
export const GET_TASK_ATTACHMENT_FAILED = "GET_TASK_ATTACHMENT_FAILED";

export const CREATE_TASK_ATTACHMENT = "CREATE_TASK_ATTACHMENT";
export const CREATE_TASK_ATTACHMENT_SUCCESS = "CREATE_TASK_ATTACHMENT_SUCCESS";
export const CREATE_TASK_ATTACHMENT_FAILED = "CREATE_TASK_ATTACHMENT_FAILED";

export const DELETE_TASK_ATTACHMENT = "DELETE_TASK_ATTACHMENT";
export const DELETE_TASK_ATTACHMENT_SUCCESS = "DELETE_TASK_ATTACHMENT_SUCCESS";
export const DELETE_TASK_ATTACHMENT_FAILED = "DELETE_TASK_ATTACHMENT_FAILED";

export const UPDATE_TASK_ATTACHMENT = "UPDATE_TASK_ATTACHMENT";
export const UPDATE_TASK_ATTACHMENT_SUCCESS = "UPDATE_TASK_ATTACHMENT_SUCCESS";
export const UPDATE_TASK_ATTACHMENT_FAILED = "UPDATE_TASK_ATTACHMENT_FAILED";

export const FETCH_TASK_ATTACHMENTS = "FETCH_TASK_ATTACHMENTS";
export const FETCH_TASK_ATTACHMENTS_SUCCESS = "FETCH_TASK_ATTACHMENTS_SUCCESS";
export const FETCH_TASK_ATTACHMENTS_FAILED = "FETCH_TASK_ATTACHMENTS_FAILED";

export const SET_TASK_FILTERS = "SET_TASK_FILTERS";
