import React from "react";

export const Goals = ({ viewBox = "0 0 20 20", color = "white" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        d="M10,11.7c-0.5,0-0.8-0.4-0.8-0.8V8.4c0,0,0,0,0,0V1.7c0-0.3,0.1-0.6,0.4-0.7c0.2-0.2,0.6-0.2,0.8,0L17,4.3
	c0.3,0.1,0.5,0.4,0.5,0.7S17.3,5.6,17,5.7l-6.2,3.1v2C10.8,11.3,10.5,11.7,10,11.7z M10.8,3v4l4-2L10.8,3z"
      />
      <path
        d="M10,19.2c-1.1,0-2.2-0.2-3.2-0.7c-1.5-0.6-2.7-1.6-3.6-3c-0.9-1.3-1.4-2.9-1.5-4.5c0-1.6,0.4-3.2,1.2-4.5
	s2-2.5,3.5-3.2c0.4-0.2,0.9,0,1.1,0.4c0.2,0.4,0,0.9-0.4,1.1C5.9,5.4,4.9,6.3,4.3,7.4c-0.7,1.1-1,2.4-0.9,3.6c0,1.3,0.4,2.5,1.2,3.6
	c0.7,1.1,1.7,1.9,2.9,2.4c1.2,0.5,2.5,0.6,3.7,0.4c1.3-0.2,2.4-0.8,3.4-1.7s1.6-2,1.9-3.2c0.3-1.2,0.3-2.5-0.1-3.8
	c-0.1-0.4,0.1-0.9,0.5-1c0.4-0.1,0.9,0.1,1,0.5c0.5,1.5,0.5,3.1,0.1,4.7c-0.4,1.5-1.2,2.9-2.4,4c-1.2,1.1-2.6,1.8-4.2,2.1
	C10.9,19.1,10.5,19.2,10,19.2z"
      />
      <path
        d="M10,15.8c-0.4,0-0.7,0-1.1-0.1c-0.8-0.2-1.6-0.6-2.2-1.1c-0.6-0.6-1.1-1.3-1.4-2c-0.3-0.8-0.4-1.6-0.3-2.5
	C5.2,9.3,5.5,8.5,6,7.8c0.3-0.4,0.8-0.4,1.2-0.2c0.4,0.3,0.4,0.8,0.2,1.2c-0.3,0.4-0.6,1-0.6,1.5c-0.1,0.5,0,1.1,0.2,1.6
	c0.2,0.5,0.5,1,0.9,1.4c0.4,0.4,0.9,0.6,1.5,0.7c0.5,0.1,1.1,0.1,1.6,0c0.5-0.2,1-0.4,1.4-0.8c0.4-0.4,0.7-0.9,0.9-1.4
	c0.2-0.5,0.2-1.1,0.1-1.6c-0.1-0.5,0.2-0.9,0.7-1c0.5-0.1,0.9,0.2,1,0.7c0.2,0.8,0.1,1.7-0.1,2.5c-0.2,0.8-0.7,1.5-1.3,2.1
	c-0.6,0.6-1.3,1-2.1,1.2C10.9,15.8,10.5,15.8,10,15.8z"
      />
    </svg>
  );
};
