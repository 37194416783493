import * as actionTypes from "./actionType";

const searchDesignation = (dataset, search) => {
  if (search.length > 0) {
    var searchdata = _.filter(dataset, (list) => {
      return list.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
        ? true
        : false;
    });

    return searchdata;
  } else {
    return dataset;
  }
};

const initialState = {
  designation_create_processing: false,
  designation_create_success: false,
  designation_create_failed: false,
  designation_create_data: [],
  designation_create_message: "",

  designation_list_processing: false,
  designation_list_success: false,
  designation_list_failed: false,
  designation_list_data: [],
  designation_list_message: "",
  designationMaster: [],

  designation_update_processing: false,
  designation_update_success: false,
  designation_update_failed: false,
  designation_update_data: [],
  designation_update_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.DESIGNATION_CREATE_INITIAL:
      return {
        ...state,
        designation_create_processing: false,
        designation_create_success: false,
        designation_create_failed: false,
        designation_create_data: [],
        designation_create_message: "",
      };
    case actionTypes.DESIGNATION_CREATE_PROCESS:
      return {
        ...state,
        designation_create_processing: true,
        designation_create_success: false,
        designation_create_failed: false,
        designation_create_data: [],
        designation_create_message: "",
      };

    case actionTypes.DESIGNATION_CREATE_SUCCESS:
      return {
        ...state,
        designation_create_processing: false,
        designation_create_success: true,
        designation_create_failed: false,
        designation_create_data: action.data,
        designation_list_data: [...state.designation_list_data, action.data],
        designationMaster: [...state.designation_list_data, action.data],
        designation_create_message: "",
      };
    case actionTypes.DESIGNATION_CREATE_FAILED:
      return {
        ...state,
        designation_create_processing: false,
        designation_create_success: false,
        designation_create_failed: true,
        designation_create_data: [],
        designation_create_message: action.data,
      };

    case actionTypes.DESIGNATION_LIST_INITIAL:
      return {
        ...state,
        designation_list_processing: false,
        designation_list_success: false,
        designation_list_failed: false,
        designation_list_data: [],
        designationMaster: [],
        designation_list_message: "",
      };
    case actionTypes.DESIGNATION_LIST_PROCESS:
      return {
        ...state,
        designation_list_processing: true,
        designation_list_success: false,
        designation_list_failed: false,
        designation_list_data: [],
        designationMaster: [],
        designation_list_message: "",
      };

    case actionTypes.DESIGNATION_LIST_SUCCESS:
      return {
        ...state,
        designation_list_processing: false,
        designation_list_success: true,
        designation_list_failed: false,
        designation_list_data: action.data.data,
        designationMaster: action.data.data,
        designation_list_message: "",
      };
    case actionTypes.DESIGNATION_LIST_FAILED:
      return {
        ...state,
        designation_list_processing: false,
        designation_list_success: false,
        designation_list_failed: true,
        designation_list_data: [],

        designation_list_message: action.data,
      };

    case actionTypes.DESIGNATION_LIST_SEARCH:
      return {
        ...state,
        designation_list_data: searchDesignation(
          state.designationMaster,
          action.data
        ),
      };

    case actionTypes.DESIGNATION_UPDATE_INITIAL:
      return {
        ...state,
        designation_update_processing: false,
        designation_update_success: false,
        designation_update_failed: false,
        designation_update_data: [],
        designation_update_message: "",
      };
    case actionTypes.DESIGNATION_UPDATE_PROCESS:
      return {
        ...state,
        designation_update_processing: true,
        designation_update_success: false,
        designation_update_failed: false,
        designation_update_data: [],
        designation_update_message: "",
      };

    case actionTypes.DESIGNATION_UPDATE_SUCCESS:
      const fetchDesignationlist = [...state.designation_list_data];
      const findIndex = fetchDesignationlist.findIndex(
        (item) => item.id == action.data.id
      );
      fetchDesignationlist[findIndex] = action.data;
      return {
        ...state,
        designation_update_processing: false,
        designation_update_success: true,
        designation_update_failed: false,
        designation_update_data: action.data,
        designation_list_data: fetchDesignationlist,
        designation_update_message: "",
      };
    case actionTypes.DESIGNATION_UPDATE_FAILED:
      return {
        ...state,
        designation_update_processing: false,
        designation_update_success: false,
        designation_update_failed: true,
        designation_update_data: [],
        designation_update_message: action.data,
      };

    default:
      return state;
  }
};
