import { put } from "redux-saga/effects";
import { ApplicationSummaryAction } from "../action";
import { Client } from "../../../utils/transport";

//Application SUMMARY TOP LEAST ACTIVE TEAM

export function* GetApplicationSummaryTopLeastActiveTeam(data) {
  var token = data != undefined && data.token;
}

//Application SUMMARY LATE ARRIVAL TENDENCY

export function* GetApplicationSummaryTeamWiseApplicationBreakDown(data) {
  var token = data != undefined && data.token;
}

export function* GetApplicationSummaryTopAppUrlData(data) {
  let config = {
    method: "POST",
    url: "query/activity/tops",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationSummaryAction.ApplicationSummaryTopAppUrlSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ApplicationSummaryAction.ApplicationSummaryTopAppUrlFailed(response)
      );
    }
  } catch (err) {
    yield put(ApplicationSummaryAction.ApplicationSummaryTopAppUrlFailed(err));
  }
}

export function* GetApplicationSummaryTotalApplicationUsageData(data) {
  let config = {
    method: "POST",
    url: "query/application/application_usage",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationSummaryAction.ApplicationSummaryTotalApplicationUsageSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ApplicationSummaryAction.ApplicationSummaryTotalApplicationUsageFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ApplicationSummaryAction.ApplicationSummaryTotalApplicationUsageFailed(
        err
      )
    );
  }
}

export function* GetApplicationSummaryTotalUrlUsageData(data) {
  let config = {
    method: "POST",
    url: "query/application/urls_usage",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,

    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationSummaryAction.ApplicationSummaryTotalUrlUsageSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ApplicationSummaryAction.ApplicationSummaryTotalUrlUsageFailed(response)
      );
    }
  } catch (err) {
    yield put(
      ApplicationSummaryAction.ApplicationSummaryTotalUrlUsageFailed(err)
    );
  }
}

export function* GetApplicationSummaryTopButtomActiveTeam(data) {
  let config = {
    method: "POST",
    url: "query/application/team_wise_application",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ApplicationSummaryAction.ApplicationSummaryTopButtomActiveTeamSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ApplicationSummaryAction.ApplicationSummaryTopButtomActiveTeamFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ApplicationSummaryAction.ApplicationSummaryTopButtomActiveTeamFailed(err)
    );
  }
}

export function* GetApplicationSummaryCategoryUtilizationData(data) {
  let config = {
    method: "POST",
    url: "query/application/category_utilization",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);
    if (response.status === 200) {
      yield put(
        ApplicationSummaryAction.ApplicationSummaryCategoryUtilizationSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ApplicationSummaryAction.ApplicationSummaryCategoryUtilizationFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ApplicationSummaryAction.ApplicationSummaryCategoryUtilizationFailed(err)
    );
  }
}
