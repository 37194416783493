import { put } from "redux-saga/effects";
import { TaskActivitiesAction } from "../action";
import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { notification } from "antd";

export function* fetchTaskActivitiesSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/task_activities/search?${action.payload.query}`,
    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };
  try {
    const response = yield Client.request(config);

    if (action.payload.isTimer) {
      yield put(
        TaskActivitiesAction.fetchActiveTimerActivitySuccess(
          (response.data?.data?.length || 0) > 0 ? response.data.data[0] : null
        )
      );
    } else {
      yield put(TaskActivitiesAction.fetchTaskActivitiesSuccess(response.data));
    }
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TaskActivitiesAction.fetchTaskActivitiesFailed(errorMessage));
  }
}

export function* createTaskActivitySaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/task_activities/`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);

    if (action.payload.isTimer) {
      yield put(
        TaskActivitiesAction.createActiveTimerActivitySuccess(response.data)
      );
    } else {
      yield put(TaskActivitiesAction.createTaskActivitySuccess(response.data));
      // notification.success({
      //   message: `Successfully Created TaskActivity!`,
      // });
    }
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TaskActivitiesAction.createTaskActivityFailed(errorMessage));
  }
}

export function* updateTaskActivitySaga(action) {
  const config = {
    method: "PUT",
    url: `/api/v1/task_activities/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);

    if (action.payload.isTimer) {
      yield put(
        TaskActivitiesAction.updateActiveTimerActivitySuccess(response.data)
      );
    } else {
      yield put(TaskActivitiesAction.updateTaskActivitySuccess(response.data));
      // notification.success({
      //   message: `Successfully Updated TaskActivity!`,
      // });
    }
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TaskActivitiesAction.updateTaskActivityFailed(errorMessage));
  }
}

export function* deleteTaskActivitySaga(action) {
  const config = {
    method: "DELETE",
    url: `/api/v1/task_activities/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    yield Client.request(config);

    yield put(TaskActivitiesAction.deleteTaskActivitySuccess());
    if (action.payload.isTimer) {
      notification.success({
        message: `Successfully Deleted Tracked Time`,
      });
    } else {
      notification.success({
        message: `Successfully Deleted TaskActivity!`,
      });
    }
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TaskActivitiesAction.deleteTaskActivityFailed(errorMessage));
  }
}

// export function* fetchActiveTimerActivitySaga(action) {
//   const config = {
//     method: "POST",
//     url: `/api/v1/task_activities/search?${action.payload.query}`,
//     headers: {
//       ...action.payload.headers,
//     },
//     data: { ...action.payload.body },
//   };
//   try {
//     const response = yield Client.request(config);
//     yield put(
//       TaskActivitiesAction.fetchActiveTimerActivitySuccess(
//         (response.data?.data?.length || 0) > 0 ? response.data.data[0] : null
//       )
//     );
//   } catch (err) {
//     let errorMessage = SOMETHING_WENT_WRONG;
//     if (err.response?.status < 500) {
//       errorMessage = err.response?.data?.errors?.[0]?.detail;
//     }
//     yield put(
//       TaskActivitiesAction.fetchActiveTimerActivityFailed(errorMessage)
//     );
//   }
// }
