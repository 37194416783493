import { put } from "redux-saga/effects";
import { AlertsAction } from "../action";
import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { notification } from "antd";
import dayjs from "dayjs";
import { ExportCSV } from "utils/export-csv";
import { ALERT_TYPE_LABEL } from "constants/constants.tsx";

const ExportData = (fileName, data, colunms, filterDate) => {
  let result = [];
  const generatedDate =
    "Generated Date:-" + dayjs().format("DD-MM-YYYY hh:mm a");

  const generatedData = [
    fileName,
    "Filter Date:-" + filterDate,
    generatedDate,
    colunms.join(),
  ];

  data?.forEach((record) => {
    const addColunmsValues = [
      dayjs(record?.created_date).format("DD MMM YYYY"),
      record?.description,
      ALERT_TYPE_LABEL[record?.type],
      record?.source_identity !== null
        ? record?.source_identity?.first_name +
          " " +
          record?.source_identity?.last_name
        : "--",
      dayjs(record?.created_date).format("hh:mm:ss A"),
    ];

    generatedData.push(addColunmsValues.join(","));
  });
  result = generatedData;
  return result;
};

const getDownloadCSV = (payload, data) => {
  const csvData = data?.data;
  const date = dayjs(
    payload?.bodyData?.greaterThanOrEqualTo?.created_date
  ).format("YYYY-MM-DD");

  const colunms = [
    "Date",
    "Alert Description",
    "Alert Type",
    "Triggered for",
    "Triggered time",
  ];
  const exdata = ExportData("Alert Logs", csvData, colunms, date);
  ExportCSV("ALERT_LOGS_", exdata);
};

export function* fetchAlertsSaga(action) {
  const config = {
    method: "GET",
    url: `/api/v1/alert_rules/?${action.payload.query}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    const response = yield Client.request(config);

    yield put(AlertsAction.fetchAlertsSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(AlertsAction.fetchAlertsFailed(errorMessage));
  }
}

export function* createAlertSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/alert_rules/`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);

    yield put(AlertsAction.createAlertSuccess(response.data));
    notification.success({
      message: `Successfully Created Alert Rule!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(AlertsAction.createAlertFailed(errorMessage));
  }
}

export function* updateAlertSaga(action) {
  const config = {
    method: "PUT",
    url: `/api/v1/alert_rules/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);

    yield put(AlertsAction.updateAlertSuccess(response.data));
    notification.success({
      message: `Successfully Updated Alert Rule!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(AlertsAction.updateAlertFailed(errorMessage));
  }
}

export function* deleteAlertSaga(action) {
  const config = {
    method: "DELETE",
    url: `/api/v1/alert_rules/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    yield Client.request(config);

    yield put(AlertsAction.deleteAlertSuccess());
    notification.success({
      message: `Successfully Deleted Alert Rule!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(AlertsAction.deleteAlertFailed(errorMessage));
  }
}

export function* fetchAlertLogsSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/admin/alert-logs/?${action.payload.query}`,
    headers: {
      "Content-Type": "application/json",
      ...action.payload.headers,
    },
    data: action.payload.bodyData,
    fetchPolicy: "no-cache",
    maxBodyLength: Infinity,
  };
  try {
    const response = yield Client.request(config);

    yield put(AlertsAction.fetchAlertsLogsSuccess(response?.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors[0]?.detail;
    }
    yield put(AlertsAction.fetchAlertsLogsFailed(errorMessage));
  }
}

export function* downloadAlertLogsSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/admin/alert-logs/?${action.payload.query}`,
    headers: {
      "Content-Type": "application/json",
      ...action.payload.headers,
    },
    data: action.payload.bodyData,
    fetchPolicy: "no-cache",
    maxBodyLength: Infinity,
  };
  try {
    const response = yield Client.request(config);
    getDownloadCSV(action.payload, response?.data);
    yield put(AlertsAction.downloadAlertsLogs(response?.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors[0]?.detail;
    }
    yield put(AlertsAction.downloadAlertsLogsFailed(errorMessage));
  }
}
