import { put } from "redux-saga/effects";
import { ManualTimeAction } from "../action";
import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { notification } from "antd";
import dayjs from "dayjs";
import { ExportCSV } from "utils/export-csv";

const ExportData = (
  fileName,
  data,
  colunms,
  filterDate,
  user_map_data,
  team_map_data
) => {
  let result = [];
  const Generated = "Generated Date:-" + dayjs().format("DD-MM-YYYY hh:mm a");
  const generatedData = [
    fileName,
    // "Teams: - " + teams.join(",").replaceAll(",", "/"),
    "Date:-" + filterDate,

    Generated,
    colunms.join(),
  ];

  data.forEach((record) => {
    const teamId = user_map_data[record?.created_by_id]?.attached_group_id;
    const addColunmsValues = [
      `${user_map_data[record?.created_by_id]?.identity?.first_name} ${
        user_map_data[record?.created_by_id]?.identity?.last_name
      }`,
      team_map_data[teamId]?.name,
      dayjs(record?.date).format("YYYY-MM-DD"),
      dayjs(record?.start_time, "HH:mm:ss").format("hh:mm A"),
      dayjs(record?.end_time, "HH:mm:ss").format("hh:mm A"),
      dayjs(record?.created_date).format("YYYY-MM-DD"),
      record?.app_policy_status,
      record?.summary,
      record?.status,
      record?.approval_remarks,
      record?.approver?.first_name + " " + record?.approver?.last_name,
      record?.approver?.first_name + " " + record?.approver?.last_name,
    ];

    generatedData.push(addColunmsValues.join(","));
  });
  result = generatedData;
  return result;
};
const getDownloadCSV = (payload, data) => {
  // const teamIds = payload.payload.body.group_id.split(",");
  // const selectTeamNames = payload.payload.team_list_data
  //   .filter((item) => teamIds.includes(item.id))
  //   .map((item) => item.name);

  const csvdata = data?.data;

  const date = dayjs(payload?.payload.body.date).format("YYYY-MM-DD");

  const colunms = [
    "User",
    "Team",
    "Date",
    "Start Time",
    "End Time",
    "Created date",
    "Time Attribution",
    "Summary",
    "Status",
    "Remark",
    "Approved by",
    "Rejected by",
  ];
  const exdata = ExportData(
    "Manual Time",
    csvdata,
    colunms,
    date,
    // selectTeamNames,
    payload.payload.user_map_data,
    payload.payload.team_map_data
  );

  ExportCSV("ManualTime_List_", exdata);
};

export function* fetchManualTimeSaga(action) {
  const queryString = Object.entries(action.payload.body)
    .map(([key, value]) => {
      if (typeof value === "object") {
        return Object.entries(value)
          .map(
            ([subKey, subValue]) =>
              `${key}[${subKey}]=${encodeURIComponent(subValue)}`
          )
          .join("&");
      } else {
        return `${key}=${encodeURIComponent(value)}`;
      }
    })
    .join("&");
  const config = {
    method: "POST",
    url: action.payload.isOwner
      ? `/api/v1/me/hierarchy/time_logs/search/?${queryString}`
      : `/api/v1/me/time_logs/search/`,

    headers: {
      ...action.payload.header,
    },
    data: !action.payload.isOwner ? action.payload.body : {},
  };
  try {
    const response = yield Client.request(config);
    yield put(ManualTimeAction.fetchManualTimeSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(ManualTimeAction.fetchManualTimeFailed(errorMessage));
  }
}

export function* createManualTimeSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/me/time_logs/`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);
    yield put(ManualTimeAction.createManualTimeSuccess(response.data));
    notification.success({
      message: `Successfully Created Manual Time!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(ManualTimeAction.createManualTimeFailed(errorMessage));
  }
}

export function* updateManualTimeSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/me/hierarchy/time_logs/${action.payload.id}/approve/?approvalRemarks=${action.payload.approvalRemarks}&isRejected=${action.payload.isRejected}`,

    headers: {
      ...action.payload.headers,
    },
  };
  try {
    const response = yield Client.request(config);
    yield put(ManualTimeAction.updateManualTimeSuccess(response.data));
    notification.success({
      message: `Status Updated Successfully!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(ManualTimeAction.updateManualTimeFailed(errorMessage));
  }
}

export function* deleteManualTimeSaga(action) {
  const config = {
    method: "DELETE",
    url: `/api/v1/me/time_logs/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    yield Client.request(config);

    yield put(ManualTimeAction.deleteManualTimeSuccess());
    notification.success({
      message: `Successfully Deleted ManualTime!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(ManualTimeAction.deleteManualTimeFailed(errorMessage));
  }
}

export function* downloadManualTimeSaga(action) {
  const queryString = Object.entries(action.payload.body)
    .map(([key, value]) => {
      if (typeof value === "object") {
        return Object.entries(value)
          .map(
            ([subKey, subValue]) =>
              `${key}[${subKey}]=${encodeURIComponent(subValue)}`
          )
          .join("&");
      } else {
        return `${key}=${encodeURIComponent(value)}`;
      }
    })
    .join("&");
  const config = {
    method: "POST",
    url: action.payload.isOwner
      ? `/api/v1/me/hierarchy/time_logs/search/?${queryString}`
      : `/api/v1/me/time_logs/search/`,

    headers: {
      ...action.payload.header,
    },
    data: !action.payload.isOwner ? action.payload.body : {},
  };
  try {
    const response = yield Client.request(config);
    getDownloadCSV(action, response.data);

    yield put(ManualTimeAction.downloadManualTimeSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(ManualTimeAction.downloadManualTimeFailed(errorMessage));
  }
}
