import { getStatusMapFromList } from "utils/projectUtils.tsx";
import * as actionTypes from "./actionTypes";

const initialPagination = { totalCount: 0 };

const initialState = {
  getProjectLoading: true,
  selectedProject: null,
  statusMap: {},
  fetchProjectsLoading: true,
  projectsData: {},
  pagination: initialPagination,
  projectLoading: false,
  projectSuccess: false,
  deleteProjectSuccess: false,
  deleteProjectLoading: false,
  projectErrorMessage: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_PROJECT:
      return {
        ...state,
        getProjectLoading: true,
        projectErrorMessage: "",
        selectedProject: null,
        statusMap: {},
      };

    case actionTypes.GET_PROJECT_SUCCESS:
      return {
        ...state,
        getProjectLoading: false,
        selectedProject: action.payload,
        statusMap: getStatusMapFromList(action.payload.configuration.statuses),
      };

    case actionTypes.SET_SELECTED_PROJECT:
      return {
        ...state,
        selectedProject: action.payload,
        statusMap: action.payload
          ? getStatusMapFromList(action.payload.configuration.statuses)
          : {},
      };

    case actionTypes.GET_PROJECT_FAILED:
      return {
        ...state,
        getProjectLoading: false,
        projectErrorMessage: action.payload,
      };

    case actionTypes.FETCH_PROJECTS:
      return {
        ...state,
        fetchProjectsLoading: true,
        projectErrorMessage: "",
      };

    case actionTypes.FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        fetchProjectsLoading: false,
        projectsData: action.payload.data.reduce(
          (acc, project) => ({ ...acc, [project.id]: project }),
          {}
        ),
        pagination: action.payload.meta || state.pagination,
      };

    case actionTypes.FETCH_PROJECTS_FAILED:
      return {
        ...state,
        fetchProjectsLoading: false,
        projectsData: {},
        pagination: initialPagination,
        projectErrorMessage: action.payload,
      };

    case actionTypes.CREATE_PROJECT:
      return {
        ...state,
        projectLoading: true,
        projectErrorMessage: "",
        projectSuccess: false,
      };

    case actionTypes.CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        projectLoading: false,
        projectsData: {
          ...state.projectsData,
          [action.payload.id]: action.payload,
        },
        pagination: {
          ...state.pagination,
          totalCount: state.pagination.totalCount + 1,
        },
        projectSuccess: true,
      };

    case actionTypes.CREATE_PROJECT_FAILED:
      return {
        ...state,
        projectLoading: false,
        projectErrorMessage: action.payload,
      };

    case actionTypes.UPDATE_PROJECT:
      return {
        ...state,
        projectLoading: true,
        projectErrorMessage: "",
        projectSuccess: false,
      };

    case actionTypes.UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        projectLoading: false,
        projectsData: {
          ...state.projectsData,
          [action.payload.id]: action.payload,
        },
        projectSuccess: true,
      };

    case actionTypes.UPDATE_PROJECT_FAILED:
      return {
        ...state,
        projectLoading: false,
        projectErrorMessage: action.payload,
      };

    case actionTypes.DELETE_PROJECT:
      return {
        ...state,
        deleteProjectLoading: true,
        projectErrorMessage: "",
        deleteProjectSuccess: false,
      };

    case actionTypes.DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        deleteProjectLoading: false,
        deleteProjectSuccess: true,
      };

    case actionTypes.DELETE_PROJECT_FAILED:
      return {
        ...state,
        deleteProjectLoading: false,
        projectErrorMessage: action.payload,
      };

    case actionTypes.SET_PROJECT_ERROR_MESSAGE:
      return {
        ...state,
        projectErrorMessage: action.payload,
      };

    default:
      return state;
  }
};
