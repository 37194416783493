export const FETCH_ALERTS = "FETCH_ALERTS";
export const FETCH_ALERTS_SUCCESS = "FETCH_ALERTS_SUCCESS";
export const FETCH_ALERTS_FAILED = "FETCH_ALERTS_FAILED";

export const CREATE_ALERT = "CREATE_ALERT";
export const CREATE_ALERT_SUCCESS = "CREATE_ALERT_SUCCESS";
export const CREATE_ALERT_FAILED = "CREATE_ALERT_FAILED";

export const DELETE_ALERT = "DELETE_ALERT";
export const DELETE_ALERT_SUCCESS = "DELETE_ALERT_SUCCESS";
export const DELETE_ALERT_FAILED = "DELETE_ALERT_FAILED";

export const UPDATE_ALERT = "UPDATE_ALERT";
export const UPDATE_ALERT_SUCCESS = "UPDATE_ALERT_SUCCESS";
export const UPDATE_ALERT_FAILED = "UPDATE_ALERT_FAILED";

export const SET_ERROR_MESSAGE = "SET_ERROR_MESSAGE";

export const FETCH_ALERTS_LOGS = "FETCH_ALERTS_LOGS";
export const FETCH_ALERTS_LOGS_SUCCESS = "FETCH_ALERTS_LOGS_SUCCESS";
export const FETCH_ALERTS_LOGS_FAILED = "FETCH_ALERTS_LOGS_FAILED";

export const DOWNLOAD_ALERTS_LOGS = "DOWNLOAD_ALERTS_LOGS";
export const DOWNLOAD_ALERTS_LOGS_SUCCESS = "DOWNLOAD_ALERTS_LOGS_SUCCESS";
export const DOWNLOAD_ALERTS_LOGS_FAILED = "DOWNLOAD_ALERTS_LOGS_FAILED";
