import React from "react";

export const Settings = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        d="M10.2,12.7c-1.5,0-2.8-1.2-2.8-2.8c0-1.5,1.2-2.8,2.8-2.8s2.8,1.2,2.8,2.8C12.9,11.5,11.7,12.7,10.2,12.7z
            M10.2,8.7c-0.7,0-1.2,0.6-1.2,1.2s0.6,1.3,1.2,1.3s1.2-0.6,1.2-1.3S10.8,8.7,10.2,8.7z"
      />
      <path
        d="M10.2,18.1c-0.6,0-1.1-0.2-1.5-0.6S8.1,16.5,8.1,16c0-0.1,0-0.2-0.1-0.2c0-0.1-0.1-0.1-0.2-0.1
        c-0.1,0-0.2-0.1-0.2,0c-0.1,0-0.1,0-0.2,0.1C7.2,15.9,7,16,6.8,16.2c-0.5,0.2-1.1,0.2-1.6,0c-0.3-0.1-0.5-0.3-0.7-0.5
        C4.3,15.5,4.1,15.3,4,15c-0.1-0.3-0.2-0.5-0.2-0.8s0.1-0.5,0.2-0.8c0.1-0.3,0.3-0.5,0.5-0.7c0.1-0.1,0.1-0.1,0.1-0.2
        c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1,0-0.1-0.1-0.2-0.1H4.2c-0.6,0-1.1-0.2-1.5-0.6S2.1,10.6,2.1,10
        c0-0.6,0.2-1.1,0.6-1.5C3.1,8.2,3.6,8,4.2,8c0.1,0,0.2,0,0.2-0.1c0.1,0,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0-0.2c0-0.1,0-0.1-0.1-0.2
        C4.2,7.1,4.1,6.8,4,6.6C3.9,6.3,3.8,6.1,3.8,5.8C3.8,5.5,3.9,5.2,4,5c0.1-0.3,0.3-0.5,0.5-0.7C4.6,4.1,4.9,4,5.1,3.9
        c0.5-0.2,1.1-0.2,1.6,0C7,4,7.2,4.1,7.4,4.3c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0C8,4.3,8,4.3,8,4.3
        c0-0.1,0.1-0.1,0.1-0.2V4c0-0.6,0.2-1.1,0.6-1.5c0.8-0.8,2.2-0.8,2.9,0C12,2.9,12.2,3.4,12.2,4c0,0.1,0,0.2,0.1,0.2
        c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1c0.2-0.2,0.5-0.4,0.7-0.5c0.5-0.2,1.1-0.2,1.6,0
        c0.3,0.1,0.5,0.3,0.7,0.5c0.2,0.2,0.3,0.4,0.5,0.7c0.1,0.3,0.2,0.5,0.2,0.8c0,0.3-0.1,0.5-0.2,0.8C16.2,6.8,16,7,15.8,7.2
        c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1h0.1
        c0.5,0,1.1,0.2,1.5,0.6c0.4,0.4,0.6,0.9,0.6,1.5c0,0.6-0.2,1.1-0.6,1.5c-0.4,0.4-0.9,0.6-1.5,0.6c-0.1,0-0.2,0-0.3,0.1
        c-0.1,0-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.2c0.2,0.2,0.4,0.5,0.5,0.7c0.1,0.3,0.2,0.5,0.2,0.8
        s-0.1,0.5-0.2,0.8c-0.1,0.3-0.3,0.5-0.5,0.7c-0.2,0.2-0.4,0.3-0.7,0.5c-0.5,0.2-1.1,0.2-1.6,0c-0.3-0.1-0.5-0.3-0.7-0.5
        c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.2,0.1c0,0.1-0.1,0.1-0.1,0.2V16c0,0.6-0.2,1.1-0.6,1.5
        S10.8,18.1,10.2,18.1z M7.7,14.1c0.3,0,0.5,0.1,0.7,0.2c0.3,0.1,0.6,0.3,0.8,0.6c0.2,0.3,0.3,0.7,0.3,1c0,0.2,0.1,0.4,0.2,0.5
        c0.2,0.2,0.6,0.2,0.8,0c0.1-0.1,0.2-0.3,0.2-0.4v-0.1c0-0.4,0.1-0.7,0.3-1c0.2-0.3,0.5-0.5,0.8-0.7C12.2,14,12.6,14,13,14
        c0.4,0.1,0.7,0.2,1,0.5c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.1,0.3,0.1,0.4,0c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.1-0.1,0.1-0.2
        c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.3-0.3-0.5-0.6-0.5-1c-0.1-0.4,0-0.7,0.1-1.1
        c0.1-0.3,0.4-0.6,0.7-0.8c0.3-0.2,0.7-0.3,1-0.3c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.4c0-0.2-0.1-0.3-0.2-0.4
        c-0.1-0.1-0.3-0.2-0.4-0.2H16c-0.4,0-0.7-0.1-1-0.3c-0.3-0.2-0.5-0.5-0.7-0.8c0-0.1,0-0.1-0.1-0.2c-0.1-0.3-0.1-0.6-0.1-0.9
        c0.1-0.4,0.2-0.7,0.5-1C14.8,6.1,14.9,6,14.9,6c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0-0.1-0.1-0.1-0.1-0.2
        c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1-0.1-0.3-0.1-0.4,0c-0.1,0-0.1,0.1-0.2,0.1c-0.3,0.3-0.6,0.5-1,0.5c-0.4,0.1-0.7,0-1.1-0.1
        c-0.3-0.1-0.6-0.4-0.8-0.7c-0.2-0.3-0.3-0.7-0.3-1c0-0.2-0.1-0.4-0.2-0.5c-0.2-0.2-0.6-0.2-0.8,0C9.6,3.7,9.6,3.8,9.6,4v0.1
        c0,0.4-0.1,0.7-0.3,1C9.1,5.4,8.8,5.6,8.5,5.8c-0.1,0-0.1,0-0.2,0.1C8,5.9,7.6,6,7.3,5.9C7,5.8,6.6,5.7,6.4,5.4
        C6.3,5.3,6.2,5.3,6.1,5.2C6,5.2,5.8,5.2,5.7,5.2c-0.1,0-0.1,0.1-0.2,0.1C5.4,5.4,5.4,5.5,5.4,5.6c0,0.1,0,0.1,0,0.2
        c0,0.1,0,0.2,0,0.2c0,0.1,0.1,0.1,0.1,0.2C5.8,6.5,6,6.8,6,7.2c0.1,0.4,0,0.7-0.1,1.1C5.8,8.6,5.6,8.9,5.3,9.1
        C5,9.3,4.6,9.4,4.2,9.5C4,9.5,3.9,9.5,3.7,9.6S3.6,9.9,3.6,10s0.1,0.3,0.2,0.4s0.3,0.2,0.4,0.2h0.1c0.4,0,0.7,0.1,1,0.3
        c0.3,0.2,0.5,0.5,0.7,0.8c0.1,0.3,0.2,0.7,0.1,1.1c-0.1,0.4-0.2,0.7-0.5,1c-0.1,0.1-0.1,0.2-0.2,0.2c0,0.1,0,0.1,0,0.2
        c0,0.1,0,0.2,0,0.2c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0.1,0.3,0.1,0.4,0c0.1,0,0.1-0.1,0.2-0.1
        c0.3-0.3,0.6-0.5,1-0.5C7.5,14.1,7.6,14.1,7.7,14.1z M15.1,12L15.1,12L15.1,12z"
      />
    </svg>
  );
};
