//* BREAK Start*/
export const ACTIVITY_REPORT_INITIAL = "ACTIVITY_REPORT_INITIAL";
export const ACTIVITY_REPORT_PROCESS = "ACTIVITY_REPORT_PROCESS";
export const ACTIVITY_REPORT_SUCCESS = "ACTIVITY_REPORT_SUCCESS";
export const ACTIVITY_REPORT_FAILED = "ACTIVITY_REPORT_FAILED";

//* BREAK End*/

// ACTIVITY REPORT DOWNLOAD

export const ACTIVITY_REPORT_DOWNLOAD_INITIAL =
  "ACTIVITY_REPORT_DOWNLOAD_INITIAL";
export const ACTIVITY_REPORT_DOWNLOAD_PROCESS =
  "ACTIVITY_REPORT_DOWNLOAD_PROCESS";
export const ACTIVITY_REPORT_DOWNLOAD_SUCCESS =
  "ACTIVITY_REPORT_DOWNLOAD_SUCCESS";
export const ACTIVITY_REPORT_DOWNLOAD_FAILED =
  "ACTIVITY_REPORT_DOWNLOAD_FAILED";
