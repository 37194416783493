import { put } from "redux-saga/effects";
import { AttendanceDetailedAction } from "../action";
import { Client } from "../../../utils/transport";
import { ExportCSV } from "utils/export-csv";
import { hms, hmsOnlyNumber } from "utils/timeConverter";
import dayjs from "dayjs";
import offlineValue from "utils/getOfflineTime";

// ATTENDANCE SUMMARY ATTENDANCE TRENDS

const ExportData = (fileName, data, colunms, filterDate, teams) => {
  let result = [];
  const generatedDate =
    "Generated Date:-" + dayjs().format("DD-MM-YYYY hh:mm a");

  const generatedData = [
    fileName,
    "Teams: - " + teams.join(",").replaceAll(",", "/"),
    "Date:-" + filterDate,
    generatedDate,
    colunms.join(),
  ];

  data?.forEach((record) => {
    const offlinetime = offlineValue(
      record?.punch_duration,
      record?.online_duration,
      record?.break_duration
    );

    const addColunmsValues = [
      !record?.first_name && !record?.last_name
        ? "--"
        : record?.first_name + " " + record?.last_name,
      record?.team_name,
      record?.employee_id,
      !record.attendance ? 0 : record.attendance,
      hmsOnlyNumber(record?.punch_duration),
      hmsOnlyNumber(record?.online_duration),
      // hms(offlinetime),
      hmsOnlyNumber(record?.break_duration),
    ];
    generatedData.push(addColunmsValues.join(","));
  });
  result = generatedData;
  return result;
};

const getDownloadCSV = (payload, data) => {
  const teamIds = payload.bodyData.group_id.split(",");
  const selectTeamNames = payload.teamsList
    .filter((item) => teamIds.includes(item.id))
    .map((item) => item.name);

  const csvData = data?.data;
  const date =
    dayjs(payload?.bodyData?.start_date).format("YYYY-MM-DD") +
    " To " +
    dayjs(payload?.bodyData?.end_date).format("YYYY-MM-DD");
  const colunms = [
    "Employee",
    "Team",
    "Employee ID",
    "Attendance",
    "Working time (hh:mm:ss)",
    "Online time (hh:mm:ss)",
    "Break time",
  ];
  const exdata = ExportData(
    "Attendance Employee List",
    csvData,
    colunms,
    date,
    selectTeamNames
  );
  ExportCSV("Attendance_Employee_List_", exdata);
};

export function* GetAttendanceDetailedAttendanceTrend(data) {
  const config = {
    method: "POST",
    url: "query/reports/monthly_inout_attendance",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    fetchPolicy: "no-cache",
    data: data.bodyData,
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        AttendanceDetailedAction.AttendanceDetailedAttendanceTrendSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        AttendanceDetailedAction.AttendanceDetailedAttendanceTrendFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      AttendanceDetailedAction.AttendanceDetailedAttendanceTrendFailed(err)
    );
  }
}

// ATTENDANCE DETAILED EMPLOYEE LIST

export function* GetAttendanceDetailedEmployeeList(data) {
  const config = {
    method: "POST",
    url: "query/attendance/details",

    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        AttendanceDetailedAction.AttendanceDetailedEmployeeListSuccess(
          response.data
        )
      );
    } else {
      yield put(
        AttendanceDetailedAction.AttendanceDetailedEmployeeListFailed(response)
      );
    }
  } catch (err) {
    yield put(
      AttendanceDetailedAction.AttendanceDetailedEmployeeListFailed(err)
    );
  }
}

export function* GetAttendanceDetailedEmployeePunchLogList(data) {
  const config = {
    method: "POST",
    url: "query/attendance/logs",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        AttendanceDetailedAction.AttendanceDetailedEmployeePunchLogListSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        AttendanceDetailedAction.AttendanceDetailedEmployeePunchLogListFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      AttendanceDetailedAction.AttendanceDetailedEmployeePunchLogListFailed(err)
    );
  }
}

// ATTENDANCE DETAILED ATTENDANCE TRENDS GRAPH

export function* GetAttendanceDetailedAttendanceTrendGraph(data) {
  const config = {
    method: "POST",
    url: "query/attendance/summary",

    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        AttendanceDetailedAction.AttendanceDetailedAttendanceTrendGraphSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        AttendanceDetailedAction.AttendanceDetailedAttendanceTrendGraphFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      AttendanceDetailedAction.AttendanceDetailedAttendanceTrendGraphFailed(err)
    );
  }
}

export function* GetAttendanceDatewiseAttendancelist(data) {
  const config = {
    method: "POST",
    url: "query/attendance/datewise_attendance",

    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        AttendanceDetailedAction.AttendanceDatewiseAttendanceListSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        AttendanceDetailedAction.AttendanceDatewiseAttendanceListFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      AttendanceDetailedAction.AttendanceDatewiseAttendanceListFailed(err)
    );
  }
}

export function* GetDownloadAttendanceEmployeeList(payload) {
  const config = {
    method: "POST",
    url: "query/attendance/details",

    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + payload.token,
    },
    data: payload.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      getDownloadCSV(payload, response.data);
      yield put(
        AttendanceDetailedAction.AttendanceEmpListDownloadSuccess(response.data)
      );
    } else {
      yield put(
        AttendanceDetailedAction.AttendanceEmpListDownloadFailed(response)
      );
    }
  } catch (err) {
    yield put(AttendanceDetailedAction.AttendanceEmpListDownloadFailed(err));
  }
}
