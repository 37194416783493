import React from "react";

export const Deviceinfo = ({ viewBox = "0 0 20 20" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox={viewBox}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill="currentColor"
        d="M22.8,13.8C22.8,13.8,22.8,13.8,22.8,13.8C22.8,13.8,22.7,13.8,22.8,13.8c-0.1-0.1-0.1-0.2-0.2-0.2c-0.5-0.5-1-0.9-1.7-1.3
		c-0.1,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0-2.8,0-5.6,0-8.4c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1c-0.3,0-0.6,0-0.8,0c0,0-0.1,0-0.1,0
		c-5.5,0-11,0-16.5,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3c0,3.9,0,7.7,0,11.6c0,0.1,0,0.1,0,0.2
		c0,0.1,0,0.1,0.1,0.1c1.9,0,3.9,0,5.8,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0.1,0.1,0.1c0,0.6,0,1.2,0,1.8c0,0.1,0,0.1-0.1,0.1
		c0,0,0,0-0.1,0c-1.8,0-3.6,0-5.4,0c-0.3,0-0.5,0-0.8-0.1c-0.1,0-0.2-0.1-0.4-0.1C2.2,17.5,2,17.3,1.7,17c-0.1-0.2-0.3-0.3-0.4-0.5
		c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0-0.1c0-0.7,0-1.4,0-2.2c0-3.2,0-6.5,0-9.7
		c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0.1-0.1,0-0.1,0.1-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.2,0.2-0.4
		c0-0.1,0.1-0.1,0.1-0.2C1.7,2.2,1.9,2,2.1,1.9c0.2-0.2,0.5-0.3,0.7-0.3c0.1,0,0.2,0,0.2-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0
		c5.8,0,11.6,0,17.3,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1c0.3,0.1,0.6,0.2,0.8,0.4c0.2,0.2,0.4,0.3,0.5,0.5
		c0.2,0.3,0.3,0.6,0.4,0.9c0.1,0.2,0.1,0.4,0.1,0.6c0,2.8,0,5.6,0,8.4c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.2c0,0.3,0,0.7,0,1
		c0,0,0,0.1,0,0.1C22.9,13.9,22.9,13.9,22.8,13.8z"
      />
      <path
        fill="currentColor"
        d="M10.7,8.6c0-0.1,0.1-0.3,0.1-0.4c0.2-0.4,0.4-0.7,0.7-0.9C11.8,7.2,12,7.1,12.3,7c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3-0.1
		c0,0,0.1,0,0.1,0c1.4,0,2.9,0,4.3,0c0,0,0.1,0,0.1,0c0.2,0,0.3,0.1,0.5,0.1c0,0,0.1,0,0.1,0.1c0.1,0,0.2,0.1,0.3,0.1
		c0.2,0.1,0.4,0.2,0.6,0.4c0.1,0,0.1,0.1,0.2,0.1c0.3,0.3,0.4,0.6,0.5,0.9c0,0.1,0.1,0.2,0.1,0.3l0,0c0,0,0,0.1,0,0.1
		c0,0.9,0,1.8,0,2.7c0,0,0,0,0,0.1c0,0.1,0,0.1-0.1,0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.4,0-0.6-0.1c-0.1,0-0.2,0-0.3,0
		c-0.1,0-0.1,0-0.1-0.1c0,0,0-0.1,0-0.1c0-0.8,0-1.6,0-2.4c0-0.1,0-0.3-0.1-0.4c-0.1-0.2-0.2-0.3-0.4-0.4c0,0-0.1,0-0.1,0
		c0,0,0,0,0,0c-0.3,0-0.6,0-0.9,0c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1-0.3,0.2-0.3,0.4c0,0,0,0.1-0.1,0.1c-0.7,0-1.3,0-2,0
		c0,0-0.1,0-0.1-0.1c0-0.1,0-0.2-0.1-0.2c-0.1-0.2-0.3-0.3-0.4-0.4c0,0,0,0,0,0c-0.3,0-0.6,0-0.9,0c-0.1,0-0.1,0.1-0.2,0.1
		c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.1,0,0.1-0.1,0.2c0,0,0,0.1,0,0.1c0,3.7,0,7.3,0,11c0,0,0,0,0,0.1c0,0.1,0.1,0.1,0.1,0.2
		c0.1,0.1,0.2,0.2,0.3,0.3c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0.1c0.2,0.3,0.4,0.7,0.6,1c0.1,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0
		c0,0,0,0,0,0c0,0.1,0,0.1-0.1,0.1c-0.2,0-0.4,0-0.6,0c-0.1,0-0.1,0-0.1,0l0,0c-0.1,0-0.2,0-0.3-0.1c-0.1,0-0.2-0.1-0.3-0.1
		c-0.4-0.2-0.8-0.5-1.1-0.9c-0.1-0.2-0.2-0.4-0.3-0.6c0,0,0-0.1,0-0.1c0-0.1,0-0.2-0.1-0.3c0,0,0,0,0-0.1c0-3.6,0-7.3,0-10.9
		C10.6,8.8,10.7,8.7,10.7,8.6z"
      />
      <path
        fill="currentColor"
        d="M6.5,21.8C6.5,21.8,6.5,21.8,6.5,21.8c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1
		c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0.1-0.1c0,0,0,0,0.1,0c0.8,0,1.6,0,2.4,0c0.1,0,0.1,0,0.1-0.1l0,0c0-0.1,0-0.2,0-0.3
		c0,0,0-0.1,0-0.1c0,0,0,0,0,0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0.1,0.3c0.1,0.3,0.2,0.6,0.4,0.9c0,0.1,0.1,0.1,0.1,0.2
		c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1-0.1,0.1c0,0,0,0,0,0c-1.1,0-2.1,0-3.2,0C6.6,21.9,6.6,21.9,6.5,21.8C6.5,21.9,6.5,21.8,6.5,21.8z
		"
      />
      <path
        fill="currentColor"
        d="M22.9,17.4C22.9,17.4,22.9,17.4,22.9,17.4c0-0.2-0.1-0.3,0-0.4c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.2-0.1-0.2
		c0-0.2-0.1-0.3-0.1-0.4c0,0,0-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.4c-0.2-0.3-0.4-0.6-0.6-0.9c-0.4-0.5-1-0.9-1.6-1.2
		c-0.1-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.1,0-0.1-0.1c-0.1-0.1-0.1,0-0.2-0.1c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.1,0-0.2,0
		c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c-0.2,0-0.4,0-0.6,0c-0.2,0-0.4,0-0.6,0.1c0,0,0,0,0,0
		c-0.1,0-0.2,0-0.4,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1-0.2,0.1-0.2,0.1
		c-0.4,0.2-0.8,0.5-1.1,0.8c-0.1,0.1-0.2,0.2-0.4,0.4c-0.4,0.4-0.6,0.8-0.9,1.3c0,0.1,0,0.1-0.1,0.2v0c0,0.1,0,0.1-0.1,0.2
		c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0.1c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3l0,0c0,0,0,0,0,0
		c0,0.3,0,0.6,0,0.9c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0.1,0.3c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0.1,0.2c0,0,0,0.1,0,0.1
		c0.1,0.1,0.1,0.3,0.2,0.4c0.2,0.3,0.4,0.7,0.6,1c0.3,0.3,0.6,0.7,1,0.9c0.3,0.2,0.6,0.4,0.9,0.5c0.1,0,0.1,0.1,0.2,0.1
		c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.4,0.1,0.5,0.1c0.2,0,0.5,0,0.7,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
		c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3-0.1c0,0,0,0,0,0c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0,0,0c0.1,0,0.2,0,0.2-0.1c0.1,0,0.2-0.1,0.3-0.1
		c0.4-0.2,0.8-0.4,1.1-0.7c0.1-0.1,0.2-0.2,0.4-0.4c0.2-0.3,0.4-0.5,0.6-0.8c0.1-0.2,0.2-0.4,0.3-0.6c0-0.1,0.1-0.1,0.1-0.2
		c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0.1-0.2c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.4c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0.1-0.3
		c0,0,0,0,0,0C22.9,17.9,22.9,17.7,22.9,17.4z M17.5,14.9c0-0.1,0.1-0.3,0.2-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.2,0
		c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0,0.2,0,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c0,0-0.1,0.1-0.1,0.1
		c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.3-0.2-0.4-0.4C17.4,15.1,17.4,15,17.5,14.9z
		 M18.8,18.1c0,0.2,0,0.4,0,0.5c0,0.8,0,1.6,0,2.5c0,0,0,0,0,0.1c0,0-0.1,0-0.1,0c-0.4,0-0.7,0-1.1,0c-0.1,0-0.1,0-0.1-0.1
		c0-1.3,0-2.5,0-3.8c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0c0,0,0,0,0,0
		c0-0.1,0.1,0,0.1,0c0.5,0,1.1,0,1.6,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0.2C18.8,17.1,18.8,17.6,18.8,18.1
		C18.8,18.1,18.8,18.1,18.8,18.1z"
      />
    </svg>
  );
};
