import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "../index.scss";
import { InfoCircleFilled } from "@ant-design/icons";
import Chart from "./../../chart";
import { useSelector } from "react-redux";
import { Skeleton, Typography, Tooltip, Empty, message } from "antd";
import { Attendance } from "components/icons/attendance";
import { getDonutChartOptions } from "utils/apexChartsUtil.tsx";

const { Text, Link, Title } = Typography;

const Cardskeleton = (props) => {
  const { loading = false } = props;
  return (
    <>
      <div className="charts">
        <div className="chart_header">
          <Skeleton.Input
            size={"small"}
            shape={"default"}
            style={{ height: "15px" }}
          />
          <Skeleton.Avatar
            size={"small"}
            shape={"circle"}
            style={{ height: "15px", width: "15px" }}
          />
        </div>
        <div className="chartContainer chart_tool" id="chart">
          {/* chart */}
          <Skeleton.Avatar
            size={"large"}
            shape={"default"}
            style={{ height: "300px", width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

// const appusages = {
//   series: [144, 55],
// };

const AttendanceChart = (props) => {
  const attendancedetailed = useSelector((state) => state.attendancedetailed);

  const [TodayAttendance, setTodayAttendance] = useState();
  useEffect(() => {
    if (
      attendancedetailed.attendance_detailed_attendance_trend_success === true
    ) {
      if (
        _.size(attendancedetailed.attendance_detailed_attendance_trend_data) > 0
      ) {
        if (
          _.size(
            attendancedetailed.attendance_detailed_attendance_trend_data[0].logs
          ) > 0
        ) {
          let filterPresent = 0;
          _.filter(
            attendancedetailed.attendance_detailed_attendance_trend_data[0]
              .logs,
            function (c) {
              if (c.att_status === "P") {
                ++filterPresent;
              }
            }
          );
          let filterAbsent = 0;
          _.filter(
            attendancedetailed.attendance_detailed_attendance_trend_data[0]
              .logs,
            function (c) {
              if (c.att_status === "A") {
                ++filterAbsent;
              }
            }
          );

          //if(filterPresent.length>0&&filterAbsent.length>0){
          const appusages = {
            series: [
              // 12,50
              filterPresent,
              filterAbsent,
            ],
          };
          setTodayAttendance(appusages);

          // }
        }
      }
    }
  }, [attendancedetailed.attendance_detailed_attendance_trend_success]);

  const chartOptions = getDonutChartOptions({
    options: {
      labels: ["Present", "Absent"],
      colors: ["#7B61FF", "#CFD8DC"],
    },
    type: "NUMBER",
  });

  const [emptydata, setEmptydata] = useState(false);

  const [loading, setLoading] = useState(true);
  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const { title, tooltip } = props;

  return (
    <>
      {attendancedetailed.attendance_detailed_attendance_trend_processing ===
      true ? (
        <Cardskeleton />
      ) : (
        <div className="charts ">
          <div className="chart_header">
            <Title level={2}>{title}</Title>
            <Tooltip title={tooltip}>
              <InfoCircleFilled />
            </Tooltip>
          </div>
          <div className="chartContainer chart_tool" id="chart">
            {/* chart */}
            {TodayAttendance && TodayAttendance != undefined ? (
              <Chart
                options={chartOptions.options}
                series={TodayAttendance && TodayAttendance.series}
                type="donut"
                height={300}
              />
            ) : (
              <div style={{ height: "271px" }} className="emptyblock">
                <Empty description={false} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

AttendanceChart.propTypes = {
  getList: PropTypes.func,
};

export default AttendanceChart;
