import * as actionTypes from "./actionTypes";

const initialState = {
  activity_report_processing: false,
  activity_report_success: false,
  activity_report_failed: false,
  activity_report_data: [],
  activity_report_message: "",
  breakReportMaster: [],

  activity_report_download_processing: false,
  activity_report_download_success: false,
  activity_report_download_failed: false,
  activity_report_download_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.ACTIVITY_REPORT_INITIAL:
      return {
        ...state,
        activity_report_processing: false,
        activity_report_success: false,
        activity_report_failed: false,
        activity_report_data: [],
        activity_report_message: "",
        breakReportMaster: [],
      };
    case actionTypes.ACTIVITY_REPORT_PROCESS:
      return {
        ...state,
        activity_report_processing: true,
        activity_report_success: false,
        activity_report_failed: false,
        activity_report_data: [],
        activity_report_message: "",
        breakReportMaster: [],
      };
    case actionTypes.ACTIVITY_REPORT_SUCCESS:
      return {
        ...state,
        activity_report_processing: false,
        activity_report_success: true,
        activity_report_failed: false,
        activity_report_data: action.data,
        activity_report_message: "",
        breakReportMaster: action.data,
      };
    case actionTypes.ACTIVITY_REPORT_FAILED:
      return {
        ...state,
        activity_report_processing: false,
        activity_report_success: false,
        activity_report_failed: true,
        activity_report_message: action.data,
        activity_report_data: [],
        breakReportMaster: [],
      };
    case actionTypes.ACTIVITY_REPORT_DOWNLOAD_INITIAL:
      return {
        ...state,
        activity_report_download_processing: false,
        activity_report_download_success: false,
        activity_report_download_failed: false,
        activity_report_download_message: "",
      };
    case actionTypes.ACTIVITY_REPORT_DOWNLOAD_PROCESS:
      return {
        ...state,
        activity_report_download_processing: true,
        activity_report_download_success: false,
        activity_report_download_failed: false,
        activity_report_download_message: "",
      };
    case actionTypes.ACTIVITY_REPORT_DOWNLOAD_SUCCESS:
      return {
        ...state,
        activity_report_download_processing: false,
        activity_report_download_success: true,
        activity_report_download_failed: false,
        activity_report_download_message: "",
      };

    case actionTypes.ACTIVITY_REPORT_DOWNLOAD_FAILED:
      return {
        ...state,
        activity_report_download_processing: false,
        activity_report_download_success: false,
        activity_report_download_failed: true,
        activity_report_download_message: "",
      };
    default:
      return state;
  }
};
