export const GENERATE_STEALTH_KEY = "GENERATE_STEALTH_KEY";

export const ROLE_CREATE_INITIAL = "ROLE_CREATE_INITIAL";
export const ROLE_CREATE_PROCESS = "ROLE_CREATE_PROCESS";
export const ROLE_CREATE_SUCCESS = "ROLE_CREATE_SUCCESS";
export const ROLE_CREATE_FAILED = "ROLE_CREATE_FAILED";

export const ROLE_LIST_INITIAL = "ROLE_LIST_INITIAL";
export const ROLE_LIST_PROCESS = "ROLE_LIST_PROCESS";
export const ROLE_LIST_SUCCESS = "ROLE_LIST_SUCCESS";
export const ROLE_LIST_FAILED = "ROLE_LIST_FAILED";
export const ROLE_LIST_SEARCH = "ROLE_LIST_SEARCH";

export const ROLE_UPDATE_INITIAL = "ROLE_UPDATE_INITIAL";
export const ROLE_UPDATE_PROCESS = "ROLE_UPDATE_PROCESS";
export const ROLE_UPDATE_SUCCESS = "ROLE_UPDATE_SUCCESS";
export const ROLE_UPDATE_FAILED = "ROLE_UPDATE_FAILED";

export const USER_ROLE_SEARCH_LIST_INITIAL = "USER_ROLE_SEARCH_LIST_INITIAL";
export const USER_ROLE_SEARCH_LIST_PROCESS = "USER_ROLE_SEARCH_LIST_PROCESS";
export const USER_ROLE_SEARCH_LIST_SUCCESS = "USER_ROLE_SEARCH_LIST_SUCCESS";
export const USER_ROLE_SEARCH_LIST_FAILED = "USER_ROLE_SEARCH_LIST_FAILED";
