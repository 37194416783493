import { Button, Progress } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeProgressBar } from "redux/store/download/action";
import { InfoCircleOutlined } from "@ant-design/icons";

const AppDownloadProgress = () => {
  const dispatch = useDispatch();

  // @ts-ignore
  const { progressInfo, ajaxRequest } = useSelector((state) => state.download);

  const onCancel = () => {
    if (ajaxRequest) {
      ajaxRequest.cancel();
    }

    dispatch(closeProgressBar());
  };

  if (progressInfo) {
    return (
      <div
        className="fixed right-8 bottom-8 rounded-lg z-[9999] bg-white px-6 py-4 border-teal-500 w-96"
        style={{
          boxShadow:
            "0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05)",
        }}
      >
        <div className="flex items-center gap-4 mb-4">
          <InfoCircleOutlined className="text-blue-700" />{" "}
          <div className=" text-lg">Downloading Desktop Stealth App</div>{" "}
        </div>
        <Progress
          className="w-9/12 mb-4"
          percent={(progressInfo.loaded / progressInfo.total) * 100}
          status="active"
          format={() =>
            `${(progressInfo.loaded / (1024 * 1024)).toFixed(1)}MB/${(
              progressInfo.total /
              (1024 * 1024)
            ).toFixed(0)}MB `
          }
        />
        <div className="flex justify-end w-full">
          <Button type="default" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      </div>
    );
  }

  return null;
};

export default AppDownloadProgress;
