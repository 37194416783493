import { put } from "redux-saga/effects";
import { MappingAction } from "../action";
import { Client } from "../../../utils/transport";
import { ExportCSV } from "utils/export-csv";
import dayjs from "dayjs";
import { message } from "antd";
import { SOMETHING_WENT_WRONG } from "constants/staticText";

const ExportData = (fileName, payload, data, colunms) => {
  let result = [];
  let applyFilter = [];
  const searchFilter =
    !payload?.data?.body?.ilike["value"] === true
      ? null
      : payload?.data?.body?.ilike?.value;

  if (searchFilter) {
    applyFilter.push(`Search : ${searchFilter}`);
  }

  const typeFilter =
    !payload?.data?.body?.ilike["type"] === true
      ? null
      : payload?.data?.body?.ilike?.type;
  if (typeFilter) {
    applyFilter.push(`Type : ${typeFilter}`);
  }
  const mappedFilter =
    !payload?.data?.body?.isNotNull === true ? null : "Mapped";
  if (mappedFilter) {
    applyFilter.push(`Category : ${mappedFilter}`);
  }
  const unmappedFilter =
    !payload?.data?.body?.isNull === true ? null : "Unmapped";

  if (unmappedFilter) {
    applyFilter.push(`Category : ${unmappedFilter}`);
  }

  if (!mappedFilter && !unmappedFilter) {
    applyFilter.push(`Category : All`);
  }

  const generatedDate =
    "Generated Date:-" + dayjs().format("DD-MM-YYYY hh:mm a");

  const generatedData = [
    fileName,
    generatedDate,
    `Filter :- ${applyFilter?.join(" / ")}`,
    colunms.join(),
  ];

  data?.forEach((record) => {
    const addColunmsValues = [
      record?.type === "APPLICATION" ? "APP" : record?.type,
      record?.value,
      !record?.application_category ? "--" : record?.application_category?.name,
    ];

    generatedData.push(addColunmsValues.join(","));
  });
  result = generatedData;
  return result;
};

const getDownloadCSV = (payload, data) => {
  const csvData = data?.data;
  const colunms = ["Type", "App/URL Name", "Category"];
  const exdata = ExportData(
    "Productivity Mapping List",
    payload,
    csvData,
    colunms
  );
  ExportCSV("Productivity_Mapping_List_", exdata);
  message.success("Productivity mapping download successfully.");
};
//LIST APPLICATIONURL

export function* GetApplicationUrlList(data) {
  let config = {
    method: "POST",
    url: `api/v1/app_identifiers/search?page=${data?.data?.param?.page}&size=${data?.data?.param?.size}&sortBy=${data?.data?.param?.sortBy}&direction=${data?.data?.param?.direction}`,
    headers: data.data.headers,
    data: data?.data?.body,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(MappingAction.AppilcationUrlListSuccess(response?.data));
    } else {
      yield put(MappingAction.AppilcationUrlListFailed(response));
    }
  } catch (err) {
    yield put(MappingAction.AppilcationUrlListFailed(err));
  }
}

//LIST AppilcationPolicyMapping

export function* GetApplicationPolicyMappingList(data) {
  let config = {
    method: "GET",
    url: `api/v1/application_policies/${data.data.policyId}/mappings`,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        MappingAction.AppilcationPolicyMappingListSuccess(response.data)
      );
    } else {
      yield put(MappingAction.AppilcationPolicyMappingListFailed(response));
    }
  } catch (err) {
    yield put(MappingAction.AppilcationPolicyMappingListFailed(err));
  }
}

//LIST APPLICATION CAREGORY

export function* GetApplicationCategoryList(data) {
  let config = {
    method: "GET",
    url: "APPLIATION_CATEGORY_PHASE_1",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(MappingAction.AppilcationCategoryListSuccess(response.data));
    } else {
      yield put(MappingAction.AppilcationCategoryListFailed(response));
    }
  } catch (err) {
    yield put(MappingAction.AppilcationCategoryListFailed(err));
  }
}

export function* EditApplicationUrl(data) {
  const config = {
    method: "PUT",
    url: "api/v1/application_urls/" + data.data.body.id,
    headers: {
      ...data.data.headers,
    },
    data: data.data.body,
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);

    if (data.data.historicalUpdates > 0) {
      const now = new Date();

      const config = {
        method: "POST",
        url: `/api/v1/app_identifiers/${
          data.data.body.id
        }/refresh_clickhouse/?start_date=${
          new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() - data.data.historicalUpdates
          )
            .toISOString()
            .split("T")[0]
        }`,
        headers: {
          ...data.data.headers,
        },
        data: data.data.body,
        fetchPolicy: "no-cache",
      };

      yield Client.request(config);
    }

    yield put(MappingAction.ApplicationUrlEditSuccess(response.data));
  } catch (err) {
    console.log(err);
    let errorMessage = SOMETHING_WENT_WRONG;

    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(MappingAction.ApplicationUrlEditFailed(errorMessage));
  }
}

//ADD APPLICATION POLICY MAPPING
export function* CreateApplicationPolicyMapping(data) {
  let config = {
    method: "POST",
    url: "APPLIATION_POLICY_MAPPING_PHASE_1",
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.BodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        MappingAction.ApplicationPolicyMappingCreateSuccess(response.data)
      );
    } else {
      yield put(MappingAction.ApplicationPolicyMappingCreateFailed(response));
    }
  } catch (err) {
    yield put(MappingAction.ApplicationPolicyMappingCreateFailed(err));
  }
}

//UPDATE APPLICATION POLICY MAPPING

export function* EditApplicationPolicyMapping(data) {
  let config = {
    method: "PUT",
    url: "api/v1/application_policy_mappings/" + data.data.id,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
      "X-Tenant-Id": data.data.clientId,
    },
    data: data.data.BodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        MappingAction.ApplicationPolicyMappingEditSuccess(response.data)
      );
    } else {
      yield put(MappingAction.ApplicationPolicyMappingEditFailed(response));
    }
  } catch (err) {
    yield put(MappingAction.ApplicationPolicyMappingEditFailed(err));
  }
}

export function* searchApplicationUrlList(data) {
  let config = {
    method: "POST",
    url: "api/v1/app_identifiers/search?page=&size=&sortBy=id&direction=asc",
    data: data.data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(MappingAction.AppilcationUrlListSuccess(response.data));
    } else {
      yield put(MappingAction.AppilcationUrlListFailed(response));
    }
  } catch (err) {
    yield put(MappingAction.AppilcationUrlListFailed(err));
  }
}

// Application mapping Download
export function* GetApplicationUrlDownloadList(data) {
  let config = {
    method: "POST",
    url: `api/v1/app_identifiers/search?page=${data?.data?.param?.page}&size=${data?.data?.param?.size}&sortBy=${data?.data?.param?.sortBy}&direction=${data?.data?.param?.direction}`,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.data.token,
    },
    data: data?.data?.body,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      getDownloadCSV(data, response?.data);
      yield put(
        MappingAction.ApplicationMappingDownloadSuccess(response?.data)
      );
    } else {
      // message.error(response?.message);
      // yield put(MappingAction.ApplicationMappingDownloadFailed(response));
    }
  } catch (err) {
    // message.error(response?.message);
    //yield put(MappingAction.ApplicationMappingDownloadFailed(err));
  }
}
