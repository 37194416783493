// GET ACTIVITY

import * as actionTypes from "./actionTypes";

export const ActivityReportInitial = (token) => {
  return {
    type: actionTypes.ACTIVITY_REPORT_INITIAL,
    token,
  };
};

export const ActivityReportProcess = (token, bodyData) => {
  return {
    type: actionTypes.ACTIVITY_REPORT_PROCESS,
    token,
    bodyData,
  };
};
export const ActivityReportSuccess = (data) => {
  return {
    type: actionTypes.ACTIVITY_REPORT_SUCCESS,
    data,
  };
};
export const ActivityReportFailed = (data) => {
  return {
    type: actionTypes.ACTIVITY_REPORT_FAILED,
    data,
  };
};

export const ActivityReportDownloadInitial = () => {
  return {
    type: actionTypes.ACTIVITY_REPORT_DOWNLOAD_INITIAL,
  };
};

export const ActivityReportDownloadProcess = (token, bodyData, teamsList) => {
  return {
    type: actionTypes.ACTIVITY_REPORT_DOWNLOAD_PROCESS,
    token,
    bodyData,
    teamsList,
  };
};

export const ActivityReportDownloadSuccess = () => {
  return {
    type: actionTypes.ACTIVITY_REPORT_DOWNLOAD_SUCCESS,
  };
};

export const ActivityReportDownloadFailed = (err) => {
  return {
    type: actionTypes.ACTIVITY_REPORT_DOWNLOAD_FAILED,
    err,
  };
};
