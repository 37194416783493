import * as actionTypes from "./actionTypes";

const initialPagination = { totalCount: 0 };

const initialState = {
  fetchAlertsLoading: true,
  alertsData: {},
  pagination: initialPagination,
  alertLoading: false,
  alertSuccess: false,
  deleteAlertSuccess: false,
  deleteAlertLoading: false,
  errorMessage: "",
  alertLogsData: [],
  alertLogsSuccess: false,
  downloadalertLogs: false,
  downloadAlertLogsData: [],
  downloadalertLogsSuccess: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_ALERTS:
      return {
        ...state,
        fetchAlertsLoading: true,
        errorMessage: "",
      };

    case actionTypes.FETCH_ALERTS_SUCCESS:
      return {
        ...state,
        fetchAlertsLoading: false,
        alertsData: action.payload.data.reduce(
          (acc, alert) => ({ ...acc, [alert.id]: alert }),
          {}
        ),
        pagination: action.payload.meta || state.pagination,
      };

    case actionTypes.FETCH_ALERTS_FAILED:
      return {
        ...state,
        fetchAlertsLoading: false,
        alertsData: {},
        pagination: initialPagination,
        errorMessage: action.payload,
      };

    case actionTypes.CREATE_ALERT:
      return {
        ...state,
        alertLoading: true,
        errorMessage: "",
        alertSuccess: false,
      };

    case actionTypes.CREATE_ALERT_SUCCESS:
      return {
        ...state,
        alertLoading: false,
        alertsData: {
          ...state.alertsData,
          [action.payload.id]: action.payload,
        },
        pagination: {
          ...state.pagination,
          totalCount: state.pagination.totalCount + 1,
        },
        alertSuccess: true,
      };

    case actionTypes.CREATE_ALERT_FAILED:
      return {
        ...state,
        alertLoading: false,
        errorMessage: action.payload,
      };

    case actionTypes.UPDATE_ALERT:
      return {
        ...state,
        alertLoading: true,
        errorMessage: "",
        alertSuccess: false,
      };

    case actionTypes.UPDATE_ALERT_SUCCESS:
      return {
        ...state,
        alertLoading: false,
        alertsData: {
          ...state.alertsData,
          [action.payload.id]: action.payload,
        },
        alertSuccess: true,
      };

    case actionTypes.UPDATE_ALERT_FAILED:
      return {
        ...state,
        alertLoading: false,
        errorMessage: action.payload,
      };

    case actionTypes.DELETE_ALERT:
      return {
        ...state,
        deleteAlertLoading: true,
        errorMessage: "",
        deleteAlertSuccess: false,
      };

    case actionTypes.DELETE_ALERT_SUCCESS:
      return {
        ...state,
        deleteAlertLoading: false,
        deleteAlertSuccess: true,
      };

    case actionTypes.DELETE_ALERT_FAILED:
      return {
        ...state,
        deleteAlertLoading: false,
        errorMessage: action.payload,
      };

    case actionTypes.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      };

    case actionTypes.FETCH_ALERTS_LOGS:
      return {
        ...state,
        fetchAlertsLoading: true,
        errorMessage: "",
        alertLogsSuccess: false,
      };

    case actionTypes.FETCH_ALERTS_LOGS_SUCCESS:
      return {
        ...state,
        fetchAlertsLoading: false,
        alertLogsData: action.payload?.data,
        pagination: action.payload?.meta,
        alertLogsSuccess: true,
      };

    case actionTypes.FETCH_ALERTS_LOGS_FAILED:
      return {
        ...state,
        fetchAlertsLoading: false,
        alertLogsData: {},
        pagination: initialPagination,
        errorMessage: action.payload,
        alertLogsSuccess: false,
      };

    case actionTypes.DOWNLOAD_ALERTS_LOGS:
      return {
        ...state,
        downloadalertLogs: false,
        downloadAlertLogsData: [],
        downloadalertLogsSuccess: false,
        downloadalertLogsFailed: false,
        errorMessage: "",
      };

    case actionTypes.DOWNLOAD_ALERTS_LOGS_SUCCESS:
      return {
        ...state,
        downloadalertLogs: false,
        downloadAlertLogsData: action.payload.data,
        downloadalertLogsSuccess: true,
        downloadalertLogsFailed: false,
        errorMessage: "",
      };

    case actionTypes.DOWNLOAD_ALERTS_LOGS_FAILED:
      return {
        ...state,
        downloadalertLogs: false,
        downloadAlertLogsData: [],
        downloadalertLogsSuccess: false,
        downloadalertLogsFailed: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};
