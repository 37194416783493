import { put } from "redux-saga/effects";
import { BillingAction } from "../action";
import { Client, getCountryFromIP } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { notification } from "antd";
import { countryList } from "constants/constant";

export function* fetchSubscriptionsSaga(action) {
  const config = {
    method: "GET",
    url: `/api/v1/me/license-details`,
    headers: {
      ...action.payload.headers,
      "X-UI-Version": "1.0.0",
    },
  };
  try {
    const response = yield Client.request(config);

    yield put(BillingAction.fetchSubscriptionsSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(BillingAction.fetchSubscriptionsFailed(errorMessage));
  }
}

export function* fetchPlanGroupSaga(action) {
  const countryName = yield getCountryFromIP();
  let countryCode = "";
  for (const countryDetail of countryList) {
    if (countryDetail.name === countryName) {
      countryCode = countryDetail.code;
      break;
    }
  }

  const config = {
    method: "GET",
    url: `/api/v1/billing/plans?country=${countryCode}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    const response = yield Client.request(config);

    yield put(BillingAction.fetchPlanGroupSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(BillingAction.fetchPlanGroupFailed(errorMessage));
  }
}

export function* fetchInvoicesSaga(action) {
  const config = {
    method: "GET",
    url: `/api/v1/billing/invoices`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    const response = yield Client.request(config);

    yield put(BillingAction.fetchInvoicesSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(BillingAction.fetchInvoicesFailed(errorMessage));
  }
}

export function* sendInvoiceViaEmailSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/billing/invoices/${action.payload.invoiceID}/send-via-email`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    const response = yield Client.request(config);

    notification.success({ message: "Email sent successfully!" });

    yield put(BillingAction.sendInvoiceViaEmailSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(BillingAction.sendInvoiceViaEmailFailed(errorMessage));
  }
}

export function* initiateSubscriptionSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/billing/subscriptions/hosted-pages`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);

    yield put(BillingAction.initiateSubscriptionSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(BillingAction.initiateSubscriptionFailed(errorMessage));
  }
}

export function* updateSubscriptionSaga(action) {
  const config = {
    method: "PUT",
    url: `/api/v1/billing/subscriptions`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);

    yield put(BillingAction.updateSubscriptionSuccess());
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(BillingAction.updateSubscriptionFailed(errorMessage));
  }
}
