import * as actionTypes from "./actionTypes";
import _ from "lodash";

const initialState = {
  //*GLOBAL*//

  //* WELLNESS360 SUMMARY WEDIGT *//

  wellness360_summary_widget_processing: false,
  wellness360_summary_widget_success: false,
  wellness360_summary_widget_failed: false,
  wellness360_summary_widget_data: [],
  wellness360_summary_widget_message: "",

  //* WELLNESS360 SUMMARY HEALTHY EMPLOYEES *//

  wellness360_summary_healthy_employees_processing: false,
  wellness360_summary_healthy_employees_success: false,
  wellness360_summary_healthy_employees_failed: false,
  wellness360_summary_healthy_employees_data: {},
  wellness360_summary_healthy_employees_message: "",

  //* WELLNESS360 SUMMARY HEALTHY EMPLOYEES PREVIOUS MONTH *//

  wellness360_summary_healthy_employees_previous_month_processing: false,
  wellness360_summary_healthy_employees_previous_month_success: false,
  wellness360_summary_healthy_employees_previous_month_failed: false,
  wellness360_summary_healthy_employees_previous_month_data: {},
  wellness360_summary_healthy_employees_previous_month_message: "",

  //* WELLNESS360 SUMMARY OVERBURDENED EMPLOYEES *//

  wellness360_summary_Overburdened_employees_processing: false,
  wellness360_summary_Overburdened_employees_success: false,
  wellness360_summary_Overburdened_employees_failed: false,
  wellness360_summary_Overburdened_employees_data: [],
  wellness360_summary_Overburdened_employees_message: "",

  //* WELLNESS360 SUMMARY OVERALL SCORE *//

  wellness360_summary_Overall_score_processing: false,
  wellness360_summary_Overall_score_success: false,
  wellness360_summary_Overall_score_failed: false,
  wellness360_summary_Overall_score_data: [],
  wellness360_summary_Overall_score_message: "",

  //* WELLNESS360 ME OVERALL SCORE *//

  wellness360_me_Overall_score_processing: false,
  wellness360_me_Overall_score_success: false,
  wellness360_me_Overall_score_failed: false,
  wellness360_me_Overall_score_data: [],
  wellness360_me_Overall_score_message: "",

  //* WELLNESS360 SUMMARY TOP 10 HEALTHY EMPLOYEES *//

  wellness360_summary_top_teams_processing: false,
  wellness360_summary_top_teams_success: false,
  wellness360_summary_top_teams_failed: false,
  wellness360_summary_top_teams_data: [],
  wellness360_summary_top_teams_message: "",

  //* WELLNESS360 SUMMARY TEAM WISE UTILIZATION *//

  wellness360_summary_Team_Wise_Utilization_processing: false,
  wellness360_summary_Team_Wise_Utilization_success: false,
  wellness360_summary_Team_Wise_Utilization_failed: false,
  wellness360_summary_Team_Wise_Utilization_data: [],
  wellness360_summary_Team_Wise_Utilization_message: "",

  //* WELLNESS360 SUMMARY ONLINE TIME BREAK DOWN *//

  wellness360_summary_online_time_break_down_processing: false,
  wellness360_summary_online_time_break_down_success: false,
  wellness360_summary_online_time_break_down_failed: false,
  wellness360_summary_online_time_break_down_data: [],
  wellness360_summary_online_time_break_down_message: "",

  //* WELLNESS360 SUMMARY WELLNESS360 LEVEL BREAK DOWN  *//

  wellness360_summary_wellness360_level_break_down_processing: false,
  wellness360_summary_wellness360_level_break_down_success: false,
  wellness360_summary_wellness360_level_break_down_failed: false,
  wellness360_summary_wellness360_level_break_down_data: [],
  wellness360_summary_wellness360_level_break_down_message: "",

  //* WELLNESS360 SUMMARY TOP MOST ACTIVE TEAM  *//

  wellness360_summary_top_most_active_team_processing: true,
  wellness360_summary_top_most_active_team_success: false,
  wellness360_summary_top_most_active_team_failed: false,
  wellness360_summary_top_most_active_team_data: [],
  wellness360_summary_top_most_active_team_message: "",

  //* WELLNESS360 SUMMARY TOP LEAST ACTIVE TEAM  *//

  wellness360_summary_top_least_active_team_processing: true,
  wellness360_summary_top_least_active_team_success: false,
  wellness360_summary_top_least_active_team_failed: false,
  wellness360_summary_top_least_active_team_data: [],
  wellness360_summary_top_least_active_team_message: "",

  //* WELLNESS360 SUMMARY TEAM WISE WELLNESS360 BREAK DOWN *//

  wellness360_summary_team_wise_wellness360_break_down_processing: true,
  wellness360_summary_team_wise_wellness360_break_down_success: false,
  wellness360_summary_team_wise_wellness360_break_down_failed: false,
  wellness360_summary_team_wise_wellness360_break_down_data: [],
  wellness360_summary_team_wise_wellness360_break_down_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.WELLNESS360_SUMMARY_WIDGET_INITIAL:
      return {
        ...state,
        wellness360_summary_widget_processing: false,
        wellness360_summary_widget_success: false,
        wellness360_summary_widget_failed: false,
        wellness360_summary_widget_data: [],
        wellness360_summary_widget_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_WIDGET_PROCESS:
      return {
        ...state,
        wellness360_summary_widget_processing: true,
        wellness360_summary_widget_success: false,
        wellness360_summary_widget_failed: false,
        wellness360_summary_widget_data: [],
        wellness360_summary_widget_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_WIDGET_SUCCESS:
      return {
        ...state,
        wellness360_summary_widget_processing: false,
        wellness360_summary_widget_success: true,
        wellness360_summary_widget_failed: false,
        wellness360_summary_widget_data: action.data,
        wellness360_summary_widget_message: "",
      };
    case actionTypes.WELLNESS360_SUMMARY_WIDGET_FAILED:
      return {
        ...state,
        wellness360_summary_widget_processing: false,
        wellness360_summary_widget_success: false,
        wellness360_summary_widget_failed: true,
        wellness360_summary_widget_data: action.data,
        wellness360_summary_widget_message: "",
      };

    //* HEALTHY EMPLOYEES

    case actionTypes.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_INITIAL:
      return {
        ...state,
        wellness360_summary_healthy_employees_processing: false,
        wellness360_summary_healthy_employees_success: false,
        wellness360_summary_healthy_employees_failed: false,
        wellness360_summary_healthy_employees_data: {},
        wellness360_summary_healthy_employees_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PROCESS:
      return {
        ...state,
        wellness360_summary_healthy_employees_processing: true,
        wellness360_summary_healthy_employees_success: false,
        wellness360_summary_healthy_employees_failed: false,
        wellness360_summary_healthy_employees_data: {},
        wellness360_summary_healthy_employees_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_SUCCESS:
      return {
        ...state,
        wellness360_summary_healthy_employees_processing: false,
        wellness360_summary_healthy_employees_success: true,
        wellness360_summary_healthy_employees_failed: false,
        wellness360_summary_healthy_employees_data: action.data,
        wellness360_summary_healthy_employees_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_FAILED:
      return {
        ...state,
        wellness360_summary_healthy_employees_processing: false,
        wellness360_summary_healthy_employees_success: false,
        wellness360_summary_healthy_employees_failed: true,
        wellness360_summary_healthy_employees_data: action.data,
        wellness360_summary_healthy_employees_message: action.err,
      };

    //* HEALTHY EMPLOYEES PREVIOUS MONTH

    case actionTypes.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PREVIOUS_MONTH_INITIAL:
      return {
        ...state,
        wellness360_summary_healthy_employees_previous_month_processing: false,
        wellness360_summary_healthy_employees_previous_month_success: false,
        wellness360_summary_healthy_employees_previous_month_failed: false,
        wellness360_summary_healthy_employees_previous_month_data: {},
        wellness360_summary_healthy_employees_previous_month_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PREVIOUS_MONTH_PROCESS:
      return {
        ...state,
        wellness360_summary_healthy_employees_previous_month_processing: true,
        wellness360_summary_healthy_employees_previous_month_success: false,
        wellness360_summary_healthy_employees_previous_month_failed: false,
        wellness360_summary_healthy_employees_previous_month_data: {},
        wellness360_summary_healthy_employees_previous_month_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PREVIOUS_MONTH_SUCCESS:
      return {
        ...state,
        wellness360_summary_healthy_employees_previous_month_processing: false,
        wellness360_summary_healthy_employees_previous_month_success: true,
        wellness360_summary_healthy_employees_previous_month_failed: false,
        wellness360_summary_healthy_employees_previous_month_data: action.data,
        wellness360_summary_healthy_employees_previous_month_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_HEALTHY_EMPLOYEES_PREVIOUS_MONTH_FAILED:
      return {
        ...state,
        wellness360_summary_healthy_employees_previous_month_processing: false,
        wellness360_summary_healthy_employees_previous_month_success: false,
        wellness360_summary_healthy_employees_previous_month_failed: true,
        wellness360_summary_healthy_employees_previous_month_data: action.data,
        wellness360_summary_healthy_employees_previous_month_message:
          action.err,
      };

    //* OVERBURDENED EMPLOYEES

    case actionTypes.WELLNESS360_SUMMARY_OVERBURDENED_EMPLOYEES_INITIAL:
      return {
        ...state,
        wellness360_summary_overburdened_employees_processing: false,
        wellness360_summary_overburdened_employees_success: false,
        wellness360_summary_overburdened_employees_failed: false,
        wellness360_summary_overburdened_employees_data: {},
        wellness360_summary_overburdened_employees_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_OVERBURDENED_EMPLOYEES_PROCESS:
      return {
        ...state,
        wellness360_summary_overburdened_employees_success: false,
        wellness360_summary_overburdened_employees_failed: false,
        wellness360_summary_overburdened_employees_data: {},
        wellness360_summary_overburdened_employees_message: "",
        wellness360_summary_overburdened_employees_processing: true,
      };

    case actionTypes.WELLNESS360_SUMMARY_OVERBURDENED_EMPLOYEES_SUCCESS:
      return {
        ...state,
        wellness360_summary_overburdened_employees_processing: false,
        wellness360_summary_overburdened_employees_success: true,
        wellness360_summary_overburdened_employees_failed: false,
        wellness360_summary_overburdened_employees_data: action.data,
        wellness360_summary_overburdened_employees_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_OVERBURDENED_EMPLOYEES_FAILED:
      return {
        ...state,
        wellness360_summary_overburdened_employees_processing: false,
        wellness360_summary_overburdened_employees_success: false,
        wellness360_summary_overburdened_employees_failed: true,
        wellness360_summary_overburdened_employees_data: action.data,
        wellness360_summary_overburdened_employees_message: action.err,
      };

    //* OVERALL SCORE

    case actionTypes.WELLNESS360_SUMMARY_OVERALL_SCORE_INITIAL:
      return {
        ...state,
        wellness360_summary_overall_score_processing: false,
        wellness360_summary_overall_score_success: false,
        wellness360_summary_overall_score_failed: false,
        wellness360_summary_overall_score_data: {},
        wellness360_summary_overall_score_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_OVERALL_SCORE_PROCESS:
      return {
        ...state,
        wellness360_summary_overall_score_success: false,
        wellness360_summary_overall_score_failed: false,
        wellness360_summary_overall_score_data: {},
        wellness360_summary_overall_score_message: "",
        wellness360_summary_overall_score_processing: true,
      };

    case actionTypes.WELLNESS360_SUMMARY_OVERALL_SCORE_SUCCESS:
      return {
        ...state,
        wellness360_summary_overall_score_processing: false,
        wellness360_summary_overall_score_success: true,
        wellness360_summary_overall_score_failed: false,
        wellness360_summary_overall_score_data: action.data,
        wellness360_summary_overall_score_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_OVERALL_SCORE_FAILED:
      return {
        ...state,
        wellness360_summary_overall_score_processing: false,
        wellness360_summary_overall_score_success: false,
        wellness360_summary_overall_score_failed: true,
        wellness360_summary_overall_score_data: action.data,
        wellness360_summary_overall_score_message: action.err,
      };

    //* ME OVERALL SCORE

    case actionTypes.WELLNESS360_ME_OVERALL_SCORE_INITIAL:
      return {
        ...state,
        wellness360_me_overall_score_processing: false,
        wellness360_me_overall_score_success: false,
        wellness360_me_overall_score_failed: false,
        wellness360_me_overall_score_data: {},
        wellness360_me_overall_score_message: "",
      };

    case actionTypes.WELLNESS360_ME_OVERALL_SCORE_PROCESS:
      return {
        ...state,
        wellness360_me_overall_score_success: false,
        wellness360_me_overall_score_failed: false,
        wellness360_me_overall_score_data: {},
        wellness360_me_overall_score_message: "",
        wellness360_me_overall_score_processing: true,
      };

    case actionTypes.WELLNESS360_ME_OVERALL_SCORE_SUCCESS:
      return {
        ...state,
        wellness360_me_overall_score_processing: false,
        wellness360_me_overall_score_success: true,
        wellness360_me_overall_score_failed: false,
        wellness360_me_overall_score_data: action.data,
        wellness360_me_overall_score_message: "",
      };

    case actionTypes.WELLNESS360_ME_OVERALL_SCORE_FAILED:
      return {
        ...state,
        wellness360_me_overall_score_processing: false,
        wellness360_me_overall_score_success: false,
        wellness360_me_overall_score_failed: true,
        wellness360_me_overall_score_data: action.data,
        wellness360_me_overall_score_message: action.err,
      };

    //* TOP 10 HEALTHY EMPLOYEES

    case actionTypes.WELLNESS360_SUMMARY_TOP_TEAMS_INITIAL:
      return {
        ...state,
        wellness360_summary_top_teams_processing: false,
        wellness360_summary_top_teams_success: false,
        wellness360_summary_top_teams_failed: false,
        wellness360_summary_top_teams_data: {},
        wellness360_summary_top_teams__message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_TOP_TEAMS_PROCESS:
      return {
        ...state,
        wellness360_summary_top_teams_processing: false,
        wellness360_summary_top_teams_success: false,
        wellness360_summary_top_teams_failed: {},
        wellness360_summary_top_teams_message: "",
        wellness360_summary_top_teams_processing: true,
      };

    case actionTypes.WELLNESS360_SUMMARY_TOP_TEAMS_SUCCESS:
      return {
        ...state,
        wellness360_summary_top_teams_processing: false,
        wellness360_summary_top_teams_success: true,
        wellness360_summary_top_teams_failed: false,
        wellness360_summary_top_teams_data: action.data,
        wellness360_summary_top_teams_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_TOP_TEAMS_FAILED:
      return {
        ...state,
        wellness360_summary_top_teams_processing: false,
        wellness360_summary_top_teams_success: false,
        wellness360_summary_top_teams_failed: true,
        wellness360_summary_top_teams_data: action.data,
        wellness360_summary_top_teams_message: action.err,
      };

    //* TOP 10 HEALTHY EMPLOYEES

    case actionTypes.WELLNESS360_SUMMARY_TOP_TEAMS_INITIAL:
      return {
        ...state,
        wellness360_summary_top_teams_processing: false,
        wellness360_summary_top_teams_success: false,
        wellness360_summary_top_teams_failed: false,
        wellness360_summary_top_teams_data: {},
        wellness360_summary_top_teams__message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_TOP_TEAMS_PROCESS:
      return {
        ...state,
        wellness360_summary_top_teams_processing: false,
        wellness360_summary_top_teams_success: false,
        wellness360_summary_top_teams_failed: {},
        wellness360_summary_top_teams_message: "",
        wellness360_summary_top_teams_processing: true,
      };

    case actionTypes.WELLNESS360_SUMMARY_TOP_TEAMS_SUCCESS:
      return {
        ...state,
        wellness360_summary_top_teams_processing: false,
        wellness360_summary_top_teams_success: true,
        wellness360_summary_top_teams_failed: false,
        wellness360_summary_top_teams_data: action.data,
        wellness360_summary_top_teams_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_TOP_TEAMS_FAILED:
      return {
        ...state,
        wellness360_summary_top_teams_processing: false,
        wellness360_summary_top_teams_success: false,
        wellness360_summary_top_teams_failed: true,
        wellness360_summary_top_teams_data: action.data,
        wellness360_summary_top_teams_message: action.err,
      };

    //* WELLNESS360 SUMMARY TEAM WISE UTILIZATION *//

    case actionTypes.WELLNESS360_SUMMARY_TEAM_WISE_UTILIZATION_INITIAL:
      return {
        ...state,
        wellness360_summary_team_wise_utilization_processing: false,
        wellness360_summary_team_wise_utilization_success: false,
        wellness360_summary_team_wise_utilization_failed: false,
        wellness360_summary_team_wise_utilization_data: [],
        wellness360_summary_team_wise_utilization_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_TEAM_WISE_UTILIZATION_PROCESS:
      return {
        ...state,
        wellness360_summary_team_wise_utilization__processing: true,
        wellness360_summary_team_wise_utilization_success: false,
        wellness360_summary_team_wise_utilization_failed: false,
        wellness360_summary_team_wise_utilization_message: "",
        wellness360_summary_team_wise_utilization_data: [],
      };

    case actionTypes.WELLNESS360_SUMMARY_TEAM_WISE_UTILIZATION_SUCCESS: {
      return {
        ...state,
        wellness360_summary_team_wise_utilization_processing: false,
        wellness360_summary_team_wise_utilization_success: true,
        wellness360_summary_team_wise_utilization_failed: false,
        wellness360_summary_team_wise_utilization_data: action.data,
        wellness360_summary_team_wise_utilization_message: "",
      };
    }

    case actionTypes.WELLNESS360_SUMMARY_TEAM_WISE_UTILIZATION_FAILED:
      return {
        ...state,
        wellness360_summary_team_wise_utilization_processing: false,
        wellness360_summary_team_wise_utilization_success: false,
        wellness360_summary_team_wise_utilization_failed: true,
        wellness360_summary_team_wise_utilization_data: action.data,
        wellness360_summary_team_wise_utilization_message: action.err,
      };

    //* BREAK DOWN

    case actionTypes.WELLNESS360_SUMMARY_ONLINE_TIME_BREAK_DOWN_INITIAL:
      return {
        ...state,
        wellness360_summary_online_time_break_down_processing: true,
        wellness360_summary_online_time_break_down_success: false,
        wellness360_summary_online_time_break_down_failed: false,
        wellness360_summary_online_time_break_down_data: [],
        wellness360_summary_online_time_break_down_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_ONLINE_TIME_BREAK_DOWN_PROCESS:
      return {
        ...state,
        wellness360_summary_online_time_break_down_processing: true,
        wellness360_summary_online_time_break_down_success: false,
        wellness360_summary_online_time_break_down_failed: false,
        wellness360_summary_online_time_break_down_data: [],
        wellness360_summary_online_time_break_down_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_ONLINE_TIME_BREAK_DOWN_SUCCESS:
      return {
        ...state,
        wellness360_summary_online_time_break_down_processing: false,
        wellness360_summary_online_time_break_down_success: true,
        wellness360_summary_online_time_break_down_failed: false,
        wellness360_summary_online_time_break_down_data: action.data,
        wellness360_summary_online_time_break_down_message: "",
      };
    case actionTypes.WELLNESS360_SUMMARY_ONLINE_TIME_BREAK_DOWN_FAILED:
      return {
        ...state,
        wellness360_summary_online_time_break_down_processing: false,
        wellness360_summary_online_time_break_down_success: false,
        wellness360_summary_online_time_break_down_failed: true,
        wellness360_summary_online_time_break_down_data: action.data,
        wellness360_summary_online_time_break_down_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_WELLNESS360_LEVEL_BREAK_DOWN_INITIAL:
      return {
        ...state,
        wellness360_summary_wellness360_level_break_down_processing: true,
        wellness360_summary_wellness360_level_break_down_success: false,
        wellness360_summary_wellness360_level_break_down_failed: false,
        wellness360_summary_wellness360_level_break_down_data: [],
        wellness360_summary_wellness360_level_break_down_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_WELLNESS360_LEVEL_BREAK_DOWN_PROCESS:
      return {
        ...state,
        wellness360_summary_wellness360_level_break_down_processing: true,
        wellness360_summary_wellness360_level_break_down_success: false,
        wellness360_summary_wellness360_level_break_down_failed: false,
        wellness360_summary_wellness360_level_break_down_data: [],
        wellness360_summary_wellness360_level_break_down_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_WELLNESS360_LEVEL_BREAK_DOWN_SUCCESS:
      return {
        ...state,
        wellness360_summary_wellness360_level_break_down_processing: false,
        wellness360_summary_wellness360_level_break_down_success: true,
        wellness360_summary_wellness360_level_break_downfailed: false,
        wellness360_summary_wellness360_level_break_down_data: action.data,
        wellness360_summary_wellness360_level_break_down_message: "",
      };
    case actionTypes.WELLNESS360_SUMMARY_WELLNESS360_LEVEL_BREAK_DOWN_FAILED:
      return {
        ...state,
        wellness360_summary_wellness360_level_break_down_processing: false,
        wellness360_summary_wellness360_level_break_downsuccess: false,
        wellness360_summary_wellness360_level_break_down_failed: true,
        wellness360_summary_wellness360_level_break_downdata: action.data,
        wellness360_summary_wellness360_level_break_down_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_TOP_MOST_ACTIVE_TEAM_INITIAL:
      return {
        ...state,
        wellness360_summary_top_most_Active_team_processing: true,
        wellness360_summary_top_most_Active_team_success: false,
        wellness360_summary_top_most_Active_team_failed: false,
        wellness360_summary_top_most_Active_team_data: [],
        wellness360_summary_top_most_Active_team_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_TOP_MOST_ACTIVE_TEAM_PROCESS:
      return {
        ...state,
        wellness360_summary_top_most_Active_team_processing: true,
        wellness360_summary_top_most_Active_team_success: false,
        wellness360_summary_top_most_Active_team_failed: false,
        wellness360_summary_top_most_Active_team_data: [],
        wellness360_summary_top_most_Active_team_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_TOP_MOST_ACTIVE_TEAM_SUCCESS:
      return {
        ...state,
        wellness360_summary_top_most_Active_team_processing: false,
        wellness360_summary_top_most_Active_team_success: true,
        wellness360_summary_top_most_Active_teamfailed: false,
        wellness360_summary_top_most_Active_team_data: action.data,
        wellness360_summary_top_most_Active_team_message: "",
      };
    case actionTypes.WELLNESS360_SUMMARY_TOP_MOST_ACTIVE_TEAM_FAILED:
      return {
        ...state,
        wellness360_summary_top_most_Active_team_processing: false,
        wellness360_summary_top_most_Active_team_success: false,
        wellness360_summary_top_most_Active_team_failed: true,
        wellness360_summary_top_most_Active_team_data: action.data,
        wellness360_summary_top_most_Active_team_message: "",
      };
    case actionTypes.WELLNESS360_SUMMARY_TOP_LEAST_ACTIVE_TEAM_INITIAL:
      return {
        ...state,
        wellness360_summary_top_least_Active_team_processing: true,
        wellness360_summary_top_least_Active_team_success: false,
        wellness360_summary_top_least_Active_team_failed: false,
        wellness360_summary_top_least_Active_team_data: [],
        wellness360_summary_top_least_Active_team_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_TOP_LEAST_ACTIVE_TEAM_PROCESS:
      return {
        ...state,
        wellness360_summary_top_least_Active_team_processing: true,
        wellness360_summary_top_least_Active_team_success: false,
        wellness360_summary_top_least_Active_team_failed: false,
        wellness360_summary_top_least_Active_team_data: [],
        wellness360_summary_top_least_Active_team_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_TOP_LEAST_ACTIVE_TEAM_SUCCESS:
      return {
        ...state,
        wellness360_summary_top_least_Active_team_processing: false,
        wellness360_summary_top_least_Active_team_success: true,
        wellness360_summary_top_least_Active_teamfailed: false,
        wellness360_summary_top_least_Active_team_data: action.data,
        wellness360_summary_top_least_Active_team_message: "",
      };
    case actionTypes.WELLNESS360_SUMMARY_TOP_LEAST_ACTIVE_TEAM_FAILED:
      return {
        ...state,
        wellness360_summary_top_least_Active_team_processing: false,
        wellness360_summary_top_least_Active_team_success: false,
        wellness360_summary_top_least_Active_team_failed: true,
        wellness360_summary_top_least_Active_team_data: action.data,
        wellness360_summary_top_least_Active_team_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_TEAM_WISE_WELLNESS360_BREAK_DOWN_INITIAL:
      return {
        ...state,
        wellness360_summary_team_wise_wellness360_break_down_processing: true,
        wellness360_summary_team_wise_wellness360_break_down_success: false,
        wellness360_summary_team_wise_wellness360_break_down_failed: false,
        wellness360_summary_team_wise_wellness360_break_down_data: [],
        wellness360_summary_team_wise_wellness360_break_down_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_TEAM_WISE_WELLNESS360_BREAK_DOWN_PROCESS:
      return {
        ...state,
        wellness360_summary_team_wise_wellness360_break_down_processing: true,
        wellness360_summary_team_wise_wellness360_break_down_success: false,
        wellness360_summary_team_wise_wellness360_break_down_failed: false,
        wellness360_summary_team_wise_wellness360_break_down_data: [],
        wellness360_summary_team_wise_wellness360_break_down_message: "",
      };

    case actionTypes.WELLNESS360_SUMMARY_TEAM_WISE_WELLNESS360_BREAK_DOWN_SUCCESS:
      return {
        ...state,
        wellness360_summary_team_wise_wellness360_break_down_processing: false,
        wellness360_summary_team_wise_wellness360_break_down_success: true,
        wellness360_summary_team_wise_wellness360_break_down_failed: false,
        wellness360_summary_team_wise_wellness360_break_down_data: action.data,
        wellness360_summary_team_wise_wellness360_break_down_message: "",
      };
    case actionTypes.WELLNESS360_SUMMARY_TEAM_WISE_WELLNESS360_BREAK_DOWN_FAILED:
      return {
        ...state,
        wellness360_summary_team_wise_wellness360_break_down_processing: false,
        wellness360_summary_team_wise_wellness360_break_down_success: false,
        wellness360_summary_team_wise_wellness360_break_down_failed: true,
        wellness360_summary_team_wise_wellness360_break_down_data: action.data,
        wellness360_summary_team_wise_wellness360_break_down_message: "",
      };

    default:
      return state;
  }
};
