//* BREAK Start*/
export const PRODUCTIVITY_REPORT_INITIAL = "PRODUCTIVITY_REPORT_INITIAL";
export const PRODUCTIVITY_REPORT_PROCESS = "PRODUCTIVITY_REPORT_PROCESS";
export const PRODUCTIVITY_REPORT_SUCCESS = "PRODUCTIVITY_REPORT_SUCCESS";
export const PRODUCTIVITY_REPORT_FAILED = "PRODUCTIVITY_REPORT_FAILED";

//* BREAK End*/

// PRODUCTIVITY REPORT DOWNLOAD

export const PRODUCTIVITY_REPORT_DOWNLOAD_INITIAL =
  "PRODUCTIVITY_REPORT_DOWNLOAD_INITIAL";
export const PRODUCTIVITY_REPORT_DOWNLOAD_PROCESS =
  "PRODUCTIVITY_REPORT_DOWNLOAD_PROCESS";
export const PRODUCTIVITY_REPORT_DOWNLOAD_SUCCESS =
  "PRODUCTIVITY_REPORT_DOWNLOAD_SUCCESS";
export const PRODUCTIVITY_REPORT_DOWNLOAD_FAILED =
  "PRODUCTIVITY_REPORT_DOWNLOAD_FAILED";
