import * as actionTypes from "./actionTypes";

//*  ACTIVITY DETAILED WORKING TIME TRENDS *//

export const fetchRoles = (payload) => {
  return {
    type: actionTypes.FETCH_ROLES,
    payload,
  };
};

export const fetchRolesSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_ROLES_SUCCESS,
    payload,
  };
};

export const fetchRolesFailed = (payload) => {
  return {
    type: actionTypes.FETCH_ROLES_FAILED,
    payload,
  };
};

export const createRole = (payload) => {
  return {
    type: actionTypes.CREATE_ROLE,
    payload,
  };
};

export const createRoleSuccess = (payload) => {
  return {
    type: actionTypes.CREATE_ROLE_SUCCESS,
    payload,
  };
};

export const createRoleFailed = (payload) => {
  return {
    type: actionTypes.CREATE_ROLE_FAILED,
    payload,
  };
};

export const deleteRole = (payload) => {
  return {
    type: actionTypes.DELETE_ROLE,
    payload,
  };
};

export const deleteRoleSuccess = (payload) => {
  return {
    type: actionTypes.DELETE_ROLE_SUCCESS,
    payload,
  };
};

export const deleteRoleFailed = (payload) => {
  return {
    type: actionTypes.DELETE_ROLE_FAILED,
    payload,
  };
};

export const updateRole = (payload) => {
  return {
    type: actionTypes.UPDATE_ROLE,
    payload,
  };
};

export const updateRoleSuccess = (payload) => {
  return {
    type: actionTypes.UPDATE_ROLE_SUCCESS,
    payload,
  };
};

export const updateRoleFailed = (payload) => {
  return {
    type: actionTypes.UPDATE_ROLE_FAILED,
    payload,
  };
};

export const setRolesErrorMessage = (payload) => {
  return {
    type: actionTypes.SET_ROLES_ERROR_MESSAGE,
    payload,
  };
};
