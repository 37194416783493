import * as actionTypes from "./actionTypes";

export const pcUserDeviceInfoInitial = () => {
  return {
    type: actionTypes.PC_USER_DEVICE_INFO_INITIAL,
  };
};
export const pcUserDeviceInfoProcess = (payload) => {
  return {
    type: actionTypes.PC_USER_DEVICE_INFO_PROCESS,
    payload,
  };
};
export const pcUserDeviceInfoSuccess = (data) => {
  return {
    type: actionTypes.PC_USER_DEVICE_INFO_SUCCESS,
    data,
  };
};
export const pcUserDeviceInfoFailed = (data) => {
  return {
    type: actionTypes.PC_USER_DEVICE_INFO_FAILED,
    data,
  };
};
export const mobileUserDeviceInfoInitial = () => {
  return {
    type: actionTypes.MOBILE_USER_DEVICE_INFO_INITIAL,
  };
};
export const mobileUserDeviceInfoProcess = (payload) => {
  return {
    type: actionTypes.MOBILE_USER_DEVICE_INFO_PROCESS,
    payload,
  };
};
export const mobileUserDeviceInfoSuccess = (data) => {
  return {
    type: actionTypes.MOBILE_USER_DEVICE_INFO_SUCCESS,
    data,
  };
};
export const mobileUserDeviceInfoFailed = (data) => {
  return {
    type: actionTypes.MOBILE_USER_DEVICE_INFO_FAILED,
    data,
  };
};

export const userDeviceCountInitial = () => {
  return {
    type: actionTypes.USER_DEVICE_COUNT_INITIAL,
  };
};
export const userDeviceCountProcess = (payload) => {
  return {
    type: actionTypes.USER_DEVICE_COUNT_PROCESS,
    payload,
  };
};
export const userDeviceCountSuccess = (data) => {
  return {
    type: actionTypes.USER_DEVICE_COUNT_SUCCESS,
    data,
  };
};
export const userDeviceCountFailed = (data) => {
  return {
    type: actionTypes.USER_DEVICE_COUNT_FAILED,
    data,
  };
};

export const mobilePlatformInitial = () => {
  return {
    type: actionTypes.MOBILE_PLATFORM_INITIAL,
  };
};
export const mobilePlatformProcess = (payload) => {
  return {
    type: actionTypes.MOBILE_PLATFORM_PROCESS,
    payload,
  };
};
export const mobilePlatformSuccess = (data) => {
  return {
    type: actionTypes.MOBILE_PLATFORM_SUCCESS,
    data,
  };
};
export const mobilePlatformFailed = (data) => {
  return {
    type: actionTypes.MOBILE_PLATFORM_FAILED,
    data,
  };
};

export const pcPlatformInitial = () => {
  return {
    type: actionTypes.PC_PLATFORM_INITIAL,
  };
};
export const pcPlatformProcess = (payload) => {
  return {
    type: actionTypes.PC_PLATFORM_PROCESS,
    payload,
  };
};
export const pcPlatformSuccess = (data) => {
  return {
    type: actionTypes.PC_PLATFORM_SUCCESS,
    data,
  };
};
export const pcPlatformFailed = (data) => {
  return {
    type: actionTypes.PC_PLATFORM_FAILED,
    data,
  };
};

export const pcSystemTypeInitial = () => {
  return {
    type: actionTypes.PC_SYSTEM_TYPE_INITIAL,
  };
};
export const pcSystemTypeProcess = (payload) => {
  return {
    type: actionTypes.PC_SYSTEM_TYPE_PROCESS,
    payload,
  };
};
export const pcSystemTypeSuccess = (data) => {
  return {
    type: actionTypes.PC_SYSTEM_TYPE_SUCCESS,
    data,
  };
};
export const pcSystemTypeFailed = (data) => {
  return {
    type: actionTypes.PC_SYSTEM_TYPE_FAILED,
    data,
  };
};

export const pcMyzenVersionInitial = () => {
  return {
    type: actionTypes.PC_MYZEN_VERSION_INITIAL,
  };
};
export const pcMyzenVersionProcess = (payload) => {
  return {
    type: actionTypes.PC_MYZEN_VERSION_PROCESS,
    payload,
  };
};
export const pcMyzenVersionSuccess = (data) => {
  return {
    type: actionTypes.PC_MYZEN_VERSION_SUCCESS,
    data,
  };
};
export const pcMyzenVersionFailed = (data) => {
  return {
    type: actionTypes.PC_MYZEN_VERSION_FAILED,
    data,
  };
};
export const pcUserDeviceInfoDownloadInitial = () => {
  return {
    type: actionTypes.PC_USER_DEVICE_INFO_DOWNLOAD_INITIAL,
  };
};
export const pcUserDeviceInfoDownloadProcess = (payload) => {
  return {
    type: actionTypes.PC_USER_DEVICE_INFO_DOWNLOAD_PROCESS,
    payload,
  };
};
export const pcUserDeviceInfoDownloadSuccess = (data) => {
  return {
    type: actionTypes.PC_USER_DEVICE_INFO_DOWNLOAD_SUCCESS,
    data,
  };
};
export const pcUserDeviceInfoDownloadFailed = (data) => {
  return {
    type: actionTypes.PC_USER_DEVICE_INFO_DOWNLOAD_FAILED,
    data,
  };
};
export const mobileUserDeviceInfoDownloadInitial = () => {
  return {
    type: actionTypes.MOBILE_USER_DEVICE_INFO_DOWNLOAD_INITIAL,
  };
};
export const mobileUserDeviceInfoDownloadProcess = (payload) => {
  return {
    type: actionTypes.MOBILE_USER_DEVICE_INFO_DOWNLOAD_PROCESS,
    payload,
  };
};
export const mobileUserDeviceInfoDownloadSuccess = (data) => {
  return {
    type: actionTypes.MOBILE_USER_DEVICE_INFO_DOWNLOAD_SUCCESS,
    data,
  };
};
export const mobileUserDeviceInfoDownloadFailed = (data) => {
  return {
    type: actionTypes.MOBILE_USER_DEVICE_INFO_DOWNLOAD_FAILED,
    data,
  };
};
