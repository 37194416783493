import React from "react";

export const SettingsM = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        d="M16,9.8C16,9.5,16,9.3,16,9c0-0.3,0-0.5-0.1-0.8l1.7-1.3c0.2-0.1,0.2-0.3,0.1-0.5l-1.6-2.8
	c-0.1-0.2-0.3-0.2-0.5-0.2l-2,0.8c-0.4-0.3-0.9-0.6-1.3-0.8L12,1.3C12,1.1,11.8,1,11.6,1H8.4C8.2,1,8,1.1,8,1.3L7.7,3.5
	C7.2,3.7,6.8,3.9,6.4,4.2l-2-0.8C4.2,3.4,4,3.4,3.9,3.6L2.3,6.4C2.2,6.6,2.2,6.8,2.4,6.9l1.7,1.3C4,8.5,4,8.7,4,9
	c0,0.3,0,0.5,0.1,0.8l-1.7,1.3c-0.1,0.1-0.2,0.3-0.1,0.5l1.6,2.8c0.1,0.2,0.3,0.2,0.5,0.2l2-0.8c0.4,0.3,0.9,0.6,1.3,0.8L8,16.7
	C8,16.9,8.2,17,8.4,17h3.2c0.2,0,0.4-0.1,0.4-0.3l0.3-2.1c0.5-0.2,0.9-0.5,1.3-0.8l2,0.8c0.2,0.1,0.4,0,0.5-0.2l1.6-2.8
	c0.1-0.2,0.1-0.4-0.1-0.5L16,9.8z M10,12c-1.6,0-3-1.4-3-3s1.3-3,3-3c1.6,0,3,1.3,3,3S11.7,12,10,12z"
      />
    </svg>
  );
};
