import * as actionTypes from "./actionType";

const searchRoles = (dataset, search) => {
  if (search.length > 0) {
    var searchdata = _.filter(dataset, (list) => {
      return list.name.toLowerCase().indexOf(search.toLowerCase()) >= 0
        ? true
        : false;
    });

    return searchdata;
  } else {
    return dataset;
  }
};

const initialState = {
  role_create_processing: false,
  role_create_success: false,
  role_create_failed: false,
  role_create_data: [],
  role_create_message: "",

  role_list_processing: false,
  role_list_success: false,
  role_list_failed: false,
  role_list_data: [],
  role_list_message: "",
  roleMaster: [],

  role_update_processing: false,
  role_update_success: false,
  role_update_failed: false,
  role_update_data: [],
  role_update_message: "",

  user_role_search_list_processing: false,
  user_role_search_list_success: false,
  user_role_search_list_failed: false,
  user_role_search_list_data: [],
  user_role_search_list_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.ROLE_CREATE_INITIAL:
      return {
        ...state,
        role_create_processing: false,
        role_create_success: false,
        role_create_failed: false,
        role_create_data: [],
        role_create_message: "",
      };
    case actionTypes.ROLE_CREATE_PROCESS:
      return {
        ...state,
        role_create_processing: true,
        role_create_success: false,
        role_create_failed: false,
        role_create_data: [],
        role_create_message: "",
      };

    case actionTypes.ROLE_CREATE_SUCCESS:
      return {
        ...state,
        role_create_processing: false,
        role_create_success: true,
        role_create_failed: false,
        role_create_data: action.data,
        role_list_data: [...state.role_list_data, action.data],
        roleMaster: [...state.role_list_data, action.data],
        role_create_message: "",
      };
    case actionTypes.ROLE_CREATE_FAILED:
      return {
        ...state,
        role_create_processing: false,
        role_create_success: false,
        role_create_failed: true,
        role_create_data: [],
        role_create_message: action.data,
      };

    case actionTypes.ROLE_LIST_INITIAL:
      return {
        ...state,
        role_list_processing: false,
        role_list_success: false,
        role_list_failed: false,
        role_list_data: [],
        role_list_message: "",
      };
    case actionTypes.ROLE_LIST_PROCESS:
      return {
        ...state,
        role_list_processing: true,
        role_list_success: false,
        role_list_failed: false,
        role_list_data: [],
        role_list_message: "",
        roleMaster: [],
      };

    case actionTypes.ROLE_LIST_SUCCESS:
      return {
        ...state,
        role_list_processing: false,
        role_list_success: true,
        role_list_failed: false,
        role_list_data: action.data.data,
        role_list_message: "",
        roleMaster: action.data.data,
      };
    case actionTypes.ROLE_LIST_FAILED:
      return {
        ...state,
        role_list_processing: false,
        role_list_success: false,
        role_list_failed: true,
        role_list_data: [],
        role_list_message: action.data,
        roleMaster: [],
      };

    case actionTypes.ROLE_LIST_SEARCH:
      return {
        ...state,
        role_list_data: searchRoles(state.roleMaster, action.data),
      };

    case actionTypes.ROLE_UPDATE_INITIAL:
      return {
        ...state,
        role_update_processing: false,
        role_update_success: false,
        role_update_failed: false,
        role_update_data: [],
        role_update_message: "",
      };
    case actionTypes.ROLE_UPDATE_PROCESS:
      return {
        ...state,
        role_update_processing: true,
        role_update_success: false,
        role_update_failed: false,
        role_update_data: [],
        role_update_message: "",
      };

    case actionTypes.ROLE_UPDATE_SUCCESS:
      const fetchRolelist = [...state.role_list_data];
      const findIndex = fetchRolelist.findIndex(
        (item) => item.id == action.data.id
      );
      fetchRolelist[findIndex] = action.data;
      return {
        ...state,
        role_update_processing: false,
        role_update_success: true,
        role_update_failed: false,
        role_update_data: action.data,
        role_list_data: fetchRolelist,
        role_update_message: "",
      };
    case actionTypes.ROLE_UPDATE_FAILED:
      return {
        ...state,
        role_update_processing: false,
        role_update_success: false,
        role_update_failed: true,
        role_update_data: [],
        role_update_message: action.data,
      };

    case actionTypes.USER_ROLE_SEARCH_LIST_INITIAL:
      return {
        ...state,
        user_role_search_list_processing: false,
        user_role_search_list_success: false,
        user_role_search_list_failed: false,
        user_role_search_list_data: [],
        user_role_search_list_message: "",
      };
    case actionTypes.USER_ROLE_SEARCH_LIST_PROCESS:
      return {
        ...state,
        user_role_search_list_processing: true,
        user_role_search_list_success: false,
        user_role_search_list_failed: false,
        user_role_search_list_data: [],
        user_role_search_list_message: "",
      };

    case actionTypes.USER_ROLE_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        user_role_search_list_processing: false,
        user_role_search_list_success: true,
        user_role_search_list_failed: false,
        user_role_search_list_data: action.data,
        user_role_search_list_message: "",
      };
    case actionTypes.USER_ROLE_SEARCH_LIST_FAILED:
      return {
        ...state,
        user_role_search_list_processing: false,
        user_role_search_list_success: false,
        user_role_search_list_failed: true,
        user_role_search_list_data: [],
        user_role_search_list_message: action.data,
      };

    default:
      return state;
  }
};
