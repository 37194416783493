// SCREENSHOT TABLE

export const RESET_SCREENSHOT_TABLE = "RESET_SCREENSHOT_TABLE";
export const FETCH_SCREENSHOT_TABLE = "FETCH_SCREENSHOT_TABLE";
export const FETCH_SCREENSHOT_TABLE_SUCCESS = "FETCH_SCREENSHOT_TABLE_SUCCESS";
export const FETCH_SCREENSHOT_TABLE_FAILED = "FETCH_SCREENSHOT_TABLE_FAILED";

// Hourly session drawer
export const SET_HOURLY_SESSION_BREAKDOWN = "SET_HOURLY_SESSION_BREAKDOWN";
export const FETCH_HOURLY_ACTIVITY_PRODUCTIVITY =
  "FETCH_HOURLY_ACTIVITY_PRODUCTIVITY";
export const FETCH_HOURLY_ACTIVITY_PRODUCTIVITY_SUCCESS =
  "FETCH_HOURLY_ACTIVITY_PRODUCTIVITY_SUCCESS";
export const FETCH_HOURLY_ACTIVITY_PRODUCTIVITY_FAILED =
  "FETCH_HOURLY_ACTIVITY_PRODUCTIVITY_FAILED";
export const SET_HOURLY_ACTIVITY_PRODUCTIVITY_LOADING =
  "SET_HOURLY_ACTIVITY_PRODUCTIVITY_LOADING";

export const FETCH_POWER_LOGS = "FETCH_POWER_LOGS";
export const FETCH_POWER_LOGS_SUCCESS = "FETCH_POWER_LOGS_SUCCESS";
export const FETCH_POWER_LOGS_FAILED = "FETCH_POWER_LOGS_FAILED";

export const FETCH_SESSION_APP_LOG = "FETCH_SESSION_APP_LOG";
export const FETCH_SESSION_APP_LOG_SUCCESS = "FETCH_SESSION_APP_LOG_SUCCESS";
export const FETCH_SESSION_APP_LOG_FAILED = "FETCH_SESSION_APP_LOG_FAILED";

//  SCREENSHOT LIST BY USER ID
export const FETCH_SCREENSHOT_LIST_USER_ID_INITIAL =
  "FETCH_SCREENSHOT_LIST_USER_ID_INITIAL";
export const FETCH_SCREENSHOT_LIST_USER_ID_PROCESS =
  "FETCH_SCREENSHOT_LIST_USER_ID_PROCESS";
export const FETCH_SCREENSHOT_LIST_USER_ID_SUCCESS =
  "FETCH_SCREENSHOT_LIST_USER_ID_SUCCESS";
export const FETCH_SCREENSHOT_LIST_USER_ID_FAILED =
  "FETCH_SCREENSHOT_LIST_USER_ID_FAILED";

export const DOWNLOAD_SCREENSHOT = "DOWNLOAD_SCREENSHOT";
export const DOWNLOAD_SCREENSHOT_SUCCESS = "DOWNLOAD_SCREENSHOT_SUCCESS";
export const DOWNLOAD_SCREENSHOT_FAILED = "DOWNLOAD_SCREENSHOT_FAILED";

export const DOWNLOAD_SCREENSHOTS_ZIP = "DOWNLOAD_SCREENSHOTS_ZIP";
export const DOWNLOAD_SCREENSHOTS_ZIP_SUCCESS =
  "DOWNLOAD_SCREENSHOTS_ZIP_SUCCESS";
export const DOWNLOAD_SCREENSHOTS_ZIP_FAILED =
  "DOWNLOAD_SCREENSHOTS_ZIP_FAILED";

export const FLAG_SCREENSHOT = "FLAG_SCREENSHOT";
export const FLAG_SCREENSHOT_SUCCESS = "FLAG_SCREENSHOT_SUCCESS";
export const FLAG_SCREENSHOT_FAILED = "FLAG_SCREENSHOT_FAILED";

export const UNFLAG_SCREENSHOT = "UNFLAG_SCREENSHOT";
export const UNFLAG_SCREENSHOT_SUCCESS = "UNFLAG_SCREENSHOT_SUCCESS";
export const UNFLAG_SCREENSHOT_FAILED = "UNFLAG_SCREENSHOT_FAILED";

export const GET_FLAGGED_SCREENSHOT = "GET_FLAGGED_SCREENSHOT";
export const GET_FLAGGED_SCREENSHOT_SUCCESS = "GET_FLAGGED_SCREENSHOT_SUCCESS";
export const GET_FLAGGED_SCREENSHOT_FAILED = "GET_FLAGGED_SCREENSHOT_FAILED";

export const SET_SCREENSHOT_ERROR_MESSAGE = "SET_SCREENSHOT_ERROR_MESSAGE";
