import { put } from "redux-saga/effects";
import { TasksAction } from "../action";
import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { notification } from "antd";

export function* getTaskSaga(action) {
  const config = {
    method: "GET",
    url: `/api/v1/tasks/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    const response = yield Client.request(config);

    yield put(TasksAction.getTaskSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TasksAction.getTaskFailed(errorMessage));
  }
}

export function* fetchTasksSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/tasks/search?${action.payload.query}`,
    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };
  try {
    const response = yield Client.request(config);

    yield put(TasksAction.fetchTasksSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TasksAction.fetchTasksFailed(errorMessage));
  }
}

export function* fetchChildTasksSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/tasks/search?${action.payload.query}`,
    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };
  try {
    const response = yield Client.request(config);

    yield put(TasksAction.fetchChildTasksSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TasksAction.fetchChildTasksFailed(errorMessage));
  }
}

export function* createTaskSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/tasks/`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);

    yield put(TasksAction.createTaskSuccess(response.data));
    notification.success({
      message: `Successfully Created Task!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TasksAction.createTaskFailed(errorMessage));
  }
}

export function* updateTaskSaga(action) {
  const config = {
    method: "PUT",
    url: `/api/v1/tasks/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);

    yield put(TasksAction.updateTaskSuccess(response.data));
    notification.success({
      message: `Successfully Updated Task!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;

    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TasksAction.updateTaskFailed(errorMessage));
  }
}

export function* deleteTaskSaga(action) {
  const config = {
    method: "DELETE",
    url: `/api/v1/tasks/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    yield Client.request(config);

    yield put(TasksAction.deleteTaskSuccess());
    notification.success({
      message: `Successfully Deleted Task!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TasksAction.deleteTaskFailed(errorMessage));
  }
}

export function* getTaskAttachmentSaga(action) {
  const config = {
    method: "GET",
    url: `/api/v1/tasks/${action.payload.taskID}/attachments/${action.payload.id}/download`,
    headers: {
      ...action.payload.headers,
    },
    maxRedirects: 0,
  };
  try {
    const response = yield Client.request(config);

    window.open(response.data.data.url, "_blank");
    yield put(TasksAction.getTaskAttachmentSuccess());
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TasksAction.getTaskAttachmentFailed(errorMessage));
  }
}

export function* fetchTaskAttachmentsSaga(action) {
  const config = {
    method: "GET",
    url: `/api/v1/tasks/${action.payload.taskID}/attachments`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    const response = yield Client.request(config);

    yield put(TasksAction.fetchTaskAttachmentsSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TasksAction.fetchTaskAttachmentsFailed(errorMessage));
  }
}

export function* createTaskAttachmentSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/tasks/${action.payload.taskID}/attachments`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    for (const body of action.payload.bodyList) {
      config.data = body;
      yield Client.request(config);
    }

    yield put(TasksAction.createTaskAttachmentSuccess());
    notification.success({
      message: `Successfully Uploaded Task Attachments!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TasksAction.createTaskAttachmentFailed(errorMessage));
  }
}

export function* updateTaskAttachmentSaga(action) {
  const config = {
    method: "PUT",
    url: `/api/v1/tasks/${action.payload.taskID}/attachments/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);

    yield put(TasksAction.updateTaskAttachmentSuccess(response.data));
    notification.success({
      message: `Successfully Updated Task Attachment!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;

    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TasksAction.updateTaskAttachmentFailed(errorMessage));
  }
}

export function* deleteTaskAttachmentSaga(action) {
  const config = {
    method: "DELETE",
    url: `/api/v1/tasks/${action.payload.taskID}/attachments/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    yield Client.request(config);

    yield put(TasksAction.deleteTaskAttachmentSuccess());
    notification.success({
      message: `Successfully Deleted Task Attachment!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(TasksAction.deleteTaskAttachmentFailed(errorMessage));
  }
}
