import * as actionTypes from "./actionTypes";

const leaveTypeList = (data) => {};
const initialPagination = { totalCount: 0 };
const initialState = {
  //* LIST *//
  leave_list_loading: true,
  leave_list_success: false,
  leave_list_failed: false,
  leave_list_data: [],
  leave_pagination: initialPagination,
  leave_message: "",

  //* CREATE *//
  leave_create_loading: false,
  leave_create_success: false,
  leave_create_failed: false,
  leave_create_data: [],
  //* EDIT *//

  leave_edit_loading: false,
  leave_edit_success: false,
  leave_edit_failed: false,
  leave_edit_data: [],
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.LEAVE_LIST_INITIAL:
      return {
        ...state,
        leave_list_loading: true,
        leave_list_success: false,
        leave_list_failed: false,
        leave_list_data: [],
        leave_message: "",
      };
    case actionTypes.LEAVE_LIST_PROCESS:
      return {
        ...state,
        leave_list_loading: true,
        leave_list_success: false,
        leave_list_failed: false,
        leave_list_data: [],
        leave_message: "",
      };
    case actionTypes.LEAVE_LIST_SUCCESS:
      return {
        ...state,
        leave_list_loading: false,
        leave_list_success: true,
        leave_list_failed: false,
        leave_list_data: action?.data?.data,
        leave_pagination: action?.data?.meta,
        leave_message: "",
      };
    case actionTypes.LEAVE_LIST_FAILED:
      return {
        ...state,
        leave_list_loading: false,
        leave_list_success: false,
        leave_list_failed: true,
        leave_list_data: [],
        leave_message: action?.data?.data,
      };

    case actionTypes.LEAVE_CREATE_INITIAL:
      return {
        ...state,
        leave_create_loading: false,
        leave_create_success: false,
        leave_create_failed: false,
        leave_create_data: [],
        leave_message: "",
      };
    case actionTypes.LEAVE_CREATE_PROCESS:
      return {
        ...state,
        leave_create_loading: true,
        leave_create_success: false,
        leave_create_failed: false,
        leave_create_data: [],
        leave_message: "",
      };
    case actionTypes.LEAVE_CREATE_SUCCESS:
      return {
        ...state,
        leave_create_loading: false,
        leave_create_success: true,
        leave_create_failed: false,
        leave_create_data: action?.data,
        leave_list_data: [action?.data, ...state.leave_list_data],
        leave_message: "",
      };
    case actionTypes.LEAVE_CREATE_FAILED:
      return {
        ...state,
        leave_create_loading: false,
        leave_create_success: false,
        leave_create_failed: true,
        leave_create_data: [],
        leave_message: action?.data?.data,
      };

    case actionTypes.LEAVE_EDIT_INITIAL:
      return {
        ...state,
        leave_edit_loading: false,
        leave_edit_success: false,
        leave_edit_failed: false,
        leave_edit_data: [],
        leave_message: "",
      };
    case actionTypes.LEAVE_EDIT_PROCESS:
      return {
        ...state,
        leave_edit_loading: true,
        leave_edit_success: false,
        leave_edit_failed: false,
        leave_edit_data: [],
        leave_message: "",
      };
    case actionTypes.LEAVE_EDIT_SUCCESS:
      const fetchLeaveList = [...state.leave_list_data];
      const findIndex = fetchLeaveList.findIndex(
        (item) => item.id == action.data.id
      );
      fetchLeaveList[findIndex] = action.data;

      return {
        ...state,
        leave_edit_loading: false,
        leave_edit_success: true,
        leave_edit_failed: false,
        leave_edit_data: action?.data,
        leave_list_data: fetchLeaveList,
        leave_message: "",
      };
    case actionTypes.LEAVE_EDIT_FAILED:
      return {
        ...state,
        leave_edit_loading: false,
        leave_edit_success: false,
        leave_edit_failed: true,
        leave_edit_data: [],
        leave_message: action?.data?.data,
      };

    default:
      return state;
  }
};
