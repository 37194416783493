import * as actionTypes from "./actionTypes";

export const comparativeAnalysisUserPerformanceInitial = () => {
  return {
    type: actionTypes.COMPARATIVE_ANALYSIS_USER_PERFORMANCE_INITIAL,
  };
};
export const comparativeAnalysisUserPerformanceProcess = (payload) => {
  return {
    type: actionTypes.COMPARATIVE_ANALYSIS_USER_PERFORMANCE_PROCESS,
    payload,
  };
};
export const comparativeAnalysisUserPerformanceSuccess = (data) => {
  return {
    type: actionTypes.COMPARATIVE_ANALYSIS_USER_PERFORMANCE_SUCCESS,
    data,
  };
};
export const comparativeAnalysisUserPerformanceFailed = (data) => {
  return {
    type: actionTypes.COMPARATIVE_ANALYSIS_USER_PERFORMANCE_FAILED,
    data,
  };
};

export const comparativeAttendanceUserPerformanceInitial = () => {
  return {
    type: actionTypes.COMPARATIVE_ATTENDANCE_USER_PERFORMANCE_INITIAL,
  };
};
export const comparativeAttendanceUserPerformanceProcess = (payload) => {
  return {
    type: actionTypes.COMPARATIVE_ATTENDANCE_USER_PERFORMANCE_PROCESS,
    payload,
  };
};
export const comparativeAttendanceUserPerformanceSuccess = (data) => {
  return {
    type: actionTypes.COMPARATIVE_ATTENDANCE_USER_PERFORMANCE_SUCCESS,
    data,
  };
};
export const comparativeAttendanceUserPerformanceFailed = (data) => {
  return {
    type: actionTypes.COMPARATIVE_ATTENDANCE_USER_PERFORMANCE_FAILED,
    data,
  };
};

export const comparativeGoalUserPerformanceInitial = () => {
  return {
    type: actionTypes.COMPARATIVE_GOAL_USER_PERFORMANCE_INITIAL,
  };
};
export const comparativeGoalUserPerformanceProcess = (payload) => {
  return {
    type: actionTypes.COMPARATIVE_GOAL_USER_PERFORMANCE_PROCESS,
    payload,
  };
};
export const comparativeGoalUserPerformanceSuccess = (data) => {
  return {
    type: actionTypes.COMPARATIVE_GOAL_USER_PERFORMANCE_SUCCESS,
    data,
  };
};
export const comparativeGoalUserPerformanceFailed = (data) => {
  return {
    type: actionTypes.COMPARATIVE_GOAL_USER_PERFORMANCE_FAILED,
    data,
  };
};

export const comparativeApplicationUserPerformanceInitial = () => {
  return {
    type: actionTypes.COMPARATIVE_APPLICATION_USER_PERFORMANCE_INITIAL,
  };
};
export const comparativeApplicationUserPerformanceProcess = (payload) => {
  return {
    type: actionTypes.COMPARATIVE_APPLICATION_USER_PERFORMANCE_PROCESS,
    payload,
  };
};
export const comparativeApplicationUserPerformanceSuccess = (data) => {
  return {
    type: actionTypes.COMPARATIVE_APPLICATION_USER_PERFORMANCE_SUCCESS,
    data,
  };
};
export const comparativeApplicationUserPerformanceFailed = (data) => {
  return {
    type: actionTypes.COMPARATIVE_APPLICATION_USER_PERFORMANCE_FAILED,
    data,
  };
};
