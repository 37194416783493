//GET PRODUCTIVITY

import * as actionTypes from "./actionTypes";

export const ProductivityReportInitial = (token) => {
  return {
    type: actionTypes.PRODUCTIVITY_REPORT_INITIAL,
    token,
  };
};

export const ProductivityReportProcess = (token, bodyData) => {
  return {
    type: actionTypes.PRODUCTIVITY_REPORT_PROCESS,
    token,
    bodyData,
  };
};
export const ProductivityReportSuccess = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_REPORT_SUCCESS,
    data,
  };
};
export const ProductivityReportFailed = (data) => {
  return {
    type: actionTypes.PRODUCTIVITY_REPORT_FAILED,
    data,
  };
};

/**  PRODUCTIVITY REPORT DOWNLOAD  */

export const ReportDownloadProductivityInitial = () => {
  return {
    type: actionTypes.PRODUCTIVITY_REPORT_DOWNLOAD_INITIAL,
  };
};

export const ReportDownloadProductivityProcess = (
  token,
  bodyData,
  teamList
) => {
  return {
    type: actionTypes.PRODUCTIVITY_REPORT_DOWNLOAD_PROCESS,
    token,
    bodyData,
    teamList,
  };
};

export const ReportDownloadProductivitySuccess = () => {
  return {
    type: actionTypes.PRODUCTIVITY_REPORT_DOWNLOAD_SUCCESS,
  };
};

export const ReportDownloadProductivityFailed = (err) => {
  return {
    type: actionTypes.PRODUCTIVITY_REPORT_DOWNLOAD_FAILED,
    err,
  };
};
