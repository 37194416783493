export const PC_USER_DEVICE_INFO_INITIAL = "PC_USER_DEVICE_INFO_INITIAL";
export const PC_USER_DEVICE_INFO_PROCESS = "PC_USER_DEVICE_INFO_PROCESS";
export const PC_USER_DEVICE_INFO_SUCCESS = "PC_USER_DEVICE_INFO_SUCCESS";
export const PC_USER_DEVICE_INFO_FAILED = "PC_USER_DEVICE_INFO_FAILED";

export const MOBILE_USER_DEVICE_INFO_INITIAL =
  "MOBILE_USER_DEVICE_INFO_INITIAL";
export const MOBILE_USER_DEVICE_INFO_PROCESS =
  "MOBILE_USER_DEVICE_INFO_PROCESS";
export const MOBILE_USER_DEVICE_INFO_SUCCESS =
  "MOBILE_USER_DEVICE_INFO_SUCCESS";
export const MOBILE_USER_DEVICE_INFO_FAILED = "MOBILE_USER_DEVICE_INFO_FAILED";

export const USER_DEVICE_COUNT_INITIAL = "USER_DEVICE_COUNT_INITIAL";
export const USER_DEVICE_COUNT_PROCESS = "USER_DEVICE_COUNT_PROCESS";
export const USER_DEVICE_COUNT_SUCCESS = "USER_DEVICE_COUNT_SUCCESS";
export const USER_DEVICE_COUNT_FAILED = "USER_DEVICE_COUNT_FAILED";

export const MOBILE_PLATFORM_INITIAL = "MOBILE_PLATFORM_INITIAL";
export const MOBILE_PLATFORM_PROCESS = "MOBILE_PLATFORM_PROCESS";
export const MOBILE_PLATFORM_SUCCESS = "MOBILE_PLATFORM_SUCCESS";
export const MOBILE_PLATFORM_FAILED = "MOBILE_PLATFORM_FAILED";

export const PC_PLATFORM_INITIAL = "PC_PLATFORM_INITIAL";
export const PC_PLATFORM_PROCESS = "PC_PLATFORM_PROCESS";
export const PC_PLATFORM_SUCCESS = "PC_PLATFORM_SUCCESS";
export const PC_PLATFORM_FAILED = "PC_PLATFORM_FAILED";

export const PC_SYSTEM_TYPE_INITIAL = "PC_SYSTEM_TYPE_INITIAL";
export const PC_SYSTEM_TYPE_PROCESS = "PC_SYSTEM_TYPE_PROCESS";
export const PC_SYSTEM_TYPE_SUCCESS = "PC_SYSTEM_TYPE_SUCCESS";
export const PC_SYSTEM_TYPE_FAILED = "PC_SYSTEM_TYPE_FAILED";

export const PC_MYZEN_VERSION_INITIAL = "PC_MYZEN_VERSION_INITIAL";
export const PC_MYZEN_VERSION_PROCESS = "PC_MYZEN_VERSION_PROCESS";
export const PC_MYZEN_VERSION_SUCCESS = "PC_MYZEN_VERSION_SUCCESS";
export const PC_MYZEN_VERSION_FAILED = "PC_MYZEN_VERSION_FAILED";

export const PC_USER_DEVICE_INFO_DOWNLOAD_INITIAL =
  "PC_USER_DEVICE_INFO_DOWNLOAD_INITIAL";
export const PC_USER_DEVICE_INFO_DOWNLOAD_PROCESS =
  "PC_USER_DEVICE_INFO_DOWNLOAD_PROCESS";
export const PC_USER_DEVICE_INFO_DOWNLOAD_SUCCESS =
  "PC_USER_DEVICE_INFO_DOWNLOAD_SUCCESS";
export const PC_USER_DEVICE_INFO_DOWNLOAD_FAILED =
  "PC_USER_DEVICE_INFO_DOWNLOAD_FAILED";

export const MOBILE_USER_DEVICE_INFO_DOWNLOAD_INITIAL =
  "MOBILE_USER_DEVICE_INFO_DOWNLOAD_INITIAL";
export const MOBILE_USER_DEVICE_INFO_DOWNLOAD_PROCESS =
  "MOBILE_USER_DEVICE_INFO_DOWNLOAD_PROCESS";
export const MOBILE_USER_DEVICE_INFO_DOWNLOAD_SUCCESS =
  "MOBILE_USER_DEVICE_INFO_DOWNLOAD_SUCCESS";
export const MOBILE_USER_DEVICE_INFO_DOWNLOAD_FAILED =
  "MOBILE_USER_DEVICE_INFO_DOWNLOAD_FAILED";
