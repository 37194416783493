import * as actionTypes from "./actionTypes";

//*  ACTIVITY DETAILED WORKING TIME TRENDS *//

export const fetchAlerts = (payload) => {
  return {
    type: actionTypes.FETCH_ALERTS,
    payload,
  };
};

export const fetchAlertsSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_ALERTS_SUCCESS,
    payload,
  };
};

export const fetchAlertsFailed = (payload) => {
  return {
    type: actionTypes.FETCH_ALERTS_FAILED,
    payload,
  };
};

export const createAlert = (payload) => {
  return {
    type: actionTypes.CREATE_ALERT,
    payload,
  };
};

export const createAlertSuccess = (payload) => {
  return {
    type: actionTypes.CREATE_ALERT_SUCCESS,
    payload,
  };
};

export const createAlertFailed = (payload) => {
  return {
    type: actionTypes.CREATE_ALERT_FAILED,
    payload,
  };
};

export const deleteAlert = (payload) => {
  return {
    type: actionTypes.DELETE_ALERT,
    payload,
  };
};

export const deleteAlertSuccess = (payload) => {
  return {
    type: actionTypes.DELETE_ALERT_SUCCESS,
    payload,
  };
};

export const deleteAlertFailed = (payload) => {
  return {
    type: actionTypes.DELETE_ALERT_FAILED,
    payload,
  };
};

export const updateAlert = (payload) => {
  return {
    type: actionTypes.UPDATE_ALERT,
    payload,
  };
};

export const updateAlertSuccess = (payload) => {
  return {
    type: actionTypes.UPDATE_ALERT_SUCCESS,
    payload,
  };
};

export const updateAlertFailed = (payload) => {
  return {
    type: actionTypes.UPDATE_ALERT_FAILED,
    payload,
  };
};

export const setErrorMessage = (payload) => {
  return {
    type: actionTypes.SET_ERROR_MESSAGE,
    payload,
  };
};

export const fetchAlertsLogs = (payload) => {
  return {
    type: actionTypes.FETCH_ALERTS_LOGS,
    payload,
  };
};

export const fetchAlertsLogsSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_ALERTS_LOGS_SUCCESS,
    payload,
  };
};

export const fetchAlertsLogsFailed = (payload) => {
  return {
    type: actionTypes.FETCH_ALERTS_LOGS_FAILED,
    payload,
  };
};

export const downloadAlertsLogs = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_ALERTS_LOGS,
    payload,
  };
};

export const downloadAlertsLogsSuccess = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_ALERTS_LOGS_SUCCESS,
    payload,
  };
};

export const downloadAlertsLogsFailed = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_ALERTS_LOGS_FAILED,
    payload,
  };
};
