import { put } from "redux-saga/effects";
import { CrossDayAttendanceReportAction } from "../../action";
import { Client } from "../../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { ExportCSV } from "utils/export-csv";
import dayjs from "dayjs";
import { hms } from "utils/timeConverter";

const ExportData = (fileName, data, colunms, filterDate, teams) => {
  let result = [];
  const Generated = "Generated Date:-" + dayjs().format("DD-MM-YYYY hh:mm a");
  const generatedData = [
    fileName,
    "Teams: - " + teams.join(",").replaceAll(",", "/"),
    "Date:-" + filterDate,

    Generated,
    colunms.join(),
  ];

  data.forEach((record) => {
    const addColunmsValues = [
      dayjs(record?.date).format("DD-MM-YYYY"),
      record?.group_name,
      record?.employee_name,
      record?.employee_id,
      dayjs(record?.punch_in).format("hh:mm A"),
      dayjs(record?.punch_out).format("hh:mm A"),
      hms(record?.duration),
    ];

    generatedData.push(addColunmsValues.join(","));
  });
  result = generatedData;
  return result;
};
const getDownloadCSV = (payload, data) => {
  const teamIds = payload.payload.body.group_id.split(",");
  const selectTeamNames = payload.payload.team_list_data
    .filter((item) => teamIds.includes(item.id))
    .map((item) => item.name);

  const csvdata = data?.data;

  const date =
    dayjs(payload?.payload.body.start_date).format("YYYY-MM-DD") +
    " To " +
    dayjs(payload?.payload.body.end_date).format("YYYY-MM-DD");

  const colunms = [
    "Date",
    "Team",
    "Employee",
    "Employee Id",
    "Punch In",
    "Punch Out",
    "Duration",
  ];
  const exdata = ExportData(
    "Cross Day Attendance Report List",
    csvdata,
    colunms,
    date,
    selectTeamNames
  );

  ExportCSV("Cross_day_attendance_report_List_", exdata);
};
export function* fetchCrossDayAttendanceReportSaga(action) {
  const config = {
    method: "POST",
    url: `query/reports/cross_day_attendance_report`,
    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);
    yield put(
      CrossDayAttendanceReportAction.fetchCrossDayAttendanceReportSuccess(
        response.data
      )
    );
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(
      CrossDayAttendanceReportAction.fetchCrossDayAttendanceReportFailed(
        errorMessage
      )
    );
  }
}

export function* downloadCrossDayAttendanceReportSaga(action) {
  const config = {
    method: "POST",
    url: `query/reports/cross_day_attendance_report`,
    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };

  try {
    const response = yield Client.request(config);
    getDownloadCSV(action, response.data);
    yield put(
      CrossDayAttendanceReportAction.downloadCrossDayAttendanceReportSuccess(
        response.data
      )
    );
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(
      CrossDayAttendanceReportAction.downloadCrossDayAttendanceReportFailed(
        errorMessage
      )
    );
  }
}
