import React, { Fragment, Suspense, lazy, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  SIGNUP_INITIATE,
  ME,
  HOME,
  ACCOUNT,
  ACTIVITY,
  APPLICATION,
  ATTENDANCE,
  DASHBOARD,
  ONBOARDING,
  LIVESTREAM,
  PRODUCTIVITY,
  REPORTS,
  SETTINGS,
  TIMELINE,
  WELLNESS,
  FORBIDDEN,
  SIGNIN,
  SIGNUP_PHASE_7,
  SIGNUP_PHASE_2,
  SIGNUP_PHASE_8,
  BILLING,
  FIELD,
  PROJECTS,
  TASK,
  SCREENSHOT,
  DOWNLOAD,
  ALERTS,
  NOTEBOOK,
  ACCEPT_INVITATION,
  LEAVE_MANAGEMENT,
  DEVICE360,
  MANUALTIME,
  CLEAR_COOKIES,
  GOALS,
  SUCCESS,
  REDIRECT_TO_TENANT,
  EXTENSION_LOGIN,
  CHOOSE_LOGIN_FLOW,
} from "./constant";
import sLogoai from "../assets/images/logo_ai.png";

// import Signup from "./../actions/Auth/signup";

// import SignIn from "./../actions/Auth/SignIn.tsx";

// import SettingsContainer from "../containers/Settings/settingsContainer";
import AuthContextProvider from "contexts/authContext";
// import HomeContainer from "../containers/Home/homeContainer";
// import AttendanceContainer from "../containers/Attendance/attendanceContainer";
// import WellnessContainer from "../containers/Wellness360/WellnessContainer";
// import ActivityContainer from "../containers/Activity/ActivityContainer";
// import ProductivityContainer from "../containers/Productivity/ProductivityContainer";
// import TimelineContainer from "../containers/Timeline/timelineContainer";
// import LivestreamContainer from "../containers/Livestream/livestreamContainer";
// import ApplicationContainer from "../containers/Application/applicationContainer";
// import ReportsContainer from "../containers/Reports/reportsContainer";
// import AccountContainer from "../containers/Account/AccountContainer";

import Forbidden from "components/403";
import ClearCookies from "components/403/ClearCookies.tsx";
import LayoutBox from "common/Layout/index.js";
import AppDownloadProgress from "common/Header/AppDownloadProgress.tsx";
import { permissionsMap } from "constants/constant.js";
import ExtensionLogin from "components/ExtensionLogin/index.tsx";
// import Congratulations from "actions/Auth/congratulations.js";
// import CompleteSetup from "actions/Auth/CompleteSetup.tsx";
// import CompleteGoogleSetup from "actions/Auth/CompleteGoogleSetup.tsx";

// import BillingContainer from "containers/Account/BillingContainer.js";
// import Field from "components/Field/index.js";
// import ProjectPage from "components/Project/index.tsx";
// import TaskPage from "components/Project/Task/index.tsx";

const Signup = lazy(() => import("./../actions/Auth/signup"));
const SignIn = lazy(() => import("./../actions/Auth/SignIn.tsx"));
const ChooseLoginFlow = lazy(() =>
  import("./../actions/Auth/ChooseLoginFlow.tsx")
);

const SettingsContainer = lazy(() =>
  import("../containers/Settings/settingsContainer")
);

const Success = lazy(() => import("actions/Auth/Success.js"));

// const AuthContextProvider = lazy(() => import("contexts/authContext"));
const HomeContainer = lazy(() => import("../containers/Home/homeContainer"));
const AttendanceContainer = lazy(() =>
  import("../containers/Attendance/attendanceContainer")
);
const WellnessContainer = lazy(() =>
  import("../containers/Wellness360/WellnessContainer")
);
const ActivityContainer = lazy(() =>
  import("../containers/Activity/ActivityContainer")
);
const ProductivityContainer = lazy(() =>
  import("../containers/Productivity/ProductivityContainer")
);
const TimelineContainer = lazy(() =>
  import("../containers/Timeline/timelineContainer")
);
const LivestreamContainer = lazy(() =>
  import("../containers/Livestream/livestreamContainer")
);
const ApplicationContainer = lazy(() =>
  import("../containers/Application/applicationContainer")
);
const ReportsContainer = lazy(() =>
  import("../containers/Reports/reportsContainer")
);
const AccountContainer = lazy(() =>
  import("../containers/Account/AccountContainer")
);

const Congratulations = lazy(() => import("actions/Auth/congratulations.js"));
const CompleteSetup = lazy(() => import("actions/Auth/CompleteSetup.tsx"));
const AcceptInvitation = lazy(() =>
  import("actions/Auth/AcceptInvitation.tsx")
);

const CompleteGoogleSetup = lazy(() =>
  import("actions/Auth/CompleteGoogleSetup.tsx")
);
const RedirectToTenant = lazy(() =>
  import("actions/Auth/RedirectToTenant.tsx")
);

const BillingContainer = lazy(() =>
  import("containers/Account/BillingContainer.js")
);
const Field = lazy(() => import("components/Field/index.tsx"));
const ProjectPage = lazy(() => import("components/Project/index.tsx"));
const TaskPage = lazy(() => import("components/Project/Task/index.tsx"));
const Notebook = lazy(() => import("components/Notebook/index.tsx"));

const Screenshot = lazy(() => import("components/Screenshot/index.tsx"));
const Alerts = lazy(() => import("components/Alerts/index.tsx"));
const Goals = lazy(() => import("components/Goals/index.tsx"));

const Download = lazy(() => import("components/Download/index.tsx"));

const UserDetail = lazy(() => import("components/UserDetail/index.js"));
const OnboardingContainer = lazy(() =>
  import("../containers/Onboarding/onboardingContainer.js")
);
const LeavesContainer = lazy(() =>
  import("../containers/Leaves/leavesContainer")
);
const Device360Page = lazy(() => import("components/Device360/index.tsx"));
const ManualTimePage = lazy(() => import("components/ManualTime/index.tsx"));

const LoadPage = () => {
  return (
    <>
      <div className="auth_layout">
        <div
          className="signup_blcok setpass_blcok"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="s_top">
            <div className=" cir_logo">
              <div className="logo_ai">
                <img src={sLogoai} />
              </div>
            </div>
            <center style={{ marginTop: "25px" }}>
              <div style={{ marginBottom: "15px" }}>We360.ai</div>
              <div>A step towards productivity</div>
            </center>
          </div>
        </div>
      </div>
    </>
  );
};

export default function App() {
  useEffect(() => {
    const anonymousId = localStorage.getItem("anonymous_id");
    if (!anonymousId || anonymousId === "null" || anonymousId === "undefined") {
      localStorage.setItem("anonymous_id", uuid());
    }
  }, []);

  return (
    <Fragment>
      <AppDownloadProgress />
      <Routes>
        <Route path={FORBIDDEN} element={<Forbidden />} />
        <Route path={CLEAR_COOKIES} element={<ClearCookies />} />
        <Route path={HOME} element={<LoadPage />} />

        <Route
          path={SIGNUP_INITIATE}
          element={
            <AuthContextProvider authRoute>
              <Suspense fallback={<LoadPage />}>
                <Signup />
              </Suspense>
            </AuthContextProvider>
          }
        />

        <Route
          path={SIGNIN}
          element={
            <AuthContextProvider authRoute>
              <Suspense fallback={<LoadPage />}>
                <SignIn />
              </Suspense>
            </AuthContextProvider>
          }
        />

        {/* <Route path={SIGNUP_PHASE_1} element={<EmailVerification />} />
        <Route path={SIGNUP_PHASE_3} element={<EmailalreadyVerify />} />
        <Route path={SIGNUP_PHASE_4} element={<LinkExpired />} />
        <Route path={SIGNUP_PHASE_5} element={<SetPassword />} />  */}
        <Route
          path={SIGNUP_PHASE_7}
          element={
            <AuthContextProvider authRoute>
              <Suspense fallback={<LoadPage />}>
                <CompleteSetup />
              </Suspense>
            </AuthContextProvider>
          }
        />

        <Route
          path={CHOOSE_LOGIN_FLOW}
          element={
            <AuthContextProvider authRoute>
              <Suspense fallback={<LoadPage />}>
                <ChooseLoginFlow />
              </Suspense>
            </AuthContextProvider>
          }
        />

        <Route
          path={ACCEPT_INVITATION}
          element={
            <AuthContextProvider authRoute>
              <Suspense fallback={<LoadPage />}>
                <AcceptInvitation />
              </Suspense>
            </AuthContextProvider>
          }
        />

        <Route
          path={SIGNUP_PHASE_8}
          element={
            <AuthContextProvider authRoute>
              <Suspense fallback={<LoadPage />}>
                <CompleteGoogleSetup />
              </Suspense>
            </AuthContextProvider>
          }
        />

        <Route
          path={REDIRECT_TO_TENANT}
          element={
            <AuthContextProvider authRoute>
              <Suspense fallback={<LoadPage />}>
                <RedirectToTenant />
              </Suspense>
            </AuthContextProvider>
          }
        />

        <Route
          path={SIGNUP_PHASE_2}
          element={
            <AuthContextProvider>
              <Suspense fallback={<LoadPage />}>
                <Congratulations />
              </Suspense>
            </AuthContextProvider>
          }
        />

        <Route
          path={SUCCESS}
          element={
            <AuthContextProvider>
              <Suspense fallback={<LoadPage />}>
                <Success />
              </Suspense>
            </AuthContextProvider>
          }
        />
        <Route
          path={ME}
          element={
            <AuthContextProvider
              requiredPermissions={[permissionsMap.TENANT_USER]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <UserDetail page />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />
        <Route
          path={BILLING}
          element={
            <AuthContextProvider
              requiredPermissions={[permissionsMap.TENANT_OWNER]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <BillingContainer />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />
        <Route
          path={DASHBOARD}
          element={
            <AuthContextProvider
              requiredPermissions={[
                permissionsMap.TENANT_MANAGER,
                permissionsMap.TENANT_OWNER,
              ]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <HomeContainer />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />

        <Route
          exact
          path={PROJECTS}
          element={
            <AuthContextProvider
              requiredPermissions={[permissionsMap.TENANT_USER]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <ProjectPage />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />
        <Route
          path={`${PROJECTS}/:projectID`}
          element={
            <AuthContextProvider
              requiredPermissions={[permissionsMap.TENANT_USER]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <TaskPage />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />

        <Route
          path={`${PROJECTS}/:projectID${TASK}/:taskID`}
          element={
            <AuthContextProvider
              requiredPermissions={[permissionsMap.TENANT_USER]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <TaskPage />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />

        <Route
          path={NOTEBOOK}
          element={
            <AuthContextProvider
              requiredPermissions={[permissionsMap.TENANT_USER]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <Notebook />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />

        <Route
          path={ATTENDANCE}
          element={
            <AuthContextProvider
              requiredPermissions={[
                permissionsMap.TENANT_MANAGER,
                permissionsMap.TENANT_OWNER,
              ]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <AttendanceContainer />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />
        <Route
          path={LIVESTREAM}
          element={
            <AuthContextProvider
              requiredPermissions={[
                permissionsMap.TENANT_MANAGER,
                permissionsMap.TENANT_OWNER,
              ]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <LivestreamContainer />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />
        <Route
          path={WELLNESS}
          element={
            <AuthContextProvider
              requiredPermissions={[
                permissionsMap.TENANT_MANAGER,
                permissionsMap.TENANT_OWNER,
              ]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <WellnessContainer />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />
        <Route
          path={ACTIVITY}
          element={
            <AuthContextProvider
              requiredPermissions={[
                permissionsMap.TENANT_MANAGER,
                permissionsMap.TENANT_OWNER,
              ]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <ActivityContainer />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />
        <Route
          path={PRODUCTIVITY}
          element={
            <AuthContextProvider
              requiredPermissions={[
                permissionsMap.TENANT_MANAGER,
                permissionsMap.TENANT_OWNER,
              ]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <ProductivityContainer />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />
        <Route
          path={TIMELINE}
          element={
            <AuthContextProvider
              requiredPermissions={[
                permissionsMap.TENANT_MANAGER,
                permissionsMap.TENANT_OWNER,
              ]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <TimelineContainer />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />
        <Route
          path={APPLICATION}
          element={
            <AuthContextProvider
              requiredPermissions={[
                permissionsMap.TENANT_MANAGER,
                permissionsMap.TENANT_OWNER,
              ]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <ApplicationContainer />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />
        <Route
          path={GOALS}
          element={
            <AuthContextProvider
              requiredPermissions={[
                permissionsMap.TENANT_MANAGER,
                permissionsMap.TENANT_OWNER,
              ]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <Goals />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />
        <Route
          path={SETTINGS}
          element={
            <AuthContextProvider
              requiredPermissions={[
                permissionsMap.TENANT_OWNER,
                permissionsMap.IAM_MANAGE_ALL,
                permissionsMap.IAM_TEAM_MANAGE,
              ]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <SettingsContainer />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />
        <Route
          path={FIELD}
          element={
            <AuthContextProvider
              requiredPermissions={[
                permissionsMap.TENANT_MANAGER,
                permissionsMap.TENANT_OWNER,
              ]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <Field />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />
        <Route
          path={REPORTS}
          element={
            <AuthContextProvider
              requiredPermissions={[
                permissionsMap.TENANT_MANAGER,
                permissionsMap.TENANT_OWNER,
              ]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <ReportsContainer />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />
        <Route
          path={ACCOUNT}
          element={
            <AuthContextProvider
              requiredPermissions={[
                permissionsMap.TENANT_MANAGER,
                permissionsMap.TENANT_OWNER,
              ]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <AccountContainer />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />
        <Route
          path="*"
          element={
            <AuthContextProvider homePage>
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <HomeContainer />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />

        <Route
          path={SCREENSHOT}
          element={
            <AuthContextProvider
              requiredPermissions={[
                permissionsMap.TENANT_MANAGER,
                permissionsMap.TENANT_OWNER,
              ]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <Screenshot />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />
        <Route
          path={ALERTS}
          element={
            <AuthContextProvider
              requiredPermissions={[
                permissionsMap.TENANT_MANAGER,
                permissionsMap.TENANT_OWNER,
              ]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <Alerts />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />

        <Route
          path={DOWNLOAD}
          element={
            <AuthContextProvider
              requiredPermissions={[permissionsMap.TENANT_USER]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <Download />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />
        <Route
          exact
          path={DEVICE360}
          element={
            <AuthContextProvider
              requiredPermissions={[
                permissionsMap.TENANT_OWNER,
                permissionsMap.TENANT_MANAGER,
              ]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <Device360Page />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />
        <Route
          exact
          path={MANUALTIME}
          element={
            <AuthContextProvider
              requiredPermissions={[
                permissionsMap.TENANT_OWNER,
                permissionsMap.TENANT_USER,
                permissionsMap.TENANT_MANAGER,
                permissionsMap.IAM_MANAGE_ALL,
              ]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <ManualTimePage />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />

        {/* <Route
          path={ONBOARDING}
          element={
            <AuthContextProvider
              requiredPermissions={[permissionsMap.TENANT_OWNER]}
            >

              <Suspense fallback={<LoadPage />}>
                <OnboardingContainer />
              </Suspense>

            </AuthContextProvider>
          }
        /> */}

        <Route
          path={LEAVE_MANAGEMENT}
          element={
            <AuthContextProvider
              requiredPermissions={[
                permissionsMap.TENANT_OWNER,
                permissionsMap.TENANT_USER,
                permissionsMap.TENANT_MANAGER,
                permissionsMap.IAM_MANAGE_ALL,
              ]}
            >
              <LayoutBox>
                <Suspense fallback={<LoadPage />}>
                  <LeavesContainer />
                </Suspense>
              </LayoutBox>
            </AuthContextProvider>
          }
        />

        <Route
          path={EXTENSION_LOGIN}
          element={
            <AuthContextProvider
              requiredPermissions={[
                permissionsMap.TENANT_OWNER,
                permissionsMap.TENANT_USER,
                permissionsMap.TENANT_MANAGER,
                permissionsMap.IAM_MANAGE_ALL,
              ]}
            >
              <ExtensionLogin />
            </AuthContextProvider>
          }
        />

        {/* <Route
          path={SETTINGS}
          element={
            <AuthContextProvider><Suspense fallback={<LoadPage />}>
              <SettingsContainer />
            </Suspense></AuthContextProvider>
          }
        /> */}
        {/* <Route path="/*" element={<>HELLO there</>} /> */}

        {/*  <Route
          path="*"
          element={
            <AuthContextProvider><Suspense fallback={<LoadPage />}>
              <ProtectedRoute />
            </Suspense></AuthContextProvider>
          }
        /> */}
      </Routes>
    </Fragment>
  );
}
