//* APPLICATION DETAILED WORKING TIME TRENDS *//

export const APPLICATION_DETAILED_CATEGORY_LIST_INITIAL =
  "APPLICATION_DETAILED_CATEGORY_LIST_INITIAL";
export const APPLICATION_DETAILED_CATEGORY_LIST_PROCESS =
  "APPLICATION_DETAILED_CATEGORY_LIST_PROCESS";
export const APPLICATION_DETAILED_CATEGORY_LIST_SUCCESS =
  "APPLICATION_DETAILED_CATEGORY_LIST_SUCCESS";
export const APPLICATION_DETAILED_CATEGORY_LIST_FAILED =
  "APPLICATION_DETAILED_CATEGORY_LIST_FAILED";

//*  APPLICATION DETAILED WORKING TIME TRENDS End *//

//* APPLICATION DETAILED TEAM WISE APP UTILIZATION *//

export const APPLICATION_DETAILED_TEAM_WISE_APP_UTILIZATION_INITIAL =
  "APPLICATION_DETAILED_TEAM_WISE_APP_UTILIZATION_INITIAL";
export const APPLICATION_DETAILED_TEAM_WISE_APP_UTILIZATION_PROCESS =
  "APPLICATION_DETAILED_TEAM_WISE_APP_UTILIZATION_PROCESS";
export const APPLICATION_DETAILED_TEAM_WISE_APP_UTILIZATION_SUCCESS =
  "APPLICATION_DETAILED_TEAM_WISE_APP_UTILIZATION_SUCCESS";
export const APPLICATION_DETAILED_TEAM_WISE_APP_UTILIZATION_FAILED =
  "APPLICATION_DETAILED_TEAM_WISE_APP_UTILIZATION_FAILED";

//*  APPLICATION DETAILED TEAM WISE APP UTILIZATION End *//

//* APPLICATION DETAILED TEAM WISE URL UTILIZATION *//

export const APPLICATION_DETAILED_TEAM_WISE_URL_UTILIZATION_INITIAL =
  "APPLICATION_DETAILED_TEAM_WISE_URL_UTILIZATION_INITIAL";
export const APPLICATION_DETAILED_TEAM_WISE_URL_UTILIZATION_PROCESS =
  "APPLICATION_DETAILED_TEAM_WISE_URL_UTILIZATION_PROCESS";
export const APPLICATION_DETAILED_TEAM_WISE_URL_UTILIZATION_SUCCESS =
  "APPLICATION_DETAILED_TEAM_WISE_URL_UTILIZATION_SUCCESS";
export const APPLICATION_DETAILED_TEAM_WISE_URL_UTILIZATION_FAILED =
  "APPLICATION_DETAILED_TEAM_WISE_URL_UTILIZATION_FAILED";

//*  APPLICATION DETAILED TEAM WISE URL UTILIZATION End *//

//* APPLICATION DETAILED _APPLICATION_WSIE_APP_URL_UTILIZATION *//

export const APPLICATION_DETAILED_APPLICATION_WISE_APP_URL_UTILIZATION_INITIAL =
  "APPLICATION_DETAILED_APPLICATION_WISE_APP_URL_UTILIZATION_INITIAL";
export const APPLICATION_DETAILED_APPLICATION_WISE_APP_URL_UTILIZATION_PROCESS =
  "APPLICATION_DETAILED_APPLICATION_WISE_APP_URL_UTILIZATION_PROCESS";
export const APPLICATION_DETAILED_APPLICATION_WISE_APP_URL_UTILIZATION_SUCCESS =
  "APPLICATION_DETAILED_APPLICATION_WISE_APP_URL_UTILIZATION_SUCCESS";
export const APPLICATION_DETAILED_APPLICATION_WISE_APP_URL_UTILIZATION_FAILED =
  "APPLICATION_DETAILED_APPLICATION_WISE_APP_URL_UTILIZATION_FAILED";

//*  APPLICATION DETAILED _APPLICATION_WISE_APP_URL_UTILIZATION End *//

//* APPLICATION DETAILED _SEARCH_WISE_APP_DETAIL *//

export const APPLICATION_DETAILED_SEARCH_WISE_APP_DETAIL_INITIAL =
  "APPLICATION_DETAILED_SEARCH_WISE_APP_DETAIL_INITIAL";
export const APPLICATION_DETAILED_SEARCH_WISE_APP_DETAIL_PROCESS =
  "APPLICATION_DETAILED_SEARCH_WISE_APP_DETAIL_PROCESS";
export const APPLICATION_DETAILED_SEARCH_WISE_APP_DETAIL_SUCCESS =
  "APPLICATION_DETAILED_SEARCH_WISE_APP_DETAIL_SUCCESS";
export const APPLICATION_DETAILED_SEARCH_WISE_APP_DETAIL_FAILED =
  "APPLICATION_DETAILED_SEARCH_WISE_APP_DETAIL_FAILED";

//* APPLICATION DETAILED _SEARCH_WISE_URL_DETAIL *//

export const APPLICATION_DETAILED_SEARCH_WISE_URL_DETAIL_INITIAL =
  "APPLICATION_DETAILED_SEARCH_WISE_URL_DETAIL_INITIAL";
export const APPLICATION_DETAILED_SEARCH_WISE_URL_DETAIL_PROCESS =
  "APPLICATION_DETAILED_SEARCH_WISE_URL_DETAIL_PROCESS";
export const APPLICATION_DETAILED_SEARCH_WISE_URL_DETAIL_SUCCESS =
  "APPLICATION_DETAILED_SEARCH_WISE_URL_DETAIL_SUCCESS";
export const APPLICATION_DETAILED_SEARCH_WISE_URL_DETAIL_FAILED =
  "APPLICATION_DETAILED_SEARCH_WISE_URL_DETAIL_FAILED";

//*  APPLICATION DETAILED _SEARCH_WISE_APP_URL_DETAIL End *//

//* APPLICATION DETAILED _SEARCH_WISE_USER URL_DETAIL *//

export const APPLICATION_DETAILED_SEARCH_WISE_USER_URL_DETAIL_INITIAL =
  "APPLICATION_DETAILED_SEARCH_WISE_USER_URL_DETAIL_INITIAL";
export const APPLICATION_DETAILED_SEARCH_WISE_USER_URL_DETAIL_PROCESS =
  "APPLICATION_DETAILED_SEARCH_WISE_USER_URL_DETAIL_PROCESS";
export const APPLICATION_DETAILED_SEARCH_WISE_USER_URL_DETAIL_SUCCESS =
  "APPLICATION_DETAILED_SEARCH_WISE_USER_URL_DETAIL_SUCCESS";
export const APPLICATION_DETAILED_SEARCH_WISE_USER_URL_DETAIL_FAILED =
  "APPLICATION_DETAILED_SEARCH_WISE_USER_URL_DETAIL_FAILED";

//*  APPLICATION DETAILED _SEARCH_WISE_USER_URL_DETAIL End *//

//* APPLICATION DETAILED _SEARCH_WISE_USER APP_DETAIL *//

export const APPLICATION_DETAILED_SEARCH_WISE_USER_APP_DETAIL_INITIAL =
  "APPLICATION_DETAILED_SEARCH_WISE_USER_APP_DETAIL_INITIAL";
export const APPLICATION_DETAILED_SEARCH_WISE_USER_APP_DETAIL_PROCESS =
  "APPLICATION_DETAILED_SEARCH_WISE_USER_APP_DETAIL_PROCESS";
export const APPLICATION_DETAILED_SEARCH_WISE_USER_APP_DETAIL_SUCCESS =
  "APPLICATION_DETAILED_SEARCH_WISE_USER_APP_DETAIL_SUCCESS";
export const APPLICATION_DETAILED_SEARCH_WISE_USER_APP_DETAIL_FAILED =
  "APPLICATION_DETAILED_SEARCH_WISE_USER_APP_DETAIL_FAILED";

//*  APPLICATION DETAILED _SEARCH_WISE_USER_APP_DETAIL End *//

//* APPLICATION DETAILED _SEARCH_WISE_GRAPH APP_DETAIL *//

export const APPLICATION_DETAILED_SEARCH_WISE_GRAPH_APP_DETAIL_INITIAL =
  "APPLICATION_DETAILED_SEARCH_WISE_GRAPH_APP_DETAIL_INITIAL";
export const APPLICATION_DETAILED_SEARCH_WISE_GRAPH_APP_DETAIL_PROCESS =
  "APPLICATION_DETAILED_SEARCH_WISE_GRAPH_APP_DETAIL_PROCESS";
export const APPLICATION_DETAILED_SEARCH_WISE_GRAPH_APP_DETAIL_SUCCESS =
  "APPLICATION_DETAILED_SEARCH_WISE_GRAPH_APP_DETAIL_SUCCESS";
export const APPLICATION_DETAILED_SEARCH_WISE_GRAPH_APP_DETAIL_FAILED =
  "APPLICATION_DETAILED_SEARCH_WISE_GRAPH_APP_DETAIL_FAILED";

//*  APPLICATION DETAILED _SEARCH_WISE_GRAPH_APP_DETAIL End *//

//* APPLICATION DETAILED _SEARCH_WISE_GRAPH URL_DETAIL *//

export const APPLICATION_DETAILED_SEARCH_WISE_GRAPH_URL_DETAIL_INITIAL =
  "APPLICATION_DETAILED_SEARCH_WISE_GRAPH_URL_DETAIL_INITIAL";
export const APPLICATION_DETAILED_SEARCH_WISE_GRAPH_URL_DETAIL_PROCESS =
  "APPLICATION_DETAILED_SEARCH_WISE_GRAPH_URL_DETAIL_PROCESS";
export const APPLICATION_DETAILED_SEARCH_WISE_GRAPH_URL_DETAIL_SUCCESS =
  "APPLICATION_DETAILED_SEARCH_WISE_GRAPH_URL_DETAIL_SUCCESS";
export const APPLICATION_DETAILED_SEARCH_WISE_GRAPH_URL_DETAIL_FAILED =
  "APPLICATION_DETAILED_SEARCH_WISE_GRAPH_URL_DETAIL_FAILED";

//*  APPLICATION DETAILED _SEARCH_WISE_GRAPH_URL_DETAIL End *//

//* APPLICATION DETAILED _SEARCH_WISE_TITLE APP_DETAIL *//

export const APPLICATION_DETAILED_SEARCH_WISE_TITLE_APP_DETAIL_INITIAL =
  "APPLICATION_DETAILED_SEARCH_WISE_TITLE_APP_DETAIL_INITIAL";
export const APPLICATION_DETAILED_SEARCH_WISE_TITLE_APP_DETAIL_PROCESS =
  "APPLICATION_DETAILED_SEARCH_WISE_TITLE_APP_DETAIL_PROCESS";
export const APPLICATION_DETAILED_SEARCH_WISE_TITLE_APP_DETAIL_SUCCESS =
  "APPLICATION_DETAILED_SEARCH_WISE_TITLE_APP_DETAIL_SUCCESS";
export const APPLICATION_DETAILED_SEARCH_WISE_TITLE_APP_DETAIL_FAILED =
  "APPLICATION_DETAILED_SEARCH_WISE_TITLE_APP_DETAIL_FAILED";

//*  APPLICATION DETAILED _SEARCH_WISE_TITLE_APP_DETAIL End *//

//* APPLICATION DETAILED _SEARCH_WISE_TITLE_URL_DETAIL *//

export const APPLICATION_DETAILED_TOTAL_APPLICATION_USAGE_INITIAL =
  "APPLICATION_DETAILED_TOTAL_APPLICATION_USAGE_INITIAL";
export const APPLICATION_DETAILED_TOTAL_APPLICATION_USAGE_PROCESS =
  "APPLICATION_DETAILED_TOTAL_APPLICATION_USAGE_PROCESS";
export const APPLICATION_DETAILED_TOTAL_APPLICATION_USAGE_SUCCESS =
  "APPLICATION_DETAILED_TOTAL_APPLICATION_USAGE_SUCCESS";
export const APPLICATION_DETAILED_TOTAL_APPLICATION_USAGE_FAILED =
  "APPLICATION_DETAILED_TOTAL_APPLICATION_USAGE_FAILED";

//*  APPLICATION DETAILED ONLINE TIME BREAK DOWN *//

//* APPLICATION DETAILED APPLICATION LEVEL BREAK DOWN *//

export const APPLICATION_DETAILED_TOTAL_URL_USAGE_INITIAL =
  "APPLICATION_DETAILED_TOTAL_URL_USAGE_INITIAL";
export const APPLICATION_DETAILED_TOTAL_URL_USAGE_PROCESS =
  "APPLICATION_DETAILED_TOTAL_URL_USAGE_PROCESS";
export const APPLICATION_DETAILED_TOTAL_URL_USAGE_SUCCESS =
  "APPLICATION_DETAILED_TOTAL_URL_USAGE_SUCCESS";
export const APPLICATION_DETAILED_TOTAL_URL_USAGE_FAILED =
  "APPLICATION_DETAILED_TOTAL_URL_USAGE_FAILED";

//* APPLICATION DETAILED _SEARCH_WISE_TITLE_URL_DETAIL *//

export const APPLICATION_DETAILED_SEARCH_WISE_TITLE_URL_DETAIL_INITIAL =
  "APPLICATION_DETAILED_SEARCH_WISE_TITLE_URL_DETAIL_INITIAL";
export const APPLICATION_DETAILED_SEARCH_WISE_TITLE_URL_DETAIL_PROCESS =
  "APPLICATION_DETAILED_SEARCH_WISE_TITLE_URL_DETAIL_PROCESS";
export const APPLICATION_DETAILED_SEARCH_WISE_TITLE_URL_DETAIL_SUCCESS =
  "APPLICATION_DETAILED_SEARCH_WISE_TITLE_URL_DETAIL_SUCCESS";
export const APPLICATION_DETAILED_SEARCH_WISE_TITLE_URL_DETAIL_FAILED =
  "APPLICATION_DETAILED_SEARCH_WISE_TITLE_URL_DETAIL_FAILED";

//*  APPLICATION DETAILED _SEARCH_WISE_TITLE_URL_DETAIL End *//

export const APPLICATION_DETAILED_APP_SEARCH =
  "APPLICATION_DETAILED_APP_SEARCH";
export const APPLICATION_DETAILED_URL_SEARCH =
  "APPLICATION_DETAILED_URL_SEARCH";
export const APPLICATION_DETAILED_CATEGORY_LIST_SEARCH =
  "APPLICATION_DETAILED_CATEGORY_LIST_SEARCH";

//* APPLICATION DETAILED _SEARCH_WISE_APP_URL_DETAIL *//

export const APPLICATION_DETAILED_SEARCH_WISE_APP_URL_DETAIL_INITIAL =
  "APPLICATION_DETAILED_SEARCH_WISE_APP_URL_DETAIL_INITIAL";
export const APPLICATION_DETAILED_SEARCH_WISE_APP_URL_DETAIL_PROCESS =
  "APPLICATION_DETAILED_SEARCH_WISE_APP_URL_DETAIL_PROCESS";
export const APPLICATION_DETAILED_SEARCH_WISE_APP_URL_DETAIL_SUCCESS =
  "APPLICATION_DETAILED_SEARCH_WISE_APP_URL_DETAIL_SUCCESS";
export const APPLICATION_DETAILED_SEARCH_WISE_APP_URL_DETAIL_FAILED =
  "APPLICATION_DETAILED_SEARCH_WISE_APP_URL_DETAIL_FAILED";

//*  APPLICATION DETAILED _SEARCH_WISE_APP_URL_DETAIL End *//
