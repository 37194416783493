import React from "react";
import PropTypes from "prop-types";

import { Divider, Descriptions } from "antd";
import "./header.scss";

import Profileinfo from "./profileinfo";
import Companyinfo from "./companyinfo";
import { useSelector } from "react-redux";

const ProfileDetail = () => {
  const users = useSelector((state) => state.users);
  const { team_map_data } = useSelector((state) => state.team);
  return (
    <div className="">
      <Profileinfo />
      <Divider />

      <Descriptions layout="vertical">
        <Descriptions.Item label="Date of joining" span={1}>
          {users &&
            users.medetails_data &&
            users.medetails_data.date_of_joining &&
            users.medetails_data.date_of_joining}
        </Descriptions.Item>
        <Descriptions.Item label="Date of Birth">
          {/* 10 Api 1972 */}
          {users.medetails_data?.date_of_birth}
        </Descriptions.Item>
        <Descriptions.Item label="Gender">
          {users.medetails_data?.gender}
        </Descriptions.Item>
        <Descriptions.Item label="Phone No">
          {users.medetails_data?.phone_number}
        </Descriptions.Item>
        <Descriptions.Item label="Team" span={1}>
          {/* Technology -> DevOps{" "} */}
          {users.medetails_data?.attached_group_id &&
            team_map_data[users.medetails_data?.attached_group_id]?.name}
        </Descriptions.Item>
        <Descriptions.Item label="Shift" span={1}>
          {/* Morning{" "} */}
          {users.medetails_data?.attached_group_id &&
            team_map_data[users.medetails_data?.attached_group_id]?.shift?.name}
        </Descriptions.Item>
      </Descriptions>
      <Divider />

      <Companyinfo />
    </div>
  );
};

ProfileDetail.propTypes = {
  showBackground: PropTypes.bool,
  silderBtn: PropTypes.func,
};

export default ProfileDetail;
