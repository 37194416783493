import * as actionTypes from "./actionTypes";
import { FETCH_ME_INFO } from "../users/actionTypes";

const initialPagination = { totalCount: 0 };

const initialState = {
  fetchRolesLoading: true,
  rolesData: {},
  pagination: initialPagination,
  roleLoading: false,
  roleSuccess: false,
  deleteRoleSuccess: false,
  deleteRoleLoading: false,
  roleErrorMessage: "",
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_ROLES:
      return {
        ...state,
        fetchRolesLoading: true,
        roleErrorMessage: "",
      };

    case actionTypes.FETCH_ROLES_SUCCESS:
      return {
        ...state,
        fetchRolesLoading: false,
        rolesData: action.payload.data.reduce(
          (acc, role) => ({ ...acc, [role.id]: role }),
          {}
        ),
        pagination: action.payload.meta || state.pagination,
      };

    case actionTypes.FETCH_ROLES_FAILED:
      return {
        ...state,
        fetchRolesLoading: false,
        rolesData: {},
        pagination: initialPagination,
        roleErrorMessage: action.payload,
      };

    case actionTypes.CREATE_ROLE:
      return {
        ...state,
        roleLoading: true,
        roleErrorMessage: "",
        roleSuccess: false,
      };

    case actionTypes.CREATE_ROLE_SUCCESS:
      return {
        ...state,
        roleLoading: false,
        rolesData: {
          ...state.rolesData,
          [action.payload.id]: action.payload,
        },
        pagination: {
          ...state.pagination,
          totalCount: state.pagination.totalCount + 1,
        },
        roleSuccess: true,
      };

    case actionTypes.CREATE_ROLE_FAILED:
      return {
        ...state,
        roleLoading: false,
        roleErrorMessage: action.payload,
      };

    case actionTypes.UPDATE_ROLE:
      return {
        ...state,
        roleLoading: true,
        roleErrorMessage: "",
        roleSuccess: false,
      };

    case actionTypes.UPDATE_ROLE_SUCCESS:
      return {
        ...state,
        roleLoading: false,
        rolesData: {
          ...state.rolesData,
          [action.payload.id]: action.payload,
        },
        roleSuccess: true,
      };

    case actionTypes.UPDATE_ROLE_FAILED:
      return {
        ...state,
        roleLoading: false,
        roleErrorMessage: action.payload,
      };

    case actionTypes.DELETE_ROLE:
      return {
        ...state,
        deleteRoleLoading: true,
        roleErrorMessage: "",
        deleteRoleSuccess: false,
      };

    case actionTypes.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        deleteRoleLoading: false,
        deleteRoleSuccess: true,
      };

    case actionTypes.DELETE_ROLE_FAILED:
      return {
        ...state,
        deleteRoleLoading: false,
        roleErrorMessage: action.payload,
      };

    case actionTypes.SET_ROLES_ERROR_MESSAGE:
      return {
        ...state,
        roleErrorMessage: action.payload,
      };

    case FETCH_ME_INFO:
      return {
        ...state,
        fetchSubscriptionsLoading: true,
        billingErrorMessage: "",
        fetchSubscriptionFailed: false,
      };

    default:
      return state;
  }
};
