export const FETCH_TENANT_SETTINGS = "FETCH_TENANT_SETTINGS";
export const FETCH_TENANT_SETTINGS_SUCCESS = "FETCH_TENANT_SETTINGS_SUCCESS";
export const FETCH_TENANT_SETTINGS_FAILED = "FETCH_TENANT_SETTINGS_FAILED";

export const UPDATE_TENANT_SETTINGS = "UPDATE_TENANT_SETTINGS";
export const UPDATE_TENANT_SETTINGS_SUCCESS = "UPDATE_TENANT_SETTINGS_SUCCESS";
export const UPDATE_TENANT_SETTINGS_FAILED = "UPDATE_TENANT_SETTINGS_FAILED";

export const FETCH_USER_SESSION = "FETCH_USER_SESSION";
export const FETCH_USER_SESSION_SUCCESS = "FETCH_USER_SESSION_SUCCESS";
export const FETCH_USER_SESSION_FAILED = "FETCH_USER_SESSION_FAILED";

export const SET_TENANT_SETTINGS_ERROR_MESSAGE =
  "SET_TENANT_SETTINGS_ERROR_MESSAGE";

export const SET_BANNER_INFO = "SET_BANNER_INFO";
