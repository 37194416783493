export const FETCH_GOAL_ACHIEVEMENT_DISTRIBUTION =
  "FETCH_GOAL_ACHIEVEMENT_DISTRIBUTION";
export const FETCH_GOAL_ACHIEVEMENT_DISTRIBUTION_SUCCESS =
  "FETCH_GOAL_ACHIEVEMENT_DISTRIBUTION_SUCCESS";
export const FETCH_GOAL_ACHIEVEMENT_DISTRIBUTION_FAILED =
  "FETCH_GOAL_ACHIEVEMENT_DISTRIBUTION_FAILED";

export const FETCH_TEAMWISE_GOAL_COMPARISON = "FETCH_TEAMWISE_GOAL_COMPARISON";
export const FETCH_TEAMWISE_GOAL_COMPARISON_SUCCESS =
  "FETCH_TEAMWISE_GOAL_COMPARISON_SUCCESS";
export const FETCH_TEAMWISE_GOAL_COMPARISON_FAILED =
  "FETCH_TEAMWISE_GOAL_COMPARISON_FAILED";

export const FETCH_USERWISE_GOAL_ANALYTICS = "FETCH_USERWISE_GOAL_ANALYTICS";
export const FETCH_USERWISE_GOAL_ANALYTICS_SUCCESS =
  "FETCH_USERWISE_GOAL_ANALYTICS_SUCCESS";
export const FETCH_USERWISE_GOAL_ANALYTICS_FAILED =
  "FETCH_USERWISE_GOAL_ANALYTICS_FAILED";

export const SET_GOAL_ERROR_MESSAGE = "SET_GOAL_ERROR_MESSAGE";

export const FETCH_USERWISE_GOAL_ANALYTICS_DOWNLOAD =
  "FETCH_USERWISE_GOAL_ANALYTICS_DOWNLOAD";

export const FETCH_USERWISE_GOAL_ANALYTICS_DOWNLOAD_SUCCESS =
  "FETCH_USERWISE_GOAL_ANALYTICS_DOWNLOAD_SUCCESS";

export const FETCH_USERWISE_GOAL_ANALYTICS_DOWNLOAD_FAILED =
  "FETCH_USERWISE_GOAL_ANALYTICS_DOWNLOAD_FAILED";
