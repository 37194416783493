import { put } from "redux-saga/effects";
import {
  Client,
  ContcrolClient,
  getDownloadAppUrl,
  RawHttpClient as RawFileDownloadClient,
} from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { DownloadActions } from "../action";
import store from "../../../redux/index";
import axios from "axios";
import { message, notification } from "antd";
import { v4 as uuid } from "uuid";
import { copyToClipboard } from "utils/commonUtils";

const downloadStealthAppInstaller = (response, os, name) => {
  const blob = new Blob([response.data], {
    type: "application/octet-stream",
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  const fileName =
    name ||
    response.headers?.["content-disposition"]
      ?.split("filename=")[1]
      .split(";")[0];

  link.download =
    fileName || (os === "windows" ? "sass-install.ps1" : "sass-install.zip");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  notification.success({ message: "App Downloaded Successfully" });
};

export function* downloadStealthAppSaga(data) {
  const config = {
    method: "POST",
    url: `api/v1/app-downloads/stealth/${data.payload.os}`,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      ...data.payload.headers,
    },
    fetchPolicy: "no-cache",
  };
  try {
    let response = yield Client.request(config);
    let fileName = "";
    if (data.payload.copy) {
      copyToClipboard(
        getDownloadAppUrl(
          data.payload.coreApiUrl,
          response?.data?.data?.url,
          response?.data?.data?.fileName ||
            response.headers?.["content-disposition"]
              ?.split("filename=")[1]
              .split(";")[0] ||
            (response?.data?.data?.url || "MyzenV2").split("/").slice(-1)[0]
        )
      );
      message.warning(
        "This link is meant for one time use only. Please download the file via the link and distribute on to users internally.",
        10
      );
      return;
    }

    if (data.payload.internalDownload) {
      fileName = response.data.data.fileName;
      const ajaxRequest = axios.CancelToken.source();

      response = yield RawFileDownloadClient.request({
        method: "GET",
        url: `${response.data.data.url}?rand=${uuid()}`,
        headers: {
          "Content-Type": "application/octet-stream",
        },
        fetchPolicy: "no-cache",
        cancelToken: ajaxRequest.token,
        responseType: "blob",

        onDownloadProgress: (p) => {
          store.dispatch(
            DownloadActions.openProgressBar({
              ajaxRequest,
              progressInfo: { total: p.total, loaded: p.loaded },
            })
          );
        },
      });
      yield put(DownloadActions.closeProgressBar());
    }
    downloadStealthAppInstaller(response, data.payload.os, fileName);

    yield put(DownloadActions.downloadStealthAppSuccess());
  } catch (err) {
    console.log(err);
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(DownloadActions.downloadStealthAppFailed(errorMessage));
  }
}

export function* downloadStandardAppSaga(data) {
  const config = {
    method: "POST",
    url: `api/v1/app-downloads/standard/${data.payload.os}`,
    headers: {
      // Accept: "application/json",
      "Content-Type": "application/json",
      ...data.payload.headers,
    },
    fetchPolicy: "no-cache",
  };
  try {
    const response = yield Client.request(config);
    if (data.payload.copy) {
      copyToClipboard(response?.data?.data?.url);
      return;
    }
    window.open(`${response.data.data.url}?rand=${uuid()}`, "_blank");
    notification.success({ message: "App Downloading..." });
    yield put(DownloadActions.downloadStandardAppSuccess());
  } catch (err) {
    console.log(err);
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(DownloadActions.downloadStandardAppFailed(errorMessage));
  }
}

export function* downloadKeyConfigSaga(data) {
  const config = {
    method: "POST",
    url: `/api/v1/app-downloads/keyconfig`,
    headers: {
      ...data.payload.headers,
    },
    fetchPolicy: "no-cache",
  };

  try {
    const response = yield Client.request(config);

    const blob = new Blob([JSON.stringify(response.data)], {
      type: "application/octet-stream",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "keyconfig.json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    notification.success({ message: "Key Config Downloading..." });
    yield put(DownloadActions.downloadKeyConfigSuccess());
  } catch (err) {
    console.log(err);
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(DownloadActions.downloadKeyConfigFailed(errorMessage));
  }
}

export function* fetchAppVersionDetailsSaga(data) {
  try {
    const response = yield axios({
      url: `${process.env.REACT_APP_CONTROL_CENTER_ENDPOINT}discover/app_versions/`,
      method: "GET",
    });
    yield put(DownloadActions.fetchAppVersionDetailsSuccess(response.data));
  } catch (err) {
    console.log(err);
  }
}
