/* eslint-disable react/prop-types */
import React from "react";
import Chart from "../../../chart";
import { useSelector } from "react-redux";
import { Skeleton, Empty } from "antd";
import PropTypes from "prop-types";
import { hms } from "utils/timeConverter";
import dayjs from "dayjs";

const UserCompairsonCompanyWiseRadar = (props) => {
  const Cardskeleton = () => {
    return (
      <>
        <div className="charts">
          <div className="chart_header">
            <Skeleton.Input size={"small"} style={{ height: "15px" }} />
            <Skeleton.Avatar
              size={"small"}
              shape={"circle"}
              style={{ height: "15px", width: "15px" }}
            />
          </div>
          <div className="chartContainer chart_tool" id="chart">
            {/* chart */}
            <Skeleton.Avatar
              size={"large"}
              style={{ height: "300px", width: "100%" }}
            />
          </div>
        </div>
      </>
    );
  };

  Cardskeleton.propTypes = {
    loading: PropTypes.bool,
  };
  const userscorecard = useSelector(
    (
      state // @ts-ignore
    ) => state.userscorecard
  );
  const seriesColors = ["#7B61FF", "#E64F2D"];

  const {
    userPerformance,
    companyPerformance,
    userComparativeDurationPerformance,
    companyComparativeDurationPerformance,
  } = props;
  // Convert data to HH:mm:ss format
  const formatData = (data) => {
    return data.map((value) => hms(value));
  };

  const formattedUserPerformance = formatData(
    userComparativeDurationPerformance
  );
  const formattedCompanyPerformance = formatData(
    companyComparativeDurationPerformance
  );
  const chartOptions = {
    chart: {
      toolbar: {
        show: false,
      },
    },

    colors: seriesColors, // Example colors, you can change them as needed
    markers: {
      size: 4,
      colors: seriesColors,
      strokeWidth: 2,
    },
    stroke: {
      width: 2,
    },
    fill: {
      opacity: 0.1,
    },

    yaxis: {
      labels: {
        formatter: (value) => value,
      },
      stepSize: 2,
    },
    tooltip: {
      theme: "light",
      // y: {
      //   formatter: (value) =>
      //     dayjs.duration(value, "seconds").format("HH:mm:ss"),
      // },
      custom: ({ series, seriesIndex, dataPointIndex, w }) => {
        const name = seriesIndex === 0 ? "Individual user" : "Company";
        const value =
          seriesIndex === 0
            ? formattedUserPerformance[dataPointIndex]
            : formattedCompanyPerformance[dataPointIndex];
        const category = w.config.xaxis.categories[dataPointIndex];
        const color =
          seriesIndex === 0 ? w.globals.colors[0] : w.globals.colors[1];

        return (
          '<div class="bartooltip">' +
          category +
          '</div></div><div class="tooltip_card"><div class="w_data"><div class="colordot" style="background:' +
          color +
          '"></div>' +
          name +
          '<div class="w_value">' +
          value +
          '</div></div><div class="w_data"><div class="colordot" style="background:' +
          "</div></div></div>"
        );
      },
    },
    xaxis: {
      categories: [
        "Avg Working time",
        "Avg Active time",
        "Avg Online time",
        "Avg Productive time",
        "Avg Idle time",
      ],
    },
  };

  const appusages = {
    series: [
      {
        name: "Individual user",
        data: userPerformance,
      },
      {
        name: "Company",
        data: companyPerformance,
      },
    ],
  };

  return (
    <>
      {userscorecard.comparative_analysis_user_performance_processing ===
      true ? (
        <Cardskeleton />
      ) : (
        <div className="scorecard shadow-none border-0">
          <div className="chartContainer all_tool ml-4" id="chart">
            {/* chart */}
            {userPerformance && userPerformance?.length > 0 ? (
              <Chart
                options={chartOptions}
                series={appusages && appusages?.series}
                type="radar"
                height={450}
                width={330}
              />
            ) : (
              <div style={{ height: 450 }} className="emptyblock">
                <Empty description={false} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default UserCompairsonCompanyWiseRadar;
