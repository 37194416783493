import * as actionTypes from "./actionTypes";
import { FETCH_ME_INFO } from "../users/actionTypes";
const initialState = {
  //*GLOBAL*//

  //* LIST *//

  tenant_list_loading: false,
  tenant_list_success: false,
  tenant_list_failed: false,
  tenant_list_data: {},
  tenant_list_message: "",

  //* CREATE *//

  tenant_create_loading: false,
  tenant_create_success: false,
  tenant_create_failed: false,
  tenant_create_data: [],
  tenant_create_message: "",

  //* EDIT *//

  tenant_edit_loading: false,
  tenant_edit_success: false,
  tenant_edit_failed: false,
  tenant_edit_data: [],
  tenant_edit_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.TENANT_LIST_INITIAL:
      return {
        ...state,
        tenant_list_loading: true,
        tenant_list_success: false,
        tenant_list_failed: false,
        tenant_list_data: {},
        tenant_list_message: "",
      };
    case actionTypes.TENANT_LIST_PROCESS:
      return {
        ...state,
        tenant_list_loading: true,
        tenant_list_success: false,
        tenant_list_failed: false,
        tenant_list_data: {},
        tenant_list_message: "",
      };
    case actionTypes.TENANT_LIST_SUCCESS:
      return {
        ...state,
        tenant_list_loading: false,
        tenant_list_success: true,
        tenant_list_failed: false,
        tenant_list_data: action.data,
        tenant_list_message: "",
      };
    case actionTypes.TENANT_LIST_FAILED:
      return {
        ...state,
        tenant_list_loading: false,
        tenant_list_success: false,
        tenant_list_failed: true,
        tenant_list_data: {},
        tenant_list_message: "",
      };

    case actionTypes.TENANT_CREATE_INITIAL:
      return {
        ...state,
        tenant_create_loading: false,
        tenant_create_success: false,
        tenant_create_failed: false,
        tenant_create_data: [],
        tenant_create_message: "",
      };

    case actionTypes.TENANT_CREATE_PROCESS:
      return {
        ...state,
        tenant_create_loading: true,
        tenant_create_success: false,
        tenant_create_failed: false,
        tenant_create_data: [],
        tenant_create_message: "",
      };

    case actionTypes.TENANT_CREATE_SUCCESS:
      var res = action.data;

      return {
        ...state,
        tenant_create_loading: false,
        tenant_create_success: true,
        tenant_create_failed: false,
        tenant_create_data: res,
        // tenant_list_data: [createddata],
        tenant_create_message: "",
      };

    case actionTypes.TENANT_CREATE_FAILED:
      return {
        ...state,
        tenant_create_loading: false,
        tenant_create_success: false,
        tenant_create_failed: true,
        tenant_error_message: action.data,
        tenant_create_data: [],
        tenant_create_message: "",
      };

    case actionTypes.TENANT_EDIT_INITIAL:
      return {
        ...state,
        tenant_edit_loading: false,
        tenant_edit_success: false,
        tenant_edit_failed: false,
        tenant_edit_data: [],
        tenant_edit_message: "",
      };

    case actionTypes.TENANT_EDIT_PROCESS:
      return {
        ...state,
        tenant_edit_loading: true,
        tenant_edit_success: false,
        tenant_edit_failed: false,
        tenant_edit_data: [],
        tenant_edit_message: "",
      };

    case actionTypes.TENANT_EDIT_SUCCESS: {
      return {
        ...state,
        tenant_edit_loading: false,
        tenant_edit_success: true,
        tenant_edit_failed: false,
        tenant_list_data: action.data,
        tenant_edit_message: "",
      };
    }

    case actionTypes.TENANT_EDIT_FAILED:
      // const fetchLeaveslist = [...state.tenant_list_data];
      // const findIndexLeave = fetchLeaveslist.findIndex(item => item.id == action.data.id);
      // fetchLeaveslist[findIndexLeave] = action.data;
      // const fetchMyLeavelist = [...state.my_leave_list_data];
      // const findIndexMyLeave = fetchMyLeavelist.findIndex(item => item.id == action.data.id);
      // fetchMyLeavelist[findIndexMyLeave] = action.data;
      return {
        ...state,
        tenant_edit_loading: false,
        tenant_edit_success: false,
        tenant_edit_failed: true,
        tenant_edit_data: action.data,
        tenant_edit_message: "",
      };

    case FETCH_ME_INFO:
      return {
        ...state,
        tenant_list_loading: true,
        tenant_list_failed: false,
        tenant_list_message: "",
      };

    default:
      return state;
  }
};
