import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import "../index.scss";
import { Skeleton, ConfigProvider, Table, Typography } from "antd";
import { hms, hmsOnlyNumber } from "../../../utils/timeConverter";
import moment from "moment";
import { BreakReportAction } from "../../../redux/store/action";
import { AuthContext } from "contexts/authContext";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import GetParamers from "utils/getParamers";
import { SelectTopRight } from "common/SelectTop";
import { BreaksReportProcess } from "redux/store/reports/break-report/action";
const { Title } = Typography;

const Cardskeleton = () => {
  return (
    <>
      <div className="charts marginT">
        <div className="chart_header">
          <Skeleton.Input
            size={"small"}
            shape={"default"}
            style={{ height: "15px" }}
          />
          <Skeleton.Avatar
            size={"small"}
            shape={"circle"}
            style={{ height: "15px", width: "15px" }}
          />
        </div>
        <div className="chartContainer chart_tool" id="chart">
          <Skeleton.Avatar
            size={"large"}
            shape={"default"}
            style={{ height: "300px", width: "100%" }}
          />
        </div>
      </div>
    </>
  );
};

Cardskeleton.propTypes = {
  loading: PropTypes.bool,
};

const BreakTable = (props) => {
  const { startDate, endDate, userIds, groupIds } = props;
  const breakreportdata = useSelector((state) => state.breakreport);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const { KState, getDefaultHeader } = useContext(AuthContext);
  // const [startDate, setstartDate] = useState(dayjs());
  // const [endDate, setendDate] = useState(dayjs());
  // const [userIds, setuserIds] = useState([]);
  // const [groupIds, setgroupIds] = useState([]);

  const [paginationInfo, setPaginationInfo] = useState({
    page: 1,
    pageSize: 15,
  });

  const [sortedInfo, setSortedInfo] = useState({
    order: false,
    orderText: "",
    columnKey: "break_start",
  });

  const setSorter = (sortOrder, sortField, sortOrderText) => {
    setSortedInfo({
      order: sortOrder,
      columnKey: sortField,
      orderText: sortOrderText,
    });
  };

  const handleChange = (pagination, filters, sorter, action) => {
    if (action.action === "sort") {
      if (sorter.column !== undefined) {
        const sortField = sorter?.column?.key;
        const sortOrder = sorter.order === "ascend" ? false : true;
        const sortOrderText = sorter.order;
        setSorter(sortOrder, sortField, sortOrderText);
        dispatch(
          BreaksReportProcess({
            body: {
              start_date: startDate.format("YYYY-MM-DD") + "T00:00:00.474505",
              end_date: endDate.format("YYYY-MM-DD") + "T23:59:59.474505",
              page: paginationInfo.page,
              limit: paginationInfo.pageSize,
              user_id: userIds?.length > 0 ? userIds.join(",") : "",
              group_id: groupIds?.length > 0 ? groupIds.join(",") : "",
              sort_by: sortField,
              desc: sortOrder,
            },

            header: getDefaultHeader(),
            // isOwner: checkRequiredPermissions([permissionsMap.TENANT_OWNER]),
          })
        );
      } else {
        setSorter(false, "", "");
        dispatch(
          BreaksReportProcess({
            body: {
              start_date: startDate.format("YYYY-MM-DD") + "T00:00:00.474505",
              end_date: endDate.format("YYYY-MM-DD") + "T23:59:59.474505",
              page: paginationInfo.page,
              limit: paginationInfo.pageSize,
              user_id: userIds?.length > 0 ? userIds.join(",") : "",
              group_id: groupIds?.length > 0 ? groupIds.join(",") : "",
              sort_by: sortedInfo.sortField,
              desc: sortedInfo.order,
            },

            header: getDefaultHeader(),
            // isOwner: checkRequiredPermissions([permissionsMap.TENANT_OWNER]),
          })
        );
      }
    } else {
      setPaginationInfo({
        page: pagination.current,
        pageSize: pagination.pageSize,
      });
      dispatch(
        BreaksReportProcess({
          body: {
            start_date: startDate.format("YYYY-MM-DD") + "T00:00:00.474505",
            end_date: endDate.format("YYYY-MM-DD") + "T23:59:59.474505",
            page: pagination.current,
            limit: pagination.pageSize,
            user_id: userIds?.length > 0 ? userIds.join(",") : "",
            group_id: groupIds?.length > 0 ? groupIds.join(",") : "",
            sort_by: sortedInfo.columnKey,
            desc: sortedInfo.order,
          },

          header: getDefaultHeader(),
          // isOwner: checkRequiredPermissions([permissionsMap.TENANT_OWNER]),
        })
      );
    }
  };

  useEffect(() => {
    BreakReportTab(startDate, endDate, groupIds, userIds, 1, 15);
  }, []);
  const BreakReportTab = (
    startDate,
    endDate,
    groupIds,
    userIds,
    page,
    limit
  ) => {
    dispatch(
      BreaksReportProcess({
        body: {
          start_date: startDate.format("YYYY-MM-DD") + "T00:00:00.474505",
          end_date: endDate.format("YYYY-MM-DD") + "T23:59:59.474505",
          page: page,
          limit: limit,
          user_id: userIds?.length > 0 ? userIds.join(",") : "",
          group_id: groupIds?.length > 0 ? groupIds.join(",") : "",
        },

        header: getDefaultHeader(),
        // isOwner: checkRequiredPermissions([permissionsMap.TENANT_OWNER]),
      })
    );
  };

  const customize = true;
  const [loading, setLoading] = useState(true);
  if (loading) {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }

  const columns = [
    {
      dataIndex: "break_start",
      title: "Date",
      key: "break_start",
      sorter: {
        compare: (a, b) => {},
      },
      sortOrder:
        sortedInfo.columnKey === "break_start" ? sortedInfo.orderText : "",
      width: 10,
      render: (value, record) => {
        const breakStart =
          record.break_start != null
            ? moment(record.break_start).format("YYYY-MM-DD")
            : "";
        return <p>{breakStart}</p>;
      },
    },
    {
      dataIndex: "break_type",
      title: "Break Type",
      width: 10,
      key: "break_type",
      sorter: {
        compare: (a, b) => {},
      },

      sortOrder:
        sortedInfo.columnKey === "break_type" ? sortedInfo.orderText : "",
    },
    {
      dataIndex: "break_start",
      title: "Break Start",
      width: 10,
      key: "break_start",
      sorter: {
        compare: (a, b) => {},
      },

      sortOrder:
        sortedInfo.columnKey === "break_start" ? sortedInfo.orderText : "",
      render: (value, record) => {
        const breakStart =
          record.break_start != null
            ? moment(record.break_start).format("hh:mm a ")
            : "";
        return <p>{breakStart}</p>;
      },
    },
    {
      dataIndex: "break_end",
      title: "Break End",
      width: 10,
      key: "break_end",
      sorter: {
        compare: (a, b) => {},
      },

      sortOrder:
        sortedInfo.columnKey === "break_end" ? sortedInfo.orderText : "",
      render: (value, record) => {
        const breakEnd =
          record.break_end != null
            ? moment(record.break_end).format("hh:mm a")
            : "";
        return <p>{breakEnd}</p>;
      },
    },
    {
      dataIndex: "breakduration",
      title: "Break Duration",
      width: 50,
      key: "break_duration",
      sorter: {
        compare: (a, b) => {},
      },

      sortOrder:
        sortedInfo.columnKey === "break_duration" ? sortedInfo.orderText : "",
      render: (value, record) => {
        const breakDuration =
          record.break_duration != null ? hms(record.break_duration) : "";
        return <p>{breakDuration}</p>;
      },
    },
  ];

  const ExportData = (filename, data, header, filterdata) => {
    dispatch(BreakReportAction.BreaksDownloadReportInitial());
    let result = [];
    const dateran = filterdata;
    const Generated =
      "Generated Date:-" + moment().format("DD-MM-YYYY hh:mm a");
    const daterange = "Date:-" + dateran;
    const ddd = [filename, daterange, Generated, header.join()];
    let energy = "";
    data.map((record) => {
      const fruits = [
        record.first_name + " " + record.last_name,
        record.break_type,
        record.break_start != null
          ? moment(record.break_start).format("hh:mm a")
          : "",
        record.break_end != null
          ? moment(record.break_end).format("hh:mm a")
          : "",
        record.break_duration != null
          ? hmsOnlyNumber(record.break_duration)
          : "",
      ];
      energy = fruits.join(",");
      ddd.push(energy);
    });
    result = ddd;
    return result;
  };

  return (
    <>
      <div className="charts">
        <div className="chart_header ml-2">
          {breakreportdata.breaks_report_processing ? (
            <Skeleton.Input
              size={"small"}
              shape={"default"}
              style={{ height: "15px" }}
            />
          ) : (
            <Title level={2}>Detail</Title>
          )}
        </div>
        <ConfigProvider renderEmpty={customize ? null : undefined}>
          <div className="">
            <Table
              dataSource={breakreportdata && breakreportdata.breaks_report_data}
              columns={columns}
              size="small"
              scroll={{
                x: 1500,
              }}
              pagination={{
                showTotal: (total) => `Total ${total} Break`,
                total: breakreportdata && breakreportdata?.pagination.optional,
                defaultPageSize: 15,
                pageSizeOptions: [15, 30, 60, 100],
                current: paginationInfo?.page,
                showSizeChanger: true,
                selectComponentClass: SelectTopRight,
              }}
              onChange={handleChange}
              rowKey="id"
            />
          </div>
        </ConfigProvider>
      </div>
    </>
  );
};

BreakTable.propTypes = {
  getList: PropTypes.func,
};

export default BreakTable;
