import { Avatar, Button, Divider, Tooltip } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { stringToColor } from "utils/helper";
import { getAvatarUrl } from "utils/transport";
import { AuthContext } from "contexts/authContext";

interface Props {
  id: string;
  open: boolean;
  onUserDetail?: (e: React.MouseEvent) => void;
  children: React.ReactNode;
  hideUserDetailLinkWithRole: boolean;
}

const UserInfoTooltip = ({
  id,
  open,
  onUserDetail = () => {},
  children,
  hideUserDetailLinkWithRole,
}: Props) => {
  const { coreApiUrl } = useContext(AuthContext);
  const {
    user_map_data,
  }: {
    user_map_data: { [key: string]: User };
  } =
    // @ts-ignore
    useSelector((state) => state.users);

  const {
    team_map_data,
  }: {
    team_map_data: { [key: string]: Group };
  } =
    // @ts-ignore
    useSelector((state) => state.team);

  const [close, setClose] = useState(false);

  useEffect(() => {
    setClose(false);
  });

  if (!open || !user_map_data[id]) {
    return <>{children}</>;
  }

  const {
    identity,
    employee_id,
    attached_group_id,
    attached_group_role,
    designation,
  } = user_map_data[id];

  const userDetailsList = [
    { key: "Team", value: team_map_data[attached_group_id]?.name },
    { key: "Designation", value: designation?.name },
  ];

  return (
    <Tooltip
      arrow={false}
      overlayInnerStyle={{
        background: "white",
        width: "max-content",
        padding: "0px",
      }}
      destroyTooltipOnHide
      {...(close ? { open: false } : {})}
      title={
        <div className="w-max">
          <div className="flex gap-2 mr-2 p-2">
            {identity?.avatar_uri === "@we360" ? (
              <Avatar
                shape="square"
                className="rounded-xl"
                size={64}
                src={getAvatarUrl(coreApiUrl, id)}
              />
            ) : (
              <Avatar
                shape="square"
                className="rounded-xl"
                size={64}
                style={{
                  background: stringToColor(
                    `${identity?.first_name} ${identity?.last_name}`
                  ),
                  fontSize: "28px",
                }}
              >{`${
                identity.first_name.length
                  ? identity?.first_name[0].toUpperCase()
                  : ""
              }${
                identity.last_name.length
                  ? identity?.last_name[0].toUpperCase()
                  : ""
              }`}</Avatar>
            )}
            <div>
              <div className="text-sm tracking-wider text-[#191127]">{`${identity?.first_name} ${identity?.last_name}`}</div>
              <div className="text-[11px] text-[#667085] ">
                {identity.email}
              </div>
              <div className="flex gap-2 items-center mt-1">
                {employee_id && (
                  <div className="bg-[#E2DFEF] text-[#7B61FF] text-xs px-2 py-[2px] rounded">
                    {employee_id}
                  </div>
                )}
                {attached_group_role === "OWNER" && (
                  <div className="bg-green-100 text-green-600 text-xs px-2 py-[2px] rounded">
                    Team Manager
                  </div>
                )}
              </div>
            </div>
          </div>
          <Divider />
          <div className=" p-2 pt-0">
            {userDetailsList.map((userProperty) => (
              <div
                key={userProperty.key}
                className="flex justify-between items-center  gap-8"
              >
                <div className="text-[#667085] text-[11px] ">
                  {userProperty.key}
                </div>
                <div className="text-xs text-[#263238] tracking-wide ">
                  {userProperty.value || "-"}
                </div>
              </div>
            ))}
          </div>
          {!hideUserDetailLinkWithRole && (
            <Button
              className="w-full rounded-t-none bg-[#E2DFEF] text-[#7B61FF]"
              onClick={(e) => {
                setClose(true);
                onUserDetail(e);
              }}
            >
              View User Detail
            </Button>
          )}
        </div>
      }
    >
      {children}
    </Tooltip>
  );
};

export default UserInfoTooltip;
