import React from "react";
import { Alert } from "antd";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BILLING } from "route/constant";

const OverdueBanner = () => {
  const navigate = useNavigate();
  //@ts-ignore
  const { medetails_success } = useSelector((state) => state.users);

  const {
    fetchSubscriptionSuccess,
    subscriptionsData,
  }: {
    fetchSubscriptionSuccess: boolean;
    subscriptionsData: Subscription;
  } =
    //@ts-ignore
    useSelector((state) => state.billing);

  return (
    <Alert
      className={
        medetails_success && fetchSubscriptionSuccess ? "ml-[220px]" : ""
      }
      type="warning"
      message={
        <div>
          {" "}
          You have overdue invoice(s), please pay before{" "}
          {subscriptionsData.totalAllowedUsers < 50
            ? new Date(
                new Date(subscriptionsData.expiryDate).getTime() + 7 * 86400
              ).toDateString()
            : new Date(
                new Date(subscriptionsData.expiryDate).getTime() + 15 * 86400
              ).toDateString()}{" "}
          to avoid disruption of services. [
          <a
            onClick={() => {
              navigate(BILLING);
            }}
          >
            Go to Billing
          </a>
          ]
        </div>
      }
      banner
      closable
    />
  );
};

export default OverdueBanner;
