import * as actionTypes from "./actionTypes";

//*  ACTIVITY DETAILED WORKING TIME TRENDS *//

export const fetchUserData = (payload) => {
  return {
    type: actionTypes.FETCH_USER_DATA,
    payload,
  };
};

export const fetchUserDataSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_USER_DATA_SUCCESS,
    payload,
  };
};

export const fetchUserDataFailed = (payload) => {
  return {
    type: actionTypes.FETCH_USER_DATA_FAILED,
    payload,
  };
};

export const startLivestream = (payload) => {
  return {
    type: actionTypes.START_LIVESTREAM,
    payload,
  };
};

export const startLivestreamSuccess = (payload) => {
  return {
    type: actionTypes.START_LIVESTREAM_SUCCESS,
    payload,
  };
};

export const startLivestreamFailed = (payload) => {
  return {
    type: actionTypes.START_LIVESTREAM_FAILED,
    payload,
  };
};

export const startScreenshot = (payload) => {
  return {
    type: actionTypes.START_SCREENSHOT,
    payload,
  };
};

export const startScreenshotSuccess = (payload) => {
  return {
    type: actionTypes.START_SCREENSHOT_SUCCESS,
    payload,
  };
};

export const startScreenshotFailed = (payload) => {
  return {
    type: actionTypes.START_SCREENSHOT_FAILED,
    payload,
  };
};
