import * as actionTypes from "./actionTypes";

const initialState = {
  group_insight_report_processing: false,
  group_insight_report_success: false,
  group_insight_report_failed: false,
  group_insight_report_data: [],
  group_insight_report_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.GROUP_INSIGHT_REPORT_INITIAL:
      return {
        ...state,
        group_insight_report_processing: false,
        group_insight_report_success: false,
        group_insight_report_failed: false,
        group_insight_report_data: [],
        group_insight_report_message: "",
      };
    case actionTypes.GROUP_INSIGHT_REPORT_PROCESS:
      return {
        ...state,
        group_insight_report_processing: true,
        group_insight_report_success: false,
        group_insight_report_failed: false,
        group_insight_report_data: [],
        group_insight_report_message: "",
      };
    case actionTypes.GROUP_INSIGHT_REPORT_SUCCESS:
      return {
        ...state,
        group_insight_report_processing: false,
        group_insight_report_success: true,
        group_insight_report_failed: false,
        group_insight_report_data: action.data,
        group_insight_report_message: "",
      };
    case actionTypes.GROUP_INSIGHT_REPORT_FAILED:
      return {
        ...state,
        group_insight_report_processing: false,
        group_insight_report_success: false,
        group_insight_report_failed: true,
        group_insight_report_message: action.data,
        group_insight_report_data: [],
      };

    default:
      return state;
  }
};
