import { put } from "redux-saga/effects";
import { ActivityDetailAction } from "../action";
import { Client } from "../../../utils/transport";
import moment from "moment";

export function* GetactivityDetailWorkingTimeTrend(data) {
  let config = {
    method: "POST",
    url: "query/productivity/total_working_time",

    headers: {
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);
    if (response.status === 200) {
      yield put(
        ActivityDetailAction.ActivityDetailedWorkingTimeTrendSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ActivityDetailAction.ActivityDetailedWorkingTimeTrendFailed(response)
      );
    }
  } catch (err) {
    yield put(ActivityDetailAction.ActivityDetailedWorkingTimeTrendFailed(err));
  }
}

export function* GetActivityDetailedEmployeeList(data) {
  let config = {
    method: "POST",
    url: "query/productivity/productivity_employee_list",
    headers: {
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ActivityDetailAction.ActivityDetailedEmployeeListSuccess(response.data)
      );
    } else {
      yield put(
        ActivityDetailAction.ActivityDetailedEmployeeListFailed(response)
      );
    }
  } catch (err) {
    yield put(ActivityDetailAction.ActivityDetailedEmployeeListFailed(err));
  }
}

export function* GetActivityEmployeeDetailedEmployeeList(data) {
  let config = {
    method: "POST",
    url: "query/productivity/productivity_employee_list",
    headers: {
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ActivityDetailAction.ActivityEmployeeDetailedEmployeeListSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ActivityDetailAction.ActivityEmployeeDetailedEmployeeListFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ActivityDetailAction.ActivityEmployeeDetailedEmployeeListFailed(err)
    );
  }
}
