import * as actionTypes from "./actionTypes";

//* APPLICATION SUMMARY CATEGORYUTILIZATION *//

export const ApplicationSummaryCategoryUtilizationInitial = () => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_CATEGORY_UTILIZATION_INITIAL,
  };
};
export const ApplicationSummaryCategoryUtilizationProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_CATEGORY_UTILIZATION_PROCESS,
    token,
    bodyData,
  };
};
export const ApplicationSummaryCategoryUtilizationSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_CATEGORY_UTILIZATION_SUCCESS,
    data,
  };
};
export const ApplicationSummaryCategoryUtilizationFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_CATEGORY_UTILIZATION_FAILED,
    data,
  };
};

//* Online time Breakdown SUMMARY *//

export const ApplicationSummaryTotalApplicationUsageInitial = () => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TOTAL_APPLICATION_USAGE_INITIAL,
  };
};
export const ApplicationSummaryTotalApplicationUsageProcess = (
  token,
  bodyData
) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TOTAL_APPLICATION_USAGE_PROCESS,
    token,
    bodyData,
  };
};
export const ApplicationSummaryTotalApplicationUsageSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TOTAL_APPLICATION_USAGE_SUCCESS,
    data,
  };
};
export const ApplicationSummaryTotalApplicationUsageFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TOTAL_APPLICATION_USAGE_FAILED,
    data,
  };
};

//*  APPLICATION SUMMARY APPLICATION Level Break down *//

export const ApplicationSummaryTotalUrlUsageInitial = () => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TOTAL_URL_USAGE_INITIAL,
  };
};
export const ApplicationSummaryTotalUrlUsageProcess = (token, bodyData) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TOTAL_URL_USAGE_PROCESS,
    token,
    bodyData,
  };
};
export const ApplicationSummaryTotalUrlUsageSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TOTAL_URL_USAGE_SUCCESS,
    data,
  };
};
export const ApplicationSummaryTotalUrlUsageFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TOTAL_URL_USAGE_FAILED,
    data,
  };
};

//*  APPLICATION SUMMARY Top 3 most active team *//

export const ApplicationSummaryTopButtomActiveTeamInitial = () => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_INITIAL,
  };
};
export const ApplicationSummaryTopButtomActiveTeamProcess = (data) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_PROCESS,
    data,
  };
};
export const ApplicationSummaryTopButtomActiveTeamSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_SUCCESS,
    data,
  };
};
export const ApplicationSummaryTopButtomActiveTeamFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TOP_BUTTOM_ACTIVE_TEAM_FAILED,
    data,
  };
};
//*  APPLICATION SUMMARY Top 3 lease active team *//

export const ApplicationSummaryTopLeastActiveTeamInitial = (token) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TOP_LEAST_ACTIVE_TEAM_INITIAL,
    token,
  };
};
export const ApplicationSummaryTopLeastActiveTeamProcess = (token) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TOP_LEAST_ACTIVE_TEAM_PROCESS,
    token,
  };
};
export const ApplicationSummaryTopLeastActiveTeamSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TOP_LEAST_ACTIVE_TEAM_SUCCESS,
    data,
  };
};
export const ApplicationSummaryTopLeastActiveTeamFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TOP_LEAST_ACTIVE_TEAM_FAILED,
    data,
  };
};

//*  APPLICATION SUMMARY TEAM WISE APPLICATION BREAK DOWN *//

export const ApplicationSummaryTeamWiseApplicationBreakdownInitial = (
  token
) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TEAM_WISE_APPLICATION_BREAK_DOWN_INITIAL,
    token,
  };
};
export const ApplicationSummaryTeamWiseApplicationBreakdownProcess = (
  token
) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TEAM_WISE_APPLICATION_BREAK_DOWN_PROCESS,
    token,
  };
};
export const ApplicationSummaryTeamWiseApplicationBreakdownSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TEAM_WISE_APPLICATION_BREAK_DOWN_SUCCESS,
    data,
  };
};
export const ApplicationSummaryTeamWiseApplicationBreakdownFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TEAM_WISE_APPLICATION_BREAK_DOWN_FAILED,
    data,
  };
};

export const ApplicationSummaryTopAppUrlInitial = () => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TOP_APPLICATION_URL_INITIAL,
  };
};

export const ApplicationSummaryTopAppUrlProcess = (token, bodyData) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TOP_APPLICATION_URL_PROCESS,
    token,
    bodyData,
  };
};

export const ApplicationSummaryTopAppUrlSuccess = (data) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TOP_APPLICATION_URL_SUCCESS,
    data,
  };
};

export const ApplicationSummaryTopAppUrlFailed = (data) => {
  return {
    type: actionTypes.APPLICATION_SUMMARY_TOP_APPLICATION_URL_FAILED,
    data,
  };
};
