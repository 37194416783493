import * as actionType from "./actionType";

export const DesignationCreateInitial = () => {
  return {
    type: actionType.DESIGNATION_CREATE_INITIAL,
  };
};

export const DesignationCreateProcess = (data) => {
  return {
    type: actionType.DESIGNATION_CREATE_PROCESS,
    data,
  };
};

export const DesignationCreateSuccess = (data) => {
  return {
    type: actionType.DESIGNATION_CREATE_SUCCESS,
    data,
  };
};

export const DesignationCreateFailed = (data) => {
  return {
    type: actionType.DESIGNATION_CREATE_FAILED,
    data,
  };
};

export const DesignationListInitial = () => {
  return {
    type: actionType.DESIGNATION_LIST_INITIAL,
  };
};

export const DesignationListProcess = (data) => {
  return {
    type: actionType.DESIGNATION_LIST_PROCESS,
    data,
  };
};

export const DesignationListSuccess = (data) => {
  return {
    type: actionType.DESIGNATION_LIST_SUCCESS,
    data,
  };
};
export const DesignationListSearch = (data) => {
  return {
    type: actionType.DESIGNATION_LIST_SEARCH,
    data,
  };
};
export const DesignationListFailed = (data) => {
  return {
    type: actionType.DESIGNATION_LIST_FAILED,
    data,
  };
};

export const DesignationUpdateInitial = () => {
  return {
    type: actionType.DESIGNATION_UPDATE_INITIAL,
  };
};

export const DesignationUpdateProcess = (data) => {
  return {
    type: actionType.DESIGNATION_UPDATE_PROCESS,
    data,
  };
};

export const DesignationUpdateSuccess = (data) => {
  return {
    type: actionType.DESIGNATION_UPDATE_SUCCESS,
    data,
  };
};

export const DesignationUpdateFailed = (data) => {
  return {
    type: actionType.DESIGNATION_UPDATE_FAILED,
    data,
  };
};
