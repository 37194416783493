import * as actionTypes from "./actionTypes";

export const fetchLogsReport = (payload) => {
  return {
    type: actionTypes.FETCH_LOGS_REPORT,
    payload,
  };
};

export const fetchLogsReportSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_LOGS_REPORT_SUCCESS,
    payload,
  };
};

export const fetchLogsReportFailed = (payload) => {
  return {
    type: actionTypes.FETCH_LOGS_REPORT_FAILED,
    payload,
  };
};

export const downloadLogsReport = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_LOGS_REPORT,
    payload,
  };
};

export const downloadLogsReportSuccess = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_LOGS_REPORT_SUCCESS,
    payload,
  };
};

export const downloadLogsReportFailed = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_LOGS_REPORT_FAILED,
    payload,
  };
};
