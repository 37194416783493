import React from "react";

export const Wellness = ({ viewBox = "0 0 20 20" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox={viewBox}
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.15313 14.1631C1.77833 13.7883 1.44548 13.3903 1.14981 12.9801C0.636335 13.3018 0.255671 13.6033 0.0702415 13.7581C-0.00421165 13.8203 -0.0218288 13.9273 0.028718 14.0101C0.41407 14.6414 2.09067 17.1605 4.53071 17.5628C5.60942 17.7406 6.67938 17.4786 7.58676 17.0901C5.96747 16.6755 3.82969 15.8397 2.15313 14.1631Z"
        fill="currentColor"
      />
      <path
        d="M18.8502 12.9801C18.5545 13.3904 18.2216 13.7883 17.8468 14.1631C16.1702 15.8398 14.0325 16.6755 12.4132 17.0901C13.3206 17.4786 14.3906 17.7406 15.4693 17.5628C17.9093 17.1605 19.5859 14.6414 19.9713 14.0101C20.0218 13.9273 20.0042 13.8204 19.9297 13.7581C19.7443 13.6033 19.3637 13.3018 18.8502 12.9801Z"
        fill="currentColor"
      />
      <path
        d="M6.23296 6.95274C6.44554 6.10496 6.74968 5.32203 7.08214 4.62887C6.13999 3.75688 5.25519 3.19613 4.87179 2.97039C4.7666 2.90844 4.63136 2.95684 4.58929 3.07145C4.43289 3.49785 4.09398 4.52571 3.92175 5.83574C4.67382 6.10992 5.46707 6.47289 6.23296 6.95274Z"
        fill="currentColor"
      />
      <path
        d="M16.0782 5.83574C15.906 4.52571 15.5671 3.49785 15.4107 3.07145C15.3687 2.95684 15.2334 2.90844 15.1282 2.97039C14.7448 3.1961 13.86 3.75688 12.9178 4.62887C13.2503 5.32203 13.5544 6.10492 13.767 6.95274C14.5329 6.47289 15.3262 6.10992 16.0782 5.83574Z"
        fill="currentColor"
      />
      <path
        d="M5.92747 9.34834C5.92747 8.95842 5.95302 8.57592 5.99899 8.20233C3.71915 6.61026 1.01509 6.25553 0.216493 6.18326C0.0928997 6.17209 -0.0103424 6.27533 0.000829442 6.39893C0.0858685 7.33822 0.561142 10.9138 2.98177 13.3344C4.59149 14.9442 6.71173 15.6935 8.22802 16.0399C7.27462 14.6568 5.92747 12.1976 5.92747 9.34834Z"
        fill="currentColor"
      />
      <path
        d="M19.7835 6.18326C18.9849 6.25553 16.2809 6.61026 14.001 8.20236C14.0471 8.57592 14.0726 8.95842 14.0726 9.34834C14.0726 12.1976 12.7254 14.6568 11.772 16.0399C13.2883 15.6934 15.4085 14.9442 17.0183 13.3344C19.4389 10.9138 19.9142 7.33819 19.9992 6.39889C20.0104 6.27533 19.9071 6.17209 19.7835 6.18326Z"
        fill="currentColor"
      />
      <path
        d="M12.9007 9.34834C12.9007 12.7158 10.7443 15.5334 10.1501 16.2459C10.0719 16.3397 9.92823 16.3397 9.85003 16.2459C9.25581 15.5334 7.09937 12.7158 7.09937 9.34834C7.09937 5.98092 9.25585 3.16331 9.85007 2.45081C9.92827 2.35706 10.0719 2.35706 10.1501 2.45081C10.7443 3.16331 12.9007 5.98096 12.9007 9.34834Z"
        fill="currentColor"
      />
    </svg>
  );
};
