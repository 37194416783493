import * as actionType from "./actionType";

export const ReportsDailyAttendanceInitial = (data) => {
  return {
    type: actionType.REPORTS_DAILY_ATTENDANCE_INITIAL,
  };
};
export const ReportsDailyAttendanceProcess = (token, bodyData) => {
  return {
    type: actionType.REPORTS_DAILY_ATTENDANCE_PROCESS,
    token,
    bodyData,
  };
};
export const ReportsDailyAttendanceSuccess = (data) => {
  return {
    type: actionType.REPORTS_DAILY_ATTENDANCE_SUCCESS,
    data,
  };
};
export const ReportsDailyAttendanceFailed = (data) => {
  return {
    type: actionType.REPORTS_DAILY_ATTENDANCE_FAILED,
    data,
  };
};

export const ReportsDownloadDailyAttendanceInitial = (data) => {
  return {
    type: actionType.REPORTS_DOWNLOAD_DAILY_ATTENDANCE_INITIAL,
  };
};
export const ReportsDownloadDailyAttendanceProcess = (token, bodyData) => {
  return {
    type: actionType.REPORTS_DOWNLOAD_DAILY_ATTENDANCE_PROCESS,
    token,
    bodyData,
  };
};
export const ReportsDownloadDailyAttendanceSuccess = (data) => {
  return {
    type: actionType.REPORTS_DOWNLOAD_DAILY_ATTENDANCE_SUCCESS,
    data,
  };
};
export const ReportsDownloadDailyAttendanceFailed = (data) => {
  return {
    type: actionType.REPORTS_DOWNLOAD_DAILY_ATTENDANCE_FAILED,
    data,
  };
};
