import React from "react";

export const Reports = ({ className = "" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className}
    >
      <path
        d="M18.5,10.7h-6.2c-0.3,0-0.5,0.2-0.5,0.5c0,0.1,0.1,0.3,0.2,0.4l4.4,4.4c0.1,0.1,0.2,0.2,0.4,0.2
	c0.2,0,0.3-0.1,0.4-0.2c1.1-1.3,1.8-2.8,1.9-4.6c0,0,0-0.1,0-0.1C19,10.9,18.8,10.7,18.5,10.7z"
      />
      <path
        d="M10.4,1.8c-0.3,0-0.5,0.2-0.5,0.5v6.9c0,0.3,0.2,0.5,0.5,0.5h6.9c0.3,0,0.5-0.2,0.5-0.5
	C17.6,5.2,14.4,2,10.4,1.8z"
      />
      <path
        d="M14.2,15.6l-5.1-5.1c-0.1-0.1-0.1-0.2-0.1-0.3l0-7.2c0-0.3-0.2-0.5-0.5-0.5C4.3,2.6,1,6.1,1,10.3
	c0,4.4,3.6,7.9,7.9,7.9c2,0,3.8-0.7,5.2-2c0.1-0.1,0.2-0.2,0.2-0.4C14.3,15.8,14.3,15.6,14.2,15.6z"
      />
    </svg>
  );
};
