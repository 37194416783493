import React from "react";

export const Pdownload = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        d="M8.6,14.6c0.2,0.2,0.5,0.4,0.7,0.5c0.3,0.1,0.6,0.2,0.9,0.2c0.3,0,0.6-0.1,0.9-0.2c0.3-0.1,0.5-0.3,0.7-0.5
            l2.4-2.4c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.5,0.2l-2.2,2.2l0-11.6
            c0-0.2-0.1-0.4-0.2-0.5C10.5,1.1,10.4,1,10.2,1l0,0C10,1,9.8,1.1,9.6,1.2C9.5,1.3,9.4,1.5,9.4,1.7l0,11.6l-2.2-2.2
            c-0.1-0.1-0.3-0.2-0.5-0.2c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5c0,0.2,0.1,0.4,0.2,0.5L8.6,14.6z"
      />
      <path
        d="M18.4,13c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5v3c0,0.2-0.1,0.4-0.2,0.5s-0.3,0.2-0.5,0.2H3.4
            c-0.2,0-0.4-0.1-0.5-0.2c-0.1-0.1-0.2-0.3-0.2-0.5v-3c0-0.2-0.1-0.4-0.2-0.5C2.3,13.1,2.1,13,1.9,13c-0.2,0-0.4,0.1-0.5,0.2
            c-0.1,0.1-0.2,0.3-0.2,0.5v3c0,0.6,0.2,1.2,0.7,1.6c0.4,0.4,1,0.7,1.6,0.7h13.5c0.6,0,1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6v-3
            c0-0.2-0.1-0.4-0.2-0.5C18.8,13.1,18.6,13,18.4,13z"
      />
    </svg>
  );
};
