import * as actionTypes from "./actionTypes";

//* LIST *//

export const workplaceSettingInitital = () => {
  return {
    type: actionTypes.WORKPLACE_SETTING_INITIAL,
  };
};
export const workplaceSettingProcess = (data) => {
  return {
    type: actionTypes.WORKPLACE_SETTING_PROCESS,
    data,
  };
};

export const workplaceSettingSuccess = (data) => {
  return {
    type: actionTypes.WORKPLACE_SETTING_SUCCESS,
    data,
  };
};

export const workplaceSettingFailed = (data) => {
  return {
    type: actionTypes.WORKPLACE_SETTING_FAILED,
    data,
  };
};

//* EDIT *//

export const workplaceSettingEditInitial = () => {
  return {
    type: actionTypes.WORKPLACE_SETTING_EDIT_INITIAL,
  };
};
export const workplaceSettingEditProcess = (data) => {
  return {
    type: actionTypes.WORKPLACE_SETTING_EDIT_PROCESS,
    data,
  };
};
export const workplaceSettingEditSuccess = (data) => {
  return {
    type: actionTypes.WORKPLACE_SETTING_EDIT_SUCCESS,
    data,
  };
};
export const workplaceSettingEditFailed = (data) => {
  return {
    type: actionTypes.WORKPLACE_SETTING_EDIT_FAILED,
    data,
  };
};
