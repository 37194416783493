import { put } from "redux-saga/effects";
import { NotesAction } from "../action";
import { Client } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { notification } from "antd";

export function* getNoteSaga(action) {
  const config = {
    method: "GET",
    url: `/api/v1/me/notes/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    const response = yield Client.request(config);

    yield put(NotesAction.getNoteSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(NotesAction.getNoteFailed(errorMessage));
  }
}

export function* fetchNotesSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/me/notes/search?${action.payload.query}`,
    headers: {
      ...action.payload.headers,
    },
    data: { ...action.payload.body },
  };
  try {
    const response = yield Client.request(config);

    yield put(NotesAction.fetchNotesSuccess(response.data));
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(NotesAction.fetchNotesFailed(errorMessage));
  }
}

export function* createNoteSaga(action) {
  const config = {
    method: "POST",
    url: `/api/v1/me/notes/`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);

    yield put(NotesAction.createNoteSuccess(response.data));

  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(NotesAction.createNoteFailed(errorMessage));
  }
}

export function* updateNoteSaga(action) {
  const config = {
    method: "PUT",
    url: `/api/v1/me/notes/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
    data: action.payload.body,
  };
  try {
    const response = yield Client.request(config);

    yield put(NotesAction.updateNoteSuccess(response.data));
  
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(NotesAction.updateNoteFailed(errorMessage));
  }
}

export function* deleteNoteSaga(action) {
  const config = {
    method: "DELETE",
    url: `/api/v1/me/notes/${action.payload.id}`,
    headers: {
      ...action.payload.headers,
    },
  };
  try {
    yield Client.request(config);

    yield put(NotesAction.deleteNoteSuccess());
    notification.success({
      message: `Successfully Deleted Note!`,
    });
  } catch (err) {
    let errorMessage = SOMETHING_WENT_WRONG;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(NotesAction.deleteNoteFailed(errorMessage));
  }
}
