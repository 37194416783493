//GET BREAKS

import * as actionTypes from "./actionTypes";

export const BreaksReportInitial = (token) => {
  return {
    type: actionTypes.BREAKS_REPORT_INITIAL,
    token,
  };
};

export const BreaksReportProcess = (payload) => {
  return {
    type: actionTypes.BREAKS_REPORT_PROCESS,
    payload,
  };
};
export const BreaksReportSuccess = (data) => {
  return {
    type: actionTypes.BREAKS_REPORT_SUCCESS,
    data,
  };
};
export const BreaksReportFailed = (data) => {
  return {
    type: actionTypes.BREAKS_REPORT_FAILED,
    data,
  };
};

export const BreaksDownloadReportInitial = (token) => {
  return {
    type: actionTypes.BREAKS_DOWNLOAD_REPORT_INITIAL,
    token,
  };
};

export const BreaksDownloadReportProcess = (payload) => {
  return {
    type: actionTypes.BREAKS_DOWNLOAD_REPORT_PROCESS,
    payload,
  };
};
export const BreaksDownloadReportSuccess = (data) => {
  return {
    type: actionTypes.BREAKS_DOWNLOAD_REPORT_SUCCESS,
    data,
  };
};
export const BreaksDownloadReportFailed = (data) => {
  return {
    type: actionTypes.BREAKS_DOWNLOAD_REPORT_FAILED,
    data,
  };
};

export const MonthlyBreaksReportInitial = (token) => {
  return {
    type: actionTypes.MONTHLY_BREAKS_REPORT_INITIAL,
    token,
  };
};

export const MonthlyBreaksReportProcess = (payload) => {
  return {
    type: actionTypes.MONTHLY_BREAKS_REPORT_PROCESS,
    payload,
  };
};
export const MonthlyBreaksReportSuccess = (data) => {
  return {
    type: actionTypes.MONTHLY_BREAKS_REPORT_SUCCESS,
    data,
  };
};
export const MonthlyBreaksReportFailed = (data) => {
  return {
    type: actionTypes.MONTHLY_BREAKS_REPORT_FAILED,
    data,
  };
};

export const MonthlyBreaksDownloadReportInitial = (token) => {
  return {
    type: actionTypes.MONTHLY_BREAKS_DOWNLOAD_REPORT_INITIAL,
    token,
  };
};

export const MonthlyBreaksDownloadReportProcess = (payload) => {
  return {
    type: actionTypes.MONTHLY_BREAKS_DOWNLOAD_REPORT_PROCESS,
    payload,
  };
};
export const MonthlyBreaksDownloadReportSuccess = (data) => {
  return {
    type: actionTypes.MONTHLY_BREAKS_DOWNLOAD_REPORT_SUCCESS,
    data,
  };
};
export const MonthlyBreaksDownloadReportFailed = (data) => {
  return {
    type: actionTypes.MONTHLY_BREAKS_DOWNLOAD_REPORT_FAILED,
    data,
  };
};
