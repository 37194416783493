import * as actionTypes from "./actionTypes";

export const downloadStealthApp = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_STEALTH_APP,
    payload,
  };
};
export const downloadStealthAppSuccess = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_STEALTH_APP_SUCCESS,
    payload,
  };
};
export const downloadStealthAppFailed = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_STEALTH_APP_SUCCESS,
    payload,
  };
};

export const downloadStandardApp = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_STANDARD_APP,
    payload,
  };
};
export const downloadStandardAppSuccess = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_STANDARD_APP_SUCCESS,
    payload,
  };
};
export const downloadStandardAppFailed = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_STANDARD_APP_SUCCESS,
    payload,
  };
};

export const openProgressBar = (payload) => {
  return {
    type: actionTypes.OPEN_PROGRESS_BAR,
    payload,
  };
};

export const closeProgressBar = (payload) => {
  return {
    type: actionTypes.CLOSE_PROGRESS_BAR,
    payload,
  };
};

export const downloadKeyConfig = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_KEY_CONFIG,
    payload,
  };
};

export const downloadKeyConfigSuccess = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_KEY_CONFIG_SUCCESS,
    payload,
  };
};

export const downloadKeyConfigFailed = (payload) => {
  return {
    type: actionTypes.DOWNLOAD_KEY_CONFIG_SUCCESS,
    payload,
  };
};

export const fetchAppVersionDetails = (payload) => {
  return {
    type: actionTypes.FETCH_APP_VERSION_DETAILS,
    payload,
  };
};

export const fetchAppVersionDetailsSuccess = (payload) => {
  return {
    type: actionTypes.FETCH_APP_VERSION_DETAILS_SUCCESS,
    payload,
  };
};
