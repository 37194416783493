import * as actionTypes from "./actionTypes";

const initialPagination = { totalCount: 0 };

const initialState = {
  dailyPingsData: [],
  fetchDailyPingsLoading: true,
  fieldErrorMessage: "",
  pagination: initialPagination,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.FETCH_DAILY_PINGS:
      return {
        ...state,
        fetchDailyPingsLoading: true,
        fieldErrorMessage: "",
      };

    case actionTypes.FETCH_DAILY_PINGS_SUCCESS:
      return {
        ...state,
        fetchDailyPingsLoading: false,
        dailyPingsData: action.payload.data,
        pagination: action.payload.meta || state.pagination,
      };

    case actionTypes.FETCH_DAILY_PINGS_FAILED:
      return {
        ...state,
        fetchDailyPingsLoading: false,
        dailyPingsData: [],
        pagination: initialPagination,
        fieldErrorMessage: action.payload,
      };

    case actionTypes.SET_FIELD_ERROR_MESSAGE:
      return {
        ...state,
        fieldErrorMessage: action.payload,
      };

    default:
      return state;
  }
};
