import * as actionTypes from "./actionTypes";
import _ from "lodash";

const initialState = {
  //*GLOBAL*//

  //* ACTIVITY detailed WORKIGN TIME TRENDS  *//

  activity_detailed_working_time_trend_processing: false,
  activity_detailed_working_time_trend_success: false,
  activity_detailed_working_time_trend_failed: false,
  activity_detailed_working_time_trend_data: [],
  activity_detailed_working_time_trend_message: "",

  //* ACTIVITY detailed employee_list WEDIGT *//

  activity_detailed_employee_list_processing: false,
  activity_detailed_employee_list_success: false,
  activity_detailed_employee_list_failed: false,
  activity_detailed_employee_list_data: [],
  activity_detailed_employee_list_message: "",

  //* ACTIVITY employee detailed employee_list WEDIGT *//

  activity_employee_detailed_employee_list_processing: false,
  activity_employee_detailed_employee_list_success: false,
  activity_employee_detailed_employee_list_failed: false,
  activity_employee_detailed_employee_list_data: [],
  activity_employee_detailed_employee_list_message: "",
};
export default (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.ACTIVITY_DETAILED_WORKING_TIME_TREND_INITIAL:
      return {
        ...state,
        activity_detailed_working_time_trend_processing: false,
        activity_detailed_working_time_trend_success: false,
        activity_detailed_working_time_trend_failed: false,
        activity_detailed_working_time_trend_data: [],
        activity_detailed_working_time_trend_message: "",
      };

    case actionTypes.ACTIVITY_DETAILED_WORKING_TIME_TREND_PROCESS:
      return {
        ...state,
        activity_detailed_working_time_trend_processing: true,
        activity_detailed_working_time_trend_success: false,
        activity_detailed_working_time_trend_failed: false,
        activity_detailed_working_time_trend_data: [],
        activity_detailed_working_time_trend_message: "",
      };

    case actionTypes.ACTIVITY_DETAILED_WORKING_TIME_TREND_SUCCESS:
      return {
        ...state,
        activity_detailed_working_time_trend_processing: false,
        activity_detailed_working_time_trend_success: true,
        activity_detailed_working_time_trend_failed: false,
        activity_detailed_working_time_trend_data: action.data,
        activity_detailed_working_time_trend_message: "",
      };
    case actionTypes.ACTIVITY_DETAILED_WORKING_TIME_TREND_FAILED:
      return {
        ...state,
        activity_detailed_working_time_trend_processing: false,
        activity_detailed_working_time_trend_success: false,
        activity_detailed_working_time_trend_failed: true,
        activity_detailed_working_time_trend_data: [],
        activity_detailed_working_time_trend_message: action.data,
      };
    case actionTypes.ACTIVITY_DETAILED_EMPLOYEE_LIST_INITIAL:
      return {
        ...state,
        activity_detailed_employee_list_processing: true,
        activity_detailed_employee_list_success: false,
        activity_detailed_employee_list_failed: false,
        activity_detailed_employee_list_data: [],
        activity_detailed_employee_list_message: "",
      };

    case actionTypes.ACTIVITY_DETAILED_EMPLOYEE_LIST_PROCESS:
      return {
        ...state,
        activity_detailed_employee_list_processing: true,
        activity_detailed_employee_list_success: false,
        activity_detailed_employee_list_failed: false,
        activity_detailed_employee_list_data: [],
        activity_detailed_employee_list_message: "",
      };

    case actionTypes.ACTIVITY_DETAILED_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        activity_detailed_employee_list_processing: false,
        activity_detailed_employee_list_success: true,
        activity_detailed_employee_list_failed: false,
        activity_detailed_employee_list_data: action.data,
        activity_detailed_employee_list_message: "",
      };
    case actionTypes.ACTIVITY_DETAILED_EMPLOYEE_LIST_FAILED:
      return {
        ...state,
        activity_detailed_employee_list_processing: false,
        activity_detailed_employee_list_success: false,
        activity_detailed_employee_list_failed: true,
        activity_detailed_employee_list_data: action.data,
        activity_detailed_employee_list_message: "",
      };

    case actionTypes.ACTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_INITIAL:
      return {
        ...state,
        activity_employee_detailed_employee_list_processing: true,
        activity_employee_detailed_employee_list_success: false,
        activity_employee_detailed_employee_list_failed: false,
        activity_employee_detailed_employee_list_data: [],
        activity_employee_detailed_employee_list_message: "",
      };

    case actionTypes.ACTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_PROCESS:
      return {
        ...state,
        activity_employee_detailed_employee_list_processing: true,
        activity_employee_detailed_employee_list_success: false,
        activity_employee_detailed_employee_list_failed: false,
        activity_employee_detailed_employee_list_data: [],
        activity_employee_detailed_employee_list_message: "",
      };

    case actionTypes.ACTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_SUCCESS:
      return {
        ...state,
        activity_employee_detailed_employee_list_processing: false,
        activity_employee_detailed_employee_list_success: true,
        activity_employee_detailed_employee_list_failed: false,
        activity_employee_detailed_employee_list_data: action.data,
        activity_employee_detailed_employee_list_message: "",
      };
    case actionTypes.ACTIVITY_EMPLOYEE_DETAILED_EMPLOYEE_LIST_FAILED:
      return {
        ...state,
        activity_employee_detailed_employee_list_processing: false,
        activity_employee_detailed_employee_list_success: false,
        activity_employee_detailed_employee_list_failed: true,
        activity_employee_detailed_employee_list_data: action.data,
        activity_employee_detailed_employee_list_message: "",
      };

    default:
      return state;
  }
};
