import { put, call } from "redux-saga/effects";
import { SignupAction } from "../action";
import { AuthClient, Client, ContcrolClient } from "../../../utils/transport";
import { SOMETHING_WENT_WRONG } from "constants/staticText";
import { HUBSPOT_CREATE_CONTACT_ENDPOINT } from "constants/urls";
import axios from "axios";
import { getSignupTenantType } from "utils/commonUtils";
import { analyticsTrackEvent } from "utils/analyticsUtils";

const getAnalyticsProperties = (tenantDetails, type, idp) => {
  const signupTenantType = getSignupTenantType(
    tenantDetails?.contact_email?.split("@")[1]
  );

  analyticsTrackEvent("Tenant Registration Complete", {
    email: tenantDetails.contact_email,
    name: `${tenantDetails.contact_first_name} ${tenantDetails.contact_last_name}`,
    phone: tenantDetails.shipping_address?.phone_number,
    tenantId: tenantDetails.id,
    tenantName: tenantDetails.name,
    tenantBaseDomain: tenantDetails.base_domain,
    identityProvider: idp,
    type,
    signupTenantType,
  });

  return {
    company: tenantDetails.name,
    email: tenantDetails.contact_email,
    firstname: tenantDetails.contact_first_name,
    lastname: tenantDetails.contact_last_name,
    phone: tenantDetails.shipping_address?.phone_number,
    basedomain: tenantDetails.base_domain,
    tenantid: tenantDetails.id,
    lifecyclestage: "lead",
    signup_type: signupTenantType === "organization" ? 1 : 0,
  };
};

export function* CheckDomain(data) {
  const domain = {
    baseDomain: data.data.domain,
  };
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    url: "SIGNUP_PHASE_5",
    //  url:`discover/tenant_by_base_domain?baseDomain=${data.data.domain}`,
    method: "GET",
    params: domain,
  };
  try {
    const response = yield ContcrolClient.request(config);

    if (response.data) {
      yield put(SignupAction.CheckDomainSuccess(response.data));
      localStorage.setItem(
        "headers",
        JSON.stringify(response?.data?.headers || {})
      );
      Client.defaults.headers.post = response?.data?.headers || {};
    } else {
      yield put(SignupAction.CheckDomainFailed(response));
    }
  } catch (err) {
    yield put(SignupAction.CheckDomainFailed(err.response));
  }
}

export function* Signup(data) {
  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    url: `onboarding/start-for-tenant/`,
    method: "POST",
    data: data.data,
  };
  try {
    const response = yield Client.request(config);

    if (response.status === 200) {
      yield put(SignupAction.SignupCreateSuccess(response));
    } else {
      yield put(SignupAction.SignupCreateFailed(response));
    }
  } catch (err) {
    let errorMessage;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(
      SignupAction.SignupCreateFailed(errorMessage || SOMETHING_WENT_WRONG)
    );
  }
}

export function* VerifyToken(data) {
  const config = {
    url:
      "/onboarding/complete-for-tenant-via-email-token/?token=" +
      data.data.token,
    method: "POST",
    headers: data.data.headers,
  };

  try {
    const response = yield Client.request(config);

    const tenantDetails = response.data || {};
    try {
      const hubspotConfig = {
        url: HUBSPOT_CREATE_CONTACT_ENDPOINT,
        method: "POST",
        data: {
          properties: getAnalyticsProperties(tenantDetails, "emailPassword"),
        },
      };

      yield axios.request(hubspotConfig);
    } catch (err) {
      console.error("hubspot registration failed", err.errorMessage);
    }
    yield put(SignupAction.SignupVerifyTokenSuccess(tenantDetails));
  } catch (err) {
    let errorMessage;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(
      SignupAction.SignupVerifyTokenFailed(errorMessage || SOMETHING_WENT_WRONG)
    );
  }
}

export function* acceptInvitationSaga(data) {
  const config = {
    url: `/onboarding/${data.data.tenantId}/setup-standard-user/?token=${data.data.token}`,
    method: "POST",
    headers: data.data.headers,
  };

  try {
    const response = yield Client.request(config);

    yield put(SignupAction.acceptInvitationSuccess(response.data));
  } catch (err) {
    let errorMessage;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(
      SignupAction.acceptInvitationFailed(errorMessage || SOMETHING_WENT_WRONG)
    );
  }
}

export function* reconfirmCompleteSetupSaga(data) {
  const config = {
    url: "/discover/tenant_by_id?id=" + data.data.tenantID,
    method: "GET",
  };

  try {
    const response = yield ContcrolClient.request(config);

    const tenantDetails = response.data || {};
    try {
      throw "Reconfirm Complete Setup doesn't have contact info";
      // const hubspotConfig = {
      //   url: HUBSPOT_CREATE_CONTACT_ENDPOINT,
      //   method: "POST",
      //   data: {
      //     properties: getAnalyticsProperties(tenantDetails),
      //   },
      // };

      // yield axios.request(hubspotConfig);
    } catch (err) {
      console.error("hubspot registration failed", err.errorMessage);
    }
    yield put(SignupAction.SignupVerifyTokenSuccess(tenantDetails));
  } catch (err) {
    let errorMessage;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(
      SignupAction.SignupVerifyTokenFailed(errorMessage || SOMETHING_WENT_WRONG)
    );
  }
}

export function* GetTokenFromCode(data) {
  const params = new URLSearchParams();
  params.append("grant_type", "authorization_code");
  params.append("client_id", "signup");
  params.append("redirect_uri", data.data.redirect_uri);
  params.append("code", data.data.code);

  const config = {
    url: "/token",
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    data: params,
  };
  try {
    const response = yield AuthClient.request(config);

    if (response.status === 200) {
      yield put(SignupAction.SignupGetTokenSuccess(response));
    } else {
      yield put(SignupAction.SignupGetTokenFailed(response));
    }
  } catch (err) {
    yield put(SignupAction.SignupGetTokenFailed(SOMETHING_WENT_WRONG));
  }
}

export function* VerifyGoogleToken(data) {
  const header = {
    url: "/onboarding/tenant-for-authenticated-user/",
    method: "POST",
    headers: {
      Authorization: `Bearer ${data.data.token}`,
    },
    data: data.data.finalData,
  };
  try {
    const response = yield Client.request(header);

    const tenantDetails = response.data || {};

    try {
      const hubspotConfig = {
        url: HUBSPOT_CREATE_CONTACT_ENDPOINT,
        method: "POST",
        data: {
          properties: getAnalyticsProperties(tenantDetails, "social", "google"),
        },
      };

      yield axios.request(hubspotConfig);
    } catch (err) {
      console.error("hubspot registration failed", err.errorMessage);
    }
    yield put(SignupAction.SignupGoogleVerifyTokenSuccess(tenantDetails));
  } catch (err) {
    let errorMessage;
    if (err.response?.status < 500) {
      errorMessage = err.response?.data?.errors?.[0]?.detail;
    }
    yield put(
      SignupAction.SignupGoogleVerifyTokenFailed(
        errorMessage || SOMETHING_WENT_WRONG
      )
    );
  }
}

export function* SignupSetPassword(data) {
  const header = {
    url: "signup/create_tenant/" + data.data.token,
    method: "POST",
    data: data.data.data,
  };
  try {
    const response = yield Client.request(header);

    if (response.data) {
      yield put(SignupAction.SignupSetPasswordSuccess(response.data));
    } else {
      yield put(SignupAction.SignupSetPasswordFailed(response));
    }
  } catch (err) {
    yield put(SignupAction.SignupSetPasswordFailed(err));
  }
}

export function* SetDomain(data) {
  const header = {
    url: "signup/create_tenant/" + data.data,
    method: "POST",
  };
  try {
    const response = yield Client.request(header);

    if (response.data) {
      yield put(SignupAction.SetDomainSuccess(response.data));
    } else {
      yield put(SignupAction.SetDomainFailed(response));
    }
  } catch (err) {
    yield put(SignupAction.SetDomainFailed(err));
  }
}

export function* SetResendEmail(data) {
  const header = {
    url: "signup/resend_mail",
    method: "POST",
    data: data.data,
  };
  try {
    const response = yield Client.request(header);

    if (response.data) {
      yield put(SignupAction.SetResendEmailSuccess(response.data));
    } else {
      yield put(SignupAction.SetResendEmailFailed(response));
    }
  } catch (err) {
    yield put(SignupAction.SetResendEmailFailed(err));
  }
}
