import { put } from "redux-saga/effects";
import { ActivitySummaryAction } from "../action";
import { Client } from "../../../utils/transport";

export function* GetActivitySummaryTopAppUrlData(data) {
  let config = {
    method: "POST",
    url: "query/activity/tops",

    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ActivitySummaryAction.ActivitySummaryTopAppUrlSuccess(
          response.data.data
        )
      );
    } else {
      yield put(ActivitySummaryAction.ActivitySummaryTopAppUrlFailed(response));
    }
  } catch (err) {
    yield put(ActivitySummaryAction.ActivitySummaryTopAppUrlFailed(err));
  }
}

export function* GetActivitySummaryOnlineBreaDownData(data) {
  let config = {
    method: "POST",
    url: "query/activity/online_time_breakdown",

    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ActivitySummaryAction.ActivitySummaryOnlineTimeBreakdownSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ActivitySummaryAction.ActivitySummaryOnlineTimeBreakdownFailed(response)
      );
    }
  } catch (err) {
    yield put(
      ActivitySummaryAction.ActivitySummaryOnlineTimeBreakdownFailed(err)
    );
  }
}

export function* GetActivitySummaryActivityLevelBreakDown(data) {
  let config = {
    method: "POST",
    url: "query/activity/activity_level_breakdown",
    headers: {
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ActivitySummaryAction.ActivitySummaryActivityLevelBreakdownSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ActivitySummaryAction.ActivitySummaryActivityLevelBreakdownFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ActivitySummaryAction.ActivitySummaryActivityLevelBreakdownFailed(err)
    );
  }
}

export function* GetActivitySummaryTopButtomActiveTeam(data) {
  let config = {
    method: "POST",
    url: "query/activity/team_wise_activity",
    headers: {
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ActivitySummaryAction.ActivitySummaryTopButtomActiveTeamSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ActivitySummaryAction.ActivitySummaryTopButtomActiveTeamFailed(response)
      );
    }
  } catch (err) {
    yield put(
      ActivitySummaryAction.ActivitySummaryTopButtomActiveTeamFailed(err)
    );
  }
}

export function* GetActivitySummaryTeamWiseActivityBreakDown(data) {
  let config = {
    method: "POST",
    url: "query/activity/team_wise_activity",
    headers: {
      Authorization: `Bearer ` + data.token,
    },
    data: data.bodyData,
    fetchPolicy: "no-cache",
  };
  try {
    var response = yield Client.request(config);

    if (response.status === 200) {
      yield put(
        ActivitySummaryAction.ActivitySummaryTeamWiseActivityBreakdownSuccess(
          response.data.data
        )
      );
    } else {
      yield put(
        ActivitySummaryAction.ActivitySummaryTeamWiseActivityBreakdownFailed(
          response
        )
      );
    }
  } catch (err) {
    yield put(
      ActivitySummaryAction.ActivitySummaryTeamWiseActivityBreakdownFailed(err)
    );
  }
}
