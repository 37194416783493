export const GET_NOTE = "GET_NOTE";
export const GET_NOTE_SUCCESS = "GET_NOTE_SUCCESS";
export const GET_NOTE_FAILED = "GET_NOTE_FAILED";

export const FETCH_NOTES = "FETCH_NOTES";
export const FETCH_NOTES_SUCCESS = "FETCH_NOTES_SUCCESS";
export const FETCH_NOTES_FAILED = "FETCH_NOTES_FAILED";

export const CREATE_NOTE = "CREATE_NOTE";
export const CREATE_NOTE_SUCCESS = "CREATE_NOTE_SUCCESS";
export const CREATE_NOTE_FAILED = "CREATE_NOTE_FAILED";

export const DELETE_NOTE = "DELETE_NOTE";
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";
export const DELETE_NOTE_FAILED = "DELETE_NOTE_FAILED";

export const UPDATE_NOTE = "UPDATE_NOTE";
export const UPDATE_NOTE_SUCCESS = "UPDATE_NOTE_SUCCESS";
export const UPDATE_NOTE_FAILED = "UPDATE_NOTE_FAILED";

export const SET_NOTE_ERROR_MESSAGE = "SET_NOTE_ERROR_MESSAGE";

export const SET_SELECTED_NOTE = "SET_SELECTED_NOTE";
